<template>
    <div class="benchmarks">
        <div v-for="benchmark in benchmarks" :key="benchmark.columnName" class="benchmark-item">
            <div class="column-identifier" />
            <div ref="benchmarks" class="benchmark-values" data-chart-sync-scroll>
                <div v-for="(value, idx) in benchmark.values" :key="idx" class="benchmark-value body-small">
                    {{ value !== null ? value + '%' : '' }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Benchmarks',
    props: {
        benchmarks: {
            type: Array,
            default: () => [],
        },
    },
    mounted() {
        this.$emit('benchmarksMounted', this.$refs.benchmarks || []);
    },
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixins';
.benchmark-item {
    border-top: 1px solid black;
    padding-top: 3px;
    margin-top: 5px;
    display: flex;
    align-items: center;
}
.benchmark-item .column-identifier {
    width: 25px;
    height: 25px;
    margin-right: calc(var(--yValueWidth) - 25px);
    flex-shrink: 0;
}
.benchmark-values {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    overflow: auto;
    @include invisibleScrollbar;
    pointer-events: none;
}
.benchmark-value {
    width: var(--columnsGroupWidth);
    margin: 0 var(--columnsGroupMargin);
    text-align: right;
    flex-shrink: 0;
}
.benchmark-value:first-child {
    margin-left: 0;
}
.benchmark-value:last-child {
    margin-right: 0;
}
</style>
