<template>
    <EEPlaceholder v-if="isExperienceEditor">Fund Details Preliminary Important Info</EEPlaceholder>

    <div
        v-else-if="loading || error || lineItems.shown.length"
        class="px-2 px-md-3 px-lg-5 py-4"
        :class="vectorBasedBackground"
    >
        <h2 class="h5">{{ $t('fundDetailsPreliminaryImportantInformation') }}</h2>

        <div class="mt-3">
            <FundDetailsTabSkeleton v-if="loading" />

            <AlertMessageBase v-else-if="error" type="error" :title="$t(error)" />

            <template v-else-if="lineItems.shown.length">
                <ul class="list">
                    <li
                        v-for="(line, index) in lineItems.shown"
                        :key="line"
                        class="body-small"
                        :class="{ 'mt-2': index !== 0 }"
                    >
                        {{ line }}
                    </li>

                    <ExpandableContent v-if="lineItems.hidden.length" :show="showMore">
                        <div class="pt-2">
                            <li
                                v-for="(line, index) in lineItems.hidden"
                                :key="line"
                                class="body-small"
                                :class="{ 'mt-2': index !== 0 }"
                            >
                                {{ line }}
                            </li>
                        </div>
                    </ExpandableContent>
                </ul>

                <button
                    v-if="lineItems.hidden.length"
                    class="mt-3 btn-naked d-flex gap-1"
                    :aria-expanded="showMore ? 'true' : 'false'"
                    @click="showMore = !showMore"
                >
                    <span class="text-link">{{ showMore ? $t('showLess') : $t('showMore') }}</span>
                    <IconChevron class="icon icon--chevron" />
                </button>
            </template>

            <div v-else class="body-default">{{ $t('noResultsMessage') }}</div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import AlertMessageBase from '@/components/Generic/AlertMessageBase';
import ExpandableContent from '@/components/Generic/ExpandableContent';
import IconChevron from '@/components/Icons/icon-chevron-second-level-tabs';
import EEPlaceholder from '@/components/Sections/EEPlaceholder';

import FundDetailsTabSkeleton from './FundDetailsTabSkeleton';

export default {
    name: 'FundDetailsPreliminaryImportantInfo',

    components: {
        AlertMessageBase,
        EEPlaceholder,
        ExpandableContent,
        FundDetailsTabSkeleton,
        IconChevron,
    },

    data() {
        return {
            linesToShowByDefault: 2,
            showMore: false,
        };
    },

    computed: {
        ...mapState('fundDetails', {
            loading: (state) => state.preliminaryImportantInfo.loading,
            error: (state) => state.preliminaryImportantInfo.error,
            data: (state) => state.preliminaryImportantInfo.data,
        }),

        ...mapGetters('fundDetails', ['selectedShareClass']),

        lineItems() {
            return {
                shown: this.data?.slice(0, this.linesToShowByDefault) || [],
                hidden: this.data?.slice(this.linesToShowByDefault) || [],
            };
        },
    },

    watch: {
        selectedShareClass: {
            handler() {
                this.fetchData();
            },
            immediate: true,
        },
    },

    methods: {
        ...mapActions('fundDetails', ['fetchPreliminaryImportantInfo']),

        async fetchData() {
            if (this.isExperienceEditor) return;

            const {
                fundsConfiguration: {
                    countryInvestors: { countryCode },
                },
                displayLanguage,
                site: { name },
            } = this.$jss.sitecoreContext();

            await this.fetchPreliminaryImportantInfo({
                countryCode,
                language: displayLanguage,
                shareClass: this.selectedShareClass?.id,
                site: name,
            });
        },
    },
};
</script>

<style scoped lang="scss">
.icon {
    width: calc(var(--spacer) * 3);
    height: calc(var(--spacer) * 3);
}

.icon--chevron {
    transition: transform 0.3s;
}

[aria-expanded='true'] > .icon--chevron {
    transform: rotate(180deg);
}

.list {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
        position: relative;
        padding-left: calc(var(--spacer) * 6);
        color: var(--abrdn-black);

        &:before {
            content: '';
            position: absolute;
            top: calc(var(--spacer) * 0.75);
            left: calc(var(--spacer) * 3);
            width: var(--spacer);
            height: var(--spacer);
            border-radius: 50%;
            background-color: currentColor;
        }
    }
}
</style>
