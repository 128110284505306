<template>
    <router-link :to="data.url" class="article-card">
        <div class="d-flex flex-column flex-md-row gap-4">
            <div class="flex-shrink-0">
                <StaticImage
                    :src="data.image.url"
                    :alt="data.image.alt"
                    class="article-card__image"
                    :srcSet="[{ mw: 200 }, { mw: 400 }, { mw: 600 }, { mw: 800 }, { mw: 1000 }]"
                    sizes="
                        (max-width: 575px) 100vw,
                        (max-width: 767px) 510px,
                        170px
                    "
                />
            </div>

            <div class="d-flex flex-column flex-grow-1">
                <div class="d-flex justify-content-between gap-2">
                    <div class="section-header-small">{{ data.tag }}</div>
                    <time class="section-header-small text-nowrap">{{ localizeDate(data.date) }}</time>
                </div>

                <h4 class="article-card__title h4 mt-2 mb-3">{{ data.title }}</h4>

                <div class="mt-auto">
                    <Readtime :articleType="data.type" :duration="data.duration" class="section-header-small" />
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
import StaticImage from '@/components/Generic/SitecoreWrappers/StaticImage';
import Readtime from '@/components/Includes/Readtime';
import { localizeDate } from '@/mixins/Helpers.mixin';

export default {
    name: 'ArticleCardHorizontal',
    components: {
        Readtime,
        StaticImage,
    },
    mixins: [localizeDate],
    props: {
        data: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';

.article-card {
    display: block;

    &:hover,
    &:focus {
        .article-card__title {
            text-decoration: underline;
        }
    }

    .article-card__image {
        display: block;
        width: 100%;
        height: 170px;
        object-fit: cover;

        @include media-breakpoint-up(md) {
            width: 170px;
        }
    }
}
</style>
