<template>
    <section :class="{ 'background-primary': applyBackground }" class="default-section-padding">
        <div class="container">
            <div v-if="currentStep !== steps.length && steps.length" class="d-flex flex-column">
                <AbrdnButton
                    buttonType="primary"
                    type="submit"
                    class="text-grey text-center w-fit-content"
                    :replaceWithSlot="false"
                    :showExternalIcon="false"
                    @click.native="nextStep"
                >
                    {{ getNextStep }}
                    <i class="bi bi-chevron-right ms-1" aria-hidden="true" />
                </AbrdnButton>
                <AbrdnButton
                    v-if="currentStep !== 0"
                    buttonType="textLink"
                    class="back-button text-center w-fit-content"
                    :replaceWithSlot="false"
                    :showExternalIcon="false"
                    @click.native="prevStep"
                >
                    <i class="bi bi-chevron-left me-1" aria-hidden="true" />

                    {{ getPrevStep }}
                </AbrdnButton>
            </div>
            <AbrdnButton
                v-if="showSubmitButton"
                buttonType="primary"
                class="text-grey text-center"
                :replaceWithSlot="false"
                :showExternalIcon="false"
                @click.native="$emit('submitForm')"
            >
                Submit
                <i class="bi bi-chevron-right ms-1" aria-hidden="true" />
            </AbrdnButton>
        </div>
    </section>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton';
import { updateUrlParams } from '@/mixins/Helpers.mixin';

export default {
    name: 'StepSelector',
    components: { AbrdnButton },
    mixins: [updateUrlParams],
    data() {
        return {
            isStepValid: false,
        };
    },
    computed: {
        ...mapState('onboardingForm', ['steps', 'currentStep', 'completedSteps']),
        showSubmitButton() {
            return this.currentStep === this.steps?.length;
        },
        applyBackground() {
            return this.currentStep !== 0 && this.currentStep !== this.steps?.length;
        },
        getNextStep() {
            if (this.currentStep === 0) {
                return this.steps[this.currentStep].nextStep;
            } else {
                return `Step ${this.currentStep + 1}: ${this.steps[this.currentStep].nextStep}`;
            }
        },
        getPrevStep() {
            if (this.currentStep === 1) {
                return `Back to ${this.steps[this.currentStep].prevStep}`;
            } else {
                return `Back to step ${this.currentStep - 1}: ${this.steps[this.currentStep].prevStep}`;
            }
        },
    },
    methods: {
        ...mapMutations('onboardingForm', ['changeStep', 'setCompletedStep']),
        nextStep() {
            // if user change step manually in URl we need to prevent him to move forward
            if (this.currentStep !== 0 && !this.completedSteps[this.currentStep - 1]) {
                this.changeStep(0);
                this.updateUrl();
            } else if (this.validateStepFields()) {
                this.setCompletedStep();
                this.changeStep(this.currentStep + 1);
                this.updateUrl();
            }
        },
        prevStep() {
            this.changeStep(this.currentStep - 1);
            this.updateUrl();
        },
        updateUrl() {
            this.updateUrlParams([{ name: 'step', value: this.currentStep }]);
            this.$nextTick(() => {
                const form = document.getElementById('onboarding_form');
                form && form.scrollIntoView();
            });
        },
        validateStepFields() {
            if (this.currentStep === 0) return true;

            const form = this.$parent?.$refs?.step?.$children[0].$refs.form;
            let isValid = true;
            const listOfInvalidElements = [];

            for (const element of form.elements) {
                if (element.willValidate) {
                    if (!element.checkValidity()) {
                        isValid = false;

                        // Trigger the invalid event manually
                        const invalidEvent = new Event('invalid', {
                            bubbles: true,
                            cancelable: true,
                        });
                        listOfInvalidElements.push(element);
                        element.dispatchEvent(invalidEvent);
                        listOfInvalidElements[0].scrollIntoView({ block: 'center' });
                    }
                }
            }
            return isValid;
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
@import '@/assets/styles/mixins';
.back-button {
    color: $white;
    text-decoration: none;
    margin-top: 40px;

    &:hover {
        text-decoration: underline;
    }
}
</style>
