import { render, staticRenderFns } from "./PastPerformanceModalContent.vue?vue&type=template&id=6b8be097&scoped=true"
import script from "./PastPerformanceModalContent.vue?vue&type=script&lang=js"
export * from "./PastPerformanceModalContent.vue?vue&type=script&lang=js"
import style0 from "./PastPerformanceModalContent.vue?vue&type=style&index=0&id=6b8be097&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b8be097",
  null
  
)

export default component.exports