<template>
    <div ref="animation" />
</template>

<script>
import lottie from 'lottie-web';

export default {
    name: 'Animation',
    props: {
        renderer: {
            type: String,
            default: /* istanbul ignore next */ () => 'svg',
        },
        loop: {
            type: Boolean,
            default: /* istanbul ignore next */ () => true,
        },
        autoplay: {
            type: Boolean,
            default: /* istanbul ignore next */ () => true,
        },
        animationData: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        visible: {
            type: Boolean,
            default: /* istanbul ignore next */ () => true,
        },
        scaleSettings: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    watch: {
        visible: {
            handler(val) {
                val ? lottie.play() : lottie.stop();
            },
        },
    },
    mounted() {
        let parsedAnimationData = this.animationData;
        if (typeof parsedAnimationData === 'string') {
            parsedAnimationData = JSON.parse(this.animationData);
        }
        if (!!Object.keys(parsedAnimationData).length) {
            lottie.loadAnimation({
                container: this.$refs.animation, // the dom element that will contain the animation
                renderer: this.renderer,
                loop: this.loop,
                autoplay: this.autoplay,
                animationData: parsedAnimationData, // the path to the animation json
                rendererSettings: this.scaleSettings,
            });
        }
    },
    beforeDestroy() {
        lottie.destroy();
    },
};
</script>
