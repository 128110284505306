<template>
    <section v-if="article" :id="anchorId" class="background-white">
        <div class="section-header-wrapper">
            <WrappedImage
                :media="article.image"
                class="section-background"
                :srcSet="[
                    { mw: 400 },
                    { mw: 600 },
                    { mw: 800 },
                    { mw: 1000 },
                    { mw: 1200 },
                    { mw: 1400 },
                    { mw: 1600 },
                    { mw: 1800 },
                    { mw: 2000 },
                ]"
                sizes="100vw"
                :lazy="false"
            />

            <div class="mt-auto pt-10">
                <div class="container">
                    <div class="row">
                        <div class="col-10 col-md-9 col-lg-7">
                            <div class="section-header">
                                <div
                                    v-if="article.contentGroup || article.category"
                                    class="mb-2 d-flex flex-column flex-md-row align-items-md-center gap-1 gap-md-4"
                                >
                                    <div v-if="article.contentGroup">
                                        <div class="h5 text-nowrap">{{ article.contentGroup }}</div>
                                    </div>
                                    <div v-if="article.category">
                                        <Badge>{{ article.category }}</Badge>
                                    </div>
                                </div>

                                <ScText tag="h2" :field="article.title" class="h2" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="py-4 py-lg-8">
                <div class="row">
                    <div class="col-12 col-md-9">
                        <ScText tag="p" :field="article.strapline" class="subheader-1 text-grey" />
                    </div>
                </div>

                <div v-if="authorsLength || article.contributors" class="row mt-4 mt-md-5 mt-lg-6">
                    <div class="col-12 col-md-9 col-lg-12">
                        <div class="row mt-n3">
                            <ArticleAuthors
                                v-if="authorsLength"
                                :authors="article.authors"
                                class="col-12 col-lg-6 mt-3"
                            />
                            <ArticleContributors
                                v-if="article.contributors"
                                :contributors="article.contributors"
                                class="col-12 col-lg-6 mt-3"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import WrappedImage from '@/components/Generic/SitecoreWrappers/WrappedImage';
import Badge from '@/components/Includes/Badge';

import ArticleAuthors from './ArticleAuthors';
import ArticleContributors from './ArticleContributors';

export default {
    name: 'ArticleHeroImageBackground',
    components: {
        ArticleAuthors,
        ArticleContributors,
        Badge,
        ScText: Text,
        WrappedImage,
    },
    props: {
        article: {
            type: Object,
            default: null,
        },
    },
    computed: {
        authorsLength() {
            return this.article?.authors?.length;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';

.section-header-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 430px;

    @include media-breakpoint-up(md) {
        min-height: 570px;
    }
}

.section-background {
    &,
    & ::v-deep img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.section-header {
    position: relative;
    z-index: 0;
    padding-top: calc(var(--spacer) * 4);
    padding-right: calc(var(--spacer) * 4);
    background-color: var(--abrdn-white);

    @include media-breakpoint-up(md) {
        padding-top: calc(var(--spacer) * 5);
        padding-right: calc(var(--spacer) * 5);
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 100%;
        z-index: -1;
        width: 100vw;
        height: 100%;
        background-color: inherit;
    }
}
</style>
