import axios from 'axios';

const axiosWithAdditionalErrorHandling = axios.create();

export const handleResponse = (response) => {
    const status = response.data?.statusCode;

    if (status !== undefined) {
        const isStatusSuccess = status >= 200 && status < 300;

        if (!isStatusSuccess) {
            const error = new Error();
            error.response = { status };
            return Promise.reject(error);
        }

        return {
            ...response,
            data: response.data?.content,
        };
    }

    return response;
};

export const handleRequest = (config) => {
    const { data } = JSON.parse(JSON.stringify(config));
    const language = data.language;
    const site = data.site;

    if ((site === 'abrdnJssAutomationIntermediary' || site === 'abrdnJssIntermediary') && language === 'en-us') {
        if ((config.url && config.url.includes('/api/funds')) || config.url.includes('/api/library')) {
            if (config.data) {
                config.data.language = 'en-lu';
            }
        }
    }

    return config;
};

export const handleError = (error) => {
    return Promise.reject(error);
};
axiosWithAdditionalErrorHandling.interceptors.request.use(handleRequest, handleError);

axiosWithAdditionalErrorHandling.interceptors.response.use(handleResponse, handleError);

export default axiosWithAdditionalErrorHandling;
