<template>
    <section
        :id="anchorId"
        :data-e2e-component-uid="rendering.uid"
        :class="{ [backgroundClass]: fields.items.length === 1 }"
        class="default-section-padding"
    >
        <div class="container w-100">
            <template v-if="fields.items.length === 1">
                <div
                    v-for="quoteBlock in fields.items"
                    :key="quoteBlock.id"
                    class="d-flex flex-column-reverse flex-md-row row"
                    :class="{ 'justify-content-xl-center': isImageProvided }"
                >
                    <div
                        class="d-flex justify-content-start justify-content-md-end align-items-start col-12 mt-3 mt-md-0"
                        :class="{
                            'justify-content-xl-center': isImageProvided,
                            'col-md-0 col-lg-1': !isImageProvided && !isExperienceEditor,
                            'col-md-2': isImageProvided || isExperienceEditor,
                        }"
                    >
                        <WrappedImage
                            v-if="isExperienceEditor || isImageProvided"
                            :media="quoteBlock.fields.image"
                            :srcSet="[{ mw: 100 }, { mw: 200 }, { mw: 300 }]"
                            sizes="
                                (max-width: 575px) 20vw,
                                (max-width: 767px) 95px,
                                (max-width: 991px) 90px,
                                (max-width: 1199px) 15vw,
                                170px
                            "
                        />
                        <div class="d-flex flex-column d-md-none">
                            <ScText
                                v-if="
                                    isExperienceEditor ||
                                    (quoteBlock.fields &&
                                        quoteBlock.fields.authorName &&
                                        quoteBlock.fields.authorName.value)
                                "
                                :field="quoteBlock.fields.authorName"
                                tag="p"
                                class="background-to-change footnote"
                            />
                            <ScText
                                v-if="
                                    isExperienceEditor ||
                                    (quoteBlock.fields &&
                                        quoteBlock.fields.authorTitle &&
                                        quoteBlock.fields.authorTitle.value)
                                "
                                :field="quoteBlock.fields.authorTitle"
                                tag="p"
                                class="background-to-change caption"
                            />
                        </div>
                    </div>
                    <div class="d-flex flex-column col-md-10 col-xl-8">
                        <ScText
                            v-if="
                                isExperienceEditor ||
                                (quoteBlock.fields && quoteBlock.fields.quotation && quoteBlock.fields.quotation.value)
                            "
                            :field="quoteBlock.fields.quotation"
                            tag="blockquote"
                            class="background-to-change h3"
                        />
                        <ScText
                            v-if="
                                isExperienceEditor ||
                                (quoteBlock.fields &&
                                    quoteBlock.fields.authorName &&
                                    quoteBlock.fields.authorName.value)
                            "
                            :field="quoteBlock.fields.authorName"
                            tag="p"
                            class="d-none d-md-flex mt-3 background-to-change footnote"
                        />
                        <ScText
                            v-if="
                                isExperienceEditor ||
                                (quoteBlock.fields &&
                                    quoteBlock.fields.authorTitle &&
                                    quoteBlock.fields.authorTitle.value)
                            "
                            :field="quoteBlock.fields.authorTitle"
                            tag="p"
                            class="d-none d-md-flex background-to-change caption"
                        />
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="row">
                    <div class="col col-xl-8 col-md-10" :class="{ 'col-xl-8 col-md-10': fields.items.length === 1 }">
                        <ScText
                            v-if="isExperienceEditor || haveTitleValue"
                            tag="h3"
                            class="mb-3"
                            :field="fields.title"
                        />
                    </div>
                </div>
                <div class="row d-flex">
                    <div v-for="quoteBlock in fields.items" :key="quoteBlock.id" class="col-12 col-md mt-5 mt-md-0">
                        <ScText
                            v-if="
                                isExperienceEditor ||
                                (quoteBlock.fields && quoteBlock.fields.quotation && quoteBlock.fields.quotation.value)
                            "
                            tag="blockquote"
                            class="body-default text-grey"
                            :field="quoteBlock.fields.quotation"
                        />
                        <ScText
                            :field="quoteBlock.fields.authorName"
                            tag="p"
                            class="mt-2 background-to-change footnote"
                        />
                        <ScText
                            :field="quoteBlock.fields.authorTitle"
                            tag="p"
                            class="background-to-change caption text-grey"
                        />
                    </div>
                </div>
            </template>
        </div>
    </section>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import WrappedImage from '@/components/Generic/SitecoreWrappers/WrappedImage.vue';

export default {
    name: 'QuotationBlocks',
    components: {
        ScText: Text,
        WrappedImage,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        isExperienceEditor() {
            return this.$jss.sitecoreContext().pageEditing;
        },
        haveTitleValue() {
            return this.fields?.title?.value;
        },
        isImageProvided() {
            return this.fields?.items[0]?.fields?.image?.value?.src;
        },
        backgroundClass() {
            return this.rendering?.params?.background || 'background-digital-deep-grey';
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
.h3 {
    font-size: 28px;
    line-height: 1.14;
    letter-spacing: 0.01em;
    @include media-breakpoint-down(lg) {
        font-size: 28px;
        line-height: 32px;
    }
    @include media-breakpoint-down(sm) {
        font-size: 24px;
        letter-spacing: 0.01em;
        line-height: 1.33;
    }
}
::v-deep img {
    width: 100%;
    height: auto;
    object-fit: contain;
    @include media-breakpoint-down(md) {
        width: 18.555%;
        margin-right: 30px;
    }
}

.footnote {
    font-weight: 600;
}
</style>
