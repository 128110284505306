<template>
    <svg
        width="108"
        height="108"
        viewBox="0 0 108 108"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
    >
        <path
            d="M54 22.0317C71.6127 22.0317 85.9683 36.3873 85.9683 54C85.9683 71.6127 71.6127 85.9683 54 85.9683C36.3873 85.9683 22.0317 71.6127 22.0317 54C22.0317 36.3873 36.3873 22.0317 54 22.0317ZM54 16C33.0095 16 16 33.0095 16 54C16 74.9905 33.0095 92 54 92C74.9905 92 92 74.9905 92 54C92 33.0095 74.9905 16 54 16Z"
            fill="currentColor"
        />
        <path
            d="M47.0333 71.7936C46.1888 71.7936 45.3444 71.5524 44.5904 71.1301C43.0825 70.2555 42.1777 68.6571 42.1777 66.9381V41.0619C42.1777 39.3428 43.1127 37.7143 44.5904 36.8698C46.0984 35.9952 47.9381 35.9952 49.446 36.8698L71.8539 49.8079C73.3619 50.6825 74.2666 52.2809 74.2666 54C74.2666 55.719 73.3317 57.3476 71.8238 58.192L49.4158 71.1301C48.7523 71.5825 47.9079 71.7936 47.0333 71.7936ZM48.2095 43.0825V64.8873L67.119 54L48.2095 43.0825Z"
            fill="currentColor"
        />
    </svg>
</template>

<script>
export default {
    name: 'IconWebinar',
};
</script>

<style scoped></style>
