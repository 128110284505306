<script>
export default {
    methods: {
        findComponentInTree(data, activity, setTo) {
            if (!Array.isArray(data)) {
                if (data.componentName == activity) {
                    this[setTo] = data;
                } else if (data.placeholders?.length) {
                    Object.keys(data.placeholders).forEach((placeholder) => {
                        this.findComponentInTree(data.placeholders[placeholder], activity, setTo);
                    });
                }
            } else {
                data.forEach((component) => {
                    if (component.componentName == activity) {
                        this[setTo] = component;
                    } else {
                        component?.placeholders &&
                            Object.keys(component?.placeholders).length &&
                            Object.keys(component?.placeholders).forEach((placeholder) => {
                                this.findComponentInTree(component.placeholders[placeholder], activity, setTo);
                            });
                    }
                });
            }
        },
    },
};
</script>
<style>
.collapse:not(.show) {
    height: 0;
    pointer-events: none;
}
</style>
