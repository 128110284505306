import axios from 'axios';

import { getApiHost } from '@/Service.api';

const apiHost = process.env.NODE_ENV === 'development' ? 'https://tst-jss.abrdn.com' : getApiHost();
export function getTableData(data) {
    return axios.post(`${apiHost}/api/funds/kidhub`, data);
}

export function getPredictivePerformance(data) {
    return axios.post(`${apiHost}/api/funds/performance/predicted`, data);
}
export function getPastPerformanceData(shareclassId, displayLanguage, countryCode) {
    const data = {
        shareclassId,
        language: displayLanguage,
        countryCode,
    };
    return axios.post(`${apiHost}/api/funds/performance/past`, data);
}
