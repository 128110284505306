export default {
    name: 'DataLayerHandler',
    methods: {
        pushDataLayerEvent(data) {
            if (typeof window !== 'undefined') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push(data);
            }
        },
    },
};
