<template>
    <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
        <path
            d="M8.24269 0.354004L6.91342 1.68327H11.3665L4.45427 8.529C4.18842 8.79486 4.18842 9.19364 4.45427 9.45949C4.72013 9.72535 5.11891 9.72535 5.38476 9.45949L12.297 2.68022V7.13327L13.6262 5.804V0.354004H8.24269Z"
            fill="currentColor"
        />
        <path
            d="M12.9604 8.994C12.5616 8.994 12.2957 9.25986 12.2957 9.65864V12.3172H1.32927V1.68303H4.98476C5.38354 1.68303 5.64939 1.41717 5.64939 1.01839C5.64939 0.619613 5.38354 0.35376 4.98476 0.35376H0.664634C0.265854 0.35376 0 0.619613 0 1.01839V12.9818C0 13.3806 0.265854 13.6464 0.664634 13.6464H12.9604C13.3591 13.6464 13.625 13.3806 13.625 12.9818V9.65864C13.625 9.32632 13.2927 8.994 12.9604 8.994Z"
            fill="currentColor"
        />
    </svg>
</template>

<script>
export default {
    name: 'IconExternal',
};
</script>

<style scoped></style>
