import { UTCFormatShort } from '@/constants/dateFormats';

const sanitizeUrl = require('@braintree/sanitize-url').sanitizeUrl;
const asiaPacificCountryCodes = ['AUS', 'CHL', 'CHN', 'HKG', 'JPN', 'MYS', 'NZL', 'PER', 'SGP', 'KOR', 'TWN', 'THA'];

export const backgroundClass = {
    computed: {
        backgroundClass() {
            return this.rendering?.params?.background || 'background-white';
        },
    },
};

export const hasPlaceholders = {
    computed: {
        hasPlaceholders() {
            return (
                this.rendering?.placeholders &&
                Object.keys(this.rendering?.placeholders)
                    .map((key) => {
                        return this.rendering?.placeholders[key];
                    })
                    .flat(Infinity).length
            );
        },
    },
};

export const isPlaceholderNotEmpty = {
    methods: {
        isPlaceholderNotEmpty(placeholderName) {
            return (
                this.rendering?.placeholders?.[placeholderName] && this.rendering.placeholders[placeholderName].length
            );
        },
    },
};

export const localizeDate = {
    methods: {
        localizeDate(timestamp, options = UTCFormatShort) {
            if (!timestamp) return '';
            const isMidNight = timestamp.toString().includes('T00:00:00Z');
            if (isMidNight) {
                const timestampWithoutTime = timestamp.replace('T00:00:00Z', '');
                return new Intl.DateTimeFormat(this.$jss.sitecoreContext().displayLanguage, options).format(
                    new Date(Date.parse(timestampWithoutTime)),
                );
            }
            return new Intl.DateTimeFormat(this.$jss.sitecoreContext().displayLanguage, options).format(
                new Date(Date.parse(timestamp)),
            );
        },
    },
};

export const formatNumber = {
    methods: {
        formatNumber(number, displayType) {
            const isPercentage = number?.toString().includes('%') || displayType === 'percentage';
            if (typeof number === 'string') {
                // Remove the percentage sign and convert to a number
                number = parseFloat(number.replace('%', ''));
            }

            if (isNaN(number)) return '';

            const { displayLanguage } = this.$jss.sitecoreContext();
            const countryCode =
                this.$jss.sitecoreContext().fundsConfiguration?.countryInvestors.countryCode ||
                this.$jss.sitecoreContext().fundCentreConfiguration?.countryInvestors.countryCode;

            const fractionDigits = asiaPacificCountryCodes.includes(countryCode) ? 4 : 2;

            const options = {
                minimumFractionDigits: fractionDigits,
                maximumFractionDigits: fractionDigits,
            };

            if (isPercentage) {
                options.style = 'percent';
                // Convert number to decimal form for percentage formatting
                number /= 100;
            }

            const formatter = new Intl.NumberFormat(displayLanguage, options);

            return formatter.format(number);
        },
    },
};
export const formatCurrency = {
    methods: {
        formatCurrency(number, currency = 'GBP', notation = 'standard', fractionDigits = 2) {
            const isPercentage = number?.toString().includes('%');
            if (typeof number === 'string') {
                // Remove the percentage sign and convert to a number
                number = parseFloat(number.replace('%', ''));
            }
            if (isNaN(number) || !currency) return '';

            const { displayLanguage } = this.$jss.sitecoreContext();
            const countryCode =
                this.$jss.sitecoreContext().fundsConfiguration?.countryInvestors.countryCode ||
                this.$jss.sitecoreContext().fundCentreConfiguration?.countryInvestors.countryCode;

            const options = {
                minimumFractionDigits: asiaPacificCountryCodes.includes(countryCode) ? 4 : fractionDigits,
                maximumFractionDigits: asiaPacificCountryCodes.includes(countryCode) ? 4 : fractionDigits,
                notation: notation,
                currency,
            };

            if (isPercentage) {
                options.style = 'percent';
                // Convert number to decimal form for percentage formatting
                number /= 100;
            }

            const formatter = new Intl.NumberFormat(displayLanguage, options);

            const formattedPrice = formatter.format(number);

            // fix negative zeros
            if (Number(formattedPrice) === 0) {
                return formatter.format(0);
            }

            return formattedPrice;
        },
    },
};

export const sanitizeURL = {
    methods: {
        sanitizeURL(url) {
            return sanitizeUrl(url);
        },
    },
};

export const updateUrlParams = {
    methods: {
        /*
         * Payload sample: [
         *   {
         *     name: 'page',
         *     value: 2,
         *   }
         * ]
         * */
        updateUrlParams(arrayOfParams = []) {
            if (!this.isExperienceEditor && process.release?.name !== 'node') {
                const url = new URL(window.location.href);
                const searchParams = new URLSearchParams(url.search);
                if (arrayOfParams.length) {
                    arrayOfParams.forEach((param) => {
                        if (param.value) {
                            searchParams.set(param.name, param.value);
                        } else {
                            searchParams.delete(param.name);
                        }
                    });
                    url.search = searchParams.toString();
                    window.history.replaceState(null, '', url.href);
                }
                return Object.fromEntries(searchParams.entries());
            }
            return {};
        },
    },
};

export const isFocusWithin = {
    methods: {
        isFocusWithin(event) {
            if (!event.relatedTarget) return true;
            return event.currentTarget.contains(event.relatedTarget);
        },
    },
};

export function sitecoreLanguage() {
    return this.$jss.sitecoreContext()?.displayLanguage;
}

export function vectorResolver(virtualVectors, currentPathVector) {
    const relatedVectorType = virtualVectors.find((vectorType) => vectorType.vectorPath == currentPathVector);
    return relatedVectorType ? relatedVectorType.siteName : '';
}
