<template>
    <section class="default-section-padding">
        <div class="container">
            <AlertMessageBase v-if="error" type="error" :title="$t(error)" />
            <template v-else>
                <FundsFiltersSection v-if="withFilters" :filters="filters" class="mb-4 mb-lg-5" />
                <div class="funds-listing">
                    <FundsListingHeading
                        :take="paginationData.take"
                        :currentPage="currentPage"
                        :totalFunds="totalFunds"
                    />
                    <FundsTable />
                    <FundsListingFooter
                        :take="paginationData.take"
                        :currentPage="currentPage"
                        :totalFunds="totalFunds"
                        class="mt-3 mt-md-4"
                    />
                    <Pagination
                        v-if="withPagination"
                        :currentPage="currentPage"
                        :maxPages="maxPages"
                        class="justify-content-center mt-4 mt-md-8"
                        :isLoading="isLoading"
                        :showSkeleton="isLoading && !isPaginationLoading"
                        @onSelect="onPageSelect"
                    />
                </div>
            </template>
        </div>
    </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

import AlertMessageBase from '@/components/Generic/AlertMessageBase';
import Pagination from '@/components/Includes/Pagination';
import FundsFiltersSection from '@/components/Pages/FundsCenter/AllFunds/FundsFilters/FundsFiltersSection';
import FundsListingFooter from '@/components/Pages/FundsCenter/AllFunds/FundsListing/FundsListingFooter';
import FundsListingHeading from '@/components/Pages/FundsCenter/AllFunds/FundsListing/FundsListingHeading';
import FundsTable from '@/components/Pages/FundsCenter/AllFunds/FundsListing/FundsTable';
import { sitecoreLanguage, updateUrlParams } from '@/mixins/Helpers.mixin';

export default {
    name: 'FundsListing',
    components: {
        FundsFiltersSection,
        FundsListingHeading,
        FundsListingFooter,
        Pagination,
        FundsTable,
        AlertMessageBase,
    },
    mixins: [updateUrlParams],
    props: {
        countryCode: {
            type: String,
            required: true,
        },
        investorTypes: {
            type: String,
            required: true,
        },
        literatureAuthorization: {
            type: String,
            required: true,
        },
        filters: {
            type: Array,
            required: true,
            default: () => [],
        },
        jurisdiction: {
            type: String,
            required: true,
        },
        site: {
            type: String,
            required: true,
        },
        itemsPerPage: {
            type: Number,
            default: 20,
            required: true,
        },
        withFilters: {
            type: Boolean,
            default: true,
        },
        withPagination: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            currentPage: 1,
            maxPages: 1,
            isMounted: false,
        };
    },
    computed: {
        ...mapState('allFunds', [
            'allFundsCount',
            'fundsInFocusCount',
            'paginationData',
            'isLoading',
            'searchQuery',
            'isPaginationLoading',
            'appliedFilters',
            'selectedTab',
            'activeTabKey',
            'error',
        ]),
        sitecoreLanguage,
        totalFunds() {
            return this.activeTabKey === 0 ? this.allFundsCount : this.fundsInFocusCount;
        },
    },
    watch: {
        searchQuery: {
            handler: function () {
                if (!this.isMounted) return false;
                this.resetPagination();
                this.fetchFunds();
            },
            deep: true,
        },
        appliedFilters: {
            handler: function () {
                if (!this.isMounted) return false;
                this.resetPagination();
                this.fetchFunds();
            },
            deep: true,
        },
        selectedTab() {
            if (!this.isMounted) return false;
            this.resetPagination();
            this.fetchFunds();
        },
    },
    async mounted() {
        const { page, search } = this.updateUrlParams();
        this.currentPage = Number(page || 1);
        this.updatePaginationData();
        this.updateSearchData(search);
        await this.$nextTick();
        const allowedValues = ['overview', 'prices', 'performance'];
        const { table } = this.$route.query;

        if (allowedValues.includes(table)) {
            this.setSelectedTab(table);
        }
        this.fetchFunds();
        this.isMounted = true;
    },
    created() {
        const payload = {
            countryCode: this.countryCode,
            investorTypes: this.investorTypes,
            jurisdiction: this.jurisdiction,
            displayLanguage: this.sitecoreLanguage,
            site: this.site,
            literatureAuthorization: this.literatureAuthorization,
        };
        this.setRequestPayloadAssets(payload);
    },
    methods: {
        ...mapMutations('allFunds', ['setSelectedTab']),
        resetPagination() {
            this.currentPage = 1;
            this.updatePaginationData();
            this.updateUrlParams([
                {
                    name: 'page',
                    value: this.currentPage,
                },
            ]);
        },
        ...mapActions('allFunds', ['fetchFundsOverview', 'fetchFundsPrices']),
        ...mapMutations('allFunds', [
            'setPaginationData',
            'setFundsData',
            'setRequestPayloadAssets',
            'setSearchQuery',
            'setPaginationLoading',
        ]),
        async fetchFunds() {
            let fetchFunds;

            if (this.selectedTab === 'prices') {
                fetchFunds = this.fetchFundsPrices;
            } else {
                fetchFunds = this.fetchFundsOverview;
            }

            try {
                const data = await fetchFunds();
                if (!data) return;
                this.setFundsData(data);
                this.maxPages = data.lastPageNumber + 1;
            } catch (e) {
                console.error(e);
            }
        },
        async onPageSelect(page) {
            this.setPaginationLoading(true);
            this.currentPage = page;
            this.updatePaginationData();
            this.updateUrlParams([
                {
                    name: 'page',
                    value: this.currentPage,
                },
            ]);
            await this.fetchFunds();
            this.setPaginationLoading(false);
        },
        updatePaginationData() {
            this.setPaginationData({
                skip: (this.currentPage - 1) * this.itemsPerPage,
                take: this.itemsPerPage,
            });
        },
        updateSearchData(search) {
            this.setSearchQuery({
                id: '',
                name: search ? decodeURIComponent(search) : '',
            });
        },
    },
};
</script>

<style scoped lang="scss">
.loader {
    display: block;
    margin: 0 auto;
}
</style>
