<template>
    <div class="d-flex flex-column">
        <div
            class="d-inline-flex align-items-start checkbox-wrapper"
            :class="{ ['form-element--error']: isError }"
            tabindex="0"
            @click.stop="!disabled && setState()"
            @keyup.enter.stop="!disabled && setState()"
        >
            <div class="checkbox d-flex" :class="{ selected: isSelected, disabled: disabled }">
                <input
                    v-model="isSelected"
                    aria-label="checkbox"
                    type="checkbox"
                    :required="required"
                    @invalid="checkError"
                />
                <em v-if="isSelected" class="d-flex bi-check text-white" aria-hidden="true" />
            </div>
            <label v-if="$slots['label']" for="checkbox" class="label-slot ms-2" :class="{ disabled: disabled }">
                <slot name="label" />
            </label>
        </div>
        <span
            v-if="$slots['error'] && isError"
            class="error-message field-error-text text-error-400"
            :class="{ disabled: disabled }"
        >
            <slot name="error" />
        </span>
        <span v-if="$slots['instructions']" class="hint-text mt-1" :class="{ disabled: disabled }">
            <slot name="instructions" />
        </span>
    </div>
</template>

<script>
export default {
    name: 'Checkbox',
    props: {
        selected: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: () => false,
        },
    },
    data() {
        return {
            isSelected: this.selected,
            isError: false,
        };
    },
    methods: {
        setState() {
            this.isSelected = !this.isSelected;
            this.$emit('onChange', this.isSelected);
            this.$emit('updateDataLayer');
            this.isError = false;
            this.$emit('onError', false);
        },
        checkError(event) {
            if (this.$slots.error) {
                event.preventDefault();
            }
            switch (true) {
                case event.target.validity.valueMissing:
                    this.isError = true;
                    this.$emit('onError', true);
                    break;
                default:
                    this.$emit('onError', false);
                    this.isError = false;
                    break;
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
@import '@/assets/styles/mixins';
.checkbox {
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 24px;
    min-height: 24px;
    box-shadow: inset 0px 0px 0px 1px var(--button-night);
    @include dark-backgrounds() {
        box-shadow: inset 0px 0px 0px 1px var(--button-white);
    }
    &.selected {
        background: var(--abrdn-black);
    }
    &:hover:not(.selected):not(.disabled),
    &:focus:not(.selected):not(.disabled) {
        background: var(--button-mist);
        box-shadow: inset 0px 0px 0px 2px rgba(0, 0, 0, 0.5);
    }
    &:hover.selected {
        background: var(--button-night);
    }
    &.disabled {
        border-color: rgba(63, 63, 63, 0.5) !important;
        box-shadow: inset 0px 0px 0px 1px rgba(63, 63, 63, 0.5);
    }
    &.selected.disabled {
        background: rgba(0, 0, 0, 0.5);
        border-color: rgba(0, 0, 0, 0.5);
        box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.5);
    }
}

.disabled {
    color: var(--button-mist);
}
.bi-check {
    font-size: 22px;
}

.checkbox-wrapper {
    cursor: pointer;
    position: relative;
    margin-bottom: 16px;
    @include media-breakpoint-down(lg) {
        outline: 0px;
    }
}

.form-element--error {
    display: block;
    width: 100%;
    margin-bottom: 24px;
    .checkbox {
        background: transparent;
        box-shadow: inset 0px 0px 0px 2px var(--error-400);
        @include dark-backgrounds() {
            background: var(--error-200);
            outline: 2px solid var(--error-200);
            box-shadow: inset 0px 0px 0px 2px var(--error-400);
            &:hover:not(.selected):not(.disabled) {
                background: var(--button-mist);
                box-shadow: inset 0px 0px 0px 2px rgba(0, 0, 0, 0.5);
            }
        }
    }
    + .error-message {
        @include dark-backgrounds() {
            padding: 4px;
            background-color: $abrdn-white;
        }
    }
}

input {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
}
.label-slot * {
    line-height: 24px !important;
    cursor: pointer;
}
</style>
