<template>
    <div v-if="visible" class="jss-loading">
        <div class="loader">Loading...</div>
    </div>
</template>

<script>
export default {
    props: {
        delay: {
            type: Number,
            default: 300,
        },
    },
    data() {
        return {
            visible: false,
        };
    },
    created() {
        // Wait {this.delay}ms before making the loading graphic visible.
        // If whatever other async operation completes within that timeframe,
        // then whichever parent component invoked the loading component will
        // presumably re-render without the loading component.
        // This helps prevent the loading graphic from too briefly "flashing" on the screen
        // causing an undesirable flicker for users.
        setTimeout(() => {
            this.visible = true;
        }, this.delay);
    },
};
</script>

<style scoped>
.jss-loading {
    display: flex;
    position: absolute;
    width: 100vw;
    height: calc(100vh - 100px);
    background: #ffffff;
    justify-content: center;
    align-items: center;
}

.loader,
.loader:before,
.loader:after {
    border-radius: 50%;
}

.loader {
    color: var(--digital-steel-grey);
    font-size: 11px;
    text-indent: -99999em;
    margin: 55px auto;
    position: relative;
    width: 10em;
    height: 10em;
    box-shadow: inset 0 0 0 1em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.loader:before,
.loader:after {
    position: absolute;
    content: '';
}

.loader:before {
    width: 5.2em;
    height: 10.2em;
    background: #ffffff;
    border-radius: 10.2em 0 0 10.2em;
    top: -0.1em;
    left: -0.1em;
    -webkit-transform-origin: 5.1em 5.1em;
    transform-origin: 5.1em 5.1em;
    -webkit-animation: load2 1.5s infinite ease 1s;
    animation: load2 1.5s infinite ease 1s;
}

.loader:after {
    width: 5.2em;
    height: 10.2em;
    background: #ffffff;
    border-radius: 0 10.2em 10.2em 0;
    top: -0.1em;
    left: 4.9em;
    -webkit-transform-origin: 0.1em 5.1em;
    transform-origin: 0.1em 5.1em;
    -webkit-animation: load2 1.5s infinite ease;
    animation: load2 1.5s infinite ease;
}
@-webkit-keyframes load2 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load2 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
</style>
