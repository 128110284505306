<template>
    <svg viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.29592 1.49834C8.53582 0.117943 6.55211 0.117945 5.79201 1.49834L0.910807 10.3629C0.176842 11.6958 1.14112 13.3276 2.66277 13.3276H12.4252C13.9468 13.3276 14.9111 11.6958 14.1771 10.3629L9.29592 1.49834ZM6.66797 2.95882C7.04803 2.26862 8.03988 2.26863 8.41993 2.95882L12.5617 10.4805C12.9287 11.147 12.4465 11.9629 11.6857 11.9629H3.40218C2.64136 11.9629 2.15922 11.147 2.5262 10.4805L6.66797 2.95882ZM8.40333 7.69207C8.40333 8.16635 8.01884 8.55083 7.54456 8.55083C7.07028 8.55083 6.68579 8.16635 6.68579 7.69206V5.90055C6.68579 5.42627 7.07028 5.04179 7.54456 5.04179C8.01884 5.04179 8.40333 5.42627 8.40333 5.90056V7.69207ZM7.62354 10.8906C8.05421 10.8906 8.40333 10.5415 8.40333 10.1108C8.40333 9.68018 8.05421 9.33105 7.62354 9.33105H7.46558C7.03491 9.33105 6.68579 9.68018 6.68579 10.1108C6.68579 10.5415 7.03491 10.8906 7.46558 10.8906H7.62354Z"
            fill="currentColor"
        />
    </svg>
</template>

<script>
export default {
    name: 'IconWarning',
};
</script>

<style scoped></style>
