<template>
    <FormStep :stepData="formFields" />
</template>

<script>
import FormStep from '@/components/Sections/Forms/Form-LPP48/FormStepTemplate';

export default {
    name: 'Step4',
    components: { FormStep },
    props: {
        formFields: {
            type: Object,
            default: () => {},
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep .radioFieldSet {
    display: inline-block !important;
    .radio-button-wrapper {
        margin-bottom: 15px;
        &:nth-child(4) {
            margin-bottom: 0;
        }
    }
}
</style>
