<template>
    <section v-if="article" :id="anchorId" class="container py-3 py-md-5 py-lg-8">
        <div class="row flex-lg-row flex-column-reverse">
            <div class="col-12 col-lg-6">
                <div v-if="article.contentGroup || article.category" class="d-flex align-items-center gap-2">
                    <div v-if="article.contentGroup" class="h5 text-nowrap">{{ article.contentGroup }}</div>
                    <Badge v-if="article.category">{{ article.category }}</Badge>
                </div>

                <ScText tag="h1" class="h2 mt-2" :field="article.title" />
                <ScText tag="p" class="body-large text-grey mt-2 mt-md-3 mt-lg-2" :field="article.strapline" />

                <div v-if="authorsLength || article.contributors" class="row">
                    <div class="col-12 col-md-9 col-lg-12">
                        <ArticleAuthors v-if="authorsLength" :authors="article.authors" class="mt-3" />
                        <ArticleContributors
                            v-if="article.contributors"
                            :contributors="article.contributors"
                            class="mt-3"
                        />
                    </div>
                </div>
            </div>

            <div class="col-12 col-lg-6 mb-2 mb-lg-0">
                <WrappedImage
                    :media="article.image"
                    class="article-hero-image"
                    :srcSet="[{ mw: 400 }, { mw: 600 }, { mw: 800 }, { mw: 1000 }, { mw: 1200 }]"
                    sizes="
                        (max-width: 575px) 100vw,
                        (max-width: 767px) 510px,
                        (max-width: 991px) 690px,
                        (max-width: 1199px) 50vw,
                        570px
                    "
                    :lazy="false"
                />
            </div>
        </div>
    </section>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import WrappedImage from '@/components/Generic/SitecoreWrappers/WrappedImage';
import Badge from '@/components/Includes/Badge';

import ArticleAuthors from './ArticleAuthors';
import ArticleContributors from './ArticleContributors';

export default {
    name: 'ArticleHeroImageRight',
    components: {
        ArticleAuthors,
        ArticleContributors,
        Badge,
        ScText: Text,
        WrappedImage,
    },
    props: {
        article: {
            type: Object,
            default: null,
        },
    },
    computed: {
        authorsLength() {
            return this.article?.authors?.length;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';

.article-hero-image,
.article-hero-image ::v-deep img {
    width: 100% !important;
    height: 100% !important;
    min-height: 360px !important;
    max-height: 360px !important;
    object-fit: cover !important;

    @include media-breakpoint-down(lg) {
        min-height: 436px !important;
        max-height: 436px !important;
    }

    @include media-breakpoint-down(md) {
        min-height: 218px !important;
        max-height: 218px !important;
    }
}

.body-large {
    font-weight: 500;
}
</style>
