<template>
    <section :id="anchorId" :data-e2e-component-uid="rendering.uid" class="default-section-margin short-section">
        <div class="container">
            <Placeholder class="mb-4" :rendering="rendering" name="abrdn-tiles-heading" />
            <Placeholder
                :rendering="rendering"
                name="abrdn-customer-reviews"
                class="row margin-top-row"
                :gridData="gridData"
            />
        </div>
    </section>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';

export default {
    name: 'CustomerReviewsSection',
    components: { Placeholder },
    props: {
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        gridData() {
            const components = this.rendering.placeholders['abrdn-customer-reviews'];
            const columns = components.map((component) => Number(component.params?.size || 12));
            return {
                count: components.length,
                columns,
            };
        },
    },
};
</script>

<style scoped></style>
