<script>
import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton';
import AbrdnSelect from '@/components/Generic/FormElements/FormFields/AbrdnSelect';
import Checkbox from '@/components/Generic/FormElements/FormFields/Checkbox';
import DatePicker from '@/components/Generic/FormElements/FormFields/DatePicker';
import InputField from '@/components/Generic/FormElements/FormFields/InputField';
import MultipleCheckboxes from '@/components/Generic/FormElements/FormFields/MultipleCheckboxes';
import MultiplePickList from '@/components/Generic/FormElements/FormFields/MultiplePickList';
import Note from '@/components/Generic/FormElements/FormFields/Note';
import RadioButtonFieldset from '@/components/Generic/FormElements/FormFields/RadioButtonFieldset';

export default {
    name: 'FormsFieldsSharedLogic',
    components: {
        AbrdnSelect,
        Checkbox,
        InputField,
        RadioButtonFieldset,
        MultiplePickList,
        AbrdnButton,
        MultipleCheckboxes,
        Note,
        DatePicker,
    },

    props: {
        element: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            formStartEventExistInDataLayer: false,
        };
    },

    computed: {
        displayType() {
            return this.element?.displayType;
        },
        name() {
            return this.element?.name;
        },
        htmlName() {
            return this.element?.htmlName;
        },
        id() {
            return this.element?.id;
        },
        instructions() {
            return this.element?.instructions;
        },
        description() {
            return this.element?.description;
        },
        validations() {
            return this.element?.validations;
        },
        optionList() {
            return this.element?.optionList;
        },
        conditional() {
            return this.element?.conditionalField || false;
        },
        relatesTo() {
            return this.element?.relatesTo;
        },
        formattedFields() {
            return {
                label: {
                    value: this.isRequired ? this.name + '*' : this.name,
                },
                placeholder: {
                    value: '',
                },
                errorMessages: this.errorMessages,
                name: {
                    value: this.htmlName,
                },
                hint: {
                    value: '',
                },
                tooltip: {
                    value: '',
                },
                options: this.options,
                required: {
                    value: this.isRequired,
                },
                type: {
                    value: this.inputType,
                },
                min: {
                    value: this.minValue,
                },
                max: {
                    value: this.maxValue,
                },
                conditionalField: {
                    value: this.conditional,
                },
                relatesTo: {
                    value: this.relatesTo,
                },
            };
        },
        isRequired() {
            return !!this.validations?.some(({ condition }) => condition.type === 'IsRequiredCondition');
        },
        minValue() {
            return this.validations?.find(({ condition }) => condition.type === 'TextLengthCondition')?.condition
                ?.minimum;
        },
        maxValue() {
            return this.validations?.find(({ condition }) => condition.type === 'TextLengthCondition')?.condition
                ?.maximum;
        },
        requiredErrorMessage() {
            return this.validations?.find(({ condition }) => condition.type === 'IsRequiredCondition')?.message;
        },
        errorMessages() {
            return {
                valueMissing: this.validations?.find(({ condition }) => condition.type === 'IsRequiredCondition')
                    ?.message,
                typeMismatch: this.validations?.find(({ condition }) => condition.type === 'IsEmailAddressCondition')
                    ?.message,
                tooLong: this.validations?.find(({ condition }) => condition.type === 'TextLengthCondition')?.message,
                tooShort: this.validations?.find(({ condition }) => condition.type === 'TextLengthCondition')?.message,
            };
        },
        inputType() {
            switch (this.htmlName) {
                case 'emailAddress':
                    return 'email';
                case 'singleCheckbox':
                    return 'checkbox';
                case 'number':
                    return 'number';
                default:
                    return 'text';
            }
        },
        options() {
            const optionListElements = this.optionList?.elements;
            if (optionListElements) {
                optionListElements.map((opt) => {
                    opt.name = opt.value;
                });
                return { value: optionListElements };
            } else {
                return {};
            }
        },
    },
};
</script>
<style lang="scss">
.form-field-list {
    margin-bottom: 5px;
}
.form-field-list:last-of-type {
    margin-bottom: 28px;
}
.form-field-header {
    margin-bottom: 28px;
}
.relative {
    position: relative;
}
.message-content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}
.success-description {
    max-width: 670px;
}
</style>
