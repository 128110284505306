<template>
    <section class="py-3">
        <div class="container">
            <ScText v-if="fields.title" tag="h3" :field="fields.title" class="mb-3" />
            <DocumentListingBase :editionId="fields.editionId && fields.editionId.value" />
        </div>
    </section>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import DocumentListingBase from '@/components/Generic/DocumentListingBase';

export default {
    name: 'AutomaticDocumentListings',

    components: {
        ScText: Text,
        DocumentListingBase,
    },

    props: {
        fields: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>
