<template>
    <ArticleHeroImageRight :data-e2e-component-uid="rendering.uid" :article="article" />
</template>

<script>
import ArticleHeroImageRight from '@/components/Sections/Banners/ArticleHero/ArticleHeroImageRight';
import ArticleTypes from '@/constants/article';

export default {
    name: 'ArticleHero',
    components: {
        ArticleHeroImageRight,
    },
    props: {
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    data() {
        return {
            article: null,
            articleTypes: ArticleTypes,
        };
    },
    mounted() {
        const { fields, templateId } = this.$jss.routeData();
        const contentGroup = this.$t(this.articleTypes[templateId.replace(/-/g, '')]);

        this.article = {
            image: fields?.articleImage,
            contentGroup,
            category: fields?.articleCategory?.fields?.name?.value,
            title: fields?.articleTitle,
            strapline: fields?.articleStrapline,
            authors: fields?.articleAuthor?.map?.((author) => ({
                image: {
                    src: author?.fields?.jobImage?.value?.src,
                    alt: author?.fields?.jobImage?.value?.alt,
                },
                name: author?.fields?.name?.value,
                jobTitle: author?.fields?.jobTitle?.value,
            })),
        };
    },
};
</script>

<style scoped lang="scss"></style>
