var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"default-section-padding",class:( _obj = {}, _obj[_vm.backgroundClass] = _vm.fields.items.length === 1, _obj ),attrs:{"id":_vm.anchorId,"data-e2e-component-uid":_vm.rendering.uid}},[_c('div',{staticClass:"container w-100"},[(_vm.fields.items.length === 1)?_vm._l((_vm.fields.items),function(quoteBlock){return _c('div',{key:quoteBlock.id,staticClass:"d-flex flex-column-reverse flex-md-row row",class:{ 'justify-content-xl-center': _vm.isImageProvided }},[_c('div',{staticClass:"d-flex justify-content-start justify-content-md-end align-items-start col-12 mt-3 mt-md-0",class:{
                        'justify-content-xl-center': _vm.isImageProvided,
                        'col-md-0 col-lg-1': !_vm.isImageProvided && !_vm.isExperienceEditor,
                        'col-md-2': _vm.isImageProvided || _vm.isExperienceEditor,
                    }},[(_vm.isExperienceEditor || _vm.isImageProvided)?_c('WrappedImage',{attrs:{"media":quoteBlock.fields.image,"srcSet":[{ mw: 100 }, { mw: 200 }, { mw: 300 }],"sizes":"\n                            (max-width: 575px) 20vw,\n                            (max-width: 767px) 95px,\n                            (max-width: 991px) 90px,\n                            (max-width: 1199px) 15vw,\n                            170px\n                        "}}):_vm._e(),_c('div',{staticClass:"d-flex flex-column d-md-none"},[(
                                _vm.isExperienceEditor ||
                                (quoteBlock.fields &&
                                    quoteBlock.fields.authorName &&
                                    quoteBlock.fields.authorName.value)
                            )?_c('ScText',{staticClass:"background-to-change footnote",attrs:{"field":quoteBlock.fields.authorName,"tag":"p"}}):_vm._e(),(
                                _vm.isExperienceEditor ||
                                (quoteBlock.fields &&
                                    quoteBlock.fields.authorTitle &&
                                    quoteBlock.fields.authorTitle.value)
                            )?_c('ScText',{staticClass:"background-to-change caption",attrs:{"field":quoteBlock.fields.authorTitle,"tag":"p"}}):_vm._e()],1)],1),_c('div',{staticClass:"d-flex flex-column col-md-10 col-xl-8"},[(
                            _vm.isExperienceEditor ||
                            (quoteBlock.fields && quoteBlock.fields.quotation && quoteBlock.fields.quotation.value)
                        )?_c('ScText',{staticClass:"background-to-change h3",attrs:{"field":quoteBlock.fields.quotation,"tag":"blockquote"}}):_vm._e(),(
                            _vm.isExperienceEditor ||
                            (quoteBlock.fields &&
                                quoteBlock.fields.authorName &&
                                quoteBlock.fields.authorName.value)
                        )?_c('ScText',{staticClass:"d-none d-md-flex mt-3 background-to-change footnote",attrs:{"field":quoteBlock.fields.authorName,"tag":"p"}}):_vm._e(),(
                            _vm.isExperienceEditor ||
                            (quoteBlock.fields &&
                                quoteBlock.fields.authorTitle &&
                                quoteBlock.fields.authorTitle.value)
                        )?_c('ScText',{staticClass:"d-none d-md-flex background-to-change caption",attrs:{"field":quoteBlock.fields.authorTitle,"tag":"p"}}):_vm._e()],1)])}):[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-xl-8 col-md-10",class:{ 'col-xl-8 col-md-10': _vm.fields.items.length === 1 }},[(_vm.isExperienceEditor || _vm.haveTitleValue)?_c('ScText',{staticClass:"mb-3",attrs:{"tag":"h3","field":_vm.fields.title}}):_vm._e()],1)]),_c('div',{staticClass:"row d-flex"},_vm._l((_vm.fields.items),function(quoteBlock){return _c('div',{key:quoteBlock.id,staticClass:"col-12 col-md mt-5 mt-md-0"},[(
                            _vm.isExperienceEditor ||
                            (quoteBlock.fields && quoteBlock.fields.quotation && quoteBlock.fields.quotation.value)
                        )?_c('ScText',{staticClass:"body-default text-grey",attrs:{"tag":"blockquote","field":quoteBlock.fields.quotation}}):_vm._e(),_c('ScText',{staticClass:"mt-2 background-to-change footnote",attrs:{"field":quoteBlock.fields.authorName,"tag":"p"}}),_c('ScText',{staticClass:"background-to-change caption text-grey",attrs:{"field":quoteBlock.fields.authorTitle,"tag":"p"}})],1)}),0)]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }