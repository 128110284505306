<template>
    <RelatedStoriesBasic
        v-if="parentIsHelpArticlePlaceholder"
        :id="anchorId"
        :title="$t('helpMoreFrom')"
        :fields="fields"
        :rendering="rendering"
    />
    <RelatedHelpArticlesSection
        v-else
        :id="anchorId"
        class="d-none d-md-block"
        :fields="fields"
        :rendering="rendering"
    />
</template>

<script>
import RelatedStoriesBasic from '@/components/Pages/ArticleParts/RelatedStoriesBasic';
import RelatedHelpArticlesSection from '@/components/Sections/RelatedArticlesVariations/RelatedHelpArticlesSection';

export default {
    name: 'RelatedTaggedHelp',
    components: { RelatedHelpArticlesSection, RelatedStoriesBasic },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        parentIsHelpArticlePlaceholder() {
            return this.$parent.name === 'abrdn-article-related-tag-help';
        },
    },
};
</script>

<style scoped lang="scss"></style>
