<template>
    <component
        :is="as"
        ref="megaNavMenuItem"
        class="px-2"
        tabindex="-1"
        @focusout="onFocusOut"
        @keydown.esc="
            closeMegaNavBody();
            restoreFocus();
        "
    >
        <template v-if="category.hasLinks || isExperienceEditor">
            <div
                ref="overlay"
                role="button"
                aria-label="overlay to close mega navigation"
                class="overlay d-none d-xl-block"
                :class="{ active }"
                tabindex="-1"
                @click="
                    closeMegaNavBody();
                    restoreFocus();
                "
            />
            <button
                class="btn btn-naked header-link meganav__button"
                :class="{ active }"
                :aria-expanded="active ? 'true' : 'false'"
                @keydown.enter="onMegaNavButtonClick($event, category)"
                @mousedown="onMegaNavButtonClick($event, category)"
            >
                <ScText class="meganav__button__text" tag="span" :field="category.fields.title" />
                <IconChevron class="meganav__button__icon" />
            </button>
            <MegaNavigationBody
                :category="category"
                :active="active"
                @closeMegaNavBody="closeMegaNavBody"
                @restoreFocus="restoreFocus"
            />
        </template>
        <ScLink
            v-else-if="!category.hasLinks && category.directLink"
            class="btn btn-naked header-link meganav__button"
            :field="category.directLink"
            @click.native="closeMegaNavBody"
        >
            <span class="meganav__button__text">{{ category.directLink.value.text }}</span>
        </ScLink>
    </component>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import MegaNavigationBody from '@/components/Generic/Header/MegaNavigation/MegaNavigationBody';
import ScLink from '@/components/Generic/SitecoreWrappers/ScLink';
import IconChevronSecondLevelTabs from '@/components/Icons/icon-chevron-second-level-tabs';
import DisableScroll from '@/mixins/DisableScroll.mixin';

export default {
    name: 'MegaNavigationMenuItem',
    components: {
        MegaNavigationBody,
        ScLink,
        ScText: Text,
        IconChevron: IconChevronSecondLevelTabs,
    },
    mixins: [DisableScroll],
    props: {
        as: {
            type: String,
            default: 'li',
        },
        active: {
            type: Boolean,
            default: false,
        },
        category: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    data() {
        return {
            lastClickedMenuItem: null,
        };
    },
    methods: {
        onMegaNavButtonClick(event, category) {
            if (this.active) {
                this.closeMegaNavBody();
            } else {
                this.lastClickedMenuItem = event.currentTarget;
                /*
                 * NOTE: `setTimeout` is necessary because the code inside
                 * of it has to be called later than `onMegaNavBodyFocusOut`.
                 */
                setTimeout(() => {
                    event.preventDefault();
                    this.$emit('setActiveCategory', category);
                    this.toggleWindowScroll(true);
                }, 10);
            }
        },
        onFocusOut() {
            /*
             * NOTE: `setTimeout` is necessary because `document.activeElement`
             * is not set immediately to the newly focused element after
             * `focusout` event was triggered.
             */
            setTimeout(() => {
                if (
                    !this.$refs.megaNavMenuItem.contains(document.activeElement) &&
                    document.activeElement !== this.$refs.overlay
                ) {
                    this.closeMegaNavBody();
                }
            });
        },
        closeMegaNavBody() {
            this.$emit('setActiveCategory', null);
            this.toggleWindowScroll(false);
        },
        restoreFocus() {
            this.lastClickedMenuItem?.focus();
        },
    },
};
</script>

<style scoped lang="scss">
.overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    pointer-events: none;
    z-index: -1;
    outline: none;
}

.overlay.active {
    opacity: 1;
    pointer-events: all;
}

li,
a,
.meganav__button,
.meganav__button__text {
    height: 100%;
}

.meganav__button,
.meganav__button__text {
    display: flex;
    align-items: center;
}

.meganav__button__text {
    position: relative;
}

.meganav__button__text::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 4px;
    background-color: var(--button-night);
    opacity: 0;
    transition: opacity 0.2s;
}

html[data-page-theme^='financialfairness'] .meganav__button__text::before {
    background-color: var(--primary);
}

.meganav__button.active .meganav__button__text::before,
.meganav__button:hover .meganav__button__text::before,
.meganav__button:focus .meganav__button__text::before {
    opacity: 1;
}

.meganav__button__icon {
    width: calc(var(--spacer) * 3);
    transition: transform 0.2s;
}

.meganav__button.active .meganav__button__icon {
    transform: rotate(180deg);
}
</style>
