<template>
    <div v-if="chapters.length || isExperienceEditor" :data-e2e-component-uid="rendering.uid" class="w-100 realtive">
        <div ref="chapters-wrapper" class="chapters-wrapper mb-6 mb-xl-0">
            <nav :style="{ width: `${sidebarWidth}px` }">
                <h3 class="mb-5">{{ $t('articleChaptersTitle') }}</h3>
                <div class="flex flex-column w-100">
                    <div v-for="(title, i) in fields.items" :key="`${title.fields.anchor.value}-${i}`">
                        <p
                            v-if="title.fields.title && title.fields.title.value"
                            class="title text-uppercase py-1 ps-3"
                            :tabindex="0"
                            :class="{ active: activeSubtitle === title.fields.anchor.value }"
                            @click="getScrollToSectionId(title)"
                            @keyup.enter="getScrollToSectionId(title)"
                        >
                            {{ title.fields.title.value }}
                        </p>
                    </div>
                </div>
            </nav>
        </div>
        <div v-show="showDropdown" class="chapters-dropdown flex-column" :style="{ top: `${computeHeight}px` }">
            <button
                ref="chapters-dropdown"
                v-collapse-target="'#chapters-dropdown-btn'"
                aria-expanded="false"
                class="chapters-dropdown-btn collapsed pe-2"
                type="button"
                variant="button"
                @click="
                    showScroll = !showScroll;
                    isDropdownShown = !isDropdownShown;
                "
            >
                <div class="d-flex align-items-center container expandable">
                    <p class="text-left text-uppercase dropdown-spacing text-black">
                        {{ getActiveSubtitleName }}
                    </p>
                    <em class="d-flex bi-chevron-down ms-auto ms-sm-3" aria-hidden="true" />
                </div>
            </button>
            <div id="chapters-dropdown-btn" class="collapse background-white">
                <div
                    class="copywrite-dropdown pb-1"
                    :style="{ 'max-height': `calc(100vh - ${computeHeight + dropdownHeight}px)` }"
                >
                    <div v-for="(title, i) in fields.items" :key="`${title.fields.anchor.value}-${i}`">
                        <div
                            v-if="title.fields.title && title.fields.title.value"
                            class="title dropdown-title dropdown-spacing text-uppercase py-2"
                            :tabindex="0"
                            :class="{ active: activeSubtitle === title.fields.anchor.value }"
                            @keyup.enter="getScrollToSectionId(title)"
                            @click="
                                getScrollToSectionId(title);
                                $refs['chapters-dropdown'].click();
                            "
                        >
                            <div class="container">
                                {{ title.fields.title.value }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import Collapse from '@/mixins/Collapse.mixin.vue';
import DisableScroll from '@/mixins/DisableScroll.mixin';
import SidebarMixin from '@/mixins/Sidebar.mixin.vue';

export default {
    name: 'ArticleChapters',
    mixins: [Collapse, DisableScroll, SidebarMixin],
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    data() {
        return {
            activeSubtitle: null,
            firstId: null,
            scrolling: false,
            sidebarWidth: null,
            resizeObserver: null,
            showDropdown: false,
            dropdownHeight: null,
            showScroll: true,
            isDropdownShown: false,
            dropdownRef: 'chapters-dropdown',
            wrapperRef: 'chapters-wrapper',
        };
    },
    computed: {
        ...mapState('global', ['havePremiumArticleEloquaForm']),
        getActiveSubtitleName() {
            const currentTitle = this.chapters.filter((item) => item.fields.anchor.value === this.activeSubtitle)[0]
                ?.fields.title.value;
            return currentTitle;
        },
        computeHeight() {
            return this.$root.headerHeight ? this.$root.headerHeight : 0;
        },
        chapters() {
            return this.fields.items || [];
        },
    },
    mounted() {
        if (this.chapters.length) {
            this.activeSubtitle = this.fields.items[0].fields.anchor.value;
            this.firstId = this.fields.items[0].fields.anchor.value;
            window.addEventListener('scroll', this.trackScreenView);
            this.setSidebarWidth('chapters-wrapper');
            this.dropdownHeight = this.$refs['chapters-dropdown'].getBoundingClientRect().height;
            this.$refs['chapters-dropdown'] && this.observeHeight('chapters-dropdown');
            window.addEventListener('resize', this.setSidebarWidth);
        }
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.trackScreenView);
        window.removeEventListener('resize', this.setSidebarWidth);
    },
    methods: {
        getScrollToSectionId(title) {
            const id = this.havePremiumArticleEloquaForm ? 'premium-eloqua-form' : title.fields.anchor.value;
            document.getElementById(id).scrollIntoView();
        },
    },
};
</script>

<style lang="scss" scoped>
.chapters-wrapper {
    position: sticky;
    top: 157px;
}

.title {
    letter-spacing: 0.04em;
}
.title,
.subtitle {
    border-left: 2px solid rgba(177, 185, 190, 0.4);
    cursor: pointer;
    transition: border-left ease-out 0.5s;
}

.title.active {
    border-left: 2.3px solid var(--abrdn-black);
    font-weight: 600;
    transition: border-left ease-in 0.2s;
}
.dropdown-spacing {
    letter-spacing: 0.04em;
}
.dropdown-title,
.dropdown-subtitle {
    cursor: pointer;
    border-left: none;
    &.active {
        border-left: none;
        font-weight: 600;
    }
}
.subtitle.active {
    font-weight: 600;
}

.chapters-dropdown {
    position: fixed;
    width: 100%;
    z-index: 998;
    left: 0;
    display: flex;
    padding: 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
}

.chapters-dropdown-btn {
    width: 100%;
    border: none;
    border-bottom: 1px solid black;
    border-top: 1px solid black;
    background: white;
    padding: 20px 0;
    display: flex;
    font-weight: 600;
    justify-content: center;
    padding-right: 0 !important;

    > div {
        padding: 0 15px;
    }
    * {
        color: var(--abrdn-black) !important;
    }
}
.container {
    padding: 0 15px;
}
.collapse {
    border-bottom: 1px solid black;
}
.bi-chevron-down {
    &:before {
        font-weight: 600 !important;
    }
}
#chapters-dropdown-btn {
    > div {
        overflow-y: auto;
        overflow-x: hidden;
        // margin: 0 15px;
        // margin-right: 15px;
        padding-left: 15px;
        > div {
            margin-left: -15px;
            width: 100vw;
            // padding-left: 15px;
        }
    }
    // &.active {
    //     .bi-chevron-down {
    //         transform: rotate(180deg);
    //     }
    // }
}

.bi-chevron-down {
    transform: rotate(180deg);
    transition: 0.2s linear;
}

.collapsed .bi-chevron-down {
    transform: rotate(0deg);
}

.text-left {
    text-align: left;
}

.realtive {
    position: relative;
    height: 100%;
}
</style>
