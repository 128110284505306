<template>
    <section :id="anchorId" class="default-section-padding background-white link-change">
        <div class="container">
            <ScText tag="h2" :field="title" class="h2 mb-3" />
            <ScText tag="p" :field="description" class="body-large text-grey" />
            <div class="mt-5 mt-md-6 mt-lg-8">
                <div v-for="(item, index) in items" :key="item.id">
                    <div v-if="index > 0" class="divider" />
                    <div class="row">
                        <div class="col-6 col-md-4 col-lg-3">
                            <WrappedImage
                                :media="item.image"
                                class="w-100 h-auto"
                                :srcSet="[{ mw: 300 }, { mw: 400 }, { mw: 500 }]"
                                sizes="
                                    (max-width: 575px) 50vw,
                                    (max-width: 767px) 240px,
                                    (max-width: 991px) 210px,
                                    270px
                                "
                            />
                        </div>
                        <div class="col-12 col-md-8 col-lg-9 mt-3 mt-md-0">
                            <ScText tag="h3" :field="item.title" class="h3" />
                            <ScRichText
                                tag="div"
                                :field="item.description"
                                class="body-default text-grey abrdn-wrapper"
                                :class="{ 'mt-3': item.title.value }"
                            />
                            <div class="abrdn-wrapper">
                                <AbrdnPlaceholder :name="`abrdn-list-${index}`" :rendering="rendering" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { RichText } from '@sitecore-jss/sitecore-jss-vue';

import AbrdnPlaceholder from '@/components/Generic/SitecoreWrappers/AbrdnPlaceholder.vue';
import WrappedImage from '@/components/Generic/SitecoreWrappers/WrappedImage';

export default {
    name: 'LogoTextBlocks',
    components: {
        AbrdnPlaceholder,
        WrappedImage,
        ScText: Text,
        ScRichText: RichText,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        title() {
            return this.fields?.title;
        },
        description() {
            return this.fields?.description;
        },
        items() {
            return this.fields?.items?.map((item) => {
                const title = item?.fields?.title;

                return {
                    id: item?.id,
                    image: item?.fields?.image,
                    title: {
                        ...title,
                        value: title?.value,
                    },
                    description: item?.fields?.description,
                };
            });
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';

.divider {
    height: 1px;
    margin-top: calc(var(--spacer) * 5);
    margin-bottom: calc(var(--spacer) * 5);
    background-color: var(--abrdn-black);
    opacity: 0.3;

    @include media-breakpoint-up(md) {
        margin-top: calc(var(--spacer) * 6);
        margin-bottom: calc(var(--spacer) * 6);
    }
}
.abrdn-wrapper {
    ::v-deep p,
    p {
        margin-top: 0 !important;
        margin-bottom: 1rem !important;
    }
}
</style>
