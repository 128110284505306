<template>
    <div class="rating background-to-change">
        <IconStar v-for="(star, idx) in 5" :key="idx" :fillingPercentage="getFillPercent(idx + 1)" />
    </div>
</template>

<script>
import IconStar from '@/components/Icons/icon-star';

export default {
    name: 'Rating',
    components: { IconStar },
    props: {
        rating: {
            type: Number,
            default: /* istanbul ignore next */ () => 5,
        },
    },
    methods: {
        getFillPercent(starNumber) {
            if (this.rating >= starNumber) {
                return 100;
            } else {
                const floatingValue = Number((this.rating % 1).toFixed(1));
                if (this.rating > starNumber - 1) {
                    return floatingValue * 100;
                }
                return 0;
            }
        },
    },
};
</script>

<style scoped></style>
