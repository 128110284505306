<template>
    <div v-if="articles.length" :id="anchorId" class="container pb-10">
        <div class="row d-flex flex-wrap mb-n4" :class="{ 'w-100': articles.length < 4 }">
            <div v-for="article in articles" :key="article.document.id" class="col-12 col-md-4 col-lg-3 mb-4">
                <TrustCard :fields="article.document" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import TrustCard from '@/components/Tiles/TrustCard.vue';

export default {
    name: 'TrustCards',
    components: {
        TrustCard,
    },
    computed: {
        ...mapState('trusts', ['articles']),
    },
};
</script>

<style scoped lang="scss"></style>
