<template>
    <WrappedImage :media="media" :editable="false" v-bind="$attrs" />
</template>

<script>
import WrappedImage from '@/components/Generic/SitecoreWrappers/WrappedImage';

export default {
    name: 'StaticImage',
    components: {
        WrappedImage,
    },
    inheritAttrs: false,
    props: {
        src: {
            type: String,
            default: () => '',
        },
        alt: {
            type: String,
            default: () => '',
        },
    },
    computed: {
        media() {
            return {
                value: {
                    src: this.srcCdn,
                    alt: this.alt,
                },
            };
        },
        srcCdn() {
            let output = this.src;
            if (typeof window !== 'undefined' && this.src && this.src.startsWith('-/media')) {
                output = (window.app?.cdnHost || '') + '/' + this.src;
            } else if (this.src && this.src.startsWith('-/media')) {
                output = (this.$root.cdnHost || '') + '/' + this.src;
            }
            return output;
        },
    },
};
</script>

<style scoped></style>
