export const formFieldsStep5 = {
    rows: [
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                            element: {
                                type: 'FormFieldGroup',
                                id: '334',
                                depth: 'complete',
                                name: 'Admin user details (step 5 of 7)',
                                instructions: '',
                                style: {
                                    textType: 'header',
                                    fieldSize: 'large',
                                    guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                                },
                                unique: true,
                            },
                        },
                    ],
                },
            ],
            type: 'FormFieldGroup',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                            element: {
                                type: 'FormFieldGroup',
                                id: '334',
                                depth: 'complete',
                                name: 'Frequency',
                                instructions: '',
                                style: {
                                    textType: 'subheader',
                                    fieldSize: 'large',
                                    guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                                },
                                unique: true,
                            },
                        },
                    ],
                },
            ],
            type: 'FormFieldGroup',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                            element: {
                                type: 'FormFieldGroup',
                                id: '334',
                                depth: 'complete',
                                name: 'Please provide the details of two admin users. It is the responsibility if admin user to create other users within your firms.',
                                instructions: '',
                                style: {
                                    textType: 'body',
                                    fieldSize: 'large',
                                    guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                                },
                                unique: true,
                            },
                        },
                    ],
                },
            ],
            type: 'FormFieldGroup',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '00N6900000Kvzsw',
                            element: {
                                type: 'FormField',
                                id: 'note',
                                title: 'NOTE',
                                body: 'Admin users are not able to manage model portfolios - only transact users have the ability to manage model portfolios. Please refer to the Separately Managed Accounts user guide for full details of access levels for both admin and transact users.',
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kvzsw',
                                },
                                dataType: 'text',
                                displayType: 'note',
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                            element: {
                                type: 'FormFieldGroup',
                                id: '334',
                                depth: 'complete',
                                name: 'Admin user 1',
                                instructions: '',
                                style: {
                                    textType: 'subheader',
                                    fieldSize: 'large',
                                    guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                                },
                                unique: true,
                            },
                        },
                    ],
                },
            ],
            type: 'FormFieldGroup',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '00N6900000Kvzsg',
                            element: {
                                type: 'FormField',
                                id: 'Title_Admin_user_1__c',
                                name: 'Title',
                                description: '',
                                relatesTo: {
                                    value: 'Admin user 1',
                                },
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kvzsg',
                                },
                                createdFromContactFieldId: '100003',
                                dataType: 'text',
                                displayType: 'text',
                                htmlName: '00N6900000Kvzsg',
                                useGlobalSubscriptionStatus: 'False',
                                validations: [
                                    {
                                        type: 'FieldValidation',
                                        id: '19599',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventUrlCondition' },
                                        isEnabled: 'true',
                                        message: "Value must not contain any URL's",
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19600',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventXSSCondition' },
                                        isEnabled: 'true',
                                        message: 'Value must not contain any HTML',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19601',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: {
                                            type: 'TextLengthCondition',
                                            maximum: '20',
                                            minimum: '0',
                                        },
                                        isEnabled: 'true',
                                        message: 'Invalid length for field value',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19610',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'IsRequiredCondition' },
                                        isEnabled: 'true',
                                        message: 'This field is required',
                                    },
                                ],
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '00N6900000Kvzsg',
                            element: {
                                type: 'FormField',
                                id: 'First_Name_Admin_user_1__c',
                                name: 'First name',
                                relatesTo: {
                                    value: 'Admin user 1',
                                },
                                description: '',
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kvzsg',
                                },
                                createdFromContactFieldId: '100003',
                                dataType: 'text',
                                displayType: 'text',
                                htmlName: '00N6900000Kvzsg',
                                useGlobalSubscriptionStatus: 'False',
                                validations: [
                                    {
                                        type: 'FieldValidation',
                                        id: '19599',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventUrlCondition' },
                                        isEnabled: 'true',
                                        message: "Value must not contain any URL's",
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19600',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventXSSCondition' },
                                        isEnabled: 'true',
                                        message: 'Value must not contain any HTML',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19601',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: {
                                            type: 'TextLengthCondition',
                                            maximum: '100',
                                            minimum: '0',
                                        },
                                        isEnabled: 'true',
                                        message: 'Invalid length for field value',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19610',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'IsRequiredCondition' },
                                        isEnabled: 'true',
                                        message: 'This field is required',
                                    },
                                ],
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '00N6900000Kvzsg',
                            element: {
                                type: 'FormField',
                                id: 'Last_Name_Admin_user_1__c',
                                name: 'Surname',
                                relatesTo: {
                                    value: 'Admin user 1',
                                },
                                description: '',
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kvzsg',
                                },
                                createdFromContactFieldId: '100003',
                                dataType: 'text',
                                displayType: 'text',
                                htmlName: '00N6900000Kvzsg',
                                useGlobalSubscriptionStatus: 'False',
                                validations: [
                                    {
                                        type: 'FieldValidation',
                                        id: '19599',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventUrlCondition' },
                                        isEnabled: 'true',
                                        message: "Value must not contain any URL's",
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19600',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventXSSCondition' },
                                        isEnabled: 'true',
                                        message: 'Value must not contain any HTML',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19601',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: {
                                            type: 'TextLengthCondition',
                                            maximum: '100',
                                            minimum: '0',
                                        },
                                        isEnabled: 'true',
                                        message: 'Invalid length for field value',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19610',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'IsRequiredCondition' },
                                        isEnabled: 'true',
                                        message: 'This field is required',
                                    },
                                ],
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '00N6900000Kvzsg',
                            element: {
                                type: 'FormField',
                                id: 'Email_Admin_user_1__c',
                                name: 'Email',
                                relatesTo: {
                                    value: 'Admin user 1',
                                },
                                description: '',
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kvzsg',
                                },
                                createdFromContactFieldId: '100003',
                                dataType: 'text',
                                displayType: 'text',
                                htmlName: 'emailAddress',
                                useGlobalSubscriptionStatus: 'False',
                                validations: [
                                    {
                                        type: 'FieldValidation',
                                        id: '19599',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventUrlCondition' },
                                        isEnabled: 'true',
                                        message: "Value must not contain any URL's",
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19600',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventXSSCondition' },
                                        isEnabled: 'true',
                                        message: 'Value must not contain any HTML',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19601',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: {
                                            type: 'TextLengthCondition',
                                            maximum: '255',
                                            minimum: '0',
                                        },
                                        isEnabled: 'true',
                                        message: 'Invalid length for field value',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19610',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'IsRequiredCondition' },
                                        isEnabled: 'true',
                                        message: 'This field is required',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '31410',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: {
                                            type: 'IsEmailAddressCondition',
                                        },
                                        isEnabled: 'true',
                                        message: 'A valid email address is required',
                                    },
                                ],
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '00N6900000Kvzsg',
                            element: {
                                type: 'FormField',
                                id: 'FCA_number_Admin_user_1__c',
                                name: 'FCA number (if applicable)',
                                description: '',
                                relatesTo: {
                                    value: 'Admin user 1',
                                },
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kvzsg',
                                },
                                createdFromContactFieldId: '100003',
                                dataType: 'text',
                                displayType: 'text',
                                htmlName: 'number',
                                useGlobalSubscriptionStatus: 'False',
                                validations: [
                                    {
                                        type: 'FieldValidation',
                                        id: '19599',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventUrlCondition' },
                                        isEnabled: 'true',
                                        message: "Value must not contain any URL's",
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19600',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventXSSCondition' },
                                        isEnabled: 'true',
                                        message: 'Value must not contain any HTML',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19601',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: {
                                            type: 'TextLengthCondition',
                                            maximum: '10',
                                            minimum: '0',
                                        },
                                        isEnabled: 'true',
                                        message: 'Invalid length for field value',
                                    },
                                ],
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                            element: {
                                type: 'FormFieldGroup',
                                id: '334',
                                depth: 'complete',
                                name: 'Date of birth',
                                instructions: 'For example, 01 10 1990. We ask for this for security reasons.',
                                style: {
                                    textType: 'subheader',
                                    fieldSize: 'large',
                                    guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                                },
                                unique: true,
                            },
                        },
                    ],
                },
            ],
            type: 'FormFieldGroup',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '00N6900000Kvzsw',
                            element: {
                                type: 'FormField',
                                id: 'date_of_birth',
                                relatesTo: {
                                    value: 'Admin user 1',
                                },
                                validations: [
                                    {
                                        type: 'FieldValidation',
                                        id: '19599',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventUrlCondition' },
                                        isEnabled: 'true',
                                        message: "Value must not contain any URL's",
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19600',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventXSSCondition' },
                                        isEnabled: 'true',
                                        message: 'Value must not contain any HTML',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19601',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: {
                                            type: 'TextLengthCondition',
                                            maximum: '255',
                                            minimum: '0',
                                        },
                                        isEnabled: 'true',
                                        message: 'Invalid length for field value',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19610',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'IsRequiredCondition' },
                                        isEnabled: 'true',
                                        message: 'This field is required',
                                    },
                                ],
                                day: {
                                    name: 'Date of birth',
                                    label: 'Day',
                                    required: true,
                                    type: 'number',
                                    id: 'Date_of_birth_Admin_user_1__c',
                                },
                                month: {
                                    name: 'Date of birth',
                                    label: 'Month',
                                    required: true,
                                    type: 'number',
                                    id: 'Date_of_birth_Admin_user_1__c',
                                },
                                year: {
                                    name: 'Date of birth',
                                    label: 'Year',
                                    required: true,
                                    type: 'number',
                                    id: 'Date_of_birth_Admin_user_1__c',
                                },
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kvzsw',
                                },
                                dataType: 'text',
                                displayType: 'datePicker',
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },

        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                            element: {
                                type: 'FormFieldGroup',
                                id: '334',
                                depth: 'complete',
                                name: 'Admin user 2',
                                instructions: '',
                                style: {
                                    textType: 'subheader',
                                    fieldSize: 'large',
                                    guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                                },
                                unique: true,
                            },
                        },
                    ],
                },
            ],
            type: 'FormFieldGroup',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '00N6900000Kvzsg',
                            element: {
                                type: 'FormField',
                                id: 'Title_Admin_user_2__c',
                                name: 'Title',
                                relatesTo: {
                                    value: 'Admin user 2',
                                },
                                description: '',
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kvzsg',
                                },
                                createdFromContactFieldId: '100003',
                                dataType: 'text',
                                displayType: 'text',
                                htmlName: '00N6900000Kvzsg',
                                useGlobalSubscriptionStatus: 'False',
                                validations: [
                                    {
                                        type: 'FieldValidation',
                                        id: '19599',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventUrlCondition' },
                                        isEnabled: 'true',
                                        message: "Value must not contain any URL's",
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19600',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventXSSCondition' },
                                        isEnabled: 'true',
                                        message: 'Value must not contain any HTML',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19601',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: {
                                            type: 'TextLengthCondition',
                                            maximum: '20',
                                            minimum: '0',
                                        },
                                        isEnabled: 'true',
                                        message: 'Invalid length for field value',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19610',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'IsRequiredCondition' },
                                        isEnabled: 'true',
                                        message: 'This field is required',
                                    },
                                ],
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '00N6900000Kvzsg',
                            element: {
                                type: 'FormField',
                                id: 'First_Name_Admin_user_2__c',
                                name: 'First name',
                                relatesTo: {
                                    value: 'Admin user 2',
                                },
                                description: '',
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kvzsg',
                                },
                                createdFromContactFieldId: '100003',
                                dataType: 'text',
                                displayType: 'text',
                                htmlName: '00N6900000Kvzsg',
                                useGlobalSubscriptionStatus: 'False',
                                validations: [
                                    {
                                        type: 'FieldValidation',
                                        id: '19599',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventUrlCondition' },
                                        isEnabled: 'true',
                                        message: "Value must not contain any URL's",
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19600',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventXSSCondition' },
                                        isEnabled: 'true',
                                        message: 'Value must not contain any HTML',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19601',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: {
                                            type: 'TextLengthCondition',
                                            maximum: '100',
                                            minimum: '0',
                                        },
                                        isEnabled: 'true',
                                        message: 'Invalid length for field value',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19610',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'IsRequiredCondition' },
                                        isEnabled: 'true',
                                        message: 'This field is required',
                                    },
                                ],
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '00N6900000Kvzsg',
                            element: {
                                type: 'FormField',
                                id: 'Last_Name_Admin_user_2__c',
                                name: 'Surname',
                                relatesTo: {
                                    value: 'Admin user 2',
                                },
                                description: '',
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kvzsg',
                                },
                                createdFromContactFieldId: '100003',
                                dataType: 'text',
                                displayType: 'text',
                                htmlName: '00N6900000Kvzsg',
                                useGlobalSubscriptionStatus: 'False',
                                validations: [
                                    {
                                        type: 'FieldValidation',
                                        id: '19599',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventUrlCondition' },
                                        isEnabled: 'true',
                                        message: "Value must not contain any URL's",
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19600',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventXSSCondition' },
                                        isEnabled: 'true',
                                        message: 'Value must not contain any HTML',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19601',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: {
                                            type: 'TextLengthCondition',
                                            maximum: '100',
                                            minimum: '0',
                                        },
                                        isEnabled: 'true',
                                        message: 'Invalid length for field value',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19610',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'IsRequiredCondition' },
                                        isEnabled: 'true',
                                        message: 'This field is required',
                                    },
                                ],
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '00N6900000Kvzsg',
                            element: {
                                type: 'FormField',
                                id: 'Email_Admin_user_2__c',
                                name: 'Email',
                                relatesTo: {
                                    value: 'Admin user 2',
                                },
                                description: '',
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kvzsg',
                                },
                                createdFromContactFieldId: '100003',
                                dataType: 'text',
                                displayType: 'text',
                                htmlName: 'emailAddress',
                                useGlobalSubscriptionStatus: 'False',
                                validations: [
                                    {
                                        type: 'FieldValidation',
                                        id: '19599',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventUrlCondition' },
                                        isEnabled: 'true',
                                        message: "Value must not contain any URL's",
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19600',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventXSSCondition' },
                                        isEnabled: 'true',
                                        message: 'Value must not contain any HTML',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19601',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: {
                                            type: 'TextLengthCondition',
                                            maximum: '255',
                                            minimum: '0',
                                        },
                                        isEnabled: 'true',
                                        message: 'Invalid length for field value',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19610',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'IsRequiredCondition' },
                                        isEnabled: 'true',
                                        message: 'This field is required',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '31410',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: {
                                            type: 'IsEmailAddressCondition',
                                        },
                                        isEnabled: 'true',
                                        message: 'A valid email address is required',
                                    },
                                ],
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '00N6900000Kvzsg',
                            element: {
                                type: 'FormField',
                                id: 'FCA_number_Admin_user_2__c',
                                name: 'FCA number (if applicable)',
                                description: '',
                                relatesTo: {
                                    value: 'Admin user 2',
                                },
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kvzsg',
                                },
                                createdFromContactFieldId: '100003',
                                dataType: 'text',
                                displayType: 'text',
                                htmlName: 'number',
                                useGlobalSubscriptionStatus: 'False',
                                validations: [
                                    {
                                        type: 'FieldValidation',
                                        id: '19599',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventUrlCondition' },
                                        isEnabled: 'true',
                                        message: "Value must not contain any URL's",
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19600',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventXSSCondition' },
                                        isEnabled: 'true',
                                        message: 'Value must not contain any HTML',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19601',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: {
                                            type: 'TextLengthCondition',
                                            maximum: '10',
                                            minimum: '0',
                                        },
                                        isEnabled: 'true',
                                        message: 'Invalid length for field value',
                                    },
                                ],
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                            element: {
                                type: 'FormFieldGroup',
                                id: '334',
                                depth: 'complete',
                                name: 'Date of birth',
                                instructions: 'For example, 01 10 1990. We ask for this for security reasons.',
                                style: {
                                    textType: 'subheader',
                                    fieldSize: 'large',
                                    guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                                },
                                unique: true,
                            },
                        },
                    ],
                },
            ],
            type: 'FormFieldGroup',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '00N6900000Kvzsw',
                            element: {
                                type: 'FormField',
                                id: 'date_of_birth_2',
                                relatesTo: {
                                    value: 'Admin user 2',
                                },
                                validations: [
                                    {
                                        type: 'FieldValidation',
                                        id: '19599',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventUrlCondition' },
                                        isEnabled: 'true',
                                        message: "Value must not contain any URL's",
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19600',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventXSSCondition' },
                                        isEnabled: 'true',
                                        message: 'Value must not contain any HTML',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19601',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: {
                                            type: 'TextLengthCondition',
                                            maximum: '255',
                                            minimum: '0',
                                        },
                                        isEnabled: 'true',
                                        message: 'Invalid length for field value',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19610',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'IsRequiredCondition' },
                                        isEnabled: 'true',
                                        message: 'This field is required',
                                    },
                                ],
                                day: {
                                    name: 'Date of birth',
                                    label: 'Day',
                                    required: true,
                                    type: 'number',
                                    id: 'Date_of_birth_Admin_user_2__c',
                                },
                                month: {
                                    name: 'Date of birth',
                                    label: 'Month',
                                    required: true,
                                    type: 'number',
                                    id: 'Date_of_birth_Admin_user_2__c',
                                },
                                year: {
                                    name: 'Date of birth',
                                    label: 'Year',
                                    required: true,
                                    type: 'number',
                                    id: 'Date_of_birth_Admin_user_2__c',
                                },
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kvzsw',
                                },
                                dataType: 'text',
                                displayType: 'datePicker',
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
    ],
};
