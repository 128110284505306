<template>
    <section :id="anchorId" class="default-section-padding background-white">
        <div class="container">
            <h2 class="h2">{{ $t('seriesTotal').replace('{token.total}', `${articles.length}`) }}</h2>

            <div v-if="articles.length > minLengthToDisplayLatestArticle" class="mt-5">
                <h3 class="h3">{{ $t('seriesLatest') }}</h3>

                <div class="mt-3">
                    <template v-for="(article, index) in articles">
                        <div v-if="index === 0" :key="article.url" class="latest-article-wrapper">
                            <ArticleCardHorizontal :data="article" />
                        </div>
                    </template>
                </div>
            </div>

            <div class="mt-5">
                <h3 class="h3">{{ $t('seriesMoreFrom') }}</h3>

                <div class="row mt-5">
                    <template v-for="(article, index) in articles">
                        <div
                            v-if="articles.length <= minLengthToDisplayLatestArticle || index !== 0"
                            :key="article.url"
                            class="col-12 col-md-6 col-lg-3 mb-4"
                        >
                            <ArticleCard
                                :fields="getArticleCardFields(article)"
                                cardCategoryField="tag"
                                :useLineClamps="false"
                            />
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ArticleCard from '@/components/Tiles/ArticleCard';
import ArticleCardHorizontal from '@/components/Tiles/ArticleCardHorizontal';

export default {
    name: 'SeriesArticles',
    components: {
        ArticleCard,
        ArticleCardHorizontal,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    data() {
        return {
            minLengthToDisplayLatestArticle: 1,
        };
    },
    computed: {
        articles() {
            return this.fields?.articles?.value || [];
        },
    },
    methods: {
        getArticleCardFields(article) {
            return {
                ...article,
                articleType: article.type,
                media: article.image.url,
                name: article.url,
                publicationDate: article.date,
                strapline: article.description,
            };
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';

.latest-article-wrapper {
    @include media-breakpoint-up(md) {
        max-width: 470px;
    }
}
</style>
