<template>
    <div v-if="allOptions.length" class="filters-box">
        <MobileBaseFilters
            class="d-lg-none mobile-filters-button"
            :filters="filters"
            :isLoading="isLoading"
            :appliedOptions="appliedOptions"
            :selectedOptions="selectedOptions"
            :allOptions="allOptions"
            @needAggregateCount="(filterId) => $emit('needAggregateCount', filterId)"
            @optionSelect="onOptionSelect"
            @resetUnAppliedOptions="onResetUnAppliedOptions"
            @clearFilters="
                onResetUnAppliedOptions();
                unApplyOptions(null, appliedOptions);
            "
            @filterApply="onFilterApply"
        />
        <div class="filters-group d-none d-lg-flex align-items-center">
            <FilterDropdown
                v-for="filter in filters"
                :key="filter.id"
                :filter="filter"
                :isLoading="isLoading"
                @needAggregateCount="$emit('needAggregateCount', filter.id)"
                @filterApply="onFilterApply"
                @optionSelect="onOptionSelect"
                @clearFilterSelection="onClearFilterSelection"
                @resetUnAppliedOptions="onResetUnAppliedOptions"
            />
        </div>
        <SelectedFilters
            v-if="appliedOptions.length"
            class="mt-4"
            :approvedFilters="appliedOptions"
            @deleteOption="unApplyOptions"
            @clearFilters="
                onResetUnAppliedOptions();
                unApplyOptions(null, appliedOptions);
            "
        />
    </div>
</template>

<script>
import SelectedFilters from '@/components/Functional/ArticleFilterBar/SelectedFilters.vue';
import FilterDropdown from '@/components/Functional/Filters/FilterDropdown.vue';
import MobileBaseFilters from '@/components/Functional/Filters/MobileFilter/MobileBaseFilters.vue';
import { updateUrlParams } from '@/mixins/Helpers.mixin';

export default {
    name: 'BaseFilter',
    components: { MobileBaseFilters, SelectedFilters, FilterDropdown },
    mixins: [updateUrlParams],
    props: {
        filters: {
            type: Array,
            default: () => [],
            required: true,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        appliedOptions() {
            return this.filters
                .map((filter) => filter.options?.filter((option) => option.applied))
                .flat()
                .filter((option) => option)
                .sort((a, b) => a.numberOfSelected - b.numberOfSelected);
        },
        selectedOptions() {
            return this.filters
                .map((filter) => filter.options?.filter((option) => option.selected))
                .flat()
                .filter((option) => option)
                .sort((a, b) => a.numberOfSelected - b.numberOfSelected);
        },
        allOptions() {
            return this.filters
                .map((filter) => filter.options?.filter((option) => option.aggregateCount !== null))
                .flat()
                .filter((option) => option);
        },
        maxNumInSelectedOptions() {
            return this.selectedOptions[this.selectedOptions.length - 1]?.numberOfSelected + 1 || 0;
        },
    },
    mounted() {
        this.preselectFilters();
    },
    methods: {
        onOptionSelect(option) {
            option.selected = !option.selected;
            option.selected ? (option.numberOfSelected = this.maxNumInSelectedOptions) : (option.numberOfSelected = 0);
        },
        onResetUnAppliedOptions() {
            this.allOptions.forEach((option) => {
                option.selected = option.applied;
            });
        },
        onClearFilterSelection(filter) {
            filter.options.forEach((option) => {
                option.selected = false;
                option.numberOfSelected = 0;
                option.applied = false;
            });
            this.saveFilterToUrl();
            this.$emit('filterApply');
        },
        preselectFilters() {
            const queryParams = this.updateUrlParams();
            for (const [filterId, selectedOptionsIds] of Object.entries(queryParams)) {
                this.filters.forEach((filter) => {
                    if (filter.id === filterId) {
                        this.setFilterOptions(selectedOptionsIds.split(','), filter);
                    }
                });
            }
            this.$emit('filterApply');
        },
        setFilterOptions(optionsToSet = [], filter) {
            optionsToSet.forEach((selectedOptionId) => {
                const option = filter.options.find((option) => option.id === selectedOptionId);
                if (option) {
                    option.applied = true;
                    option.selected = true;
                    option.numberOfSelected = this.maxNumInSelectedOptions;
                }
            });
        },
        saveFilterToUrl() {
            const selectedFilters = this.filters.map((filter) => {
                return {
                    filterName: filter.id,
                    selectedOptions: filter.options
                        .filter((option) => option.applied)
                        .map((selectedOption) => selectedOption.id),
                };
            });
            const params = selectedFilters.map((filter) => {
                return {
                    name: filter.filterName,
                    value: filter.selectedOptions.join(','),
                };
            });
            this.updateUrlParams(params);
        },
        unApplyOptions(singleOption = {}, arrayOfOptions = []) {
            [singleOption || {}, ...arrayOfOptions].forEach((option) => {
                option.selected = false;
            });
            this.onFilterApply();
        },
        onFilterApply() {
            //Check is there any difference between already applied and selected options
            if (this.appliedOptions.length === this.selectedOptions.length) {
                if (!this.appliedOptions.find((appliedFilter) => !this.selectedOptions.includes(appliedFilter))) return;
            }
            this.allOptions.forEach((option) => (option.applied = false));
            this.selectedOptions.forEach((option) => (option.applied = true));
            this.saveFilterToUrl();
            this.$emit('filterApply');
        },
    },
};
</script>

<style scoped></style>
