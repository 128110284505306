<template>
    <div v-if="price">
        <div class="h6">{{ price }}</div>
        <div v-if="date" class="body-small mt-1/2">{{ date }}</div>
    </div>
    <div v-else class="h6">--</div>
</template>

<script>
import { UTCFormat } from '@/constants/dateFormats';
import { formatCurrency, localizeDate } from '@/mixins/Helpers.mixin';

export default {
    name: 'FundsPrice',
    mixins: [formatCurrency, localizeDate],
    props: {
        propertyName: {
            type: String,
            required: true,
        },
        selectedShareclass: {
            type: Object,
            required: true,
        },
    },
    computed: {
        price() {
            return this.formatCurrency(this.selectedShareclass[this.propertyName], this.selectedShareclass.currency);
        },
        date() {
            return this.localizeDate(this.selectedShareclass.date || this.selectedShareclass.priceDate, UTCFormat);
        },
    },
};
</script>
