<template>
    <div :data-e2e-component-uid="rendering.uid" class="container py-4 background-white">
        <div v-if="articles.length" class="row d-flex flex-wrap">
            <div v-for="data in articles" :key="data.document.id" class="col-12 col-md-6 col-lg-4 mb-3 mb-md-4">
                <PressReleaseCard :fields="data.document" class="h-100" />
            </div>
        </div>
        <LoadMoreButton
            :isLoading="loading"
            :currentPage="page"
            :maxPages="maxPages"
            class="d-flex mx-auto mt-1 mt-md-0"
            :data-e2e-component-uid="rendering.uid + 'load-more'"
            @loadMore="fetchArticles(...arguments)"
        />
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

import LoadMoreButton from '@/components/Includes/LoadMoreButton.vue';
import PressReleaseCard from '@/components/Tiles/PressReleaseCard';
import FilterHelper from '@/mixins/ArticleFilter/FilterHelper.mixin';
import { sitecoreLanguage } from '@/mixins/Helpers.mixin';

export default {
    name: 'PressReleaseCards',
    components: { LoadMoreButton, PressReleaseCard },
    mixins: [FilterHelper],
    props: {
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        ...mapState('pressReleases', [
            'articles',
            'page',
            'totalResults',
            'take',
            'loading',
            'approvedFilters',
            'filters',
            'filterMappings',
        ]),
        maxPages() {
            return Math.ceil(this.totalResults / this.take);
        },
        sitecoreLanguage,
    },
    methods: {
        ...mapActions('pressReleases', ['getArticles']),
        ...mapMutations('pressReleases', ['setPaginationData']),
        async fetchArticles(page = 1) {
            try {
                const payload = {
                    ...this.getParams(
                        this.prepareFilterCategories(this.approvedFilters),
                        this.filterMappings,
                        page,
                        this.take,
                        this.sitecoreLanguage,
                    ),
                    setDataRules: {
                        preserveArticles: true,
                    },
                };
                await this.getArticles(payload);
                this.setPaginationData({
                    page: page,
                    take: this.take,
                });
            } catch (e) {
                console.warn(e);
            }
        },
    },
};
</script>

<style scoped lang="scss"></style>
