<template>
    <div :class="[`alert alert--${type}`, renderIcon ? 'p-1' : 'px-1.5 py-1']">
        <h2 class="sr-only">{{ $t('alertImportantInformation') }}</h2>

        <div class="d-flex gap-1">
            <div v-if="renderIcon" class="flex-shrink-0 icon-wrapper icon-wrapper--size-4">
                <i :class="`bi bi-${icon}`" aria-hidden="true" />
            </div>
            <div class="field-text align-self-center">{{ title }}</div>
        </div>

        <div v-if="description.value || expandableDescription.value" class="mt-1" :class="{ 'ps-5': renderIcon }">
            <ScRichText class="body-default" :field="description" />
            <ExpandableContent :show="isExpanded">
                <ScRichText class="pt-1 body-default" :field="expandableDescription" />
            </ExpandableContent>
            <button
                v-if="showDropdownButton"
                :aria-expanded="isExpanded ? 'true' : 'false'"
                class="mt-1 btn-naked d-flex align-items-center gap-1"
                @click="isExpanded = !isExpanded"
            >
                <div class="text-link">{{ $t(isExpanded ? 'showLess' : 'showMore') }}</div>
                <div class="icon-wrapper icon-wrapper--size-3">
                    <i class="bi bi-chevron-down" aria-hidden="true" />
                </div>
            </button>
        </div>
    </div>
</template>

<script>
import { RichText } from '@sitecore-jss/sitecore-jss-vue';

import ExpandableContent from '@/components/Generic/ExpandableContent';

export default {
    name: 'AlertMessageBase',
    components: {
        ScRichText: RichText,
        ExpandableContent,
    },
    props: {
        type: {
            type: String,
            default: 'generic',
        },
        title: {
            type: String,
            default: '',
        },
        description: {
            type: Object,
            default: () => ({}),
        },
        expandableDescription: {
            type: Object,
            default: () => ({}),
        },
        defaultExpanded: {
            type: Boolean,
            default: false,
        },
        showIcon: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            isExpanded: !!this.defaultExpanded,
        };
    },
    computed: {
        icon() {
            const icons = {
                warning: 'exclamation-circle',
                error: 'exclamation-triangle',
                success: 'check-circle',
                informative: 'info-circle',
            };
            return icons[this.type];
        },
        renderIcon() {
            return !!this.icon && this.showIcon;
        },
        showDropdownButton() {
            return !!this.expandableDescription?.value;
        },
    },
};
</script>

<style scoped lang="scss">
.alert--error {
    background-color: rgba(238, 39, 55, 0.1);
    border-left: 2px solid #ee2737;
}

.alert--success {
    background-color: rgba(0, 183, 79, 0.1);
    border-left: 2px solid #00b74f;
}

.alert--warning {
    background-color: rgba(238, 181, 0, 0.1);
    border-left: 2px solid #eeb500;
}

.alert--informative {
    background-color: rgba(0, 87, 183, 0.1);
    border-left: 2px solid #0057b7;
}

.alert--generic {
    background-color: rgba(0, 0, 0, 0.1);
    border-left: 2px solid #000;
}

.icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-wrapper--size-3 {
    width: calc(var(--spacer) * 3);
    height: calc(var(--spacer) * 3);
}

.icon-wrapper--size-4 {
    width: calc(var(--spacer) * 4);
    height: calc(var(--spacer) * 4);
}

.bi-chevron-down {
    transition: transform 0.3s;
}

[aria-expanded='true'] .bi-chevron-down {
    transform: rotate(180deg);
}

::v-deep {
    ul {
        margin: 0;
        padding-left: calc(var(--spacer) * 2);
    }

    a {
        border-bottom: 1px solid;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0;
        color: var(--abrdn-black);
    }
}
</style>
