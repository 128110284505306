const state = () => ({
    preOpenGlobalLangSelector: false,
    havePremiumArticleEloquaForm: false,
});

// getters
const getters = {
    cartTotalPrice: (state) => {
        return state.preOpenGlobalLangSelector;
    },
};

// actions
const actions = {};

const mutations = {
    setPreOpenGlobalLangSelector: (state, data) => {
        state.preOpenGlobalLangSelector = data;
    },
    setHavePremiumArticleEloquaForm: (state, data) => {
        state.havePremiumArticleEloquaForm = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
