<template>
    <FundDetailsTabRow fullWidth class="mt-4">
        <FundDetailsTabSkeleton v-if="loading" />
        <AlertMessageBase v-else-if="error" type="error" :title="$t(error)" />
        <template v-else-if="tableData">
            <FundDetailsStripedTable
                :isScrollable="isScrollable"
                :tableData="tableData"
                :footnote="footnote"
                tableId="calendarTable"
                wrapperId="calendarWrapper"
            />
        </template>
        <div v-else class="body-default">{{ $t('noResultsMessage') }}</div>
    </FundDetailsTabRow>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import AlertMessageBase from '@/components/Generic/AlertMessageBase';
import FundDetailsStripedTable from '@/components/Pages/FundsCenter/FundDetails/FirstLevelTabs/FundDetailsStripedTable';
import { standardFormat } from '@/constants/dateFormats';

import FundDetailsTabRow from '../../FundDetailsTabRow';
import FundDetailsTabSharedLogic from '../../FundDetailsTabSharedLogic.mixin';
import FundDetailsTabSkeleton from '../../FundDetailsTabSkeleton';

export default {
    name: 'FundDetailsCalendarTab',

    components: {
        FundDetailsTabRow,
        FundDetailsStripedTable,
        AlertMessageBase,
        FundDetailsTabSkeleton,
    },

    mixins: [FundDetailsTabSharedLogic],

    computed: {
        ...mapState('fundDetails', {
            loading: (state) => state.calendarTabData.loading,
            error: (state) => state.calendarTabData.error,
            tabData: (state) => state.calendarTabData.data,
        }),

        mappedData() {
            const mapped = [];

            for (const performanceSet of this.performanceSet) {
                mapped.push({
                    rowLabel: performanceSet.performanceLabel,
                    values: performanceSet.values?.map(({ value }) => value),
                });
            }

            return mapped;
        },

        tableColumnsName() {
            const names = [];
            for (const performanceSet of this.performanceSet) {
                for (const value of performanceSet.values) {
                    names.push(value.performanceTimePeriod);
                }
            }
            names.sort((a, b) => b.localeCompare(a));
            return new Set(names);
        },

        performanceSet() {
            return this.tabData?.performanceSet || [];
        },

        tableData() {
            if (!this.mappedData) return;

            return {
                tableHeaderLabels: this.tableColumnsName,
                tableBodyRowLabels: this.mappedData,
            };
        },

        footnote() {
            const lastRelevantDate = this.tabData?.lastRelevantDate;
            if (!lastRelevantDate) return;
            return `${this.$t('fundDetailsPerformanceCumulativeFootnote')} ${this.localizeDate(
                lastRelevantDate,
                standardFormat,
            )}`;
        },
    },

    methods: {
        ...mapActions('fundDetails', {
            fetchTabData: 'fetchCalendarTabData',
        }),
    },
};
</script>
