<template>
    <EEPlaceholder v-if="isExperienceEditor">Team Member Biography</EEPlaceholder>

    <div v-else :id="anchorId">
        <TertiaryHeroBanner v-bind="tertiaryHeroBannerProps" />

        <section class="default-section-padding background-white">
            <div class="container">
                <div class="row gy-4">
                    <div class="col-12 col-md-6">
                        <div class="d-flex flex-column gap-4">
                            <template v-if="textBlocksLength">
                                <ScRichText
                                    v-for="item in textBlocks"
                                    :key="item.id"
                                    :fields="item.fields"
                                    spacingType="tight"
                                    class="body-default text-grey"
                                />
                            </template>

                            <ul
                                v-if="phoneNumber || email || linkedin || twitter || downloadImageLink"
                                class="contact-links m-0 p-0 d-flex flex-column gap-2"
                            >
                                <li v-if="phoneNumber">
                                    <a :href="`tel:${phoneNumber}`" class="d-inline-flex gap-1 align-items-center">
                                        <IconPhone class="icon fs-4" />
                                        <span class="inline-link inline-link--small">{{ phoneNumber }}</span>
                                    </a>
                                </li>

                                <li v-if="email">
                                    <a :href="`mailto:${email}`" class="d-inline-flex gap-1 align-items-center">
                                        <IconEnvelope class="icon fs-4" />
                                        <span class="inline-link inline-link--small">{{ email }}</span>
                                    </a>
                                </li>

                                <li v-if="linkedin">
                                    <a :href="linkedin" target="_blank" class="d-inline-flex gap-1 align-items-center">
                                        <i aria-hidden="true" class="bi bi-linkedin fs-4" />
                                        <span class="inline-link inline-link--small">Linkedin</span>
                                        <span class="sr-only">{{ $t('opensInNewWindow') }}</span>
                                    </a>
                                </li>

                                <li v-if="twitter">
                                    <a :href="twitter" target="_blank" class="d-inline-flex gap-1 align-items-center">
                                        <i aria-hidden="true" class="bi bi-twitter fs-4" />
                                        <span class="inline-link inline-link--small">Twitter</span>
                                        <span class="sr-only">{{ $t('opensInNewWindow') }}</span>
                                    </a>
                                </li>

                                <li v-if="downloadImageLink">
                                    <a
                                        :href="downloadImageLink"
                                        download
                                        class="d-inline-flex gap-1 align-items-center"
                                    >
                                        <IconDownload class="icon fs-4" />
                                        <span class="inline-link inline-link--small">{{ $t('downloadImage') }}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div v-if="image" class="col-12 col-md-6">
                        <StaticImage
                            :src="image"
                            alt=""
                            class="w-100"
                            :srcSet="[{ mw: 400 }, { mw: 600 }, { mw: 800 }, { mw: 1000 }]"
                            sizes="
                                (max-width: 575px) 100vw,
                                (max-width: 767px) 510px,
                                (max-width: 991px) 330px,
                                (max-width: 1199px) 50vw,
                                570px
                            "
                        />
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import RichText from '@/components/Generic/SitecoreWrappers/RichText';
import StaticImage from '@/components/Generic/SitecoreWrappers/StaticImage';
import IconDownload from '@/components/Icons/icon-download';
import IconEnvelope from '@/components/Icons/icon-envelope';
import IconPhone from '@/components/Icons/icon-phone';
import TertiaryHeroBanner from '@/components/Sections/Banners/TertieryHeroBanner';
import EEPlaceholder from '@/components/Sections/EEPlaceholder';

export default {
    name: 'TeamMemberBiography',
    components: {
        ScRichText: RichText,
        IconDownload,
        IconEnvelope,
        IconPhone,
        TertiaryHeroBanner,
        EEPlaceholder,
        StaticImage,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        tertiaryHeroBannerProps() {
            return {
                fields: {
                    title: {
                        value: `${this.fields?.firstName?.value} ${this.fields?.lastName?.value}`,
                    },
                    subtitle: {
                        value: this.fields?.jobTitle?.value,
                    },
                },
                rendering: {
                    params: {
                        background: `${this.changeBackgroundColor}`,
                    },
                },
            };
        },
        changeBackgroundColor() {
            return this.$jss.sitecoreContext().pageTheme?.toLowerCase() === 'financialfairness'
                ? 'background-secondary'
                : 'background-primary';
        },
        phoneNumber() {
            return this.fields?.phoneNumber?.value;
        },
        email() {
            return this.fields?.email?.value;
        },
        linkedin() {
            return this.fields?.linkedin?.value?.href;
        },
        twitter() {
            return this.fields?.twitter?.value?.href;
        },
        downloadImageLink() {
            return this.fields?.downloadImageLink?.value?.href;
        },
        textBlocksLength() {
            return this.fields?.items?.length;
        },
        textBlocks() {
            return this.fields?.items;
        },
        image() {
            return this.fields?.image?.value?.src;
        },
    },
};
</script>

<style scoped lang="scss">
.contact-links {
    list-style: none;
}

.icon {
    width: 1em;
    height: 1em;
}
</style>
