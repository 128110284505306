<template>
    <FundDetailsTabRow fullWidth class="mt-4">
        <FundDetailsTabSkeleton v-if="loading" />
        <AlertMessageBase v-else-if="error" type="error" :title="$t(error)" />
        <div v-else-if="tabData" class="abrdn-table">
            <table :aria-label="$t('fundDetailsTabsTradingInformation')" class="minimal">
                <thead>
                    <tr>
                        <th scope="col">
                            <span class="visually-hidden">{{ $t('fundTradingInfoExchange') }}</span>
                        </th>
                        <th v-for="key in theadData" :key="key" scope="col">
                            {{ $t(dictionaryItems[key]) }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(row, index) in tbodyData" :key="index">
                        <th scope="row">{{ row.header }}</th>
                        <td v-for="data in row.data" :key="data">{{ data }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-else class="body-default">{{ $t('noResultsMessage') }}</div>
    </FundDetailsTabRow>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import AlertMessageBase from '@/components/Generic/AlertMessageBase';
import { localizeDate } from '@/mixins/Helpers.mixin';

import FundDetailsTabRow from '../FundDetailsTabRow';
import FundDetailsTabSharedLogic from '../FundDetailsTabSharedLogic.mixin';
import FundDetailsTabSkeleton from '../FundDetailsTabSkeleton';

export default {
    name: 'FundDetailsTradingInformationTab',

    components: {
        FundDetailsTabRow,
        AlertMessageBase,
        FundDetailsTabSkeleton,
    },

    mixins: [FundDetailsTabSharedLogic, localizeDate],

    data() {
        return {
            dictionaryItems: {
                bloombergCode: 'fundTradingInfoBloombergCode',
                bloombergTicker: 'fundCodesBloomberg',
                currency: 'fundDetailsCurrency',
                cusip: 'fundCodesCusip',
                exchangeCode: 'fundTradingInfoExchangeCode',
                reutersCode: 'fundTradingInfoReutersCode',
                ric: 'fundCodesRic',
                sedol: 'fundCodesSedol',
                tradingHours: 'fundTradingInfoTradingHours',
            },

            columnsOrder: [
                'name',
                'exchangeCode',
                'currency',
                'cusip',
                'ric',
                'sedol',
                'bloombergCode',
                'bloombergTicker',
                'reutersCode',
                'tradingHours',
            ],
        };
    },

    computed: {
        ...mapState('fundDetails', {
            loading: (state) => state.tradingInformationTabData.loading,
            error: (state) => state.tradingInformationTabData.error,
            tabData: (state) => state.tradingInformationTabData.data,
        }),

        formattedTabData() {
            if (!this.tabData?.length) return [];

            return this.tabData.map((row) => {
                return this.columnsOrder
                    .filter((column) => Object.keys(this.tabData[0]).includes(column))
                    .reduce((orderedColumns, column) => {
                        orderedColumns[column] = row[column];
                        return orderedColumns;
                    }, {});
            });
        },

        theadData() {
            const [firstRow] = this.formattedTabData;
            return firstRow ? Object.keys(firstRow).filter((key) => key !== 'name') : [];
        },

        tbodyData() {
            return this.formattedTabData.map((row) => ({
                header: Object.entries(row).find(([key]) => key === 'name')[1],
                data: Object.entries(row)
                    .filter(([key]) => key !== 'name')
                    .map(([, value]) => value || this.$t('notAvailableShortMessage')),
            }));
        },
    },

    methods: {
        ...mapActions('fundDetails', {
            fetchTabData: 'fetchTradingInformationTabData',
        }),
    },
};
</script>
