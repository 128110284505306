var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"abrdn-header position-relative",class:{ breadcrumbs: _vm.haveBreadcrumbs },style:({ minHeight: _vm.computeHeight + 'px' }),attrs:{"id":_vm.anchorId,"data-e2e-component-uid":_vm.rendering.uid}},[_c('div',{ref:"header",class:{ 'fixed-top': !_vm.isExperienceEditor }},[(_vm.fields.items && _vm.fields.items.length)?_c('GlobalNavigation',{attrs:{"data-e2e-component-uid":_vm.rendering.uid + '-global-nav',"globalNavFields":_vm.fields.items[0]}}):_vm._e(),_c('div',{staticClass:"background-white header-outer d-flex align-items-center"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"d-flex header-inner"},[_c('h5',{staticClass:"me-auto font-weight-normal logo-wrapper align-self-center"},[_c('ScLink',{attrs:{"field":_vm.fields.logoLink,"showExternalIcon":false}},[_c('WrappedImage',{staticClass:"abrdn-logo",attrs:{"media":_vm.fields.logo,"srcSet":[{ mw: 200 }, { mw: 300 }, { mw: 400 }],"sizes":"110px","lazy":false}})],1)],1),_c('div',{staticClass:"header__content"},[_c('div',{staticClass:"d-flex w-100 align-items-center justify-content-end h-100"},[(!_vm.isExperienceEditor)?_c('ScLink',{staticClass:"login-icon me-1 d-md-none",class:{
                                    'width-unset':
                                        _vm.fields.items && _vm.fields.items.length
                                            ? !_vm.fields.items[0].fields.icon.value
                                            : false,
                                },attrs:{"showExternalIcon":false,"field":_vm.haveItems && _vm.fields.items[0].fields.cta}},[(_vm.haveItems && _vm.fields.items[0].fields.icon.value)?_c('em',{staticClass:"login-section-icon d-flex",class:( _obj = {}, _obj['bi-' + _vm.fields.items[0].fields.icon.value] = true, _obj )}):_vm._e()]):_vm._e(),_c('div',{staticClass:"h-100"},[_c('Placeholder',{staticClass:"h-100",attrs:{"rendering":_vm.rendering,"name":"abrdn-mega-navigation"}})],1),_c('div',[_c('Placeholder',{staticClass:"align-self-center",attrs:{"rendering":_vm.rendering,"name":"abrdn-language-selector"}})],1)],1)])])])])],1),_c('Placeholder',{ref:"breadcrumbs",staticClass:"breadcrumbs w-100",style:({ top: _vm.computeHeight + 'px' }),attrs:{"rendering":_vm.rendering,"name":"abrdn-breadcrumbs"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }