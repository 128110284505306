<template>
    <section
        :id="anchorId"
        :data-e2e-component-uid="rendering.uid"
        class="default-section-padding text-blocks link-change"
        :class="backgroundClass"
    >
        <div class="container">
            <div class="row">
                <div :class="{ 'col-12 col-xl-8 mb-4 mb-xl-0': haveAside, 'col-12': !haveAside }">
                    <div
                        v-if="isExperienceEditor || haveTitleValue"
                        class="row"
                        :class="{ 'mb-5': hasPlaceholders || (fields.items && fields.items.length) }"
                    >
                        <div class="col col-xl-8 col-md-10" :class="{ 'col-12': haveAside }">
                            <ScText :tag="headingType" class="background-to-change" :field="fields.title" />
                        </div>
                    </div>

                    <div v-if="hasPlaceholders || (fields.items && fields.items.length)" class="row d-flex">
                        <div
                            v-for="(textBlock, i) in fields.items"
                            :key="textBlock.id"
                            class="col-12 col-md mb-md-0 d-flex flex-column align-items-start"
                            :class="{
                                'col-xl-8 col-md-10': fields.items.length === 1 && !haveAside,
                                'col-md-6 col-xl mb-xl-0': fields.items.length > 3 && !haveAside,
                                'mb-md-5': i <= 1 && fields.items.length > 3 && !haveAside,
                                'mb-5': i !== fields.items.length - 1,
                                'col-12': haveAside,
                            }"
                        >
                            <div v-if="isExperienceEditor || getIcon(textBlock)" class="mb-2 mb-md-4 image-wrapper">
                                <WrappedImage
                                    :media="textBlock.fields.icon"
                                    class="image background-to-change"
                                    :srcSet="[{ mw: 100 }, { mw: 200 }]"
                                    sizes="64px"
                                />
                            </div>

                            <ScText
                                v-if="
                                    isExperienceEditor ||
                                    (textBlock.fields && textBlock.fields.title && textBlock.fields.title.value)
                                "
                                tag="h5"
                                class="mb-2 background-to-change"
                                :field="textBlock.fields.title"
                            />
                            <ScRichText
                                v-if="
                                    isExperienceEditor ||
                                    (textBlock.fields &&
                                        textBlock.fields.description &&
                                        textBlock.fields.description.value)
                                "
                                class="contentDescription body-default quote-description text-grey background-to-change mb-0 w-100 link-change"
                                :fields="textBlock.fields"
                            />
                            <AbrdnPlaceholder :rendering="rendering" :name="`abrdn-list-${i}`" class="mt-3" />
                            <template
                                v-if="
                                    isExperienceEditor ||
                                    (textBlock.fields &&
                                        textBlock.fields.cta &&
                                        textBlock.fields.cta.value &&
                                        textBlock.fields.cta.value.text)
                                "
                            >
                                <AbrdnButton
                                    :buttonType="buttonType"
                                    :linkField="textBlock.fields.cta"
                                    :class="{ 'mt-2 mt-md-3': !haveInsidePlaceholders }"
                                />
                            </template>
                        </div>
                        <AbrdnPlaceholder :rendering="rendering" name="abrdn-text-blocks" />
                    </div>
                </div>
                <AbrdnPlaceholder
                    :rendering="rendering"
                    name="abrdn-text-blocks-aside"
                    class="col-12 col-xl-4"
                    :singleTile="true"
                    hardcodedBg="background-blue-grey"
                />
            </div>
        </div>
    </section>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton';
import AbrdnPlaceholder from '@/components/Generic/SitecoreWrappers/AbrdnPlaceholder.vue';
import RichText from '@/components/Generic/SitecoreWrappers/RichText.vue';
import WrappedImage from '@/components/Generic/SitecoreWrappers/WrappedImage.vue';
import { backgroundClass, hasPlaceholders } from '@/mixins/Helpers.mixin';

export default {
    name: 'TextBlocks',
    components: {
        AbrdnPlaceholder,
        ScText: Text,
        ScRichText: RichText,
        WrappedImage,
        AbrdnButton,
    },
    mixins: [backgroundClass, hasPlaceholders],
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        params: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        haveTitleValue() {
            return this.fields?.title?.value;
        },
        headingType() {
            return this.params.headingType || 'h2';
        },
        haveAside() {
            const { placeholders } = this.rendering;
            return placeholders && placeholders['abrdn-text-blocks-aside']?.length;
        },
        haveInsidePlaceholders() {
            const { placeholders } = this.rendering;
            return placeholders && placeholders['abrdn-list-0']?.length;
        },
        buttonType() {
            const { linkType } = this.rendering.params;
            const buttonTypesMap = {
                Primary: 'primary',
                Secondary: 'secondary',
                Tertiary: 'textLink',
            };
            return buttonTypesMap[linkType] || 'textLink';
        },
    },
    methods: {
        getIcon(textBlock) {
            return textBlock?.fields?.icon?.value?.src;
        },
    },
};
</script>

<style scoped lang="scss">
.quote-description {
    margin-bottom: 0 !important;
}

::v-deep a {
    text-decoration: underline;
    font-weight: 600;
}
.image-wrapper {
    display: flex;
    align-items: flex-end;
    min-height: 64px;
    max-width: 64px;
}
.image {
    min-width: 64px;
    min-height: auto;
    width: 64px;
    height: auto;
    display: block;

    ::v-deep img {
        max-width: 64px;
        max-height: auto;
    }

    ::v-deep svg {
        width: 64px;
        height: auto;
    }
}
</style>
