<template>
    <RelatedArticlesSections
        :id="anchorId"
        :data-e2e-component-uid="rendering.uid"
        :sectionTitle="$t('latestPressReleasesTitle')"
        :fields="fields"
        :rendering="rendering"
        :sliderCardComponent="sliderCardComponent"
    />
</template>

<script>
import RelatedArticlesSections from '@/components/Sections/RelatedArticlesVariations/RelatedArticlesSection.vue';
import PressReleaseCard from '@/components/Tiles/PressReleaseCard.vue';

export default {
    name: 'LatestPressReleasesSection',
    components: { RelatedArticlesSections },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    data() {
        return {
            sliderCardComponent: PressReleaseCard,
        };
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
::v-deep .swiper-slide .press-release-card {
    width: 370px;
    @include media-breakpoint-down(xl) {
        width: 290px;
    }
}
::v-deep .swiper-slide {
    width: auto;
}
</style>
