<template>
    <div>
        <div class="footnote footnote--bold text-grey">{{ $t('contributors') }}</div>
        <div class="mt-1 body-small text-grey">{{ contributors }}</div>
    </div>
</template>

<script>
export default {
    name: 'ArticleContributors',
    props: {
        contributors: {
            type: String,
            default: '',
        },
    },
};
</script>

<style lang="scss" scoped></style>
