<template>
    <router-link :to="fields.url" class="help-article h-100">
        <div class="d-flex flex-column flex-grow-1 background-white px-2 py-3">
            <div class="d-flex flex-column h-100 flex-grow-1 align-items-start mt-2">
                <h5>{{ fields.title }}</h5>
                <p class="body-small text-grey mt-1 mb-4 line-clamp-3">{{ fields.description }}</p>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    name: 'HelpArticleTile',
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';

.help-article {
    transition: box-shadow 0.2s ease-in-out;
    cursor: pointer;
    display: flex;
    flex-direction: column;
}
.help-article:focus,
.help-article:hover {
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.25);
    h5 {
        text-decoration: underline;
    }
}
</style>
