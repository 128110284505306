<template>
    <AbrdnButton
        v-if="maxPages > currentPage"
        buttonType="primary"
        :disabled="isLoading"
        @click.native="$emit('loadMore', currentPage + 1)"
    >
        <span>{{ $t('loadMore') }}</span>
        <Loader v-if="isLoading" class="loader ms-2" color="white" aria-hidden="true" />
        <IconChevron v-else class="chevron ms-2" aria-hidden="true" />
    </AbrdnButton>
</template>

<script>
import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton.vue';
import Loader from '@/components/Generic/Loader.vue';
import IconChevron from '@/components/Icons/icon-chevron.vue';

export default {
    name: 'LoadMoreButton',
    components: { IconChevron, Loader, AbrdnButton },
    props: {
        currentPage: {
            type: [Number, null],
            default: null,
            required: true,
        },
        maxPages: {
            type: [Number, null],
            default: null,
            required: true,
        },
        isLoading: {
            type: Boolean,
            default: false,
            required: true,
        },
    },
};
</script>

<style scoped lang="scss">
.loader {
    margin-top: 0;
}
.chevron {
    width: 0.6em;
    height: 0.6em;
}
</style>
