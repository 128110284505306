<template>
    <EEPlaceholder v-if="isExperienceEditor">Country selector</EEPlaceholder>

    <div
        v-else
        :id="anchorId"
        ref="wrapper"
        class="country-selector"
        :data-e2e-component-uid="rendering.uid"
        tabindex="-1"
        @focusout="onFocusOut"
        @keydown.esc="
            isOpen = false;
            $refs.dropdownButton.focus();
        "
    >
        <button
            ref="dropdownButton"
            class="country-selector__button"
            :aria-expanded="isOpen ? 'true' : 'false'"
            aria-controls="country-selector-menu"
            :aria-label="`${$t('changeRegion')}, ${selectedCountry.name} ${selectedLanguage.name} currently selected`"
            @click="isOpen = !isOpen"
        >
            <ScImage
                class="country-selector__flag country-selector__flag--small me-2"
                :media="selectedCountry.flag"
                :srcSet="[{ mw: 50 }, { mw: 100 }]"
                sizes="32px"
            />
            <span class="country-selector__button-text me-1 me-md-2" aria-hidden="true">{{
                selectedLanguage.code
            }}</span>
            <span class="country-selector__button-text text-decoration-underline me-1">{{ $t('changeRegion') }}</span>
            <IconChevron class="country-selector__icon" aria-hidden="true" />
        </button>

        <transition>
            <div v-if="isOpen" id="country-selector-menu" class="country-selector__menu">
                <div class="py-8 py-md-11">
                    <div class="container">
                        <h2 v-if="title" class="h1">{{ title }}</h2>
                        <p v-if="description" class="body-large mt-5 mt-md-6">{{ description }}</p>

                        <div
                            class="country-selector__regions-list"
                            :class="{ 'mt-7 mt-md-9 mt-lg-11': title || description }"
                        >
                            <div v-for="region in regions" :key="region.name">
                                <h3 class="h2">{{ region.name }}</h3>

                                <div class="country-selector__countries-list mt-6">
                                    <div v-for="country in region.countries" :key="country.name">
                                        <ScImage
                                            class="country-selector__flag country-selector__flag--large"
                                            :media="country.flag"
                                            :srcSet="[{ mw: 100 }, { mw: 200 }]"
                                            sizes="64px"
                                        />
                                        <div class="country-selector__languages-list mt-2">
                                            <div v-for="language in country.languages" :key="language.code">
                                                <router-link
                                                    class="text-link text-decoration-underline"
                                                    :to="getLanguageRoute(language)"
                                                    :lang="language.code"
                                                    @click.native="onLanguageClick(language)"
                                                >
                                                    {{ language.countryName }} - {{ language.name }}
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { Image } from '@sitecore-jss/sitecore-jss-vue';
import { mapMutations, mapState } from 'vuex';

import IconChevronSecondLevelTabs from '@/components/Icons/icon-chevron-second-level-tabs';
import EEPlaceholder from '@/components/Sections/EEPlaceholder';
import Cookie from '@/mixins/Cookie.mixin';
import DisableScroll from '@/mixins/DisableScroll.mixin';

export default {
    name: 'CountrySelector',

    components: {
        IconChevron: IconChevronSecondLevelTabs,
        EEPlaceholder,
        ScImage: Image,
    },

    mixins: [Cookie, DisableScroll],

    props: {
        fields: {
            type: Object,
            default: () => ({}),
        },
        rendering: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            isOpen: false,
        };
    },

    computed: {
        ...mapState('global', { isDefaultOpen: 'preOpenGlobalLangSelector' }),

        title() {
            return this.fields?.title?.value || '';
        },

        description() {
            return this.fields?.description?.value || '';
        },

        regions() {
            return this.fields?.regions?.reduce?.(this.transformRegions, []) || [];
        },

        allCountries() {
            return this.regions.reduce((accumulator, region) => {
                return [
                    ...accumulator,
                    ...region.countries.reduce((accumulator, country) => {
                        return [...accumulator, country];
                    }, []),
                ];
            }, []);
        },

        allLanguages() {
            return this.allCountries.reduce((accumulator, country) => {
                return [
                    ...accumulator,
                    ...country.languages.reduce((accumulator, language) => {
                        return [...accumulator, language];
                    }, []),
                ];
            }, []);
        },

        displayLanguage() {
            return this.$jss.sitecoreContext().displayLanguage;
        },

        selectedCountry() {
            const selectedCountry = this.allCountries.find((country) => {
                return country.code.toLowerCase() === this.displayLanguage.split('-')?.[1]?.toLowerCase?.();
            });
            return {
                name: selectedCountry?.name,
                flag: selectedCountry?.flag || {},
            };
        },

        selectedLanguage() {
            const selectedLanguage = this.allLanguages.find(
                (language) => language.code.toLowerCase() === this.displayLanguage.toLowerCase(),
            );
            return {
                name: selectedLanguage?.name,
                code: selectedLanguage?.code.split('-')?.[0]?.toUpperCase?.(),
            };
        },
    },

    watch: {
        isDefaultOpen(value) {
            if (value) {
                this.isOpen = true;
                this.setIsDefaultOpen(false);
            }
        },

        isOpen(value) {
            this.toggleWindowScroll(value);
        },
    },

    mounted() {
        this.isOpen = this.isDefaultOpen;
    },

    methods: {
        ...mapMutations('global', { setIsDefaultOpen: 'setPreOpenGlobalLangSelector' }),

        onFocusOut() {
            /*
             * NOTE: `setTimeout` is necessary because `document.activeElement`
             * is not set immediately to the newly focused element after
             * `focusout` event was triggered.
             */
            setTimeout(() => {
                if (!this.$refs.wrapper.contains(document.activeElement)) {
                    this.isOpen = false;
                }
            });
        },

        transformRegions(accumulator, region) {
            return [
                ...accumulator,
                {
                    name: region?.name?.value || '',
                    countries: region?.countries?.reduce?.(this.transformCountries, []) || [],
                },
            ];
        },

        transformCountries(accumulator, country) {
            return [
                ...accumulator,
                {
                    name: country?.name?.value || '',
                    code: country?.code?.value || '',
                    flag: country?.flag || {},
                    languages: country?.languages?.reduce?.(this.transformLanguages, []) || [],
                },
            ];
        },

        transformLanguages(accumulator, language) {
            return [
                ...accumulator,
                {
                    name: language?.name?.value || '',
                    code: language?.code?.value || '',
                    countryName: language?.countryName?.value || '',
                },
            ];
        },

        getLanguageRoute(language) {
            const languageCode = language.code.toLowerCase();
            if (this.$route.params.lang) {
                return { params: { lang: languageCode } };
            }
            return { path: `/${languageCode}${this.$route.path}` };
        },

        onLanguageClick(language) {
            this.isOpen = false;
            this.setCookie(`${this.$jss.sitecoreContext().site.name.toLowerCase()}#lang`, language.code, 9999);
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';

.country-selector__button {
    display: flex;
    align-items: center;
    border: 0;
    padding: 0;
    background: none;
    color: var(--abrdn-black);
}

.country-selector__button-text {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    letter-spacing: 3%;
}

.country-selector__flag {
    border: 1px solid;
    border-radius: 50%;
    object-fit: cover;
}

.country-selector__flag--small {
    width: calc(var(--spacer) * 4);
    height: calc(var(--spacer) * 4);
}

.country-selector__flag--large {
    width: calc(var(--spacer) * 8);
    height: calc(var(--spacer) * 8);
}

.country-selector__icon {
    width: calc(var(--spacer) * 3);
    transition: transform 0.2s;
}

[aria-expanded='true'] > .country-selector__icon {
    transform: rotate(180deg);
}

.country-selector__menu {
    position: fixed;
    top: var(--headerHeight);
    right: 0;
    left: 0;
    height: calc(100% - var(--headerHeight));
    overflow: auto;
    background: var(--abrdn-white);

    &.v-enter-active,
    &.v-leave-active {
        transition: height 0.2s;
    }

    &.v-enter,
    &.v-leave-to {
        height: 0;
    }
}

.country-selector__regions-list {
    display: grid;
    gap: calc(var(--spacer) * 10);
    margin: 0;
    padding: 0;
    list-style: none;

    @include media-breakpoint-up(md) {
        gap: calc(var(--spacer) * 12);
    }

    @include media-breakpoint-up(lg) {
        gap: calc(var(--spacer) * 14);
    }
}

.country-selector__countries-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: calc(var(--spacer) * 6) 16px;
    margin: 0;
    padding: 0;
    list-style: none;

    @include media-breakpoint-up(md) {
        grid-template-columns: repeat(4, 1fr);
        gap: calc(var(--spacer) * 5) calc(var(--spacer) * 2);
    }
}

.country-selector__languages-list {
    display: grid;
    gap: calc(var(--spacer) * 2);
    margin: 0;
    padding: 0;
    list-style: none;
}
</style>
