import Vue from 'vue';
import Vuex from 'vuex';

import allFunds from '@/store/modules/allFunds';
import articles from '@/store/modules/articles';
import fundDetails from '@/store/modules/fundDetails';
import global from '@/store/modules/global';
import language from '@/store/modules/language';
import literatureHub from '@/store/modules/literatureHub';
import onboardingForm from '@/store/modules/onboardingForm';
import pressReleases from '@/store/modules/pressReleases';
import riskWarning from '@/store/modules/riskWarning';
import trusts from '@/store/modules/trusts';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        articles,
        trusts,
        pressReleases,
        riskWarning,
        language,
        global,
        allFunds,
        fundDetails,
        literatureHub,
        onboardingForm,
    },
});
