<template>
    <WrappedImage :id="anchorId" :media="fields.image" />
</template>

<script>
import WrappedImage from '@/components/Generic/SitecoreWrappers/WrappedImage.vue';

export default {
    name: 'AbrdnImage',
    components: {
        WrappedImage,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
};
</script>

<style scoped lang="scss">
img {
    height: auto;
    ::v-deep & {
        height: auto;
    }
}
</style>
