<script>
export default {
    directives: {
        ['collapse-target']: {
            inserted: function (el, binding, vnode) {
                const self = vnode.context;
                const targetIdentifier = binding.value;
                const target = self.$el.querySelector(targetIdentifier);
                if (!target) {
                    console.warn('Collapse target was not found', targetIdentifier);
                    return;
                }
                if (!target.classList.contains('show')) {
                    self.$gsap.set(target, { display: 'none' });
                } else {
                    vnode.context.$data.overlay = true;
                }
                const duration = binding.arg / 1000 || 0.3;
                let isInProgress = false;
                el.collapseTrigger = () => {
                    vnode.context.$data.overlay = !vnode.context.$data.overlay;
                    if (isInProgress) return;

                    //Workaround for issues when class adds programmatically
                    if (target.classList.contains('show') && target.getBoundingClientRect().height <= 0) {
                        target.classList.remove('show');
                    }

                    if (!target.classList.contains('show')) {
                        isInProgress = true;
                        self.$gsap.set(target, { display: '' });
                        target.classList.add('show');
                        self.$gsap &&
                            self.$gsap.fromTo(
                                target,
                                { height: el.getAttribute('data-not-collapse-fully') ? 'auto' : 0 },
                                { height: 'auto', duration: duration, onComplete: () => (isInProgress = false) },
                            );
                        el.setAttribute('aria-expanded', 'true');
                        el.classList.remove('collapsed');
                        target.classList.remove('collapsed');
                    } else {
                        isInProgress = true;
                        self.$gsap.to(target, {
                            height: el.getAttribute('data-not-collapse-fully') ? 'auto' : 0,
                            duration: duration,
                            onComplete: () => {
                                target.classList.remove('show');
                                isInProgress = false;
                                el.setAttribute('aria-expanded', 'false');
                                self.$gsap.set(target, { display: 'none' });
                            },
                        });
                        el.classList.add('collapsed');
                        target.classList.add('collapsed');
                    }
                };
                el.addEventListener('click', el.collapseTrigger);
            },
            unbind: (el) => {
                el.removeEventListener('click', el.collapseTrigger);
            },
        },
    },
    data() {
        return {
            overlay: false,
        };
    },
};
</script>
<style>
.collapse:not(.show) {
    height: 0;
    pointer-events: none;
}
</style>
