<template>
    <div class="descriptive-highlight background-to-change p-2" :class="numberClass">
        <div class="descriptive-highlight__title">
            <WrappedImage
                :media="fields.icon"
                class="background-to-change descriptive-highlight__icon flex-shrink-0 me-2"
                :srcSet="[{ mw: 50 }, { mw: 100 }]"
                sizes="48px"
            />
            <ScText tag="h6" :field="fields.title" class="text-grey" />
        </div>
        <ScText
            tag="p"
            class="descriptive-highlight__content body-default text-grey mt-1"
            :field="fields.description"
        />
    </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import WrappedImage from '@/components/Generic/SitecoreWrappers/WrappedImage';

export default {
    name: 'DescriptiveHighlightTile',
    components: {
        WrappedImage,
        ScText: Text,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        numberOfItems: {
            type: Number,
            default: /* istanbul ignore next */ () => 0,
        },
    },
    computed: {
        numberClass() {
            return `layout-${this.numberOfItems}`;
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
.descriptive-highlight {
    @include media-breakpoint-up(xl) {
        &.layout-4 {
            .descriptive-highlight__title {
                flex-direction: column;
                align-items: flex-start;
                .descriptive-highlight__icon {
                    margin-bottom: calc(var(--spacer) * 2);
                }
            }
        }
    }
    @include media-breakpoint-between(md, xl) {
        &.layout-3 {
            .descriptive-highlight__title {
                flex-direction: column;
                align-items: flex-start;
                .descriptive-highlight__icon {
                    margin-bottom: var(--spacer);
                }
            }
        }
    }
}
.descriptive-highlight__icon {
    width: 48px;
    height: 48px;
    svg {
        width: 100%;
        height: 100%;
    }
}
.descriptive-highlight__title {
    display: flex;
    align-items: center;
}
</style>
