<template>
    <section :id="anchorId">
        <div class="background-primary">
            <div class="background-to-change">
                <div class="container">
                    <div class="d-flex align-items-center gap-1 gap-md-4">
                        <button
                            v-for="{ tabType: tabKey } in fields.fundCentreTabs.value"
                            :key="tabKey"
                            :class="['tab-button', tabKey === activeTabKey && 'tab-button--active']"
                            @click="toggleTab(tabKey)"
                        >
                            <span class="d-flex align-items-center gap-2">
                                <em
                                    v-if="tabKey === TAB_KEYS.MY_FUNDS"
                                    class="bi bi-bookmarks-fill d-none d-md-block"
                                />
                                <span class="subheader-1">{{ getTabTitle(tabKey) }}</span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <template v-if="activeTabKey === TAB_KEYS.ALL_FUNDS">
            <FundsListing
                :countryCode="countryCode"
                :investorTypes="investorTypes"
                :itemsPerPage="itemsPerPage"
                :jurisdiction="jurisdiction"
                :literatureAuthorization="literatureAuthorization"
                :site="site"
                :filters="filters"
            />
        </template>

        <template v-if="showFundsInFocus">
            <Placeholder :rendering="rendering" name="abrdn-strategies-in-focus" />
        </template>

        <template v-else-if="activeTabKey === TAB_KEYS.MY_FUNDS">
            <!-- TODO: Render component for "My Funds" -->
            [Content for "My Funds"]
        </template>
    </section>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';
import { mapMutations, mapState } from 'vuex';

import FundsListing from '@/components/Pages/FundsCenter/AllFunds/FundsListing/FundsListing.vue';
import { updateUrlParams } from '@/mixins/Helpers.mixin';

export default {
    name: 'FundCentreMainTabs',
    components: { FundsListing, Placeholder },
    mixins: [updateUrlParams],
    provide() {
        return {
            countryCode: this.countryCode,
            investorTypes: this.investorTypes,
            jurisdiction: this.jurisdiction,
            allFundsTabTabs: this.allFundsTabTabs,
            site: this.site,
        };
    },
    props: {
        fields: {
            type: Object,
            default: () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    data() {
        return {
            TAB_KEYS: {
                ALL_FUNDS: 0,
                FUNDS_IN_FOCUS: 1,
                MY_FUNDS: 2,
            },
            ALL_FUNDS_TAB_KEYS: {
                0: 'overview',
                1: 'prices',
                2: 'performance',
            },
            TAB_TITLE_KEYS: {
                0: 'fundsFundCentreTabsAllFunds',
                1: 'fundsFundCentreTabsFundsInFocus',
                2: 'fundsFundCentreTabsMyFunds',
            },
            ALL_FUNDS_TAB_TITLE_KEYS: {
                0: 'fundCentreOverview',
                1: 'fundCentrePrices',
                2: 'fundCentrePerformance',
            },
        };
    },
    computed: {
        ...mapState('allFunds', ['allFundsCount', 'activeTabKey']),
        countryCode() {
            return this.$jss.sitecoreContext().fundCentreConfiguration.countryInvestors.countryCode || '';
        },
        jurisdiction() {
            return this.$jss.sitecoreContext().fundCentreConfiguration.countryInvestors.jurisdiction || '';
        },
        investorTypes() {
            return this.$jss.sitecoreContext().fundCentreConfiguration.countryInvestors.investorType || '';
        },
        literatureAuthorization() {
            return this.$jss.sitecoreContext().fundCentreConfiguration.countryInvestors.literatureAuthorization || '';
        },
        site() {
            return this.$jss.sitecoreContext().site.name || '';
        },
        itemsPerPage() {
            return this.$jss.sitecoreContext().fundCentreConfiguration.itemsPerPage || 20;
        },
        filters() {
            return this.fields.filterMappings?.value?.filters || [];
        },
        fundDetailsPageUrl() {
            return this.fields.fundDetailsPageUrl;
        },
        showFundsInFocus() {
            if (this.isExperienceEditor) return true;

            return this.activeTabKey === this.TAB_KEYS.FUNDS_IN_FOCUS;
        },
        allFundsTabTabs() {
            let tabsToShow = Object.entries(this.fields.fundCentreAllFundsTabs.value);
            tabsToShow = tabsToShow.map(([, value]) => ({
                key: this.ALL_FUNDS_TAB_KEYS[value.tabType],
                title: this.ALL_FUNDS_TAB_TITLE_KEYS[value.tabType],
            }));
            return tabsToShow;
        },
    },
    watch: {
        activeTabKey() {
            this.setPaginationData({
                skip: 0,
                take: this.itemsPerPage,
            });
            this.updateUrlParams([
                {
                    name: 'page',
                    value: 1,
                },
            ]);
        },
    },
    mounted() {
        const { activeTab } = this.$route.query;

        if (activeTab) {
            this.setActiveTab(+activeTab);
        }

        this.setFundDetailsPageUrl(this.fundDetailsPageUrl);
        this.setSelectedTab(this.ALL_FUNDS_TAB_KEYS[this.fields.fundCentreAllFundsTabs.value[0].tabType]);
    },
    methods: {
        ...mapMutations('allFunds', ['setFundDetailsPageUrl', 'setActiveTab', 'setPaginationData', 'setSelectedTab']),
        toggleTab(tabKey) {
            if (tabKey !== this.activeTabKey) {
                this.setActiveTab(tabKey);

                if (!this.isExperienceEditor) {
                    const url = new URL(window.location.href);
                    const searchParams = new URLSearchParams(url.search);

                    if (tabKey === this.TAB_KEYS.ALL_FUNDS) {
                        searchParams.delete('activeTab');
                    } else {
                        searchParams.set('activeTab', tabKey);
                    }

                    url.search = searchParams.toString();
                    window.history.replaceState(null, '', url.href);
                }
            }
        },
        getTabTitle(tabKey) {
            switch (tabKey) {
                case this.TAB_KEYS.ALL_FUNDS: {
                    return this.$t(this.TAB_TITLE_KEYS[tabKey]).replace('{token.fundsCount}', this.allFundsCount);
                }
                case this.TAB_KEYS.FUNDS_IN_FOCUS: {
                    return this.$t(this.TAB_TITLE_KEYS[tabKey]);
                }
                case this.TAB_KEYS.MY_FUNDS: {
                    // TODO: Get funds count from API
                    const fundsCount = 0;
                    return this.$t(this.TAB_TITLE_KEYS[tabKey]).replace('{token.fundsCount}', fundsCount);
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';

.tab-button {
    background: unset;
    border: unset;
    color: unset;
    padding: calc(var(--spacer) * 1.5);
    text-align: left;

    @include media-breakpoint-up(md) {
        padding: calc(var(--spacer) * 3);
    }

    &--active {
        background-color: var(--abrdn-white);
        color: var(--abrdn-black);
    }

    .bi {
        font-size: calc(var(--spacer) * 3);
        line-height: 0;
    }
}
</style>
