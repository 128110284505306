<template>
    <div>
        <button
            ref="mobileFiltersSection"
            class="d-flex align-items-center filter-selector btn-naked"
            @click="toggleFiltersAside"
        >
            <p class="field-text">{{ `Filters (${appliedOptions.length})` }}</p>
            <em class="d-flex bi-sliders ms-auto" aria-hidden="true" />
        </button>
        <AsideModal
            v-if="filters.length"
            :positionX="'left'"
            :active="isActive"
            class="modal-widow"
            @onClose="
                $emit('forget');
                toggleFiltersAside();
            "
            @enter="toggleFiltersAside()"
            @keydown.esc="isActive = false"
        >
            <div class="d-flex align-items-center filters-summury filter-dropdown-sum">
                <p class="me-3 h4">{{ $t('filters') }}</p>
                <span class="separator" />
                <p class="ms-3 body-default">{{ selectedOptions.length }} of {{ allOptions.length }}</p>
                <button
                    class="ms-auto btn-naked"
                    :aria-label="$t('close')"
                    @click="
                        $emit('forget');
                        toggleFiltersAside();
                    "
                >
                    <em class="d-flex bi-x-lg ms-2" aria-hidden="true" />
                </button>
            </div>
            <MobileBaseFiltersDropdown
                v-for="filter in filters"
                :key="filter.id"
                :filter="filter"
                :isLoading="isLoading"
                :isOpen="activeDropdown === filter.id"
                @toggleDropdown="onToggleDropdown"
                @optionSelect="(option) => $emit('optionSelect', option)"
            />
            <MobileBaseFiltersActions
                :isLoading="isLoading"
                class="filter-dropdown-nav"
                @clearFilters="
                    $emit('clearFilters');
                    toggleFiltersAside();
                "
                @filterApply="
                    $emit('filterApply');
                    toggleFiltersAside();
                "
            />
        </AsideModal>
    </div>
</template>

<script>
import MobileBaseFiltersActions from '@/components/Functional/Filters/MobileFilter/MobileBaseFiltersActions.vue';
import MobileBaseFiltersDropdown from '@/components/Functional/Filters/MobileFilter/MobileBaseFiltersDropdown.vue';
import AsideModal from '@/components/Generic/AsideModal.vue';
import DisableScrollMixin from '@/mixins/DisableScroll.mixin';

export default {
    name: 'MobileBaseFilters',
    components: {
        MobileBaseFiltersDropdown,
        AsideModal,
        MobileBaseFiltersActions,
    },
    mixins: [DisableScrollMixin],
    props: {
        filters: {
            type: Array,
            default: () => [],
            required: true,
        },
        appliedOptions: {
            type: Array,
            default: () => [],
            required: true,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        selectedOptions: {
            type: Array,
            default: () => [],
            required: true,
        },
        allOptions: {
            type: Array,
            default: () => [],
            required: true,
        },
    },
    data() {
        return {
            isActive: false,
            activeDropdown: '',
        };
    },
    methods: {
        toggleFiltersAside() {
            this.isActive = !this.isActive;
            this.toggleWindowScroll(this.isActive);
            if (this.isActive) {
                this.$emit('needAggregateCount', this.filters[0].id);
                this.activeDropdown = this.filters[0].id;
                this.$refs.mobileFiltersSection.focus();
            } else {
                this.activeDropdown = '';
                this.$emit('resetUnAppliedOptions');
            }
        },
        onToggleDropdown(filterId) {
            if (this.activeDropdown === filterId) {
                this.activeDropdown = '';
            } else {
                this.activeDropdown = filterId;
                this.$emit('needAggregateCount', filterId);
            }
        },
    },
};
</script>

<style scoped lang="scss">
.filter-dropdown-nav {
    position: absolute;
    bottom: 0;
    background: var(--abrdn-white);
    padding: calc(var(--spacer) * 2);
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.filter-dropdown-sum {
    position: absolute;
    top: 0;
    z-index: 1002;
    background: var(--abrdn-white);
    padding: 0 calc(var(--spacer) * 2);
    height: 68px;
    display: flex;
    align-items: center;
    width: 100%;
}

.filters-summury {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 20px 16px;
}

.separator {
    height: 100%;
    width: 1px;
    background: rgba(0, 0, 0, 0.2);
}

.bi-x-lg {
    font-size: 21px;
}
</style>
