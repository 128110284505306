<template>
    <component
        :is="linkComponent"
        v-if="isExperienceEditor || hasHref"
        class="sitecore-link"
        :class="linkClass"
        v-bind="attributes"
        @click.native="scrollToId(field.value.anchor)"
    >
        <slot v-if="hasSlot && replaceWithSlot" />
        <template v-else-if="isExperienceEditor"><slot /></template>
        <template v-else>{{ linkText }}<slot /></template>
        <span v-if="displayExternalIcon" class="text-nowrap">&#65279;<IconExternal class="external-icon" /></span>
        <span v-if="opensInNewWindow" class="sr-only">{{ $t('opensInNewWindow') }}</span>
    </component>
    <ScLinkPlaceholder v-else><slot /></ScLinkPlaceholder>
</template>

<script>
import { Link } from '@sitecore-jss/sitecore-jss-vue';

import ScLinkPlaceholder from '@/components/Generic/SitecoreWrappers/ScLink/ScLinkPlaceholder.vue';
import IconExternal from '@/components/Icons/icon-external.vue';

export default {
    name: 'ScLink',
    components: {
        IconExternal,
        ScLinkPlaceholder,
        ScIntegratedLink: Link,
    },
    props: {
        field: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        params: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        showExternalIcon: {
            type: Boolean,
            default: true,
        },
        replaceWithSlot: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            integratedLinkStartsWith: ['http', 'mailto:', 'tel:'],
        };
    },
    computed: {
        linkText() {
            return this.field?.value?.text || '';
        },
        linkType() {
            return this.field?.value.linktype;
        },
        isAnchorLink() {
            return this.field.value.href.includes('#') || this.linkType === 'anchor';
        },
        attributes() {
            const attrsObj = {};
            if (this.isRouterLink) {
                attrsObj.to = this.linkTo;
                attrsObj.target = this.field.value.target;
            } else if (this.isIntegratedLink) {
                attrsObj.field = this.linkTo;
            }
            return { ...this.$attrs, ...attrsObj };
        },
        linkComponent() {
            if (this.isRouterLink) {
                return 'router-link';
            }
            return 'sc-integrated-link';
        },
        linkClass() {
            if (this.isRouterLink) return 'internal-link';
            if (this.isIntegratedLink) return 'external-link';
            return '';
        },
        isRouterLink() {
            if (this.isExperienceEditor) return false;
            return !this.integratedLinkStartsWith.find((subStr) => this.field.value.href.startsWith(subStr));
        },
        hasSlot() {
            return Object.keys(this.$slots).length;
        },
        hasHref() {
            return this.field?.value?.href;
        },
        isIntegratedLink() {
            if (this.isExperienceEditor) return true;
            if (this.isRouterLink) return false;
            return this.hasSlot || this.field.value?.text;
        },
        linkTo() {
            if (this.isRouterLink) {
                if (this.$route?.query?.performanceTesting === 'true') {
                    const firstSeparator = this.field.value.href.includes(`?`) ? '&' : '?';

                    return this.field.value.href + firstSeparator + 'performanceTesting=true';
                }
                return this.isAnchorLink
                    ? { path: this.field.value.href.replace(`#${this.field.value.anchor}`, ''), hash: '' }
                    : { path: this.field.value.href, hash: this.field.value.anchor };
            } else {
                return {
                    ...this.field,
                    value: {
                        ...this.field.value,
                        href: this.getUtmLink,
                    },
                };
            }
        },
        getUtmLink() {
            const paramKeys = this.$route && Object.keys(this.$route?.query);
            const utmParams = ['utm_medium', 'utm_source', 'utm_campaign', 'utm_content', 'cn'];
            if (
                this.$route &&
                this.$jss?.sitecoreContext()?.site?.name === 'abrdnJssPersonal' &&
                this.field?.value?.href.match('advice-uk.abrdn.com') &&
                this.field?.value?.linktype === 'external' &&
                !this.isExperienceEditor &&
                paramKeys.some((key) => utmParams.includes(key))
            ) {
                const firstSeparator = this.field.value.href.includes(`?`) ? '&' : '?';
                let url = [];
                utmParams.forEach((param) => {
                    if (this.$route.query[param]) {
                        url.push(param.replace('utm_', '') + '=' + this.$route.query[param]);
                    }
                });
                const splittedUrl = this.field.value.href.split('#');
                url = url.join('&');
                url = splittedUrl[0] + firstSeparator + url;
                url = splittedUrl[1] ? url + '#' + splittedUrl[1] : url;
                return url;
            }
            return this.field?.value?.href;
        },
        displayExternalIcon() {
            if (!this.showExternalIcon || this.isExperienceEditor) return false;
            if (this.field.value.target === '_blank') return true;
            if (this.isIntegratedLink) {
                try {
                    const siteHostName = new URL(this.$jss.sitecoreContext().hostName).host.replace('www.', '');
                    const linkHostName = new URL(this.field.value.href).host.replace('www.', '');
                    if (siteHostName !== linkHostName) return true;
                } catch (e) {
                    console.warn(
                        'ScLink component failed to convert url string to URL object. Error: ',
                        e,
                        this.field.value.href,
                    );
                    return false;
                }
            }
            return false;
        },
        opensInNewWindow() {
            return this.field?.value?.target === '_blank';
        },
    },
    methods: {
        scrollToId(hash) {
            if (!this.isAnchorLink) return;
            const targetElement = document.getElementById(hash);
            // handle scroll to anchor on new opened page. Scroll will be executed in RouteHandler.vue
            if (hash && !targetElement) {
                this.$router.props = {
                    scrollToAnchor: true,
                    targetScroll: hash,
                };
            } else if (hash) {
                // handle scroll to anchor on current page
                targetElement.scrollIntoView();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.sitecore-link {
    ::v-deep a:after,
    &:after {
        display: none;
    }
}

.external-icon {
    width: 0.8em;
    height: 0.8em;
    margin-left: 6px;
    line-height: inherit;
}
.btn svg {
    vertical-align: unset;
}
</style>
