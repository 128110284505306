import Vue from 'vue';
import Router from 'vue-router';

import { virtualVectors } from './constants/vectors';
import RouteHandler from './RouteHandler.vue';

Vue.use(Router);

// supports 4 types of url: with/without language and with/without virtual path. Virtual parth configurations should be placed first
export const routePatterns = [
    ...virtualVectors.map(
        (vectorRoute) => `/:lang([a-z]{2}-[a-zA-Z]{2}|[a-z]{2})/:vectorPath(${vectorRoute.vectorPath})/:sitecoreRoute*`,
    ),
    '/:lang([a-z]{2}-[a-zA-Z]{2}|[a-z]{2})/:sitecoreRoute*',
    ...virtualVectors.map((vectorRoute) => `/:vectorPath(${vectorRoute.vectorPath})/:sitecoreRoute*`),
    '/:sitecoreRoute*',
];

export function createRouter() {
    // create an instance of vue-router and configure routes to use the RouteHandler component
    const router = new Router({
        mode: 'history',
        routes: routePatterns.map((routePattern) => {
            return {
                path: routePattern,
                component: RouteHandler,
                props: (route) => ({
                    route,
                }),
            };
        }),
        scrollBehavior(to, from, savedPosition) {
            const html = document.querySelector('html');
            if (savedPosition) {
                html.style.overflowY = 'initial';
                return { x: 0, y: 0 };
            }
            html.style.overflowY = 'scroll';
            return { x: 0, y: 0 };
        },
    });
    return router;
}
