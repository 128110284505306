<template>
    <div class="swiper-slide"><slot /></div>
</template>

<script>
export default {
    name: 'SliderSlide',
};
</script>

<style scoped></style>
