<template>
    <div
        role="button"
        :tabindex="!disabled && isVisible ? '0' : '-1'"
        class="d-flex filter-dropdown-option w-100"
        :class="{ disabled: disabled }"
        @click.stop="optionClickHandler"
        @keyup.enter="optionClickHandler"
        @keydown.space="optionClickHandler"
    >
        <Checkbox tabindex="-1" :selected="filterOption.selected" :label="filterOption.name" class="pe-none" />
        <div v-if="filterOption.showAggregateCount" class="d-flex">
            <Loader v-if="isLoading" />
            <p v-else class="ms-auto">
                {{ filterOption.aggregateCount }}
                <span class="sr-only">funds. {{ filterOption.selected ? 'checked' : 'not checked' }}.</span>
            </p>
        </div>
    </div>
</template>

<script>
import Checkbox from '@/components/Generic/Checkbox.vue';
import Loader from '@/components/Generic/Loader.vue';

export default {
    name: 'MobileBaseFiltersDropdownOption',
    components: { Loader, Checkbox },
    props: {
        filterOption: {
            type: Object,
            default: () => ({}),
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        isVisible: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        disabled() {
            return (
                ((this.filterOption.showAggregateCount ? !this.filterOption.aggregateCount : false) &&
                    !this.filterOption.selected) ||
                this.isLoading
            );
        },
    },
    methods: {
        optionClickHandler() {
            if (!this.disabled) {
                this.$emit('optionSelect', this.filterOption);
            }
        },
    },
};
</script>

<style scoped lang="scss">
.filter-dropdown-option {
    padding: 16px 24px 16px 16px;
    cursor: pointer;
    outline: 0;
    &:hover:not(.disabled),
    &:focus:not(.disabled) {
        background: var(--button-mist);
    }
    &.disabled,
    &.disabled {
        cursor: default;
        ::v-deep .checkbox-wrapper {
            cursor: default;
            opacity: 0.5;
        }
    }
}
</style>
