import axios from 'axios';

import { getApiHost } from '@/Service.api';

const apiHost = process.env.NODE_ENV === 'development' ? 'https://tst-jss.abrdn.com' : getApiHost();
export function apiSearchArticles(data) {
    return axios.post(`${apiHost}/api/articles/search`, data);
}

export function apiGetArticleFacets(data) {
    return axios.post(`${apiHost}/api/articles/facets`, data);
}

export function apiSearchTrusts(data) {
    return axios.post(`${apiHost}/api/funds/trustSearch`, data);
}

export function apiGetTrustFacets(data) {
    return axios.post(`${apiHost}/api/funds/trustFacets`, data);
}

export function apiSearchPressReleases(data) {
    return axios.post(`${apiHost}/api/pressreleases/search`, data);
}

export function apiGetPressReleasesFacets(data) {
    return axios.post(`${apiHost}/api/pressreleases/facets`, data);
}
