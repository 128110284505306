import axios from '@/api/axios';
import { getApiHost } from '@/Service.api';

const apiHost = process.env.NODE_ENV === 'development' ? 'https://tst-jss.abrdn.com' : getApiHost();

export function getPricingData(data) {
    return axios.post(`${apiHost}/api/funds/dailyPricing`, data);
}

export function getAllFundsOverview(data, { axiosConfig = {} }) {
    const {
        countryCode,
        investorTypes,
        jurisdiction,
        skip,
        take,
        displayLanguage,
        searchQuery,
        filters,
        site,
        literatureAuthorization,
    } = data;
    const payload = {
        CountryInvestors: {
            CountryCode: countryCode,
            InvestorType: investorTypes,
            Jurisdiction: jurisdiction,
            literatureAuthorization,
        },
        SearchQuery: {
            Id: searchQuery.id,
            Name: searchQuery.name,
        },
        filters,
        Skip: skip,
        Take: take,
        language: displayLanguage,
        site: site,
    };
    return axios.post(`${apiHost}/api/funds/overview`, payload, {
        ...axiosConfig,
    });
}

export function getAllFundsPrices(data, { axiosConfig = {} }) {
    const {
        countryCode,
        investorTypes,
        jurisdiction,
        skip,
        take,
        displayLanguage,
        searchQuery,
        filters,
        site,
        literatureAuthorization,
    } = data;
    const payload = {
        CountryInvestors: {
            CountryCode: countryCode,
            InvestorType: investorTypes,
            Jurisdiction: jurisdiction,
            literatureAuthorization,
        },
        SearchQuery: {
            Id: searchQuery.id,
            Name: searchQuery.name,
        },
        filters,
        Skip: skip,
        Take: take,
        language: displayLanguage,
        site: site,
    };
    return axios.post(`${apiHost}/api/funds/prices`, payload, {
        ...axiosConfig,
    });
}

export function getFundsSearchResults(data) {
    const {
        countryCode,
        investorTypes,
        jurisdiction,
        displayLanguage,
        searchFundName,
        filters,
        site,
        literatureAuthorization,
    } = data;
    const payload = {
        CountryInvestors: {
            CountryCode: countryCode,
            InvestorType: investorTypes,
            Jurisdiction: jurisdiction,
            literatureAuthorization,
        },
        filters,
        SearchQuery: {
            Name: searchFundName,
        },
        language: displayLanguage,
        site: site,
    };
    return axios.post(`${apiHost}/api/funds/search`, payload);
}

export function getFundDetails({ CountryInvestors, language, isin, site }) {
    const data = { CountryInvestors, language, isin, site };
    return axios.post(`${apiHost}/api/funds/funddetails`, data);
}

export function getFundsFacets(data) {
    const {
        countryCode,
        investorTypes,
        jurisdiction,
        displayLanguage,
        site,
        searchQuery,
        facets = [],
        filters = [],
    } = data;
    const payload = {
        search: {
            language: displayLanguage,
            countryInvestors: {
                CountryCode: countryCode,
                InvestorType: investorTypes,
                Jurisdiction: jurisdiction,
            },
        },
        SearchQuery: {
            Id: searchQuery.id,
            Name: searchQuery.name,
        },
        facets,
        filters,
        site: site,
        language: displayLanguage,
    };

    return axios.post(`${apiHost}/api/funds/fundfacets`, payload);
}

export function getFundDetailsTabData(
    {
        countryCode,
        language,
        shareClass,
        correlationFundRangeId,
        fundRangeName,
        literatureAuthorizations,
        fundName,
        fund,
        site,
        skip,
        take,
        holdingType,
        countryInvestors,
        fromDate,
        toDate,
        quarter,
        quarterly,
    },
    endpoint,
    responseType,
) {
    const payload = {
        countryCode,
        language,
        shareClass,
        correlationFundRangeId,
        fundRangeName,
        literatureAuthorizations,
        fundName,
        fund,
        site,
        skip,
        take,
        holdingType,
        responseType,
        countryInvestors,
        fromDate,
        toDate,
        quarter,
        quarterly,
    };
    return axios.post(`${apiHost}/api/${endpoint}`, payload, responseType);
}

export const getFundDetailsTabDataEndpoints = {
    MANAGEMENT: 'funds/text/management',
    PORTFOLIO_SECURITIES: 'funds/text/portfolioSecurities',
    KEY_RISK: 'funds/text/keyRisk',
    DERIVATIVE_USAGE: 'funds/text/derivativeUsage',
    CODES: 'funds/fundDetailsCodes',
    CORRELATION_MATRIX: 'funds/breakdown/correlationMatrix',
    RISK_ANALYSIS: 'funds/breakdown/risk',
    IMPORTANT_INFORMATION: 'funds/text/importantInfo',
    FUND_OBJECTIVES: 'funds/text/fundObjectives',
    LITERATURE: 'library/fund',
    FUND_RISK_STATISTICS: 'funds/funddetailsriskstatistics',
    PERFORMANCE: {
        CUMULATIVE: 'funds/performance/cumulative',
        CUMULATIVE_DAILY: 'funds/performance/cumulativeDaily',
        CUMULATIVE_CHART: 'funds/performance/cumulativeChart',
        ANNUALIZED: 'funds/performance/annualized',
        ANNUALIZED_DAILY: 'funds/performance/annualizedDaily',
        DISCRETE: 'funds/performance/discrete',
        DISCLAIMER: 'funds/text/performancesDisclaimer',
        CALENDAR: 'funds/performance/calendar',
    },
    KEY_INFORMATION: 'funds/fundDetailsKeyInformation',
    KEY_INFORMATION_DISCLAIMER: 'funds/text/keyInfoDisclaimer',
    HOLDINGS: {
        DAILY_AND_MONTHLY: 'funds/breakdown/dailyHoldings',
        TOP_TEN_HOLDINGS: 'funds/breakdown/topTenHoldings',
        DOWNLOAD_DATA: 'funds/breakdown/dailyHoldingsSheet',
        DISCLAIMER: 'funds/text/holdingsDisclaimer',
    },
    FUND_DOCUMENTS: 'library/fundHero',
    BREAKDOWN: {
        SECTOR: 'funds/breakdown/sector',
        COUNTRY: 'funds/breakdown/country',
        CREDIT: 'funds/breakdown/credit',
        MATURITY: 'funds/breakdown/maturity',
    },
    AUTHORISED_COUNTRIES: 'funds/funddetailsauthorizedcountries',
    TRADING_INFORMATION: 'funds/funddetailstradinginformation',
    PREMIUM_DISCOUNT: {
        BAR_CHART: 'funds/fundDetailsPremiumDiscount',
        LINE_CHART: 'funds/fundDetailsPremiumLineGraph',
    },
    PODCAST: 'funds/media/podcast',
    PRELIMINARY_IMPORTANT_INFO: 'funds/text/preliminaryImportantInfo',
};
