<template>
    <section class="default-section-padding background-white">
        <div class="container abrdn-table">
            <LiteratureFiltersSection :filters="filters" class="mb-4 mb-lg-5" />
            <div v-if="!showSkeleton" class="mb-4">
                <h3>{{ $t('literatureHubResultsLabel') }}</h3>
            </div>
            <LiteratureHubTable />
            <div class="d-flex flex-column-reverse flex-xl-row justify-content-between align-items-center">
                <template v-if="!showSkeleton">
                    <p class="footnote text-grey">
                        {{
                            $t('paginationDisplayingResults')
                                .replace('{token.range}', `${resultFrom} - ${resultTo}`)
                                .replace('{token.total}', `${totalResults}`)
                        }}
                    </p>
                    <BackToTopButton class="mx-0 my-3 my-lg-4" />
                </template>
                <template v-else>
                    <BaseSkeleton width="320px" height="32px" />
                    <BaseSkeleton width="320px" height="32px" class="mx-0 my-3 my-lg-4" />
                </template>
            </div>
            <Pagination
                :currentPage="currentPage"
                :maxPages="maxPages"
                class="justify-content-center mt-4 mt-md-8"
                :isLoading="isLoading"
                :showSkeleton="showSkeleton"
                @onSelect="onSelect"
            />
        </div>
    </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

import BaseSkeleton from '@/components/Generic/BaseSkeleton.vue';
import BackToTopButton from '@/components/Generic/Buttons/BackToTopButton.vue';
import Pagination from '@/components/Includes/Pagination.vue';
import LiteratureFiltersSection from '@/components/Pages/LiteratureHub/LiteratureFilters/LiteratureFiltersSection.vue';
import LiteratureHubTable from '@/components/Pages/LiteratureHub/LiteratureHubTable.vue';
import { localizeDate } from '@/mixins/Helpers.mixin';
import { updateUrlParams } from '@/mixins/Helpers.mixin';

export default {
    name: 'LiteratureHub',
    components: { Pagination, LiteratureHubTable, LiteratureFiltersSection, BackToTopButton, BaseSkeleton },
    mixins: [localizeDate, updateUrlParams],
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    data() {
        return {
            currentPage: 1,
            isMounted: false,
        };
    },
    computed: {
        ...mapState('literatureHub', [
            'documents',
            'allDocumentsCount',
            'paginationData',
            'searchQuery',
            'isLoading',
            'isPaginationLoading',
            'appliedFilters',
        ]),
        showSkeleton() {
            return this.isLoading && !this.isPaginationLoading;
        },
        maxPages() {
            return Math.ceil(this.allDocumentsCount / this.paginationData?.take) || 1;
        },
        itemsPerPage() {
            return this.fields?.itemsPerPage?.value || 20;
        },
        totalResults() {
            return this.allDocumentsCount;
        },
        resultFrom() {
            if (this.totalResults === 0) return 0;
            return this.paginationData?.take * (this.currentPage - 1) + 1;
        },
        resultTo() {
            return this.paginationData?.take * (this.currentPage - 1) + this.documents?.length;
        },
        filters() {
            return this.fields.filterMappings?.value || [];
        },
    },
    watch: {
        searchQuery: {
            handler: function () {
                if (!this.isMounted) return false;
                this.resetPagination();
                this.fetchData();
            },
            deep: true,
        },
        appliedFilters: {
            handler: function () {
                if (!this.isMounted) return false;
                this.resetPagination();
                this.fetchData();
            },
            deep: true,
        },
    },
    async mounted() {
        const { page, search } = this.updateUrlParams();
        this.currentPage = Number(page || 1);
        this.updatePaginationData();
        this.updateSearchData(search);
        await this.fetchData();
        this.isMounted = true;
    },
    methods: {
        ...mapActions('literatureHub', ['fetchDocuments']),
        ...mapMutations('literatureHub', ['setPaginationData', 'setDocuments', 'setSearchQuery', 'resetDocumentsData']),
        async fetchData() {
            try {
                const data = await this.fetchDocuments();
                if (data === null) {
                    this.resetDocumentsData();
                }
                if (data) {
                    this.setDocuments(data);
                }
            } catch (e) {
                console.error(e);
            }
        },
        onSelect(currentPage) {
            this.currentPage = currentPage;
            this.updateUrlParams([
                {
                    name: 'page',
                    value: this.currentPage,
                },
            ]);
            this.updatePaginationData();
            this.fetchData();
        },
        resetPagination() {
            this.currentPage = 1;
            this.updatePaginationData();
            this.updateUrlParams([
                {
                    name: 'page',
                    value: this.currentPage,
                },
            ]);
        },
        updatePaginationData() {
            this.setPaginationData({
                skip: (this.currentPage - 1) * this.itemsPerPage,
                take: this.itemsPerPage,
            });
        },
        updateSearchData(search) {
            this.setSearchQuery({
                id: '',
                name: search ? decodeURIComponent(search) : '',
            });
        },
    },
};
</script>

<style scoped lang="scss"></style>
