<template>
    <div class="background-white navigation-wrapper" :style="{ top: `${$root.headerHeight}px` }">
        <div class="container d-flex align-items-center relative">
            <em v-show="tabs.length" ref="navPrev" class="bi-chevron-left" aria-label="curtain left" />
            <div v-show="tabs.length" class="curtain-left" />
            <Slider :sliderOptions="sliderOptions" class="w-100">
                <template #slides>
                    <SliderSlide v-for="(tab, index) in tabs" :key="tab.id + 'page-level-tab'">
                        <ScText
                            :field="tab.fields.title"
                            class="page-level-tabs pb-1 page-level-tabs-text"
                            tabindex="0"
                            :class="{ active: index === activeTab }"
                            @click="toggleTab(index)"
                            @keyup.enter="toggleTab(index)"
                        />
                    </SliderSlide>
                </template>
            </Slider>
            <em v-show="tabs.length" ref="navNext" class="bi-chevron-right" aria-label="curtain right" />
            <div v-show="tabs.length" class="curtain-right" />
        </div>
    </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { Keyboard, Navigation } from 'swiper';

import Slider from '@/components/Functional/Slider/Slider';
import SliderSlide from '@/components/Functional/Slider/SliderSlide';

export default {
    name: 'Tabs',
    components: { ScText: Text, Slider, SliderSlide },
    props: {
        fields: {
            type: Object,
            default: () => ({}),
        },
        activeTab: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            tabs: [],
            sliderOptions: null,
        };
    },
    mounted() {
        let tabs = [];

        if (this.fields?.items?.length) {
            tabs = this.fields.items;
            tabs = tabs.map((item, i) => {
                return { ...item, id: 'abrdn-page-level-tab-' + i };
            });
        }
        const query = this.$route.query;
        let initTab = 0;
        if (query.tab) {
            initTab = +query.tab - 1;
            this.$emit('onTabChange', +query.tab - 1);
        } else {
            initTab = 0;
            this.$emit('onTabChange', 0);
        }
        this.tabs = tabs;
        if (tabs.length) {
            this.sliderOptions = {
                modules: [Navigation, Keyboard],
                slidesPerView: 'auto',
                spaceBetween: 24,
                slidesPerGroup: 1,
                initialSlide: initTab,
                navigation: {
                    nextEl: this.$refs.navNext,
                    prevEl: this.$refs.navPrev,
                },
                keyboard: {
                    enabled: true,
                },
            };
        }
    },
    methods: {
        toggleTab(tabIndex) {
            if (tabIndex !== this.activeTab) {
                this.$emit('onTabChange', tabIndex);
                if (this.$jss.sitecoreContext().pageState === 'normal') {
                    history.pushState(null, null, tabIndex ? `?tab=${tabIndex + 1}` : this.$route.path);
                }
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';

@include media-breakpoint-down(lg) {
    .page-level-tabs {
        outline: 0px;
    }
}
.page-level-tabs-wrapper {
    max-height: 60px;
    overflow: hidden;
    border-bottom: 1px solid var(--digital-steel-grey);
}
.navigation-wrapper {
    position: sticky;
    border-bottom: 1px solid var(--digital-steel-grey);
    z-index: 2;
}
.relative {
    position: relative;
}
.bi-chevron-left:not(.swiper-button-disabled) + .curtain-left {
    display: block;
    height: 72px;
    z-index: 2;
    left: -10px;
    width: 78px;
    position: absolute;
    background: linear-gradient(270deg, #fff 47.06%, rgba(255, 255, 255, 0) 95.59%);
    transform: scale(-1, 1);
    pointer-events: none;
}
.bi-chevron-right:not(.swiper-button-disabled) + .curtain-right {
    display: block;
    height: 72px;
    z-index: 2;
    width: 78px;
    right: -10px;
    position: absolute;
    background: linear-gradient(270deg, #fff 47.06%, rgba(255, 255, 255, 0) 95.59%);
    pointer-events: none;
}
.bi-chevron-left,
.bi-chevron-right {
    position: absolute;
    opacity: 1;
    transition: opacity 0.3s ease-out;
    min-width: 24px;
    display: flex;
    z-index: 3;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    &:hover {
        border-radius: 12px;
        background: var(--abrdn-black);
        color: white;
    }
}

.bi-chevron-right {
    right: 15px;
}
.swiper-button-disabled {
    opacity: 0;
}
.tabs-wrapper {
    height: 72px;
}
::v-deep .swiper-slide {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
}

.page-level-tabs {
    border-bottom: 4px solid transparent;
    margin-bottom: 18px;
    margin-top: 18px;
    cursor: pointer;
    &:hover {
        border-bottom: 4px solid var(--abrdn-black);
    }
}
.active {
    border-bottom: 4px solid var(--abrdn-black);
}

.page-level-tabs-text {
    letter-spacing: 0.02em;
    text-align: left;
    font-weight: 600;
    white-space: nowrap;
    font-size: 20px;
    line-height: 24px;
}

i {
    cursor: pointer;
}
</style>
