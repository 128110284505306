import config from '@/temp/config.js';

export function getApiKey() {
    if (typeof window !== 'undefined') {
        return window?.app?.sitecoreApiKey || config.sitecoreApiKey;
    }
    return process?.env?.JSS_API_KEY || config.sitecoreApiKey;
}

export function getApiHost() {
    if (typeof window !== 'undefined') {
        return window?.app?.sitecoreApiHost || config.sitecoreApiHost;
    }
    return config.sitecoreApiHost;
}
