<template>
    <div
        :id="anchorId"
        ref="infographic"
        :data-e2e-component-uid="rendering.uid"
        :style="{ width: '100%' }"
        :class="{ 'experience-editor-holder': isExperienceEditor }"
    >
        <ScText
            v-if="fields.title || isExperienceEditor"
            tag="p"
            class="mb-2 chart-title text-grey"
            :field="fields.title"
        />
    </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

export default {
    name: 'Infographics',
    components: {
        ScText: Text,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    mounted() {
        if (this.fields?.source?.value && this.fields?.id?.value) {
            const Infogram = document.createElement('script');
            Infogram.src = this.fields.source.value;
            Infogram.id = this.fields.id.value;
            Infogram.type = 'text/javascript';
            this.$refs.infographic.appendChild(Infogram);
        }
    },
};
</script>

<style scoped lang="scss">
.chart-title {
    font-weight: 700;
}
</style>
