<template>
    <RelatedBlock v-bind="passThroughProps" />
</template>

<script>
import RelatedBlock from './RelatedBlock';

export default {
    name: 'RelatedCapabilities',

    components: {
        RelatedBlock,
    },

    props: {
        rendering: {
            type: Object,
            default: () => ({}),
        },
        fields: {
            type: Object,
            default: () => ({}),
        },
        sidebar: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        items() {
            return this.fields?.relatedCapabilities?.value || [];
        },
        passThroughProps() {
            return {
                rendering: this.rendering,
                sidebar: this.sidebar,
                title: this.$t('relatedCapabilitiesTitle'),
                items: this.items,
            };
        },
    },
};
</script>
