<template>
    <section :class="{ [backgroundClass]: backgroundClass }">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h2>Dropdowns</h2>
                </div>
                <div class="col-md-3">
                    <Placeholder name="abrdn-form-elem" :rendering="rendering" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';

import { backgroundClass } from '@/mixins/Helpers.mixin';

export default {
    name: 'StyleguideFormFields',
    components: { Placeholder },
    mixins: [backgroundClass],
    props: {
        rendering: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>

<style scoped lang="scss">
section {
    padding: 100px 0;
}
</style>
