<template>
    <div
        class="d-flex w-100 align-items-center checkbox-wrapper"
        tabindex="-1"
        @click.stop="!disabled && setState()"
        @keyup.enter.stop="!disabled && setState()"
    >
        <div
            class="checkbox d-flex"
            :class="{ selected: isSelected, disabled: disabled }"
            :style="customColor && `--custom-color: ${customColor}`"
        >
            <em v-if="isSelected" class="d-flex bi-check text-white" width="30" aria-hidden="true" />
        </div>
        <p class="field-dropdown-expanded-label ms-2" :class="{ disabled: disabled }">{{ label }}</p>
    </div>
</template>

<script>
export default {
    name: 'Checkbox',
    props: {
        selected: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: '',
        },
        customColor: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            isSelected: this.selected,
        };
    },
    watch: {
        selected() {
            if (this.isSelected !== this.selected) {
                this.isSelected = this.selected;
            }
        },
    },
    methods: {
        setState() {
            this.isSelected = !this.isSelected;
            this.$emit('onChange', this.isSelected);
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
.checkbox {
    // border: 1px solid var(--button-night);
    box-sizing: border-box;
    border-radius: 6px;
    min-width: 24px;
    min-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0px 0px 0px 1px var(--custom-color, var(--button-night));
    &.selected {
        background: var(--custom-color, var(--abrdn-black));
    }
    &:hover:not(.selected):not(.disabled),
    &:focus:not(.selected):not(.disabled) {
        background: var(--custom-color, var(--button-mist));
        box-shadow: inset 0px 0px 0px 2px var(--custom-color, rgba(0, 0, 0, 0.5));
    }
    &:hover.selected {
        background: var(--custom-color, var(--button-night));
    }
    &.disabled {
        border-color: rgba(63, 63, 63, 0.5) !important;
        box-shadow: inset 0px 0px 0px 1px rgba(63, 63, 63, 0.5);
    }
    &.selected.disabled {
        background: rgba(0, 0, 0, 0.5);
        border-color: rgba(0, 0, 0, 0.5);
        box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.5);
    }
}

.disabled {
    color: var(--button-mist);
}
.bi-check {
    // width: 13.15px;
    font-size: 22px;
}

.checkbox-wrapper {
    cursor: pointer;
    @include media-breakpoint-down(lg) {
        outline: 0px;
    }
}
</style>
