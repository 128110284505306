<template>
    <div
        role="button"
        :tabindex="isFocusable ? '0' : '-1'"
        class="d-flex filter-dropdown-option"
        :class="{ disabled: isDisabledOption }"
        @keydown.space.prevent
        @keydown.down.prevent="$emit('selectFilterOptionWithArrow', 'down')"
        @keydown.up.prevent="$emit('selectFilterOptionWithArrow', 'up')"
    >
        <Checkbox class="pe-none" tabindex="-1" :selected="filterOption.selected" :label="filterOption.name" />
        <div v-if="filterOption.showAggregateCount" class="d-flex pe-none loader-wrapper">
            <Loader v-if="isLoading" />
            <p v-else class="ms-auto">
                {{ filterOption.aggregateCount }}
                <span class="sr-only"
                    >{{ fundsDictionaryItem }}.
                    {{ filterOption.selected ? $t('checkboxChecked') : $t('checkboxNotChecked') }}.</span
                >
            </p>
        </div>
    </div>
</template>

<script>
import Checkbox from '@/components/Generic/Checkbox.vue';
import Loader from '@/components/Generic/Loader.vue';

export default {
    name: 'FilterDropdownOption',
    components: { Loader, Checkbox },
    props: {
        filterOption: {
            type: Object,
            default: () => ({}),
            required: true,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        isVisible: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isDisabledOption() {
            return (
                ((this.filterOption.showAggregateCount ? !this.filterOption.aggregateCount : false) &&
                    !this.filterOption.selected) ||
                this.isLoading
            );
        },
        isFocusable() {
            return this.isVisible && !this.isDisabledOption;
        },
        fundsDictionaryItem() {
            return this.$t('allFunds').split(' ')[1];
        },
    },
};
</script>

<style scoped lang="scss">
.filter-dropdown-option {
    padding: 12px 16px;
    cursor: pointer;
    &:hover:not(.disabled),
    &:focus:not(.disabled) {
        background: var(--button-mist);
    }
    &.disabled {
        pointer-events: none;
        ::v-deep .checkbox-wrapper {
            cursor: default;
            opacity: 0.5;
        }
    }
}
</style>
