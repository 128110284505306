<template>
    <div>
        <div v-if="isLoading">
            <BaseSkeleton height="120px" />
        </div>

        <template v-else-if="tableData">
            <div class="position-relative">
                <div
                    id="currentDataWrapper"
                    class="abrdn-table"
                    :class="[{ scrollable: deviceType === 'mobile' }, { [gradientPosition]: gradientPosition }]"
                >
                    <table
                        id="currentDataTable"
                        class="not-striped"
                        tabindex="0"
                        :aria-label="$t('fundDetailsPremiumDiscountCurrentTitle')"
                    >
                        <thead>
                            <tr>
                                <th v-for="th in tableData.thead" :key="th.key" scope="col">{{ th.name }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <template v-for="(td, index) in tableData.tbody">
                                    <th v-if="index === 0" :key="index" scope="row">
                                        {{ td.value || $t('notAvailableShortMessage') }}
                                    </th>
                                    <td v-else :key="td.key">
                                        {{ td.value || $t('notAvailableShortMessage') }}
                                    </td>
                                </template>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <CustomScroll
                v-if="deviceType === 'mobile'"
                wrapperId="currentDataWrapper"
                tableId="currentDataTable"
                @changeGradientPosition="changeGradientPosition"
            />
        </template>

        <div v-else class="body-default">{{ $t('noResultsMessage') }}</div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import CustomScroll from '@/components/Functional/CustomScroll';
import BaseSkeleton from '@/components/Generic/BaseSkeleton';
import { standardFormat } from '@/constants/dateFormats';
import DeviceType from '@/mixins/DeviceType.mixin';
import { formatCurrency, formatNumber, localizeDate } from '@/mixins/Helpers.mixin';

export default {
    name: 'FundDetailsPremiumDiscountBarChartCurrentDataTable',

    components: {
        BaseSkeleton,
        CustomScroll,
    },

    mixins: [localizeDate, DeviceType, formatCurrency, formatNumber],

    data() {
        return {
            gradientPosition: 'gradient-right',
        };
    },

    computed: {
        ...mapState('fundDetails', {
            data: (state) => state.premiumBarChartData.data?.premiumDiscountTable,
            isLoading: (state) => state.premiumBarChartData.loading,
        }),

        tableDataWithPossibleValues() {
            if (!this.data) return;

            return {
                thead: [
                    {
                        key: 'date',
                        name: this.$t('fundDetailsPremiumDiscountDates'),
                    },
                    {
                        key: 'currency',
                        name: this.$t('fundDetailsPremiumDiscountCurrency'),
                    },
                    {
                        key: 'nav',
                        name: this.$t('fundDetailsPremiumDiscountNav'),
                    },
                    {
                        key: 'midpoint',
                        name: this.$t('fundDetailsPremiumDiscountMidpoint'),
                    },
                    {
                        key: 'premiumDiscount',
                        name: this.$t('fundDetailsPremiumDiscount'),
                    },
                ],
                tbody: [
                    {
                        key: 'date',
                        value: this.localizeDate(this.data.date, standardFormat),
                    },
                    {
                        key: 'currency',
                        value: this.data.currency,
                    },
                    {
                        key: 'nav',
                        value: this.data.nav ? this.formatCurrency(this.data.nav, this.data.currency, 'compact') : '',
                    },
                    {
                        key: 'midpoint',
                        value: this.data.midpoint ? this.formatNumber(this.data.midpoint) : '',
                    },
                    {
                        key: 'premiumDiscount',
                        value: this.data.premiumDiscount ? `${this.formatNumber(this.data.premiumDiscount)}%` : '',
                    },
                ],
            };
        },

        tableData() {
            if (!this.data) return;
            return {
                thead: this.tableDataWithPossibleValues.thead.filter((item) => this.data[item.key]),
                tbody: this.tableDataWithPossibleValues.tbody.filter((item) => this.data[item.key]),
            };
        },
    },

    methods: {
        changeGradientPosition(val) {
            this.gradientPosition = val;
        },
    },
};
</script>
