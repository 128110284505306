<template>
    <div class="d-flex gap-1">
        <div v-if="author.image.src">
            <StaticImage
                :src="author.image.src"
                :alt="author.image.alt"
                class="author-image"
                :srcSet="[{ mw: 50 }, { mw: 100 }]"
                sizes="48px"
            />
        </div>
        <div class="align-self-center">
            <div class="inline-link inline-link--small">{{ author.name }}</div>
            <div v-if="author.jobTitle" class="mt-1 body-small text-grey">{{ author.jobTitle }}</div>
        </div>
    </div>
</template>

<script>
import StaticImage from '@/components/Generic/SitecoreWrappers/StaticImage';

export default {
    name: 'ArticleAuthor',
    components: { StaticImage },
    props: {
        author: {
            type: Object,
            default: null,
        },
    },
};
</script>

<style lang="scss" scoped>
.author-image {
    &,
    & ::v-deep img {
        display: block;
        width: calc(var(--spacer) * 6);
        height: calc(var(--spacer) * 6);
        object-fit: cover;
        border-radius: 50%;
    }
}
</style>
