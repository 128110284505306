<template>
    <div :id="anchorId" class="ps-2 pe-2 ps-md-6 pe-md-5">
        <div class="mt-md-1 ps-1">
            <p class="form-label">{{ $t('languageSelectorTitle') }}</p>
        </div>
        <div v-if="getLanguageVersions && getLanguageVersions.length" class="ps-1">
            <div
                v-for="(item, i) in getLanguageVersions"
                :key="i"
                class="select-item d-flex py-1 mt-1"
                tabindex="0"
                @click="selectOption(item)"
                @keyup.enter="selectOption(item)"
            >
                <div
                    class="outer-circle"
                    :style="{ borderWidth: selectedOption.hreflang === item.hreflang ? '2px' : '1px' }"
                >
                    <div v-if="selectedOption.hreflang === item.hreflang" class="inner-circle" />
                </div>
                <p class="ms-2 body-default">{{ item.label }}</p>
            </div>
        </div>
        <hr class="mt-5" />
        <div class="my-3">
            <AbrdnButton :buttonType="'secondary'" @click.native="$emit('goBack')">
                <span>{{ $t('cancel') }}</span>
            </AbrdnButton>
            <AbrdnButton class="ms-2" :buttonType="'primary'" @click.native="changeLanguage">
                <span>{{ $t('change') }}</span>
            </AbrdnButton>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton.vue';

export default {
    name: 'ChangeLanguage',
    components: {
        AbrdnButton,
    },
    props: {
        selected: {
            type: Number,
            default: 0,
        },
        items: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            selectedOption: {},
        };
    },
    computed: {
        ...mapGetters('language', ['getLanguageVersions']),
    },
    mounted() {
        const lang = this.$jss.sitecoreContext()?.displayLanguage;
        this.selectedOption = this.getLanguageVersions.find((item) => item.hreflang === lang);
    },
    methods: {
        changeLanguage() {
            this.$router.push(this.selectedOption.href);
        },
        selectOption(item) {
            this.selectedOption = item;
        },
    },
};
</script>

<style scoped lang="scss">
.outer-circle {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    border: 1px solid var(--abrdn-black);
    display: flex;
    justify-content: center;
    align-items: center;
}
.inner-circle {
    width: 14px;
    height: 14px;
    border-radius: 7px;
    background: var(--abrdn-black);
}

.select-item {
    cursor: pointer;
}

hr {
    background-color: rgba(0, 0, 0, 0.3);
}
</style>
