export const getOption = {
    methods: {
        getOption(id) {
            const filterFacets = this.filtersFacets.find((option) => option.name === id);
            //We should let user deselect selected option even if there are no results for this filter
            const approvedFilters = this.approvedFilters.find((option) => option.id === id);
            return filterFacets || approvedFilters || {};
        },
    },
};

export const disabledOption = {
    methods: {
        disabledOption(id) {
            const option = this.getOption(id);
            return option.selected || option.aggregateCount ? false : true;
        },
    },
};
export const filterOptions = {
    computed: {
        filterOptions() {
            return this.filter.options.filter((option) => option.aggregateCount !== null);
        },
    },
};
