export default {
    id: 'abe32a60-3aa2-4fb7-98af-3d02f7b14076',
    name: 'Investment Solutions',
    displayName: 'Investment Solutions',
    fields: {
        title: {
            value: 'Change Language',
        },

        items: [
            {
                id: '96d8a43a-84ae-4dc5-9b42-c7ab67c65f17',
                name: 'Banners',
                displayName: 'Banners',
                fields: {
                    languageSelector: {},
                },
            },
        ],
    },
    hideTitle: {},
};
