<template>
    <section :id="anchorId" :data-e2e-component-uid="rendering.uid" class="short-section default-section-margin">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div
                        class="call-out-with-options py-xl-6 py-3 ps-xl-8 d-flex align-items-start"
                        :class="backgroundClass"
                    >
                        <div
                            v-if="isImageExist || isExperienceEditor"
                            class="call-out-with-options__image me-6 flex-shrink-0 d-none d-xl-block"
                        >
                            <WrappedImage
                                :media="fields.icon"
                                :srcSet="[{ mw: 200 }, { mw: 300 }, { mw: 400 }]"
                                sizes="160px"
                            />
                            <ScText
                                v-if="!isPictogram || isExperienceEditor"
                                tag="p"
                                :field="fields.employeeName"
                                class="background-to-change footnote footnote--bold mt-2"
                            />
                            <ScText
                                v-if="!isPictogram || isExperienceEditor"
                                tag="p"
                                :field="fields.jobTitle"
                                class="background-to-change footnote mt-1"
                            />
                        </div>
                        <div class="call-out-with-options__content px-xl-3 px-2 flex-grow-1">
                            <ScText tag="h3" :field="fields.title" class="background-to-change" />
                            <div
                                class="call-out-with-options__content__dropdown d-flex align-items-xl-end y flex-xl-row flex-column align-items-start mt-3 mt-md-4"
                            >
                                <AbrdnSelect
                                    :fields="dropdownProps"
                                    class="flex-grow-1 w-100"
                                    @updateValue="updateValue"
                                />
                                <AbrdnButton
                                    :disabled="!selectedOption && !isExperienceEditor"
                                    buttonType="primary"
                                    class="ms-xl-4 mt-3 mt-xl-0 flex-shrink-0 background-to-change"
                                    :linkField="!isExperienceEditor ? ctaData : false"
                                    :showExternalIcon="false"
                                >
                                    <ScText tag="p" :field="fields.ctaLabel" />
                                    <IconArrowRight class="button-arrow ms-1" aria-hidden="true" />
                                </AbrdnButton>
                            </div>
                            <div
                                class="call-out-with-options__rating mt-xl-4 background-to-change"
                                :class="{ 'mt-3': Number(fields.stars.value) || starsDescription.value }"
                            >
                                <Rating v-if="Number(fields.stars.value)" :rating="Number(fields.stars.value)" />
                                <ScText tag="p" :field="starsDescription" class="mt-2 mt-xl-1 body-small" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton';
import AbrdnSelect from '@/components/Generic/FormElements/FormFields/AbrdnSelect';
import WrappedImage from '@/components/Generic/SitecoreWrappers/WrappedImage';
import IconArrowRight from '@/components/Icons/icon-arrow-right';
import Rating from '@/components/Includes/Rating';
import { backgroundClass } from '@/mixins/Helpers.mixin';

export default {
    name: 'CallOutSection',
    components: { Rating, WrappedImage, IconArrowRight, AbrdnButton, AbrdnSelect, ScText: Text },
    mixins: [backgroundClass],
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    data() {
        return {
            selectedOption: undefined,
        };
    },
    computed: {
        isImageExist() {
            return this.fields.icon.value?.src;
        },
        isPictogram() {
            return this.fields.icon.value?.['data-type'] === 'image/svg+xml';
        },
        dropdownOptions() {
            if (!this.fields.items.length) {
                return [];
            }
            return this.fields.items.map((item) => {
                const { text, href, linktype, url, target } = item.fields.dropdownOption.value;
                return {
                    name: text,
                    displayName: text,
                    url: url || href,
                    target,
                    linktype,
                };
            });
        },
        dropdownProps() {
            return {
                label: {
                    value: this.fields?.description.value,
                },
                placeholder: {
                    value: this.fields.dropdownPlaceholder.value,
                },
                options: {
                    value: this.dropdownOptions,
                },
            };
        },
        ctaData() {
            return {
                value: {
                    text: this.fields.ctaLabel?.value,
                    href: this.selectedOption?.url || '',
                    target: this.selectedOption?.target || '',
                    backgroundToChange: false,
                    linktype: this.selectedOption?.linktype,
                },
            };
        },
        starsDescription() {
            return {
                ...this.fields.starsDescription,
                value: this.fields.starsDescription.value.replace('{token.stars}', this.fields.stars.value),
            };
        },
    },
    methods: {
        updateValue({ value }) {
            this.selectedOption = this.dropdownOptions.find((option) => option.displayName === value);
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
.button-arrow {
    width: 16px;
    height: 16px;
}

.call-out-with-options__image {
    img,
    ::v-deep img {
        width: 160px;
        height: 160px;
        object-fit: cover;
    }
}
.call-out-with-options__content {
    @include media-breakpoint-up(lg) {
        max-width: 666px;
    }
}
.rating {
    line-height: 1;
}
.rating ::v-deep svg {
    height: 20px;
    width: 20px;
}
</style>
