<template>
    <section :id="anchorId" :data-e2e-component-uid="rendering.uid" class="py-3" :class="backgroundClass">
        <div class="container">
            <Placeholder class="mb-4" :rendering="rendering" name="abrdn-tiles-heading" />
            <div class="row">
                <div
                    v-for="item in fields.items"
                    :key="item.id"
                    class="col-12 col-md mw-0"
                    :class="[highlightItemColClass]"
                >
                    <DescriptiveHighlightTile :fields="item.fields" :numberOfItems="numberOfItems" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';

import DescriptiveHighlightTile from '@/components/Tiles/DescriptiveHighlightTile';
import { backgroundClass } from '@/mixins/Helpers.mixin';

export default {
    name: 'DescriptiveHighlightSection',
    components: { DescriptiveHighlightTile, Placeholder },
    mixins: [backgroundClass],
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        highlightItemColClass() {
            return this.fields.items.length >= 4 ? 'col-md-6 col-xl' : '';
        },
        numberOfItems() {
            return this.fields.items.length;
        },
    },
};
</script>

<style scoped lang="scss">
.mw-0 {
    min-width: 0;
}
</style>
