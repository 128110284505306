export function baseFilterState(apiRequests, overrides) {
    let state = {
        articles: [],
        filters: [],
        filtersForApprove: [],
        approvedFilters: [],
        filterMappings: { filters: [] },
        filtersFacets: [],
        emptyFacets: [],
        totalResults: 0,
        loading: true,
        approvedFiltersBuffer: [],
        page: 1,
        take: 20,
        api: {
            searchRequest: undefined,
            facetsRequest: undefined,
        },
        baseUrl: '',
    };
    state = { ...state, ...overrides };
    state.api = { ...state.api, ...apiRequests };
    return state;
}
