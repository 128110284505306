<template>
    <div>
        <h4 class="mb-4">{{ title }}</h4>
        <FundDetailsTabSkeleton v-if="loading" />
        <AlertMessageBase v-else-if="error" type="error" :title="$t(error)" />
        <FundDetailsGeneralPerformanceTable
            v-else-if="performanceSet.length"
            :tabData="tabData"
            :footnoteText="footnoteText"
            :wrapperId="wrapperId"
            :tableId="tableId"
        />
        <div v-else class="body-default">{{ $t('noResultsMessage') }}</div>
    </div>
</template>

<script>
import AlertMessageBase from '@/components/Generic/AlertMessageBase';
import FundDetailsTabSharedLogic from '@/components/Pages/FundsCenter/FundDetails/FundDetailsTabSharedLogic.mixin';
import FundDetailsTabSkeleton from '@/components/Pages/FundsCenter/FundDetails/FundDetailsTabSkeleton';

import FundDetailsGeneralPerformanceTable from '../FundDetailsGeneralPerformanceTable';

export default {
    name: 'PerformanceTableBase',

    components: {
        AlertMessageBase,
        FundDetailsGeneralPerformanceTable,
        FundDetailsTabSkeleton,
    },

    mixins: [FundDetailsTabSharedLogic],

    props: {
        title: {
            type: String,
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        error: {
            type: String,
            default: null,
        },
        tabData: {
            type: Object,
            default: null,
        },
        fetchTabData: {
            type: Function,
            required: true,
        },
        footnoteText: {
            type: String,
            required: true,
        },
        wrapperId: {
            type: String,
            required: true,
        },
        tableId: {
            type: String,
            required: true,
        },
    },

    computed: {
        performanceSet() {
            return this.tabData?.performanceSet || [];
        },
    },
};
</script>
