<template>
    <Portal>
        <transition name="fade" @enter="toggleFocus" @leave="toggleFocus">
            <div v-if="renderContent" class="renderer-wrapper">
                <transition name="fade" @enter="toggleFocus" @leave="toggleFocus">
                    <div
                        v-show="isModalOpen"
                        ref="modalBox"
                        tabindex="-1"
                        role="dialog"
                        :aria-label="ariaLabel"
                        class="modal"
                        :class="{ 'modal--overflow': allowOuterOverflow }"
                        @keydown.esc="closeMethodHandler('esc')"
                    >
                        <div class="container">
                            <div
                                role="button"
                                tabindex="-1"
                                :aria-label="$t('closeModal')"
                                class="modal-overlay"
                                :class="{ inactive: !availableCloseMethods.overlay }"
                                @click="closeMethodHandler('overlay')"
                            />
                            <div class="modal-main">
                                <slot />
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </transition>
    </Portal>
</template>

<script>
import { Portal } from '@linusborg/vue-simple-portal';

import DisableScroll from '@/mixins/DisableScroll.mixin';

export default {
    name: 'Modal',
    components: { Portal },
    mixins: [DisableScroll],
    props: {
        ariaLabel: {
            type: String,
            default: () => 'Modal window',
        },
        closeMethods: {
            type: Array,
            default: () => ['Keydown', 'Overlay'],
        },
        allowOuterOverflow: {
            type: Boolean,
            default: () => false,
        },
        isModalOpen: {
            type: Boolean,
            default: () => false,
        },
        renderContent: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            lastFocusElement: null,
        };
    },
    computed: {
        availableCloseMethods() {
            if (!this.closeMethods) return {};
            return {
                esc: this.closeMethods.includes('Keydown'),
                overlay: this.closeMethods.includes('Overlay'),
            };
        },
    },
    watch: {
        isModalOpen: {
            handler: function (val) {
                this.toggleWindowScroll(val);
                if (val) {
                    this.lastFocusElement = document.activeElement;
                }
            },
        },
    },
    methods: {
        closeMethodHandler(method) {
            if (!this.availableCloseMethods[method]) return;
            this.$emit('closeModal');
        },
        toggleFocus() {
            this.$nextTick(() => {
                this.isModalOpen && this.lastFocusElement ? this.$refs.modalBox.focus() : this.lastFocusElement.focus();
            });
        },
    },
};
</script>

<style scoped lang="scss">
.renderer-wrapper {
    z-index: 5000;
    position: relative;
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
}
.modal--overflow {
    overflow: auto;
}
.modal-overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
}

.modal-overlay.inactive {
    cursor: default;
}

.modal-main {
    z-index: 1;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    pointer-events: none;
    width: 100%;
    align-items: center;
    & ::v-deep > * {
        pointer-events: all;
    }
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s linear;
    .modal-main {
        transition: transform 0.2s ease-in-out;
    }
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
    transition-timing-function: linear;
    .modal-main {
        transform: translateY(10%);
        transition-timing-function: ease-out;
    }
}
</style>
