<template>
    <section :class="{ [container]: true, [backgroundClass]: !container, 'py-4': !container }" class="risk-warning">
        <div
            :class="{
                container: !container,
                'risk-warning-wrapper py-4 px-md-3 px-xl-5': container,
                [backgroundClass]: true,
            }"
        >
            <div class="row">
                <div class="col">
                    <ScText class="background-to-change" tag="h5" :field="fields.title" />
                    <ScText class="background-to-change mt-3" tag="p" :field="fields.description" />
                    <div v-if="hasCta" class="risk-warning__cta mt-2">
                        <ScLink
                            class="inline-link inline-link--small background-to-change revert-underline"
                            :field="fields.link"
                        />
                    </div>
                    <div v-if="hasDescriptionExtra" class="risk-warning__additional collapse">
                        <ScText class="background-to-change mt-2" tag="p" :field="fields.descriptionExtra" />
                    </div>
                    <button
                        v-if="hasDescriptionExtra"
                        v-collapse-target="'.risk-warning__additional'"
                        aria-expanded="false"
                        class="btn border-0 text-link p-0 mt-3 collapsed background-to-change risk-warning__additional-toggler"
                        type="button"
                    >
                        <span class="more">{{ $t('showMore') }}</span>
                        <span class="less">{{ $t('showLess') }}</span>
                        <span class="chevron"><IconChevronQuickLinks /></span>
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { mapMutations, mapState } from 'vuex';

import ScLink from '@/components/Generic/SitecoreWrappers/ScLink.vue';
import IconChevronQuickLinks from '@/components/Icons/icon-chevron-quick-links.vue';
import Collapse from '@/mixins/Collapse.mixin.vue';

export default {
    name: 'RiskWarningContent',
    components: {
        IconChevronQuickLinks,
        ScText: Text,
        ScLink,
    },
    mixins: [Collapse],
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    data() {
        return {
            riskWarningNumber: null,
        };
    },
    computed: {
        ...mapState('riskWarning', ['amountOfRiskWarningElements']),
        hasDescriptionExtra() {
            return this.fields?.descriptionExtra.value;
        },
        backgroundClass() {
            return this.rendering?.params?.background || this.vectorBasedBackground;
        },
        container() {
            return this.rendering?.params ? 'container' : '';
        },
        hasCta() {
            return this.fields?.link.value;
        },
    },

    methods: {
        ...mapMutations('riskWarning', ['setAmount']),
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
.risk-warning__additional-toggler {
    display: flex;
    align-items: center;
    .chevron {
        margin-left: 14px;
        svg {
            transform: rotate(90deg);
            transition: transform 0.3s;
        }
    }
    .less {
        display: none;
    }
    &:not(.collapsed) {
        svg {
            transform: rotate(-90deg);
        }
        .more {
            display: none;
        }
        .less {
            display: inline;
        }
    }
}

@include media-breakpoint-down(xl) {
    .risk-warning-wrapper {
        padding: 0 8px;
    }
}
@include media-breakpoint-down(sm) {
    .container {
        padding: 0;
        width: calc(100% - 30px);
        margin: 0 15;
    }
    .risk-warning-wrapper {
        padding-right: 15px;
        padding-left: 15px;
    }
}
</style>
