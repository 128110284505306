<template>
    <section :id="anchorId" :data-e2e-component-uid="rendering.uid" class="container default-section-padding">
        <div class="row d-flex flex-column flex-xl-row">
            <Sidebar v-if="isExperienceEditor ? fields : content" :fields="isExperienceEditor ? fields : content" />
            <Articles
                v-if="isExperienceEditor ? fields : content"
                class="col-12 col-xl-9"
                :articles="isExperienceEditor ? fields : content"
            />
        </div>
    </section>
</template>

<script>
import Articles from './Articles';
import Sidebar from './Sidebar';

export default {
    name: 'TableOfContents',
    components: { Articles, Sidebar },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    data() {
        return {
            titles: [],
            tabIndexCounter: 0,
            titleDescriptions: [],
            content: null,
        };
    },
    mounted() {
        if (this.fields.items.length) {
            this.prepareContent();
        }
    },
    methods: {
        prepareContent() {
            const { items } = this.fields;
            const content = items.map((article, i) => {
                // to enable scrolling in frontend mode
                return {
                    ...article,
                    fields: {
                        ...article.fields,
                        items: article.fields.items
                            ? article.fields.items.map((item, index) => {
                                  return {
                                      ...item,
                                      id: item.id + i + index + 'subtitle',
                                  };
                              })
                            : [],
                    },
                    id: article.id + i + 'title',
                };
            });
            this.content = { ...this.fields, items: content };
        },
    },
};
</script>

<style lang="scss" scoped></style>
