export default {
    id: 'change-Site',
    name: 'Change Site',
    displayName: 'Change Site',
    fields: {
        title: {
            value: 'Change site',
        },
        items: [
            {
                id: '96d8a43a-84ae-4dc5-9b42-c7ab67c65f17',
                name: 'Change Site Item',
                displayName: 'Change Site Item',
                fields: {
                    changeSite: {},
                },
            },
        ],
    },
    hideTitle: {},
};
