<template>
    <EEPlaceholder v-if="isExperienceEditor">Fund Details Tabs</EEPlaceholder>

    <BaseSkeleton v-else-if="isLoading" height="64px" />

    <div v-else-if="tabs.length && shareClassesLength">
        <FirstLevelTabs :tabs="tabs" :activeTabKey="activeTabKey" @tabClick="activeTabKey = $event" />
        <component :is="activeTabComponent" :title="tabTitlesByTabKeys[activeTabKey]" />
        <div v-if="eloquaFormFields" id="contact-form" tabindex="-1">
            <EloquaForm :fields="eloquaFormFields" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import BaseSkeleton from '@/components/Generic/BaseSkeleton';
import FirstLevelTabs from '@/components/Generic/FirstLevelTabs';
import EEPlaceholder from '@/components/Sections/EEPlaceholder';
import EloquaForm from '@/components/Sections/EloquaForm';
import { updateUrlParams } from '@/mixins/Helpers.mixin';

import FundDetailsBreakdownTab from './FirstLevelTabs/FundDetailsBreakdownTab/FundDetailsBreakdownTab.vue';
import FundDetailsFundsFactsTab from './FirstLevelTabs/FundDetailsFundsFactsTab';
import FundDetailsHoldingsTab from './FirstLevelTabs/FundDetailsHoldingsTab';
import FundDetailsLiteratureTab from './FirstLevelTabs/FundDetailsLiteratureTab';
import FundDetailsManagementTab from './FirstLevelTabs/FundDetailsManagementTab';
import FundDetailsPerformanceTab from './FirstLevelTabs/FundDetailsPerformanceTab';
import FundDetailsPodcastTab from './FirstLevelTabs/FundDetailsPodcastTab';
import FundDetailsPremiumDiscountTab from './FirstLevelTabs/FundDetailsPremiumDiscountTab/FundDetailsPremiumDiscountTab';
import FundDetailsThaiPerformanceTab from './FirstLevelTabs/FundDetailsThaiPerformanceTab';

export default {
    name: 'FundDetailsTabs',

    components: {
        BaseSkeleton,
        FirstLevelTabs,
        EEPlaceholder,
        EloquaForm,
    },

    mixins: [updateUrlParams],

    data() {
        return {
            tabTitlesByTabKeys: {
                fundFactsTab: this.$t('fundDetailsTabsFundFacts'),
                performanceTab: this.$t('fundDetailsTabsPerformance'),
                managementTab: this.$t('fundDetailsTabsManagement'),
                literatureTab: this.$t('fundDetailsTabsLiterature'),
                holdingsTab: this.$t('fundDetailsTabsHoldings'),
                breakdownTab: this.$t('fundDetailsTabsBreakdown'),
                premiumDiscountTab: this.$t('fundDetailsTabsPremiumDiscount'),
                podcastTab: this.$t('fundDetailsTabsQuarterlyUpdatePodcast'),
            },
            activeTabKey: this.updateUrlParams().tab,
        };
    },

    computed: {
        ...mapState('fundDetails', ['isLoading', 'availableTabs', 'shareClasses', 'formJson']),

        tabs() {
            if (!this.availableTabs) return [];
            let tabsToShow = Object.entries(this.availableTabs);
            tabsToShow = tabsToShow.filter(([key, value]) => {
                if (!this.tabTitlesByTabKeys[key] || value.tab === false) return false;

                if (typeof value === 'object') {
                    return Object.values(value).some((value) => {
                        if (typeof value === 'object') {
                            return Object.values(value).some((value) => value);
                        }
                        return value;
                    });
                }
                return value;
            });
            tabsToShow = tabsToShow.map(([key]) => ({ key, title: this.tabTitlesByTabKeys[key] }));
            return tabsToShow;
        },

        shareClassesLength() {
            return this.shareClasses?.length;
        },

        activeTabComponent() {
            return this.tabComponentsByTabKeys[this.activeTabKey];
        },

        tabComponentsByTabKeys() {
            const showThaiPerformanceTab =
                this.availableTabs?.performanceTab?.thaiCumulativeTable ||
                this.availableTabs?.performanceTab?.thaiCumulativeDailyTable ||
                this.availableTabs?.performanceTab?.thaiAnnualizedTable ||
                this.availableTabs?.performanceTab?.thaiAnnualizedDailyTable;

            return {
                fundFactsTab: FundDetailsFundsFactsTab,
                performanceTab: showThaiPerformanceTab ? FundDetailsThaiPerformanceTab : FundDetailsPerformanceTab,
                managementTab: FundDetailsManagementTab,
                literatureTab: FundDetailsLiteratureTab,
                holdingsTab: FundDetailsHoldingsTab,
                breakdownTab: FundDetailsBreakdownTab,
                premiumDiscountTab: FundDetailsPremiumDiscountTab,
                podcastTab: FundDetailsPodcastTab,
            };
        },

        eloquaFormFields() {
            if (!this.formJson) return;
            return {
                formJson: {
                    value: JSON.parse(this.formJson),
                },
            };
        },

        performanceTabIncludesSubTabs() {
            const subTabs = [];
            for (const [key, value] of Object.entries(this.availableTabs.performanceTab)) {
                key !== 'tab' && key !== 'graphSection' && value && subTabs.push(key);
            }
            return subTabs;
        },
    },

    watch: {
        tabs: {
            handler(tabs) {
                if (!this.tabs) return;
                const tabExists = tabs.some((tab) => tab.key === this.activeTabKey);
                if (!tabExists) this.activeTabKey = tabs[0]?.key;
            },
            deep: true,
        },

        activeTabKey(activeTabKey) {
            this.updateUrlParams([{ name: 'tab', value: activeTabKey }]);

            if (activeTabKey === 'fundFactsTab') return;

            if (activeTabKey === 'performanceTab') {
                this.updateUrlParams([{ name: 'subTab2', value: '' }]);
                if (!this.performanceTabIncludesSubTabs.length) {
                    this.updateUrlParams([{ name: 'subTab', value: '' }]);
                }
            } else {
                this.updateUrlParams([{ name: 'subTab', value: '' }]);
                this.updateUrlParams([{ name: 'subTab2', value: '' }]);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .abrdn-list {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
        position: relative;
        padding-top: calc(var(--spacer) * 2);
        padding-bottom: calc(var(--spacer) * 2);
        padding-left: calc(var(--spacer) * 6);
        color: var(--digital-deep-grey);

        &:before {
            content: '';
            position: absolute;
            top: calc(var(--spacer) * 3);
            left: calc(var(--spacer) * 3);
            width: var(--spacer);
            height: var(--spacer);
            border-radius: 50%;
            background-color: var(--digital-ash-grey);
        }
    }
}
</style>
