export default {
    name: 'FormFieldsGetters',
    data() {
        return {
            errorMessage: '',
            isError: false,
        };
    },
    computed: {
        getLabel() {
            return this.fields.label?.value;
        },
        getTooltip() {
            return this.fields.tooltip?.value;
        },
        getErrorMessage() {
            return this.fields.errorMessage?.value;
        },
        getValidationPatterns() {
            return this.fields.validations?.value?.length ? this.fields.validations.value : [];
        },
        getHintMessage() {
            return this.fields.hint?.value;
        },
        getMin() {
            return parseInt(this.fields?.min?.value);
        },
        getMax() {
            return parseInt(this.fields?.max?.value);
        },
    },
    methods: {
        validate(value) {
            const message = this.getValidationPatterns?.find((validation) => {
                const regex = new RegExp(validation.validation);
                return !regex.test(value);
            })?.title;
            this.isError = !!message;
            this.errorMessage = message;
        },
    },
};
