<template>
    <TeamProfileBase :fields="fields" :rendering="rendering" :showImage="true" />
</template>

<script>
import TeamProfileBase from '@/components/Generic/TeamProfileBase.vue';

export default {
    name: 'TeamProfileCardsWithImages',
    components: {
        TeamProfileBase,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
};
</script>

<style scoped lang="scss"></style>
