<template>
    <div :id="anchorId" class="vector-tile w-100 py-5 px-3 d-flex flex-column background-digital-deep-grey">
        <div class="vector-dots flex-shrink-0 mb-5" />
        <div class="vector-tile__body text-white d-flex flex-column flex-grow-1">
            <ScText v-if="fields.heading" tag="h3" class="vector-tile__heading" :field="fields.heading" />
            <ScText
                v-if="fields.description"
                tag="p"
                class="vector-tile__description mt-2"
                :field="fields.description"
            />
            <div class="d-flex flex-column pt-8">
                <div
                    v-for="(link, i) in getExistingLinks"
                    :key="i"
                    :class="{ ['mb-2']: i + 1 !== getExistingLinks.length }"
                >
                    <ScLink
                        role="button"
                        class="text-white revert-underline text-link vector-tile-link"
                        :field="link.fields.link"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import ScLink from '@/components/Generic/SitecoreWrappers/ScLink.vue';

export default {
    name: 'VectorTile',
    components: {
        ScText: Text,
        ScLink,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    data() {
        return {
            links: this.fields?.items || [],
        };
    },
    computed: {
        getExistingLinks() {
            return this.links.filter(
                (link) => (link && link.fields.link.value.text) || this.$jss.sitecoreContext().pageEditing,
            );
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
.row > div:only-child .vector-tile {
    @include media-breakpoint-up(xl) {
        padding-left: calc(var(--spacer) * 5) !important;
        padding-right: calc(var(--spacer) * 5) !important;
    }
}
.vector-tile {
    height: 100%;
    .vector-tile-link {
        ::v-deep a {
            color: inherit;
        }
    }
    .footer-placeholder {
        color: var(--abrdn-white);
    }
}
.vector-dots {
    width: 96px;
    height: 64px;
}

.row > div:nth-of-type(3n + 1) .vector-dots:before {
    content: url('~@/assets/img/vector-tiles/tile1.svg');
}
.row > div:nth-of-type(3n-1) .vector-dots:before {
    content: url('~@/assets/img/vector-tiles/tile2.svg');
}
.row > div:nth-of-type(3n-3) .vector-dots:before {
    content: url('~@/assets/img/vector-tiles/tile3.svg');
}
.vector-tile__description {
    max-width: 426px;
    @include media-breakpoint-up(lg) {
        max-width: 730px;
    }
}
</style>
