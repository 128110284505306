<template>
    <div v-if="tabs.length">
        <SecondLevelTabs :activeTabKey="activeTabKey" :tabs="tabs" @tabClick="setActiveTab($event)" />

        <component
            :is="activeTabComponent"
            v-if="activeTabComponent"
            :data-testid="tabComponentsByTabKeys[activeTabKey].name"
        />

        <FundDetailsTabRow v-else class="mt-4">
            <div class="body-default">{{ $t('noResultsMessage') }}</div>
        </FundDetailsTabRow>
    </div>
</template>

<script>
import SecondLevelTabs from '@/components/Generic/SecondLevelTabs';
import { updateUrlParams } from '@/mixins/Helpers.mixin';

import FundDetailsTabRow from './FundDetailsTabRow';

export default {
    name: 'FundDetailsSecondLevelTabGroup',

    components: {
        SecondLevelTabs,
        FundDetailsTabRow,
    },

    mixins: [updateUrlParams],

    props: {
        availableTabs: {
            type: Object,
            default: null,
        },
        tabComponentsByTabKeys: {
            type: Object,
            default: () => ({}),
        },
        tabTitlesByTabKeys: {
            type: Object,
            default: () => ({}),
        },
        tabLvl: {
            type: String,
            default: () => 'subTab',
        },
    },

    data() {
        return {
            activeTabKey: this.updateUrlParams()[this.tabLvl],
        };
    },

    computed: {
        tabs() {
            if (!this.availableTabs) return [];
            let tabsToShow = Object.entries(this.availableTabs);
            tabsToShow = tabsToShow.filter(([key, value]) => value && this.tabTitlesByTabKeys[key]);
            tabsToShow = tabsToShow.map(([key]) => ({ key, title: this.tabTitlesByTabKeys[key] }));
            return tabsToShow;
        },

        activeTabComponent() {
            return this.tabComponentsByTabKeys[this.activeTabKey];
        },
    },

    created() {
        if (!this.tabs.length) return;
        this.activeTabByAvailableTabs();
    },

    methods: {
        setActiveTab(tabKey) {
            this.activeTabKey = tabKey;
            this.$emit('tabChanged', tabKey);
            this.updateUrlParams([{ name: this.tabLvl, value: tabKey }]);
        },
        activeTabByAvailableTabs() {
            const selectedSecondLvlTab = this.updateUrlParams()[this.tabLvl];
            const tabs = this.tabs.map((tab) => tab.key);

            if (tabs.includes(selectedSecondLvlTab)) {
                this.activeTabKey = selectedSecondLvlTab;
            } else if (!this.tabs.length) {
                this.updateUrlParams([{ name: this.tabLvl, value: '' }]);
            } else {
                this.activeTabKey = this.tabs[0]?.key;
                this.updateUrlParams([{ name: this.tabLvl, value: this.tabs[0]?.key }]);
            }
        },
    },
};
</script>
