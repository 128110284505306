import { apiGetPressReleasesFacets, apiSearchPressReleases } from '@/api/filters';
import { clearArticles, getArticles, getEmptyFacets, getFacets } from '@/store/reusableActions/filterActions';
import {
    setApprovedFilters,
    setApprovedFiltersBuffer,
    setBaseUrl,
    setData,
    setEmptyFacetsData,
    setFacetsData,
    setFilterMappings,
    setFilters,
    setFiltersForApprove,
    setLoading,
    setPaginationData,
} from '@/store/reusableMutations/filterMutations';
import { baseFilterState } from '@/store/reusableStates/baseFilterState';

const state = () =>
    baseFilterState({
        searchRequest: apiSearchPressReleases,
        facetsRequest: apiGetPressReleasesFacets,
    });

// actions
const actions = {
    getArticles,
    clearArticles,
    getFacets,
    getEmptyFacets,
};

const mutations = {
    setFilters,
    setData,
    setLoading,
    setApprovedFilters,
    setFilterMappings,
    setApprovedFiltersBuffer,
    setPaginationData,
    setFiltersForApprove,
    setBaseUrl,
    setEmptyFacetsData,
    setFacetsData,
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
