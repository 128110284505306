<template>
    <RelatedArticlesSections
        :id="anchorId"
        :sectionTitle="$t('featuredArticlesTitle')"
        :fields="fields"
        :rendering="rendering"
        :data-e2e-component-uid="rendering.uid"
    />
</template>

<script>
import RelatedArticlesSections from '@/components/Sections/RelatedArticlesVariations/RelatedArticlesSection';

export default {
    name: 'FeaturedArticlesSection',
    components: { RelatedArticlesSections },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
};
</script>

<style scoped></style>
