<template>
    <div class="custom-scroll mt-3">
        <input
            type="range"
            min="-15"
            :max="maxValue"
            aria-label="table scroll range"
            :value="scrollValue"
            @input="scrollTable"
        />
        <AbrdnButton
            class="custom-scroll__button me-2"
            :class="{ disabled: scrollValue === -15 }"
            aria-label="scroll left"
            role="button"
            @click.native="scrollTableWithButton('left')"
        >
            <i class="bi-chevron-left" aria-hidden="true" />
        </AbrdnButton>
        <AbrdnButton
            class="custom-scroll__button"
            :class="{ disabled: scrollValue >= maxValue }"
            aria-label="scroll right"
            role="button"
            @click.native="scrollTableWithButton('right')"
        >
            <i class="bi-chevron-right" aria-hidden="true" />
        </AbrdnButton>
    </div>
</template>

<script>
import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton';

export default {
    name: 'CustomScroll',
    components: {
        AbrdnButton,
    },
    props: {
        wrapperId: {
            type: String,
            default: '',
        },
        tableId: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            scrollValue: -15,
            maxValue: 0,
        };
    },
    watch: {
        scrollValue: {
            handler(val) {
                if (val) {
                    const currentYPosition = window.scrollY;
                    const table = document.getElementById(this.wrapperId);
                    if (table && currentYPosition) {
                        table.scroll(val, currentYPosition);
                    }
                }
                if (this.scrollValue >= this.maxValue) {
                    this.$emit('changeGradientPosition', 'gradient-left');
                }
                if (this.scrollValue === -15) {
                    this.$emit('changeGradientPosition', 'gradient-right');
                }
            },
        },
    },
    mounted() {
        this.setMaxScrollValue();
    },

    updated() {
        this.setMaxScrollValue();
    },
    methods: {
        scrollTable(event) {
            this.scrollValue = +event.target.value;
        },
        scrollTableWithButton(direction) {
            if (direction === 'left') {
                this.scrollValue -= 15;
            } else {
                this.scrollValue += 15;
            }
        },
        setMaxScrollValue() {
            const element = document.getElementById(this.tableId);
            const leftPagePadding = document.getElementById(this.wrapperId);

            if (element && leftPagePadding) {
                this.maxValue = element.offsetWidth - leftPagePadding.offsetWidth;
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
.custom-scroll {
    width: 100%;
    display: flex;
    align-items: center;
    input[type='range'] {
        height: 18px;
        -webkit-appearance: none;
        width: 90%;
        margin-right: 24px;
    }
    input[type='range']:focus {
        outline: none;
    }
    input[type='range']::-webkit-slider-runnable-track {
        width: 100%;
        height: 2px;
        cursor: pointer;
        box-shadow: 0px 0px 0px #706c70;
        background: #dedede;
        border-radius: 5px;
        border: 0;
    }
    input[type='range']::-webkit-slider-thumb {
        box-shadow: 1px 1px 1px var(--abrdn-black);
        border: 1px solid var(--abrdn-black);
        height: 6px;
        border-radius: 5px;
        background: black;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -3px;
        @include media-breakpoint-down(sm) {
            width: 30%;
        }
        @include media-breakpoint-up(md) {
            width: 50%;
        }
        @include media-breakpoint-up(lg) {
            width: 60%;
        }
    }
    input[type='range']:focus::-webkit-slider-runnable-track {
        background: #dedede;
        height: 2px;
        width: 100px;
    }
    input[type='range']::-moz-range-track {
        width: 100%;
        height: 2px;
        cursor: pointer;
        box-shadow: 0px 0px 0px #706c70;
        background: #dedede;
        border-radius: 5px;
        border: 0;
    }
    input[type='range']::-moz-range-thumb {
        box-shadow: 1px 1px 1px var(--abrdn-black);
        border: 1px solid var(--abrdn-black);
        height: 6px;
        border-radius: 5px;
        background: black;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -3px;
        @include media-breakpoint-down(sm) {
            width: 40%;
        }
        @include media-breakpoint-up(md) {
            width: 70%;
        }
        @include media-breakpoint-up(lg) {
            width: 90%;
        }
    }
    input[type='range']::-ms-track {
        width: 100px;
        height: 1px;
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        color: transparent;
    }
    input[type='range']::-ms-fill-lower {
        background: #dedede;
        border: 0px solid #000000;
        border-radius: 10px;
        box-shadow: 0px 0px 0px #706c70;
    }
    input[type='range']::-ms-fill-upper {
        background: #dedede;
        border: 0px solid #000000;
        border-radius: 10px;
        box-shadow: 0px 0px 0px #706c70;
    }
    input[type='range']::-ms-thumb {
        margin-top: 1px;
        box-shadow: 1px 1px 1px #000000;
        border: 1px solid #000000;
        height: 10px;
        width: 50px;
        border-radius: 5px;
        background: #ffffff;
        cursor: pointer;
    }
    input[type='range']:focus::-ms-fill-lower {
        background: #a97633;
    }
    input[type='range']:focus::-ms-fill-upper {
        background: #a97633;
    }
    &__button {
        border-radius: 50%;
        background: black;
        height: 40px;
        width: 40px;
        &.disabled {
            opacity: 0.3;
        }
        i {
            color: white;
        }
        &:active i {
            color: black;
        }
    }
}
</style>
