<template>
    <div>
        <button class="d-flex align-items-center filter-selector w-100" @click="toggleFiltersModal">
            <ScText :field="{ value: `Filters (${approvedFilters.length})` }" class="field-text" />
            <em class="d-flex bi-sliders ms-auto" aria-hidden="true" />
        </button>
        <AsideModal
            v-if="filtersFacets.length && filters.length"
            :positionX="'left'"
            :active="active"
            :height="height"
            class="modal-widow"
            @onClose="
                $emit('forget');
                toggleFiltersModal();
            "
            @enter="toggleFiltersModal()"
        >
            <div class="d-flex align-items-center filters-summury filter-dropdown-sum">
                <p class="me-3 h4">{{ $t('filters') }}</p>
                <span class="separator" />
                <p class="ms-3 body-default">{{ getSelectedFiltersCount }} of {{ getTotalFilterCount }}</p>
                <button
                    class="ms-auto btn-naked"
                    :aria-label="$t('close')"
                    @click="
                        $emit('forget');
                        toggleFiltersModal();
                    "
                >
                    <em class="d-flex bi-x-lg" aria-hidden="true" />
                </button>
            </div>
            <template v-for="(section, i) in filters">
                <div
                    v-if="section.isActive"
                    :key="section.id + 'mobile-section'"
                    class="flex-column d-flex align-items-start dropdown-section-root"
                >
                    <button
                        :ref="`dropdown-section${i}`"
                        v-collapse-target="`#dropdown-section${i}`"
                        aria-expanded="false"
                        class="d-flex align-items-center justify-content-between expandable w-100 dropdown-section-title collapsed btn-naked"
                        @click="
                            $emit('getFacets', section, true);
                            manageDropdown(i);
                        "
                    >
                        <ScText :field="{ value: section.value }" tag="h5" />
                        <em class="d-flex bi-chevron-down" aria-hidden="true" />
                    </button>
                    <div :id="'dropdown-section' + i" class="collapse background-white mt-1 w-100">
                        <div class="d-flex flex-column w-100">
                            <ArticleFilterOption
                                v-for="option in section.options.filter((option) => emptyFacets.includes(option.id))"
                                :key="option.id + 'mobile-option'"
                                :option="option"
                                :loading="loading"
                                :filtersFacets="filtersFacets"
                                :approvedFilters="approvedFilters"
                                @onSelect="$emit('onSelect', option)"
                            />
                        </div>
                    </div>
                </div>
            </template>
            <div class="filter-dropdown-nav d-flex align-items-center justify-content-between">
                <AbrdnButton
                    buttonType="secondary"
                    :class="{ disabled: loading }"
                    @click.native.stop="
                        $emit('clearFilters');
                        toggleFiltersModal();
                    "
                >
                    <span>{{ $t('clear') }}</span>
                </AbrdnButton>
                <AbrdnButton
                    buttonType="primary"
                    :class="{ disabled: loading }"
                    @click.native="
                        toggleFiltersModal();
                        $emit('aproveFilters');
                    "
                >
                    <span>{{ $t('apply') }}</span>
                </AbrdnButton>
            </div>
        </AsideModal>
    </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import ArticleFilterOption from '@/components/Functional/ArticleFilterBar/ArticleFilterOption.vue';
import AsideModal from '@/components/Generic/AsideModal';
import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton';
import Collapse from '@/mixins/Collapse.mixin';
import DisableScroll from '@/mixins/DisableScroll.mixin';

export default {
    name: 'ArticleFiltersMobile',
    components: { ScText: Text, AsideModal, AbrdnButton, ArticleFilterOption },
    mixins: [Collapse, DisableScroll],
    props: {
        filters: {
            type: Array,
            default: () => [],
        },
        filtersFacets: {
            type: Array,
            default: () => [],
        },
        emptyFacets: {
            type: Array,
            default: () => [],
        },
        approvedFilters: {
            type: Array,
            default: () => [],
        },
        loading: {
            type: Boolean,
            default: () => false,
        },
        totalResults: {
            type: Number,
            default: () => 0,
        },
        articles: {
            type: Array,
            default: () => [],
        },
        filtersForApprove: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            active: false,
            prevCollapse: {
                num: null,
                state: true,
            },
            height: 0,
        };
    },
    computed: {
        defaultItemIndex() {
            let defaultItem = null;
            this.filters.map((item, i) => {
                if (item.isActive && defaultItem === null) {
                    defaultItem = i;
                }
            });
            return defaultItem;
        },
        getSelectedFiltersCount() {
            return [...this.approvedFilters, ...this.filtersForApprove].filter((item) => item.selected).length;
        },
        getTotalFilterCount() {
            return Object.keys(this.filters)
                .map((filterGroup) => {
                    return this.filters[filterGroup].options;
                })
                .flat().length;
        },
    },
    methods: {
        toggleFiltersModal() {
            this.toggleWindowScroll(!this.active);
            this.active = !this.active;
            if (this.prevCollapse.num === null) {
                this.prevCollapse.num = this.defaultItemIndex;
            }
            if (this.prevCollapse.state && this.prevCollapse.num != 0) {
                this.$refs[`dropdown-section${this.prevCollapse.num}`][0].collapseTrigger();
            }
            if (this.prevCollapse.state && this.prevCollapse.num == 0) {
                this.$refs[`dropdown-section${this.defaultItemIndex}`] &&
                    this.$refs[`dropdown-section${this.defaultItemIndex}`][0].collapseTrigger();
            }
            this.prevCollapse.num = this.defaultItemIndex;
            this.prevCollapse.state = true;

            if (this.active) {
                this.$emit('getFacets', this.filters[this.defaultItemIndex], true);
            }
        },
        manageDropdown(i) {
            if (i !== this.prevCollapse.num && this.prevCollapse.state == true) {
                this.$refs[`dropdown-section${this.prevCollapse.num}`][0].collapseTrigger();
            }

            if (this.prevCollapse.num !== i) {
                this.prevCollapse.num = i;
                this.prevCollapse.state = true;
            } else {
                this.prevCollapse.state = !this.prevCollapse.state;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.bi-chevron-down {
    transform: rotate(180deg);

    .collapsed & {
        transform: rotate(0deg);
    }
}

.filter-dropdown-nav {
    position: absolute;
    bottom: 0;
    background: var(--abrdn-white);
    padding: calc(var(--spacer) * 2);
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.filter-dropdown-sum {
    position: absolute;
    top: 0;
    z-index: 1002;
    background: var(--abrdn-white);
    padding: calc(var(--spacer) * 2);
    width: 100%;
}

.dropdown-section-title {
    padding: 10px 24px 10px 16px;
    cursor: pointer;

    &.disabled,
    &.disabled {
        ::v-deep .checkbox-wrapper {
            cursor: default;
        }
    }
}

.dropdown-section-root {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding-top: 16px;
    padding-bottom: 16px;
}

.filters-summury {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 20px 16px;
}

.separator {
    height: 100%;
    width: 1px;
    background: rgba(0, 0, 0, 0.2);
}

.bi-x-lg {
    font-size: 21px;
}
</style>
