<template>
    <section class="px-0">
        <form id="onboarding_form" ref="form">
            <template v-for="(row, i) in stepData.rows">
                <p v-if="isHeader(row)" :key="'h' + i" class="h3 form-field-header">
                    {{ row.cols[0].cells[0].element.name }}
                </p>
                <p v-if="isSubHeader(row)" :key="'h4' + i" class="h4 form-field-header">
                    {{ row.cols[0].cells[0].element.name }}
                    <span v-if="row.cols[0].cells[0].element.instructions" class="text-grey body-small d-block">{{
                        row.cols[0].cells[0].element.instructions
                    }}</span>
                </p>
                <p v-else-if="isDescription(row)" :key="'d' + i" class="form-field-list body-default text-grey mb-3">
                    {{ row.cols[0].cells[0].element.name }}
                </p>
                <template v-else>
                    <div
                        v-if="showByCondition(row.cols[0].cells[0].element)"
                        :key="i"
                        class="mb-3"
                        :class="[getColClass(row.cols[0].width)]"
                    >
                        <FormsSharedFields
                            :element="row.cols[0].cells[0].element"
                            :formName="'onboardingForm'"
                            @change="setFormData($event)"
                            @updateDataLayer="pushDataLayerEvent($event)"
                            @executeFormStartEvent="executeFormStartEvent($event)"
                        />
                    </div>
                </template>
            </template>
        </form>
    </section>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

import FormsSharedFields from '@/components/Sections/Forms/FormsSharedFields';
import DataLayerHandler from '@/mixins/DataLayer.mixin';
import FormsSharedLogic from '@/mixins/FormsSharedLogic.mixin';

export default {
    name: 'FormStepTemplate',
    components: { FormsSharedFields },
    mixins: [FormsSharedLogic, DataLayerHandler],
    props: {
        stepData: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            eventExistIOnDataLayer: false,
        };
    },
    methods: {
        ...mapMutations('onboardingForm', ['setData']),
        setFormData({ id, value, name, relatesTo }) {
            this.setData({ id, value, name, relatesTo });

            this.$forceUpdate();
        },
        showByCondition(element) {
            if (element.conditionalField) {
                return this.onboardingFormData[element.conditionTargetField] === element.conditionTargetValue;
            }

            return true;
        },
        executeFormStartEvent(payload) {
            if (!this.eventExistIOnDataLayer) {
                this.eventExistIOnDataLayer = true;
                this.pushDataLayerEvent(payload);
            }
        },
    },
    computed: {
        ...mapState('onboardingForm', ['onboardingFormData']),
    },
};
</script>
