<template>
    <section :id="id" class="ee-placeholder">
        <slot />
    </section>
</template>

<script>
export default {
    name: 'EEPlaceholder',
    props: {
        id: {
            type: String,
            default: '',
        },
    },
};
</script>

<style scoped lang="scss">
.ee-placeholder {
    width: 100%;
    padding: 100px 50px;
    border: 1px solid black;
    text-align: center;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: center;
    background: var(--digital-light-grey);
}
</style>
