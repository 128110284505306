<template>
    <div class="relative">
        <p class="form-label form-element__label">
            {{ label }}
        </p>
        <Checkbox
            v-for="(checkbox, i) in optionList.elements"
            :key="i"
            :required="required"
            @onChange="$emit('setMultipleValue', $event, checkbox.value)"
            @updateDataLayer="$emit('updateDataLayer')"
            @onError="setError"
        >
            <template #label>
                <p class="body-small">{{ checkbox.displayName }}</p>
            </template>
            <template v-if="instructions" #instructions>{{ instructions }}</template>
        </Checkbox>
        <span v-if="isError" class="form-element__hints form-element__error field-error-text text-error-400">{{
            requiredErrorMessage
        }}</span>
    </div>
</template>

<script>
import Checkbox from '@/components/Generic/FormElements/FormFields/Checkbox';

export default {
    name: 'MultipleCheckboxes',
    components: { Checkbox },
    props: {
        optionList: {
            type: Object,
            default: () => {},
        },
        label: {
            type: String,
            default: '',
        },
        required: {
            type: Boolean,
            default: false,
        },
        instructions: {
            type: String,
            default: '',
        },
        requiredErrorMessage: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            isError: false,
        };
    },
    methods: {
        setError(value) {
            this.isError = value;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '@/assets/styles/mixins';
.form-element__label {
    margin-bottom: var(--default-form-label-margin);
}

.form-element__error {
    display: block;
    width: 100%;
    margin-bottom: 4px;
    @include dark-backgrounds() {
        padding: 4px;
        background-color: $abrdn-white;
    }
}

.form-element__hints {
    line-height: 1;
    margin-top: 12px;
}
</style>
