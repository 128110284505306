<!--
-->
<template>
    <div id="root">
        <ContextView :visible="contextViewVisible" />
        <!-- 'router-view' is a "global" component that is injected into the Vue component registry by vue-router. -->
        <router-view :languageIsChanging="languageIsChanging" />
        <!-- <div v-if="languageIsChanging" class="alert alert-info">Language is changing...</div> -->
    </div>
</template>

<script>
import ContextView from './ContextView';

export default {
    name: 'AppRoot',
    components: {
        ContextView,
    },
    provide() {
        // Use Vue's provide/inject capabilities to "provide" functions to
        // any descendant component that want to use/"inject" the functions.
        return {
            changeAppLanguage: this.changeAppLanguage,
            toggleContextView: this.toggleContextView,
        };
    },
    data() {
        return {
            languageIsChanging: false,
            contextViewVisible: false,
            tagsToReplaceAbbreviation: ['span', 'p', 'div', 'strong', 'h2', 'h1', 'h3', 'a'],
        };
    },
    updated() {
        // calling replace function when navigate to the next page
        this.replaceAbrdnForScreenReaders();
    },
    mounted() {
        // calling the replace function once component has been mounted
        this.replaceAbrdnForScreenReaders();

        this.$root.breakPoints = {
            xs: parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--breakpoint-xs')),
            sm: parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--breakpoint-sm')),
            md: parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--breakpoint-md')),
            lg: parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--breakpoint-lg')),
            xl: parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--breakpoint-xl')),
            current: undefined,
        };
        //Subtract 0.02 - Windows zoom issue workaround
        const medias = {
            xs: window.matchMedia(
                `(min-width: ${this.$root.breakPoints.xs}px) and (max-width: ${this.$root.breakPoints.sm - 0.02}px)`,
            ),
            sm: window.matchMedia(
                `(min-width: ${this.$root.breakPoints.sm}px) and (max-width: ${this.$root.breakPoints.md - 0.02}px)`,
            ),
            md: window.matchMedia(
                `(min-width: ${this.$root.breakPoints.md}px) and (max-width: ${this.$root.breakPoints.lg - 0.02}px)`,
            ),
            lg: window.matchMedia(
                `(min-width: ${this.$root.breakPoints.lg}px) and (max-width: ${this.$root.breakPoints.xl - 0.02}px)`,
            ),
            xl: window.matchMedia(`(min-width: ${this.$root.breakPoints.xl}px)`),
        };
        this.setMedia(medias);
    },
    methods: {
        setMedia(medias) {
            Object.entries(medias).forEach((media) => {
                const key = media[0];
                const property = media[1];
                if (property.matches) {
                    this.$root.breakPoints.current = key;
                }
                property.addEventListener('change', (e) => {
                    if (e.matches) {
                        this.$root.breakPoints.current = key;
                    }
                });
            });
        },
        replaceAbrdnForScreenReaders() {
            if (this.isExperienceEditor) return;

            setTimeout(() => {
                const elementsToBeSkipped = Array.from(document.querySelectorAll('span[aria-label="aberdeen"]'));

                this.tagsToReplaceAbbreviation.forEach((tag) => {
                    const elements = Array.from(document.querySelectorAll(tag));
                    if (!elements) return;

                    this.replaceTextInElements(
                        elements,
                        elementsToBeSkipped,
                        'abrdn',
                        '<span aria-label="aberdeen">abrdn</span>',
                    );
                });
                // replace abrdn with abrdn spaces around it in selector
                ['.placeholder [aria-label="aberdeen"]'].forEach((tag) => {
                    const elements = Array.from(document.querySelectorAll(tag));
                    if (!elements) return;

                    this.replaceTextInElements(elements, [], 'abrdn', '&nbsp;abrdn&nbsp;');
                });
            }, 1000);
        },
        replaceTextInElements(elements, elementsToBeSkipped, searchText, replacementText) {
            elements.forEach((element, index) => {
                if (
                    !Array.from(element.childNodes).filter(
                        (child) => child.nodeType === Node.TEXT_NODE && child.textContent.includes(searchText),
                    ).length
                )
                    return;

                if (elementsToBeSkipped.indexOf(element) >= 0) return;

                let appendedHtml = '';
                Array.from(element.childNodes).forEach((childNode) => {
                    if (childNode.nodeType === Node.TEXT_NODE) {
                        appendedHtml += childNode.textContent.includes(searchText)
                            ? childNode.textContent.replace(new RegExp(searchText, 'g'), replacementText)
                            : childNode.textContent;
                    } else {
                        appendedHtml += childNode.outerHTML || childNode.textContent;
                    }
                });

                if (appendedHtml && elements[index].innerHTML !== appendedHtml) {
                    elements[index].innerHTML = appendedHtml;
                }
            });
        },
        changeAppLanguage(language) {
            // Changing languages is an async action, therefore the `languageIsChanging` property can be used
            // to show a loading/switching screen when language is being changed.
            const i18n = this.$i18n.i18next;
            if (i18n.language !== language) {
                this.languageIsChanging = true;

                i18n.changeLanguage(language, () => {
                    this.languageIsChanging = false;
                });
            }
        },
        toggleContextView() {
            this.contextViewVisible = !this.contextViewVisible;
        },
    },
};
</script>
