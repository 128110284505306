<template>
    <PodcastBase :fields="fields">
        <iframe
            :src="`https://www.buzzsprout.com/${src}?client_source=small_player&iframe=true`"
            loading="lazy"
            width="100%"
            height="200"
            :title="title"
        />
    </PodcastBase>
</template>

<script>
import PodcastBase from './PodcastBase';

export default {
    name: 'Podcast',
    components: {
        PodcastBase,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        src() {
            return this.fields.url?.value;
        },
        title() {
            return this.fields.title?.value;
        },
    },
};
</script>
