<template>
    <div>
        <TertieryHeroBanner :fields="fields" :rendering="rendering" />
        <div class="container">
            <ScText :field="fields.errorCode" tag="h2" class="pt-6 pb-5" />
            <ScText :field="fields.errorTitle" tag="h5" />
            <ScText class="body-default text-grey pt-2 col-xl-8" tag="p" :field="fields.errorSubtitle1" />
            <ScText class="body-default text-grey pt-3" tag="p" :field="fields.errorSubtitle2" />
        </div>
    </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import TertieryHeroBanner from '@/components/Sections/Banners/TertieryHeroBanner.vue';

export default {
    name: 'Error500',
    components: {
        TertieryHeroBanner,
        ScText: Text,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>

<style lang="scss" scoped>
.root-wrapper {
    display: inline;
}

.sitecore-link {
    color: inherit;

    ::v-deep a,
    ::v-deep a:hover,
    &:hover {
        color: inherit;
    }

    ::v-deep a:after,
    &:after {
        display: none;
    }
}
</style>
