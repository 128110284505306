<template>
    <div :id="anchorId" class="background-blue-grey p-4">
        <h2 v-if="title">{{ title }}</h2>
        <h3 v-if="subtitle" class="h4" :class="{ 'mt-5': title }">{{ subtitle }}</h3>
        <p v-if="description" class="body-default mt-3">{{ description }}</p>
        <AbrdnPlaceholder :name="placeholderName" :rendering="rendering" :sidebar="false" class="mt-3" />
    </div>
</template>

<script>
import AbrdnPlaceholder from '@/components/Generic/SitecoreWrappers/AbrdnPlaceholder';

export default {
    name: 'NextStepsCommon',

    components: {
        AbrdnPlaceholder,
    },

    props: {
        rendering: {
            type: Object,
            default: () => ({}),
        },
        title: {
            type: String,
            default: '',
        },
        subtitle: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        placeholderName: {
            type: String,
            default: '',
        },
    },
};
</script>
