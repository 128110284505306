<template>
    <svg height="24" viewBox="0 0 24 24" width="24" aria-hidden="true">
        <defs>
            <linearGradient :id="`${gradientId}`">
                <stop offset="0%" stop-color="currentColor" />
                <stop :offset="fillingPercentage + '%'" stop-color="currentColor" />
                <stop :offset="fillingPercentage + '%'" stop-color="transparent" />
                <stop offset="100%" stop-color="transparent" />
            </linearGradient>
        </defs>

        <path
            d="M12.0001 17.27L18.1801 21L16.5401 13.97L22.0001 9.24L14.8101 8.62L12.0001 2L9.19008 8.62L2.00008 9.24L7.45008 13.97L5.82008 21L12.0001 17.27Z"
            :fill="`url(#${gradientId})`"
            stroke="currentColor"
        />
    </svg>
</template>

<script>
export default {
    name: 'IconStar',
    props: {
        fillingPercentage: {
            type: Number,
            default: () => 100,
        },
    },
    computed: {
        gradientId() {
            return `star-fill${this.$componentId()}`;
        },
    },
};
</script>

<style scoped></style>
