<template>
    <section
        :id="anchorId"
        class="main-footer"
        :class="{ 'default-margin-top': shoulAddMargin }"
        :data-e2e-component-uid="rendering.uid"
    >
        <RiskWarning v-if="riskWarningsFields" :fields="riskWarningsFields" />
        <footer ref="footer" class="flex background-black footer-root py-5 py-md-6 py-xl-8" role="contentinfo">
            <div class="container">
                <div class="logo-wrapper mb-5 mb-md-6 mb-xl-8">
                    <ScLink :backgroundToChange="false" :field="fields.logoLink" :showExternalIcon="false">
                        <WrappedImage
                            v-if="fields.logo.value"
                            :media="fields.logo"
                            :srcSet="[{ mw: 200 }, { mw: 300 }, { mw: 400 }]"
                            sizes="170px"
                        />
                    </ScLink>
                </div>
                <div
                    v-if="
                        (fields.items && fields.items) ||
                        (rendering.placeholders['abrdn-social-network'] &&
                            rendering.placeholders['abrdn-social-network'].length) ||
                        (fields.expandableBlockTitle && fields.expandableBlockTitle.value) ||
                        (fields.copywriteText && fields.copywriteText.value) ||
                        isExperienceEditor
                    "
                    class="policy-section pt-4"
                >
                    <div class="d-flex flex-column flex-md-row mb-3 mb-lg-2 mb-md-3 row">
                        <div
                            v-if="fields.items || isExperienceEditor"
                            class="col policies d-flex col-12 col-lg-9 flex-wrap flex-lg-wrap"
                        >
                            <div
                                v-for="policy in fields.items"
                                :key="policy.id"
                                class="mb-sm-2 mb-2 mb-md-1 me-lg-4 me-sm-3 align-items-center"
                            >
                                <ScLink :class="'footer-link-small'" :field="policy.fields.link" />
                            </div>
                        </div>
                        <div
                            v-if="
                                (rendering.placeholders['abrdn-social-network'] &&
                                    rendering.placeholders['abrdn-social-network'].length) ||
                                isExperienceEditor
                            "
                            class="col col-12 col-lg-3 d-flex justify-content-start justify-content-lg-end mt-1 mt-sm-1 mt-md-2 mt-lg-0"
                        >
                            <Placeholder :rendering="rendering" name="abrdn-social-network" />
                        </div>
                    </div>
                    <div
                        v-if="
                            (fields.expandableBlockTitle && fields.expandableBlockTitle.value) ||
                            (fields.copywriteText && fields.copywriteText.value) ||
                            (fields.badge && fields.badge.value) ||
                            isExperienceEditor
                        "
                        class="row"
                    >
                        <div
                            class="col col-12 d-flex flex-column flex-md-row align-items-start align-items-md-end mb-3"
                        >
                            <span class="d-flex flex-column">
                                <ScText :field="copyrightString" class="footnote" />
                                <ScText :field="fields.registeredText" class="footnote" />
                            </span>
                            <WrappedImage
                                class="badge-wrapper mt-3 mt-md-0 ms-0 ms-md-3"
                                :media="fields.badge"
                                :srcSet="[{ mw: 50 }, { mw: 100 }]"
                                sizes="48px"
                            />
                        </div>
                        <div
                            v-if="
                                (fields.expandableBlockTitle && fields.expandableBlockTitle.value) || isExperienceEditor
                            "
                            class="col col-12 col-md-8 d-flex"
                        >
                            <button
                                v-collapse-target="'#copywrite-dropdown'"
                                :aria-expanded="isDropdownExpandedByDefault ? 'true' : 'false'"
                                class="btn text-link copywrite-button d-flex align-items-end p-0"
                                :class="{ collapsed: !isDropdownExpandedByDefault }"
                                type="button"
                                variant="button"
                                @click="
                                    ((fields.expandableBlockText && fields.expandableBlockText.value) ||
                                        isExperienceEditor) &&
                                        showCopywriteText()
                                "
                            >
                                <div class="d-flex align-items-center expandable">
                                    <ScText
                                        :field="fields.expandableBlockTitle"
                                        class="expandable-title mb-0 font-size-6"
                                    />
                                    <em
                                        v-if="
                                            (fields.expandableBlockText && fields.expandableBlockText.value) ||
                                            isExperienceEditor
                                        "
                                        class="d-flex bi-chevron-down ms-1"
                                    />
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    id="copywrite-dropdown"
                    class="collapse"
                    :class="isDropdownExpandedByDefault ? 'show' : 'collapsed'"
                >
                    <div class="copywrite-dropdown">
                        <div class="row">
                            <div class="col">
                                <ScRichText :field="fields.expandableBlockText" class="body-small" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </section>
</template>

<script>
import { Placeholder, RichText, Text } from '@sitecore-jss/sitecore-jss-vue';
import { mapState } from 'vuex';

import ScLink from '@/components/Generic/SitecoreWrappers/ScLink.vue';
import WrappedImage from '@/components/Generic/SitecoreWrappers/WrappedImage';
import RiskWarning from '@/components/Sections/RiskWarning';
import Collapse from '@/mixins/Collapse.mixin';

export default {
    name: 'Footer',
    components: {
        RiskWarning,
        ScText: Text,
        WrappedImage,
        ScLink,
        Placeholder,
        ScRichText: RichText,
    },
    mixins: [Collapse],
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    data() {
        return {
            shoulAddMargin: false,
        };
    },

    computed: {
        ...mapState('riskWarning', ['amountOfRiskWarningElements']),

        riskWarningsFields() {
            return this.fields?.riskWarning?.fields;
        },
        currentYear() {
            return new Date().getFullYear().toString().slice(-2);
        },
        copyrightString() {
            return {
                ...this.fields.copyrightText,
                value: this.fields.copyrightText.value.replace('{token.currentYear}', this.currentYear),
            };
        },
        isDropdownExpandedByDefault() {
            return !!this.fields.expandedView?.value;
        },
    },
    mounted() {
        //this.setMargin();
        this.$root.footerHeight = this.$refs.footer.getBoundingClientRect().height;
    },
    methods: {
        showCopywriteText() {
            /* istanbul ignore next */ setTimeout(() => {
                window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
            }, 200);
        },
        setMargin() {
            if (this.amountOfRiskWarningElements <= 1 && typeof document !== 'undefined') {
                const lastBodyElement = document.querySelector('main > div > *:last-of-type');
                if (lastBodyElement) {
                    const elementStyles = lastBodyElement.currentStyle || window.getComputedStyle(lastBodyElement);
                    this.shoulAddMargin =
                        (elementStyles.marginBottom === '0px' &&
                            lastBodyElement.className.match('background-digital-light-grey')) ||
                        lastBodyElement.className.match('feature-banner');
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
@import '@/assets/styles/mixins';

@include financial-fairness-colors();

.footer-root {
    background: var(--abrdn-black);

    .footer-placeholder {
        color: var(--abrdn-white);
    }

    .logo-wrapper {
        ::v-deep img {
            width: auto;
            height: auto;
            max-width: 170px;
        }

        ::v-deep * {
            display: inline-block;
            color: var(--abrdn-white);
        }
    }

    .policy-section .footnote {
        color: var(--abrdn-white);
    }

    .policy-section,
    .copywrite-dropdown {
        border-top: 1px solid var(--dark-grey);
        ::v-deep & {
            white-space: pre-line;
        }
        ::v-deep p {
            white-space: pre-line;
        }
    }

    .policies {
        ::v-deep a,
        ::v-deep i,
        ::v-deep span {
            font-size: var(--fs-m);
            color: var(--digital-steel-grey);
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        > div:last-of-type {
            margin-right: 0 !important;
        }

        @media (max-width: 768px) {
            > div {
                width: 50%;
            }
        }
    }

    .copywrite-dropdown {
        padding-top: 2rem;
        margin-top: 2rem;
        color: var(--abrdn-white);
        font-size: var(--fs-m);
    }

    .copywrite-button {
        color: var(--abrdn-white);
        padding-right: 0;

        &.active {
            .bi-chevron-down {
                transform: rotate(180deg);
            }
        }
    }

    .bi-chevron-down {
        transform: rotate(180deg);
        transition: 0.2s linear;
    }

    .collapsed .bi-chevron-down {
        transform: rotate(0deg);
    }

    .badge-wrapper {
        display: block;
        max-height: 64px;
        max-width: 64px;
        object-fit: contain;
        @include media-breakpoint-down(xl) {
            max-width: 48px;
            max-height: 48px;
        }
        ::v-deep img {
            max-height: 64px;
            max-width: 64px;
            object-fit: contain;
            @include media-breakpoint-down(xl) {
                max-width: 48px;
                max-height: 48px;
            }
        }
    }

    .text-link {
        text-decoration: none;
    }

    .expandable:hover .expandable-title {
        text-decoration: underline;
    }
}
</style>
