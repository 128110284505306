<template>
    <div class="funds-listing__footer d-flex flex-column flex-md-row justify-content-between">
        <DisplayingRange
            class="footnote text-grey order-1 order-md-0"
            dictionaryKey="paginationDisplayingResults"
            :total="totalFunds"
            :take="take"
            :page="currentPage"
            :showSkeleton="isLoading"
        />
        <BackToTopButton class="ms-0 order-0 order-md-1 align-self-center align-self-md-auto mb-4 mb-md-0" />
    </div>
</template>

<script>
import { mapState } from 'vuex';

import BackToTopButton from '@/components/Generic/Buttons/BackToTopButton.vue';
import DisplayingRange from '@/components/Includes/DisplayingRange.vue';

export default {
    name: 'FundsListingFooter',
    components: { BackToTopButton, DisplayingRange },
    props: {
        totalFunds: {
            type: Number,
            required: true,
        },
        currentPage: {
            type: Number,
            required: true,
        },
        take: {
            type: Number,
            required: true,
        },
    },
    computed: {
        ...mapState('allFunds', ['isLoading']),
    },
};
</script>

<style scoped></style>
