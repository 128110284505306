export const virtualVectors = [
    {
        vectorPath: 'personal',
        siteName: 'abrdnJssPersonal',
    },
    {
        vectorPath: 'kid-hub',
        siteName: 'abrdnJssKIDHub',
    },
    {
        vectorPath: 'wrap-investment-hub',
        siteName: 'abrdnJssWrap',
    },
    {
        vectorPath: 'connect',
        siteName: 'abrdnJssConnect',
    },
    {
        vectorPath: 'institutional',
        siteName: 'abrdnJssInstitutional',
    },
    {
        vectorPath: 'intermediary',
        siteName: 'abrdnJssIntermediary',
    },
    {
        vectorPath: 'investor',
        siteName: 'abrdnJssInvestor',
    },
    {
        vectorPath: 'corporate',
        siteName: 'abrdnJssCorporate',
    },
    {
        vectorPath: 'adviser',
        siteName: 'abrdnJssAdviser',
    },
    {
        vectorPath: 'master-trust-member-area',
        siteName: 'abrdnJssMasterTrustMemberArea',
    },
    {
        vectorPath: 'clientinfo',
        siteName: 'abrdnJssClientinfo',
    },
    {
        vectorPath: 'wrapinfo',
        siteName: 'abrdnJssWrapinfo',
    },
    {
        vectorPath: 'wrap-client',
        siteName: 'abrdnJssWrapClient',
    },
    {
        vectorPath: 'allenovery',
        siteName: 'abrdnJssAllenovery',
    },
    {
        vectorPath: 'myfunds',
        siteName: 'abrdnJssMyfunds',
    },
    // env specific
    ...(process.env.envName != 'prd' || process.env.envRole === 'cm'
        ? [
              {
                  vectorPath: 'invest',
                  siteName: 'abrdnJssTrust',
              },
              {
                  vectorPath: 'trust/invtrusts',
                  siteName: 'invTrustsJss',
              },
              {
                  vectorPath: 'trust/abrdndiversified',
                  siteName: 'abrdnDiversifiedJss',
              },
              {
                  vectorPath: 'trust/abrdnchina',
                  siteName: 'abrdnChinaJss',
              },
              {
                  vectorPath: 'trust/abrdnjapan',
                  siteName: 'abrdnJapanJss',
              },
              {
                  vectorPath: 'trust/abrdnnewindia',
                  siteName: 'abrdnNewIndiaJss',
              },
              {
                  vectorPath: 'trust/abrdnsmallercompanies',
                  siteName: 'abrdnSmallerCompaniesJss',
              },
              {
                  vectorPath: 'trust/asianincome',
                  siteName: 'asianIncomeJss',
              },
              {
                  vectorPath: 'trust/asiafocus',
                  siteName: 'asiaFocusJss',
              },
              {
                  vectorPath: 'trust/ceibalimited',
                  siteName: 'ceibaLimitedJss',
              },
              {
                  vectorPath: 'trust/dunedinincomegrowth',
                  siteName: 'dunedinIncomeGrowthJss',
              },
              {
                  vectorPath: 'trust/eurologisticsincome',
                  siteName: 'euroLogisticsIncomeJss',
              },
              {
                  vectorPath: 'trust/latamincome',
                  siteName: 'latamIncomeJss',
              },
              {
                  vectorPath: 'trust/murrayincome',
                  siteName: 'murrayIncomeJss',
              },
              {
                  vectorPath: 'trust/murrayintl',
                  siteName: 'murrayIntlJss',
              },
              {
                  vectorPath: 'trust/newdawntrust',
                  siteName: 'newDawnTrustJss',
              },
              {
                  vectorPath: 'trust/northamericanincome',
                  siteName: 'northAmericanIncomeJss',
              },
              {
                  vectorPath: 'trust/shiresincome',
                  siteName: 'shiresIncomeJss',
              },
              {
                  vectorPath: 'trust/abrdnequityincometrust',
                  siteName: 'abrdnEquityIncomeTrustJss',
              },
              {
                  vectorPath: 'trust/asiadragon',
                  siteName: 'asiaDragonJss',
              },
              {
                  vectorPath: 'trust/abrdnpit',
                  siteName: 'abrdnPITJss',
              },
              {
                  vectorPath: 'trust/abrdnpeot',
                  siteName: 'abrdnPEOTJss',
              },
              {
                  vectorPath: 'trust/abrdnuksmallercompaniesgrowth',
                  siteName: 'abrdnUKSmallerCompaniesGrowthJss',
              },
              {
                  vectorPath: 'trust/ukcpreit',
                  siteName: 'ukCPReitJss',
              },

              {
                  vectorPath: 'kbc',
                  siteName: 'abrdnJssKbc',
              },
              {
                  vectorPath: 'cbc',
                  siteName: 'abrdnJssCbc',
              },
              {
                  vectorPath: 'capgemini',
                  siteName: 'abrdnJssCapgemini',
              },
              {
                  vectorPath: 'swpm',
                  siteName: 'abrdnJssSwpm',
              },
              {
                  vectorPath: 'financialfairness',
                  siteName: 'abrdnJssFinancialFairness',
              },
              {
                  vectorPath: 'staffpensions',
                  siteName: 'abrdnJssStaffPensions',
              },
              {
                  vectorPath: 'automationsample',
                  siteName: 'abrdnJssAutomationSample',
              },
              {
                  vectorPath: 'automationinstitutional',
                  siteName: 'abrdnJssAutomationInstitutional',
              },
              {
                  vectorPath: 'automationinvestor',
                  siteName: 'abrdnJssAutomationInvestor',
              },
              {
                  vectorPath: 'automationintermediary',
                  siteName: 'abrdnJssAutomationIntermediary',
              },
              {
                  vectorPath: 'automationtest',
                  siteName: 'abrdnJssAutomationTest',
              },
          ]
        : []),
];

export const defaultVectors = [
    {
        vectorPath: '',
        siteName: 'abrdnJssGlobal',
    },
];
