<template>
    <section
        :id="anchorId"
        :data-e2e-component-uid="rendering.uid"
        class="d-flex default-section-padding"
        :class="{ 'full-width': articleBody, 'background-white': !articleBody }"
    >
        <div class="container">
            <div v-if="renderTitle || renderDescription" class="mb-3 mb-md-4 row">
                <div class="col-12 col-lg-8">
                    <ScText v-if="renderTitle" :field="fields.title" tag="h2" class="mb-3" />
                    <ScText
                        v-if="renderDescription"
                        :field="fields.description"
                        tag="p"
                        class="body-default text-grey"
                    />
                </div>
            </div>
            <div :class="{ 'video-wrapper': singleVideo, 'w-100': !singleVideo }">
                <div class="video-container" :class="{ 'single-video': singleVideo }">
                    <div :id="renderingId" ref="video-container" />
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { v4 as uuidv4 } from 'uuid';

import deviceType from '@/mixins/DeviceType.mixin';

export default {
    name: 'Video',
    components: {
        ScText: Text,
    },
    mixins: [deviceType],
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        articleBody: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            height: 700,
            qumuLoaded: false,
            currentOffsetWidth: null,
            singleVideo: true,
            videoSize: typeof window !== 'undefined' && window.devicePixelRatio >= 1.25 ? 1400 * 0.75 : 1400,
            renderingId: undefined,
        };
    },
    computed: {
        isExperienceEditor() {
            return this.$jss.sitecoreContext().pageEditing;
        },
        renderTitle() {
            return !this.isInMediaBlock && (!!this.fields?.title?.value || this.isExperienceEditor);
        },
        renderDescription() {
            return !this.isInMediaBlock && (!!this.fields?.description?.value || this.isExperienceEditor);
        },
        isInMediaBlock() {
            return this.fields.isMediaBlock?.value;
        },
        itemWidth() {
            if (this.deviceType === 'desktop') {
                if (this.maxVideoCount % 2 === 0 && this.maxVideoCount < 6) return '570px';
                if (this.maxVideoCount % 2 === 1 || this.maxVideoCount === 6) return '370px';
            }
            if (this.deviceType === 'tablet') return '330px';
            return '345px';
        },
        packshotHeight() {
            if (this.deviceType === 'desktop') {
                if (this.maxVideoCount % 2 === 0 && this.maxVideoCount < 6) return '320px';
                if (this.maxVideoCount % 2 === 1 || this.maxVideoCount === 6) return '210px';
            }
            if (this.deviceType === 'tablet') return '190px';
            return '210px';
        },
        videosLength() {
            return this.$el.getElementsByClassName('kv-kulu').length;
        },
        maxVideoCount() {
            return parseInt(this.rendering?.params?.maxVideoCount);
        },
    },
    watch: {
        deviceType: {
            handler(val, oldVal) {
                if (val !== oldVal && window.KV?.widget) {
                    this.setWidgetSettings();
                    this.setThumbnailSettings();
                }
            },
        },
    },
    mounted() {
        this.renderingId = uuidv4();
        // Windows OS has default 125% scaling
        this.resetWidth();
        this.$nextTick(() => {
            /* istanbul ignore next */
            const loadQumuData = () => {
                this.qumuLoaded = true;

                if (this?.fields?.playlistID?.value) {
                    this.singleVideo = false;
                    this.observeHeight();
                    this.setWidgetSettings();
                } else if (this?.fields?.videoID?.value) {
                    this.singleVideo = true;
                    this.observeHeight();
                    this.setThumbnailSettings();
                }
            };
            if (window.KV?.widget) {
                loadQumuData();
            } else {
                const script = document.createElement('script');
                script.onload = async () => {
                    loadQumuData();
                };

                script.async = true;
                script.defer = true;
                script.setAttribute('data-ot-ignore', '');
                script.src = 'https://abrdn.qumucloud.com/widgets/application.js';
                document.getElementsByTagName('head')[0].appendChild(script);
            }
        });
    },
    methods: {
        resetWidth() {
            const widthHeightRation = 1.7;
            if (1400 / window.innerHeight > widthHeightRation) {
                this.videoSize = window.innerHeight * widthHeightRation;
            } else {
                this.videoSize = window.devicePixelRatio >= 1.125 ? 1400 * 0.85 : 1400;
            }
        },
        setWidgetSettings() {
            this.resetWidth();
            window.KV.widget({
                guid: this.fields.playlistID.value,
                type: 'grid',
                sdkUrl: 'https://unpkg.com/@enghouse-qumu/player-sdk@3.0.3/dist/index.umd.js',
                selector: `#${this.renderingId}`,
                showInfoPanel: false,
                hideEmbedButton: true,
                sort: [],
                item: {
                    width: this.itemWidth,
                    height: 'auto',
                },
                packshot: {
                    width: '100%',
                    height: this.packshotHeight,
                    maxWidth: '330',
                    anchor: 'center',
                },
                player: {
                    width: this.videoSize,
                    maxWidth: this.videoSize,
                    height: '100%',
                    maxHeight: 'auto',
                },
            });
        },
        setThumbnailSettings() {
            this.resetWidth();
            window.KV.thumbnail({
                selector: `#${this.renderingId}`,
                guid: this.fields.videoID.value,
                type: 'thumbnail',
                sdkUrl: 'https://unpkg.com/@enghouse-qumu/player-sdk@3.0.3/dist/index.umd.js',
                showInfoPanel: false,
                item: {
                    width: '100%',
                    height: '100%',
                },
                thumbnail: {
                    width: '95%',
                    maxWidth: '95%',
                },
                player: {
                    width: this.videoSize,
                    maxWidth: this.videoSize,
                    height: 'auto',
                },
            });
        },
        observeHeight() {
            const resizeObserver = new ResizeObserver(this.resizeVideo);
            resizeObserver.observe(this.$refs['video-container']);
        },
        resizeVideo() {
            this.resetWidth();
            const qumuVideoPlayer = this.$el.getElementsByClassName('kv-kulu-container');
            const titles = this.$el.getElementsByClassName('kv-kulu-bottom');
            if (this.maxVideoCount < 6) {
                const videoCollection = Array.from(this.$el.getElementsByClassName('kv-kulu'));
                videoCollection.slice(this.maxVideoCount).forEach((video) => {
                    video.style.display = 'none';
                });
            }
            if (qumuVideoPlayer.length && qumuVideoPlayer[0].offsetWidth != this.currentOffsetWidth) {
                /* istanbul ignore next */
                if (window.innerWidth >= 768) {
                    // 5.39 aspect ratio to get box of three video 16:9
                    qumuVideoPlayer[0].style.height = `${qumuVideoPlayer[0].offsetWidth / 4.5}px`;
                    this.currentOffsetWidth = qumuVideoPlayer[0].offsetWidth;
                } else {
                    // box that will contain 3 videos by 16:9, 0.53 aspect ratio to get box 16:9 multiply by number of videos
                    const videosLength = Math.min(this.videosLength, 6);
                    const videos = this.$el.getElementsByClassName('kv-kulu');
                    qumuVideoPlayer[0].style.height = `${qumuVideoPlayer[0].offsetWidth * 0.59 * videosLength}px`;

                    for (let i = 0; i < videosLength; i++) {
                        videos[i].style.margin = `0 0 ${titles[i].offsetHeight}px 0`;
                    }
                }
                /* istanbul ignore next */
                this.currentOffsetWidth = qumuVideoPlayer[0].offsetWidth;
            }
        },
    },
};
</script>

<style lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
.video-wrapper {
    width: 50%;
    @include media-breakpoint-down(lg) {
        width: 100%;
    }
}
.full-width {
    padding: 0 !important;
    .video-wrapper {
        width: 100%;
    }
    .container {
        padding: 0 !important;
    }
}
.video-container {
    position: relative;
    height: 100%;
    width: calc(100% + 30px);
    margin: -15px;
    @include media-breakpoint-down(md) {
        .kv-widget .kv-grid-widget .kv-kulu-container {
            height: 100% !important;
        }
    }
}
.video-container.single-video {
    padding-bottom: 56.25%; /* 16:9 */
    max-width: 100%;
}
.video-container.single-video .kv-widget {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    ::v-deep body {
        background-color: transparent;
    }
    ::v-deep .kv-packshot {
        background-color: transparent !important;
    }
    .kv-kulu {
        display: flex;
        width: 100% !important;
        .kv-packshot-holder {
            display: flex;
            width: 100% !important;
            button {
                display: flex;
                left: 0 !important;
                width: 100% !important;
            }
        }
    }

    .kv-player.kv-shadow {
        width: 100vw;
    }
}
</style>

<style lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';

.kv-play-icon {
    top: calc(50% - 18px) !important;
    left: calc(50% - 18px) !important;
}
.kv-thumbnail-widget {
    width: 100%;
    height: 100%;
}
@include media-breakpoint-down(md) {
    .kv-kulu-container {
        display: flex !important;
        flex-direction: column !important;
        .kv-kulu {
            width: calc(100% - 20px) !important;
            // margin-bottom: 20px;
        }
    }
}

.kv-kulu {
    float: unset !important;
    padding: 15px !important;
}

.kv-packshot-holder {
    height: auto;
    width: 100% !important;
    button {
        left: 0 !important;
        height: 100% !important;
        width: 100% !important;
    }
}

.kv-widget {
    height: 100%;
}

.kv-widget,
.kv-info-field .kv-info-field-title {
    color: var(--text-grey) !important;
}

.kv-kulu-container {
    height: unset !important;
    display: flex;
    flex-wrap: wrap;
    .kv-kulu:nth-child(n + 7) {
        display: none;
    }
    overflow: unset !important;
}

.kv-auto-scroll {
    overflow: unset !important;
}

.kv-grid-widget {
    height: 100%;
    overflow: unset !important;
}
</style>
