<template>
    <div :id="anchorId" class="quick-links background-to-change">
        <ScLink class="ms-2" :field="fields.link" />
        <IconChevronQuickLinks class="chevron ms-1 px-1" />
    </div>
</template>

<script>
import ScLink from '@/components/Generic/SitecoreWrappers/ScLink.vue';
import IconChevronQuickLinks from '@/components/Icons/icon-chevron-quick-links.vue';

export default {
    name: 'CallToAction',
    components: {
        ScLink,
        IconChevronQuickLinks,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
@import '@/assets/styles/variables';

.chevron {
    box-sizing: border-box;
    width: 23px;
    height: 24px;
}

.quick-links {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(sm) {
        width: 100%;
    }

    ::v-deep .sitecore-link {
        display: block;
        max-width: 158px;
        @include media-breakpoint-down(xl) {
            max-width: 118px;
        }
        @include media-breakpoint-down(lg) {
            max-width: 158px;
        }
        &:hover {
            text-decoration: underline;
        }
    }
}

.quick-links:before {
    line-height: 10px;
    width: 48px;
    height: 48px;
}

.background-digital-deep-grey .container .row > div:nth-of-type(4n + 1) .quick-links:before {
    content: url('~@/assets/img/quick-link/white-investment-image.svg');
}

.background-digital-deep-grey .container .row > div:nth-of-type(4n + 2) .quick-links:before {
    content: url('~@/assets/img/quick-link/white-adviser-image.svg');
}

.background-digital-deep-grey .container .row > div:nth-of-type(4n + 3) .quick-links:before {
    content: url('~@/assets/img/quick-link/white-quick-link-variation-1.svg');
}

.background-digital-deep-grey .container .row > div:nth-of-type(4n + 4) .quick-links:before {
    content: url('~@/assets/img/quick-link/white-quick-link-variation-2.svg');
}

.container .row > {
    div {
        &:nth-of-type(4n + 1) .quick-links:before {
            content: url('~@/assets/img/quick-link/investment-image.svg');
        }

        &:nth-of-type(4n + 2) .quick-links:before {
            content: url('~@/assets/img/quick-link/adviser-image.svg');
        }

        &:nth-of-type(4n + 3) .quick-links:before {
            content: url('~@/assets/img/quick-link/quick-link variation-1.svg');
        }

        &:nth-of-type(4n + 4) .quick-links:before {
            content: url('~@/assets/img/quick-link/quick-link-variation-2.svg');
        }

        &:not(:last-of-type) .quick-links {
            @include media-breakpoint-down(md) {
                border-bottom: 1px solid $dark-grey;
                padding-bottom: 32px;
            }
        }

        &:first-of-type .quick-links {
            @include media-breakpoint-down(md) {
                padding-top: 0;
            }
        }

        &:last-of-type .quick-links {
            @include media-breakpoint-down(md) {
                padding-bottom: 0;
            }
        }
    }
}
</style>
