<template>
    <div>
        <div v-for="(_item, index) in Array(6)" :key="index" :class="{ 'mt-3': index > 0 }">
            <BaseSkeleton height="64px" />
        </div>
    </div>
</template>

<script>
import BaseSkeleton from '@/components/Generic/BaseSkeleton.vue';

export default {
    name: 'FundDetailsTabSkeleton',

    components: {
        BaseSkeleton,
    },
};
</script>
