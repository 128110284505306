<template>
    <div ref="chartWrapper" class="past-performance-wrapper">
        <div class="past-performance-chart">
            <div class="y-axis axis">
                <div class="y-axis__heading body-small">{{ $t('pastPerformanceReturnsAxis') }}</div>
                <YAxisValues :yValues="yValues" />
            </div>
            <div ref="xAxis" class="x-axis">
                <XAxisItem
                    v-for="xValue in xValues"
                    :key="xValue.year"
                    :xValue="xValue"
                    :columnBottomOffset="waterlineOffset"
                    :class="{ 'negative-column-present': hasNegativeColumn }"
                />
            </div>
            <div v-if="hasNegativeColumn" class="waterline-container">
                <span class="waterline" :style="{ bottom: waterlineOffset }" />
            </div>
            <span v-if="!hasData" class="no-data-notice body-small">
                {{ $t('pastPerformanceNoResultsMessage') }}
            </span>
        </div>
        <Benchmarks
            v-if="benchmarks.length"
            class="mt-3 mt-xl-4"
            :benchmarks="benchmarks"
            @benchmarksMounted="benchmarksMounted"
        />
    </div>
</template>

<script>
import Benchmarks from '@/components/Pages/KidsHub/KidsTable/PastPerformanceChart/Benchmarks.vue';
import XAxisItem from '@/components/Pages/KidsHub/KidsTable/PastPerformanceChart/XAxisItem.vue';
import YAxisValues from '@/components/Pages/KidsHub/KidsTable/PastPerformanceChart/YAxisValues.vue';

export default {
    name: 'PastPerformanceChart',
    components: { YAxisValues, XAxisItem, Benchmarks },
    props: {
        chartData: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            syncedScrollers: [],
            waterlineOffset: '0%',
            yValueHeight: 30,
            defaultYAxisMaxValue: 16,
            hasNegativeColumn: false,
        };
    },
    computed: {
        hasData() {
            return this.chartData.entries.find((entry) => entry.columns.length);
        },
        benchmarks() {
            const maxColumns = this.chartData.chartInfo.columnNames.length;
            const output = [];
            for (let i = 0; i < maxColumns; i++) {
                output.push({
                    values: this.xValues.map((xValue) => xValue.columns[i]?.value),
                });
            }
            return output;
        },
        maxAndMinEntryValue() {
            const arr = this.chartData.entries
                .map((entry) => entry.columns.map((column) => column.value))
                .flat(Infinity);
            let max = arr.length ? Math.max(...arr) : 0;
            const min = arr.length ? Math.min(...arr) : 0;
            if (max < this.defaultYAxisMaxValue) {
                max = this.defaultYAxisMaxValue;
            }
            return {
                max: max,
                min: min,
            };
        },
        totalYValues() {
            return this.yValues.positive.value.length + this.yValues.negative.value.length;
        },
        yValues() {
            let step = 2;
            if (this.maxAndMinEntryValue.max > 20) step = 5;
            if (this.maxAndMinEntryValue.min < -20) step = 5;
            const entriesWithTooltips = this.chartData.entries.filter((entry) => entry.tooltips.length);
            const { compensatedMaxValue, compensatedMinValue } = this.getCompensatedYAndXValues(
                entriesWithTooltips,
                this.maxAndMinEntryValue.min,
                this.maxAndMinEntryValue.max,
                step,
            );

            const generateValues = (step, startValue, targetValue) => {
                const steps = [{ value: startValue, visible: true }];
                let counter = startValue;
                if (startValue >= 0) {
                    while (counter < targetValue) {
                        let compensator = 0;
                        if (counter < this.maxAndMinEntryValue.max) {
                            compensator = step;
                        }
                        const res = {
                            value: counter + step,
                            visible: counter + step <= this.maxAndMinEntryValue.max + compensator,
                        };
                        steps.push(res);
                        counter = counter + step;
                    }
                } else {
                    while (counter > targetValue) {
                        let compensator = 0;
                        if (counter > this.maxAndMinEntryValue.min) {
                            compensator = step;
                        }
                        const res = {
                            value: counter - step,
                            visible: counter - step >= this.maxAndMinEntryValue.min - compensator,
                        };
                        steps.push(res);
                        counter = counter - step;
                    }
                }
                return steps;
            };
            return {
                positive: {
                    value: generateValues(step, step, Math.ceil(compensatedMaxValue)).reverse(),
                    step: step,
                },
                negative: {
                    value:
                        this.maxAndMinEntryValue.min >= 0
                            ? []
                            : generateValues(step, 0 - step, Math.floor(compensatedMinValue)),
                    step: step,
                },
            };
        },
        xValues() {
            return this.chartData.entries.map((entry) => {
                entry.columns.forEach((column, idx) => {
                    if (column.value === 0 || column.value === null) {
                        column.relativeValue = 0;
                    } else {
                        const isNegativeValue = column.value < 0;
                        const yStep = isNegativeValue ? this.yValues.negative.step : this.yValues.positive.step;
                        column.relativeValue = Number(((column.value / yStep / this.totalYValues) * 100).toFixed(2));

                        //Convert negative to positive
                        if (column.relativeValue < 0) {
                            this.hasNegativeColumn = true;
                            column.relativeValue = Math.abs(column.relativeValue);
                            column.isReversed = true;
                        }
                    }

                    if (idx === 0 && column.value >= 0) {
                        entry.tooltips = entry.tooltips.reverse();
                    }
                });
                return entry;
            });
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.yValueHeight = parseInt(
                getComputedStyle(this.$refs.chartWrapper).getPropertyValue('--yValueHeight'),
                10,
            );
            //this.waterlineOffset = this.yValues.negative.value.length * this.yValueHeight + 'px';
            this.waterlineOffset =
                (
                    (this.yValues.negative.value.length * 100) /
                    (this.yValues.negative.value.length + this.yValues.positive.value.length)
                ).toFixed(2) + '%';
        });
    },
    updated() {
        if (this.$refs.xAxis.children.length) {
            this.scrollToMostRecentYear();
        }
    },
    beforeDestroy() {
        this.$refs.xAxis.removeEventListener('scroll', this.syncScrollListener);
    },
    methods: {
        benchmarksMounted(payload) {
            if (payload.length) {
                this.syncedScrollers = payload;
                this.syncScroll();
            }
        },
        getCompensatedYAndXValues(entries, defaultMinValue, defaultMaxValue, step) {
            const output = {
                compensatedMinValue: defaultMinValue,
                compensatedMaxValue: defaultMaxValue,
            };
            entries.forEach((entry) => {
                const tooltipsCount = entry.tooltips.length;
                entry.columns.forEach((column, idx) => {
                    if (idx > 0) return;
                    //Tooltip is a bit smaller than a single Y value. 0.9 multiplier should compensate size
                    const neededSpace = tooltipsCount * Number((step * 0.9).toFixed(2));

                    if (column.value >= 0 && column.value + neededSpace > output.compensatedMaxValue) {
                        output.compensatedMaxValue = column.value + neededSpace;
                    }
                    if (column.value < 0 && column.value - neededSpace < output.compensatedMinValue) {
                        output.compensatedMinValue = column.value - neededSpace;
                    }
                });
            });
            return output;
        },
        scrollToMostRecentYear() {
            const scrollWidth = Math.floor(this.$refs.xAxis.scrollWidth);
            const visibleWidth = Math.floor(this.$refs.xAxis.getBoundingClientRect().width);
            if (visibleWidth < scrollWidth) {
                this.$refs.xAxis.scrollLeft = scrollWidth - visibleWidth;
            }
        },
        syncScrollListener(event) {
            const currentEl = event.currentTarget;
            this.syncedScrollers.forEach((scroller) => (scroller.scrollLeft = currentEl.scrollLeft));
        },
        syncScroll() {
            this.$refs.xAxis.addEventListener('scroll', this.syncScrollListener, { passive: true });
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixins';
.past-performance-wrapper {
    --yValueWidth: 32px;
    --yValueHeight: 30px;
    --xLegendHeight: 25px;
    --columnsGroupWidth: 56px;
    --columnsGroupMargin: 6px;
    --chartTopPadding: 30px;
    --yValueFontSize: 14px;
}
.past-performance-chart {
    display: flex;
    max-width: 100%;
    width: 100%;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        left: calc(var(--yValueWidth) - 1px);
        right: 0;
        bottom: var(--xLegendHeight);
        height: 1px;
        background-color: black;
        z-index: 1;
    }
    &:after {
        content: '';
        position: absolute;
        left: calc(var(--yValueWidth) - 1px);
        top: var(--chartTopPadding);
        bottom: var(--xLegendHeight);
        width: 1px;
        background-color: black;
    }
}
.waterline-container {
    position: absolute;
    left: 0;
    right: 0;
    top: calc(var(--chartTopPadding) + calc(var(--yValueFontSize) / 2));
    bottom: calc(var(--xLegendHeight) + calc(var(--yValueFontSize) / 2));
    pointer-events: none;
}
.waterline {
    height: 1px;
    position: absolute;
    left: var(--yValueWidth);
    right: 0;
    background-color: black;
}
.y-axis {
    position: relative;
    margin-bottom: var(--xLegendHeight);
    padding-top: var(--chartTopPadding);
}
.y-axis__heading {
    position: absolute;
    left: 0;
    bottom: calc(100% - var(--chartTopPadding) + 10px);
    white-space: nowrap;
}
.x-axis {
    width: 100%;
    display: flex;
    overflow: auto;
    justify-content: space-between;
    @include invisibleScrollbar;
    padding-top: var(--chartTopPadding);
}
.no-data-notice {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 55%;
    pointer-events: none;
}
</style>
