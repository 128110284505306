import { render, staticRenderFns } from "./AsideModal.vue?vue&type=template&id=43681970&scoped=true"
import script from "./AsideModal.vue?vue&type=script&lang=js"
export * from "./AsideModal.vue?vue&type=script&lang=js"
import style0 from "./AsideModal.vue?vue&type=style&index=0&id=43681970&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43681970",
  null
  
)

export default component.exports