<template>
    <div v-if="variant === 'secondary'">
        <h3 v-if="title" class="mb-3">{{ title }}</h3>
        <ScRichText tag="div" class="body-default text-grey" :fields="wrappedDisclaimerText" />
    </div>

    <div v-else class="py-4 px-2 px-md-3 px-lg-5" :class="vectorBasedBackground">
        <ScRichText tag="div" class="footnote" :fields="wrappedDisclaimerText" />
    </div>
</template>

<script>
import RichText from '@/components/Generic/SitecoreWrappers/RichText.vue';

export default {
    name: 'ManualDisclaimer',
    components: { ScRichText: RichText },
    props: {
        variant: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        disclaimerData: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        wrappedDisclaimerText() {
            let disclaimerText = '';
            this.disclaimerData?.forEach((disclaimerTextItem, index) => {
                disclaimerText += disclaimerTextItem;

                const isLastItem = index === this.disclaimerData.length - 1;

                if (!isLastItem && !disclaimerTextItem.startsWith('<p>')) {
                    disclaimerText += '</br>';
                }
            });
            return {
                description: {
                    value: disclaimerText,
                },
            };
        },
    },
};
</script>
