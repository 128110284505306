import { apiGetTrustFacets, apiSearchTrusts } from '@/api/filters';
import { getArticles, getEmptyFacets, getFacets } from '@/store/reusableActions/filterActions';
import {
    setApprovedFilters,
    setApprovedFiltersBuffer,
    setData,
    setEmptyFacetsData,
    setFacetsData,
    setFilterMappings,
    setFilters,
    setFiltersForApprove,
    setLoading,
} from '@/store/reusableMutations/filterMutations';
import { baseFilterState } from '@/store/reusableStates/baseFilterState';

const state = () =>
    baseFilterState(
        {
            searchRequest: apiSearchTrusts,
            facetsRequest: apiGetTrustFacets,
        },
        {
            page: undefined,
            take: undefined,
        },
    );

// actions
const actions = {
    getArticles,
    getFacets,
    getEmptyFacets,
};

const mutations = {
    setFilters,
    setData,
    setLoading,
    setFiltersForApprove,
    setApprovedFilters,
    setApprovedFiltersBuffer,
    setFacetsData,
    setEmptyFacetsData,
    setFilterMappings,
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
