<template>
    <section
        :id="anchorId"
        :data-e2e-component-uid="rendering.uid"
        class="py-xl-8 py-md-5 py-3"
        :class="backgroundClass"
    >
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-12">
                    <ScText tag="h1" class="background-to-change" :field="fields.title" />
                    <ScText
                        tag="p"
                        class="body-large mt-2 text-grey background-to-change"
                        :field="fields.description"
                    />
                </div>
                <div class="col-xl-4 ms-auto d-none-if-empty">
                    <AbrdnPlaceholder name="abrdn-banner-quick-links" :rendering="rendering" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import AbrdnPlaceholder from '@/components/Generic/SitecoreWrappers/AbrdnPlaceholder.vue';
import { backgroundClass } from '@/mixins/Helpers.mixin';

export default {
    name: 'ListingHeroSection',
    components: {
        AbrdnPlaceholder,
        ScText: Text,
    },
    mixins: [backgroundClass],
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
};
</script>
