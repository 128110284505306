<template>
    <div>
        <div v-if="showImage" class="team-profile-card__thumbnail">
            <StaticImage
                v-if="haveImage"
                :src="fields.image.url"
                :alt="fields.image.alt"
                :srcSet="[{ mw: 400 }, { mw: 600 }, { mw: 800 }, { mw: 1000 }]"
                sizes="
                    (max-width: 575px) 100vw,
                    (max-width: 767px) 510px,
                    (max-width: 991px) 330px,
                    (max-width: 1199px) 25vw,
                    270px
                "
            />
            <div
                v-if="!haveImage"
                class="background-black w-100 h-100 d-flex align-items-center justify-content-center"
            >
                <TeamProfilePlaceholder class="text-white" />
            </div>
        </div>
        <div class="d-flex flex-column justify-content-between py-3 px-2 flex-grow-1" :class="vectorBasedBackground">
            <div class="d-flex flex-column">
                <ScText tag="h5" class="team-profile-card__title mb-2" :field="fullName" />
                <ScText tag="span" class="section-header-small mb-2" :field="{ value: fields.jobTitle }" />
                <a v-if="havePhone" :href="'tel:' + fields.phoneNumber" class="d-flex align-items-center mb-2">
                    <IconPhone class="icon me-1" />
                    <ScText tag="span" class="section-header-small text-break" :field="{ value: fields.phoneNumber }" />
                </a>
                <a v-if="haveEmail" :href="'mailto:' + fields.email" class="d-flex align-items-center mb-2">
                    <IconEnvelope class="flex-shrink-0 icon me-1" />
                    <ScText
                        tag="span"
                        class="section-header-small text-lowercase text-break"
                        :field="{ value: fields.email }"
                    />
                </a>
            </div>
            <router-link
                v-if="haveLink"
                :to="fields.biographyPageUrl"
                class="d-flex align-items-center flex-shrink-0 p-0 text-link"
            >
                <span>{{ $t('readBiography') }}</span>
                <IconChevronQuickLinks class="ms-2" />
            </router-link>
        </div>
    </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import StaticImage from '@/components/Generic/SitecoreWrappers/StaticImage';
import IconChevronQuickLinks from '@/components/Icons/icon-chevron-quick-links.vue';
import IconEnvelope from '@/components/Icons/icon-envelope.vue';
import IconPhone from '@/components/Icons/icon-phone.vue';
import TeamProfilePlaceholder from '@/components/Icons/icon-team-profile-placeholder.vue';

export default {
    name: 'TeamProfileCard',
    components: {
        ScText: Text,
        StaticImage,
        TeamProfilePlaceholder,
        IconChevronQuickLinks,
        IconEnvelope,
        IconPhone,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        showImage: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        fullName() {
            return { value: this.fields?.firstName + ' ' + this.fields?.lastName };
        },
        haveImage() {
            return !!this.fields?.image?.url;
        },
        haveEmail() {
            return !!this.fields?.email;
        },
        havePhone() {
            return !!this.fields?.phoneNumber;
        },
        haveLink() {
            return !!this.fields?.biographyPageUrl;
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
.team-profile-card__thumbnail {
    width: 100%;
    height: 270px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.team-profile-card__title {
    letter-spacing: 0.02em;
}

.icon {
    width: 100%;
    height: 100%;
    max-height: 24px;
    max-width: 24px;
}
</style>
