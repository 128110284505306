<template>
    <div
        v-if="articles.length || isExperienceEditor"
        :id="anchorId"
        :data-e2e-component-uid="rendering.uid"
        class="related-stories"
    >
        <h5 class="mb-3">{{ title }}</h5>
        <div class="related-stories__listing px-3" :class="vectorBasedBackground">
            <div v-for="article in articles" :key="article.url" class="related-stories__listing__item py-3">
                <router-link :to="article.url" class="related-stories__link d-flex align-items-center revert-underline">
                    <span class="me-1">{{ article.title }}</span>
                    <span class="icon d-flex flex-shrink-0 ms-auto">
                        <IconChevron class="w-100 h-100" />
                    </span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import IconChevron from '@/components/Icons/icon-chevron';

export default {
    name: 'RelatedStories',
    components: { IconChevron },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        title: {
            type: String,
            default: '',
        },
    },
    computed: {
        articles() {
            return this.fields.articles?.value || [];
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
.related-stories {
    position: sticky;
    top: calc(var(--headerHeight) + calc(var(--spacer) * 6));
    @include media-breakpoint-down(lg) {
        top: calc(var(--headerHeight) + calc(var(--spacer) * 5));
    }
}
.related-stories__link {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    .icon {
        width: 12px;
        height: 12px;
        transform: rotate(-90deg);
    }
}
.related-stories__listing__item + .related-stories__listing__item {
    border-top: 1px solid black;
}
</style>
