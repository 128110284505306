<template>
    <FormElemWrapper
        class="form-element--input"
        :class="{ ['form-element--filled']: !!pickedValue, 'conditional-field': isConditional }"
        :isError="isError"
        wrapperTag="div"
    >
        <template v-if="getName" #fieldLabelSlot class="mb-0">
            {{ getName }}
        </template>

        <template v-if="getFieldDescription" #fieldDescriptionSlot class="mb-0">
            <p class="text-grey mt-2">{{ getFieldDescription }}</p>
        </template>

        <template v-if="showFieldset" #fieldSlot class="row">
            <div class="radioFieldSet">
                <RadioButton
                    v-for="(button, i) in buttons"
                    :key="i"
                    :value="button.value"
                    :required="isRequired"
                    :modelValue="pickedValue"
                    :class="{ getColClass, addMarginTop: i !== 0 }"
                    :name="getId"
                    @onChange="setState"
                    @onError="checkError"
                >
                    <template #label>
                        {{ button.displayName }}
                    </template>
                </RadioButton>
            </div>
        </template>

        <template #errorSlot>{{ requiredErrorMessage }}</template>

        <template v-if="getInstruction" #hintSlot>{{ getInstruction }}</template>
    </FormElemWrapper>
</template>

<script>
import FormElemWrapper from '@/components/Generic/FormElements/FormFields/FormElemWrapper';
import RadioButton from '@/components/Generic/FormElements/FormFields/RadioButton.vue';

export default {
    name: 'RadioButtonFieldset',
    components: { RadioButton, FormElemWrapper },
    props: {
        fields: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            pickedValue: '',
            isSelected: this.selected,
            isError: false,
        };
    },
    computed: {
        showFieldset() {
            return !!this.fields?.optionList?.elements?.length;
        },
        buttons() {
            return this.fields?.optionList?.elements;
        },
        getName() {
            return this.isRequired && this.fields?.name ? this.fields?.name + '*' : this.fields?.name;
        },
        getInstruction() {
            return this.fields?.instructions;
        },
        getFieldDescription() {
            return this.fields?.fieldDescription;
        },
        getId() {
            return this.fields?.id;
        },
        isRequired() {
            return !!this.fields?.validations?.find(({ condition }) => condition.type === 'IsRequiredCondition');
        },
        isConditional() {
            return this.fields?.conditionalField;
        },
        relatesTo() {
            return this.fields?.relatesTo?.value;
        },
        requiredErrorMessage() {
            return this.fields?.validations?.find(({ condition }) => condition.type === 'IsRequiredCondition')?.message;
        },
        getColumnSize() {
            switch (this.fields?.style?.listOrder) {
                case 'twoColumn':
                    return 6;
                case 'threeColumn':
                    return 4;
                default:
                    return 12;
            }
        },
        getColClass() {
            return 'col-md-' + this.getColumnSize;
        },
    },
    watch: {
        pickedValue: {
            handler: function (val) {
                this.$emit('updateValue', { value: val, relatesTo: this.relatesTo });
            },
        },
    },
    mounted() {
        if (this.fields?.defaultValue) {
            this.pickedValue = this.fields?.defaultValue;
        }
    },
    methods: {
        setState(value) {
            this.pickedValue = value;
            this.isError = false;
            this.$emit('updateDataLayer');
        },
        checkError(event) {
            if (event.target.validity.valueMissing) {
                this.isError = true;
            } else {
                this.isError = false;
            }
        },
    },
};
</script>
<style scoped>
.addMarginTop {
    margin-top: 16px;
}
</style>
