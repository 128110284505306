<template>
    <section class="start-page">
        <h3 class="start-page__header mb-4">{{ startPageData.title }}</h3>
        <p class="body-default text-grey mb-3">
            {{ startPageData.subtitle }}
        </p>
        <div class="abrdn-list-root mb-5">
            <ul class="abrdn-list ordered-style">
                <li v-for="item in startPageData.list" :key="item.title" class="start-page__list_item">
                    <span class="circle background-to-change" />
                    <div class="list-item-text d-flex flex-column">
                        <span class="text-link">{{ item.title }}</span>
                        <span class="description body-small">{{ item.description }}</span>
                    </div>
                </li>
            </ul>
        </div>
        <p class="text-grey">
            {{ startPageData.afterwords }}
        </p>
    </section>
</template>

<script>
export default {
    name: 'StartPage',
    props: {
        startPageData: {
            type: Object,
            default: () => {},
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables';
@import '@/assets/styles/mixins';
.start-page {
    .abrdn-list {
        padding: 0;
        list-style-type: none;

        li {
            display: flex;
            color: var(--digital-deep-grey);

            .description {
                margin-top: 8px;
                color: var(--digital-ash-grey);
            }
            &:not(:first-of-type) {
                margin-top: 16px;
            }
        }

        &.ordered-style {
            counter-reset: ordered;
            display: block;
            .list-item-text {
                margin-left: 42px;
            }
            li {
                position: relative;
                &:not(:first-of-type) {
                    margin-top: 32px;
                }
                &:before {
                    counter-increment: ordered;
                    content: counter(ordered);
                    padding-left: 1px;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 18px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: 0;
                    background: var(--digital-deep-grey);
                    color: var(--abrdn-white);
                    border-radius: 14px;
                    width: 24px;
                    height: 24px;
                    margin-right: 16px;
                    @include dark-backgrounds() {
                        background: var(--abrdn-white);
                        color: var(--digital-deep-grey);
                    }
                    @include light-backgrounds('.highlight-block') {
                        background: var(--digital-deep-grey);
                        color: var(--abrdn-white);
                    }
                }
            }
        }
    }
}
</style>
