<template>
    <router-link :to="fields.url">
        <div class="article-series-card h-100">
            <div class="article-series-card__thumbnail flex-shrink-0">
                <StaticImage
                    :src="fields.image.url"
                    :alt="fields.image.alt"
                    :srcSet="[{ mw: 400 }, { mw: 600 }, { mw: 800 }, { mw: 1000 }]"
                    sizes="
                        (max-width: 575px) 100vw,
                        (max-width: 767px) 510px,
                        (max-width: 991px) 330px,
                        (max-width: 1199px) 33.3vw,
                        370px
                    "
                />
            </div>
            <div class="d-flex flex-column p-3 flex-grow-1" :class="vectorBasedBackground">
                <h4 class="article-series-card__title mb-3">{{ fields.name }}</h4>
                <p class="field-text">
                    {{ getCountText }}
                </p>
            </div>
        </div>
    </router-link>
</template>

<script>
import StaticImage from '@/components/Generic/SitecoreWrappers/StaticImage';

export default {
    name: 'ArticleSeriesCard',
    components: { StaticImage },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        getCountText() {
            return this.$t('articlesAvailable').replace(
                '{token.articlesCount}',
                `${this.fields.availableArticlesCount}`,
            );
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
.article-series-card__thumbnail {
    width: 100%;
    height: 210px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @include media-breakpoint-down(lg) {
        height: 188px;
    }
    @include media-breakpoint-down(md) {
        height: 210px;
    }
}

.article-series-card {
    transition: box-shadow 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
    &:hover,
    &:focus {
        box-shadow: 0 4px 9px rgba(0, 0, 0, 0.25);
        h4,
        p {
            text-decoration: underline;
        }
    }
}

.no-break {
    white-space: nowrap;
}

.article-series-card__title {
    letter-spacing: 0.03em;
}
</style>
