import { render, staticRenderFns } from "./TeamProfileCard.vue?vue&type=template&id=32fa737b&scoped=true"
import script from "./TeamProfileCard.vue?vue&type=script&lang=js"
export * from "./TeamProfileCard.vue?vue&type=script&lang=js"
import style0 from "./TeamProfileCard.vue?vue&type=style&index=0&id=32fa737b&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32fa737b",
  null
  
)

export default component.exports