<template>
    <section class="container default-section-padding team-listing-card">
        <div class="d-flex flex-column flex-md-row align-items-start relative background-md-white">
            <div class="team-listing-card__thumbnail col-4">
                <WrappedImage
                    :media="fields.image"
                    :srcSet="[{ mw: 400 }, { mw: 600 }, { mw: 800 }, { mw: 1000 }]"
                    sizes="
                        (max-width: 575px) 100vw,
                        (max-width: 767px) 510px,
                        370px
                    "
                />
            </div>
            <div ref="textContainer" class="col-8 w-100 d-flex flex-column flex-fill mt-md-2 p-3 p-md-0 text-wrapper">
                <ScText tag="h4" :field="fields.title" class="flex-shrink-0 h4" />
                <ScText tag="p" :field="fields.position" class="flex-shrink-0 subheader-2 my-3 my-md-2" />
                <ScRichText
                    ref="collapseTarget"
                    tag="div"
                    :field="fields.description"
                    class="flex-shrink-1 body-default text-grey overflow rich-text__additional"
                />
                <div v-show="overflow" class="flex-shrink-0">
                    <button
                        class="btn show-btn border-0 p-0 collapsed background-to-change rich-text__additional-toggler"
                        type="button"
                        :class="{ 'is-expanded': isExpanded }"
                        @click="expandHandler()"
                    >
                        <span v-if="!isExpanded" class="more">{{ $t('showMore') }}</span>
                        <span v-if="isExpanded" class="less">{{ $t('showLess') }}</span>
                        <span class="chevron"><IconChevronQuickLinks /></span>
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { RichText, Text } from '@sitecore-jss/sitecore-jss-vue';

import WrappedImage from '@/components/Generic/SitecoreWrappers/WrappedImage';
import IconChevronQuickLinks from '@/components/Icons/icon-chevron-quick-links';
import Collapse from '@/mixins/Collapse.mixin';

export default {
    name: 'TeamListingsCard',
    components: {
        ScText: Text,
        WrappedImage,
        ScRichText: RichText,
        IconChevronQuickLinks,
    },
    mixins: [Collapse],
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        index: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            isExpanded: false,
            overflow: false,
            prevHeight: null,
            maxHeight: null,
            containerHeight: 0,
            resizeObserver: null,
        };
    },
    mounted() {
        window.addEventListener('resize', this.resizeHandler, true);
        this.$nextTick(() => {
            this.resizeHandler();
        });
        this.resizeObserver = new ResizeObserver(this.resizeHandler);
        this.resizeObserver.observe(this.$el);
    },
    beforeDestroy() {
        this.resizeObserver.unobserve(this.$el);
        window.removeEventListener('resize', this.resizeHandler);
    },
    methods: {
        resizeHandler(entries) {
            if (entries && entries.length) {
                if (this.containerHeight !== 0 && entries[0].contentRect.height > 0) return;
                this.containerHeight = entries[0].contentRect.height;
            } else {
                this.containerHeight = this.$refs.collapseTarget?.getBoundingClientRect().height || 0;
            }
            if (this.isExpanded) {
                this.collapseCard();
            }
            if (window && window.matchMedia && window.matchMedia('(max-width: 767px)').matches) {
                this.maxHeight = '340px';
            } else {
                this.maxHeight = '370px';
            }
            if (this.$refs.collapseTarget) {
                this.overflow =
                    this.$refs.collapseTarget.getBoundingClientRect().height + 2 <
                    this.$refs.collapseTarget.scrollHeight;
            }
        },
        expandCard() {
            this.prevHeight = this.$refs.textContainer.getBoundingClientRect().height + 'px';
            this.$gsap.set(this.$refs.textContainer, { height: this.prevHeight, maxHeight: 'none' });
            this.$gsap.to(this.$refs.textContainer, { height: 'auto' });
            this.isExpanded = true;
        },
        collapseCard() {
            this.$gsap.to(this.$refs.textContainer, {
                height: this.prevHeight,
                onComplete: () => this.$gsap.set(this.$refs.textContainer, { maxHeight: this.maxHeight, height: '' }),
            });
            this.isExpanded = false;
        },
        expandHandler() {
            if (!this.isExpanded) {
                this.expandCard();
            } else {
                this.collapseCard();
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
.team-listing-card__thumbnail {
    width: 370px;
    height: 370px;
    margin-right: 30px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @include media-breakpoint-down(md) {
        width: 100%;
        height: auto;
        min-height: 345px;
        margin-right: 0px;
    }
}
.overflow {
    overflow-y: hidden;
}
.show-btn {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
}
.text-wrapper {
    max-height: 370px;
    width: 100%;
    @include media-breakpoint-down(md) {
        max-height: 340px;
    }
}
.background-md-white {
    @include media-breakpoint-down(md) {
        background: var(--abrdn-white);
    }
}
.rich-text__additional-toggler {
    display: flex;
    align-items: center;
    margin-top: 42px;
    @include media-breakpoint-down(md) {
        margin-top: 24px;
    }
    .chevron {
        margin-left: 14px;
        svg {
            transform: rotate(90deg);
            transition: transform 0.3s;
        }
    }
    &.is-expanded {
        svg {
            transform: rotate(-90deg);
        }
    }
}
::v-deep a {
    text-decoration: underline;
    font-weight: 600;
}
</style>
