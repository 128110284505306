<template>
    <Loader v-if="isLoading" :size="100" class="loader" />
    <div v-else-if="!isLoading" class="past-performance-block">
        <div class="row">
            <div class="col-xl-7">
                <h4 class="subheader-2">{{ chartData.chartInfo.title }}</h4>
                <p class="footnote footnote--bold mt-1">{{ chartData.chartInfo.subTitle }}</p>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-xl-8">
                <PastPerformanceChart :chartData="chartData" />
            </div>
            <div class="col-12 col-md-9 col-xl-4 mt-6 mt-xl-0">
                <PastPerformanceLegend v-if="legendData.length" :legendData="legendData" :tooltips="tooltips" />
            </div>
        </div>
        <hr class="my-3" />
        <div class="row">
            <div class="col-xl-8">
                <div class="chart-description">
                    <p v-if="chartData.chartInfo.footNote" class="footnote footnote--bold">
                        <span>{{ chartData.chartInfo.footNote }}</span>
                    </p>
                    <p v-if="chartData.chartInfo.extendedFootNote" class="body-small">
                        <span>{{ chartData.chartInfo.extendedFootNote }}</span>
                    </p>
                    <p v-for="(benchmark, idx) in chartData.chartInfo.benchmarks" :key="idx" class="body-small">
                        <span>{{ benchmark.benchmarkName }}</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import {
//     threeColumnsTenYears,
//     threeColumnsFiveYears,
//     twoColumnsTenYears,
//     twoColumnsFiveYears,
//     oneColumnFiveYears,
//     oneColumnTenYears,
//     noData,
//     apiMock,
// } from '@/components/Functional/KidsTable/PastPerformanceChart/mockData';
import { getPastPerformanceData } from '@/api/kidhub';
import Loader from '@/components/Generic/Loader.vue';
import PastPerformanceLegend from '@/components/Pages/KidsHub/KidsTable/PastPerformanceChart/PastPerformanceAside.vue';
import PastPerformanceChart from '@/components/Pages/KidsHub/KidsTable/PastPerformanceChart/PastPerformanceChart.vue';
import { sitecoreLanguage } from '@/mixins/Helpers.mixin';

export default {
    name: 'PastPerformanceBlock',
    components: { Loader, PastPerformanceLegend, PastPerformanceChart },
    props: {
        shareClassId: {
            type: String,
            default: '',
        },
        countryCode: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            chartData: {
                entries: [],
                chartInfo: {
                    columnNames: [],
                    title: '',
                    subTitle: '',
                    footNote: '',
                    extendedFootNote: '',
                    benchmarks: [],
                },
                tooltips: [],
            },
            tooltipsCounter: 1,
            isLoading: true,
        };
    },
    computed: {
        legendData() {
            return this.chartData.chartInfo.columnNames;
        },
        tooltips() {
            if (!this.chartData.entries.length) return [];
            const chartEntryFirstYear = this.chartData.entries[0].year;
            const chartEntryLastYear = this.chartData.entries[this.chartData.entries.length - 1].year;
            return this.chartData.tooltips.filter(
                (tooltip) => Number(tooltip.year) >= chartEntryFirstYear && Number(tooltip.year) <= chartEntryLastYear,
            );
        },
        sitecoreLanguage,
    },
    async mounted() {
        this.isLoading = true;
        const data = await this.getChartData();
        if (data) {
            this.chartData.tooltips = data.performanceNotes.performanceYearsNotes || [];
            this.chartData.entries = this.populateEntries(data.performanceSet);
            this.chartData.chartInfo = this.populateChartInfo(data.performanceSet, data.performanceNotes);
        }
    },
    methods: {
        populateEntries(performanceSets) {
            let years = [];
            const performanceValues = performanceSets.map((set) => set.values).flat();
            performanceValues.forEach((setItem) => {
                years.push(Number(setItem.performanceTimePeriod));
            });
            years = [...new Set(years)].sort((a, b) => a - b);
            const populatedYears = this.fillYearsGaps(years);
            this.populateEmptyYears(populatedYears);
            if (populatedYears.length > 10) {
                populatedYears.splice(0, populatedYears.length - 10);
            }
            return populatedYears.map((year) => ({
                year,
                columns: this.populateColumns(performanceSets, year),
                tooltips: this.populateTooltips(this.chartData.tooltips, year),
            }));
        },
        fillYearsGaps(years) {
            const noGapsYears = [];
            for (let i = 0; i < years.length; i++) {
                const iteratedYear = years[i];
                const nextArrayYear = years[i + 1] || nextArrayYear + 1;
                let yearDiff = nextArrayYear - (iteratedYear + 1);
                noGapsYears.push(iteratedYear);
                if (yearDiff) {
                    let yearsCounter = iteratedYear + 1;
                    while (yearDiff) {
                        noGapsYears.push(yearsCounter);
                        yearDiff--;
                        yearsCounter++;
                    }
                }
            }
            return noGapsYears;
        },
        populateEmptyYears(yearsArray) {
            if (!yearsArray.length || yearsArray.length >= 10) return;
            const target = yearsArray.length > 5 ? 10 : 5;
            while (yearsArray.length < target) {
                const lastYear = yearsArray[0];
                yearsArray.unshift(lastYear - 1);
            }
        },
        populateColumns(performanceSets, year) {
            const relatedCols = [];
            performanceSets.forEach((set) => {
                relatedCols.push(
                    set.values.find((column) => Number(column.performanceTimePeriod) === year) || { value: null },
                );
            });
            return relatedCols.map((col) => ({ value: col.value !== null ? Number(col.value) : null }));
        },
        populateTooltips(tooltips, year) {
            const relatedTooltips = tooltips.filter((tooltip) => Number(tooltip.year) === year);
            relatedTooltips.forEach((tooltip) => {
                tooltip.index = this.tooltipsCounter;
                this.tooltipsCounter++;
            });
            return relatedTooltips;
        },
        populateChartInfo(performanceSets, performanceNotes) {
            const { title, subTitle, footNote, extendedFootNote, benchmarks } = performanceNotes;
            const columnNames = [];
            performanceSets.forEach((set) => {
                columnNames.push(set.performanceLabel);
            });
            return {
                ...this.chartData.chartInfo,
                title,
                subTitle,
                footNote,
                extendedFootNote,
                benchmarks,
                columnNames,
            };
        },
        async getChartData() {
            try {
                const { data } = await getPastPerformanceData(
                    this.shareClassId,
                    this.sitecoreLanguage,
                    this.countryCode,
                );
                this.isLoading = false;
                return data;
            } catch (e) {
                console.log(e);
                this.isLoading = false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
::v-deep .benchmark-item:nth-child(1) .column-identifier,
::v-deep .chart-legend__item:nth-child(1) .chart-legend__item__identifier,
::v-deep .column:nth-of-type(1) .bar {
    background-color: var(--cool-grey);
}
::v-deep .benchmark-item:nth-child(2) .column-identifier,
::v-deep .chart-legend__item:nth-child(2) .chart-legend__item__identifier,
::v-deep .column:nth-of-type(2) .bar {
    background-color: var(--dark-grey);
}
::v-deep .benchmark-item:nth-child(3) .column-identifier,
::v-deep .chart-legend__item:nth-child(3) .chart-legend__item__identifier,
::v-deep .column:nth-of-type(3) .bar {
    background-color: var(--button-night);
}
.chart-aside {
    @include media-breakpoint-up(xl) {
        margin-left: 60px;
    }
}
hr {
    background-color: var(--digital-steel-grey);
    opacity: 1;
}
.loader {
    margin: 0 auto;
    display: block;
}
.chart-description {
    white-space: pre-line;
}
.chart-description > * + * {
    margin-top: calc(var(--spacer) * 5);
}
</style>
