<template>
    <div v-if="showBaseHeading" :id="anchorId" class="w-100">
        <ScText :tag="headingSize" class="background-to-change" :class="titleClassName" :field="fields.title" />
        <ScRichText :tag="'p'" class="background-to-change" :class="descriptionClassName" :field="fields.description" />
    </div>
</template>

<script>
import { RichText, Text } from '@sitecore-jss/sitecore-jss-vue';

export default {
    name: 'BaseHeading',
    components: {
        ScText: Text,
        ScRichText: RichText,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        params: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        headingSize: {
            type: String,
            default: 'h2',
        },
        descriptionClassName: {
            type: String,
            default: 'body-large mt-4',
        },
        titleClassName: {
            type: String,
            default: '',
        },
    },
    computed: {
        haveTitle() {
            return !!this.fields?.title?.value;
        },
        haveDescription() {
            return !!this.fields?.description?.value;
        },
        showBaseHeading() {
            return this.haveTitle || this.haveDescription || this.isExperienceEditor;
        },
    },
};
</script>

<style scoped lang="scss">
.body-large {
    font-weight: 500;
}
</style>
