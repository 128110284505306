<template>
    <div>
        <div class="position-relative">
            <div
                :id="wrapperId"
                class="abrdn-table"
                :class="[{ scrollable: isScrollable }, { [gradientPosition]: gradientPosition }]"
            >
                <table :id="tableId" aria-label="Fund details performace alt tab table" class="not-striped">
                    <thead>
                        <tr>
                            <th scope="col" class="sticky-column" />
                            <template v-for="colName in possibleColumns">
                                <th v-if="haveColumn(colName)" :key="colName" scope="col">
                                    {{ $t(getLabel(colName)) }}
                                </th>
                            </template>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in tabData.performanceSet" :key="item.performanceLabel">
                            <th scope="row" class="sticky-column">{{ item.performanceLabel }}</th>
                            <template v-for="colName in possibleColumns">
                                <td v-if="haveColumn(colName)" :key="colName">{{ cellValue(item, colName) }}</td>
                            </template>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="mt-1 footnote text-grey">{{ footnote }}</div>

        <CustomScroll
            v-if="isScrollable"
            :deviceType="deviceType"
            :wrapperId="wrapperId"
            :tableId="tableId"
            @changeGradientPosition="changeGradientPosition"
        />
    </div>
</template>

<script>
import CustomScroll from '@/components/Functional/CustomScroll';
import { standardFormat } from '@/constants/dateFormats';
import DeviceType from '@/mixins/DeviceType.mixin';
import { formatNumber, localizeDate } from '@/mixins/Helpers.mixin';

export default {
    name: 'FundDetailsGeneralPerformanceTable',

    components: {
        CustomScroll,
    },

    mixins: [localizeDate, DeviceType, formatNumber],

    props: {
        tabData: {
            type: Object,
            default: null,
        },
        footnoteText: {
            type: String,
            default: '',
        },
        wrapperId: {
            type: String,
            default: '',
        },
        tableId: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            possibleColumns: [
                '1 month',
                '3 months',
                '6 months',
                '1 year',
                '3 years',
                '5 years',
                '10 years',
                'launch',
                'year to date',
            ],
            gradientPosition: 'gradient-right',
        };
    },

    computed: {
        isScrollable() {
            if (this.tabData === null) return false;
            if (this.deviceType === 'mobile') {
                return this.tabData.performanceSet[0].values.length >= 2;
            } else if (this.deviceType === 'tablet') {
                return this.tabData.performanceSet[0].values.length > 4;
            } else {
                return this.tabData.performanceSet[0].values.length > 6;
            }
        },

        footnote() {
            return this.footnoteText + ' ' + this.localizeDate(this.tabData?.lastRelevantDate, standardFormat);
        },

        availableColumns() {
            const columns = [];
            this.tabData?.performanceSet.map((set) => {
                set.values.map((value) => {
                    if (!columns.filter((item) => item.performanceTimePeriod === value.performanceTimePeriod).length) {
                        columns.push({
                            performanceTimePeriod: value.performanceTimePeriod.toLowerCase(),
                            performanceTimePeriodLabel: value.performanceTimePeriodLabel,
                        });
                    }
                });
            });
            return columns;
        },
    },

    methods: {
        haveColumn(colName) {
            return !!this.availableColumns.filter((col) => col.performanceTimePeriod === colName).length;
        },

        getLabel(colName) {
            return this.availableColumns.find((col) => col.performanceTimePeriod === colName)
                .performanceTimePeriodLabel;
        },

        cellValue(cell, colName) {
            const findValue = cell.values.find((value) => value.performanceTimePeriod === colName);
            return findValue ? this.formatNumber(findValue.value) : this.$t('notAvailableShortMessage');
        },

        changeGradientPosition(val) {
            this.gradientPosition = val;
        },
    },
};
</script>

<style lang="scss" scoped>
.abrdn-table tbody tr:last-child {
    td,
    th {
        border-bottom: none;
    }
}
</style>
