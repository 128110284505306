<template>
    <PodcastBase :fields="fields">
        <iframe
            class="iframe"
            :src="src"
            width="100%"
            height="152"
            allowfullscreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
            :title="title"
        />
    </PodcastBase>
</template>

<script>
import PodcastBase from './PodcastBase';

export default {
    name: 'SpotifyPodcast',
    components: {
        PodcastBase,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        src() {
            return this.fields.url?.value;
        },
        title() {
            return this.fields.title?.value;
        },
    },
};
</script>

<style lang="scss" scoped>
.iframe {
    display: block;
    border-radius: 12px;
}
</style>
