<template>
    <component :is="isTechnicalArticle ? 'a' : 'router-link'" v-bind="attributes" class="related-article">
        <div v-if="fields.image.url && deviceType === 'mobile'" class="related-article__thumbnail flex-shrink-0">
            <StaticImage
                :src="fields.image.url"
                :alt="fields.image.alt"
                :srcSet="[{ mw: 400 }, { mw: 600 }, { mw: 800 }, { mw: 1000 }, { mw: 1200 }]"
                sizes="(max-width: 575px) 100vw, 495px"
            />
        </div>
        <div class="related-article__main d-flex flex-column flex-grow-1 background-white p-3 pb-4">
            <div class="related-article__heading d-flex flex-shrink-0 align-items-center text-grey">
                <span class="article__category section-header-small">{{ fields.category || fields.tag }}</span>
                <Readtime :articleType="fields.type" :duration="fields.duration" class="section-header-small ms-2" />
            </div>
            <div class="related-article__body d-flex flex-grow-1 align-items-start mt-2">
                <div class="related-article__content d-flex flex-column h-100 flex-grow-1">
                    <h3 class="h5">{{ fields.title }}</h3>
                    <p class="body-small text-grey mt-2 mb-4 line-clamp-3">{{ fields.description }}</p>
                    <time class="d-block caption text-grey mt-auto text-uppercase">{{
                        localizeDate(fields.date)
                    }}</time>
                </div>
                <div
                    v-if="fields.image.url && deviceType !== 'mobile'"
                    class="related-article__thumbnail flex-shrink-0 ms-3"
                >
                    <StaticImage
                        :src="fields.image.url"
                        :alt="fields.image.alt"
                        :srcSet="[{ mw: 200 }, { mw: 400 }]"
                        sizes="170px"
                    />
                </div>
            </div>
        </div>
        <span v-if="opensInNewWindow" class="sr-only">{{ $t('opensInNewWindow') }}</span>
    </component>
</template>

<script>
import StaticImage from '@/components/Generic/SitecoreWrappers/StaticImage';
import Readtime from '@/components/Includes/Readtime';
import deviceType from '@/mixins/DeviceType.mixin';
import { localizeDate } from '@/mixins/Helpers.mixin';

export default {
    name: 'RelatedArticleTile',
    components: { StaticImage, Readtime },
    mixins: [localizeDate, deviceType],
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        isTechnicalArticle() {
            return this.fields.category === 'Technical';
        },
        attributes() {
            if (this.isTechnicalArticle) {
                return {
                    href: this.fields.url,
                    target: '_blank',
                };
            }
            return { to: this.fields.url };
        },
        opensInNewWindow() {
            return this.attributes?.target === '_blank';
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
.related-article__thumbnail {
    width: 170px;
    height: 107px;
    flex-shrink: 0;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @include media-breakpoint-down(md) {
        width: 100%;
        height: 208px;
    }
}
.article__category {
    padding: 2px 4px;
    background-color: var(--digital-light-grey);
    border-radius: 4px;
}

.related-article {
    transition: box-shadow 0.2s ease-in-out;
    cursor: pointer;
    display: flex;
    flex-direction: column;
}
.related-article:focus,
.related-article:hover {
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.25);
    h5 {
        text-decoration: underline;
    }
}
</style>
