import { render, staticRenderFns } from "./PressReleaseCards.vue?vue&type=template&id=237d17d1&scoped=true"
import script from "./PressReleaseCards.vue?vue&type=script&lang=js"
export * from "./PressReleaseCards.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "237d17d1",
  null
  
)

export default component.exports