<template>
    <div class="fail-safe-placeholder">
        {{
            `${isCorrectDatasourceTemplate ? 'Incorrect Datasource template for: ' : 'Datasource is not provided for:'}`
        }}
        {{ rendering.params.name }}
    </div>
</template>

<script>
export default {
    name: 'FailSafePlaceholder',
    props: {
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        isCorrectDatasourceTemplate() {
            return this.rendering.params.isDatasourcePresented === 'True';
        },
    },
};
</script>

<style scoped lang="scss"></style>
