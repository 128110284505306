<template>
    <ScImage
        :media="media"
        :srcSet="!isSvg && srcSet"
        :sizes="!isSvg && sizes"
        :editable="editable"
        :loading="!!lazy && 'lazy'"
    />
</template>

<script>
import { Image } from '@sitecore-jss/sitecore-jss-vue';

export default {
    name: 'WrappedImage',
    components: {
        ScImage: Image,
    },
    props: {
        media: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        srcSet: {
            type: Array,
            default: null,
        },
        sizes: {
            type: String,
            default: null,
        },
        editable: {
            type: Boolean,
            default: true,
        },
        lazy: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        isSvg() {
            const dataType = this.media?.['data-type'] || this.media?.value?.['data-type'];
            return dataType === 'image/svg+xml';
        },
    },
};
</script>

<style scoped lang="scss">
img {
    object-fit: contain;
}
::v-deep img {
    max-width: 100%;
    max-height: 100%;
}
::v-deep img.scEnabledChrome {
    width: inherit;
    height: inherit;
}
</style>
