<template>
    <div v-if="fundDetails" class="fund-in-focus background-white px-2 py-3 h-100 d-flex flex-column align-items-start">
        <h5 class="text-link mb-3">{{ fundDetails.fundName }}</h5>
        <p class="section-header-small text-uppercase text-grey">{{ $t('shareClass') }}</p>
        <h6 class="mt-1">{{ fundDetails.shareClassName }}</h6>
        <div class="btn-wrapper mt-auto">
            <AbrdnButton
                buttonType="secondary"
                class="mt-3 override--light-background"
                :linkField="fundDetails.relativeLink"
            >
                <span>{{ $t('moreDetails') }}</span>
            </AbrdnButton>
        </div>
    </div>
</template>

<script>
import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton';

export default {
    name: 'FundInFocus',
    components: { AbrdnButton },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        fundDetails() {
            if (!this.fields.fundInFocus?.value) return false;
            return {
                fundName: this.fields.fundInFocus?.value?.fundName,
                shareClassName: this.fields.fundInFocus?.value?.shareClassName || '',
                relativeLink: {
                    value: {
                        href: this.fields.fundInFocus?.value?.shareClassDetailsPageUrl,
                        text: '',
                        linktype: '',
                        url: '',
                        class: '',
                    },
                },
            };
        },
    },
};
</script>

<style scoped lang="scss"></style>
