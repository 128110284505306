<template>
    <div :id="anchorId" class="contentBlock container">
        <ScText tag="h2" class="display-4" :field="fields.heading" />
        <ScRichText class="contentDescription" :field="fields.content" />
    </div>
</template>

<script>
import { RichText, Text } from '@sitecore-jss/sitecore-jss-vue';

export default {
    name: 'ContentBlock',
    components: {
        ScText: Text,
        ScRichText: RichText,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
};
</script>
