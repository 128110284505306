<template>
    <div :id="anchorId" :data-e2e-component-uid="rendering.uid" class="container pt-5 pt-md-6">
        <div class="row d-flex justify-content-end flex-column flex-md-row article-body-wrapper">
            <div class="col-12 col-md-12 col-lg-3 mt-6 mt-md-0 abrdn-article-chapters-wrapper">
                <AbrdnPlaceholder name="abrdn-article-chapters" :rendering="rendering" />
            </div>
            <div class="col-12 col-md-8 col-lg-6">
                <Placeholder
                    name="abrdn-article-body"
                    class="abrdn-article-body d-flex flex-column"
                    :rendering="rendering"
                    :articleBody="true"
                />
                <AbrdnPlaceholder name="abrdn-help-article-next-steps" class="mt-5" :rendering="rendering" />
            </div>
            <div class="col-12 col-md-4 col-lg-3 mt-6 mt-md-0">
                <div class="article-body__sidebar">
                    <AbrdnPlaceholder
                        name="abrdn-help-article-related-help-platform"
                        :rendering="rendering"
                        :class="{ 'hide-on-mobile': helpNextStepsComponent }"
                    />
                    <AbrdnPlaceholder
                        class="related-articles-placeholder"
                        name="abrdn-article-related-tag-help"
                        :rendering="rendering"
                    />
                </div>
            </div>
        </div>
        <BackToTopButton class="my-3 my-lg-4 d-flex justify-content-end" />
    </div>
</template>

<script>
import ArticleBodySharedLogic from '@/mixins/ArticleBodySharedLogic.mixin';
import ElementFinder from '@/mixins/ElementFinder.vue';

export default {
    name: 'HelpArticleBody',
    mixins: [ElementFinder, ArticleBodySharedLogic],
    props: {
        rendering: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            helpNextStepsComponent: null,
        };
    },
    mounted() {
        this.findComponentInTree(
            this.$jss.routeData().placeholders['abrdn-main'],
            'HelpNextSteps',
            'helpNextStepsComponent',
        );
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/article-body';
</style>
