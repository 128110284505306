<template>
    <svg viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.96049 7.87785H0.521568V24.2196H5.96049V7.87785ZM6.31871 2.8227C6.28341 1.2204 5.13761 0 3.27698 0C1.41636 0 0.199951 1.2204 0.199951 2.8227C0.199951 4.3918 1.38041 5.64735 3.20638 5.64735H3.24114C5.13761 5.64735 6.31871 4.3918 6.31871 2.8227ZM25.5753 14.8497C25.5753 9.83026 22.8922 7.49411 19.3131 7.49411C16.4254 7.49411 15.1325 9.08025 14.4106 10.1929V7.87828H8.97105C9.04274 9.4117 8.97105 24.22 8.97105 24.22H14.4106V15.0934C14.4106 14.605 14.4459 14.1178 14.5897 13.7681C14.9828 12.7924 15.8778 11.7822 17.3805 11.7822C19.3494 11.7822 20.1364 13.2807 20.1364 15.4767V24.2196H25.5751L25.5753 14.8497Z"
            fill="currentColor"
        />
    </svg>
</template>

<script>
export default {
    name: 'IconLinkedin',
};
</script>

<style scoped></style>
