<template>
    <FundDetailsTabRow>
        <div v-for="item in literatureDocuments" :key="item.id" class="link-wrapper">
            <a :href="item.documentUri" target="_blank" class="text-link d-block py-3" @click="updateDataLayer(item)">
                {{ item.title }} ({{ item.format.toUpperCase() }})
                <i aria-hidden="true" :class="`bi bi-filetype-${item.format}`" />
                <span class="sr-only">{{ $t('opensInNewWindow') }}</span>
            </a>
        </div>
    </FundDetailsTabRow>
</template>

<script>
import { mapState } from 'vuex';

import FundDetailsTabRow from '@/components/Pages/FundsCenter/FundDetails/FundDetailsTabRow';
import documentsFormatMap from '@/constants/documentsFormatMap';
import pushDataLayerEvent from '@/mixins/DataLayer.mixin';

export default {
    name: 'LiteratureSharedTab',
    components: { FundDetailsTabRow },
    mixins: [pushDataLayerEvent],
    computed: {
        ...mapState('fundDetails', {
            tabData: (state) => state.literatureTabData.data,
            selectedTab: (state) => state.literatureTabSelectedSubTab.data,
        }),

        filteredLiteratureDocuments() {
            return (
                this.tabData.filter((tab) => this.$t(tab.title) === this.selectedTab)[0]?.documents ||
                this.tabData[0].documents
            );
        },
        literatureDocuments() {
            return this.filteredLiteratureDocuments.map((obj) => {
                const format = Object.entries(documentsFormatMap).find(([, value]) => value === obj.contentType);
                const copiedObj = { ...obj };
                if (typeof window !== 'undefined') {
                    const { origin } = window;
                    const documentUrl = copiedObj.documentUri;
                    copiedObj.documentUri = documentUrl.includes('/api/') ? `${origin}${documentUrl}` : documentUrl;
                }
                return { ...copiedObj, format: format ? format[0] : '' };
            });
        },
    },
    methods: {
        updateDataLayer(item) {
            if (typeof window !== 'undefined') {
                const origin = window.location.origin;
                this.pushDataLayerEvent({
                    event: 'file_download',
                    file_url: item.documentUri.includes('https://') ? item.documentUri : `${origin}${item.documentUri}`,
                    file_name: item.title,
                });
            }
        },
    },
};
</script>

<style scoped lang="scss">
.link-wrapper {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);

    a {
        width: fit-content;
    }
    i {
        font-size: 24px;
        padding-left: 8px;
    }
}
</style>
