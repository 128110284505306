<template>
    <section
        :id="anchorId"
        :data-e2e-component-uid="rendering.uid"
        class="default-section-padding"
        :class="backgroundClass"
    >
        <div class="container">
            <div v-if="showTilesHeading" class="mb-4 row">
                <div class="col-12 col-lg-8">
                    <Placeholder :rendering="rendering" name="abrdn-tiles-heading" />
                </div>
            </div>
            <div>
                <Placeholder class="row" :rendering="rendering" name="abrdn-tiles" />
            </div>
        </div>
    </section>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';

import { backgroundClass } from '@/mixins/Helpers.mixin';

export default {
    name: 'Tiles',
    components: {
        Placeholder,
    },
    mixins: [backgroundClass],
    props: {
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        showTilesHeading() {
            return (
                (this.rendering?.placeholders?.['abrdn-tiles-heading']?.length &&
                    (!!this.rendering?.placeholders?.['abrdn-tiles-heading']?.[0]?.fields?.title?.value ||
                        !!this.rendering?.placeholders?.['abrdn-tiles-heading']?.[0]?.fields?.description?.value)) ||
                this.isExperienceEditor
            );
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/helpers/bootstrap-vars-overrides';
.row {
    margin-top: (-$spacer * 3);
    ::v-deep > div {
        margin-top: $spacer * 3;
    }
}
</style>
