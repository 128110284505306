<template>
    <FundDetailsTabRow fullWidth class="mt-4">
        <CumulativeTable />
        <CumulativeQuarterlyTable v-if="quarterlyTableAvailable" class="mt-4" />
    </FundDetailsTabRow>
</template>

<script>
import { mapState } from 'vuex';

import FundDetailsTabRow from '@/components/Pages/FundsCenter/FundDetails/FundDetailsTabRow';

import CumulativeQuarterlyTable from './Tables/CumulativeQuarterlyTable';
import CumulativeTable from './Tables/CumulativeTable';

export default {
    name: 'FundDetailsCumulativeTab',

    components: {
        CumulativeQuarterlyTable,
        CumulativeTable,
        FundDetailsTabRow,
    },

    computed: {
        ...mapState('fundDetails', {
            quarterlyTableAvailable: (state) => state.availableTabs.performanceTab.cumulativeQuarterlyTab,
        }),
    },
};
</script>
