<template>
    <div class="share-modal">
        <div class="share-modal__heading d-flex align-items-center justify-content-between p-2">
            <h5 class="subheader-2">{{ $t('shareThisArticle') }}</h5>
            <button :aria-label="$t('closeModal')" class="btn close-button" @click="closeModal">
                <IconX />
            </button>
        </div>
        <div class="share-modal__content d-flex flex-column align-items-start p-2 pt-3">
            <h6 class="section-header-small">{{ $t('copyLink') }}</h6>
            <p :title="meta.pageUrl" class="h6 link mt-1">
                {{ meta.pageUrl }}
            </p>
            <input
                ref="input"
                aria-label="current url"
                class="link-input"
                type="text"
                readonly
                tabindex="-1"
                :value="meta.pageUrl"
            />
            <AbrdnButton
                ref="copyBtn"
                buttonType="primary"
                class="mt-2 copy-btn"
                :isSuccess="isCopied"
                @click.native="copyLink"
            >
                <em v-if="isCopied" class="bi bi-check2 me-1" aria-hidden="true" />
                <IconCopy v-else class="me-1" aria-hidden="true" />
                <span>{{ isCopied ? $t('copied') : $t('copyLink') }}</span>
            </AbrdnButton>
        </div>
        <div class="share-modal__list d-flex flex-column align-items-start p-2">
            <h6 class="section-header-small">{{ $t('shareTo') }}</h6>
            <div class="socials-list d-flex align-items-center flex-wrap">
                <ShareNetwork
                    v-for="(network, idx) in shareNetworks"
                    :key="network.title"
                    :network="network.title"
                    :url="meta.pageUrl"
                    :title="meta.metaTitle"
                    :description="meta.metaDescription"
                    :class="{ ['me-1']: idx !== shareNetworks.length - 1 }"
                    class="mt-1"
                    tag="div"
                >
                    <AbrdnButton buttonType="primary" class="share-btn">
                        <span class="sr-only">{{ $t('shareThisArticle') }}</span>
                        <component :is="network.icon" aria-hidden="true" />
                    </AbrdnButton>
                </ShareNetwork>
            </div>
        </div>
    </div>
</template>

<script>
import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton';
import IconCopy from '@/components/Icons/icon-copy';
import IconEmail from '@/components/Icons/icon-email';
import IconX from '@/components/Icons/icon-x';
import IconFacebook from '@/components/Icons/Socials/icon-facebook';
import IconLinkedin from '@/components/Icons/Socials/icon-linkedin';
import IconTwitter from '@/components/Icons/Socials/icon-twitter';
import IconWhatsapp from '@/components/Icons/Socials/icon-whatsapp';

export default {
    name: 'ShareModalContent',
    components: { IconFacebook, IconTwitter, IconWhatsapp, IconLinkedin, IconEmail, IconCopy, IconX, AbrdnButton },
    data() {
        return {
            isCopied: false,
            shareNetworks: [
                {
                    title: 'Facebook',
                    icon: 'IconFacebook',
                },
                {
                    title: 'LinkedIn',
                    icon: 'IconLinkedin',
                },
                {
                    title: 'Twitter',
                    icon: 'IconTwitter',
                },
                {
                    title: 'WhatsApp',
                    icon: 'IconWhatsapp',
                },
                {
                    title: 'Email',
                    icon: 'IconEmail',
                },
            ],
        };
    },
    computed: {
        meta() {
            const { metaDescription, metaTitle } = this.$jss.sitecoreContext();
            const pageUrl = this.$jss
                .sitecoreContext()
                .languageVersions.find(
                    (langVersion) =>
                        langVersion.hreflang.toLowerCase() ===
                        this.$jss.sitecoreContext().displayLanguage.toLowerCase(),
                )?.href;
            return {
                pageUrl,
                metaTitle,
                metaDescription,
            };
        },
    },
    methods: {
        closeModal() {
            this.$emit('closeModal');
        },
        copyLink() {
            this.$refs.input.select();
            document.execCommand('copy');
            this.isCopied = true;
            setTimeout(() => (this.isCopied = false), 2000);
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
@import '@/assets/styles/variables';
.share-modal {
    width: 345px;
    position: relative;
    @include media-breakpoint-down(md) {
        width: auto;
        max-width: 345px;
    }
}
.close-button {
    width: 24px;
    height: 24px;
    display: flex;
    padding: 5px;
    border: 0;
    svg {
        width: 100%;
        height: 100%;
    }
}
.link {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.link-input {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    opacity: 0;
    max-width: 100%;
    z-index: -1;
}

.share-modal {
    background-color: var(--abrdn-white);
}
.share-modal__content {
    border-top: 1px solid var(--dark-grey);
    border-bottom: 1px solid var(--dark-grey);
}
.copy-btn {
    display: flex;
    align-items: center;
    svg {
        width: 1.7em;
        height: 1.7em;
    }
    .bi-check2 {
        font-size: 1.7em;
    }
}
.share-btn {
    width: 48px;
    height: 48px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    svg {
        width: 26px;
        height: 26px;
    }
}
</style>
