<template>
    <div class="container">
        <div class="col-md-8">
            <div class="accordion border-bottom" :class="{ 'border-top': isFirst }">
                <button
                    class="btn-naked d-flex justify-content-between align-items-center w-100 py-3 gap-1"
                    :aria-expanded="show ? 'true' : 'false'"
                    @click="show = !show"
                >
                    <span class="h3">{{ title }}</span>
                    <svg
                        class="accordion__icon"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M24 7.40068V5L12.8565 16.0448L12.0086 16.9101L11.1435 16.0448L0.0171306 5.01697L0 7.38371L12 19.2853L24 7.40068Z"
                            fill="currentColor"
                        />
                    </svg>
                </button>

                <ExpandableContent :show="show">
                    <div class="accordion__panel">
                        <div class="accordion__panel__inner">
                            <div v-for="(fields, section) in description" :key="fields.title" class="mb-3">
                                <p class="h4 mb-1">{{ section }}</p>
                                <div v-for="(value, label) in fields" :key="label + 'l'">
                                    <span class="text-link">{{ label }}: </span
                                    ><span class="body-default me-2">{{ value }}</span>
                                </div>
                            </div>

                            <AbrdnButton
                                buttonType="text-link"
                                class="mt-2 text-link text-center w-fit-content border-0 px-0"
                                :replaceWithSlot="false"
                                :showExternalIcon="false"
                                @click.native="changeStepHandler"
                            >
                                <i class="bi bi-chevron-left me-1" aria-hidden="true" />

                                {{ stepBack.value }}
                            </AbrdnButton>
                        </div>
                    </div>
                </ExpandableContent>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';

import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton';
import ExpandableContent from '@/components/Generic/ExpandableContent';
import { updateUrlParams } from '@/mixins/Helpers.mixin';

export default {
    name: 'ReviewStep',
    components: {
        ExpandableContent,
        AbrdnButton,
    },
    mixins: [updateUrlParams],
    props: {
        title: {
            type: String,
            default: () => '',
        },
        description: {
            type: Object,
            default: () => ({}),
        },
        isFirst: {
            type: Boolean,
            default: false,
        },
        stepBack: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            show: false,
        };
    },
    methods: {
        ...mapMutations('onboardingForm', ['changeStep']),
        changeStepHandler() {
            this.changeStep(this.stepBack.step);
            this.updateUrl();
        },
        updateUrl() {
            this.updateUrlParams([{ name: 'step', value: this.stepBack.step }]);
        },
    },
};
</script>

<style lang="scss" scoped>
.accordion {
    border-color: var(--dark-grey) !important;
}

.accordion__icon {
    width: calc(var(--spacer) * 3);
    height: calc(var(--spacer) * 3);
    flex-shrink: 0;
    transition: transform 0.3s;
}

[aria-expanded='true'] .accordion__icon {
    transform: rotate(180deg);
}

.accordion__panel {
    padding-top: calc(var(--spacer) * 1);
    padding-bottom: calc(var(--spacer) * 3);
}

.accordion__panel__inner {
    border-left: calc(var(--spacer) * 0.5) solid var(--digital-light-grey);
    padding-left: calc(var(--spacer) * 3);
}
</style>
