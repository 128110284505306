<template>
    <div class="notification d-flex justify-content-between mb-1 p-1">
        <div class="d-flex flex-column">
            <div class="d-flex body-default">
                <em class="bi bi-exclamation-triangle-fill me-1" aria-hidden="true" />
                <span>{{ componentName }}</span>
            </div>
            <span class="body-small">
                {{
                    `This component is presented in "${contextLanguage}" language. "${dependentLanguages}" languages use "${contextLanguage}" as fallback language.`
                }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ComponentLevelNotification',
    props: {
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        dependentLanguages() {
            return this.rendering?.params?.dependentLanguages;
        },
        contextLanguage() {
            return this.rendering?.params?.originalLanguage;
        },
        componentName() {
            return this.rendering?.componentName + ' component';
        },
    },
};
</script>

<style scoped>
.notification {
    background-color: #f27900;
}
</style>
