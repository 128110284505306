<template>
    <FundDetailsTabRow fullWidth class="mt-4">
        <h3>{{ $t('fundDetailsBreakdownRiskTitle') }}</h3>
        <div class="mt-5">
            <FundDetailsTabSkeleton v-if="loading" />
            <AlertMessageBase v-else-if="error" type="error" :title="$t(error)" />
            <template v-else-if="tabData && tabData.results && tabData.results.length">
                <FundDetailsStripedTable
                    :reportDate="reportDateFromTo"
                    :tableData="tableData"
                    :minimal="true"
                    :isScrollable="isScrollable"
                    tableId="riskAnalysisTable"
                    wrapperId="riskAnalysisWrapper"
                />
                <section v-if="tabData.footnote">
                    <div class="mt-2 py-4 px-2 px-md-3 px-lg-5" :class="vectorBasedBackground">
                        <p class="body-small">
                            {{ tabData.footnote }}
                        </p>
                    </div>
                </section>
            </template>
            <div v-else class="body-default">{{ $t('noResultsMessage') }}</div>
        </div>
    </FundDetailsTabRow>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import AlertMessageBase from '@/components/Generic/AlertMessageBase';
import FundDetailsStripedTable from '@/components/Pages/FundsCenter/FundDetails/FirstLevelTabs/FundDetailsStripedTable';
import StripedTableHelper from '@/mixins/StripedTableHelper.mixin';

import FundDetailsTabRow from '../FundDetailsTabRow';
import FundDetailsTabSharedLogic from '../FundDetailsTabSharedLogic.mixin';
import FundDetailsTabSkeleton from '../FundDetailsTabSkeleton';

export default {
    name: 'FundDetailsRiskAnalysisTab',

    components: {
        FundDetailsStripedTable,
        FundDetailsTabRow,
        AlertMessageBase,
        FundDetailsTabSkeleton,
    },

    mixins: [FundDetailsTabSharedLogic, StripedTableHelper],

    computed: {
        ...mapState('fundDetails', {
            loading: (state) => state.riskAnalysisTabData.loading,
            error: (state) => state.riskAnalysisTabData.error,
            tabData: (state) => state.riskAnalysisTabData.data,
        }),
    },

    methods: {
        ...mapActions('fundDetails', {
            fetchTabData: 'fetchRiskAnalysisTabData',
        }),

        formatTableValues(columns) {
            return Array.from(this.tableColumnHeadings).map((heading) => {
                const col = columns.find((col) => col.columnLabel === heading);
                if (!col) return this.$t('notAvailableShortMessage');

                return this.applyGeneralFormattingRules(col);
            });
        },
    },
};
</script>

<style scoped lang="scss">
tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
</style>
