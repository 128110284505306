<template>
    <section
        v-if="showBanner"
        :data-e2e-component-uid="rendering.uid"
        class="p-3 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center"
        :class="backgroundClass"
    >
        <ScText tag="p" class="subheader-1 background-to-change pe-3" :field="fields.title" />
        <AbrdnButton
            buttonType="primary"
            :linkField="fields.cta"
            class="background-to-change flex-shrink-0 mt-3 mt-md-0"
            @click="scrollTo(fields.cta.value.anchor)"
        >
            <span>{{ $t('signUp') }}</span>
        </AbrdnButton>
    </section>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton';
import { backgroundClass } from '@/mixins/Helpers.mixin';
import SidebarMixin from '@/mixins/Sidebar.mixin';

export default {
    name: 'ArticleSubscriptionBanner',
    components: {
        ScText: Text,
        AbrdnButton,
    },
    mixins: [backgroundClass, SidebarMixin],
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        showBanner() {
            return (!!this.fields?.title?.value && !!this.fields?.cta?.value) || !!this.isExperienceEditor;
        },
    },
};
</script>

<style scoped lang="scss"></style>
