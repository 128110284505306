<template>
    <PerformanceTableBase
        :title="$t('fundDetailsAnnualizedQuarterlyPerformanceTitle')"
        :loading="loading"
        :error="error"
        :tabData="tabData"
        :fetchTabData="fetchTabData"
        :footnoteText="$t('fundDetailsPerformanceCumulativeFootnote')"
        wrapperId="quarterlyAnnualizedWrapper"
        tableId="quarterlyAnnualizedTable"
    />
</template>

<script>
import { mapActions, mapState } from 'vuex';

import PerformanceTableBase from './PerformanceTableBase';

export default {
    name: 'FundDetailsAnnualizedTab',

    components: {
        PerformanceTableBase,
    },

    computed: {
        ...mapState('fundDetails', {
            error: (state) => state.annualizedQuarterlyData.error,
            loading: (state) => state.annualizedQuarterlyData.loading,
            tabData: (state) => state.annualizedQuarterlyData.data,
        }),
    },

    methods: {
        ...mapActions('fundDetails', {
            fetchTabData: 'fetchAnnualizedQuarterlyTabData',
        }),
    },
};
</script>
