<template>
    <section :id="anchorId" :data-e2e-component-uid="rendering.uid">
        <div
            v-for="(item, index) in fields.items"
            :key="item.id"
            class="team-listing-card-wrapper"
            :class="[index % 2 == 0 ? 'background-white' : vectorBasedBackground]"
        >
            <TeamListingCard :fields="item.fields" :index="index" class="col-12" />
        </div>
    </section>
</template>

<script>
import TeamListingCard from '@/components/Tiles/TeamListingCard.vue';

export default {
    name: 'TeamListingCards',
    components: {
        TeamListingCard,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
};
</script>

<style scoped lang="scss"></style>
