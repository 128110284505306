<template>
    <header
        :id="anchorId"
        :data-e2e-component-uid="rendering.uid"
        :style="{ minHeight: computeHeight + 'px' }"
        class="abrdn-header position-relative"
        :class="{ breadcrumbs: haveBreadcrumbs }"
    >
        <div ref="header" :class="{ 'fixed-top': !isExperienceEditor }">
            <GlobalNavigation
                v-if="fields.items && fields.items.length"
                :data-e2e-component-uid="rendering.uid + '-global-nav'"
                :globalNavFields="fields.items[0]"
            />
            <div class="background-white header-outer d-flex align-items-center">
                <div class="container">
                    <div class="d-flex header-inner">
                        <h5 class="me-auto font-weight-normal logo-wrapper align-self-center">
                            <ScLink :field="fields.logoLink" :showExternalIcon="false">
                                <WrappedImage
                                    :media="fields.logo"
                                    class="abrdn-logo"
                                    :srcSet="[{ mw: 200 }, { mw: 300 }, { mw: 400 }]"
                                    sizes="110px"
                                    :lazy="false"
                                />
                            </ScLink>
                        </h5>
                        <div class="header__content">
                            <div class="d-flex w-100 align-items-center justify-content-end h-100">
                                <ScLink
                                    v-if="!isExperienceEditor"
                                    class="login-icon me-1 d-md-none"
                                    :showExternalIcon="false"
                                    :class="{
                                        'width-unset':
                                            fields.items && fields.items.length
                                                ? !fields.items[0].fields.icon.value
                                                : false,
                                    }"
                                    :field="haveItems && fields.items[0].fields.cta"
                                >
                                    <em
                                        v-if="haveItems && fields.items[0].fields.icon.value"
                                        :class="{ ['bi-' + fields.items[0].fields.icon.value]: true }"
                                        class="login-section-icon d-flex"
                                    />
                                </ScLink>
                                <!-- empty divs are needed for correct Experience Editor behavior -->
                                <div class="h-100">
                                    <Placeholder class="h-100" :rendering="rendering" name="abrdn-mega-navigation" />
                                </div>
                                <div>
                                    <Placeholder
                                        class="align-self-center"
                                        :rendering="rendering"
                                        name="abrdn-language-selector"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Placeholder
            ref="breadcrumbs"
            :rendering="rendering"
            name="abrdn-breadcrumbs"
            class="breadcrumbs w-100"
            :style="{ top: computeHeight + 'px' }"
        />
    </header>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';
import ResizeObserver from 'resize-observer-polyfill';

import GlobalNavigation from '@/components/Generic/Header/GlobalNavigation/GlobalNavigation.vue';
import ScLink from '@/components/Generic/SitecoreWrappers/ScLink.vue';
import WrappedImage from '@/components/Generic/SitecoreWrappers/WrappedImage.vue';

export default {
    name: 'Header',
    components: {
        WrappedImage,
        Placeholder,
        ScLink,
        GlobalNavigation,
    },
    // `toggleContextView` is provided from AppRoot
    inject: {
        toggleContextView: {
            type: Function,
        },
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    data() {
        return {
            contextButtonText: '',
            header: '',
            resizeObserver: null,
        };
    },
    computed: {
        isExperienceEditor() {
            return this.$jss.sitecoreContext().pageEditing || this.$jss.sitecoreContext().pageState === 'preview';
        },
        computeHeight() {
            return this.$root.headerHeight;
        },
        haveItems() {
            return this.fields?.items?.length;
        },
        haveBreadcrumbs() {
            return this.rendering.placeholders['abrdn-breadcrumbs']?.length;
        },
    },
    created() {
        // $t is a computed property, so it's not defined when initializing the `data` object.
        // Therefore, use the `created` hook to set the data.
        this.contextButtonText = this.$t('show-context-data');
    },
    mounted() {
        this.$root.headerHeight = this.$refs.header.getBoundingClientRect().height;
        document.documentElement.style.setProperty('--headerHeight', this.$root.headerHeight + 'px');
        this.updateBreadcrumbsHeight();

        this.observeHeight();
    },
    beforeDestroy() {
        this.resizeObserver.unobserve(this.$el);
    },
    methods: {
        observeHeight() {
            this.resizeObserver = new ResizeObserver(this.resizeHeader);

            this.resizeObserver.observe(this.$el);
        },
        resizeHeader() {
            if (this.$root.headerHeight !== this.$refs.header.getBoundingClientRect().height) {
                this.$root.headerHeight = this.$refs.header.getBoundingClientRect().height;
            }
            this.updateBreadcrumbsHeight();
        },
        toggleContext() {
            this.toggleContextView();
            this.contextButtonText =
                this.contextButtonText === this.$t('hide-context-data')
                    ? this.$t('show-context-data')
                    : this.$t('hide-context-data');
        },
        updateBreadcrumbsHeight() {
            if (this.$refs.breadcrumbs.$el instanceof HTMLElement) {
                document.documentElement.style.setProperty(
                    '--breadcrumbsHeight',
                    this.$refs.breadcrumbs.$el.getBoundingClientRect().height + 'px',
                );
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
.abrdn-header {
    z-index: 1000;
}
.abrdn-logo {
    height: calc(var(--spacer) * 3);
}
html[data-page-theme^='investment'] .abrdn-logo {
    height: calc(var(--spacer) * 4.5);
}
.breadcrumbs {
    @include media-breakpoint-down(md) {
        margin-bottom: var(--breadcrumbsHeight);
    }
}
.logo-wrapper {
    ::v-deep {
        img {
            max-height: 38px;
            width: auto;
        }
    }
}
.login-icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    .login-section-icon {
        font-size: 16px;
        cursor: pointer;
    }
}
.width-unset {
    width: unset;
}
.header-inner {
    min-height: 72px;
}
.header-outer {
    border-bottom: 1px solid black;
}

html[data-page-theme^='financialfairness'] .header-outer {
    border-bottom: 1px solid var(--primary);
}
.breadcrumbs {
    position: absolute;
    @include media-breakpoint-down(md) {
        background: var(--abrdn-white) !important;
    }
}
</style>
