<template>
    <div
        :id="anchorId"
        :data-e2e-component-uid="rendering.uid"
        :style="{ width: '100%' }"
        :class="{ 'experience-editor-holder': isExperienceEditor }"
    >
        <component
            :is="isChildIframe ? 'ChildIframe' : 'ParentIframe'"
            :src="src"
            :fields="fields"
            :rendering="rendering"
            :shouldScroll="shouldScroll"
        />
    </div>
</template>

<script>
import iframeResize from 'iframe-resizer/js/iframeResizer.min';

import ChildIframe from '@/components/Blocks/Iframes/ChildIframe';
import ParentIframe from '@/components/Blocks/Iframes/ParentIframe';

export default {
    name: 'Iframe',
    components: { ChildIframe, ParentIframe },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    data() {
        return {
            scrollExeptions: [/lt.morningstar/],
        };
    },
    computed: {
        shouldScroll() {
            return this.scrollExeptions.some((url) => url.test(this.fields.source.value)) ? 'yes' : 'no';
        },
        isChildIframe() {
            const { templateId } = this.$jss.routeData();
            const IframeResizableChildPage = '6ff27d65-56ff-4533-93bb-b3f7f568d973';
            return templateId === IframeResizableChildPage;
        },
        src() {
            if (typeof window !== 'undefined' && this.rendering.params?.passUrlParameters === '1') {
                const url = new URL(this.fields.source.value);
                const parentUrl = new URL(location.href);

                parentUrl.searchParams.forEach((value, key) => {
                    url.searchParams.set(key, value);
                });

                return url.href;
            }

            return this.fields.source.value;
        },
    },
    mounted() {
        if (this.shouldScroll === 'no') {
            this.$nextTick(() => {
                iframeResize(
                    {
                        warningTimeout: 0,
                    },
                    this.$refs['abrdn-iframe'],
                );
            });
        }
    },
};
</script>

<style scoped lang="scss">
iframe {
    min-height: 650px;
    width: 1px;
    min-width: 100%;
}
.experience-editor-holder iframe {
    pointer-events: none;
}
</style>
