<template>
    <div>
        <div v-if="isLoading">
            <BaseSkeleton height="120px" />
        </div>

        <template v-else-if="data && data.length">
            <div class="position-relative">
                <div
                    id="premiumDiscountLineChartWrapper"
                    class="abrdn-table"
                    :class="[{ scrollable: deviceType === 'mobile' }, { [gradientPosition]: gradientPosition }]"
                >
                    <table
                        id="premiumDiscountLineChartTable"
                        class="not-striped"
                        tabindex="0"
                        :aria-label="$t('fundDetailsPremiumDiscountDataTableTitle')"
                    >
                        <thead>
                            <tr>
                                <th scope="col" />
                                <th v-for="period in data" :key="period.period" scope="col">{{ period.period }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">{{ $t('fundDetailsPremiumDiscountDaysTradedAtPremium') }}</th>
                                <td v-for="period in data" :key="period.period">{{ period.daysatPremium }}</td>
                            </tr>
                            <tr>
                                <th scope="row">{{ $t('fundDetailsPremiumDiscountDaysTradedAtDiscount') }}</th>
                                <td v-for="period in data" :key="period.period">{{ period.daysatDiscount }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <CustomScroll
                v-if="deviceType === 'mobile'"
                wrapperId="premiumDiscountLineChartWrapper"
                tableId="premiumDiscountLineChartTable"
                @changeGradientPosition="changeGradientPosition"
            />
        </template>

        <div v-else class="body-default">{{ $t('noResultsMessage') }}</div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import CustomScroll from '@/components/Functional/CustomScroll';
import BaseSkeleton from '@/components/Generic/BaseSkeleton';
import DeviceType from '@/mixins/DeviceType.mixin';

export default {
    name: 'FundDetailsPremiumDiscountLineChartTable',

    components: {
        BaseSkeleton,
        CustomScroll,
    },

    mixins: [DeviceType],

    data() {
        return {
            gradientPosition: 'gradient-right',
        };
    },

    computed: {
        ...mapState('fundDetails', {
            data: (state) => state.premiumLineChartData.data?.periods,
            isLoading: (state) => state.premiumLineChartData.loading,
        }),
    },

    methods: {
        changeGradientPosition(val) {
            this.gradientPosition = val;
        },
    },
};
</script>
