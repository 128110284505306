<template>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
        <path
            d="M22.9091 22.3808H1.09091C0.490909 22.3808 0 21.8992 0 21.3107V11.6795C0 11.0909 0.490909 10.6094 1.09091 10.6094C1.69091 10.6094 2.18182 11.0909 2.18182 11.6795V20.2405H21.8182V11.6795C21.8182 11.0909 22.3091 10.6094 22.9091 10.6094C23.5091 10.6094 24 11.0909 24 11.6795V21.3107C24 21.8992 23.5091 22.3808 22.9091 22.3808Z"
            fill="currentColor"
        />
        <path
            d="M13.2764 12.9534V2.07013C13.2764 1.48156 12.7855 1 12.1855 1C11.5855 1 11.0946 1.48156 11.0946 2.07013V12.9534L7.49463 9.42192V12.4504L12.1855 17.0519L16.8764 12.4397V9.42192L13.2764 12.9534Z"
            fill="currentColor"
        />
    </svg>
</template>

<script>
export default {
    name: 'IconDownload',
};
</script>

<style scoped></style>
