<template>
    <ScText :id="anchorId" class="subheader-1 text-grey" :field="fields.title" />
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

export default {
    name: 'RichText',
    components: {
        ScText: Text,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
};
</script>

<style scoped lang="scss"></style>
