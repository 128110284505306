<template>
    <div class="mobile-site-selector">
        <div v-for="(item, idx) in siteSelectorItems" :key="idx" class="site-selector__item">
            <h4 class="site-selector__item__heading d-flex align-items-center px-2 px-md-4">
                <WrappedImage
                    :media="item.icon"
                    class="background-to-change me-2"
                    :srcSet="[{ mw: 50 }, { mw: 100 }]"
                    sizes="32px"
                />
                <ScText tag="h4" :field="item.heading" />
            </h4>
            <ScText
                class="site-selector__item__description px-2 px-md-4 mt-3 body-small"
                tag="p"
                :field="item.description"
            />
            <ul v-if="item.links.length" class="site-selector__item__links mt-2">
                <li v-for="cta in item.links" :key="cta.link.id">
                    <ScLink
                        :field="cta.link"
                        :class="{ active: cta.isSelected.value }"
                        :replaceWithSlot="false"
                        :showExternalIcon="false"
                        class="site-selector__item__link py-2 meganav-link meganav-link--small d-flex align-items-center px-2 px-md-4"
                        @click.native="$emit('changeSiteHandler')"
                    >
                        <IconChevron class="link-chevron ms-auto" />
                    </ScLink>
                </li>
            </ul>
            <hr
                class="vector-divider ms-2 me-2 ms-md-4 me-md-4"
                :class="[item.links.length ? 'mt-2' : 'mt-4', siteSelectorItems.length === idx + 1 ? 'mb-2' : 'mb-4']"
            />
        </div>
        <ScLink
            :field="siteSelectorProperty.changeCountryCta"
            class="site-selector__change-country site-selector__item__link meganav-link meganav-link--small d-flex align-items-center px-2 py-2 px-md-4"
            :showExternalIcon="false"
            @click.native="changeSiteHandler()"
        >
            <WrappedImage
                :media="siteSelectorProperty.changeCountryIcon"
                class="background-to-change link-icon me-2"
                :srcSet="[{ mw: 50 }, { mw: 100 }]"
                sizes="28px"
            />
            <span>{{ $t('changeCountrySite') }}</span>
            <IconChevron class="link-chevron ms-auto" />
        </ScLink>
    </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { mapMutations } from 'vuex';

import ScLink from '@/components/Generic/SitecoreWrappers/ScLink.vue';
import WrappedImage from '@/components/Generic/SitecoreWrappers/WrappedImage.vue';
import IconChevron from '@/components/Icons/icon-chevron.vue';

export default {
    name: 'MobileMenuSiteSelector',
    components: {
        WrappedImage,
        ScText: Text,
        IconChevron,
        ScLink,
    },
    props: {
        siteSelectorProperty: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        siteSelectorItems() {
            return this.siteSelectorProperty.vectors;
        },
    },
    methods: {
        ...mapMutations('global', ['setPreOpenGlobalLangSelector']),
        changeSiteHandler() {
            this.$emit('changeSiteHandler');
            this.setPreOpenGlobalLangSelector(true);
        },
    },
};
</script>

<style scoped lang="scss">
.site-selector__item__heading img {
    width: 32px;
    max-height: 32px;
}
.site-selector__item__links {
    padding: 0;
    margin: 0;
    list-style: none;
}
.link-chevron {
    height: 0.75em;
    width: 0.75em;
    transform: rotate(-90deg);
}
.link-icon {
    width: 28px;
    height: 28px;
}
.vector-divider {
    margin: 0;
    background-color: rgba(0, 0, 0, 0.3);
}
</style>
