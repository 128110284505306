<template>
    <div class="d-flex">
        <template v-for="(filter, i) in filters">
            <div :key="filter.id + 'filter'">
                <ScText
                    tag="p"
                    :field="{
                        ...filter,
                        value: filter.value,
                    }"
                    class="form-label mb-1.5"
                />
                <Dropdown
                    v-if="filter.isActive"
                    :key="filter.id + 'filter'"
                    ref="dropdown"
                    class="dropdown me-1"
                    :loading="loading"
                    @cancelEvent="cancelHandler(filter)"
                    @confirmEvent="confirmHandler"
                    @outsideClick="
                        $emit('forget');
                        selectedDropdownIndex = null;
                    "
                    @enter="$emit('getFacets', filter)"
                >
                    <template #trigger-area>
                        <button
                            class="d-flex align-items-center filter-selector expandable"
                            :class="{ focused: selectedDropdownIndex === i }"
                            @click="
                                $emit('getFacets', filter);
                                selectedDropdownIndex = i;
                            "
                        >
                            <span :class="{ 'text-dark-grey': !getSelected(filter) }" class="field-text">
                                {{ getSelectedFilterPlaceholder(filter) }}
                            </span>
                            <i class="d-flex bi-chevron-down" aria-hidden="true" />
                        </button>
                    </template>
                    <div
                        class="filter-dropdown-window py-1 d-flex flex-column position-relative"
                        @keydown.esc="
                            $emit('forget');
                            $refs.dropdown[i].toggleDropdown();
                        "
                    >
                        <div class="filter-dropdown-options-wrapper">
                            <ArticleFilterOption
                                v-for="option in filter.options.filter((option) => emptyFacets.includes(option.id))"
                                :key="option.id + 'filter-option'"
                                :option="option"
                                :loading="loading"
                                :filtersFacets="filtersFacets"
                                :approvedFilters="approvedFilters"
                                @onSelect="$emit('onSelect', option)"
                            />
                        </div>
                    </div>
                </Dropdown>
            </div>
        </template>
    </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import ArticleFilterOption from '@/components/Functional/ArticleFilterBar/ArticleFilterOption.vue';
import Dropdown from '@/components/Generic/Dropdown';

export default {
    name: 'ArticleFilters',
    components: { ScText: Text, Dropdown, ArticleFilterOption },
    props: {
        filters: {
            type: Array,
            default: () => [],
        },
        filtersFacets: {
            type: Array,
            default: () => [],
        },
        emptyFacets: {
            type: Array,
            default: () => [],
        },
        approvedFilters: {
            type: Array,
            default: () => [],
        },
        loading: {
            type: Boolean,
            default: () => false,
        },
    },
    data() {
        return {
            selectedDropdownIndex: null,
        };
    },
    methods: {
        getSelected(filter) {
            const firstSelectedOption = filter.options.filter((item) => item.selected)[0];
            return firstSelectedOption && { value: firstSelectedOption.name };
        },
        getSelectedFilters(filter) {
            return filter.options.filter((item) => item.selected).length;
        },
        getSelectedFilterPlaceholder(filter) {
            const selectedOptions = filter.options.filter((item) => item.selected);
            if (selectedOptions.length) {
                return `${selectedOptions.length} ${
                    selectedOptions.length === 1 ? this.$t('filterSelected') : this.$t('filtersSelected')
                }`;
            } else {
                return this.$t('selectFilter');
            }
        },
        cancelHandler(filter) {
            this.$emit('clearFilterGroup', filter);
            this.selectedDropdownIndex = null;
        },
        confirmHandler() {
            this.$emit('aproveFilters');
            this.selectedDropdownIndex = null;
        },
    },
};
</script>

<style scoped lang="scss">
.bi-chevron-down {
    transform: rotate(180deg);

    .collapsed & {
        transform: rotate(0deg);
    }
}
</style>
