<script>
import { mapGetters, mapState } from 'vuex';

import { standardFormat } from '@/constants/dateFormats';
import DeviceType from '@/mixins/DeviceType.mixin';
import { formatCurrency, localizeDate } from '@/mixins/Helpers.mixin';

export default {
    name: 'FundDetailsTabSharedLogic',

    mixins: [localizeDate, DeviceType, formatCurrency],

    computed: {
        ...mapState('fundDetails', ['fundId', 'correlationFundRangeID', 'fundRangeName', 'name', 'take', 'skip']),
        ...mapGetters('fundDetails', ['selectedShareClass']),

        tableColumnsName() {
            const rowsColumnName = new Set();
            this.tabData?.results.forEach((item) => {
                item.columns.forEach((col) => rowsColumnName.add(col.columnLabel));
            });
            return rowsColumnName;
        },
        reportDate() {
            return (
                this.$t('fundDetailsHoldingsReportDate') +
                ' ' +
                this.localizeDate(this.tabData?.reportDate, standardFormat)
            );
        },
        reportDateFromTo() {
            return (
                this.$t('fundDetailsHoldingsReportDate') +
                ' ' +
                this.localizeDate(this.tabData?.reportPeriodFromDate, standardFormat) +
                ' - ' +
                this.localizeDate(this.tabData?.reportDate, standardFormat)
            );
        },
        isScrollable() {
            if (this.tabData === null) return false;
            if (this.deviceType === 'mobile') {
                return this.tableColumnsName.size >= 2;
            } else if (this.deviceType === 'tablet') {
                return this.tableColumnsName.size > 4;
            } else {
                return this.tableColumnsName.size > 6;
            }
        },
    },

    async created() {
        if (this.tabData || this.isExperienceEditor) return;

        const {
            fundsConfiguration: {
                countryInvestors: { countryCode, literatureAuthorization, investorType, jurisdiction },
            },
            displayLanguage,
            site: { name },
        } = this.$jss.sitecoreContext();

        await this.fetchTabData({
            countryCode,
            language: displayLanguage,
            shareClass: this.selectedShareClass.id,
            fund: this.fundId,
            correlationFundRangeId: this.correlationFundRangeID,
            fundRangeName: this.fundRangeName,
            fundName: this.name,
            literatureAuthorizations: [literatureAuthorization],
            countryInvestors: {
                countryCode: countryCode,
                investorType: investorType,
                jurisdiction: jurisdiction,
            },
            site: name,
            take: this.take,
            skip: this.skip,
        });
    },
};
</script>
