export function drawGtmScripts(gtmIds) {
    if (!gtmIds || !gtmIds.length) {
        return '';
    }

    const gtmScripts = gtmIds.map((gtm) => ({
        vmid: `gtmScript-GTM-${gtm}`,
        innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-${gtm}');`,
        once: true,
    }));

    return gtmScripts;
}

export function drawGtmHeads(gtmIds) {
    if (!gtmIds || !gtmIds.length) {
        return '';
    }

    const gtmHeads = gtmIds.map((gtm) => ({
        vmid: `gtmHead-GTM-${gtm}`,
        innerHTML: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-${gtm}"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        pbody: true,
        once: true,
    }));

    return gtmHeads;
}
