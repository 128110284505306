export async function getArticles({ commit, state }, filters) {
    const { setDataRules, ...requestPayload } = filters;
    commit('setLoading', true);
    const res = await state.api.searchRequest(requestPayload);
    commit('setData', {
        ...res.data,
        setDataRules,
    });
    commit('setLoading', false);
}
export function getFacets({ commit, state }, filters) {
    commit('setLoading', true);
    state.api.facetsRequest(filters).then((res) => {
        commit('setFacetsData', {
            ...res.data,
        });
        commit('setLoading', false);
    });
}

export function getEmptyFacets({ commit, state }, filters) {
    commit('setLoading', true);
    state.api.facetsRequest(filters).then((res) => {
        commit('setEmptyFacetsData', {
            ...res.data,
        });
        commit('setLoading', false);
    });
}

export function clearArticles({ commit }) {
    commit('setData', { results: [], facets: [], totalResults: 0 });
}
