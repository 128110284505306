<template>
    <div class="container">
        <div class="row">
            <div class="col-12" :class="{ 'col-lg-8': !fullWidth }">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FundDetailsTabRow',

    props: {
        fullWidth: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
