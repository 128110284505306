<template>
    <RelatedStoriesBasic :title="$t('relatedStoriesTitle')" :fields="fields" :rendering="rendering" />
</template>

<script>
import RelatedStoriesBasic from '@/components/Pages/ArticleParts/RelatedStoriesBasic';

export default {
    name: 'RelatedStories',
    components: { RelatedStoriesBasic },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
};
</script>

<style scoped lang="scss"></style>
