<template>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
        <path
            d="M22.7519 18.1239L20.1327 15.1605C19.5077 14.3009 18.2874 14.0885 17.206 14.6953L15.5491 15.737C15.1126 15.4741 14.061 14.6751 11.9775 12.2983C10.8763 11.0442 10.033 9.98226 9.49724 9.15293C9.3385 8.92031 9.21944 8.71803 9.13015 8.5461C9.08055 8.44496 9.04086 8.36405 9.0111 8.30337L10.1818 6.75596C10.8862 5.89628 10.8664 4.61183 10.1322 3.77238L7.45347 0.728125C6.72923 -0.0910937 5.51885 -0.242801 4.61602 0.394369C4.61602 0.394369 3.30643 1.34507 2.98895 1.59791C1.81825 2.54861 1.16346 3.82295 1.02456 5.4007C0.627712 10.1542 5.15176 15.7067 6.54073 17.2945C9.56668 20.7332 13.8625 24 17.5929 24C18.7239 24 19.8153 23.6966 20.7974 23.0088C20.8867 22.9482 20.976 22.8875 22.7817 21.0265C23.4861 20.1871 23.4762 18.9431 22.7519 18.1239ZM19.6863 21.3401C16.7397 23.3932 11.5807 19.9848 8.0289 15.9393C4.76484 12.2275 2.79053 8.15166 3.00879 5.57264C3.09808 4.56126 3.49493 3.7825 4.21917 3.19589C4.51681 2.95316 5.74703 2.06315 5.74703 2.06315C5.81648 2.01258 5.91569 2.02269 5.97522 2.09349L8.65394 5.13775C8.7333 5.22877 8.74323 5.37036 8.63409 5.50184L7.14592 7.44369L7.03679 7.63586C6.81852 8.10109 6.92765 8.68769 7.38403 9.53725C7.49316 9.74964 7.64198 10.0025 7.8404 10.2958C8.43567 11.206 9.32858 12.3388 10.4993 13.6637C12.4637 15.8887 13.9419 17.2541 14.9142 17.6991C15.3904 17.9216 15.946 17.8811 16.3924 17.6081L18.208 16.4753C18.3965 16.3742 18.5255 16.3944 18.6049 16.4753L21.2836 19.5196C21.3431 19.5803 21.3431 19.6814 21.3332 19.6915C20.5792 20.4197 19.7359 21.2794 19.6863 21.3401Z"
            fill="currentColor"
        />
    </svg>
</template>

<script>
export default {
    name: 'IconPhone',
};
</script>

<style scoped></style>
