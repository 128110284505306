<template>
    <div class="overflow-wrapper" :data-e2e-component-uid="renderingId">
        <div class="past-performance-modal background-white w-100">
            <div class="past-performance-modal__heading d-flex align-items-center justify-content-between p-2 p-md-4">
                <h3 class="subheader-1">{{ $t('performanceScenarioCalculations') }}</h3>
                <button class="btn btn-naked past-performance-modal__closer text-link" @click="closeModal">
                    {{ $t('close') }} <IconX class="ms-1" />
                </button>
            </div>
            <p v-if="tableData" class="field-text pt-5 px-2 px-md-4">
                {{ tableData.recommendedHoldingPeriod }}
            </p>
            <p class="field-text pb-4 px-2 px-md-4">{{ $t('exampleInvestment') }}: 10,000 {{ currencyType }}</p>
            <div class="d-flex flex-column flex-md-row w-100 px-2 px-md-4">
                <div class="d-flex flex-column month-selector w-100 me-2 mb-2 mb-md-0">
                    <p class="form-label capitalize mb-1">{{ $t('month') }}</p>
                    <AbrdnSelect :key="lastMonthAvailable + 'month'" :fields="monthsList" @updateValue="selectMonth" />
                </div>
                <div class="d-flex flex-column year-selector w-100">
                    <p id="local" class="form-label capitalize mb-1">{{ $t('year') }}</p>
                    <AbrdnSelect :key="lastMonthAvailable + 'year'" :fields="years" @updateValue="selectYear" />
                </div>
            </div>
            <div class="past-performance-modal__content px-2 px-md-4 pb-md-4 py-4 py-md-6">
                <table v-if="tableData" class="d-flex flex-column" aria-label="predictive performance table">
                    <tr class="py-2 pe-md-2">
                        <th scope="col" class="section-header-small align-items-end align-items-md-center">
                            {{ $t('scenarios') }}
                        </th>
                        <th
                            v-if="tableData.performanceSets.length"
                            scope="col"
                            class="section-header-small align-items-end align-items-md-center"
                        >
                            <p
                                v-for="(column, i) in tableData.performanceSets[0].scenarios[0].periods"
                                :key="column.performanceTimePeriodID + 'years'"
                                class="relative"
                            >
                                {{ column.performanceTimePeriod }}
                                <span v-if="i === recomendedYearIndex" class="reccomended-year"
                                    >({{ recomendedYearPeriodTitle }})</span
                                >
                            </p>
                        </th>
                    </tr>
                    <template v-if="tableData.performanceSets[0]">
                        <tr
                            v-for="row in tableData.performanceSets"
                            :key="row.scenarioType"
                            class="ps-xl-2 flex-column flex-xl-row py-3"
                        >
                            <td class="ps-md-2 field-text mb-0 mb-md-1 mb-xl-0">{{ row.scenarioType }}</td>
                            <td class="d-flex flex-column scenarios">
                                <div
                                    v-for="(scenario, index) in row.scenarios"
                                    :key="scenario.performanceCategoryID"
                                    class="ps-md-2 d-flex w-100 justify-content-between"
                                    :class="{
                                        'pb-2 pb-md-3 get-back-cluster pe-2 pt-3 pt-xl-0': !index,
                                        'pt-2 pt-md-3 pe-2': index,
                                    }"
                                >
                                    <p class="scenario-subcategory-title field-text-enhanced">
                                        {{ scenario.scenarioLabel }}
                                    </p>
                                    <div class="d-flex returns-section">
                                        <p
                                            v-for="value in scenario.periods"
                                            :key="value.performanceTimePeriodID"
                                            class="field-text-enhanced"
                                        >
                                            {{ value.value }}
                                        </p>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </template>
                    <div v-else class="d-flex justify-content-center align-items-center w-100 py-6">
                        {{ $t('performanceScenarioCalculationsNoResultsMessage') }}
                    </div>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

import { getPredictivePerformance } from '@/api/kidhub';
import AbrdnSelect from '@/components/Generic/FormElements/FormFields/AbrdnSelect.vue';
import IconX from '@/components/Icons/icon-x.vue';

export default {
    name: 'PredictivePerformance',
    components: { IconX, AbrdnSelect },
    props: {
        selectedRow: {
            type: Object,
            default: /* istanbul ignore next */ () => {},
        },
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    data() {
        return {
            tableData: null,
            currentYear: new Date().getFullYear(),
            lastYearAvailable: null,
            lowestPossibleYear: '2022',
            lastMonthAvailable: null,
            selectedYear: null,
            currencyType: null,
            selectedMonth: new Date().getMonth(),
            recomendedYearPeriodTitle: null,
            recomendedYearIndex: null,
            monthes: {
                placeholder: { value: this.$t('chooseAnOption') },
                options: {
                    value: [
                        {
                            name: 'january',
                            displayName: this.$t('dateMonthJan'),
                            value: '01',
                            selected: false,
                            disabled: true,
                        },
                        {
                            name: 'february',
                            displayName: this.$t('dateMonthFeb'),
                            value: '02',
                            selected: false,
                            disabled: true,
                        },
                        {
                            name: 'march',
                            displayName: this.$t('dateMonthMar'),
                            value: '03',
                            selected: false,
                            disabled: true,
                        },
                        {
                            name: 'april',
                            displayName: this.$t('dateMonthApr'),
                            value: '04',
                            selected: false,
                            disabled: true,
                        },
                        {
                            name: 'may',
                            displayName: this.$t('dateMonthMay'),
                            value: '05',
                            selected: false,
                            disabled: true,
                        },
                        {
                            name: 'june',
                            displayName: this.$t('dateMonthJun'),
                            value: '06',
                            selected: false,
                            disabled: true,
                        },
                        {
                            name: 'july',
                            displayName: this.$t('dateMonthJul'),
                            value: '07',
                            selected: false,
                            disabled: true,
                        },
                        {
                            name: 'august',
                            displayName: this.$t('dateMonthAug'),
                            value: '08',
                            selected: false,
                            disabled: true,
                        },
                        {
                            name: 'september',
                            displayName: this.$t('dateMonthSep'),
                            value: '09',
                            selected: false,
                            disabled: true,
                        },
                        {
                            name: 'october',
                            displayName: this.$t('dateMonthOct'),
                            value: '10',
                            selected: false,
                            disabled: true,
                        },
                        {
                            name: 'november',
                            displayName: this.$t('dateMonthNov'),
                            value: '11',
                            selected: false,
                            disabled: true,
                        },
                        {
                            name: 'december',
                            displayName: this.$t('dateMonthDec'),
                            value: '12',
                            selected: false,
                            disabled: true,
                        },
                    ],
                },
            },
            years: {
                placeholder: { value: this.$t('chooseAnOption') },
                options: {
                    value: [
                        {
                            name: new Date().getFullYear(),
                            displayName: new Date().getFullYear(),
                            value: new Date().getFullYear(),
                            selected: true,
                        },
                    ],
                },
            },
            sortMap: {
                stressScenario: 1,
                unfavourableScenario: 2,
                moderateScenario: 3,
                favourableScenario: 4,
            },
            renderingId: undefined,
        };
    },
    computed: {
        monthsList() {
            return {
                ...this.monthes,
                options: {
                    value: this.monthes.options.value.map((month) => ({
                        ...month,
                        selected: +this.lastMonthAvailable === +month.value,
                        disabled: +month.value > +this.lastMonthAvailable,
                    })),
                },
            };
        },
    },
    mounted() {
        this.renderingId = uuidv4();
        this.getTableData(
            this.selectedRow.shareclassID,
            this.$jss?.sitecoreContext()?.displayLanguage,
            this.fields?.countries?.fields?.countryCode.value,
        ).then((response) => {
            const stringDate = response.data.lastRelevantDate.split(' ')[0].split('/');
            this.lastYearAvailable = stringDate[2];
            this.lastMonthAvailable = stringDate[0];

            this.lastMonthAvailable =
                this.lastMonthAvailable.length === 1 ? '0' + this.lastMonthAvailable : this.lastMonthAvailable;
            let yearsToDisplay = +this.lastYearAvailable + 1 - +this.lowestPossibleYear;
            yearsToDisplay = yearsToDisplay ? yearsToDisplay : 1;
            yearsToDisplay = [...Array(yearsToDisplay).keys()];
            this.years = {
                ...this.years,
                options: {
                    value: yearsToDisplay.map((yearIndex) => ({
                        name: (+this.lowestPossibleYear + yearIndex).toString(),
                        displayName: (+this.lowestPossibleYear + yearIndex).toString(),
                        value: (+this.lowestPossibleYear + yearIndex).toString(),
                        selected:
                            this.lastYearAvailable.toString() === (+this.lowestPossibleYear + yearIndex).toString(),
                    })),
                },
            };

            if (response.data.performanceSets.stressScenario) {
                this.prepareData(response);
                this.tableData.performanceSets.forEach((item) => {
                    item.scenarios.forEach((scenario) => {
                        scenario.periods.forEach((value) => {
                            if (this.currencyType === null) {
                                this.currencyType = value.value.split(' ')[1];
                            }
                        });
                    });
                });
            } else {
                this.tableData = {
                    ...response.data,
                    performanceSets: [],
                };
            }
        });
    },
    methods: {
        getTableData(ShareClassId, Language, CountryCode) {
            return getPredictivePerformance({
                ShareClassId,
                Language,
                CountryCode,
            });
        },
        closeModal() {
            this.$emit('closeModal');
        },
        selectMonth({ value }) {
            this.selectedMonth = this.monthsList.options.value.find((item) => item.name === value).value;
            this.getPredictiveData();
        },
        prepareData(response) {
            this.tableData = {
                ...response.data,
                performanceSets: Object.keys(response.data.performanceSets)
                    .sort((x, y) => {
                        return this.sortMap[x] - this.sortMap[y];
                    })
                    .map((key) => {
                        return {
                            ...response.data.performanceSets[key],
                            scenarios: response.data.performanceSets[key].scenarios.map((scenario) => {
                                return {
                                    ...scenario,
                                    periods: scenario.periods.map((period, i) => {
                                        const recomendedTitle = period.performanceTimePeriod
                                            .split(' (')[1]
                                            ?.split(')')[0];
                                        if (this.recomendedYearPeriodTitle === null && recomendedTitle) {
                                            this.recomendedYearPeriodTitle = recomendedTitle;
                                            this.recomendedYearIndex = i;
                                        }

                                        return {
                                            ...period,
                                            performanceTimePeriod: period.performanceTimePeriod.split(' (')[0],
                                        };
                                    }),
                                };
                            }),
                        };
                    }),
            };
        },
        getPredictiveData() {
            getPredictivePerformance({
                ShareClassId: this.selectedRow.shareclassID,
                Month: this.selectedMonth.toString(),
                Year: this.selectedYear ? this.selectedYear.toString() : this.lastYearAvailable.toString(),
                Language: this.$jss?.sitecoreContext()?.displayLanguage,
                CountryCode: this.fields?.countries?.fields?.countryCode.value,
            }).then((response) => {
                if (response.data.performanceSets.stressScenario) {
                    this.prepareData(response);
                } else {
                    this.tableData = {
                        ...response.data,
                        performanceSets: [],
                    };
                }
            });
        },
        selectYear({ value }) {
            this.selectedYear = value;
            if (value < this.lastYearAvailable) {
                this.lastMonthAvailable = 12;
                this.selectedMonth = 12;
            }
            this.getPredictiveData();
            this.years.options.value.forEach((yearItem, i) => {
                this.years.options.value[i].selected = +yearItem.value === +value;
            });
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
.overflow-wrapper {
    padding: 100px 0;
    width: 100%;
    @include media-breakpoint-down(md) {
        padding: 44px 0;
    }
}
.past-performance-modal__heading {
    border-bottom: 1px solid var(--digital-light-grey);
}
.past-performance-modal__closer svg {
    width: 0.875em;
    height: 0.875em;
}
.body-default {
    font-weight: 600;
}
tr {
    display: flex;
    th,
    td {
        display: flex;
        width: 100%;
        align-items: center;
    }
    th:first-child {
        min-width: 200px;
        max-width: 200px;
    }
    td:first-child {
        min-width: 250px;
        max-width: 250px;
    }
    th:nth-child(2) {
        display: flex;
        justify-content: flex-end;
        p {
            min-width: 100px;
            max-width: 100px;
        }
        p:not(:first-child) {
            margin-left: calc(var(--spacer) * 6);
            @include media-breakpoint-down(xl) {
                margin-left: calc(var(--spacer) * 3);
            }
        }
    }
    .returns-section {
        display: flex;
        justify-content: flex-end;
        .field-text-enhanced {
            text-align: right;
        }
        p {
            min-width: 100px;
            max-width: 100px;
        }
        p:not(:first-child) {
            margin-left: calc(var(--spacer) * 6);
            @include media-breakpoint-down(xl) {
                margin-left: calc(var(--spacer) * 3);
            }
        }
    }
}

tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    @include media-breakpoint-down(md) {
        border-bottom: 0;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            left: calc(var(--spacer) * -2);
            right: calc(var(--spacer) * -2);
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.3);
            height: 1px;
        }
    }
}
.table-sorting-wrapper {
    position: relative;
    height: 100%;
    .bi-caret-up-fill {
        color: var(--digital-steel-grey);
        cursor: pointer;
        top: 15%;
        font-size: 9px;
        height: 10px;
        position: absolute;
    }
    .bi-caret-down-fill {
        color: var(--digital-steel-grey);
        cursor: pointer;
        top: 46%;
        height: 10px;
        font-size: 9px;
        position: absolute;
        &::before {
            vertical-align: 1.25em;
        }
    }
    .active {
        color: var(--abrdn-black);
    }
}

.scenarios {
    p {
        white-space: nowrap;
    }
    .scenario-subcategory-title {
        @include media-breakpoint-down(xl) {
            max-width: 195px;
            white-space: normal;
        }
    }
    .field-text-enhanced {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.02em;
        @include media-breakpoint-down(xl) {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.02em;
        }
    }
}

.bi-box-arrow-up-right {
    cursor: pointer;
}

.get-back-cluster {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.field-text {
    align-items: flex-start;
    text-transform: capitalize;
}
.capitalize {
    text-transform: capitalize;
}

table {
    @include media-breakpoint-down(md) {
        min-width: 594px;
    }
}

.past-performance-modal__content {
    overflow: auto;
}

.past-performance-modal__content {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}
.past-performance-modal__content::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}
.month-selector,
.year-selector {
    max-width: 318px;
    @include media-breakpoint-down(xl) {
        max-width: 240px;
    }
    @include media-breakpoint-down(md) {
        max-width: 100%;
        width: 100%;
    }
}

.relative {
    position: relative;
    text-align: right;
    .reccomended-year {
        position: absolute;
        right: 0;
        text-align: right;
        bottom: calc(100% + 10px);
        color: var(--text-grey);
        width: 133px;
    }
}
</style>
