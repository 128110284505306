<template>
    <nav :id="anchorId" class="d-flex" :data-e2e-component-uid="rendering.uid">
        <button
            class="btn btn-naked meganav__burger d-flex d-xl-none"
            @click="
                toggleMobileMenu(true);
                toggleWindowScroll(true);
            "
        >
            <IconBurger />
        </button>
        <ul class="d-none d-xl-flex">
            <MegaNavigationMenuItem
                v-for="category in categories"
                :key="category.id"
                :active="category === activeCategory"
                :category="category"
                :closeLabel="fields.closeLabel"
                @setActiveCategory="activeCategory = $event"
            />
        </ul>
        <MegaNavigationBodyMobile
            :class="{ active: isMobileMenuOpen }"
            :categories="categories"
            :closeLabel="fields.closeLabel"
            :backLabel="fields.backLabel"
            class="d-block d-xl-none"
            @closeMobileMenu="
                toggleMobileMenu(false);
                toggleWindowScroll(false);
            "
        />
    </nav>
</template>

<script>
import MegaNavigationBodyMobile from '@/components/Generic/Header/MegaNavigation/MegaNavigationBodyMobile';
import MegaNavigationMenuItem from '@/components/Generic/Header/MegaNavigation/MegaNavigationMenuItem';
import IconBurger from '@/components/Icons/icon-burger';
import DisableScroll from '@/mixins/DisableScroll.mixin';

export default {
    name: 'MegaNavigation',
    components: {
        IconBurger,
        MegaNavigationBodyMobile,
        MegaNavigationMenuItem,
    },
    mixins: [DisableScroll],
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    data() {
        return {
            activeCategory: null,
            isMobileMenuOpen: false,
        };
    },
    computed: {
        categories() {
            return this.fields.items
                .map((item) => {
                    item.hasLinks = !!item.fields.items?.length;
                    item.directLink =
                        !item.hasLinks && item.fields.pulloutLink.value.href ? item.fields.pulloutLink : false;
                    return item;
                })
                .filter((item) => item.hasLinks || item.directLink || this.isExperienceEditor);
        },
    },
    methods: {
        toggleMobileMenu(state) {
            this.isMobileMenuOpen = state;
        },
    },
};
</script>

<style scoped lang="scss">
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

nav,
ul {
    height: 100%;
}

.meganav__burger {
    width: 24px;
    height: 24px;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    border: none;

    svg {
        width: 20px;
        height: 20px;
    }
}
</style>
