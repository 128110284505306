<template>
    <RelatedArticlesSections
        :id="anchorId"
        :fields="fields"
        :rendering="rendering"
        navNextSelector=".nav-next"
        navPrevSelector=".nav-prev"
        backgroundColor="background-blue-grey"
    >
        <template #customSliderHeading>
            <div class="slider-heading mb-4 mb-md-5">
                <h4>{{ $t('featuredThemeTitle') }}</h4>
                <div class="slider-heading__cta">
                    <ScLink v-if="showViewAllLink" :field="viewAllLinkField" class="inline-link revert-underline">
                        {{ $t('viewAll') }} {{ themeName }}
                    </ScLink>
                </div>
                <h3>{{ themeTitle }}</h3>
                <div class="d-none d-xl-flex slider-navigation align-items-center ms-auto">
                    <SliderNavCircle ref="navPrev" aria-label="Previous slide" class="nav-prev" :direction="'left'" />
                    <SliderNavCircle ref="navNext" aria-label="Next slide" class="ms-3 nav-next" :direction="'right'" />
                </div>
            </div>
        </template>
    </RelatedArticlesSections>
</template>

<script>
import SliderNavCircle from '@/components/Functional/Slider/SliderNavCircle';
import ScLink from '@/components/Generic/SitecoreWrappers/ScLink.vue';
import RelatedArticlesSections from '@/components/Sections/RelatedArticlesVariations/RelatedArticlesSection';

export default {
    name: 'FeaturedThemeSection',
    components: { ScLink, RelatedArticlesSections, SliderNavCircle },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        themeTitle() {
            return this.fields.title?.value;
        },
        themeName() {
            return this.fields.name?.value;
        },
        showViewAllLink() {
            return !!this.fields?.viewAll?.value;
        },
        viewAllLinkField() {
            return { value: { href: this.fields?.viewAll?.value } };
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
.slider-heading {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-areas:
        'subtitle cta'
        'title navigations';
    grid-row-gap: calc(var(--spacer) * 5);
    @include media-breakpoint-down(md) {
        grid-template-columns: auto;
        grid-row-gap: calc(var(--spacer) * 3);
        grid-template-areas:
            'subtitle'
            'title'
            'cta';
    }
}
h4 {
    grid-area: subtitle;
}
h3 {
    grid-area: title;
}
.slider-heading__cta {
    grid-area: cta;
}
.slider-navigation {
    grid-area: navigations;
}
</style>
