<template>
    <section
        v-if="(slides && slides.length) || isExperienceEditor"
        :id="anchorId"
        :data-e2e-component-uid="rendering.uid"
        class="default-section-padding"
        :class="getBackgroundColor"
    >
        <div class="slider-outer container">
            <slot name="customSliderHeading" />
            <div
                v-if="!$slots.customSliderHeading"
                class="slider-heading d-flex align-items-center justify-content-between mb-4 mb-md-5 background-to-change"
            >
                <h2 class="h4">{{ sectionTitle || $t('relatedArticlesTitle') }}</h2>
                <div class="d-none d-xl-flex slider-navigation align-items-center">
                    <SliderNavCircle ref="navPrev" aria-label="Previous slide" class="nav-prev" :direction="'left'" />
                    <SliderNavCircle ref="navNext" aria-label="Next slide" class="ms-3 nav-next" :direction="'right'" />
                </div>
            </div>
            <Slider :sliderOptions="sliderOptions">
                <template #slides>
                    <SliderSlide v-for="(slide, idx) in slides" :key="idx">
                        <component :is="sliderCardComponent" :parentBackground="getBackgroundColor" :fields="slide" />
                    </SliderSlide>
                </template>
            </Slider>
            <div
                ref="pagination"
                class="d-xl-none slider-pagination d-flex align-items-center justify-content-center mt-4 mt-md-5"
            />
        </div>
    </section>
</template>

<script>
import { Keyboard, Navigation, Pagination } from 'swiper';

import Slider from '@/components/Functional/Slider/Slider';
import SliderNavCircle from '@/components/Functional/Slider/SliderNavCircle';
import SliderSlide from '@/components/Functional/Slider/SliderSlide';
import RelatedArticleTile from '@/components/Tiles/RelatedArticleTile';

export default {
    name: 'RelatedArticlesSections',
    components: { SliderNavCircle, SliderSlide, Slider, RelatedArticleTile },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        sectionTitle: {
            type: String,
            default: () => '',
        },
        navPrevSelector: {
            type: [String, Boolean],
            default: () => false,
        },
        navNextSelector: {
            type: [String, Boolean],
            default: () => false,
        },
        backgroundColor: {
            type: String,
            default: () => 'background-digital-light-grey',
        },
        sliderCardComponent: {
            type: Object,
            default: () => RelatedArticleTile,
        },
    },
    data() {
        return {
            sliderOptions: null,
        };
    },
    computed: {
        isDefaultHeading() {
            return !this.$slots.customSliderHeading;
        },
        slides() {
            return this.fields.articles?.value || [];
        },
        getBackgroundColor() {
            return this.rendering?.params?.background || this.backgroundColor;
        },
    },
    mounted() {
        if (this.slides && this.slides.length) {
            this.sliderOptions = {
                modules: [Navigation, Pagination, Keyboard],
                slidesPerView: 'auto',
                spaceBetween: 15,
                navigation: {
                    nextEl: this.isDefaultHeading
                        ? this.$refs.navNext.$el
                        : this.$el.querySelector(this.navNextSelector),
                    prevEl: this.isDefaultHeading
                        ? this.$refs.navPrev.$el
                        : this.$el.querySelector(this.navPrevSelector),
                },
                pagination: {
                    el: this.$refs.pagination,
                    type: 'bullets',
                },
                keyboard: {
                    enabled: true,
                },
                breakpoints: {
                    768: {
                        spaceBetween: 30,
                    },
                },
            };
        }
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
@import '@/assets/styles/_mixins.scss';
.swiper-slide {
    width: 470px;
    height: auto;
    @include media-breakpoint-down(md) {
        width: calc(100% - 15px);
    }
}
.related-article {
    height: 100%;
}
section {
    overflow: hidden;
}
.swiper {
    overflow: visible;
}

::v-deep .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    transition: opacity 0.2s;
    opacity: 0.3;
    background-color: var(--abrdn-black);
    border-radius: 50%;
    @include dark-backgrounds {
        background-color: var(--abrdn-white);
    }
}
::v-deep .swiper-pagination-bullet + .swiper-pagination-bullet {
    margin-left: calc(var(--spacer) * 2);
}
::v-deep .swiper-pagination-bullet-active {
    opacity: 1;
}
</style>
