<template>
    <!-- Slider main container -->
    <div class="swiper">
        <!-- Additional required wrapper -->
        <div class="swiper-wrapper">
            <!-- Slides -->
            <slot name="slides" />
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper';

export default {
    name: 'Slider',
    props: {
        sliderOptions: {
            type: Object,
            default: /* istanbul ignore next */ () => null,
        },
    },
    data() {
        return {
            slider: null,
        };
    },
    watch: {
        sliderOptions: {
            handler: function (val) {
                if (val && this.$el) {
                    this.destroySlider();
                    this.slider = new Swiper(this.$el, val);
                    this.$emit('swiperInited', this.slider);
                }
            },
        },
    },
    mounted() {
        if (this.sliderOptions) {
            this.slider = new Swiper(this.$el, this.sliderOptions);
            this.$emit('swiperInited', this.slider);
        }
    },
    beforeDestroy() {
        this.destroySlider();
    },
    methods: {
        destroySlider() {
            if (this.slider) {
                this.slider.destroy();
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import '~swiper/swiper.min.css';
</style>
