<template>
    <div class="position-relative">
        <div ref="chartWrapper" class="chart-wrapper" />
        <div v-if="isLoading" class="loading-overlay">
            <BaseSkeleton height="100%" />
        </div>
    </div>
</template>

<script>
import debounce from 'lodash.debounce';

import BaseSkeleton from '@/components/Generic/BaseSkeleton';
import { localizeDate } from '@/mixins/Helpers.mixin';

export default {
    name: 'Chart',

    components: {
        BaseSkeleton,
    },

    mixins: [localizeDate],

    data() {
        return {
            chartHeight: 0,
            chartWidth: 0,
        };
    },

    computed: {
        onResize() {
            return debounce(this.updateChartDimensions, 500);
        },
    },

    watch: {
        chart() {
            this.renderChart();
        },
    },

    mounted() {
        window.addEventListener('resize', this.onResize);
        this.updateChartDimensions();
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },

    methods: {
        updateChartDimensions() {
            this.chartWidth = this.$refs.chartWrapper.offsetWidth;
            this.chartHeight = this.$refs.chartWrapper.offsetHeight;
        },

        renderChart() {
            this.$refs.chartWrapper.innerHTML = '';
            this.$refs.chartWrapper.append(this.chart);
        },
    },
};
</script>

<style lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';

.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--abrdn-white);
}

.chart-wrapper {
    position: relative;
    padding-top: 75%;

    @include media-breakpoint-up(lg) {
        padding-top: 40%;
    }
}

.chart-wrapper {
    > svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    svg {
        font-size: 14px;
    }
}
</style>
