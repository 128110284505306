import VuePortal from '@linusborg/vue-simple-portal';
import { SitecoreJssPlaceholderPlugin } from '@sitecore-jss/sitecore-jss-vue';
import gsap from 'gsap';
import Vue from 'vue';
import VueApollo from 'vue-apollo';
import Meta from 'vue-meta';
import VueSocialSharing from 'vue-social-sharing';

import AppRoot from './AppRoot';
import GraphQLClientFactory from './lib/GraphQLClientFactory';
import SitecoreJssStorePlugin from './lib/SitecoreJssStorePlugin';
import { createRouter } from './router';
import { getApiKey } from './Service.api';
import store from './store';
import componentFactory from './temp/componentFactory';
import config from './temp/config';

Vue.config.productionTip = false;
Vue.use(Meta);
Vue.use(SitecoreJssStorePlugin);
Vue.use(SitecoreJssPlaceholderPlugin, { componentFactory: componentFactory });
Vue.use(VueApollo);
Vue.use(VueSocialSharing);
// TODO: Remove gsap
Vue.prototype.$gsap = gsap;

//We can't rely on built-in Vue _uid, so we need to create our own one.
Vue.use((Vue) => {
    // Assign a unique id to each component
    let uuid = 0;
    Vue.mixin({
        props: {
            rendering: {
                type: Object,
                default: /* istanbul ignore next */ () => ({}),
            },
        },
        computed: {
            isExperienceEditor() {
                return this.$jss.sitecoreContext().pageEditing;
            },
            anchorId() {
                return this.rendering?.params?.anchorId;
            },
            vectorBasedBackground() {
                return this.$jss.sitecoreContext().pageTheme?.toLowerCase() === 'financialfairness'
                    ? 'background-light-primary-blue'
                    : 'background-digital-light-grey';
            },
        },
        beforeCreate: function () {
            //SHOULD NOT BE USED
            //TODO: Fix or remove this uuid
            this.uuid = uuid.toString();
            uuid += 1;
        },
        methods: {
            isValueExists(field) {
                return this.isExperienceEditor || field?.value?.text || field?.value?.href;
            },
        },
    });

    // Generate a component-scoped id
    Vue.prototype.$componentId = function () {
        return this.uuid;
    };
});

Vue.use(VuePortal, {
    name: 'portal', // optional, use to rename component
});

// createApp is invoked by both the main and SSR entry points, so the two entry points can use the same app creation process.
export function createApp(initialState, i18n, cdnHost) {
    Vue.config.productionTip = false;
    Vue.config.warnHandler = function (msg, instance, trace) {
        // Ignore specific warnings
        if (msg.includes("Duplicate keys detected: 'available-in-connected-mode'. This may cause an update error.")) {
            return;
        }
        console.error('[Vue warn]: ' + msg + trace);
    };

    const router = createRouter();
    const graphQLProvider = createGraphQLProvider(initialState);

    const vueOptions = {
        data: {
            headerHeight: null,
            windowFreezer: {
                scrollPosition: 0,
                isFrozen: false,
            },
            breakPoints: {
                xs: undefined,
                sm: undefined,
                md: undefined,
                lg: undefined,
                xl: undefined,
                current: undefined,
            },
            sitecoreApiKey: undefined,
            cdnHost: cdnHost,
        },
        apolloProvider: graphQLProvider,
        router,
        store,
        render: (createElement) => createElement(AppRoot),
    };
    // conditionally add i18n to the Vue instance if it is defined
    if (i18n) {
        vueOptions.i18n = i18n;
    }

    const app = new Vue(vueOptions);

    // if there is an initial state defined, push it into the store, where it can be referenced by interested components.
    if (initialState) {
        app.$jss.store.setSitecoreData(initialState);
    }
    return { app, router, graphQLProvider };
}

export function createGraphQLProvider(initialState) {
    const client =
        initialState && initialState.APOLLO_STATE
            ? GraphQLClientFactory(
                  config.graphQLEndpoint.replace('{token}', getApiKey()),
                  false,
                  initialState.APOLLO_STATE,
              )
            : GraphQLClientFactory(config.graphQLEndpoint.replace('{token}', getApiKey()), true);

    const provider = new VueApollo({
        defaultClient: client,
    });

    return provider;
}
