<script>
import * as Plot from '@observablehq/plot';
import { mapState } from 'vuex';

import ChartMixin from '../../Chart.mixin.vue';

export default {
    name: 'FundDetailsPremiumDiscountBarChart',

    mixins: [ChartMixin],

    computed: {
        ...mapState('fundDetails', {
            data: (state) => state.premiumBarChartData.data?.premiumDiscountChart,
            isLoading: (state) => state.premiumBarChartData.loading,
        }),

        chart() {
            if (!this.data) return;

            const startValues = this.data.map((d) => d.premiumDiscountStartValueWithoutLocalization);

            const minValue = Math.min(...startValues);
            const maxValue = Math.max(...startValues) + 0.5;

            return Plot.plot({
                x: {
                    tickFormat: (d) => (d === 0 ? this.$t('fundDetailsPremiumDiscountNav') : `${d}%`),
                    domain: [Math.min(minValue, -3), Math.max(maxValue, 3)],
                    tickSpacing: 60,
                },
                y: {
                    label: this.$t('days'),
                    grid: true,
                    nice: true,
                },
                marginTop: 40,
                width: this.chartWidth,
                height: this.chartHeight,
                marks: [
                    Plot.rectY(this.data, {
                        x1: 'premiumDiscountStartValueWithoutLocalization',
                        x2: 'premiumDiscountEndValueWithoutLocalization',
                        y: 'numberOfDays',
                        fill: (d) => (d.premiumDiscountEndValueWithoutLocalization > 0 ? '#00205B' : '#BBBCBC'),
                        insetLeft: 1,
                        insetRight: 1,
                    }),

                    Plot.ruleX([0], {
                        strokeWidth: 2,
                        strokeDasharray: '8 4',
                    }),

                    Plot.ruleY([0]),

                    Plot.tip(
                        this.data,
                        Plot.pointerX({
                            x1: 'premiumDiscountStartValueWithoutLocalization',
                            x2: 'premiumDiscountEndValueWithoutLocalization',
                            y: 'numberOfDays',
                            filter: (d) => d.numberOfDays > 0,
                            title: (d) =>
                                `${this.$t('days')}: ${d.numberOfDays}\n${this.$t(
                                    'fundDetailsPremiumDiscountBarChartTooltip',
                                )
                                    .replace('{token.startValue}', d.premiumDiscountStartValue)
                                    .replace('{token.endValue}', d.premiumDiscountEndValue)}`,
                            strokeWidth: 0,
                            textPadding: 12,
                            fontSize: 12,
                            lineHeight: 1.5,
                            maxRadius: 999,
                        }),
                    ),
                ],
            });
        },
    },
};
</script>
