<template>
    <div v-if="isHeaderPresent" class="skip-to-main-content">
        <div class="container">
            <a href="#main-content" class="text-link">{{ $t('skipToMainContent') }}</a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SkipToMainContent',
    computed: {
        isHeaderPresent() {
            return !!this.$jss.routeData()?.placeholders['abrdn-header'].length;
        },
    },
};
</script>

<style lang="scss" scoped>
.skip-to-main-content {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    padding: var(--spacer) 0;
    background: #eeb500;
    opacity: 0;

    &:focus-within {
        z-index: 9999;
        opacity: 1;
    }
}
</style>
