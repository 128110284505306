<template>
    <div class="d-flex flex-column gap-4">
        <h4>{{ $t('fundDetailsTabsPerformanceCumulative') }}</h4>

        <AlertMessageBase v-if="error" type="error" :title="$t(error)" />

        <template v-else-if="isLoading || chartData">
            <div v-if="renderDateFilterPresetSelector" class="row">
                <div class="col-12 col-lg-4">
                    <div class="d-flex flex-column gap-1">
                        <div class="form-label text-nowrap">{{ $t('showResultsFrom') }}</div>
                        <AbrdnSelect
                            :key="`${selectedDateFilterPreset}${dateFilterPresetOptions.disabled.value}`"
                            :fields="dateFilterPresetOptions"
                            class="dropdown"
                            @updateValue="
                                selectedDateFilterPreset = isNaN($event.value) ? $event.value : Number($event.value)
                            "
                        />
                    </div>
                </div>

                <div v-if="showingResultsFromTo && selectedDateFilterPreset !== 'custom'" class="mt-2">
                    {{ showingResultsFromTo }}
                </div>
            </div>

            <div v-if="selectedDateFilterPreset === 'custom'">
                <div class="mb-2 body-default">{{ $t('fundDetailsPerformanceChartHelp') }}</div>

                <div class="d-flex flex-column flex-lg-row align-items-lg-center gap-4">
                    <div class="d-flex flex-column flex-lg-row align-items-lg-center gap-1 gap-lg-2">
                        <div class="form-label text-nowrap">{{ $t('dateFrom') }}</div>

                        <div class="d-flex align-items-center gap-1">
                            <AbrdnSelect
                                :key="selectedMinYear"
                                :fields="minYearOptions"
                                class="dropdown dropdown--year"
                                @updateValue="selectedMinYear = Number($event.value)"
                            />
                            <AbrdnSelect
                                :key="selectedMinMonth"
                                :fields="minMonthOptions"
                                class="dropdown dropdown--month"
                                @updateValue="selectedMinMonth = Number($event.value)"
                            />
                        </div>
                    </div>

                    <div class="d-flex flex-column flex-lg-row align-items-lg-center gap-1 gap-lg-2">
                        <div class="form-label text-nowrap">{{ $t('dateTo') }}</div>

                        <div class="d-flex align-items-center gap-1">
                            <AbrdnSelect
                                :key="selectedMaxYear"
                                :fields="maxYearOptions"
                                class="dropdown dropdown--year"
                                @updateValue="selectedMaxYear = Number($event.value)"
                            />
                            <AbrdnSelect
                                :key="selectedMaxMonth"
                                :fields="maxMonthOptions"
                                class="dropdown dropdown--month"
                                @updateValue="selectedMaxMonth = Number($event.value)"
                            />
                        </div>
                    </div>

                    <AbrdnButton
                        buttonType="primary"
                        :disabled="isLoading"
                        class="align-self-start justify-content-center"
                        @click.native="fetchFilteredData"
                    >
                        <span>{{ $t('filterResults') }}</span>
                    </AbrdnButton>
                </div>
            </div>

            <FundDetailsPerformanceTabCumulativeChart :selectedCategories="selectedCategories" />

            <div v-if="chartData && chartData.categories.length > 1">
                <div class="body-default">{{ $t('fundDetailsPerformanceChartKey') }}:</div>

                <div class="mt-2 d-flex flex-column flex-lg-row gap-2 gap-lg-4 flex-wrap">
                    <div v-for="(category, index) in chartData.categories" :key="category.label">
                        <Checkbox
                            :selected="selectedCategories[category.label]"
                            :label="category.label"
                            :customColor="colorCodes[index]"
                            @onChange="selectedCategories[category.label] = !selectedCategories[category.label]"
                        />
                    </div>
                </div>
            </div>

            <div v-if="benchmarks.length">
                <div
                    v-for="([key, value], index) in benchmarks"
                    :key="key"
                    class="footnote text-grey"
                    :class="index > 0 && 'mt-1'"
                >
                    {{ $t(benchmarkTranslationKeys[key]) }}: {{ value }}
                </div>
            </div>
        </template>

        <div v-else class="body-default">{{ $t('noResultsMessage') }}</div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import AlertMessageBase from '@/components/Generic/AlertMessageBase';
import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton';
import Checkbox from '@/components/Generic/Checkbox';
import AbrdnSelect from '@/components/Generic/FormElements/FormFields/AbrdnSelect';
import { UTCFormatShort } from '@/constants/dateFormats';
import { localizeDate } from '@/mixins/Helpers.mixin';

import { benchmarkTranslationKeys, colorCodes, monthTranslationKeys } from './config';
import FundDetailsPerformanceTabCumulativeChart from './FundDetailsPerformanceTabCumulativeChart';

export default {
    name: 'FundDetailsPerformanceTabCumulativeSection',

    components: {
        AbrdnButton,
        AbrdnSelect,
        Checkbox,
        FundDetailsPerformanceTabCumulativeChart,
        AlertMessageBase,
    },

    mixins: [localizeDate],

    data() {
        return {
            benchmarks: [],
            benchmarkTranslationKeys,
            colorCodes,
            dateFilterPresets: [],
            fetchChartDataPayload: null,
            monthTranslationKeys,
            selectedCategories: {},
            selectedDateFilterPreset: null,
            selectedMaxMonth: null,
            selectedMaxYear: null,
            selectedMinMonth: null,
            selectedMinYear: null,
            years: [],
        };
    },

    computed: {
        ...mapState('fundDetails', {
            chartData: (state) => state.cumulativeChartData.data,
            isLoading: (state) => state.cumulativeChartData.loading || state.filteredCumulativeChartData.loading,
            error: (state) => state.cumulativeChartData.error || state.filteredCumulativeChartData.error,
            correlationFundRangeID: 'correlationFundRangeID',
            fundId: 'fundId',
        }),

        ...mapGetters('fundDetails', ['selectedShareClass']),

        minMonths() {
            const uniqueValues = new Set(this.getMonthsByYear(this.selectedMinYear));
            return Array.from(uniqueValues);
        },

        maxMonths() {
            const uniqueValues = new Set(this.getMonthsByYear(this.selectedMaxYear));
            return Array.from(uniqueValues);
        },

        availableDateFilterPresets() {
            const customPreset = { months: 'custom', translationKey: 'custom' };
            if (!this.chartData) return [customPreset];
            const { values } = this.chartData.categories[0];
            const firstDate = new Date(values[0].date);
            firstDate.setDate(1);

            return [
                ...this.dateFilterPresets.filter((preset) => {
                    const presetDate = new Date(values[values.length - 1].date);
                    presetDate.setMonth(presetDate.getMonth() - preset.months + 1);
                    presetDate.setDate(1);
                    return firstDate <= presetDate;
                }),
                customPreset,
            ];
        },

        dateFilterPresetOptions() {
            return this.getAbrdnSelectFields(
                this.availableDateFilterPresets.map((preset) => ({
                    value: preset.months,
                    label: this.$t(preset.translationKey).replace(
                        '{token.value}',
                        preset.months >= 12 ? preset.months / 12 : preset.months,
                    ),
                })),
                this.selectedDateFilterPreset,
                this.isLoading,
            );
        },

        minYearOptions() {
            return this.getAbrdnSelectFields(
                this.years.map((year) => ({ value: year, label: year })),
                this.selectedMinYear,
            );
        },

        minMonthOptions() {
            return this.getAbrdnSelectFields(
                this.minMonths.map((month) => ({ value: month, label: this.$t(this.monthTranslationKeys[month]) })),
                this.selectedMinMonth,
            );
        },

        maxYearOptions() {
            return this.getAbrdnSelectFields(
                this.years.map((year) => ({ value: year, label: year })),
                this.selectedMaxYear,
            );
        },

        maxMonthOptions() {
            return this.getAbrdnSelectFields(
                this.maxMonths.map((month) => ({ value: month, label: this.$t(this.monthTranslationKeys[month]) })),
                this.selectedMaxMonth,
            );
        },

        renderDateFilterPresetSelector() {
            return this.dateFilterPresetOptions.options.value.length > 1;
        },

        showingResultsFromTo() {
            if (
                !this.chartData ||
                !this.selectedMinYear ||
                !this.selectedMinMonth ||
                !this.selectedMaxYear ||
                !this.selectedMaxMonth
            ) {
                return;
            }

            const { values } = this.chartData.categories[0];

            const mappedValues = values.map((value) => {
                const date = new Date(value.date);
                const year = date.getUTCFullYear();
                const month = date.getUTCMonth();
                return { year, month, date: value.date };
            });

            const minDate = mappedValues.find(({ year, month }) => {
                return year === this.selectedMinYear && month === this.selectedMinMonth;
            })?.date;

            const maxDate = mappedValues.find(({ year, month }) => {
                return year === this.selectedMaxYear && month === this.selectedMaxMonth;
            })?.date;

            return this.$t('showingResultsFromTo')
                .replace('{token.dateFrom}', this.localizeDate(new Date(minDate), UTCFormatShort))
                .replace('{token.dateTo}', this.localizeDate(new Date(maxDate), UTCFormatShort));
        },
    },

    watch: {
        minMonths(minMonths) {
            if (this.selectedDateFilterPreset === 'custom') {
                this.selectedMinMonth = minMonths[0];
            }
        },

        maxMonths(maxMonths) {
            if (this.selectedDateFilterPreset === 'custom') {
                this.selectedMaxMonth = maxMonths[maxMonths.length - 1];
            }
        },

        selectedDateFilterPreset(selectedDateFilterPreset, prevSelectedDateFilterPreset) {
            this.selectedMaxYear = this.years[this.years.length - 1];
            this.selectedMaxMonth = this.maxMonths[this.maxMonths.length - 1];

            if (selectedDateFilterPreset === 'custom') {
                this.selectedMinYear = this.years[0];
                this.selectedMinMonth = this.minMonths[0];
            } else {
                const minDate = new Date(this.selectedMaxYear, this.selectedMaxMonth - selectedDateFilterPreset + 1);
                const minYear = minDate.getUTCFullYear();
                const minMonth = minDate.getUTCMonth();
                this.selectedMinYear = this.years.includes(minYear) ? minYear : this.years[0];
                this.selectedMinMonth = this.minMonths.includes(minMonth) ? minMonth : this.minMonths[0];
            }

            const shouldSkipFiltering = selectedDateFilterPreset === 'custom' && prevSelectedDateFilterPreset === null;

            if (!shouldSkipFiltering) {
                this.fetchFilteredData();
            }
        },
    },

    async created() {
        if (this.isExperienceEditor) return;

        const {
            fundsConfiguration: {
                countryInvestors: { countryCode },
                performanceChartSettings,
            },
            displayLanguage,
            site: { name },
        } = this.$jss.sitecoreContext();

        this.dateFilterPresets = performanceChartSettings?.availableOptions?.map((months) => ({
            months: Number(months),
            translationKey: this.getTranslationKeyByMonths(Number(months)),
        }));

        const defaultOption = performanceChartSettings?.defaultOption;

        this.fetchChartDataPayload = {
            countryCode,
            language: displayLanguage,
            shareClass: this.selectedShareClass.id,
            correlationFundRangeId: this.correlationFundRangeID,
            site: name,
            fund: this.fundId,
        };

        if (!this.chartData) {
            await this.fetchChartData(this.fetchChartDataPayload);
        }

        if (this.chartData) {
            this.setTabDataData(['filteredCumulativeChartData', { ...this.chartData }]);
            this.benchmarks = Object.entries(this.chartData.benchmarks).filter(([, value]) => value);

            this.selectedCategories = this.chartData.categories.reduce(
                (selectedCategories, category) => ({
                    ...selectedCategories,
                    [category.label]: true,
                }),
                {},
            );

            // Need to order checkbox options to sync them with actual chart line. Add "legend: true" to chart "color" object to understand better
            this.chartData.categories.sort((a, b) => {
                if (a.label > b.label) {
                    return 1;
                } else {
                    return -1;
                }
            });

            this.years = Array.from(
                new Set(this.chartData.categories[0].values.map(({ date }) => new Date(date).getUTCFullYear())),
            );

            this.selectedDateFilterPreset = this.getDefaultSelectedDateFilterPreset(defaultOption);
        }
    },

    methods: {
        ...mapActions('fundDetails', {
            fetchChartData: 'fetchCumulativeChartData',
            fetchFilteredChartData: 'fetchFilteredCumulativeChartData',
        }),

        ...mapMutations('fundDetails', ['setTabDataData']),

        getMonthsByYear(year) {
            if (!this.chartData) return [];
            return this.chartData.categories[0].values
                .filter(({ date }) => new Date(date).getUTCFullYear() === year)
                .map(({ date }) => new Date(date).getUTCMonth());
        },

        fetchFilteredData() {
            const fromDate = new Date(Date.UTC(this.selectedMinYear, this.selectedMinMonth, 1))
                .toISOString()
                .split('T')[0];

            const toDate = new Date(Date.UTC(this.selectedMaxYear, this.selectedMaxMonth + 1, 0))
                .toISOString()
                .split('T')[0];

            this.fetchFilteredChartData({ ...this.fetchChartDataPayload, fromDate, toDate });
        },

        getAbrdnSelectFields(options, selectedValue, disabled) {
            return {
                options: {
                    value: options.map(({ value, label }) => ({
                        name: value,
                        value: value,
                        displayName: label,
                        selected: value === selectedValue,
                    })),
                },
                disabled: {
                    value: disabled,
                },
            };
        },

        getDefaultSelectedDateFilterPreset(defaultOption) {
            let defaultSelectedDateFilterPreset;

            if (!isNaN(defaultOption)) {
                defaultSelectedDateFilterPreset = Number(defaultOption);
            }

            const preset = this.availableDateFilterPresets.find(
                (preset) => preset.months === defaultSelectedDateFilterPreset,
            );

            return preset?.months || 'custom';
        },

        getTranslationKeyByMonths(months) {
            let translationKey;

            if (months === 1) {
                translationKey = 'monthNumber';
            } else if (months < 12) {
                translationKey = 'monthsNumber';
            } else if (months === 12) {
                translationKey = 'yearNumber';
            } else {
                translationKey = 'yearsNumber';
            }

            return translationKey;
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';

.dropdown {
    margin-top: 0;
}

.dropdown--year {
    width: 33.333%;

    @include media-breakpoint-up(lg) {
        width: calc(var(--spacer) * 12);
    }
}

.dropdown--month {
    width: 66.666%;

    @include media-breakpoint-up(lg) {
        width: calc(var(--spacer) * 24);
    }
}
</style>
