function getArticleDataCommon(fields) {
    return {
        image: fields?.articleImage,
        contentGroup: fields?.contentGroup?.fields?.name?.value,
        title: fields?.articleTitle,
        strapline: fields?.articleStrapline,
        authors: fields?.articleAuthor?.map?.((author) => ({
            image: {
                src: author?.fields?.jobImage?.value?.src,
                alt: author?.fields?.jobImage?.value?.alt,
            },
            name: author?.fields?.name?.value,
            jobTitle: author?.fields?.jobTitle?.value,
        })),
        contributors: fields?.articleContributor
            ?.map?.((contributor) => contributor?.fields?.name?.value)
            ?.filter?.((contributor) => contributor)
            ?.join?.(', '),
    };
}

export function getArticleDataForTrustArticleHero(fields) {
    return {
        ...getArticleDataCommon(fields),
        category: fields?.articleCategory?.fields?.name?.value,
    };
}

export function getArticleDataForInvestmentsHero(fields) {
    return {
        ...getArticleDataCommon(fields),
        category: fields?.articleTags?.[0]?.fields?.name?.value,
    };
}
