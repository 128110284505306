<template>
    <div
        :id="anchorId"
        :data-e2e-component-uid="rendering.uid"
        :style="{ width: '100%' }"
        :class="{ 'experience-editor-holder': isExperienceEditor }"
    >
        <iframe ref="abrdn-iframe" aria-label="iframe" title="Iframe" :scrolling="shouldScroll" :src="src" />
    </div>
</template>

<script>
import iframeResize from 'iframe-resizer/js/iframeResizer.min';

export default {
    name: 'ParentIframe',
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        src: {
            type: String,
        },
    },
    data() {
        return {
            scrollExeptions: [/lt.morningstar/],
            iframeHeight: 0,
        };
    },
    computed: {
        shouldScroll() {
            return this.scrollExeptions.some((url) => url.test(this.fields.source.value)) ? 'yes' : 'no';
        },
    },
    mounted() {
        if (this.shouldScroll === 'no') {
            this.$nextTick(() => {
                iframeResize(
                    {
                        warningTimeout: 0,
                        checkOrigin: false,
                        waitForLoad: true,
                    },
                    this.$refs['abrdn-iframe'],
                );
            });
        }
    },
};
</script>

<style scoped lang="scss">
iframe {
    min-height: 650px;
    width: 1px;
    min-width: 100%;
}
.experience-editor-holder iframe {
    pointer-events: none;
}
</style>
