<template>
    <section
        v-if="!isExperienceEditor"
        :data-e2e-component-uid="rendering.uid"
        class="default-section-padding background-primary text-white"
    >
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <span class="h5 me-2">{{ $t('help') }}</span>
                    <Badge v-if="!!helpTagText">{{ helpTagText }}</Badge>
                    <h1 v-if="!!title" class="mt-2">{{ title }}</h1>
                    <p v-if="!!strapline" class="body-large mt-2">{{ strapline }}</p>
                </div>
            </div>
        </div>
    </section>
    <EEPlaceholder v-else>Help Article Banner</EEPlaceholder>
</template>

<script>
import Badge from '@/components/Includes/Badge';
import EEPlaceholder from '@/components/Sections/EEPlaceholder';

export default {
    name: 'HelpArticleHero',
    components: {
        EEPlaceholder,
        Badge,
    },
    props: {
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        title() {
            return this.$jss.routeData().fields?.helpTitle?.value;
        },
        strapline() {
            return this.$jss.routeData().fields?.helpStrapline?.value;
        },
        helpTagText() {
            return this.$jss.routeData().fields?.helpTag?.fields?.name?.value;
        },
    },
};
</script>

<style scoped lang="scss"></style>
