import axios from '@/api/axios';
import { getApiHost } from '@/Service.api';

const apiHost = process.env.NODE_ENV === 'development' ? 'https://tst-jss.abrdn.com' : getApiHost();

export function getTableData(data) {
    const { skip, take, searchQuery, filters = [] } = data;
    const payload = {
        skip,
        take,
        searchQuery,
        filters,
    };

    return axios.post(`${apiHost}/api/library/adviser`, payload);
}

export function getDocumentListing(data) {
    const { editionId } = data;
    const payload = { editionId };
    return axios.post(`${apiHost}/api/library/documentListing`, payload);
}
