<template>
    <div :id="anchorId" class="container d-flex justify-content-center mb-8">
        <div class="col-11">
            <AlertMessageBase
                :type="alertType"
                :title="fields.alertTitle.value"
                :description="fields.alertText"
                :expandableDescription="fields.alertExpandableText"
                :defaultExpanded="fields.expandedView.value"
            />
        </div>
    </div>
</template>

<script>
import AlertMessageBase from '@/components/Generic/AlertMessageBase';

export default {
    name: 'AlertMessage',
    components: {
        AlertMessageBase,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        alertType() {
            return this.rendering.params.alertType?.toLowerCase();
        },
    },
};
</script>
