<template>
    <div>
        <div v-if="isLoading">
            <BaseSkeleton height="120px" />
        </div>

        <template v-else-if="tableData">
            <div class="abrdn-table" :class="{ scrollable: deviceType === 'mobile' }">
                <table class="minimal" tabindex="0" :aria-label="$t('fundDetailsPremiumDiscountHistoricalTitle')">
                    <thead hidden>
                        <tr>
                            <th scope="col">Premium and Discount historical data column name</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="tr in tableData" :key="tr[0]">
                            <td v-for="td in tr" :key="td">
                                {{ td || $t('notAvailableShortMessage') }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </template>

        <div v-else class="body-default">{{ $t('noResultsMessage') }}</div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import BaseSkeleton from '@/components/Generic/BaseSkeleton';
import { standardFormat } from '@/constants/dateFormats';
import DeviceType from '@/mixins/DeviceType.mixin';
import { localizeDate } from '@/mixins/Helpers.mixin';
import { formatNumber } from '@/mixins/Helpers.mixin';

export default {
    name: 'FundDetailsPremiumDiscountBarChartHistoricalDataTable',

    components: {
        BaseSkeleton,
    },

    mixins: [localizeDate, DeviceType, formatNumber],

    computed: {
        ...mapState('fundDetails', {
            data: (state) => state.premiumBarChartData.data?.priceAggregates,
            isLoading: (state) => state.premiumBarChartData.loading,
        }),

        tableData() {
            if (!this.data) return;

            const {
                dateOfGreatestPremium,
                dateOfGreatestDiscount,
                greatestPremium,
                greatestDiscount,
                numberOfPremiumDays,
                navNumberOfDays,
                numberOfDiscountDays,
                totalNumberOfDays,
                numberOfDaysBetween,
                percentageOfDaysBetween,
            } = this.data;

            const greatestPremiumTitle = `${this.$t('fundDetailsPremiumDiscountGreatestPremium')} - ${this.localizeDate(
                dateOfGreatestPremium,
                standardFormat,
            )}`;

            const greatestDiscountTitle = `${this.$t(
                'fundDetailsPremiumDiscountGreatestDiscount',
            )} - ${this.localizeDate(dateOfGreatestDiscount, standardFormat)}`;

            return [
                [greatestPremiumTitle, this.formatNumber(greatestPremium)],
                [greatestDiscountTitle, this.formatNumber(greatestDiscount)],
                [this.$t('fundDetailsPremiumDiscountChartPremium'), numberOfPremiumDays],
                [this.$t('fundDetailsPremiumDiscountNav'), navNumberOfDays],
                [this.$t('fundDetailsPremiumDiscountDiscount'), numberOfDiscountDays],
                [this.$t('total'), totalNumberOfDays],
                [this.$t('fundDetailsPremiumDiscountNumOfDaysBetween'), numberOfDaysBetween],
                [this.$t('fundDetailsPremiumDiscountPercOfDaysbetween'), this.formatNumber(percentageOfDaysBetween)],
            ];
        },
    },
};
</script>
