<template>
    <div>
        <h3>{{ $t('fundDetailsPremiumDiscountChartDataTitle') }}</h3>

        <AlertMessageBase v-if="error" type="error" :title="$t(error)" class="mt-4" />

        <template v-else>
            <div class="mt-4">
                <h4>{{ $t('fundDetailsPremiumDiscountChartTitle') }}</h4>

                <div v-if="isLoading || periodSelectorData" class="mt-4 row">
                    <div class="col-12 col-lg-4">
                        <div class="d-flex flex-column gap-1">
                            <template v-if="!periodSelectorData && isLoading">
                                <BaseSkeleton width="85px" height="16px" />
                                <BaseSkeleton height="48px" />
                            </template>

                            <template v-if="periodSelectorData">
                                <div class="form-label text-nowrap">{{ $t('selectPeriod') }}</div>
                                <AbrdnSelect
                                    :key="periodSelectorData.disabled.value"
                                    :fields="periodSelectorData"
                                    class="dropdown"
                                    @updateValue="onPeriodSelectorChange($event)"
                                />
                            </template>
                        </div>
                    </div>
                </div>

                <BarChart class="mt-4" />
            </div>

            <div class="mt-8">
                <h4>{{ $t('fundDetailsPremiumDiscountCurrentTitle') }}</h4>
                <FundDetailsPremiumDiscountBarChartCurrentDataTable class="mt-4" />
            </div>

            <div class="mt-8">
                <h4>{{ $t('fundDetailsPremiumDiscountHistoricalTitle') }}</h4>
                <FundDetailsPremiumDiscountBarChartHistoricalDataTable class="mt-4" />
            </div>
        </template>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import AlertMessageBase from '@/components/Generic/AlertMessageBase';
import BaseSkeleton from '@/components/Generic/BaseSkeleton';
import AbrdnSelect from '@/components/Generic/FormElements/FormFields/AbrdnSelect';

import FundDetailsPremiumDiscountBarChart from './FundDetailsPremiumDiscountBarChart';
import FundDetailsPremiumDiscountBarChartCurrentDataTable from './FundDetailsPremiumDiscountBarChartCurrentDataTable';
import FundDetailsPremiumDiscountBarChartHistoricalDataTable from './FundDetailsPremiumDiscountBarChartHistoricalDataTable';

export default {
    name: 'FundDetailsPremiumDiscountBarChartSection',

    components: {
        AbrdnSelect,
        BaseSkeleton,
        BarChart: FundDetailsPremiumDiscountBarChart,
        FundDetailsPremiumDiscountBarChartCurrentDataTable,
        FundDetailsPremiumDiscountBarChartHistoricalDataTable,
        AlertMessageBase,
    },

    data() {
        return {
            selectedPeriod: null,
        };
    },

    computed: {
        ...mapState('fundDetails', {
            data: (state) => state.premiumBarChartData.data,
            isLoading: (state) => state.premiumBarChartData.loading,
            error: (state) => state.premiumBarChartData.error,
        }),

        ...mapGetters('fundDetails', ['selectedShareClass']),

        fetchDataPayload() {
            const {
                displayLanguage,
                site: { name },
            } = this.$jss.sitecoreContext();

            return {
                language: displayLanguage,
                site: name,
                shareClass: this.selectedShareClass.id,
            };
        },

        periodSelectorData() {
            if (!this.data) return;

            return {
                options: {
                    value: this.data.quarters.map((quarter) => ({
                        name: quarter.period,
                        value: quarter.period,
                        displayName: `${quarter.periodStartDate} - ${quarter.periodEndDate}`,
                        selected: quarter.period === this.selectedPeriod,
                    })),
                },
                disabled: {
                    value: this.isLoading,
                },
            };
        },
    },

    async created() {
        if (this.isExperienceEditor) return;

        if (!this.data) {
            await this.fetchData(this.fetchDataPayload);
        }

        if (this.data) {
            this.selectedPeriod = this.data.quarters?.[0].period;
        }
    },

    methods: {
        ...mapActions('fundDetails', {
            fetchData: 'fetchPremiumBarChartData',
        }),

        onPeriodSelectorChange(event) {
            this.selectedPeriod = event.value;
            this.fetchData({
                ...this.fetchDataPayload,
                quarter: this.selectedPeriod,
            });
        },
    },
};
</script>
