import { render, staticRenderFns } from "./PressReleaseHero.vue?vue&type=template&id=246102ea&scoped=true"
import script from "./PressReleaseHero.vue?vue&type=script&lang=js"
export * from "./PressReleaseHero.vue?vue&type=script&lang=js"
import style0 from "./PressReleaseHero.vue?vue&type=style&index=0&id=246102ea&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "246102ea",
  null
  
)

export default component.exports