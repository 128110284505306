<template>
    <section :id="anchorId" :data-e2e-component-uid="rendering.uid" class="page-level-section background-white">
        <div class="container">
            <Placeholder class="mb-4" :rendering="rendering" name="abrdn-tiles-heading" />
        </div>
        <Tabs class="page-level-tabs-overflow" :activeTab="activeTab" :fields="fields" @onTabChange="toggleTab" />
        <div v-for="(item, index) in fields.items" :key="item.id">
            <AbrdnPlaceholder
                v-show="activeTab === index"
                :rendering="rendering"
                :name="`abrdn-page-level-tab-${index}`"
            />
        </div>
    </section>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';

import Tabs from '@/components/Functional/Tabs';
import AbrdnPlaceholder from '@/components/Generic/SitecoreWrappers/AbrdnPlaceholder.vue';

export default {
    name: 'PageLevelTabs',
    components: { AbrdnPlaceholder, Tabs, Placeholder },
    props: {
        fields: {
            type: Object,
            default: () => ({}),
        },
        rendering: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            activeTab: null,
        };
    },
    methods: {
        toggleTab(tab) {
            this.activeTab = tab;
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep .team-listing-card {
    padding-bottom: calc(var(--spacer) * 4);
    padding-top: calc(var(--spacer) * 4);
}

::v-deep .team-listing-cards-wrapper {
    padding-bottom: calc(var(--spacer) * 3);
    padding-top: calc(var(--spacer) * 3);
}
.page-level-tabs-overflow {
    overflow: hidden;
}
</style>
