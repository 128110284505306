<template>
    <FormElemWrapper
        class="form-element--dropdown"
        :class="{ ['form-element--filled']: isFilled }"
        :isError="isError"
        wrapperTag="div"
    >
        <template v-if="getLabel" #fieldLabelSlot>{{ getLabel }} </template>
        <template v-if="getTooltip" #tooltipSlot> {{ getTooltip }} </template>
        <template #fieldSlot>
            <span class="dropdown-box">
                <select
                    ref="selectItem"
                    :name="name"
                    :aria-label="ariaLabel"
                    class="field-text"
                    autocomplete="off"
                    :required="required"
                    :disabled="disabled"
                    @change="changeHandler($event)"
                    @blur="checkError"
                    @invalid="checkError"
                    @focus="$emit('onFocus', $event)"
                >
                    <option
                        v-if="placeholder"
                        data-placeholder="true"
                        value=""
                        class="placeholder"
                        disabled
                        selected
                        hidden
                    >
                        {{ placeholder }}
                    </option>
                    <option
                        v-for="option in options"
                        :key="option.name"
                        class="field-dropdown-expanded-label"
                        :value="option.name"
                        :selected="option.selected"
                        :disabled="option.disabled ? option.disabled : false"
                    >
                        {{ option.displayName }}
                    </option>
                </select>
            </span>
        </template>
        <template #errorSlot>{{ errorMessage }}</template>
        <template v-if="getHintMessage" #hintSlot>{{ getHintMessage }}</template>
    </FormElemWrapper>
</template>

<script>
import FormElemWrapper from '@/components/Generic/FormElements/FormFields/FormElemWrapper.vue';
import FormFieldsGetters from '@/mixins/FormFieldsGetters.mixin';

/*
 * Slimselect calls windows object in the self-invoke function. It breaks build on server-side because Node doesn't have window object.
 * */
let SlimSelect = null;
if (typeof window !== 'undefined') {
    SlimSelect = require('slim-select');
}

export default {
    name: 'AbrdnSelect',
    components: { FormElemWrapper },
    mixins: [FormFieldsGetters],
    props: {
        fields: {
            type: Object,
            default: () => {},
        },
        shouldSetDefaultValue: {
            type: Boolean,
            default: () => false,
        },
    },
    data() {
        return {
            customDropdown: null,
            isFilled: false,
            isError: false,
            value: '',
        };
    },
    computed: {
        options() {
            return this.fields.options?.value;
        },
        placeholder() {
            return this.fields?.placeholder?.value;
        },
        name() {
            return this.fields?.name?.value;
        },
        required() {
            return !!this.fields?.required?.value;
        },
        disabled() {
            return this.fields?.disabled?.value;
        },
        ariaLabel() {
            return `select ${this.fields?.ariaLabel?.value}`;
        },
    },
    watch: {
        value: {
            handler: function (value) {
                this.$emit('updateValue', { value });
                this.$emit('updateDataLayer');
            },
        },
        isFilled: {
            handler(val) {
                if (val) {
                    this.isError = false;
                }
            },
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.initCustomDropdown();
            if (this.shouldSetDefaultValue) {
                this.setDefaultValue();
            }
        });
    },
    beforeDestroy() {
        if (this.customDropdown) {
            this.destroyCustomDropdown();
        }
    },
    methods: {
        initCustomDropdown() {
            if (this.$refs['selectItem']) {
                this.customDropdown = new SlimSelect({
                    select: this.$refs['selectItem'],
                    showSearch: false,
                    placeholderText: this.placeholder,
                    beforeOpen: () => {
                        this.$emit('onFocus');
                    },
                    onChange: () => {
                        // return focus to dropdown after selecting value
                        this.customDropdown.slim.container.focus();
                    },
                    afterClose: () => {
                        // hiding search input from keyboard navigation to move out of dropdown after Tab key click
                        this.customDropdown?.slim.search.input.setAttribute('tabindex', '-1');
                    },
                });
                // remove default behavior that open dropdown on focus
                this.customDropdown.slim.search.input.onfocus = null;

                this.customDropdown.slim.search.input.setAttribute('tabindex', '0');
                this.customDropdown.slim.list.setAttribute('aria-label', 'Options select');
                this.customDropdown.slim.container.setAttribute('tabindex', '-1');

                this.customDropdown.slim.container.addEventListener('keydown', (event) => {
                    if (event.key === 'Enter') {
                        this.customDropdown.open();
                    }
                    if (event.key === 'Escape') {
                        this.customDropdown.close();
                        this.customDropdown.slim.container.focus();
                    }
                });

                this.customDropdown.slim.container.addEventListener('focusout', () => {
                    // making dropdown keyboard accessible
                    this.customDropdown.slim.container.setAttribute('tabindex', '0');
                });
            }
        },
        destroyCustomDropdown() {
            this.customDropdown.destroy();
            this.customDropdown = null;
        },
        changeHandler(event) {
            /* istanbul ignore next */
            const select = event.currentTarget;
            /* istanbul ignore next */
            const selectedOption = [...select.options].find((option) => option.selected);
            /* istanbul ignore next */
            if (selectedOption && !selectedOption.hasAttribute('data-placeholder')) {
                this.value = selectedOption.value;
            }
            /* istanbul ignore next */
            this.isFilled = !!(selectedOption && !selectedOption.hasAttribute('data-placeholder'));
        },
        checkError(event) {
            switch (true) {
                case event.target.validity.typeMismatch:
                    this.errorMessage = this.fields.errorMessages?.typeMismatch;
                    this.isError = true;
                    break;
                case event.target.validity.valueMissing:
                    this.errorMessage = this.fields.errorMessages?.valueMissing;
                    this.isError = true;
                    break;
                case event.target.validity.tooLong:
                    this.errorMessage = this.fields.errorMessages?.tooLong;
                    this.isError = true;
                    break;
                case event.target.validity.tooShort:
                    this.errorMessage = this.fields.errorMessages?.tooShort;
                    this.isError = true;
                    break;
                default:
                    this.isError = false;
                    break;
            }
        },
        setDefaultValue() {
            if (this.placeholder) {
                return;
            }
            const enabledOptions = this.options.filter((option) => !option.disabled);
            if (!enabledOptions.length) {
                return;
            }

            const selectedOptions = this.options.filter((option) => option.selected);
            this.value = selectedOptions.length ? selectedOptions[0].name : enabledOptions[0].name;
            this.isFilled = true;
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixins';
@import '~slim-select/dist/slimselect.css';

.dropdown-box {
    position: relative;
}

.form-element ::v-deep .placeholder {
    color: var(--abrdn-black);
    & .ss-disabled {
        color: var(--dark-grey);
    }
    @include dark-backgrounds() {
        color: var(--abrdn-white);
        & .ss-disabled {
            color: var(--abrdn-white);
        }
    }
}
.form-element ::v-deep .ss-single-selected,
select {
    padding: 0 12px;
    height: var(--default-input-height);
    border-radius: 0;
    border: 1px solid var(--button-night);
    @include dark-backgrounds() {
        background-color: transparent;
        border-color: var(--abrdn-white);
    }
}
select {
    appearance: none;
    padding-right: 40px;
    background-color: $abrdn-white;
    color: inherit;
    @include dark-backgrounds() {
        color: $abrdn-white;
        &:focus {
            background-color: $abrdn-white;
            color: $abrdn-black;
        }
    }
}

select:focus {
    outline: 0;
    border-width: 2px;
    + .chevron {
        color: black;
        transform: translateY(-50%) rotate(-90deg);
    }
}

.form-element--filled ::v-deep .ss-single-selected,
.form-element--filled select {
    border-color: var(--abrdn-black);
    @include dark-backgrounds() {
        border-color: var(--abrdn-white);
        border-width: 2px;
    }
}

.form-element ::v-deep .ss-main .ss-single-selected .ss-arrow {
    margin: 0;
    height: 24px;
    width: 24px;
    align-items: center;
    justify-content: center;
    align-self: center;
}
.form-element ::v-deep .ss-arrow span {
    border-color: var(--abrdn-black);
    @include dark-backgrounds() {
        border-color: var(--abrdn-white);
    }
}

::v-deep .ss-content {
    border: none;
    box-shadow: 0px 16px 100px rgba(9, 9, 14, 0.12);
    margin: 0;
}

::v-deep .ss-content .ss-list {
    max-height: 240px;
    overflow: auto;
    @include formScrollbar;
}

::v-deep .ss-content .ss-list .ss-option {
    padding: 12px 16px;
    color: var(--abrdn-black);
}

::v-deep .ss-main:focus-within {
    outline: 1px solid;
}

::v-deep .ss-main:focus {
    border: none;
}

::v-deep .ss-content .ss-list .ss-option.ss-highlighted,
::v-deep .ss-content .ss-list .ss-option:hover {
    background-color: var(--button-mist);
}

.form-element ::v-deep .ss-single-selected.ss-open-below,
.form-element ::v-deep .ss-single-selected.ss-open-above {
    border-width: 2px;
    background-color: var(--abrdn-white);
    border-color: var(--abrdn-black);
    @include dark-backgrounds() {
        border-color: var(--abrdn-white);
        background-color: var(--abrdn-white);
        & .placeholder {
            color: var(--abrdn-black);
            & .ss-disabled {
                color: var(--abrdn-black);
            }
        }
        & .ss-arrow span {
            border-color: var(--abrdn-black);
        }
    }
}
::v-deep .ss-content .ss-list .ss-option.ss-disabled {
    opacity: 0.6;
}

::v-deep .ss-disabled {
    .ss-arrow span {
        border-color: var(--dark-grey);
    }
}

.form-element--error select,
.form-element--error ::v-deep .ss-single-selected:not(.ss-open-below):not(.ss-open-above) {
    border-color: var(--error-400);
    background-color: var(--error-100);
    border-width: 2px;
    color: var(--error-400);
    outline: 0;
    & .placeholder {
        color: var(--error-400);
        & .ss-disabled {
            color: var(--error-400);
        }
    }
    @include dark-backgrounds() {
        background-color: var(--error-200);
        border-color: var(--error-200);
        color: var(--error-400);
        & .ss-arrow span {
            border-color: var(--abrdn-black);
        }
        + .chevron {
            color: var(--abrdn-black);
        }
    }
}

.chevron {
    position: absolute;
    right: 20px;
    top: 50%;
    width: 12px;
    height: 12px;
    transform: translateY(-50%) rotate(90deg);
    transition: transform 0.3s;
    transform-origin: center;
    pointer-events: none;
}
option[value=''][disabled] {
    display: none;
}
</style>
