<template>
    <div :id="anchorId" class="podcast-box">
        <ScText tag="h4" class="mb-3" :field="fields.title" />
        <slot />
        <AccordionDefault
            v-if="transcript"
            :title="{ value: $t('transcript') }"
            :description="fields.transcript"
            class="w-100 mt-3 accordion-item"
        />
    </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import AccordionDefault from '@/components/Generic/AccordionDefault';

export default {
    name: 'PodcastBase',
    components: {
        AccordionDefault,
        ScText: Text,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        transcript() {
            return this.fields.transcript?.value;
        },
    },
};
</script>

<style scoped lang="scss">
.accordion-item ::v-deep .btn {
    padding-top: 20px;
    padding-bottom: 20px;
}
</style>
