<template>
    <Placeholder class="row" name="abrdn-column" :rendering="rendering" :wrapperCols="cols" />
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';

export default {
    name: 'Row',
    components: {
        Placeholder,
    },
    props: {
        rendering: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        cols() {
            return this.rendering?.params?.col || 'dynamic';
        },
    },
};
</script>

<style scoped></style>
