<template>
    <div v-if="yValues" class="y-values-groups">
        <div class="y-values-group y-values-group--positive">
            <div
                v-for="yValue in yValues.positive.value"
                :key="yValue.value"
                class="y-value body-small"
                :class="{ 'y-value--hidden': !yValue.visible }"
            >
                <span>{{ yValue.value }}</span>
            </div>
            <div class="y-value body-small y-value--zero">0</div>
        </div>
        <div v-if="yValues.negative.value.length" class="y-values-group y-values-group--negative">
            <div
                v-for="yValue in yValues.negative.value"
                :key="yValue.value"
                class="y-value body-small"
                :class="{ 'y-value--hidden': !yValue.visible }"
            >
                <span>{{ yValue.value }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'YAxisValues',
    props: {
        yValues: {
            type: [Object, Boolean],
            require: true,
            default: () => false,
        },
    },
};
</script>

<style scoped lang="scss">
.y-values-group {
    position: relative;
}
.y-value {
    width: var(--yValueWidth);
    text-align: center;
    height: var(--yValueHeight);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    line-height: 1;
}
.y-value--hidden {
    visibility: hidden;
}
.y-value span {
    height: var(--yValueFontSize);
    transform: translateY(-50%);
}
.y-values-group--positive {
    padding-top: calc(var(--yValueFontSize) / 2);
}
.y-values-group--negative {
    padding-bottom: calc(var(--yValueFontSize) / 2);
    .y-value {
        align-items: flex-end;
        span {
            transform: translateY(50%);
        }
    }
}
.y-value--zero {
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translateY(50%);
    height: auto;
}
</style>
