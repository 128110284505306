<template>
    <div :id="anchorId" class="key-highlights p-4" :data-e2e-component-uid="rendering.uid" :class="backgroundClass">
        <ScText tag="h2" class="h2 background-to-change" :field="fields.title" />
        <KeyHighlightItem
            v-for="(item, idx) in fields.items"
            :key="item.id"
            :fields="item.fields"
            :rendering="rendering"
            :idx="idx"
            class="mt-3"
        />
    </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import KeyHighlightItem from '@/components/Pages/ArticleParts/KeyHighlightItem';
import { backgroundClass } from '@/mixins/Helpers.mixin';

export default {
    name: 'KeyHighlights',
    components: {
        ScText: Text,
        KeyHighlightItem,
    },
    mixins: [backgroundClass],
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
};
</script>

<style scoped></style>
