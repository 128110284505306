<template>
    <section :id="anchorId" :data-e2e-component-uid="rendering.uid" class="w-100 wrapper py-3">
        <div class="container d-flex flex-column flex-md-row justify-content-between">
            <div class="left-side d-flex flex-column flex-md-row justify-content-between justify-content-md-start">
                <div v-if="articleSeriesUrl" class="d-flex align-items-center body-small me-5 mb-2 mb-md-0">
                    <p>{{ $t('partOfTheSeries').replace('{token.seriesNumber}', `${articleSeriesPart}`) }}&nbsp;</p>
                    <p>{{ articleSeriesName }}</p>
                </div>
                <div class="d-flex">
                    <p v-if="articleDate" class="d-flex align-items-center body-small me-5">
                        {{ $t('duration') }}: {{ `${fields.duration.value.duration || 0} ${computeMinutes}` }}
                    </p>
                    <p v-if="articleDate" class="d-flex align-items-center body-small">
                        {{ $t('date') }}: {{ `${localizeDate(articleDate)}` }}
                    </p>
                </div>
            </div>

            <div
                v-if="articleDate"
                class="right-side d-flex justify-content-between justify-content-md-start mt-2 mt-md-0"
            >
                <!--                <a href="#" class="d-flex align-items-center text-link print me-md-4">Print</a>-->
                <!--                <a href="#" class="d-flex align-items-center text-link download me-md-4">Download</a>-->
                <button
                    type="button"
                    :aria-label="$t('openModal')"
                    class="d-flex align-items-center text-link share btn"
                    @click="toggleModal()"
                >
                    {{ $t('share') }}
                    <IconShare class="ms-1" />
                </button>
                <Modal :isModalOpen="showShareModal" :aria-label="$t('shareThisArticle')" @closeModal="toggleModal()">
                    <ShareModalContent @closeModal="toggleModal()" />
                </Modal>
            </div>
        </div>
    </section>
</template>

<script>
import IconShare from '@/components/Icons/icon-share.vue';
import Modal from '@/components/Modals/Modal.vue';
import ShareModalContent from '@/components/Modals/ShareModalContent.vue';
import { localizeDate } from '@/mixins/Helpers.mixin';

export default {
    name: 'ArticleActionBar',
    components: { IconShare, ShareModalContent, Modal },
    mixins: [localizeDate],
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    data() {
        return {
            articleDate: null,
            articleSeriesName: null,
            articleSeriesUrl: null,
            articleSeriesPart: null,
            month: null,
            day: null,
            year: null,
            showShareModal: false,
        };
    },
    computed: {
        computeMinutes() {
            if (this.fields.duration.value.duration > 1) {
                return this.$t('mins');
            } else {
                return this.$t('min');
            }
        },
        getSeriesLink() {
            return {
                value: {
                    text: this.articleSeriesName,
                    href: this.articleSeriesUrl,
                },
            };
        },
    },
    mounted() {
        this.articleDate = this.$jss.routeData().fields.articlePublicationDate.value;
        this.articleSeriesName = this.$jss.routeData().fields.articleSeries?.fields?.name?.value;
        this.articleSeriesUrl = this.$jss.routeData().fields.articleSeries?.url;
        this.articleSeriesPart = this.$jss.routeData().fields.articleSeriesPart.value;
        const newDate = new Date(this.articleDate);
        this.month = newDate.toLocaleString('en-us', { month: 'short' }).toUpperCase();
        this.day = newDate.getDay();
        this.year = newDate.getFullYear();
    },
    methods: {
        toggleModal() {
            this.showShareModal = !this.showShareModal;
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';

.wrapper {
    background: var(--digital-light-grey);
    display: flex;
    align-items: center;
    .right-side {
        a:after {
            display: block;
            margin: 7px 0 0 7px;
        }
        .print {
            &:after {
                content: url('data:image/svg+xml;utf8, <svg width="24" height="26" viewBox="0 0 24 26" fill="none" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"> <path d="M19 8.75C20.66 8.75 22 10.1738 22 11.9375V18.3125H18V22.5625H6V18.3125H2V11.9375C2 10.1738 3.34 8.75 5 8.75H6V3.4375H18V8.75H19ZM8 5.5625V8.75H16V5.5625H8ZM16 20.4375V16.1875H8V20.4375H16ZM18 16.1875H20V11.9375C20 11.3531 19.55 10.875 19 10.875H5C4.45 10.875 4 11.3531 4 11.9375V16.1875H6V14.0625H18V16.1875ZM19 12.4688C19 13.0531 18.55 13.5312 18 13.5312C17.45 13.5312 17 13.0531 17 12.4688C17 11.8844 17.45 11.4062 18 11.4062C18.55 11.4062 19 11.8844 19 12.4688Z" fill="black"/> </svg>');
            }
        }
        .download {
            &:after {
                content: url('data:image/svg+xml;utf8, <svg width="24" height="26" viewBox="0 0 24 26" fill="none" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path d="M19.5 20.9688H4.5C4.05 20.9688 3.75 20.65 3.75 20.1719V13C3.75 12.5219 4.05 12.2031 4.5 12.2031C4.95 12.2031 5.25 12.5219 5.25 13V19.375H18.75V13C18.75 12.5219 19.05 12.2031 19.5 12.2031C19.95 12.2031 20.25 12.5219 20.25 13V20.1719C20.25 20.5703 19.95 20.9688 19.5 20.9688Z" fill="black"/><path d="M12.8998 13.9563V5.82812C12.8998 5.35 12.5998 5.03125 12.1498 5.03125C11.6998 5.03125 11.3998 5.35 11.3998 5.82812V13.9563L8.9248 11.3266V13.5578L12.1498 16.9844L15.3748 13.5578V11.3266L12.8998 13.9563Z" fill="black"/></svg>');
            }
        }
        .btn {
            padding: 0;
            svg {
                width: 1.25em;
                height: 1.25em;
            }
        }
    }
}
.modal-content {
    width: 300px;
    height: 300px;
    background-color: blue;
}
</style>
