<template>
    <div class="relative">
        <div
            v-if="overlay"
            class="overlay"
            @click="
                toggleDropdown();
                $emit('outsideClick');
            "
        />
        <div
            ref="dropdown"
            v-collapse-target:[speed]="`.dropdown`"
            role="button"
            aria-expanded="false"
            class="d-flex expandable collapsed"
            tabindex="0"
            @keydown.enter="
                toggleDropdown();
                $emit('enter');
            "
            @keydown.esc="
                toggleDropdown();
                $emit('outsideClick');
            "
        >
            <slot name="trigger-area" />
        </div>
        <div class="dropdown collapse absolute">
            <slot />
            <div
                class="filter-dropdown-nav d-flex align-items-center justify-content-between"
                @keydown.esc="
                    toggleDropdown();
                    $emit('outsideClick');
                "
            >
                <AbrdnButton
                    buttonType="secondary"
                    :class="{ disabled: loading }"
                    @click.native="
                        toggleDropdown();
                        !loading && $emit('cancelEvent');
                    "
                >
                    <span>{{ $t('clear') }}</span>
                </AbrdnButton>
                <AbrdnButton
                    buttonType="primary"
                    :class="{ disabled: loading }"
                    @click.native.stop="
                        toggleDropdown();
                        !loading && $emit('confirmEvent');
                    "
                >
                    <span>{{ $t('apply') }}</span>
                </AbrdnButton>
            </div>
        </div>
    </div>
</template>

<script>
import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton';
import Collapse from '@/mixins/Collapse.mixin';

export default {
    name: 'SlotDropdown',
    components: { AbrdnButton },
    mixins: [Collapse],
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        fields: {
            type: Object,
            default: () => {},
        },
        speed: {
            type: Number,
            default: 200,
        },
    },
    methods: {
        toggleDropdown() {
            this.$refs.dropdown.click();
        },
    },
};
</script>

<style scoped lang="scss">
.relative {
    position: relative;
}
.absolute {
    position: absolute;
    z-index: 999;
}
.overlay {
    background: #eeeeee10;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 99;
}

.dropdown {
    //top: calc(100% + var(--spacer));
    background: var(--abrdn-white);
    box-shadow: 0 16px 100px rgba(9, 9, 14, 0.12);
}

.filter-dropdown-nav {
    padding: calc(var(--spacer) * 2);
    width: 100%;
}
</style>
