<template>
    <section
        :id="anchorId"
        :data-e2e-component-uid="rendering.uid"
        class="container default-section-padding background-white"
    >
        <Placeholder :rendering="rendering" name="abrdn-tiles-heading" class="mb-4" />
        <div class="row">
            <TeamProfileCard
                v-for="item in teamMembers"
                :key="item.id"
                :fields="item"
                :showImage="showImage"
                class="col-12 col-md-6 mb-4 d-flex flex-column"
                :class="itemsLength > 3 ? 'col-lg-3' : 'col-lg-4'"
            />
        </div>
    </section>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';

import TeamProfileCard from '@/components/Tiles/TeamProfileCard.vue';

export default {
    name: 'TeamProfileBase',
    components: {
        Placeholder,
        TeamProfileCard,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        showImage: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        teamMembers() {
            return this.fields?.teamMembers?.value;
        },
        itemsLength() {
            return this.teamMembers?.length || 0;
        },
    },
};
</script>

<style scoped lang="scss"></style>
