<template>
    <section class="default-section-padding background-white">
        <div class="container">
            <h2>{{ $t('fundDetailsPremiumDiscountTitle') }}</h2>
            <FundDetailsPremiumDiscountLineChartSection class="mt-6" />
            <FundDetailsPremiumDiscountBarChartSection class="mt-6" />
        </div>
    </section>
</template>

<script>
import FundDetailsPremiumDiscountBarChartSection from './FundDetailsPremiumDiscountBarChartSection';
import FundDetailsPremiumDiscountLineChartSection from './FundDetailsPremiumDiscountLineChartSection';

export default {
    name: 'FundDetailsPremiumDiscountTab',

    components: {
        FundDetailsPremiumDiscountBarChartSection,
        FundDetailsPremiumDiscountLineChartSection,
    },
};
</script>
