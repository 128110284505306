<template>
    <div :id="anchorId" :data-e2e-component-uid="rendering.uid" class="pb-4">
        <div :class="{ [backgroundClass]: true, 'quoteblock-root p-4': true }">
            <ScText tag="p" class="h3 background-to-change" :field="fields.quoteTitle" />
            <ScText tag="p" class="background-to-change section-header-large mt-2" :field="fields.quoteAuthor" />
            <div class="arrow-right" :style="{ borderLeft: `41px solid var(-${color})` }" />
        </div>
    </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import { backgroundClass } from '@/mixins/Helpers.mixin';

export default {
    name: 'Quote',
    components: {
        ScText: Text,
    },
    mixins: [backgroundClass],
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        color() {
            return this.backgroundClass.replace('background', '');
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
.quoteblock-root {
    position: relative;
}
.arrow-right {
    width: 0;
    height: 0;
    border-top: 41px solid transparent;
    border-bottom: 41px solid transparent;
    position: absolute;
    bottom: -32.5px;
    left: 0px;
    z-index: 0;
}

.section-header-large {
    @include media-breakpoint-down(md) {
        margin-top: 12px !important;
    }
}
</style>
