var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"primary-hero-banner-section d-flex flex-column",attrs:{"id":_vm.anchorId,"data-e2e-component-uid":_vm.rendering.uid}},[(_vm.isAnimationVisible)?_c('Animation',{staticClass:"background-video-wrapper",attrs:{"loop":false,"animationData":_vm.desktopAnimation,"scaleSettings":_vm.scaleSettings}}):_c('div',{staticClass:"background-image-wrapper"},[_c('WrappedImage',{attrs:{"media":_vm.fields.backgroundImage,"srcSet":[
                { mw: 400 },
                { mw: 600 },
                { mw: 800 },
                { mw: 1000 },
                { mw: 1200 },
                { mw: 1400 },
                { mw: 1600 },
                { mw: 1800 },
                { mw: 2000 } ],"sizes":"100vw","lazy":false}})],1),_c('div',{staticClass:"container text-block mb-md-n7 mt-n5 mt-md-auto"},[_c('div',{staticClass:"row w-100 pe-3 pe-md-0"},[_c('div',{staticClass:"inner-wrapper background-white col-12 col-md-12 col-lg-7 pe-md-4 py-4 pb-md-6 pt-xl-7 pb-xl-8 pe-xl-7"},[_c('ScText',{attrs:{"field":_vm.fields.title,"tag":"h1"}}),(_vm.fields.subtitle && _vm.fields.subtitle.value)?_c('ScText',{staticClass:"mt-2 body-large",attrs:{"field":_vm.fields.subtitle,"tag":"p"}}):_vm._e(),(_vm.isValueExists(_vm.fields.primaryCtaLink) || _vm.isValueExists(_vm.fields.secondaryCtaLink))?_c('div',{staticClass:"links-wrapper d-flex mt-5"},[(_vm.isValueExists(_vm.fields.primaryCtaLink))?_c('AbrdnButton',{staticClass:"mt-3 me-sm-3 mt-md-0",attrs:{"buttonType":'primary',"linkField":_vm.fields.primaryCtaLink}}):_vm._e(),(_vm.isValueExists(_vm.fields.secondaryCtaLink))?_c('AbrdnButton',{staticClass:"mt-3 mt-md-0",attrs:{"buttonType":'secondary',"linkField":_vm.fields.secondaryCtaLink}}):_vm._e()],1):_vm._e()],1)])]),_c('div',{staticClass:"break-line background-white pt-4 pt-md-7 pt-xl-7"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }