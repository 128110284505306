<template>
    <div>
        <div class="listing-card h-100">
            <div class="listing-card__thumbnail flex-shrink-0">
                <WrappedImage
                    :media="fields.image"
                    :srcSet="[{ mw: 400 }, { mw: 600 }, { mw: 800 }, { mw: 1000 }]"
                    sizes="
                        (max-width: 575px) 100vw,
                        (max-width: 767px) 510px,
                        (max-width: 991px) 330px,
                        (max-width: 1199px) 25vw,
                        270px
                    "
                />
            </div>
            <div class="listing-card__body py-2 px-2 flex-grow-1" :class="vectorBasedBackground">
                <div class="d-flex align-items-start section-header-small">
                    <span class="section-header-small">{{ category }}</span>
                </div>
                <div class="listing-card__content my-2">
                    <ScText tag="h5" :field="fields.title" class="line-clamp-2" />
                    <div class="body-small text-grey mt-2">
                        <p>
                            <span>{{ $t('propertyCardDetailsLabel') }}</span>
                            {{ ' - ' }}
                            <ScText tag="span" :field="fields.details" />
                        </p>
                        <p>
                            <span>{{ ownership }}</span>
                        </p>
                        <p>
                            <span>{{ $t('propertyCardOccupationalTenanciesLabel') }}</span>
                            {{ ' - ' }}
                            <ScText tag="span" :field="fields.occupationalTenancies" />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import WrappedImage from '@/components/Generic/SitecoreWrappers/WrappedImage';

export default {
    name: 'PropertyListingsCard',
    components: {
        ScText: Text,
        WrappedImage,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        category: {
            type: String,
            default: '',
        },
    },
    computed: {
        ownership() {
            return this.fields?.ownership?.fields?.title?.value;
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
.listing-card__thumbnail {
    width: 100%;
    height: 140px;
    ::v-deep img {
        width: 100%;
        height: auto;
        object-fit: cover;
        max-height: unset;
    }
    @include media-breakpoint-down(md) {
        height: 178px;
    }
}
.listing-card {
    transition: box-shadow 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.listing-card__body {
    display: flex;
    flex-direction: column;
}
.body-small p span:first-child {
    font-weight: 700;
}
.img-placeholder {
    background-color: rgba(231, 74, 231, 1);
}
</style>
