<template>
    <div class="d-flex align-items-center flex-wrap filter-options-wrapper gap-2">
        <button
            v-for="option in approvedFilters"
            :key="option.id + 'fab'"
            class="filter-option-selected d-flex btn-naked"
            :aria-label="$t('removeFilterOption').replace('{token.option}', option.name)"
            @click="$emit('deleteOption', option)"
        >
            <span class="form-label">{{ option.name }}</span>
            <em class="d-flex bi-x-lg ms-2" aria-hidden="true" />
        </button>
        <button
            class="inline-link inline-link--small text-decoration-underline btn-naked"
            :aria-label="$t('clearAllFilters')"
            @click.stop="$emit('clearFilters')"
        >
            {{ $t('clearAll') }}
        </button>
    </div>
</template>

<script>
export default {
    name: 'SelectedFilters',
    props: {
        approvedFilters: {
            type: Array,
            default: () => [],
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/mixins';
.filter-option-selected {
    @include dark-backgrounds() {
        color: var(--abrdn-black);
    }
}
</style>
