<template>
    <a :href="document.documentURI" target="_blank" :title="document.title" class="btn-naked btn">
        <IconExternal class="icon-external" />
        <span class="sr-only">{{ $t('opensInNewWindow') }}</span>
    </a>
</template>

<script>
import IconExternal from '@/components/Icons/icon-external.vue';

export default {
    name: 'FundsExternalLink',
    components: { IconExternal },
    props: {
        document: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style scoped lang="scss">
a {
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.icon-external {
    width: 14px;
    height: 14px;
}
</style>
