<template>
    <div
        id="main-content"
        class="layout-wrapper"
        :class="[{ [pageThemeName]: pageThemeName }, lastMainSectionBackground]"
    >
        <VisitorIdentification />
        <main>
            <Placeholder ref="main" name="abrdn-main" :rendering="route" />
        </main>
        <AbrdnPlaceholder name="abrdn-footer" :rendering="route" />
        <Modal
            v-if="pageDisclaimer && !showDisclaimerModalContent"
            :isModalOpen="showDisclaimerModal"
            :closeMethods="[]"
            @closeModal="toggleDisclaimer"
        >
            <DisclaimerModalContent :fields="pageDisclaimer" @closeModal="toggleDisclaimer" />
        </Modal>
    </div>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';
import cssVars from 'css-vars-ponyfill';

import '@/assets/app.css';
import '@/assets/styles/app.scss';
import adviserTheme from '@/assets/styles/themes/adviser.js';
import financialFairnessTheme from '@/assets/styles/themes/financialfairness';
import investmentTheme from '@/assets/styles/themes/investment.js';
import masterTheme from '@/assets/styles/themes/master.js';
import personalTheme from '@/assets/styles/themes/personal.js';
import AbrdnPlaceholder from '@/components/Generic/SitecoreWrappers/AbrdnPlaceholder.vue';
import DisclaimerModalContent from '@/components/Modals/DisclaimerModalContent';
import Modal from '@/components/Modals/Modal';
import Cookie from '@/mixins/Cookie.mixin';

import { drawGtmHeads, drawGtmScripts } from '../scripts/gtm';
import VisitorIdentification from './VisitorIdentification';

export default {
    name: 'Layout',
    components: {
        DisclaimerModalContent,
        Modal,
        Placeholder,
        VisitorIdentification,
        AbrdnPlaceholder,
    },
    mixins: [Cookie],
    props: {
        route: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            themeName: null,
            personal: personalTheme,
            investment: investmentTheme,
            adviser: adviserTheme,
            master: masterTheme,
            financialfairness: financialFairnessTheme,
            lastMainSectionBackground: '',
            showDisclaimerModal: false,
        };
    },
    computed: {
        pageThemeName() {
            return this.$jss.sitecoreContext().pageTheme?.toLowerCase() || 'master';
        },
        showDisclaimerModalContent() {
            const childPageTemplateId = '6ff27d65-56ff-4533-93bb-b3f7f568d973'; // for nested iframe component we need to hide modal on this specific templateId
            const { templateId } = this.$jss.routeData();
            return templateId === childPageTemplateId;
        },
        pageDisclaimer() {
            if (typeof document === 'undefined' || this.isExperienceEditor) return false;
            const sitecoreContext = this.$jss.sitecoreContext();
            const disclaimer = sitecoreContext.disclaimer;
            if (!disclaimer) return false;
            const siteName = sitecoreContext.site.name;
            const languageVersion = sitecoreContext.language;
            if (this.disclaimerCookies) {
                const currentSiteCookie = this.disclaimerCookies.find(
                    (cookie) => cookie.n === siteName && cookie.l === languageVersion,
                );
                if (!currentSiteCookie) return disclaimer;
                //const isSessionBased = disclaimer.session.value;
                const isSessionBased = false;
                if (isSessionBased) {
                    const currentSessionId = this.getCookie('ASP.NET_SessionId');
                    return currentSessionId !== currentSiteCookie.s ? disclaimer : false;
                } else {
                    const date = new Date();
                    const currentDate = date.getTime();
                    return currentDate > (currentSiteCookie.e || 0) ? disclaimer : false;
                }
            }
            return disclaimer;
        },
        disclaimerCookies() {
            return JSON.parse(this.getCookie('abrdn-disclaimer') || null);
        },
    },
    metaInfo() {
        const {
            displayLanguage,
            metaDescription,
            metaTitle = 'abrdn',
            languageVersions,
            hostName,
            gtmConfigs,
            canonicalTag,
            oneTrustScriptID,
            oracleCDP,
            twitterCard,
            openGraph,
        } = this.$jss.sitecoreContext();
        const xhtmlLangVersions = [];
        if (languageVersions?.length >= 2 && canonicalTag?.isSelfReferencing) {
            const url = hostName.split('.com');
            xhtmlLangVersions.push({
                vmid: 'alternate-x-default',
                rel: 'alternate',
                hreflang: 'x-default',
                href: `${url[0]}/${displayLanguage.toLowerCase()}${url[1]}`,
            });
            languageVersions
                .filter((language) => language.hreflang !== displayLanguage) // current site language should be removed from the list
                .forEach((lang) => {
                    xhtmlLangVersions.push({
                        vmid: `alternate-${lang.hreflang.toLowerCase()}`,
                        rel: 'alternate',
                        hreflang: lang.hreflang.toLowerCase(),
                        href: lang.href,
                    });
                });
        }
        const tagExist = function (tag, meta) {
            return tag ? meta : {};
        };

        const canonical = tagExist(canonicalTag, { vmid: 'canonical', rel: 'canonical', href: canonicalTag?.href });
        const ogUrl = tagExist(canonicalTag, { vmid: 'og:url', property: 'og:url', content: canonicalTag?.href });
        const ogImage = tagExist(openGraph.ogImage, {
            vmid: 'og:image',
            property: 'og:image',
            content: openGraph.ogImage,
        });
        const twitterTitle = tagExist(twitterCard.twitterTitle, {
            vmid: 'twitter:title',
            name: 'twitter:title',
            content: twitterCard.twitterTitle,
        });
        const twitterDescription = tagExist(twitterCard.twitterDescription, {
            vmid: 'twitter:description',
            name: 'twitter:description',
            content: twitterCard.twitterDescription,
        });
        const twitterImage = tagExist(twitterCard.twitterImage, {
            vmid: 'twitter:image',
            name: 'twitter:image',
            content: twitterCard.twitterImage,
        });
        const twitterSiteExist = tagExist(!!twitterCard.twitterSite, {
            vmid: 'twitter:site',
            name: 'twitter:site',
            content: twitterCard.twitterSite,
        });

        const consentModeScript = {
            vmid: 'consentModeScript',
            innerHTML: `
                window.dataLayer = window.dataLayer || [];
                function gtag() {
                    dataLayer.push(arguments);
                }
                gtag('consent', 'default', {
                    ad_storage: 'denied',
                    analytics_storage: 'denied',
                    functionality_storage: 'denied',
                    personalization_storage: 'denied',
                    security_storage: 'denied',
                    ad_user_data: 'denied',
                    ad_personalization: 'denied',
                    wait_for_update: 500,
                });
            `,
            once: true,
        };

        const oneTrustScript = oneTrustScriptID
            ? [
                  {
                      vmid: 'oneTrustScriptConsent',
                      type: 'text/javascript',
                      charset: 'UTF-8',
                      src: `https://cdn-ukwest.onetrust.com/consent/${oneTrustScriptID}/OtAutoBlock.js`,
                  },
                  {
                      vmid: 'oneTrustScript',
                      type: 'text/javascript',
                      src: 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js',
                      charset: 'UTF-8',
                      ['data-domain-script']: oneTrustScriptID,
                  },
              ]
            : [{}];
        const oracleCDPScript = this.isExperienceEditor
            ? {}
            : tagExist(oracleCDP, {
                  vmid: 'oracleCDP',
                  type: 'text/javascript',
                  charset: 'UTF-8',
                  async: true,
                  defer: true,
                  src: `https://tags.bluekai.com/site/${oracleCDP}?ret=js`,
              });
        let scripts = [oracleCDPScript];
        if (typeof document === 'undefined') {
            scripts = [consentModeScript, ...drawGtmScripts(gtmConfigs), ...oneTrustScript];
        }

        return {
            title: metaTitle,
            htmlAttrs: { lang: displayLanguage },
            meta: [
                { vmid: 'description', name: 'description', content: metaDescription },
                { vmid: 'twitter:card', name: 'twitter:card', content: twitterCard.twitterCard },
                twitterSiteExist,
                twitterTitle,
                twitterDescription,
                twitterImage,
                { vmid: 'og:title', property: 'og:title', content: openGraph.ogTitle || metaTitle },
                {
                    vmid: 'og:description',
                    property: 'og:description',
                    content: openGraph.ogDescription || metaDescription,
                },
                { vmid: 'og:type', property: 'og:type', content: openGraph.ogType },
                ogImage,
                ogUrl,
            ].filter((obj) => Object.keys(obj).length),
            link: [canonical],
            'xhtml:link': xhtmlLangVersions,
            script: [...scripts],
            noscript: [...drawGtmHeads(gtmConfigs)],
            __dangerouslyDisableSanitizers: ['script', 'innerHTML'],
        };
    },
    watch: {
        '$route.params.hash': {
            handler: function () {
                this.changeTheme();
            },
            deep: true,
            immediate: true,
        },
    },
    created() {
        this.changeTheme();
    },
    mounted() {
        const html = document.querySelector('html');
        html.dataset['pageTheme'] = this.pageThemeName;
        this.setLastMainSectionBackground();
        if (this.pageDisclaimer) {
            this.toggleDisclaimer();
        }
        this.changeTheme();

        if (window?.OneTrust != undefined) {
            this.removeDuplicatedOneTrustElements();
            this.reloadOneTrustBanner();
        }

        this.changeNoIndexTagValue();
    },
    beforeDestroy() {},
    methods: {
        setLastMainSectionBackground() {
            if (!this.$refs.main.$el.children) {
                return;
            }
            const classList = [
                ...(this.$refs.main.$el.children[this.$refs.main.$el.children.length - 1]?.classList || []),
            ].filter((className) => className.includes('background-') || className.includes('feature-banner-section'));
            this.lastMainSectionBackground = [...classList].map((className) => (className = `main-ls-bg-${className}`));
        },
        toggleDisclaimer() {
            this.showDisclaimerModal = !this.showDisclaimerModal;
        },
        changeTheme() {
            if (this.themeName === null) {
                cssVars({
                    watch: true,
                });
            }
            if (this.themeName !== this.pageThemeName && typeof document != 'undefined') {
                Object.keys(this[this.pageThemeName]).forEach((theme) => {
                    document.documentElement.style.setProperty(theme, this[this.pageThemeName][theme]);
                });
                this.themeName = this.pageThemeName;
            }
        },
        removeDuplicatedOneTrustElements() {
            const cookieListElements = document.querySelectorAll('#ot-sdk-cookie-policy');
            const verifier = [];

            if (!cookieListElements || !Array.isArray(cookieListElements)) {
                return;
            }

            for (let i = 0; i < cookieListElements.length; i++) {
                verifier.indexOf(cookieListElements[i].firstChild.innerText) < 0
                    ? verifier.push(cookieListElements[i].firstChild.innerText)
                    : cookieListElements[i].remove();
            }
        },
        reloadOneTrustBanner() {
            const sideBanner = document.getElementById('onetrust-consent-sdk');
            if (sideBanner) {
                sideBanner.remove();
            }

            window.OneTrust.Init();
            window.OneTrust.LoadBanner();

            const cookieButton = document.getElementsByClassName('ot-sdk-show-settings');
            for (let i = 0; i < cookieButton.length; i++) {
                cookieButton[i].onclick = function (event) {
                    event.stopImmediatePropagation();
                    window.OneTrust.ToggleInfoDisplay();
                };
            }
        },
        changeNoIndexTagValue() {
            const indexMetaTag = document.querySelector('meta[data-id="metaIndexTag"]');
            const indexMetaTagValue = this.route.fields.includeInIndex?.value;
            indexMetaTag && indexMetaTag.setAttribute('content', indexMetaTagValue ? 'index' : 'noindex');
        },
    },
};
</script>

<style scoped></style>
