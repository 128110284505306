<template>
    <section
        :id="anchorId"
        :data-e2e-component-uid="rendering.uid"
        class="default-section-padding"
        :class="backgroundClass"
    >
        <div class="container">
            <div v-if="sectionType === 'H2'" class="row mb-2 d-flex justify-content-center">
                <div class="col-12 col-md-8">
                    <ScText
                        v-if="isExperienceEditor || titleExist"
                        tag="h2"
                        :field="fields.title"
                        class="background-to-change"
                    />
                </div>
            </div>

            <div class="row d-flex d-flex justify-content-center">
                <div class="col-12 col-md-8 d-flex flex-column align-items-start">
                    <div v-if="sectionType === 'H3'" class="mb-2 d-flex align-items-center">
                        <WrappedImage
                            :media="fields.icon"
                            class="section-heading-image me-4 background-to-change"
                            :srcSet="[{ mw: 100 }, { mw: 200 }]"
                            sizes="64px"
                        />
                        <ScText
                            v-if="isExperienceEditor || titleExist"
                            tag="h3"
                            :field="fields.title"
                            class="background-to-change"
                        />
                    </div>
                    <ScText
                        v-if="isExperienceEditor || descriptionExist"
                        tag="p"
                        class="body-default description text-grey background-to-change"
                        :field="fields.description"
                    />

                    <AbrdnButton
                        v-if="(sectionType === 'H3' && ctaExist) || isExperienceEditor || ctaExist"
                        buttonType="primary"
                        class="mt-5 text-grey"
                        :linkField="fields.cta"
                        :replaceWithSlot="false"
                        :showExternalIcon="false"
                    >
                        <em class="bi-arrow-right ms-1" aria-hidden="true" />
                    </AbrdnButton>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton';
import WrappedImage from '@/components/Generic/SitecoreWrappers/WrappedImage';
import { backgroundClass } from '@/mixins/Helpers.mixin';

export default {
    name: 'SectionHeading',
    components: {
        ScText: Text,
        AbrdnButton,
        WrappedImage,
    },
    mixins: [backgroundClass],
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        sectionType() {
            return this.rendering?.params?.type;
        },
        titleExist() {
            return !!this.fields?.title?.value;
        },
        descriptionExist() {
            return !!this.fields?.description?.value;
        },
        ctaExist() {
            return !!this.fields?.cta?.value?.text;
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';

.section-heading-image {
    max-width: 64px;
    max-height: 64px;
    width: 100%;
    height: 100%;
    ::v-deep svg,
    ::v-deep img {
        max-width: 64px;
        max-height: 64px;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

p.description,
::v-deep .description p {
    white-space: pre-line;
}
</style>
