<template>
    <AbrdnButton buttonType="primary" class="slider-nav">
        <em class="d-flex" :class="{ [`bi-chevron-${direction}`]: true }" aria-hidden="true" />
    </AbrdnButton>
</template>

<script>
import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton';

export default {
    name: 'SliderNavCircle',
    components: { AbrdnButton },
    props: {
        direction: {
            type: String,
            default: /* istanbul ignore next */ () => 'right',
        },
    },
};
</script>

<style scoped lang="scss">
.slider-nav {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    i {
        width: 12px;
        height: 14px;
        display: flex;
        justify-content: center;
        &:before {
            width: 14px;
            display: flex;
            justify-content: center;
        }
    }
}

.bi-chevron-right {
    &:before {
        margin-left: 3px;
    }
}
</style>
