<template>
    <div>
        <ul v-if="isExperienceEditor || documents.loading" class="document-list">
            <li v-for="item in Array(5)" :key="item">
                <div class="py-3">
                    <BaseSkeleton class="d-block" width="120px" height="24px" />
                </div>
            </li>
        </ul>

        <AlertMessageBase v-else-if="documents.error" type="error" :title="$t(documents.error)" />

        <ul v-else-if="documents.data && documents.data.length" class="document-list">
            <li v-for="document in documents.data" :key="document.documentId" class="py-3">
                <a
                    :href="document.documentUri"
                    target="_blank"
                    class="d-inline-flex align-items-center gap-1"
                    @click="updateDataLayer(document)"
                >
                    <span class="text-link">{{ getLinkText(document) }}</span>

                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M13.2417 5.35382L11.9124 6.68309H16.3655L9.4533 13.5288C9.18744 13.7947 9.18744 14.1935 9.4533 14.4593C9.71915 14.7252 10.1179 14.7252 10.3838 14.4593L17.296 7.68004V12.1331L18.6252 10.8038V5.35382H13.2417Z"
                            fill="currentColor"
                        />
                        <path
                            d="M17.9604 13.9939C17.5616 13.9939 17.2957 14.2598 17.2957 14.6585V17.3171H6.32927V6.68294H9.98476C10.3835 6.68294 10.6494 6.41708 10.6494 6.0183C10.6494 5.61952 10.3835 5.35367 9.98476 5.35367H5.66463C5.26585 5.35367 5 5.61952 5 6.0183V17.9817C5 18.3805 5.26585 18.6464 5.66463 18.6464H17.9604C18.3591 18.6464 18.625 18.3805 18.625 17.9817V14.6585C18.625 14.3262 18.2927 13.9939 17.9604 13.9939Z"
                            fill="currentColor"
                        />
                    </svg>

                    <span class="sr-only">{{ $t('opensInNewWindow') }}</span>
                </a>
            </li>
        </ul>

        <div v-else class="body-default">{{ $t('noResultsMessage') }}</div>
    </div>
</template>

<script>
import { getDocumentListing } from '@/api/literatureHub';
import AlertMessageBase from '@/components/Generic/AlertMessageBase';
import BaseSkeleton from '@/components/Generic/BaseSkeleton';
import pushDataLayerEvent from '@/mixins/DataLayer.mixin';
import { localizeDate } from '@/mixins/Helpers.mixin';

export default {
    name: 'DocumentListingBase',

    components: {
        AlertMessageBase,
        BaseSkeleton,
    },

    mixins: [localizeDate, pushDataLayerEvent],

    props: {
        editionId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            documents: {
                loading: false,
                error: null,
                data: null,
            },
        };
    },

    async created() {
        await this.fetchData();
    },

    methods: {
        async fetchData() {
            if (this.isExperienceEditor) return;

            try {
                this.documents.loading = true;
                const { data } = await getDocumentListing({ editionId: this.editionId });
                this.documents.data = data;
            } catch (error) {
                const status = error?.response?.status;
                this.documents.error = status !== undefined ? `error${status}` : 'errorUnknown';
            } finally {
                this.documents.loading = false;
            }
        },

        getLinkText(document) {
            return this.localizeDate(document.figuresToDate, {
                month: 'short',
                year: 'numeric',
            });
        },

        updateDataLayer(item) {
            if (typeof window !== 'undefined') {
                const origin = window.location.origin;
                this.pushDataLayerEvent({
                    event: 'file_download',
                    file_url: item.documentUri.includes('https://') ? item.documentUri : `${origin}${item.documentUri}`,
                    file_name: this.getLinkText(item),
                });
            }
        },
    },
};
</script>

<style scoped lang="scss">
.document-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.document-list li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
</style>
