<template>
    <div v-show="Object.keys(dragger).length">
        <div class="form-label-wrapper">
            <span class="form-label background-to-change">{{ label }}</span>
            <Tooltip v-if="tooltipValue">
                <template #tooltipContent>
                    <span class="hint-text text-grey">{{ tooltipValue }}</span>
                </template>
            </Tooltip>
        </div>
        <div class="dragger-wrapper">
            <div ref="dragger" class="background-to-change" />
            <div ref="fieldText" class="field-text background-to-change">{{ value }}</div>
        </div>
    </div>
</template>

<script>
import * as noUiSlider from 'nouislider';

import Tooltip from '@/components/Generic/FormElements/FormFields/Tooltip.vue';

export default {
    name: 'Dragger',
    components: { Tooltip },
    props: {
        fields: {
            type: Object,
            default: () => {},
        },
        usePercent: {
            type: Boolean,
            default: true,
        },
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 8,
        },
        startValue: {
            type: Number,
            default: 5,
        },
        step: {
            type: Number,
            default: 1,
        },
        label: {
            type: String,
            default: 'Choose your growth rate',
        },
    },
    data() {
        return {
            dragger: {},
            value: 0,
        };
    },
    computed: {
        tooltipValue() {
            return this.fields?.tooltip?.value;
        },
    },
    mounted() {
        this.dragger = noUiSlider.create(this.$refs.dragger, {
            start: this.startValue,
            step: this.step,
            range: {
                min: this.min,
                max: this.max,
            },
            handleAttributes: [{ 'aria-label': 'dragger' }],
        });
        this.calculateValue();
    },
    destroyed() {
        this.dragger.off();
    },
    methods: {
        calculateValue() {
            const updateValue = (value, emitter) => {
                const parsedValue = parseInt(value[0]);
                if (this.usePercent) {
                    this.value = `${parsedValue} %`;
                } else {
                    this.value = parsedValue;
                }
                this.$emit(emitter, parsedValue);
            };
            this.dragger.on('update.one', (value) => {
                updateValue(value, 'updateDraggerValue');
            });
            this.dragger.on('set', (value) => {
                /* istanbul ignore next */
                updateValue(value, 'setDraggerValue');
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~nouislider/dist/nouislider.css';
@import '@/assets/styles/variables';

.label-wrapper {
    display: flex;
}

.form-label-wrapper {
    line-height: 0;
}

.dragger-wrapper {
    display: flex;
    align-items: center;
    margin-top: 27px;

    .field-text {
        margin-left: 16px;
    }

    .noUi-target {
        width: 81%;
        border-top: none;
        border-bottom: none;
        background: none;
        box-shadow: none;
        border-radius: 0;
        border-width: 2px;
        border-color: var(--dragger-gray-line);
        height: 12px;
        cursor: pointer;

        &:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            height: 2px;
            background: var(--dragger-gray-line);
        }
    }

    /deep/ .noUi-handle {
        box-shadow: none;
        width: 16px;
        height: 16px;
        right: -8px;
        top: -2px;
        border: none;
        border-radius: 50px;
        cursor: pointer;

        &:before,
        &:after {
            display: none;
        }
    }

    /deep/ .noUi-base {
        position: absolute;
        width: calc(100% - 20px);
        left: 50%;
        transform: translateX(-50%);
    }
}
</style>
