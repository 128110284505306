<script>
export default {
    name: 'FormsSharedLogic',
    data() {
        return {
            formData: {},
            salesforceFormData: {
                records: [{}],
            },
            subscriptionSuccess: false,
        };
    },
    computed: {
        layout() {
            return this.fields?.formJson?.value?.layout;
        },
        formField() {
            return this.layout?.rows?.filter(({ type }) => type === 'FormField' || !type);
        },
        sharedFields() {
            const filteredFields = this.formField.filter((item) => item.cols[0].cells[0].element.unique === undefined);
            const fieldsData = [];
            filteredFields.forEach((filed) => {
                fieldsData.push({ colWidth: filed.cols[0].width, element: filed.cols[0].cells[0].element });
            });
            return fieldsData;
        },
        uniqueFields() {
            return this.formField.filter(
                (item) =>
                    item.cols[0].cells[0].element.unique && item.cols[0].cells[0].element.displayType !== 'hidden',
            );
        },
        bottomRows() {
            return this.layout?.bottomRows;
        },
        submitRow() {
            return this.layout?.submitRow?.cols;
        },
        getFormName() {
            return this.fields?.formJson?.value?.htmlName;
        },
        showForm() {
            return (
                !!this.layout &&
                !!this.fields?.formJson?.value?.postUrl &&
                !!this.fields?.formJson?.value?.htmlName &&
                !!this.fields?.formJson?.value?.siteId
            );
        },
    },
    methods: {
        getColClass(width) {
            return 'col-md-' + width;
        },
        getButtonText(col) {
            return col.cells[0]?.element?.name;
        },
        isHeader(row) {
            return row.cols[0]?.cells[0]?.element?.style?.textType === 'header';
        },
        isSubHeader(row) {
            return row.cols[0]?.cells[0]?.element?.style?.textType === 'subheader';
        },
        isNote(row) {
            return row.cols[0]?.cells[0]?.element?.type === 'note';
        },
        isDescription(row) {
            return row.cols[0]?.cells[0]?.element?.style?.textType === 'body';
        },
    },
};
</script>
