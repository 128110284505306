<template>
    <component
        :is="defineComponent"
        class="btn"
        :field="linkField"
        :showExternalIcon="showExternalIcon"
        :replaceWithSlot="replaceWithSlot"
        :class="{
            'btn-primary button-label': buttonType === 'primary',
            'btn-secondary button-label': buttonType === 'secondary',
            'btn-text-link text-link revert-underline': buttonType === 'textLink',
            success: isSuccess,
        }"
    >
        <slot />
    </component>
</template>

<script>
import ScLink from '@/components/Generic/SitecoreWrappers/ScLink.vue';

export default {
    name: 'AbrdnButton',
    components: { ScLink },
    props: {
        buttonType: {
            type: String,
            default: /* istanbul ignore next */ () => '',
        },
        isSuccess: {
            type: Boolean,
            default: /* istanbul ignore next */ () => false,
        },
        linkField: {
            type: [Object, Boolean],
            required: false,
            default: () => false,
        },
        showExternalIcon: {
            type: Boolean,
            default: () => true,
        },
        replaceWithSlot: {
            type: Boolean,
            default: () => true,
        },
    },
    computed: {
        defineComponent() {
            return this.linkField ? 'ScLink' : 'button';
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/mixins';
@import '@/assets/styles/variables';

.btn-primary,
.btn-secondary {
    height: 48px;
    display: inline-flex;
    align-items: center;
    border-radius: 24px;
    transition: background-color 0.2s, color 0.2s, opacity 0.2s;
    padding: 0 calc(var(--spacer) * 4);
    white-space: initial;
}

.btn-primary {
    background-color: var(--button-black);
    color: var(--abrdn-white);
    border: none;
    @include dark-backgrounds() {
        background: var(--button-white);
        color: var(--button-black);
    }
}

.btn-check:checked + .btn-primary:focus,
.btn-check:active + .btn-primary:focus,
.btn-primary:active:focus,
.btn-primary.active:focus,
.btn-primary:focus,
.show > .btn-primary.dropdown-toggle:focus,
.btn-check:checked + .btn-secondary:focus,
.btn-check:active + .btn-secondary:focus,
.btn-secondary:active:focus,
.btn-secondary.active:focus,
.btn-secondary:focus,
.show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: none;
    outline: none;
}

//Btn-primary focus state
.btn-check:checked + .btn-primary:not(.disabled):not([disabled]):focus,
.btn-check:active + .btn-primary:not(.disabled):not([disabled]):focus,
.btn-primary:not(.disabled):not([disabled]):active:focus,
.btn-primary:not(.disabled):not([disabled]).active:focus,
.btn-primary:not(.disabled):not([disabled]):focus,
.show > .btn-primary:not(.disabled):not([disabled]).dropdown-toggle:focus,
.btn-primary:not(.disabled):not([disabled]):hover {
    background-color: var(--button-night);
    color: var(--button-white);
    @include dark-backgrounds() {
        background-color: var(--button-mist);
        color: var(--button-night);
    }
}

.btn-primary[disabled],
.btn-primary.disabled {
    opacity: 0.3;
    cursor: not-allowed;
    @include dark-backgrounds() {
        background-color: var(--button-white);
        color: var(--button-black);
    }
}

.btn-secondary {
    background-color: transparent;
    color: var(--button-black);
    border: 1px solid var(--button-black);
    @include dark-backgrounds() {
        color: var(--button-white);
        border-color: var(--button-white);
    }
}

.btn-check:checked + .btn-secondary:not(.disabled):not([disabled]):focus,
.btn-check:active + .btn-secondary:not(.disabled):not([disabled]):focus,
.btn-secondary:not(.disabled):not([disabled]):active:focus,
.btn-secondary:not(.disabled):not([disabled]).active:focus,
.btn-secondary:not(.disabled):not([disabled]):focus,
.show > .btn-secondary:not(.disabled):not([disabled]).dropdown-toggle:focus,
.btn-secondary:not(.disabled):not([disabled]):hover {
    background-color: var(--button-night);
    color: var(--button-white);
    border-color: var(--button-night);
    @include dark-backgrounds() {
        background-color: var(--button-mist);
        border-color: var(--button-mist);
        color: var(--button-night);
    }
}

.btn-secondary[disabled],
.btn-secondary.disabled {
    opacity: 0.6;
    cursor: not-allowed;
    @include dark-backgrounds() {
        background-color: transparent;
        border-color: var(--button-white);
        color: var(--button-white);
        opacity: 0.7;
    }
}

.btn-text-link {
    padding: 0;
    border-radius: 0;
    display: inline-block;
    border: none;
    text-underline-position: under;
}
.abrdn-not-rendered-button {
    display: none;
}

.btn-primary.success {
    background-color: $status-success-400 !important;
    pointer-events: none;
}
</style>
