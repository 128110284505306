<script>
import * as Plot from '@observablehq/plot';
import { mapState } from 'vuex';

import ChartMixin from '../../Chart.mixin.vue';

export default {
    name: 'FundDetailsPremiumDiscountLineChart',

    mixins: [ChartMixin],

    computed: {
        ...mapState('fundDetails', {
            data: (state) => state.premiumLineChartData.data?.points,
            isLoading: (state) => state.premiumLineChartData.loading,
        }),

        chart() {
            if (!this.data) return;

            const convertedData = this.data.map((d) => ({
                date: new Date(d.date),
                value: Number(d.basisPoint),
            }));

            const minValue = Math.min(...convertedData.map((d) => d.value));

            return Plot.plot({
                y: {
                    grid: true,
                    label: this.$t('fundDetailsPremiumDiscountBps'),
                },
                marginTop: 40,
                marginBottom: 40,
                width: this.chartWidth,
                height: this.chartHeight,
                marks: [
                    Plot.ruleY([minValue]),

                    Plot.areaY(convertedData, {
                        x: 'date',
                        y1: minValue,
                        y2: 'value',
                        fill: '#00205B',
                        opacity: 0.05,
                    }),

                    Plot.lineY(convertedData, {
                        x: 'date',
                        y: 'value',
                        stroke: '#00205B',
                        strokeWidth: 2,
                    }),

                    Plot.ruleX(
                        convertedData,
                        Plot.pointerX({
                            x: 'date',
                            strokeWidth: 1,
                            opacity: 0.2,
                            maxRadius: 999,
                        }),
                    ),

                    Plot.dot(
                        convertedData,
                        Plot.pointerX({
                            x: 'date',
                            y: 'value',
                            fill: '#00205B',
                            r: 4,
                            strokeWidth: 0,
                            maxRadius: 999,
                        }),
                    ),

                    Plot.tip(
                        convertedData,
                        Plot.pointerX({
                            x: 'date',
                            y: 'value',
                            title: (d) =>
                                `${this.$t('date')}: ${this.localizeDate(d.date)}\n${this.$t('value')}: ${d.value}`,
                            strokeWidth: 0,
                            pointerSize: 0,
                            textPadding: 12,
                            fontSize: 12,
                            lineHeight: 1.5,
                            maxRadius: 999,
                        }),
                    ),
                ],
            });
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';

.chart-wrapper {
    padding-top: 50%;

    @include media-breakpoint-up(lg) {
        padding-top: 30%;
    }
}
</style>
