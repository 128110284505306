<template>
    <div class="d-flex align-items-start radio-button-wrapper">
        <div
            class="d-inline-flex align-items-start"
            tabindex="0"
            @click.stop="!disabled && setState()"
            @keyup.enter.stop="!disabled && setState()"
        >
            <div class="radio-button d-flex" :class="{ selected: isChecked, disabled: disabled }">
                <input
                    :id="'radio-button' + name + ' ' + value"
                    :aria-label="'radio-button ' + value"
                    type="radio"
                    :required="required"
                    :checked="isChecked"
                    :value="value"
                    :name="name"
                    tabindex="-1"
                    @invalid="setError"
                />
                <div v-if="isChecked" class="d-flex fill-circle" />
            </div>
        </div>
        <label
            v-if="$slots['label']"
            :for="'radio-button' + name + ' ' + value"
            class="label-slot radio-button--label ms-2"
            :class="{ disabled: disabled }"
        >
            <slot name="label" />
        </label>
    </div>
</template>

<script>
export default {
    name: 'RadioButton',
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: () => false,
        },
        value: {
            type: String,
            default: () => null,
        },
        name: {
            type: String,
            default: () => '',
        },
        modelValue: {
            type: String,
            default: () => '',
        },
    },
    data() {
        return {
            isError: false,
        };
    },
    computed: {
        isChecked() {
            return this.modelValue === this.value;
        },
    },
    methods: {
        setState() {
            this.$emit('onChange', this.value);
            this.isError = false;
        },
        setError(event) {
            event.preventDefault();
            this.$emit('onError', event);
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
@import '@/assets/styles/mixins';
.radio-button {
    @include dark-backgrounds() {
        box-shadow: inset 0px 0px 0px 1px var(--button-mist);
        &:hover:not(.selected):not(.disabled),
        &:focus:not(.selected):not(.disabled) {
            background: var(--abrdn-black);
            box-shadow: inset 0px 0px 0px 2px var(--button-mist);
        }
        &:hover.selected {
            box-shadow: inset 0px 0px 0px 1px var(--button-mist);
        }
    }
    box-sizing: border-box;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 24px;
    min-height: 24px;
    box-shadow: inset 0px 0px 0px 1px var(--button-night);
    &.selected {
        box-shadow: inset 0px 0px 0px 2px var(--button-black);
        @include dark-backgrounds() {
            box-shadow: inset 0px 0px 0px 2px var(--button-white);
        }
    }
    &:hover:not(.selected):not(.disabled),
    &:focus:not(.selected):not(.disabled) {
        background: var(--button-mist);
        box-shadow: inset 0px 0px 0px 2px var(--abrdn-black);
        @include dark-backgrounds() {
            background: var(--abrdn-black);
            box-shadow: inset 0px 0px 0px 2px var(--button-mist);
        }
    }
    &:hover.selected {
        box-shadow: inset 0px 0px 0px 2px var(--button-black);
        @include dark-backgrounds() {
            box-shadow: inset 0px 0px 0px 2px var(--button-mist);
        }
    }
    &.disabled {
        border-color: rgba(63, 63, 63, 0.5) !important;
        box-shadow: inset 0px 0px 0px 1px rgba(63, 63, 63, 0.5);
    }
    &.selected.disabled {
        background: rgba(0, 0, 0, 0.5);
        border-color: rgba(0, 0, 0, 0.5);
        box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.5);
        @include dark-backgrounds() {
            background: rgba(255, 255, 255, 0.5);
            border-color: rgba(255, 255, 255, 0.5);
            box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 0.5);
        }
    }
    &--label {
        cursor: pointer;
    }
}

.disabled {
    opacity: 0.5;
}
.fill-circle {
    @include dark-backgrounds() {
        background: var(--abrdn-white);
    }
    height: 14px;
    width: 14px;
    border-radius: 14px;
    background: var(--abrdn-black);
}

.radio-button-wrapper {
    position: relative;
    @include media-breakpoint-down(lg) {
        outline: 0px;
    }
}

.form-element--error {
    .radio-button {
        background: transparent;
        box-shadow: inset 0px 0px 0px 2px var(--error-400);
        @include dark-backgrounds() {
            background: var(--error-200);
            outline: 2px solid var(--error-200);
            box-shadow: inset 0px 0px 0px 2px var(--error-400);
            &:hover:not(.selected):not(.disabled) {
                background: var(--abrdn-black) !important;
                outline: 1px solid var(--abrdn-white);
            }
        }
    }
}

input {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
}
.label-slot * {
    line-height: 24px !important;
}
</style>
