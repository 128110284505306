<template>
    <div class="relative">
        <div v-if="overlay" class="overlay" @click="$refs[`dropdown`].click()" />
        <button
            ref="dropdown"
            v-collapse-target:[speed]="`.dropdown-content`"
            class="btn btn-naked d-flex collapsed dropdown-trigger"
            aria-expanded="false"
            @keyup.enter="
                $refs[`dropdown`].click();
                $emit('enter');
            "
            @keyup.esc="
                $refs[`dropdown`].click();
                overlay = false;
            "
        >
            <slot name="trigger-area" />
        </button>
        <div class="collapse dropdown-content" :style="{ top: `${top}px` }"><slot /></div>
    </div>
</template>

<script>
import Collapse from '@/mixins/Collapse.mixin.vue';

export default {
    name: 'GlobalNavigationDropdown',
    mixins: [Collapse],
    props: {
        fields: {
            type: Object,
            default: () => {},
        },
        speed: {
            type: Number,
            default: 200,
        },
        top: {
            type: Number,
            default: 0,
        },
    },
};
</script>

<style scoped lang="scss">
.relative {
    position: relative;
}
.dropdown-trigger[aria-expanded='true'] {
    z-index: 103;
}
.dropdown-content {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 102;
}
.overlay {
    background: #eeeeee10;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 101;
}
</style>
