<template>
    <section class="default-section-padding background-white">
        <FundDetailsTabRow>
            <h2>{{ title }}</h2>
        </FundDetailsTabRow>

        <FundDetailsTabRow v-if="availablePerformanceTabs.thaiCumulativeDailyTable" fullWidth class="mt-8">
            <CumulativeDailyTable />
        </FundDetailsTabRow>

        <FundDetailsTabRow v-if="availablePerformanceTabs.thaiAnnualizedDailyTable" fullWidth class="mt-8">
            <AnnualizedDailyTable />
        </FundDetailsTabRow>

        <FundDetailsTabRow v-if="availablePerformanceTabs.thaiCumulativeTable" fullWidth class="mt-8">
            <CumulativeTable />
        </FundDetailsTabRow>

        <FundDetailsTabRow v-if="availablePerformanceTabs.thaiAnnualizedTable" fullWidth class="mt-8">
            <AnnualizedTable />
        </FundDetailsTabRow>

        <FundDetailsPerformanceDisclaimer isThai class="mt-8" />
    </section>
</template>

<script>
import { mapState } from 'vuex';

import FundDetailsTabRow from '../FundDetailsTabRow';
import AnnualizedDailyTable from '../SecondLevelTabs/PerformanceTabs/Tables/AnnualizedDailyTable';
import AnnualizedTable from '../SecondLevelTabs/PerformanceTabs/Tables/AnnualizedTable';
import CumulativeDailyTable from '../SecondLevelTabs/PerformanceTabs/Tables/CumulativeDailyTable';
import CumulativeTable from '../SecondLevelTabs/PerformanceTabs/Tables/CumulativeTable';
import FundDetailsPerformanceDisclaimer from './FundDetailsPerformanceDisclaimer';

export default {
    name: 'FundDetailsThaiPerformanceTab',

    components: {
        AnnualizedDailyTable,
        AnnualizedTable,
        CumulativeDailyTable,
        CumulativeTable,
        FundDetailsTabRow,
        FundDetailsPerformanceDisclaimer,
    },

    props: {
        title: {
            type: String,
            default: '',
        },
    },

    computed: {
        ...mapState('fundDetails', {
            availablePerformanceTabs: (state) => state.availableTabs?.performanceTab || {},
        }),
    },
};
</script>
