<template>
    <div
        v-if="showNotification"
        class="notification position-sticky d-flex justify-content-between m-1 p-1 start-0 end-0 top-0"
    >
        <div class="d-flex">
            <em class="bi bi-exclamation-triangle-fill me-1" aria-hidden="true" />
            <span>
                {{
                    `This page is opened in "${contextLanguage}" language. Any updates for components datasources could affect pages
                in "${dependentLanguages}" languages as they are using "${contextLanguage}" as a fallback language.`
                }}
            </span>
        </div>
        <button :aria-label="$t('closeModal')" class="btn close-button d-flex align-items-center" @click="show = false">
            <em class="bi bi-x-lg" aria-hidden="true" />
        </button>
    </div>
</template>

<script>
export default {
    name: 'PageLevelNotification',
    data() {
        return {
            show: true,
        };
    },
    computed: {
        showNotification() {
            return !!this.$jss.sitecoreContext().dependentLanguages && this.isExperienceEditor && this.show;
        },
        dependentLanguages() {
            return this.$jss.sitecoreContext()?.dependentLanguages;
        },
        contextLanguage() {
            return this.$jss.sitecoreContext()?.displayLanguage;
        },
    },
};
</script>

<style scoped>
.notification {
    z-index: 120;
    background-color: #f27900;
}
.close-button {
    width: 24px;
    height: 24px;
    padding: 5px;
    border: 0;
}
</style>
