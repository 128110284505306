<template>
    <section class="default-section-padding background-white">
        <FundDetailsTabRow>
            <h2>{{ title }}</h2>
        </FundDetailsTabRow>

        <FundDetailsTabRow fullWidth class="mt-4">
            <FundDetailsPerformanceTabCumulativeSection v-if="graphSection" />
        </FundDetailsTabRow>

        <FundDetailsSecondLevelTabGroup
            class="mt-8"
            :availableTabs="availablePerformanceTabs"
            :tabComponentsByTabKeys="tabComponentsByTabKeys"
            :tabTitlesByTabKeys="tabTitlesByTabKeys"
        />

        <FundDetailsPerformanceDisclaimer class="mt-8" />
    </section>
</template>

<script>
import { mapState } from 'vuex';

import FundDetailsPerformanceTabCumulativeSection from '../FundDetailsPerformanceTabCumulativeSection/FundDetailsPerformanceTabCumulativeSection';
import FundDetailsSecondLevelTabGroup from '../FundDetailsSecondLevelTabGroup';
import FundDetailsTabRow from '../FundDetailsTabRow';
import FundDetailsAnnualizedTab from '../SecondLevelTabs/PerformanceTabs/FundDetailsAnnualizedTab';
import FundDetailsCalendarTab from '../SecondLevelTabs/PerformanceTabs/FundDetailsCalendarTab';
import FundDetailsCumulativeTab from '../SecondLevelTabs/PerformanceTabs/FundDetailsCumulativeTab';
import FundDetailsDiscreteTab from '../SecondLevelTabs/PerformanceTabs/FundDetailsDiscreteTab';
import FundDetailsPerformanceDisclaimer from './FundDetailsPerformanceDisclaimer';

export default {
    name: 'FundDetailsPerformanceTab',

    components: {
        FundDetailsTabRow,
        FundDetailsSecondLevelTabGroup,
        FundDetailsPerformanceTabCumulativeSection,
        FundDetailsPerformanceDisclaimer,
    },

    props: {
        title: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            tabComponentsByTabKeys: {
                cumulativeTab: FundDetailsCumulativeTab,
                annualizedTab: FundDetailsAnnualizedTab,
                discreteTab: FundDetailsDiscreteTab,
                calendarTab: FundDetailsCalendarTab,
            },
            tabTitlesByTabKeys: {
                cumulativeTab: this.$t('fundDetailsTabsPerformanceCumulative'),
                annualizedTab: this.$t('fundDetailsTabsPerformanceAnnualized'),
                discreteTab: this.$t('fundDetailsTabsPerformanceDiscrete'),
                calendarTab: this.$t('fundDetailsTabsPerformanceCalendar'),
            },
        };
    },

    computed: {
        ...mapState('fundDetails', {
            availablePerformanceTabs: (state) => state.availableTabs?.performanceTab,
        }),

        graphSection() {
            return this.availablePerformanceTabs?.graphSection;
        },
    },
};
</script>
