<template>
    <section
        :id="anchorId"
        :data-e2e-component-uid="rendering.uid"
        :class="backgroundClass"
        class="default-section-padding"
    >
        <div class="container">
            <div class="row d-flex">
                <div class="col-12 col-md-9 col-xl-5 mb-5 mb-md-8 mb-xl-0">
                    <ScText v-if="fields.title || isExperienceEditor" tag="h2" :field="fields.title" />
                    <ScText
                        v-if="fields.description || isExperienceEditor"
                        tag="p"
                        :field="fields.description"
                        class="mt-3 text-grey"
                    />
                    <AbrdnButton
                        v-if="isValueExists(fields.cta)"
                        :linkField="fields.cta"
                        :replaceWithSlot="false"
                        :showExternalIcon="false"
                        :buttonType="'primary'"
                        class="mt-5 text-grey"
                    >
                        <em class="bi-arrow-right ms-1" aria-hidden="true" />
                    </AbrdnButton>
                </div>
                <div class="col-xl-1" />
                <div class="col-12 col-md-9 col-xl-5">
                    <div
                        v-for="(item, i) in fields.items"
                        :key="item.id"
                        class="d-flex w-100 py-5 showcase-items-container"
                        :class="{
                            'align-items-center': !(item.fields.description && item.fields.description.value),
                            'pt-0': i === 0,
                            'pb-0': i === fields.items.length - 1,
                        }"
                    >
                        <WrappedImage
                            :media="item.fields.image || isExperienceEditor"
                            class="showcase-image"
                            :srcSet="[{ mw: 100 }, { mw: 200 }]"
                            sizes="64px"
                        />
                        <div class="d-flex flex-column ms-3">
                            <ScText
                                v-if="item.fields.title || isExperienceEditor"
                                tag="h3"
                                :field="item.fields.title"
                            />
                            <ScText
                                v-if="item.fields.description || isExperienceEditor"
                                tag="p"
                                :field="item.fields.description"
                                class="body-default showcase-item-description text-grey"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton';
import WrappedImage from '@/components/Generic/SitecoreWrappers/WrappedImage';
import { backgroundClass } from '@/mixins/Helpers.mixin';

export default {
    name: 'Showcase',
    components: {
        ScText: Text,
        AbrdnButton,
        WrappedImage,
    },
    mixins: [backgroundClass],
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';

.showcase-image {
    max-width: 64px;
    max-height: 64px;
    width: 100%;
    height: 100%;
    ::v-deep svg,
    ::v-deep img {
        max-width: 64px;
        max-height: 64px;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
.showcase-items-container {
    border-bottom: 1px solid var(--cool-grey);
    .showcase-item-description {
        margin-top: 4px;
    }
}

.showcase-items-container:last-child {
    border: none;
}

p,
::v-deep p {
    white-space: pre-line;
}
</style>
