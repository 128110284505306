<template>
    <div :id="anchorId" class="key-highlight-item">
        <ScText tag="h4" class="h4 background-to-change" :field="fields.title" />
        <ScRichText class="body-default background-to-change mt-2" :fields="fields" />
        <AbrdnPlaceholder class="mt-2" :rendering="rendering" :name="`abrdn-key-highlight-item-${idx}`" />
    </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import AbrdnPlaceholder from '@/components/Generic/SitecoreWrappers/AbrdnPlaceholder.vue';
import RichText from '@/components/Generic/SitecoreWrappers/RichText.vue';

export default {
    name: 'KeyHighlightItem',
    components: {
        ScText: Text,
        ScRichText: RichText,
        AbrdnPlaceholder,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        idx: {
            type: Number,
            required: true,
        },
    },
};
</script>

<style scoped></style>
