<template>
    <div>
        <div class="row">
            <div class="col-12 col-md-8">
                <BaseSkeleton height="64px" />
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-12 col-md-8">
                <BaseSkeleton height="64px" />
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-12 col-md-4">
                <BaseSkeleton height="64px" />
            </div>

            <div class="col-12 col-md-8 mt-3 mt-md-0">
                <BaseSkeleton height="64px" />
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-12">
                <BaseSkeleton height="64px" />
            </div>
        </div>
    </div>
</template>

<script>
import BaseSkeleton from '@/components/Generic/BaseSkeleton.vue';

export default {
    name: 'FundDetailsBannerSkeleton',

    components: {
        BaseSkeleton,
    },
};
</script>
