<template>
    <section :id="anchorId" :data-e2e-component-uid="rendering.uid" class="background-white pb-1 pb-md-2 pb-xl-4">
        <Tabs
            :activeTab="activeTab"
            :fields="{
                ...fields,
                items: fields.mapUrl.value
                    ? [...fields.items, { fields: { title: { value: $t('propertyMapTitle') } } }]
                    : fields.items,
            }"
            @onTabChange="toggleTab"
        />

        <template v-for="(item, index) in fields.items">
            <div v-show="activeTab === index" :key="item.id" class="container">
                <div class="row pt-5 pt-md-6 pt-xl-8">
                    <PropertyListingCard
                        v-for="card in [...item.fields.items].sort((a, b) =>
                            a.fields.title.value.localeCompare(b.fields.title.value, language, { sensitivity: 'base' }),
                        )"
                        :key="card.id"
                        :fields="card.fields"
                        class="col-12 col-md-6 col-lg-3 mb-4"
                        :category="item.fields.title.value"
                    />
                </div>
            </div>
        </template>
        <IFrameBlock v-show="activeTab === fields.items.length" :fields="{ source: { value: fields.mapUrl.value } }" />
    </section>
</template>

<script>
import IFrame from '@/components/Blocks/Iframes/IFrame.vue';
import Tabs from '@/components/Functional/Tabs.vue';
import PropertyListingCard from '@/components/Tiles/PropertyListingCard.vue';

export default {
    name: 'PropertyListingCards',
    components: {
        PropertyListingCard,
        Tabs,
        IFrameBlock: IFrame,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            activeTab: null,
        };
    },
    computed: {
        language() {
            return this.$jss.sitecoreContext().language.slice(0, 2);
        },
    },
    methods: {
        toggleTab(tab) {
            this.activeTab = tab;
        },
    },
};
</script>

<style scoped lang="scss"></style>
