<template>
    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.42872 0.239132L6.75487 5.42435C7.08171 5.74254 7.08171 6.25843 6.75487 6.57662L1.42872 11.7618C1.10188 12.08 0.571969 12.08 0.245129 11.7618C-0.0817098 11.4437 -0.0817098 10.9278 0.245129 10.6096L4.98074 6.00049L0.245129 1.3914C-0.0817098 1.07321 -0.0817098 0.557323 0.245129 0.239132C0.571969 -0.0790595 1.10188 -0.0790595 1.42872 0.239132Z"
            fill="currentColor"
        />
    </svg>
</template>

<script>
export default {
    name: 'IconChevronQuickLinks',
};
</script>

<style scoped></style>
