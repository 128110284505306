<script>
export default {
    name: 'PaginationMixin',
    computed: {
        showMore() {
            const output = {
                placeholder: 'pages',
                options: {
                    value: [],
                },
            };
            const step = 20;
            for (let i = 0; i < 5; i++) {
                const stepNumber = (i + 1) * step;
                output.options.value.push({
                    name: stepNumber,
                    displayName: stepNumber,
                    selected: this.take === stepNumber,
                });
            }
            return output;
        },
    },
};
</script>
