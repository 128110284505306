<template>
    <section>
        <FilterBarFunctional
            :data-e2e-component-uid="rendering.uid"
            :formattedMapping="filterMappings"
            :vuexData="vuexData"
        />
        <TrustCards :data-e2e-component-uid="rendering.uid + '-listing'" />
    </section>
</template>

<script>
import FilterBarFunctional from '@/components/Functional/ArticleFilterBar/FilterBar.vue';
import TrustCards from '@/components/Pages/TrustFinder/TrustCards.vue';

export default {
    name: 'TrustFinder',
    components: { FilterBarFunctional, TrustCards },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        filterMappings() {
            const copiedProps = JSON.parse(JSON.stringify(this.fields.filterMappings.value));
            copiedProps.filters = { ...copiedProps.filters };
            Object.values(copiedProps.filters).forEach((obj) => {
                obj.options = obj.values;
                obj.id = obj.facetName;
                obj.isActive = true;
                delete obj.values;
                delete obj.facetName;
            });
            return copiedProps;
        },
        vuexData() {
            return {
                filterBar: {
                    state: {
                        namespace: 'trusts',
                        items: [
                            'filters',
                            'filtersForApprove',
                            'approvedFilters',
                            'filtersFacets',
                            'emptyFacets',
                            'loading',
                            'totalResults',
                            'articles',
                            'filtersForApprove',
                            'page',
                            'take',
                        ],
                    },
                    actions: {
                        namespace: 'trusts',
                        items: ['getArticles', 'getFacets', 'getEmptyFacets'],
                    },
                    mutations: {
                        namespace: 'trusts',
                        items: ['setFilters', 'setFiltersForApprove', 'setApprovedFilters', 'setFilterMappings'],
                    },
                },
            };
        },
    },
};
</script>

<style scoped></style>
