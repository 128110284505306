<template>
    <div class="background-to-change form-element" :class="{ ['form-element--error']: isError }">
        <component :is="wrapperTag" class="form-element__wrapper" tabindex="-1">
            <span class="form-element__label form-label d-block" :class="{ 'mb-0': !$slots['fieldLabelSlot'] }">
                <slot name="fieldLabelSlot" />
                <slot name="fieldDescriptionSlot" />
                <slot name="description" />
                <Tooltip v-if="$slots['tooltipSlot']">
                    <template #tooltipContent>
                        <span class="hint-text text-grey">{{ $slots['tooltipSlot'][0].text }}</span>
                    </template>
                </Tooltip>
            </span>
            <slot name="fieldSlot" />
        </component>
        <div v-if="(isError && $slots['errorSlot']) || $slots['hintSlot']" class="form-element__hints">
            <span v-if="isError" class="form-element__error field-error-text text-error-400">
                <slot name="errorSlot" />
            </span>
            <span v-if="$slots['hintSlot']" class="form-element__hint field-hint">
                <slot name="hintSlot" />
            </span>
        </div>
    </div>
</template>

<script>
import Tooltip from '@/components/Generic/FormElements/FormFields/Tooltip.vue';

export default {
    name: 'FormElemWrapper',
    components: { Tooltip },
    props: {
        isError: {
            type: Boolean,
            default: /* istanbul ignore next */ () => false,
        },
        wrapperTag: {
            type: String,
            default: () => 'label',
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixins';
label,
.form-element__wrapper,
select {
    width: 100%;
}
.form-element {
    position: relative;

    &__label {
        margin-bottom: var(--default-form-label-margin);
    }
    &--error {
        margin-bottom: 11px;
    }
    &__error {
        display: block;
        width: 100%;
        @include dark-backgrounds() {
            padding: 4px;
            background-color: $abrdn-white;
        }
    }
    &__hint {
        width: 100%;
        color: var(--button-night);
        @include dark-backgrounds() {
            color: var(--abrdn-white);
        }
    }
    &__hints {
        line-height: 1;
        margin-top: 12px;
    }
}
</style>
