export default {
    name: 'FilterHelper',
    methods: {
        getParams(categories, formattedMapping, page, take, language) {
            let filters = [];
            if (formattedMapping.filters) {
                filters = Object.keys(formattedMapping.filters)
                    .filter((item) => formattedMapping.filters[item].isActive)
                    .map((item) => {
                        return formattedMapping.filters[item].id;
                    });
            }
            const source = formattedMapping.articleSource;
            return {
                search: {
                    source,
                    contentGroup: formattedMapping.contentGroup || '',
                    language: language ? language : 'en-GB',
                },
                facets: filters,
                filters: filters.map((filter) => {
                    return { name: filter, values: categories && categories[filter]?.length ? categories[filter] : [] };
                }),
                take: take,
                skip: (page - 1) * take,
            };
        },
        prepareFilterCategories(approvedFilters) {
            const categories = {};
            approvedFilters.map((option) => {
                if (categories[option.group]) {
                    categories[option.group] = [...categories[option.group], option.id];
                } else {
                    categories[option.group] = [option.id];
                }
            });
            return categories;
        },
        generateUrl(approvedFilters, page, take) {
            /* We don't need to indicated preselected filters in the query */
            const approvedFiltersWithoutPreselected = approvedFilters.filter((filter) => !filter.preselected);
            const categories = this.prepareFilterCategories(approvedFiltersWithoutPreselected);
            let query = Object.keys(categories)
                .map((key, i) => {
                    const categoryKeyValue = this.prepareFilterCategories(approvedFilters);
                    if (categoryKeyValue) {
                        return `${i == 0 ? '?' : '&'}${key}=${encodeURIComponent(categoryKeyValue[key])}`;
                    } else {
                        return '';
                    }
                })
                .join('');

            if (page || take) {
                query = query ? query + `&page=${page}` : `?page=${page}`;
                if (page === 1) {
                    query = query.replace('?page=1', '');
                    query = query.replace('&page=1', '');
                }
                query = query ? query + `&take=${take}` : `?take=${take}`;

                if (take === 20) {
                    query = query.replace('?take=20', '');
                    query = query.replace('&take=20', '');
                }
            }

            history.pushState(null, null, `${query ? query : this.$route.path}`);
        },
    },
};

export const formatFiltersForReq = {
    methods: {
        formatFiltersForReq(filters, returnEmptyFilters) {
            const reqFacets = Object.values(filters).map((item) => item.id);
            const reqFilters = Object.values(filters).map((filter) => {
                return {
                    name: filter.id,
                    values: filter.options
                        .filter((option) => option.selected && !returnEmptyFilters)
                        .map((option) => option.id),
                };
            });
            return {
                reqFacets,
                reqFilters,
            };
        },
    },
};
export const getRequestParams = {
    methods: {
        getRequestParams(filters, language, { source, contentGroup }, returnEmptyFilters = false) {
            const { reqFacets, reqFilters } = formatFiltersForReq.methods.formatFiltersForReq(
                filters,
                returnEmptyFilters,
            );
            return {
                facets: reqFacets,
                filters: reqFilters,
                search: {
                    source,
                    contentGroup,
                    language,
                },
            };
        },
    },
};
