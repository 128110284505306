<template>
    <section
        v-if="!isExperienceEditor"
        :data-e2e-component-uid="rendering.uid"
        class="default-section-padding background-white"
    >
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-8 col-lg-9">
                    <h1 class="line-clamp-2">{{ title }}</h1>
                    <p class="body-large mt-2 text-grey line-clamp-3">{{ description }}</p>
                </div>
            </div>
        </div>
    </section>
    <EEPlaceholder v-else>Article Series Banner</EEPlaceholder>
</template>

<script>
import EEPlaceholder from '@/components/Sections/EEPlaceholder';

export default {
    name: 'ArticleSeriesBanner',
    components: {
        EEPlaceholder,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        title() {
            return this.fields?.name?.value;
        },
        description() {
            return this.fields?.description?.value;
        },
    },
};
</script>

<style scoped lang="scss"></style>
