<template>
    <div v-if="displayType === 'checkbox'" class="d-flex flex-column">
        <div v-if="optionList && optionList.elements">
            <MultipleCheckboxes
                :required="isMultipleCheckBoxesRequired"
                :optionList="optionList"
                :requiredErrorMessage="isMultipleCheckBoxesRequired ? requiredErrorMessage : ''"
                :instructions="instructions"
                :label="formattedFields.label.value"
                @setMultipleValue="setMultipleValue"
                @updateDataLayer.once="
                    executeFormStartEvent('executeFormStartEvent', 'form_start');
                    executeFormStartEvent('updateDataLayer', 'form_interaction');
                "
            />
        </div>
        <Checkbox
            v-else
            :required="isRequired"
            @onChange="setValue($event ? { value: 'on' } : { value: 'off' })"
            @updateDataLayer.once="
                executeFormStartEvent('executeFormStartEvent', 'form_start');
                executeFormStartEvent('updateDataLayer', 'form_interaction');
            "
        >
            <template #label>
                <p class="body-small">{{ name }}</p>
            </template>
            <template #error>{{ requiredErrorMessage }}</template>
            <template #instructions>{{ instructions }}</template>
        </Checkbox>
    </div>

    <AbrdnSelect
        v-else-if="displayType === 'singleSelect'"
        :fields="formattedFields"
        :shouldSetDefaultValue="true"
        @updateValue="setValue($event)"
        @updateDataLayer.once="executeFormStartEvent('updateDataLayer', 'form_interaction')"
        @onFocus="executeFormStartEvent('executeFormStartEvent', 'form_start')"
    />

    <AbrdnButton
        v-else-if="displayType === 'removeSubmission'"
        buttonType="secondary"
        type="button"
        @click.native="$emit('removeAsset')"
        >{{ name }}</AbrdnButton
    >

    <InputField
        v-else-if="['text', 'textArea', 'number'].includes(displayType)"
        :fields="formattedFields"
        :multiline="displayType === 'textArea'"
        :description="description"
        @updateValue="setValue($event)"
        @onFocus="executeFormStartEvent('executeFormStartEvent', 'form_start')"
        @onChange.once="executeFormStartEvent('updateDataLayer', 'form_interaction')"
    />

    <RadioButtonFieldset
        v-else-if="displayType === 'radio'"
        :fields="element"
        @updateValue="setValue($event)"
        @updateDataLayer.once="
            executeFormStartEvent('executeFormStartEvent', 'form_start');
            executeFormStartEvent('updateDataLayer', 'form_interaction');
        "
    />

    <Note v-else-if="displayType === 'note'" :fields="element" />

    <DatePicker
        v-else-if="displayType === 'datePicker'"
        :fields="element"
        :description="description"
        @updateValue="setDateValue($event)"
    />

    <MultiplePickList
        v-else-if="displayType === 'multiSelect'"
        :fields="formattedFields"
        :name="name"
        :required="isRequired"
        :optionList="optionList"
        @setSelectedValues="setSelectedValues"
        @applyOptions="$emit('applyOptions', $event)"
        @updateDataLayer.once="executeFormStartEvent('updateDataLayer', 'form_interaction')"
        @onFocus="executeFormStartEvent('executeFormStartEvent', 'form_start')"
        @deleteMultipleOptions="$emit('deleteMultipleOptions')"
        @deleteSingleOption="$emit('deleteSingleOption', $event)"
    />
</template>

<script>
import FormFieldsSharedLogic from '@/mixins/FormsFieldsSharedLogic.mixin';

export default {
    name: 'FormsSharedFields',
    mixins: [FormFieldsSharedLogic],
    props: {
        formName: {
            type: String,
            default: '',
        },
        formType: {
            type: String,
            default: 'salesforce',
        },
    },
    data() {
        return {
            selectedItems: [],
        };
    },
    computed: {
        fieldByFormType() {
            return this.formType === 'salesforce' ? 'id' : 'htmlName';
        },
        formField() {
            return this.fieldByFormType === 'id' ? this.id : this.htmlName;
        },
        isMultipleCheckBoxesRequired() {
            return this.isRequired && !this.selectedItems.length;
        },
    },
    methods: {
        setValue({ value, relatesTo }) {
            this.$emit('change', {
                [this.fieldByFormType]: this[this.fieldByFormType],
                value,
                name: this.name,
                relatesTo,
            });
        },
        setSelectedValues(selectedValues) {
            this.selectedItems = selectedValues;
            this.setMultiValuesUsingFieldNameApproach();
        },
        setMultipleValue(isSelected, name) {
            if (isSelected) {
                this.selectedItems.push(name);
            } else {
                this.selectedItems = this.selectedItems.filter((item) => item !== name);
            }
            this.setMultiValuesUsingFieldNameApproach();
            this.setMultiValuesUsingOnOffApproach(name, isSelected);
        },
        setMultiValuesUsingFieldNameApproach() {
            const fieldName = this[this.fieldByFormType];
            this.$emit('deleteSingleOption', { name: fieldName });
            this.selectedItems.forEach((value) => {
                this.$emit('appendValue', { fieldName, value });
            });
        },
        setMultiValuesUsingOnOffApproach(name, isSelected) {
            const stringSwitch = isSelected ? 'on' : 'off';
            this.$emit('change', { [this.fieldByFormType]: name, value: stringSwitch });
        },
        setDateValue(value) {
            this.$emit('change', value);
        },
        executeFormStartEvent(emitEvent, eventName) {
            this.$emit(emitEvent, {
                event: eventName,
                form_field: this.formField,
                formName: this.formName,
            });
        },
    },
};
</script>
