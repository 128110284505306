<template>
    <thead>
        <tr v-if="!showSkeleton">
            <th scope="col" class="section-header-small sticky-column">
                <div class="d-flex">
                    {{ $t('fundName') }}
                </div>
            </th>
            <th v-if="!hideShareclass" scope="col" class="section-header-small">
                <div class="d-flex">
                    {{ $t('kidShareClass') }}
                    <Tooltip
                        v-if="
                            $t('fundsFundCentreTooltipShareclass') &&
                            $t('fundsFundCentreTooltipShareclass') !== 'fundsFundCentreTooltipShareclass'
                        "
                        :arialLabelText="$t('kidShareClass')"
                    >
                        <template #tooltipTitle>
                            <p class="tooltip-title">
                                {{ $t('kidShareClass') }}
                            </p>
                        </template>
                        <template #tooltipContent>
                            <p class="tooltip-description">{{ $t('fundsFundCentreTooltipShareclass') }}</p>
                        </template>
                    </Tooltip>
                </div>
            </th>
            <th
                v-for="optionalColumn in columnsToShow.general"
                :key="optionalColumn.visibleName"
                scope="col"
                class="section-header-small"
            >
                <div class="d-flex">
                    {{ optionalColumn.visibleName }}
                    <Tooltip
                        v-if="
                            optionalColumn.propertyName === 'sfdrClassification' &&
                            $t('fundsFundCentreTooltipSfdr') &&
                            $t('fundsFundCentreTooltipSfdr') !== 'fundsFundCentreTooltipSfdr'
                        "
                        :arialLabelText="optionalColumn.visibleName"
                    >
                        <template #tooltipTitle>
                            <p class="tooltip-title">
                                {{ optionalColumn.visibleName }}
                            </p>
                        </template>
                        <template #tooltipContent>
                            <p class="tooltip-description">{{ $t('fundsFundCentreTooltipSfdr') }}</p>
                        </template>
                    </Tooltip>
                </div>
            </th>
            <th
                v-for="optionalColumn in columnsToShow.shareclassSpecific"
                :key="optionalColumn.visibleName"
                scope="col"
                class="section-header-small"
            >
                {{ optionalColumn.visibleName }}
            </th>
        </tr>
        <tr v-else class="skeleton-row">
            <th v-for="(item, idx) in 6" :key="idx" scope="col" class="section-header-small">
                <BaseSkeleton />
            </th>
        </tr>
    </thead>
</template>

<script>
import { mapState } from 'vuex';

import BaseSkeleton from '@/components/Generic/BaseSkeleton.vue';
import Tooltip from '@/components/Generic/FormElements/FormFields/Tooltip';

export default {
    name: 'FundsThead',
    components: { BaseSkeleton, Tooltip },
    props: {
        columnsToShow: {
            type: Object,
            required: true,
        },
        showSkeleton: {
            type: Boolean,
            default: false,
        },
        hideShareclass: {
            type: Boolean,
            default: () => false,
        },
    },
    computed: {
        ...mapState('allFunds', ['isLoading']),
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
@include media-breakpoint-up(xl) {
    tr {
        border-top: var(--default-table-border);
        &.skeleton-row {
            border-top-color: transparent;
        }
    }
    th {
        white-space: nowrap;
    }
}
.scrollable {
    .sticky-column {
        background-color: var(--abrdn-white);
        z-index: 1;
    }
}
</style>
