<template>
    <section class="py-2 background-island overflow-auto">
        <div class="container">
            <div class="d-flex gap-3">
                <button
                    v-for="tab in tabs"
                    :key="tab.key"
                    class="text-nowrap"
                    :class="{ active: tab.key === activeTabKey }"
                    @click="$emit('tabClick', tab.key)"
                >
                    {{ tab.title }}
                </button>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'FirstLevelTabs',

    props: {
        tabs: {
            type: Array,
            default: () => [],
        },
        activeTabKey: {
            type: String,
            default: '',
        },
    },
};
</script>

<style scoped lang="scss">
button {
    background: none;
    border: none;
    border-bottom: calc(var(--spacer) * 0.5) solid transparent;
    padding: 0;
    padding-bottom: calc(var(--spacer) * 1);
    font-weight: 600;
    font-size: 20px;
    line-height: calc(var(--spacer) * 3);
    letter-spacing: 0.02em;
    color: var(--button-mist);
    cursor: pointer;
    transition-property: color, border-bottom-color;
    transition-duration: 0.2s;

    &:hover,
    &:focus {
        border-bottom-color: currentColor;
    }

    &.active {
        color: var(--abrdn-white);
        border-bottom-color: currentColor;
    }
}
</style>
