<template>
    <div
        :id="anchorId"
        :data-e2e-component-uid="rendering.uid"
        class="highlight-block py-3 mt-5 col-xl-8"
        :class="{ [params.background]: params.background }"
    >
        <div class="container px-3">
            <ScText
                v-if="isExperienceEditor || (fields && fields.heading)"
                tag="h3"
                class="section-header-large mb-4"
                :field="fields.heading"
            />
            <ScText tag="p" class="body-default text-grey" :field="fields.description" />
            <Placeholder :rendering="rendering" name="abrdn-highlight-block" class="mt-3" />
        </div>
    </div>
</template>

<script>
import { Placeholder, Text } from '@sitecore-jss/sitecore-jss-vue';

export default {
    name: 'HighlightBlock',
    components: {
        ScText: Text,
        Placeholder,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        params: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables';
@import '@/assets/styles/mixins';
.section-header-large,
.body-default {
    @include dark-backgrounds('.highlight-block') {
        color: var(--abrdn-white);
    }
}

.highlight-block {
    ::v-deep * + .abrdn-list-root {
        margin-top: calc(var(--spacer) * 6) !important;
    }
    ::v-deep .abrdn-list-root + img {
        margin-top: calc(var(--spacer) * 3) !important;
    }

    ::v-deep img {
        width: 100%;
    }
}
</style>
