<template>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.7614 9.42872L12.5761 14.7549C12.2579 15.0817 11.7421 15.0817 11.4239 14.7549L6.23864 9.42872C5.92045 9.10188 5.92045 8.57197 6.23864 8.24513C6.55684 7.91829 7.07272 7.91829 7.39091 8.24513L12 12.9807L16.6091 8.24513C16.9273 7.91829 17.4432 7.91829 17.7614 8.24513C18.0795 8.57197 18.0795 9.10188 17.7614 9.42872Z"
            fill="currentColor"
        />
    </svg>
</template>

<script>
export default {
    name: 'IconChevronSecondLevelTabs',
};
</script>
