<template>
    <div v-if="tbodyData && tbodyData.length">
        <div class="position-relative">
            <div
                id="discretePerformanceWrapper"
                class="abrdn-table"
                :class="[{ scrollable: isScrollable }, { [gradientPosition]: gradientPosition }]"
                tabindex="0"
            >
                <table
                    id="discretePerformanceTable"
                    :aria-label="$t('fundDetailsPerformanceDiscreteTitle')"
                    class="not-striped"
                >
                    <thead>
                        <tr>
                            <th scope="col" class="sticky-column" />
                            <th v-for="header in theadData" :key="header" scope="col">{{ header }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="row in tbodyData" :key="row.header">
                            <th scope="row" class="sticky-column">{{ row.header }}</th>
                            <td v-for="data in row.data" :key="data.header">{{ data.value }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div v-if="footnote" class="mt-1 footnote text-grey">{{ footnote }}</div>

        <CustomScroll
            v-if="isScrollable"
            :deviceType="deviceType"
            wrapperId="discretePerformanceWrapper"
            tableId="discretePerformanceTable"
            @changeGradientPosition="changeGradientPosition"
        />
    </div>
    <div v-else class="body-default">{{ $t('noResultsMessage') }}</div>
</template>

<script>
import CustomScroll from '@/components/Functional/CustomScroll';
import { standardFormat } from '@/constants/dateFormats';
import DeviceType from '@/mixins/DeviceType.mixin';
import { localizeDate } from '@/mixins/Helpers.mixin';

export default {
    name: 'FundDetailsDiscretePerformanceTable',

    components: {
        CustomScroll,
    },

    mixins: [localizeDate, DeviceType],

    props: {
        tabData: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            gradientPosition: 'gradient-right',
        };
    },

    computed: {
        theadData() {
            const firstRow = this.tabData?.performanceSet?.[0];
            return firstRow?.values?.map((column) => column?.performanceTimePeriod);
        },

        tbodyData() {
            return this.tabData?.performanceSet?.map((row) => ({
                header: row?.performanceLabel,
                data: row?.values?.map((column) => ({
                    header: column?.performanceTimePeriod,
                    value: column?.value || this.$t('notAvailableShortMessage'),
                })),
            }));
        },

        isScrollable() {
            if (this.tabData === null) return false;
            if (this.deviceType === 'mobile' || this.deviceType === 'tablet') {
                return this.tabData.performanceSet[0].values.length >= 2;
            } else {
                return this.tabData.performanceSet[0].values.length > 4;
            }
        },

        footnote() {
            const lastRelevantDate = this.tabData?.lastRelevantDate;
            if (!lastRelevantDate) return;
            return `${this.$t('fundDetailsPerformanceCumulativeFootnote')} ${this.localizeDate(
                lastRelevantDate,
                standardFormat,
            )}`;
        },
    },

    methods: {
        changeGradientPosition(val) {
            this.gradientPosition = val;
        },
    },
};
</script>

<style lang="scss" scoped>
.abrdn-table tbody tr:last-child {
    td,
    th {
        border-bottom: none;
    }
}
</style>
