var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"abrdn-list-root"},[_c('ul',{staticClass:"abrdn-list mb-0",class:( _obj = {}, _obj[_vm.params.style] = _vm.params.style, _obj['icon-style'] =  _vm.iconStyle, _obj['image-style'] =  _vm.imageStyle || _vm.svgExist, _obj['circle-style'] =  !_vm.iconStyle && !_vm.imageStyle && !_vm.svgExist, _obj['ordered-style'] =  _vm.orderedStyle, _obj )},_vm._l((_vm.filteredArray),function(item){
        var _obj;
return _c('li',{key:item.id},[_c('span',{staticClass:"circle background-to-change"}),(!_vm.orderedStyle && (_vm.iconStyle || _vm.imageStyle || _vm.svgExist))?_c('div',{staticClass:"icon me-2"},[(_vm.iconStyle && (!_vm.imageStyle || !_vm.svgExist))?_c('em',{class:( _obj = {}, _obj['bi-' + _vm.fields.icon.value] = true, _obj )}):_vm._e(),((_vm.imageStyle || _vm.svgExist) && !_vm.iconStyle)?_c('WrappedImage',{staticClass:"image background-to-change",attrs:{"media":_vm.getImage(item)}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"list-item-text"},[(_vm.isExperienceEditor || (item.fields && item.fields.title && item.fields.title.value))?_c('ScRichText',{staticClass:"body-default background-to-change",attrs:{"field":item.fields.title}}):_vm._e(),(
                        _vm.isExperienceEditor ||
                        (item.fields && item.fields.description && item.fields.description.value)
                    )?_c('ScRichText',{staticClass:"description body-small",attrs:{"field":item.fields.description}}):_vm._e()],1)])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }