<template>
    <section
        v-if="inBody || isExperienceEditor"
        :data-e2e-component-uid="rendering.uid"
        :class="{
            'short-section my-4 my-xl-7': true,
            'padding-zero': !inBody && isExperienceEditor,
        }"
    >
        <RiskWarningContent :fields="fields" :rendering="rendering" />
    </section>
    <RiskWarningContent
        v-else-if="(!inBody && amountOfRiskWarningElements === 1) || isExperienceEditor"
        :data-e2e-component-uid="rendering.uid"
        :fields="fields"
        :rendering="rendering"
    />
</template>

<script>
import { mapMutations, mapState } from 'vuex';

import RiskWarningContent from './RiskWarningContent.vue';

export default {
    name: 'RiskWarning',
    components: {
        RiskWarningContent,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    data() {
        return {
            riskWarningNumber: null,
        };
    },
    computed: {
        ...mapState('riskWarning', ['amountOfRiskWarningElements']),
        inBody() {
            return this.rendering?.params ? true : false;
        },
    },
    mounted() {
        this.setAmount(this.amountOfRiskWarningElements + 1);
    },
    beforeDestroy() {
        this.setAmount(0);
    },
    methods: {
        ...mapMutations('riskWarning', ['setAmount']),
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
.risk-warning__additional-toggler {
    display: flex;
    align-items: center;
    .chevron {
        margin-left: 14px;
        svg {
            transform: rotate(90deg);
            transition: transform 0.3s;
        }
    }
    .less {
        display: none;
    }
    &:not(.collapsed) {
        svg {
            transform: rotate(-90deg);
        }
        .more {
            display: none;
        }
        .less {
            display: inline;
        }
    }
}
.risk-warning-wrapper {
    padding: 0 25px;
}
@include media-breakpoint-down(xl) {
    .risk-warning-wrapper {
        padding: 0 8px;
    }
}
@include media-breakpoint-down(sm) {
    .container {
        padding: 0;
        width: calc(100% - 30px);
        margin: 0 15;
    }
    .risk-warning-wrapper {
        padding: 0 15px;
    }
}

.padding-zero {
    padding: 0 !important;
}
</style>
