<template>
    <section :id="anchorId" :class="backgroundClass" class="py-4 py-lg-7" :data-e2e-component-uid="rendering.uid">
        <div class="container w-100 pt-1 pt-md-5 pt-lg-6">
            <div class="row">
                <div class="col-12 col-md-8 col-lg-9">
                    <ScText :field="fields.title" tag="h1" class="background-to-change" />
                    <ScText :field="fields.subtitle" class="body-large background-to-change mt-2" tag="p" />
                    <AbrdnButton
                        v-if="isValueExists(fields.cta)"
                        :buttonType="'textLink'"
                        class="mt-3 background-to-change"
                        :linkField="fields.cta"
                    />
                </div>
                <div class="d-none d-md-flex col-md-4 col-lg-3 col-3 d-flex justify-content-end">
                    <WrappedImage
                        :media="fields.icon"
                        class="background-to-change tertiary-hero-banner-image"
                        :srcSet="[{ mw: 200 }, { mw: 300 }, { mw: 400 }]"
                        sizes="(max-width: 991px) 144px, 192px"
                        :lazy="false"
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton.vue';
import WrappedImage from '@/components/Generic/SitecoreWrappers/WrappedImage.vue';

export default {
    name: 'TertieryHeroBanner',
    components: {
        ScText: Text,
        AbrdnButton,
        WrappedImage,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        backgroundClass() {
            return this.rendering?.params?.background || 'background-digital-deep-grey';
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';

::v-deep p {
    white-space: pre-line;
}

.tertiary-hero-banner-image {
    width: 100%;
    max-width: 192px;
    @include media-breakpoint-down(lg) {
        max-width: 144px;
    }
    ::v-deep svg,
    ::v-deep img {
        width: 100%;
        height: auto;
    }
}
</style>
