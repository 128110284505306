<template>
    <StateDrivenSlotDropdown
        class="dropdown me-1"
        :class="{ 'dropdown--expanded': isDropdownOpen }"
        :isOpen="isDropdownOpen"
        :showActionButtons="true"
        buttonsLayout="justify-content-between"
        @outsideClick="toggleDropdown"
        @lostFocus="isDropdownOpen = false"
        @closeDropdown="toggleDropdown()"
        @cancelEvent="
            toggleDropdown();
            $emit('clearFilterSelection', filter);
            $emit('filterApply');
        "
        @confirmEvent="
            toggleDropdown();
            $emit('filterApply');
        "
    >
        <template #trigger-area>
            <div>
                <p class="form-label mb-1.5">{{ filter.displayName }}</p>
                <button
                    ref="filterButton"
                    type="button"
                    class="d-flex align-items-center filter-selector btn btn-naked"
                    :aria-expanded="isDropdownOpen ? 'true' : 'false'"
                    :aria-label="ariaLabel"
                    @click="toggleDropdown"
                    @keydown.down.prevent="selectFirstFilterOption"
                >
                    <span class="field-text" :class="[filterButtonTextColor]">{{ filterPlaceholder }}</span>
                    <span class="ms-auto"><em class="d-flex bi-chevron-down" aria-hidden="true" /></span>
                </button>
            </div>
        </template>
        <div class="filter-dropdown-window d-flex flex-column position-relative">
            <div ref="filterDropdownOptionsWrapper" class="filter-dropdown-options-wrapper" tabindex="-1">
                <FilterDropdownOption
                    v-for="filterOption in filterOptions"
                    :key="filterOption.id"
                    ref="options"
                    :isVisible="isDropdownOpen"
                    :filterOption="filterOption"
                    :isLoading="isLoading"
                    @click.native="$emit('optionSelect', filterOption)"
                    @keyup.enter.native="$emit('optionSelect', filterOption)"
                    @keydown.space.native="$emit('optionSelect', filterOption)"
                    @selectFilterOptionWithArrow="selectFilterOptionWithArrow"
                />
            </div>
        </div>
    </StateDrivenSlotDropdown>
</template>

<script>
import FilterDropdownOption from '@/components/Functional/Filters/FilterDropdownOption.vue';
import StateDrivenSlotDropdown from '@/components/Generic/StateDrivenSlotDropdown.vue';

export default {
    name: 'FilterDropdown',
    components: { FilterDropdownOption, StateDrivenSlotDropdown },
    props: {
        filter: {
            type: Object,
            default: () => ({}),
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isDropdownOpen: false,
        };
    },
    computed: {
        filterPlaceholder() {
            if (this.selectedOptions.length) {
                return `${this.selectedOptions.length} ${
                    this.selectedOptions.length === 1 ? this.$t('filterSelected') : this.$t('filtersSelected')
                }`;
            } else {
                return this.$t('selectFilter');
            }
        },
        selectedOptions() {
            return this.filter.options.filter((option) => option.selected);
        },
        filterOptions() {
            return this.filter.options.filter((option) => option.aggregateCount !== null);
        },
        filterButtonTextColor() {
            return !this.selectedOptions.length && !this.isDropdownOpen ? 'text-dark-grey' : 'text-abrdn-black';
        },
        ariaLabel() {
            return `${this.filter.displayName}. ${this.selectedOptions.length} ${this.$t('filtersApplied')}.`;
        },
    },
    watch: {
        isDropdownOpen: {
            handler: function (val) {
                if (val) {
                    this.$emit('needAggregateCount');
                } else {
                    this.$emit('resetUnAppliedOptions');
                }
            },
        },
    },
    methods: {
        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
            this.$nextTick(() => {
                this.$refs.filterButton.focus();
            });
        },

        selectFilterOptionWithArrow(direction) {
            const currentFocusedElement = document.activeElement;

            if (direction === 'down') {
                let nextElement = currentFocusedElement?.nextElementSibling;
                while (nextElement && nextElement?.classList.contains('disabled')) {
                    nextElement = nextElement?.nextElementSibling;
                }
                nextElement && nextElement.focus();
            } else if (direction === 'up') {
                let previousElement = currentFocusedElement?.previousElementSibling;
                while (previousElement && previousElement?.classList.contains('disabled')) {
                    previousElement = previousElement?.previousElementSibling;
                }
                previousElement && previousElement.focus();
            }
        },
        selectFirstFilterOption() {
            if (!this.isDropdownOpen) return;
            const firstOption = this.$refs.options[0].$el;

            firstOption.focus();
        },
    },
};
</script>
