<template>
    <section
        :id="anchorId"
        class="primary-hero-banner-section d-flex flex-column"
        :data-e2e-component-uid="rendering.uid"
    >
        <Animation
            v-if="isAnimationVisible"
            :loop="false"
            class="background-video-wrapper"
            :animationData="desktopAnimation"
            :scaleSettings="scaleSettings"
        />
        <div v-else class="background-image-wrapper">
            <WrappedImage
                :media="fields.backgroundImage"
                :srcSet="[
                    { mw: 400 },
                    { mw: 600 },
                    { mw: 800 },
                    { mw: 1000 },
                    { mw: 1200 },
                    { mw: 1400 },
                    { mw: 1600 },
                    { mw: 1800 },
                    { mw: 2000 },
                ]"
                sizes="100vw"
                :lazy="false"
            />
        </div>
        <div class="container text-block mb-md-n7 mt-n5 mt-md-auto">
            <div class="row w-100 pe-3 pe-md-0">
                <div
                    class="inner-wrapper background-white col-12 col-md-12 col-lg-7 pe-md-4 py-4 pb-md-6 pt-xl-7 pb-xl-8 pe-xl-7"
                >
                    <ScText :field="fields.title" tag="h1" />
                    <ScText
                        v-if="fields.subtitle && fields.subtitle.value"
                        :field="fields.subtitle"
                        class="mt-2 body-large"
                        tag="p"
                    />
                    <div
                        v-if="isValueExists(fields.primaryCtaLink) || isValueExists(fields.secondaryCtaLink)"
                        class="links-wrapper d-flex mt-5"
                    >
                        <AbrdnButton
                            v-if="isValueExists(fields.primaryCtaLink)"
                            :buttonType="'primary'"
                            :linkField="fields.primaryCtaLink"
                            class="mt-3 me-sm-3 mt-md-0"
                        />
                        <AbrdnButton
                            v-if="isValueExists(fields.secondaryCtaLink)"
                            :buttonType="'secondary'"
                            class="mt-3 mt-md-0"
                            :linkField="fields.secondaryCtaLink"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="break-line background-white pt-4 pt-md-7 pt-xl-7" />
    </section>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import Animation from '@/components/Generic/Animation';
import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton.vue';
import WrappedImage from '@/components/Generic/SitecoreWrappers/WrappedImage.vue';
import deviceType from '@/mixins/DeviceType.mixin';

export default {
    name: 'PrimaryHeroBanner',
    components: {
        AbrdnButton,
        WrappedImage,
        Animation,
        ScText: Text,
    },
    mixins: [deviceType],
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        route: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        desktopAnimation() {
            return this.fields.backgroundAnimation?.value;
        },
        scaleSettings() {
            return {
                preserveAspectRatio: 'xMaxYMax slice',
            };
        },
        isAnimationVisible() {
            return this.desktopAnimation && (this.deviceType === 'desktop' || this.deviceType === 'small-desktop');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';

section {
    position: relative;
}
.break-line {
    @include media-breakpoint-down(md) {
        display: none;
    }
}
.links-wrapper {
    @include media-breakpoint-down(md) {
        flex-direction: column;
        align-items: flex-start;
        width: auto;
    }
}

.primary-hero-banner-section {
    height: 576px;
    @include media-breakpoint-down(lg) {
        height: 712px;
    }
    @include media-breakpoint-down(md) {
        height: auto;
    }
}
.background-image-wrapper,
.background-video-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 512px;
    ::v-deep img {
        object-fit: cover;
        object-position: right;
        height: 100%;
        width: 100%;
    }
    @include media-breakpoint-down(lg) {
        height: 624px;
    }
    @include media-breakpoint-down(md) {
        height: 288px;
        position: static;
    }
}

.text-block {
    display: flex;
    align-items: flex-end;
    position: relative;
    .inner-wrapper {
        position: relative;
        z-index: 1;

        &:before {
            content: '';
            width: 121px;
            position: absolute;
            z-index: 0;
            display: block;
            left: -120px;
            top: 0;
            height: 100%;
            background: inherit;
            @include media-breakpoint-down(lg) {
                width: 161px;
                left: -160px;
            }
        }
    }
}
</style>
