<template>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
        <path
            d="M22.3249 3H1.6648C0.744507 3 0 3.75173 0 4.68096V20.2795C0 21.2087 0.744507 21.9604 1.6648 21.9604H22.3352C23.2555 21.9604 24 21.2087 24 20.2795V4.68096C24 3.75173 23.2452 3 22.3249 3ZM2.06807 6.29928L8.53081 12.3654L2.06807 18.5463V6.29928ZM11.9948 12.7726L3.80526 5.08815H20.2981L11.9948 12.7726ZM10.0405 13.7853L10.8677 14.5579C11.1779 14.8503 11.5916 15.0069 11.9948 15.0069C12.3981 15.0069 12.8014 14.8607 13.1116 14.5684L13.9492 13.7957L20.3085 19.8723H3.68117L10.0405 13.7853ZM15.4795 12.3862L21.9319 6.41413V18.5463L15.4795 12.3862Z"
            fill="currentColor"
        />
    </svg>
</template>

<script>
export default {
    name: 'IconEnvelope',
};
</script>

<style scoped></style>
