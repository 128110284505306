<template>
    <div class="funds-filter px-2 py-5 px-md-5" :class="vectorBasedBackground">
        <FundsSearch />
        <BaseFilter
            v-if="formattedFilters.length"
            class="mt-4 mt-lg-5"
            :filters="formattedFilters"
            :isLoading="isFiltersLoading"
            @needAggregateCount="fetchFacets"
            @filterApply="onFilterApply"
        />
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

import { getFundsFacets } from '@/api/funds';
import BaseFilter from '@/components/Functional/Filters/BaseFilter.vue';
import FundsSearch from '@/components/Pages/FundsCenter/AllFunds/FundsFilters/FundsSearch/FundsSearch.vue';
import { formatFiltersForReq } from '@/mixins/ArticleFilter/FilterHelper.mixin';
import FiltersSection from '@/mixins/FiltersSection.mixin';

export default {
    name: 'FundsFiltersSection',
    components: { BaseFilter, FundsSearch },
    mixins: [formatFiltersForReq, FiltersSection],
    props: {
        filters: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        ...mapState('allFunds', ['requestPayloadAssets', 'searchQuery']),
    },
    methods: {
        ...mapMutations('allFunds', ['setFilters']),
        async fetchFacets(filterId) {
            const { reqFacets, reqFilters } = this.formatFiltersForReq(this.formattedFilters);

            const reqFiltersWithOutSameFilterId = reqFilters.map((obj) => {
                if (obj.name === filterId) {
                    return { name: obj.name, value: [] };
                }
                return obj;
            });

            const payload = {
                ...this.requestPayloadAssets,
                ...{ searchQuery: this.searchQuery },
                ...{
                    filters: reqFiltersWithOutSameFilterId,
                },
                ...{ facets: reqFacets },
            };
            this.isFiltersLoading = true;
            try {
                const { data } = await getFundsFacets(payload);
                this.updateAggregateCount(data.facets);
            } catch (e) {
                console.error(e);
            } finally {
                this.isFiltersLoading = false;
            }
        },
        updateAggregateCount(facets) {
            this.formattedFilters.forEach((filter) => {
                const sameFacet = facets.find((facet) => facet.name === filter.id) || { values: [] };
                filter.options.forEach((option) => {
                    option.aggregateCount =
                        sameFacet.values.find((facetOption) => facetOption.name === option.name)?.aggregateCount || 0;
                });
            });
        },
        onFilterApply() {
            const { reqFilters } = this.formatFiltersForReq(this.formattedFilters);
            this.setFilters(reqFilters);
        },
    },
};
</script>
