<template>
    <section
        :id="anchorId"
        :data-e2e-component-uid="rendering.uid"
        class="container default-section-padding background-white"
    >
        <DesktopTable
            :isLoading="loading"
            :rows="rows"
            class="d-none d-xl-flex"
            @updateRows="updateRows"
            @togglePastPerformance="togglePastPerformance"
            @togglePredictivePerformance="togglePredictivePerformance"
        />
        <MobileTable
            :rows="rows"
            :isLoading="loading"
            class="d-flex d-xl-none"
            @updateRows="updateRows"
            @togglePredictivePerformance="togglePredictivePerformance"
            @togglePastPerformance="togglePastPerformance"
        />
        <div v-if="showTable" class="d-flex-coulmn mb-0 mb-md-1 mb-xl-3 w-100 relative">
            <div class="d-flex flex-column-reverse flex-xl-row justify-content-between align-items-center">
                <p class="footnote text-grey">
                    {{
                        $t('paginationDisplayingResults')
                            .replace('{token.range}', `${getResultFrom} - ${getResultTo}`)
                            .replace('{token.total}', `${totalResults}`)
                    }}
                </p>
                <BackToTopButton class="mx-0 my-3 my-lg-4" />
            </div>
            <Pagination
                :currentPage="currentPage"
                :maxPages="lastPageNumber"
                class="mt-4 justify-content-center"
                @onSelect="onSelect"
            />
        </div>
        <Modal
            :renderContent="modalsState.pastPerformanceModal"
            :isModalOpen="modalsState.pastPerformanceModal"
            :allowOuterOverflow="true"
            @closeModal="togglePastPerformance"
        >
            <PastPerformanceModalContent
                :shareClass="selectedShareClass"
                :countryCode="countryInvestorsCode"
                @closeModal="togglePastPerformance"
            />
        </Modal>
        <Modal
            :renderContent="modalsState.predictivePerformance"
            :isModalOpen="modalsState.predictivePerformance"
            :allowOuterOverflow="true"
            @closeModal="togglePredictivePerformance"
        >
            <PredictivePerformance
                v-if="selectedShareClass"
                :selectedRow="selectedShareClass"
                :fields="fields"
                @closeModal="togglePredictivePerformance"
            />
        </Modal>
    </section>
</template>

<script>
import { getTableData } from '@/api/kidhub';
import BackToTopButton from '@/components/Generic/Buttons/BackToTopButton.vue';
import Pagination from '@/components/Includes/Pagination.vue';
import Modal from '@/components/Modals/Modal.vue';

import DesktopTable from './DesktopTable.vue';
import MobileTable from './MobileTable.vue';
import PastPerformanceModalContent from './PastPerformanceModalContent.vue';
import PredictivePerformance from './PredictivePerformance.vue';

export default {
    name: 'KidsTable',
    components: {
        DesktopTable,
        MobileTable,
        Pagination,
        BackToTopButton,
        PastPerformanceModalContent,
        Modal,
        PredictivePerformance,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    data() {
        return {
            results: null,
            currentPage: 1,
            skip: 0,
            selectedRow: null,
            loading: false,
            modalsState: {
                pastPerformanceModal: false,
                predictivePerformance: false,
            },
            resultFrom: null,
            resultTo: null,
        };
    },
    computed: {
        selectedShareClass() {
            return this.selectedRow?.shareclasses[this.selectedRow.selected];
        },
        countryInvestorsCode() {
            return this.fields?.countries?.fields?.countryCode?.value;
        },
        apiData() {
            return {
                CountryInvestors: {
                    CountryCode: this.countryInvestorsCode,
                    InvestorType: this.getInvestorTypes,
                    Jurisdiction: this.fields?.jurisdictions?.fields?.status?.value,
                },
                Skip: this.skip,
                Take: this.fields?.itemsPerPage?.value,
                Language: this.$jss?.sitecoreContext()?.displayLanguage,
            };
        },
        rows() {
            return (
                this.results?.fundLiterature.map((row) => {
                    return {
                        ...row,
                        showDownloadButton:
                            !!row.shareclasses.length && !!row.shareclasses[row.selected].kid ? true : false,
                    };
                }) || []
            );
        },
        getInvestorTypes() {
            return this.fields?.investorTypes
                .map((type) => {
                    return type.fields?.id?.value;
                })
                .join();
        },
        showTable() {
            return this.rows?.length;
        },
        lastPageNumber() {
            return this.results?.lastPageNumber + 1;
        },
        totalResults() {
            return this.results?.resultCount || 'X';
        },
        getResultFrom() {
            return this.resultFrom || 'X';
        },
        getResultTo() {
            return this.resultTo || 'X';
        },
    },
    mounted() {
        if (!this.isExperienceEditor) this.apiCall();
    },
    methods: {
        apiCall() {
            this.loading = true;
            getTableData(this.apiData).then((res) => {
                this.results = res.data;
                if (this.results?.fundLiterature?.length) {
                    this.results.fundLiterature.map((row) => {
                        row.selected = 0;
                    });
                }
                this.resultFrom = this.fields?.itemsPerPage?.value * (this.currentPage - 1) + 1;
                this.resultTo = this.fields?.itemsPerPage?.value * (this.currentPage - 1) + this.rows?.length;
                this.loading = false;
            });
        },
        updateRows(val) {
            this.results.fundLiterature = val;
        },
        onSelect(currentPage) {
            if (!this.loading) {
                this.currentPage = currentPage;
                this.skip = this.fields?.itemsPerPage?.value * (currentPage - 1);
                this.apiCall();
            }
        },
        togglePastPerformance(selectedRow) {
            this.selectedRow = selectedRow;
            this.modalsState.pastPerformanceModal = !this.modalsState.pastPerformanceModal;
        },
        togglePredictivePerformance(selectedRow) {
            this.selectedRow = selectedRow;
            this.modalsState.predictivePerformance = !this.modalsState.predictivePerformance;
        },
    },
};
</script>

<style scoped lang="scss"></style>
