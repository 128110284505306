<template>
    <div :data-e2e-component-uid="e2eId" class="promoted-content" :class="{ 'promoted-content--wide': isWide }">
        <div class="p-2 background-primary">
            <ScText class="background-to-change" tag="h6" :field="fields.title" />
            <WrappedImage
                class="promoted-content__image mt-2"
                :media="fields.image"
                :srcSet="[{ mw: 300 }, { mw: 400 }, { mw: 500 }]"
                sizes="238px"
            />
            <ScText class="body-small mt-2 background-to-change" tag="p" :field="fields.description" />
            <ScLink
                class="text-link revert-underline mt-2 d-inline-block background-to-change"
                :field="fields.ctaLink"
                @click.native="$emit('clickHandler', fields.ctaLink)"
            />
        </div>
    </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import ScLink from '@/components/Generic/SitecoreWrappers/ScLink.vue';
import WrappedImage from '@/components/Generic/SitecoreWrappers/WrappedImage.vue';

export default {
    name: 'PromotedContent',
    components: {
        WrappedImage,
        ScText: Text,
        ScLink,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => {},
        },
        isWide: {
            type: Boolean,
            default: /* istanbul ignore next */ () => false,
        },
        e2eId: {
            type: String,
            default: '',
        },
    },
};
</script>

<style scoped lang="scss">
.promoted-content {
    height: 100%;
}
.promoted-content__image {
    width: 100%;
    max-height: 100px;
    object-fit: cover;
    ::v-deep img {
        width: 100%;
        max-height: 100px;
        object-fit: cover;
    }
}
.promoted-content--wide .promoted-content__image {
    max-height: 134px;
    ::v-deep img {
        width: 100%;
        max-height: 100px;
        object-fit: cover;
    }
}
</style>
