<script>
import { mapState } from 'vuex';

import fundRangesMap from '@/constants/fundRangesMap';
import { formatCurrency, formatNumber } from '@/mixins/Helpers.mixin';

export default {
    name: 'StripedTableHelper',
    mixins: [formatCurrency, formatNumber],
    computed: {
        ...mapState('fundDetails', ['fundHeroType']),

        fundRange() {
            return fundRangesMap[this.fundHeroType];
        },

        countryCode() {
            return this.$jss.sitecoreContext().fundsConfiguration.countryInvestors.countryCode;
        },

        tableColumnHeadings() {
            const rowsColumnData = [];

            this.tabData?.results.forEach((item) => {
                item.columns.forEach((col) => {
                    rowsColumnData.push({ label: col.columnLabel, order: col.columnDisplayOrder });
                });
            });

            rowsColumnData.sort((a, b) => a.order - b.order);

            return new Set(rowsColumnData.map((col) => col.label));
        },

        tableData() {
            return {
                tableHeaderLabels: this.tableColumnHeadings,
                tableBodyRowLabels: this.tabData.results.map((data) => {
                    return {
                        rowLabel: data.rowLabel,
                        values: this.formatTableValues(data.columns),
                    };
                }),
            };
        },
    },
    methods: {
        applyGeneralFormattingRules(col) {
            if (col.columnRowValue === '') {
                return this.$t('notAvailableShortMessage');
            } else if (col.columnRowValueType === 'Percentage') {
                return `${this.formatNumber(+col.columnRowValue)}%`;
            } else if (col.columnRowValueType === 'Decimal') {
                return this.formatNumber(+col.columnRowValue);
            } else if (col.columnRowValueType === 'Text') {
                return col.columnRowValue;
            } else {
                return col.columnRowValue;
            }
        },
    },
};
</script>
