<template>
    <section
        :id="anchorId"
        :data-e2e-component-uid="rendering.uid"
        class="default-section-padding"
        :class="backgroundClass"
    >
        <div class="container">
            <div>
                <Placeholder :rendering="rendering" name="abrdn-tiles-heading" :class="{ 'mb-4': hasAnyFund }" />
            </div>
            <div>
                <Placeholder
                    class="row margin-top-row"
                    :rendering="clonedRendering"
                    :wrapperCols="4"
                    name="abrdn-fund-in-focus"
                />
            </div>
            <AbrdnButton
                v-if="viewMoreLink"
                buttonType="primary"
                :linkField="viewMoreLink"
                :showExternalIcon="false"
                class="funds-in-focus__view-more mt-4"
            >
                <span>{{ $t('visitFundCentre') }}</span>
                <IconArrowRight aria-hidden="true" />
            </AbrdnButton>
        </div>
    </section>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';

import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton.vue';
import IconArrowRight from '@/components/Icons/icon-arrow-right.vue';
import { backgroundClass } from '@/mixins/Helpers.mixin';

export default {
    name: 'FundsInFocus',
    components: {
        IconArrowRight,
        AbrdnButton,
        Placeholder,
    },
    mixins: [backgroundClass],
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        //We should filter and remove empty funds from the rendering. Props modifying is a bad practice, so we need to deep-clone the rendering object.
        clonedRendering() {
            if (
                this.isExperienceEditor ||
                !this.rendering.placeholders['abrdn-fund-in-focus'] ||
                !this.rendering.placeholders['abrdn-fund-in-focus'].length
            )
                return this.rendering;
            const clonedRendering = JSON.parse(JSON.stringify(this.rendering));
            clonedRendering.placeholders['abrdn-fund-in-focus'] = clonedRendering.placeholders[
                'abrdn-fund-in-focus'
            ].filter((fund) => fund.fields?.fundInFocus);
            return clonedRendering;
        },
        hasAnyFund() {
            return this.clonedRendering.placeholders['abrdn-fund-in-focus']?.length;
        },
        viewMoreLink() {
            if (!this.fields.fundsLandingPage?.value) return false;
            return {
                value: {
                    href: this.fields.fundsLandingPage.value,
                    text: '',
                    linktype: '',
                    url: '',
                    class: '',
                },
            };
        },
    },
};
</script>

<style scoped lang="scss">
.funds-in-focus__view-more svg {
    width: 1.142em;
    height: 1.142em;
    margin-left: 12px;
}
</style>
