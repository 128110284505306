<template>
    <transition name="slide-in-out" mode="out-in">
        <section
            v-show="(showComponent && showMobileCallButton) || isExperienceEditor"
            :id="anchorId"
            :data-e2e-component-uid="rendering.uid"
        >
            <div class="d-none d-md-block">
                <div class="d-flex justify-content-end">
                    <button
                        :aria-label="$t('closeModal')"
                        class="btn close-button d-flex align-items-center text-link"
                        @click="close"
                    >
                        <span>{{ $t('close') }}</span>
                        <em class="bi bi-x-lg" aria-hidden="true" />
                    </button>
                </div>
                <div class="text-white" :class="getBackground">
                    <div class="container d-flex justify-content-between align-items-center py-4">
                        <Animation
                            v-if="haveDesktopAnimation"
                            class="sticky-cta-image flex-shrink-0"
                            :visible="showComponent"
                            :animationData="getDesktopAnimation"
                        />
                        <ScText :field="fields.title" tag="h3" class="mx-5 mx-lg-6 flex-fill" />
                        <AbrdnButton
                            id="call-button"
                            :linkField="fields.ctaLink"
                            :showExternalIcon="false"
                            class="btn-call d-flex align-items-center background-white flex-shrink-0 ps-3 pe-4"
                            :class="{ 'flex-row-reverse': isExperienceEditor }"
                        >
                            <em :class="`bi bi-${getIcon} h4 d-flex align-items-center me-1`" aria-hidden="true" />
                            <span v-if="!isExperienceEditor" class="button-label">{{ ctaLinkText }}</span>
                        </AbrdnButton>
                    </div>
                </div>
            </div>
            <div class="d-block d-md-none m-2 text-white">
                <AbrdnButton id="call-button-mobile" :linkField="fields.ctaLink" :showExternalIcon="false">
                    <Animation
                        v-if="haveMobileAnimation"
                        class="mobile-call-button d-flex align-items-center"
                        :class="getBackground"
                        :visible="showComponent"
                        :animationData="getMobileAnimation"
                    />
                </AbrdnButton>
            </div>
        </section>
    </transition>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import ResizeObserver from 'resize-observer-polyfill';

import Animation from '@/components/Generic/Animation.vue';
import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton.vue';
import Cookie from '@/mixins/Cookie.mixin';

export default {
    name: 'StickyCta',
    components: {
        ScText: Text,
        AbrdnButton,
        Animation,
    },
    mixins: [Cookie],
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        params: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    data() {
        return {
            showComponent: false,
            showMobileCallButton: false,
            resizeObserver: null,
            screenWidth: null,
            clientHeight: null,
            footerHeight: null,
            innerHeight: null,
        };
    },
    computed: {
        halfScreenHeight() {
            return window.innerHeight / 2;
        },
        docHeightWithoutFooter() {
            return this.clientHeight - this.footerHeight - this.innerHeight;
        },
        isMobile() {
            return this.screenWidth < 768;
        },
        haveDesktopAnimation() {
            return !!this.fields?.desktopAnimation?.value;
        },
        getDesktopAnimation() {
            return this.fields?.desktopAnimation?.value;
        },
        getBackground() {
            return this.params?.background || 'background-primary';
        },
        getIcon() {
            return this.fields?.ctaLogo?.value;
        },
        haveMobileAnimation() {
            return !!this.fields?.mobileAnimation?.value;
        },
        getMobileAnimation() {
            return this.fields?.mobileAnimation?.value;
        },
        ctaLinkText() {
            return this.fields?.ctaLink?.value?.text;
        },
    },
    mounted() {
        this.setHeights();
        window.addEventListener('scroll', this.onScroll);
        this.resizeObserver = new ResizeObserver(this.setScreenWidth);
        this.resizeObserver.observe(this.$el);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.onScroll);
        this.resizeObserver.unobserve(this.$el);
    },
    methods: {
        onScroll(event) {
            this.halfScreenHeight <= event.target.defaultView.scrollY && !this.getCookie('abrdn-sticky-cta')
                ? (this.showComponent = true)
                : (this.showComponent = false);
            this.docHeightWithoutFooter <= event.target.defaultView.scrollY && this.isMobile
                ? (this.showMobileCallButton = false)
                : (this.showMobileCallButton = true);
        },
        close() {
            const value = {
                n: this.$jss.sitecoreContext().site.name,
                e: undefined,
                s: undefined,
                l: this.$jss.sitecoreContext().language,
            };
            this.setCookie('abrdn-sticky-cta', JSON.stringify(value));
            this.showComponent = false;
        },
        setScreenWidth() {
            this.screenWidth = screen.width;
            this.setHeights();
        },
        setHeights() {
            this.clientHeight = document.body.clientHeight;
            this.footerHeight = this.$root.footerHeight;
            this.innerHeight = window.innerHeight;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
@import '@/assets/styles/variables';

section {
    z-index: 20;
    position: sticky;
    bottom: 0px;
    @include media-breakpoint-down(md) {
        position: fixed;
        bottom: 0px;
        right: 0px;
    }
}

.sticky-cta-image {
    position: relative;
    width: 100%;
    max-width: 88px;
    @include media-breakpoint-down(md) {
        max-width: 48px;
    }
    ::v-deep svg {
        width: 100%;
        height: 100%;
    }
}

.close-button {
    background-color: #d9d9d9;
    padding-top: 9px;
    padding-bottom: 12px;
    padding-left: 22px;
    padding-right: 28px;
    border: 0px;
    border-radius: 3px 3px 0px 0px;
    span {
        margin-right: 13px;
    }
    i::before {
        width: auto;
        height: 14px;
    }
}

.btn-call {
    height: 48px;
    border-radius: 24px;
    padding-top: 12px;
    padding-bottom: 12px;

    &:hover {
        background: var(--button-mist);
    }

    i::before {
        width: auto;
        height: 24px;
    }
}

.mobile-call-button {
    width: 56px;
    height: 56px;
    border-radius: 28px;
}

.slide-in-out-enter-active {
    animation: slide-in 0.3s ease-in-out;
}

.slide-in-out-leave-active {
    animation: slide-out 0.3s ease-in-out;
}

@keyframes slide-in {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0%);
    }
}

@keyframes slide-out {
    from {
        transform: translateY(0%);
    }
    to {
        transform: translateY(100%);
    }
}
</style>
