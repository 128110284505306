<template>
    <div class="container pt-7 pb-5 background-white">
        <div class="row article-filter-bar-root">
            <div class="col-12">
                <div class="px-md-2 py-5 p-lg-5" :class="vectorBasedBackground">
                    <div class="d-flex align-items-start flex-column flex-md-row flex-lg-column">
                        <ScText
                            v-if="filterBarSubHeading"
                            tag="h4"
                            :field="{ value: filterBarSubHeading }"
                            class="col-12 col-md-7"
                        />
                        <ArticleFilters
                            v-if="filters.length"
                            :filters="filters"
                            :filtersFacets="filtersFacets"
                            :emptyFacets="emptyFacets"
                            :approvedFilters="approvedFilters"
                            :loading="loading"
                            class="col d-none d-lg-flex mt-4 mt-md-0 mt-lg-3"
                            @onSelect="selectFilter"
                            @clearFilterGroup="clearFilterGroup"
                            @aproveFilters="aproveFilters"
                            @forget="forget"
                            @getFacets="requestFacets"
                        />
                        <ArticleFiltersMobile
                            v-if="filters.length"
                            :filters="filters"
                            :filtersFacets="filtersFacets"
                            :emptyFacets="emptyFacets"
                            :approvedFilters="approvedFilters"
                            :loading="loading"
                            :totalResults="totalResults"
                            :articles="articles"
                            :filtersForApprove="filtersForApprove"
                            class="col-12 col-md-5 d-flex d-lg-none mt-4 mt-md-0 mt-lg-3"
                            @onSelect="selectFilter"
                            @aproveFilters="aproveFilters"
                            @clearFilters="clearFilters"
                            @forget="forget"
                            @getFacets="requestFacets"
                        />
                    </div>
                    <SelectedFilters
                        v-if="approvedFilters.length"
                        class="mt-4"
                        :approvedFilters="approvedFilters"
                        @clearFilters="clearFilters"
                        @deleteOption="deleteOption"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { mapActions, mapMutations, mapState } from 'vuex';

import FilterHelper from '@/mixins/ArticleFilter/FilterHelper.mixin';
import { sitecoreLanguage } from '@/mixins/Helpers.mixin';

import ArticleFilters from './ArticleFilters';
import ArticleFiltersMobile from './ArticleFiltersMobile';
import SelectedFilters from './SelectedFilters';

export default {
    name: 'FilterBarFunctional',
    components: {
        ArticleFilters,
        SelectedFilters,
        ArticleFiltersMobile,
        ScText: Text,
    },
    mixins: [FilterHelper],
    props: {
        formattedMapping: {
            type: Object,
            default: /* istanbul ignore next */ () => {},
        },
        vuexData: {
            type: Object,
            default: /* istanbul ignore next */ () => {},
        },
        initialTake: {
            type: [Number, undefined],
            default: undefined,
        },
    },
    computed: {
        filterBarSubHeading() {
            return this.formattedMapping?.filterBarSubHeading;
        },
        sitecoreLanguage,
    },
    beforeCreate() {
        const vuexData = this.$options.propsData.vuexData;
        this.$options.computed = {
            ...mapState(vuexData.filterBar.state.namespace, vuexData.filterBar.state.items),
            ...this.$options.computed,
        };
        this.$options.methods = {
            ...mapActions(vuexData.filterBar.actions.namespace, vuexData.filterBar.actions.items),
            ...mapMutations(vuexData.filterBar.mutations.namespace, vuexData.filterBar.mutations.items),
            ...this.$options.methods,
        };
    },
    mounted() {
        this.setFilterMappings(this.formattedMapping || {});
        const query = this.$route.query;
        const prepopulatedFilters = {};
        Object.keys(query) &&
            Object.keys(query).forEach((paramKey) => {
                prepopulatedFilters[paramKey] = query[paramKey] ? query[paramKey].split(',') : [''];
            });
        const filtersExist = Object.keys(prepopulatedFilters).length;
        if ((query.page || query.take) && this.setPaginationData) {
            this.setPaginationData({ page: +query.page ? +query.page : 1, take: query.take ? +query.take : 20 });
        } else if (this.initialTake) {
            this.setPaginationData({ page: this.page, take: this.initialTake });
        }
        this.setInitialFilters();
        const preselectedFilters = this.getPreselectedFilters();
        preselectedFilters.forEach((option) => {
            option.selected = true;
            this.approvedFilters.push(option);
            if (prepopulatedFilters[option.group]) {
                prepopulatedFilters[option.group].push(option.id);
            } else {
                prepopulatedFilters[option.group] = [option.id];
            }
        });
        if (this.getEmptyFacets) {
            this.getEmptyFacets(this.getEmptyFacetsParams());
        } else {
            console.warn('getEmptyFacets function not provided');
        }
        this.getArticles(
            this.getParams(prepopulatedFilters, this.formattedMapping, this.page, this.take, this.sitecoreLanguage),
        );

        if (this.setBaseUrl) {
            this.setBaseUrl(this.formattedMapping.articleBaseUrl);
        } else {
            console.warn('setBaseUrl function not provided');
        }

        if (filtersExist) {
            this.selectUrlFilters(prepopulatedFilters);
        }
    },
    beforeDestroy() {
        this.setFiltersForApprove([]);
        this.setApprovedFilters([]);
        if (this.setBaseUrl) {
            this.setBaseUrl('');
        } else {
            console.warn('setBaseUrl function not provided');
        }
        this.eraseArticles();
    },
    methods: {
        getPreselectedFilters() {
            return Object.values(this.filters)
                .map((filterGroup) => {
                    return filterGroup.options.filter((options) => options.preselected === true);
                })
                .flat(Infinity);
        },
        requestFacets(filter, mobile) {
            this.getFacets(
                this.getFacetsParams(
                    this.prepareFilterCategories(
                        mobile
                            ? [...this.approvedFilters, ...this.filtersForApprove.filter((option) => option.selected)]
                            : this.approvedFilters,
                    ),
                    filter,
                ),
            );
        },
        selectUrlFilters(categories) {
            const approvedFIlters = [];
            const updatedFilters = this.filters.map((filter) => {
                return {
                    ...filter,
                    options: filter.options.map((option) => {
                        const optionSelected = categories[filter.id] && categories[filter.id].includes(option.id);
                        const updatedOption = { ...option, selected: true, group: filter.id };
                        if (optionSelected) {
                            approvedFIlters.push(updatedOption);
                        }
                        return {
                            ...(optionSelected ? updatedOption : option),
                        };
                    }),
                };
            });
            this.setFilters(updatedFilters);
            this.setApprovedFilters(approvedFIlters);
        },
        setInitialFilters() {
            const filters = this.formattedMapping.filters;

            this.setFilters(
                Object.keys(filters).map((item) => {
                    return {
                        ...filters[item],
                        value: filters[item].displayName,
                        options: filters[item].options.map((option) => {
                            return { ...option, group: filters[item].id };
                        }),
                    };
                }),
            );
        },
        selectFilter(selectedOption) {
            const updatedFilters = this.changeOptionState(selectedOption);
            let currentOption = {};
            updatedFilters.forEach((category) => {
                const option = category.options.find((option) => option.id === selectedOption.id);
                if (option) {
                    currentOption = option;
                }
            });

            const existInfiltersForApproveList = this.filtersForApprove.filter(
                (item) => item.id === currentOption.id,
            )[0];
            if (!existInfiltersForApproveList) {
                this.setFiltersForApprove([...this.filtersForApprove, currentOption]);
            } else {
                this.setFiltersForApprove(
                    this.filtersForApprove.map((item) =>
                        item.id === selectedOption.id ? { ...item, selected: currentOption.selected } : item,
                    ),
                );
            }

            this.setFilters(updatedFilters);
        },
        aproveFilters() {
            this.setApprovedFilters([
                ...this.approvedFilters.filter((option) => {
                    return !this.filtersForApprove.filter((item) => item.id === option.id && !item.selected)[0];
                }),
                ...this.filtersForApprove.filter((option) => {
                    return !this.approvedFilters.filter((item) => item.id === option.id)[0] && option.selected;
                }),
            ]);

            if (this.setPaginationData) {
                this.setPaginationData({ page: 1, take: this.take });
            }

            this.generateUrl(this.approvedFilters, this.page, this.take);
            this.setFiltersForApprove([]);
            this.getArticles(
                this.getParams(
                    this.prepareFilterCategories(this.approvedFilters),
                    this.formattedMapping,
                    this.page,
                    this.take,
                    this.sitecoreLanguage,
                ),
            );
        },
        changeOptionState(selectedOption, state) {
            return this.filters.map((group) => {
                return {
                    ...group,
                    options: group.options.map((option) => {
                        return option.id === selectedOption.id
                            ? { ...option, selected: typeof state === 'boolean' ? state : !option.selected }
                            : option;
                    }),
                };
            });
        },
        deleteOption(option) {
            const updatedOptions = this.approvedFilters.filter((item) => item.id !== option.id);
            const updatedFilterForApprove = this.filtersForApprove.filter((item) => item.id !== option.id);
            this.setFilters(this.changeOptionState(option));
            this.setApprovedFilters(updatedOptions);
            this.setFiltersForApprove(updatedFilterForApprove);
            if (this.setPaginationData) {
                this.setPaginationData({ page: 1, take: this.take });
            }
            this.generateUrl(this.approvedFilters, this.page, this.take);
            this.getArticles(
                this.getParams(
                    this.prepareFilterCategories(this.approvedFilters),
                    this.formattedMapping,
                    this.page,
                    this.take,
                    this.sitecoreLanguage,
                ),
            );
        },
        clearFilters() {
            this.setInitialFilters();
            this.setFiltersForApprove([]);
            this.setApprovedFilters([]);
            if (this.setPaginationData) {
                this.setPaginationData({ page: 1, take: this.take });
            }
            this.generateUrl(this.approvedFilters, this.page, this.take);
            this.getArticles(this.getParams(null, this.formattedMapping, this.page, this.take, this.sitecoreLanguage));
        },
        getFacetsParams(categories, currentFilter) {
            const filters = Object.keys(this.formattedMapping.filters).map((item) => {
                return this.formattedMapping.filters[item].id;
            });
            const source = this.formattedMapping.articleSource;
            return {
                search: {
                    source,
                    contentGroup: this.formattedMapping.contentGroup,
                    language: this.sitecoreLanguage ? this.sitecoreLanguage : 'en-GB',
                },
                facets: filters,
                filters: filters.map((filter) => {
                    return {
                        name: filter,
                        values:
                            categories && categories[filter]?.length && filter !== currentFilter.id
                                ? categories[filter]
                                : [],
                    };
                }),
            };
        },
        getEmptyFacetsParams() {
            const filters = Object.keys(this.formattedMapping.filters).map((item) => {
                return this.formattedMapping.filters[item].id;
            });
            const source = this.formattedMapping.articleSource;
            return {
                search: {
                    source,
                    contentGroup: this.formattedMapping.contentGroup,
                    language: this.sitecoreLanguage ? this.sitecoreLanguage : 'en-GB',
                },
                facets: filters,
                filters: filters.map((filter) => {
                    return {
                        name: filter,
                        values: [],
                    };
                }),
            };
        },
        clearFilterGroup(currentfilterGroup) {
            const updatedFilters = this.filters.map((filterGroup) => {
                return currentfilterGroup.value === filterGroup.value
                    ? {
                          ...filterGroup,
                          options: filterGroup.options.map((option) => {
                              return { ...option, selected: false };
                          }),
                      }
                    : filterGroup;
            });
            const clearFilterGroup = this.approvedFilters.filter((item) => {
                return !currentfilterGroup.options.filter((option) => option.id === item.id)[0];
            });

            this.setFilters(updatedFilters);
            this.setApprovedFilters(clearFilterGroup);
            if (this.setPaginationData) {
                this.setPaginationData({ page: 1, take: this.take });
            }
            this.generateUrl(this.approvedFilters, this.page, this.take);
            this.setFiltersForApprove([]);
            this.getArticles(
                this.getParams(
                    this.prepareFilterCategories(this.approvedFilters),
                    this.formattedMapping,
                    this.page,
                    this.take,
                    this.sitecoreLanguage,
                ),
            );
        },
        forget() {
            if (this.approvedFilters.length) {
                const approvedItemsIds = this.approvedFilters.map((item) => item.id);
                this.filtersForApprove.forEach((option) => {
                    if (approvedItemsIds.includes(option.id)) {
                        this.approvedFilters.forEach((approvedOption) => {
                            if (approvedOption.id === option.id) {
                                this.setFilters(this.changeOptionState(option, approvedOption.selected));
                            }
                        });
                    } else {
                        this.setFilters(this.changeOptionState(option, false));
                    }
                });
            } else {
                this.setInitialFilters();
            }
            this.setFiltersForApprove([]);
        },
        eraseArticles() {
            if (this.clearArticles) {
                this.clearArticles();
                if (this.setPaginationData) {
                    this.setPaginationData({ page: 1, take: this.take });
                }
            } else {
                console.warn('clearArticles function is not provided');
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
.article-filter-bar-root {
    @include media-breakpoint-down(md) {
        background: var(--digital-light-grey);
    }
}
</style>
