<template>
    <div class="chart-aside">
        <div class="chart-legend">
            <div
                v-for="(item, idx) in legendData"
                :key="item"
                class="chart-legend__item body-small"
                :class="{ 'mt-4': idx !== 0 }"
            >
                <div class="chart-legend__item__identifier" />
                <div class="chart-legend__item__name ms-2">{{ item }}</div>
            </div>
        </div>
        <div v-if="tooltips.length" class="chart-tooltips mt-4">
            <ol>
                <li v-for="(tooltip, idx) in tooltips" :key="idx" class="caption" :class="{ 'mt-3': idx !== 0 }">
                    {{ tooltip.note }}
                </li>
            </ol>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PastPerformanceAside',
    props: {
        legendData: {
            type: Array,
            required: true,
            default: () => [],
        },
        tooltips: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
};
</script>

<style scoped lang="scss">
$tooltipCounterBoxSize: 20;
.chart-legend__item {
    display: flex;
    align-items: flex-start;
}
.chart-legend__item__identifier {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
}
.chart-legend__item__name {
    line-height: 24px;
}
ol {
    list-style: none;
    padding: 0;
    margin: 0;
    counter-reset: tooltips-counter;
}
ol li {
    counter-increment: tooltips-counter;
    position: relative;
    padding-left: 40px;
    z-index: 0;
}
ol li::before {
    content: counter(tooltips-counter);
    color: white;
    position: absolute;
    left: 0;
    top: 0;
    width: #{$tooltipCounterBoxSize}px;
    height: #{$tooltipCounterBoxSize}px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
}
ol li::after {
    content: '';
    position: absolute;
    left: #{$tooltipCounterBoxSize * (15/100)}px;
    top: #{$tooltipCounterBoxSize * (15/100)}px;
    width: #{$tooltipCounterBoxSize * (70/100)}px;
    height: #{$tooltipCounterBoxSize * (70/100)}px;
    background-color: black;
    transform: rotate(45deg);
    z-index: -1;
}
</style>
