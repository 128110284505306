<template>
    <Loader v-if="isLoading && !rows.length" :size="100" class="mx-auto my-5" />
    <div v-else-if="rows.length" class="d-flex flex-column mt-2">
        <div
            v-for="(row, index) in rows"
            :key="row.id + 'mobile'"
            class="mobile-table-card p-3 d-flex flex-column w-100"
        >
            <p class="body-default mb-4">{{ row.name }}</p>
            <div class="d-flex flex-column">
                <p class="section-header-small text-grey mb-1">{{ $t('kidShareClass') }}</p>
                <AbrdnSelect
                    :fields="showMore(row)"
                    class="share-selector"
                    @updateValue="updateSelected(index, $event)"
                />
            </div>
            <div
                v-if="row.shareclasses.length"
                class="d-flex justify-content-between w-100 flex-md-row flex-column mt-4"
            >
                <div class="d-flex flex-column mb-4 mb-md-0">
                    <p class="section-header-small text-grey">{{ $t('pastPerformance') }}</p>
                    <button
                        type="button"
                        class="btn-naked bi-box-arrow-up-right mt-2"
                        @click="$emit('togglePastPerformance', row)"
                    />
                </div>
                <div class="d-flex flex-column mb-4 mb-md-0">
                    <p class="section-header-small text-grey">{{ $t('scenarioCalculation') }}</p>
                    <button
                        type="button"
                        class="btn-naked bi-box-arrow-up-right mt-2"
                        @click="$emit('togglePredictivePerformance', row)"
                    />
                </div>
                <div v-if="row.showDownloadButton" class="d-flex flex-column align-items-start">
                    <p class="section-header-small text-grey">{{ $t('kid') }}</p>
                    <AbrdnButton
                        :buttonType="'primary'"
                        :linkField="getDownloadLink(row)"
                        :showExternalIcon="false"
                        class="mt-1"
                    >
                        <span>{{ $t('download') }}</span>
                        <em class="bi-download ms-1" aria-hidden="true" />
                    </AbrdnButton>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton.vue';
import AbrdnSelect from '@/components/Generic/FormElements/FormFields/AbrdnSelect.vue';
import Loader from '@/components/Generic/Loader.vue';

export default {
    name: 'MobileTable',
    components: {
        Loader,
        AbrdnSelect,
        AbrdnButton,
    },
    props: {
        rows: {
            type: Array,
            default: /* istanbul ignore next */ () => [],
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            asc: true,
        };
    },
    methods: {
        showMore(row) {
            const output = {
                placeholder: { value: this.$t('noResultsMessage') },
                disabled: { value: !row.shareclasses.length },
                options: {
                    value: [],
                },
            };
            row.shareclasses.forEach((value, i) => {
                output.options.value.push({
                    name: i,
                    displayName: value.shareclassName,
                    value: i,
                    selected: i == parseInt(row.selected) ? true : false,
                });
            });
            return output;
        },
        getDownloadLink(row) {
            return {
                value: {
                    href: row.shareclasses[row.selected].kid.documentURI,
                    linktype: 'internal',
                    target: '_blank',
                },
            };
        },
        updateSelected(index, val) {
            const updatedRows = this.rows;
            updatedRows[index].selected = val.value;
            this.$emit('updateRows', updatedRows);
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
.mobile-table-card {
    border: 1px solid rgba(0, 0, 0, 0.3);
    margin-bottom: 24px;
    &:last-of-type {
        margin-bottom: 12px;
    }
}

.body-default {
    font-weight: 600;
}

.share-selector {
    width: 100%;
    @include media-breakpoint-up(md) {
        width: 50%;
    }
}
</style>
