<script>
import * as Plot from '@observablehq/plot';
import { mapState } from 'vuex';

import ChartMixin from '../Chart.mixin';
import { colorCodes } from './config';

export default {
    name: 'FundDetailsPerformanceTabCumulativeChart',

    mixins: [ChartMixin],

    props: {
        selectedCategories: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            colorCodes,
        };
    },

    computed: {
        ...mapState('fundDetails', {
            data: (state) => state.filteredCumulativeChartData.data?.categories,
            isLoading: (state) => state.cumulativeChartData.loading || state.filteredCumulativeChartData.loading,
        }),

        chart() {
            if (!this.data) return;

            const data = this.data.reduce(
                (data, category) => [
                    ...data,
                    ...category.values.map((value) => this.getConvertedValueForChartData(value, category)),
                ],
                [],
            );

            const values = data.map(({ value }) => value);
            const minValue = Math.min(...values);
            const maxValue = Math.max(...values);

            return Plot.plot({
                x: {
                    ticks: 8,
                },
                y: {
                    grid: true,
                    label: this.$t('fundDetailsPerformanceChartValue'),
                    ticks: 6,
                },
                marginBottom: 40,
                marginLeft: 60,
                width: this.chartWidth,
                height: this.chartHeight,
                color: {
                    range: this.colorCodes,
                },
                marks: [
                    Plot.ruleY([minValue]),

                    Plot.areaY(data, {
                        x: 'date',
                        y1: minValue,
                        y2: 'value',
                        z: 'category',
                        fill: 'category',
                        opacity: 0.05,
                        filter: (d) => d.show,
                    }),

                    Plot.lineY(data, {
                        x: 'date',
                        y: 'value',
                        z: 'category',
                        stroke: 'category',
                        strokeWidth: (d) => (d.show ? 2 : 1),
                        opacity: (d) => (d.show ? 1 : 0.25),
                        sort: (d) => d.show,
                    }),

                    Plot.ruleX(
                        data,
                        Plot.pointerX({
                            x: 'date',
                            y1: minValue,
                            y2: maxValue,
                            z: 'category',
                            strokeWidth: 1,
                            opacity: 0.2,
                            maxRadius: 999,
                        }),
                    ),

                    ...this.data.map((category) =>
                        Plot.dot(
                            category.values.map((value) => this.getConvertedValueForChartData(value, category)),
                            Plot.pointerX({
                                x: 'date',
                                y: 'value',
                                z: 'category',
                                fill: 'category',
                                r: 4,
                                strokeWidth: 0,
                                filter: (d) => d.show,
                                maxRadius: 999,
                            }),
                        ),
                    ),

                    Plot.tip(
                        data,
                        Plot.pointerX({
                            x: 'date',
                            y: 'value',
                            z: 'category',
                            strokeWidth: 0,
                            filter: (d) => d.show,
                            title: (d) =>
                                [
                                    this.localizeDate(d.date),
                                    ...data
                                        .filter((data) => data.date.getTime() === d.date.getTime())
                                        .map((data) => `${data.category}: ${data.value}`),
                                ].join('\n'),
                            pointerSize: 0,
                            textPadding: 12,
                            fontSize: 12,
                            lineHeight: 1.5,
                            maxRadius: 999,
                        }),
                    ),
                ],
            });
        },
    },

    methods: {
        getConvertedValueForChartData(value, category) {
            return {
                date: new Date(value.date),
                value: value.value,
                category: category.label,
                show: !!this.selectedCategories?.[category?.label],
            };
        },
    },
};
</script>
