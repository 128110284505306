import DummyBlock from '@/components/_demo/Styleguide/DummyBlock';
import StyleguideFormFields from '@/components/_demo/Styleguide/StyleguideFormFields';
import BannerQuickLinks from '@/components/Blocks/BannerQuickLinks';
import CallToAction from '@/components/Blocks/CallToAction.vue';
import ContentBlock from '@/components/Blocks/ContentBlock.vue';
import IFrame from '@/components/Blocks/Iframes/IFrame';
import Infographics from '@/components/Blocks/Iframes/Infographics';
import AlertMessage from '@/components/Generic/AlertMessage';
import BaseHeading from '@/components/Generic/BaseHeading';
import Breadcrumbs from '@/components/Generic/Breadcrumbs';
import BackToTopButton from '@/components/Generic/Buttons/BackToTopButton';
import ContainerMain from '@/components/Generic/Containers/ContainerMain';
import FailSafePlaceholder from '@/components/Generic/ExperienceEditor/FailSafePlaceholder';
import Footer from '@/components/Generic/Footer';
import Dragger from '@/components/Generic/FormElements/Dragger';
import AbrdnSelect from '@/components/Generic/FormElements/FormFields/AbrdnSelect';
import InputField from '@/components/Generic/FormElements/FormFields/InputField';
import ValueField from '@/components/Generic/FormElements/FormFields/ValueField';
import LanguageSelector from '@/components/Generic/Header/CountrySelector.vue';
import Header from '@/components/Generic/Header/Header.vue';
import MegaNavigation from '@/components/Generic/Header/MegaNavigation/MegaNavigation';
import IntroCopy from '@/components/Generic/IntroCopy';
import Row from '@/components/Generic/Rows/Row';
import ShareScreen from '@/components/Generic/ShareScreen';
import Image from '@/components/Generic/SitecoreWrappers/Image.vue';
import RichText from '@/components/Generic/SitecoreWrappers/RichText.vue';
import SocialNetwork from '@/components/Includes/SocialNetwork.vue';
import ArticleCards from '@/components/Pages/ArticleListings/ArticleCards.vue';
import ArticleFilterBar from '@/components/Pages/ArticleListings/ArticleFilter.vue';
import FilteredArticleCards from '@/components/Pages/ArticleListings/FilteredArticleCards';
import ArticleActionBar from '@/components/Pages/ArticleParts/ArticleActionBar.vue';
import ArticleBody from '@/components/Pages/ArticleParts/ArticleBody.vue';
import ArticleBottomContainer from '@/components/Pages/ArticleParts/ArticleBottomContainer.vue';
import ArticleChapters from '@/components/Pages/ArticleParts/ArticleChapters.vue';
import ArticleFootnote from '@/components/Pages/ArticleParts/ArticleFootnote';
import ArticleSubscriptionBanner from '@/components/Pages/ArticleParts/ArticleSubscriptionBanner';
import HelpArticleBody from '@/components/Pages/ArticleParts/HelpArticleBody.vue';
import HelpNextSteps from '@/components/Pages/ArticleParts/HelpNextSteps';
import KeyHighlights from '@/components/Pages/ArticleParts/KeyHighlights';
import NextSteps from '@/components/Pages/ArticleParts/NextSteps';
import Podcast from '@/components/Pages/ArticleParts/Podcast';
import PremiumArticleEloquaForm from '@/components/Pages/ArticleParts/PremiumArticleEloquaForm';
import Quote from '@/components/Pages/ArticleParts/Quote';
import RelatedCapabilities from '@/components/Pages/ArticleParts/RelatedCapabilities';
import RelatedHelpPlatform from '@/components/Pages/ArticleParts/RelatedHelpPlatform';
import RelatedStories from '@/components/Pages/ArticleParts/RelatedStories';
import RelatedTaggedHelp from '@/components/Pages/ArticleParts/RelatedTaggedHelp';
import SpotifyPodcast from '@/components/Pages/ArticleParts/SpotifyPodcast';
import Heading from '@/components/Pages/ArticleParts/TextBlocks/Heading';
import HighlightBlock from '@/components/Pages/ArticleParts/TextBlocks/HighlightBlock';
import HighlightBlockImage from '@/components/Pages/ArticleParts/TextBlocks/HighlightBlockImage';
import Link from '@/components/Pages/ArticleParts/TextBlocks/Link';
import List from '@/components/Pages/ArticleParts/TextBlocks/List';
import Paragraph from '@/components/Pages/ArticleParts/TextBlocks/Paragraph';
import TextBlocks from '@/components/Pages/ArticleParts/TextBlocks/TextBlocks';
import Video from '@/components/Pages/ArticleParts/Video.vue';
import Error404 from '@/components/Pages/Errors/Error404';
import Error500 from '@/components/Pages/Errors/Error500';
import FundsInFocus from '@/components/Pages/Funds/FundsInFocus.vue';
import PricingSummary from '@/components/Pages/Funds/PricingSummary.vue';
import DynamicRelatedArticlesSection from '@/components/Pages/FundsCenter/AllFunds/DynamicRelatedArticlesSection.vue';
import FundCentreMainTabs from '@/components/Pages/FundsCenter/AllFunds/FundCentreMainTabs.vue';
import FundDetailsBanner from '@/components/Pages/FundsCenter/FundDetails/FundDetailsBanner';
import FundDetailsImportantInfo from '@/components/Pages/FundsCenter/FundDetails/FundDetailsImportantInfo';
import FundDetailsPreliminaryImportantInfo from '@/components/Pages/FundsCenter/FundDetails/FundDetailsPreliminaryImportantInfo';
import FundDetailsTabs from '@/components/Pages/FundsCenter/FundDetails/FundDetailsTabs';
import StrategyInFocus from '@/components/Pages/FundsCenter/FundsInFocus/StrategyInFocus';
import IsaCalculator from '@/components/Pages/IsaCalculator';
import KidsTable from '@/components/Pages/KidsHub/KidsTable';
import LiteratureHub from '@/components/Pages/LiteratureHub/LiteratureHub';
import MorningstarDataFeed from '@/components/Pages/MorningstarDataFeed';
import PressReleaseBody from '@/components/Pages/PressReleases/PressReleaseBody.vue';
import PressReleaseCards from '@/components/Pages/PressReleases/PressReleaseCards';
import PressReleaseFilterBar from '@/components/Pages/PressReleases/PressReleaseFilterBar';
import PressReleaseHero from '@/components/Pages/PressReleases/PressReleaseHero';
import TrustFinder from '@/components/Pages/TrustFinder/TrustFinder.vue';
import Accordion from '@/components/Sections/Accordion/Accordion.vue';
import ArticleSeriesCollection from '@/components/Sections/ArticleSeriesCollection';
import AutomaticDocumentListings from '@/components/Sections/AutomaticDocumentListings';
import AwardsBanner from '@/components/Sections/AwardsBanner';
import ArticleHero from '@/components/Sections/Banners/ArticleHero';
import ArticleSeriesBanner from '@/components/Sections/Banners/ArticleSeriesBanner';
import FeatureBanner from '@/components/Sections/Banners/FeatureBanner';
import HelpArticleHero from '@/components/Sections/Banners/HelpArticleHero';
import InvestmentsHero from '@/components/Sections/Banners/InvestmentsHero';
import ListingHeroSection from '@/components/Sections/Banners/ListingHeroSection.vue';
import PrimaryHeroBanner from '@/components/Sections/Banners/PrimaryHeroBanner';
import SecondaryHeroBanner from '@/components/Sections/Banners/SecondaryHeroBanner';
import TertieryHeroBanner from '@/components/Sections/Banners/TertieryHeroBanner';
import TrustArticleHero from '@/components/Sections/Banners/TrustArticleHero';
import CallOutSection from '@/components/Sections/CallOutSection';
import CustomerReviewsSection from '@/components/Sections/CustomerReviewsSection';
import DescriptiveHighlightSection from '@/components/Sections/DescriptiveHighlightSection';
import DocumentListings from '@/components/Sections/DocumentListings.vue';
import EloquaForm from '@/components/Sections/EloquaForm';
import FormLPP48 from '@/components/Sections/Forms/Form-LPP48/Form-LPP48';
import HighlightSection from '@/components/Sections/HighlightSection';
import PropertyListingCards from '@/components/Sections/Listings/PropertyListingCards.vue';
import TeamListingCards from '@/components/Sections/Listings/TeamListingCards.vue';
import LogoTextBlocks from '@/components/Sections/LogoTextBlocks';
import MediaBlock from '@/components/Sections/MediaBlock';
import PageLevelTabs from '@/components/Sections/PageLevelTabs';
import QuotationBlocks from '@/components/Sections/QuotationBlocks.vue';
import RecentArticles from '@/components/Sections/RecentArticles';
import FeaturedArticlesSection from '@/components/Sections/RelatedArticlesVariations/FeaturedArticlesSection';
import FeaturedThemeSection from '@/components/Sections/RelatedArticlesVariations/FeaturedThemeSection';
import LatestArticlesSection from '@/components/Sections/RelatedArticlesVariations/LatestArticlesSection';
import LatestPressReleasesSection from '@/components/Sections/RelatedArticlesVariations/LatestPressReleasesSection.vue';
import RelatedArticlesSection from '@/components/Sections/RelatedArticlesVariations/RelatedArticlesSection';
import RiskWarning from '@/components/Sections/RiskWarning';
import SalesforceCaseForm from '@/components/Sections/SalesforceCaseForm';
import SectionHeading from '@/components/Sections/SectionHeading';
import SectionSelector from '@/components/Sections/SectionSelector.vue';
import SeriesArticles from '@/components/Sections/SeriesArticles';
import Showcase from '@/components/Sections/Showcase';
import StickyCta from '@/components/Sections/StickyCta.vue';
import TableOfContents from '@/components/Sections/TableOfContents';
import TeamMemberBiography from '@/components/Sections/TeamMemberBiography';
import TeamProfileCards from '@/components/Sections/TeamProfileCards';
import TeamProfileCardsWithImages from '@/components/Sections/TeamProfileCardsWithImages';
import Tiles from '@/components/Sections/Tiles';
import ArticleCard from '@/components/Tiles/ArticleCard.vue';
import CustomerReviewTile from '@/components/Tiles/CustomerReviewTile';
import FundInFocus from '@/components/Tiles/FundInFocus';
import PropertyListingCard from '@/components/Tiles/PropertyListingCard';
import TeamListingCard from '@/components/Tiles/TeamListingCard';
import Tile from '@/components/Tiles/Tile';
import TrustCard from '@/components/Tiles/TrustCard';
import VectorTile from '@/components/Tiles/VectorTile.vue';

import wrapComponent from './wrapComponent';

const components = new Map();
components.set('ContentBlock', wrapComponent(ContentBlock));
components.set('DummyBlock', wrapComponent(DummyBlock));
components.set('ContainerMain', wrapComponent(ContainerMain));
components.set('Row', wrapComponent(Row));
components.set('CallToAction', wrapComponent(CallToAction));
components.set('VectorTile', wrapComponent(VectorTile));
components.set('Header', wrapComponent(Header));
components.set('Footer', wrapComponent(Footer));
components.set('LanguageSelector', wrapComponent(LanguageSelector));
components.set('SocialNetwork', wrapComponent(SocialNetwork));
components.set('FailSafePlaceholder', FailSafePlaceholder);
components.set('FeatureBanner', wrapComponent(FeatureBanner));
components.set('Error500', Error500);
components.set('AbrdnSelect', wrapComponent(AbrdnSelect));
components.set('PrimaryHeroBanner', wrapComponent(PrimaryHeroBanner));
components.set('TertieryHeroBanner', wrapComponent(TertieryHeroBanner));
components.set('SecondaryHeroBanner', wrapComponent(SecondaryHeroBanner));
components.set('QuotationBlocks', wrapComponent(QuotationBlocks));
components.set('IFrame', wrapComponent(IFrame));
components.set('Infographics', wrapComponent(Infographics));
components.set('Error404', wrapComponent(Error404));
components.set('Showcase', wrapComponent(Showcase));
components.set('SectionHeading', wrapComponent(SectionHeading));
components.set('IsaCalculator', wrapComponent(IsaCalculator));
components.set('Dragger', wrapComponent(Dragger));
components.set('StyleguideFormFields', StyleguideFormFields);
components.set('InputField', wrapComponent(InputField));
components.set('ValueField', wrapComponent(ValueField));
components.set('Video', wrapComponent(Video));
components.set('CallOutSection', wrapComponent(CallOutSection));
components.set('TableOfContents', wrapComponent(TableOfContents));
components.set('RiskWarning', wrapComponent(RiskWarning));
components.set('Accordion', wrapComponent(Accordion));
components.set('MegaNavigation', wrapComponent(MegaNavigation));
components.set('HighlightSection', wrapComponent(HighlightSection));
components.set('List', wrapComponent(List));
components.set('Tile', wrapComponent(Tile));
components.set('Tiles', wrapComponent(Tiles));
components.set('TextBlocks', wrapComponent(TextBlocks));
components.set('HighlightBlock', wrapComponent(HighlightBlock));
components.set('HighlightBlockImage', wrapComponent(HighlightBlockImage));
components.set('Breadcrumbs', wrapComponent(Breadcrumbs));
components.set('DescriptiveHighlightSection', wrapComponent(DescriptiveHighlightSection));
components.set('CustomerReviewTile', wrapComponent(CustomerReviewTile));
components.set('CustomerReviewsSection', wrapComponent(CustomerReviewsSection));
components.set('ArticleHero', wrapComponent(ArticleHero));
components.set('ArticleBody', wrapComponent(ArticleBody));
components.set('PressReleaseBody', wrapComponent(PressReleaseBody));
components.set('RichText', wrapComponent(RichText));
components.set('IntroCopy', wrapComponent(IntroCopy));
components.set('Image', wrapComponent(Image));
components.set('SectionSelector', wrapComponent(SectionSelector));
components.set('ArticleActionBar', wrapComponent(ArticleActionBar));
components.set('RelatedArticlesSection', wrapComponent(RelatedArticlesSection));
components.set('ArticleFilterBar', wrapComponent(ArticleFilterBar));
components.set('ArticleCard', wrapComponent(ArticleCard));
components.set('ArticleCards', wrapComponent(ArticleCards));
components.set('ListingHeroSection', wrapComponent(ListingHeroSection));
components.set('FeaturedArticlesSection', wrapComponent(FeaturedArticlesSection));
components.set('ArticleFootnote', wrapComponent(ArticleFootnote));
components.set('BackToTopButton', wrapComponent(BackToTopButton));
components.set('PageLevelTabs', wrapComponent(PageLevelTabs));
components.set('DocumentListings', wrapComponent(DocumentListings));
components.set('LatestArticlesSection', wrapComponent(LatestArticlesSection));
components.set('PricingSummary', wrapComponent(PricingSummary));
components.set('PropertyListingCard', wrapComponent(PropertyListingCard));
components.set('PropertyListingCards', wrapComponent(PropertyListingCards));
components.set('MorningstarDataFeed', wrapComponent(MorningstarDataFeed));
components.set('TeamListingCard', wrapComponent(TeamListingCard));
components.set('TeamListingCards', wrapComponent(TeamListingCards));
components.set('Podcast', wrapComponent(Podcast));
components.set('Heading', wrapComponent(Heading));
components.set('Paragraph', wrapComponent(Paragraph));
components.set('Link', wrapComponent(Link));
components.set('SideTile', wrapComponent(Tile));
components.set('TrustCard', wrapComponent(TrustCard));
components.set('TrustFinder', wrapComponent(TrustFinder));
components.set('EloquaForm', wrapComponent(EloquaForm));
components.set('SalesforceCaseForm', wrapComponent(SalesforceCaseForm));
components.set('RecentArticles', wrapComponent(RecentArticles));
components.set('TrustArticleHero', wrapComponent(TrustArticleHero));
components.set('KeyHighlights', wrapComponent(KeyHighlights));
components.set('RelatedStories', wrapComponent(RelatedStories));
components.set('Quote', wrapComponent(Quote));
components.set('RelatedCapabilities', wrapComponent(RelatedCapabilities));
components.set('ArticleSeriesCollection', wrapComponent(ArticleSeriesCollection));
components.set('FeaturedThemeSection', wrapComponent(FeaturedThemeSection));
components.set('FilteredArticleCards', wrapComponent(FilteredArticleCards));
components.set('StickyCta', wrapComponent(StickyCta));
components.set('BaseHeading', wrapComponent(BaseHeading));
components.set('KidsTable', wrapComponent(KidsTable));
components.set('AwardsBanner', wrapComponent(AwardsBanner));
components.set('FundsInFocus', wrapComponent(FundsInFocus));
components.set('FundInFocus', wrapComponent(FundInFocus));
components.set('ArticleChapters', wrapComponent(ArticleChapters));
components.set('NextSteps', wrapComponent(NextSteps));
components.set('ArticleSubscriptionBanner', wrapComponent(ArticleSubscriptionBanner));
components.set('BannerQuickLinks', wrapComponent(BannerQuickLinks));
components.set('PressReleaseCards', wrapComponent(PressReleaseCards));
components.set('PressReleaseFilterBar', wrapComponent(PressReleaseFilterBar));
components.set('SeriesArticles', wrapComponent(SeriesArticles));
components.set('PressReleaseHero', wrapComponent(PressReleaseHero));
components.set('ArticleSeriesBanner', wrapComponent(ArticleSeriesBanner));
components.set('ArticleBottomContainer', wrapComponent(ArticleBottomContainer));
components.set('FundCentreMainTabs', wrapComponent(FundCentreMainTabs));
components.set('MediaBlock', wrapComponent(MediaBlock));
components.set('TeamProfileCards', wrapComponent(TeamProfileCards));
components.set('TeamProfileCardsWithImages', wrapComponent(TeamProfileCardsWithImages));
components.set('TeamMemberBiography', wrapComponent(TeamMemberBiography));
components.set('InvestmentsHero', wrapComponent(InvestmentsHero));
components.set('LogoTextBlocks', wrapComponent(LogoTextBlocks));
components.set('LatestPressReleasesSection', wrapComponent(LatestPressReleasesSection));
components.set('DynamicRelatedArticlesSection', wrapComponent(DynamicRelatedArticlesSection));
components.set('FundDetailsBanner', wrapComponent(FundDetailsBanner));
components.set('LiteratureHub', wrapComponent(LiteratureHub));
components.set('FundDetailsTabs', wrapComponent(FundDetailsTabs));
components.set('FundDetailsImportantInfo', wrapComponent(FundDetailsImportantInfo));
components.set('FundDetailsPreliminaryImportantInfo', wrapComponent(FundDetailsPreliminaryImportantInfo));
components.set('HelpArticleHero', wrapComponent(HelpArticleHero));
components.set('HelpArticleBody', wrapComponent(HelpArticleBody));
components.set('HelpNextSteps', wrapComponent(HelpNextSteps));
components.set('RelatedHelpPlatform', wrapComponent(RelatedHelpPlatform));
components.set('RelatedTaggedHelp', wrapComponent(RelatedTaggedHelp));
components.set('StrategyInFocus', wrapComponent(StrategyInFocus));
components.set('PremiumArticleEloquaForm', wrapComponent(PremiumArticleEloquaForm));
components.set('ShareScreen', wrapComponent(ShareScreen));
components.set('AlertMessage', wrapComponent(AlertMessage));
components.set('SpotifyPodcast', wrapComponent(SpotifyPodcast));
components.set('AutomaticDocumentListings', wrapComponent(AutomaticDocumentListings));
components.set('Form-LPP48', wrapComponent(FormLPP48));

//list of components that reuse existing components

export default function componentFactory(componentName) {
    return components.get(componentName);
}
