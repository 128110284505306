export function setFilters(state, filters) {
    state.filters = filters;
}
export function setData(state, data) {
    /*
     * Available setDataRules: {
     *   preserveArticles: Boolean
     * }
     * */
    data.results.forEach((article) => {
        if (!article.document?.url && article.document?.name) {
            article.document.url = `${state.baseUrl || ''}/${article.document.name}`;
        }
    });
    if (data.setDataRules?.preserveArticles) {
        state.articles = [...state.articles, ...data.results];
    } else {
        state.articles = data.results;
    }
    let facets = [];
    data.facets.forEach((item) => {
        facets = [...facets, ...item.values];
    });
    state.filtersFacets = facets;
    state.totalResults = data.totalResults;
}
export function setLoading(state, loading) {
    state.loading = loading;
}
export function setFiltersForApprove(state, filters) {
    state.filtersForApprove = filters;
}
export function setApprovedFilters(state, filters) {
    state.approvedFilters = filters;
}
export function setApprovedFiltersBuffer(state) {
    state.approvedFiltersBuffer = state.approvedFilters;
}
export function setFacetsData(state, data) {
    let facets = [];
    data.facets.forEach((item) => {
        facets = [...facets, ...item.values];
    });
    state.filtersFacets = facets;
}

export function setEmptyFacetsData(state, data) {
    let facets = [];
    data.facets.forEach((item) => {
        facets = [...facets, ...item.values];
    });
    state.emptyFacets = facets.map((facet) => facet.name);
}
export function setBaseUrl(state, url) {
    state.baseUrl = url;
}
export function setPaginationData(state, data) {
    state.page = data.page;
    state.take = data.take;
}
export function setFilterMappings(state, data) {
    state.filterMappings = data;
}
