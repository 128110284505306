<template>
    <NextStepsCommon v-if="relatedHelpLength || isExperienceEditor" v-bind="passThroughProps" />
</template>

<script>
import ElementFinder from '@/mixins/ElementFinder';

import NextStepsCommon from './NextStepsCommon';

export default {
    name: 'HelpNextSteps',

    components: {
        NextStepsCommon,
    },

    mixins: [ElementFinder],

    props: {
        rendering: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            relatedHelpPlatformComponent: null,
        };
    },

    computed: {
        relatedHelpLength() {
            return this.relatedHelpPlatformComponent?.fields?.relatedHelp?.value?.length;
        },
        passThroughProps() {
            return {
                rendering: this.rendering,
                title: this.$t('helpNextStepsTitle'),
                description: this.$t('helpNextStepsDescription'),
                placeholderName: 'abrdn-help-article-next-steps-content',
            };
        },
    },

    mounted() {
        this.findComponentInTree(
            this.$jss.routeData().placeholders['abrdn-main'],
            'RelatedHelpPlatform',
            'relatedHelpPlatformComponent',
        );
    },
};
</script>
