<template>
    <div>
        <div class="footnote footnote--bold text-grey">
            {{ authorsLength === 1 ? $t('author') : $t('authors') }}
        </div>

        <div class="mt-2">
            <div class="row mt-n2">
                <div v-for="author in authors" :key="author.name" class="col-12 col-md-6 col-lg-12 mt-2">
                    <ArticleAuthor :author="author" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ArticleAuthor from './ArticleAuthor';

export default {
    name: 'ArticleAuthors',
    components: {
        ArticleAuthor,
    },
    props: {
        authors: {
            type: Array,
            default: null,
        },
    },
    computed: {
        authorsLength() {
            return this.authors?.length;
        },
    },
};
</script>

<style lang="scss" scoped></style>
