<template>
    <section
        v-if="showComponent"
        :id="anchorId"
        :data-e2e-component-uid="rendering.uid"
        class="default-section-padding background-white"
    >
        <div class="container">
            <Placeholder class="mb-4" :rendering="rendering" name="abrdn-tiles-heading" />
        </div>
        <div :class="backgroundClass">
            <div class="container">
                <div class="row">
                    <div
                        v-for="item in fields.items"
                        :key="item.id"
                        class="my-2"
                        :class="[mobileItemColClass, highlightItemColClass]"
                    >
                        <HighlightItem :fields="item.fields" :numberOfItems="numberOfItems" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';

import HighlightItem from '@/components/Tiles/HighlightItem';
import { backgroundClass } from '@/mixins/Helpers.mixin';

export default {
    name: 'HighlightSection',
    components: { HighlightItem, Placeholder },
    mixins: [backgroundClass],
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        highlightItemColClass() {
            switch (this.numberOfItems) {
                case 2:
                    return 'col-md-6';
                case 4:
                    return 'col-md-3';
                default:
                    return 'col-md-4';
            }
        },
        numberOfItems() {
            return this.fields?.items?.length;
        },
        showComponent() {
            return this.numberOfItems >= 1 && this.numberOfItems < 7;
        },
        oneOrThreeItems() {
            return this.numberOfItems === 1 || this.numberOfItems === 3;
        },
        mobileItemColClass() {
            return this.oneOrThreeItems ? 'col-12' : 'col-6';
        },
    },
};
</script>

<style scoped lang="scss"></style>
