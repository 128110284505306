<template>
    <FilterBarFunctional
        :data-e2e-component-uid="rendering.uid"
        :formattedMapping="filterMappings"
        :vuexData="vuexData"
    />
</template>

<script>
import FilterBarFunctional from '@/components/Functional/ArticleFilterBar/FilterBar.vue';

export default {
    name: 'ArticleFilterBar',
    components: { FilterBarFunctional },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        filterMappings() {
            return this.fields.filterMappings.value;
        },
        vuexData() {
            return {
                filterBar: {
                    state: {
                        namespace: 'articles',
                        items: [
                            'filters',
                            'filtersForApprove',
                            'approvedFilters',
                            'filtersFacets',
                            'emptyFacets',
                            'page',
                            'take',
                            'loading',
                            'totalResults',
                            'articles',
                            'filtersForApprove',
                        ],
                    },
                    actions: {
                        namespace: 'articles',
                        items: ['getArticles', 'getFacets', 'getEmptyFacets'],
                    },
                    mutations: {
                        namespace: 'articles',
                        items: [
                            'setFilters',
                            'setFiltersForApprove',
                            'setApprovedFilters',
                            'setBaseUrl',
                            'setFilterMappings',
                            'setPaginationData',
                        ],
                    },
                },
            };
        },
    },
};
</script>
