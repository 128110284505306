<template>
    <table v-if="!showSkeleton" class="w-100" aria-label="Literature hub table">
        <thead>
            <tr class="text-nowrap">
                <th scope="col">
                    {{ $t('literatureHubNameFilterLabel') }}
                </th>
                <th scope="col">
                    {{ $t('literatureHubDocumentCodeFilterLabel') }}
                </th>
                <th scope="col">
                    {{ $t('literatureHubDocumentTypeFilterLabel') }}
                </th>
                <th scope="col">
                    {{ $t('literatureHubPublishedDateFilterLabel') }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="document in documents" :key="document.id">
                <td>
                    <ScLink
                        :field="createLink(document)"
                        :showExternalIcon="false"
                        class="h6 hover-underline d-inline-block align-bottom"
                    >
                        <div class="line-clamp-4">{{ document.title }}</div>
                    </ScLink>
                </td>
                <td>{{ checkForValue(document.editionId) }}</td>
                <td>{{ checkForValue(document.documentCategory) }}</td>
                <td>{{ checkForValue(localizeDate(document.lastModified)) }}</td>
            </tr>
        </tbody>
    </table>
    <table v-else class="not-striped minimal w-100" aria-label="Literature hub skeleton table">
        <thead>
            <tr class="my-4">
                <th scope="col" class="section-header-small skeleton-row">
                    <BaseSkeleton class="w-100" height="56px" />
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(td, index) in paginationData.take" :key="index" class="w-100">
                <td class="w-100 skeleton-row px-0 py-3">
                    <BaseSkeleton class="w-100 d-none d-md-block" height="48px" />
                    <BaseSkeleton class="w-100 d-block d-md-none" height="56px" />
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import { mapState } from 'vuex';

import BaseSkeleton from '@/components/Generic/BaseSkeleton.vue';
import ScLink from '@/components/Generic/SitecoreWrappers/ScLink.vue';
import { localizeDate } from '@/mixins/Helpers.mixin';

export default {
    name: 'LiteratureHubTable',
    components: { BaseSkeleton, ScLink },
    mixins: [localizeDate],
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        ...mapState('literatureHub', ['documents', 'paginationData', 'isLoading', 'isPaginationLoading']),
        showSkeleton() {
            return this.isLoading && !this.isPaginationLoading;
        },
    },
    methods: {
        createLink(document) {
            return {
                value: {
                    href: '/library/' + document.documentId,
                    target: '_blank',
                },
            };
        },
        checkForValue(text) {
            return text || this.$t('notAvailableShortMessage');
        },
    },
};
</script>

<style scoped lang="scss">
.skeleton-row {
    border-bottom: 0px solid !important;
}
</style>
