<template>
    <div v-if="isExperienceEditor || fields.image" :id="anchorId" class="img-wrapper mt-6">
        <WrappedImage
            v-if="isExperienceEditor || fields.image"
            class="sc-image"
            :media="fields.image"
            :srcSet="[{ mw: 600 }, { mw: 800 }, { mw: 1000 }, { mw: 1200 }]"
            sizes="
                (max-width: 575px) 100vw,
                (max-width: 767px) 510px,
                (max-width: 991px) 450px,
                (max-width: 1199px) 50vw,
                570px
            "
        />
    </div>
</template>

<script>
import WrappedImage from '@/components/Generic/SitecoreWrappers/WrappedImage.vue';

export default {
    name: 'HighlightBlockImage',
    components: {
        WrappedImage,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
};
</script>

<style scoped lang="scss">
.img-wrapper {
    ::v-deep img {
        object-fit: cover;
        object-position: center;
        height: auto;
        width: 100%;
    }
}
</style>
