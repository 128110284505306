<template>
    <FundDetailsTabRow fullWidth class="mt-4">
        <FundDetailsTabSkeleton v-if="loading" />
        <AlertMessageBase v-else-if="error" type="error" :title="$t(error)" />
        <table v-else-if="tabData" aria-label="Fund details codes tab table" class="w-100">
            <thead hidden>
                <tr>
                    <th scope="col">Fund code name</th>
                    <th scope="col">Fund code value</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, key) in tabData" :key="item">
                    <td class="h6 w-50 py-4">{{ $t(dictionaryItems[key]) }}</td>
                    <td class="body-default w-50 py-4">{{ item }}</td>
                </tr>
            </tbody>
        </table>
        <div v-else class="body-default">{{ $t('noResultsMessage') }}</div>
    </FundDetailsTabRow>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import AlertMessageBase from '@/components/Generic/AlertMessageBase';

import FundDetailsTabRow from '../FundDetailsTabRow';
import FundDetailsTabSharedLogic from '../FundDetailsTabSharedLogic.mixin';
import FundDetailsTabSkeleton from '../FundDetailsTabSkeleton';

export default {
    name: 'FundDetailsCodesTab',

    components: {
        AlertMessageBase,
        FundDetailsTabRow,
        FundDetailsTabSkeleton,
    },

    mixins: [FundDetailsTabSharedLogic],

    data() {
        return {
            dictionaryItems: {
                sedol: 'fundCodesSedol',
                isin: 'fundCodesIsin',
                bloomberg: 'fundCodesBloomberg',
                citicode: 'fundCodesCiticode',
                ric: 'fundCodesRic',
                valoren: 'fundCodesValoren',
                wkn: 'fundCodesWkn',
                lipper: 'fundCodesLipper',
                ticker: 'fundCodesTicker',
                cusip: 'fundCodesCusip',
                apir: 'fundCodesApir',
                arsn: 'fundCodesArsn',
            },
        };
    },

    computed: {
        ...mapState('fundDetails', {
            loading: (state) => state.codesTabData.loading,
            error: (state) => state.codesTabData.error,
            tabData: (state) => state.codesTabData.data,
        }),
    },

    methods: {
        ...mapActions('fundDetails', {
            fetchTabData: 'fetchCodesTabData',
        }),
    },
};
</script>

<style scoped lang="scss">
tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
</style>
