<template>
    <FundDetailsTabRow fullWidth class="mt-4">
        <div class="row">
            <div class="col-12 col-lg-6">
                <FundDetailsTabSkeleton v-if="loading" />

                <AlertMessageBase v-else-if="error" type="error" :title="$t(error)" />

                <template v-else-if="tabData">
                    <StaticImage
                        v-if="riskProfileImage.url"
                        :src="riskProfileImage.url"
                        :alt="riskProfileImage.alt"
                        class="risk-profile-image"
                        :srcSet="[{ mw: 400 }, { mw: 500 }, { mw: 600 }, { mw: 700 }, { mw: 800 }]"
                        sizes="400px"
                    />

                    <div v-else class="d-flex flex-column gap-3">
                        <h4>{{ $t('riskAndRewardProfile') }}</h4>

                        <div class="d-flex justify-content-between">
                            <div class="subheader-2 text-start">{{ $t('lowerRisk') }}</div>
                            <div class="subheader-2 text-end">{{ $t('higherRisk') }}</div>
                        </div>

                        <div class="arrow">
                            <div class="arrow__head arrow__head--left" />
                            <div class="arrow__head arrow__head--right" />
                        </div>

                        <div class="d-flex justify-content-between">
                            <div class="subheader-2 text-start">{{ $t('potentialLowerRewards') }}</div>
                            <div class="subheader-2 text-end">{{ $t('potentialHigherRewards') }}</div>
                        </div>

                        <div class="d-flex gap-1">
                            <div
                                v-for="(_, index) in Array(7)"
                                :key="index"
                                class="risk-level"
                                :class="{ 'risk-level--active': riskLevel === index + 1 }"
                            >
                                <span class="h3">{{ index + 1 }}</span>
                            </div>
                        </div>

                        <p class="footnote text-grey">{{ riskProfileFootnote }}</p>
                    </div>
                </template>

                <div v-else class="body-default">{{ $t('noResultsMessage') }}</div>
            </div>
        </div>
    </FundDetailsTabRow>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import AlertMessageBase from '@/components/Generic/AlertMessageBase';
import StaticImage from '@/components/Generic/SitecoreWrappers/StaticImage';

import FundDetailsTabRow from '../FundDetailsTabRow';
import FundDetailsTabSharedLogic from '../FundDetailsTabSharedLogic.mixin';
import FundDetailsTabSkeleton from '../FundDetailsTabSkeleton';

export default {
    name: 'FundRiskStatisticsTab',

    components: {
        FundDetailsTabRow,
        StaticImage,
        AlertMessageBase,
        FundDetailsTabSkeleton,
    },

    mixins: [FundDetailsTabSharedLogic],

    computed: {
        ...mapState('fundDetails', {
            loading: (state) => state.fundRiskStatisticsTabData.loading,
            error: (state) => state.fundRiskStatisticsTabData.error,
            tabData: (state) => state.fundRiskStatisticsTabData.data,
        }),

        riskProfileImage() {
            const riskProfileImage = this.tabData?.riskProfileImage;

            return {
                url: riskProfileImage?.url,
                alt: riskProfileImage?.alt,
            };
        },

        riskLevel() {
            return this.tabData?.riskLevel;
        },

        riskProfileFootnote() {
            return this.tabData?.riskProfileFootnote;
        },
    },

    methods: {
        ...mapActions('fundDetails', {
            fetchTabData: 'fetchFundRiskStatisticsTabData',
        }),
    },
};
</script>

<style scoped lang="scss">
.risk-profile-image {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: calc(var(--spacer) * 50);
}

.arrow {
    position: relative;
    width: 100%;
    height: 2px;
    border-radius: 9999px;
    background-color: currentColor;

    &__head {
        position: absolute;
        top: 0;

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 0;
            width: 10px;
            height: 2px;
            border-radius: 9999px;
            background-color: currentColor;
        }

        &::before {
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(-45deg);
        }

        &--left {
            left: 0;

            &::before,
            &::after {
                left: 0;
                transform-origin: 1px 1px;
            }
        }

        &--right {
            right: 0;

            &::before,
            &::after {
                right: 0;
                transform-origin: calc(100% - 1px) 1px;
            }
        }
    }
}

.risk-level {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    aspect-ratio: 1;
    background-color: var(--digital-light-grey);

    &--active {
        background-color: var(--digital-deep-grey);
        color: var(--abrdn-white);
    }
}
</style>
