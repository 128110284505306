<template>
    <AccordionNew v-if="presentation === 'new'" :title="title" :description="description" :isFirst="isFirst">
        <slot />
    </AccordionNew>
    <AccordionDefault v-else :title="title" :description="description">
        <slot />
    </AccordionDefault>
</template>

<script>
import AccordionDefault from '@/components/Generic/AccordionDefault';
import AccordionNew from '@/components/Generic/AccordionNew';

export default {
    name: 'AccordionItem',
    components: {
        AccordionDefault,
        AccordionNew,
    },
    props: {
        title: {
            type: Object,
            default: () => ({}),
        },
        description: {
            type: Object,
            default: () => ({}),
        },
        isFirst: {
            type: Boolean,
            default: true,
        },
        presentation: {
            type: String,
            default: 'default',
        },
    },
};
</script>
<style scoped lang="scss">
::v-deep p {
    white-space: pre-line;
    margin-bottom: 24px;
}

::v-deep a {
    font-weight: 600;
    text-decoration: underline !important;
    cursor: pointer;
}

::v-deep p:last-child {
    margin-bottom: 0;
}
</style>
