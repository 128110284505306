<template>
    <div class="container d-flex" :data-e2e-component-uid="rendering.uid">
        <div class="breadcrumbs">
            <ol itemscope itemtype="https://schema.org/BreadcrumbList" class="d-flex mb-0 ps-0">
                <li
                    v-for="(breadcrumb, index) in breadcrumbs"
                    :key="breadcrumb.id"
                    itemprop="itemListElement"
                    itemscope
                    itemtype="https://schema.org/ListItem"
                    class="text-white body-small pe-1"
                    :aria-hidden="index === breadcrumbs.length - 1 && true"
                >
                    <router-link :to="breadcrumb.url" itemprop="item" :tabindex="index === breadcrumbs.length - 1 && -1"
                        ><span itemprop="name">{{
                            breadcrumb.name.startsWith('Abrdn') ? '' : breadcrumb.name
                        }}</span></router-link
                    >
                    <meta :itemprop="'position'" :content="index + 1" />
                </li>
            </ol>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'Breadcrumbs',
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        ...mapState('fundDetails', ['name']),
        breadcrumbs() {
            const breadcrumbs = this.fields.breadcrumbs.value;
            breadcrumbs.forEach((item) => {
                // workaround to make name in breadcrumbs same as fund name
                if (item.name.startsWith('Abrdn') && this.name !== '') {
                    item.name = this.name;
                }
            });
            return breadcrumbs;
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '@/assets/styles/mixins';
@import '~bootstrap/scss/variables';
@import '@/assets/styles/helpers/background-maps';

html[data-page-theme^='financialfairness'] {
    .breadcrumbs {
        background: var(--primary);

        &::before {
            background: var(--primary);
        }

        li:last-child {
            color: $light-green !important;
            a {
                pointer-events: none;
            }
        }
    }
}
.breadcrumbs {
    padding: 6px 24px 6px 0;
    background: var(--abrdn-black);
    @include media-breakpoint-down(md) {
        background: var(--abrdn-white) !important;
    }
    @include reverse-colors-white();
    @each $light-background in $light-backgrounds {
        #{$light-background} {
            @include reverse-text-colors-black();
        }
    }
    flex-grow: 0;
    position: relative;
    display: flex;
    &::before {
        content: '';
        background: var(--abrdn-black);
        @include media-breakpoint-down(md) {
            background: var(--abrdn-white) !important;
        }
        height: 100%;
        width: 100vw;
        display: block;
        position: absolute;
        top: 0;
        left: -100vw;
    }
    li {
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-left: 3px;
        padding-left: 2px;
        @include media-breakpoint-down(md) {
            display: none;
            color: var(--abrdn-black) !important;
            &:last-child,
            &:first-child {
                display: block;
                & {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 180px;
                    position: relative;
                    padding-right: 20px !important;
                }
            }
        }
        &:hover {
            text-decoration: underline;
        }
        &::after {
            content: '';
            width: 4px;
            height: 4px;
            border-radius: 2px;
            display: flex;
            background: var(--dark-grey);
            margin-left: 8px;
            @include media-breakpoint-down(md) {
                margin-left: 0;
                top: 8px;
                right: 12px;
                position: absolute;
            }
        }
    }

    li {
        list-style: none;
        &:last-child {
            color: var(--digital-steel-grey) !important;
            &:hover {
                text-decoration: none;
            }
            pointer-events: none;
            &::after {
                display: none;
            }
        }
    }
}

.body-small {
    letter-spacing: 0.03em;
    font-weight: 600;
}
</style>
