<template>
    <div class="dummy-block">
        <ScText v-if="fields.heading" tag="h2" :field="fields.heading" />
        <ScText v-if="fields.content" tag="p" :field="fields.content" />
    </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

export default {
    name: 'DummyBlock',
    components: {
        ScText: Text,
    },
    props: {
        fields: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>

<style scoped></style>
