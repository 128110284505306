<template>
    <section
        :id="anchorId"
        class="feature-banner-section overflow-hidden pb-5 py-md-6 py-lg-0"
        :class="{ [params.background]: params.background }"
        :data-e2e-component-uid="rendering.uid"
    >
        <div class="container">
            <div
                class="feature-banner row d-flex gx-lg-6"
                :class="{ 'feature-banner--right-img': params.imagePosition === 'right' }"
            >
                <div class="feature-banner__image-column col-md-6">
                    <div class="feature-banner__image">
                        <WrappedImage
                            :media="fields.image"
                            :srcSet="[{ mw: 400 }, { mw: 600 }, { mw: 800 }, { mw: 1000 }, { mw: 1200 }]"
                            sizes="
                                (max-width: 575px) 100vw,
                                (max-width: 767px) 510px,
                                (max-width: 991px) 330px,
                                (max-width: 1199px) 50vw,
                                555px
                            "
                            :lazy="false"
                        />
                    </div>
                </div>
                <div class="col-md-6 align-self-lg-center py-lg-9">
                    <div class="feature-banner__content mt-4 mt-md-0 background-to-change">
                        <ScText v-if="fields.title" tag="h2" :field="fields.title" />
                        <ScText v-if="fields.subtitle" tag="p" class="subheader-1 mt-3" :field="fields.subtitle" />
                        <ScText v-if="fields.description" tag="p" class="body-large mt-2" :field="fields.description" />
                        <AbrdnButton
                            v-if="isValueExists(fields.cta)"
                            :linkField="fields.cta"
                            :buttonType="buttonType"
                            class="mt-5 background-to-change"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton.vue';
import WrappedImage from '@/components/Generic/SitecoreWrappers/WrappedImage.vue';

export default {
    name: 'FeatureBanner',
    components: {
        ScText: Text,
        WrappedImage,
        AbrdnButton,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        params: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        linkDescLengthTooLong() {
            return this.fields?.cta?.value?.text?.length > 30;
        },
        buttonType() {
            return this.linkDescLengthTooLong ? 'textLink' : 'primary';
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
.feature-banner--right-img {
    flex-direction: row-reverse;
    .feature-banner__content {
        @include media-breakpoint-only(lg) {
            margin-left: calc(var(--spacer) * 2);
        }
    }
}
.feature-banner__image-column {
    @include media-breakpoint-down(sm) {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .feature-banner__image {
        @include media-breakpoint-up(lg) {
            height: 100%;
        }
        ::v-deep img {
            width: 100%;
            height: auto;
            object-fit: cover;
            @include media-breakpoint-up(lg) {
                min-height: 100%;
            }
        }
    }
}
</style>
