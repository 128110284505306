<template>
    <div :id="anchorId" class="global-navigation-root">
        <div class="container d-flex justify-content-end">
            <SiteSelector :class="hasLanguageSelector ? 'me-4' : 'me-auto'" />
            <div v-show="false" v-if="languageLinks.length">
                <a v-for="lang in languageLinks" :key="lang && lang.hreflang" :href="lang && lang.href" />
            </div>
            <GlobalNavigationDropdown v-if="hasLanguageSelector" class="dropdown me-auto d-none d-xl-flex" :top="24">
                <template #trigger-area>
                    <div
                        ref="languageSelector"
                        class="background-white h-100 px-2 d-flex align-items-center body-small"
                    >
                        <StaticImage
                            :src="icon.url"
                            :alt="icon.alt"
                            class="lang-selector-icon"
                            :srcSet="[{ mw: 50 }]"
                            sizes="16px"
                        />
                        <p class="ms-1">{{ selectedOption.languageName }}</p>
                        <IconChevron class="chevron-icon ms-1" />
                    </div>
                </template>
                <div v-if="getLanguageVersions && getLanguageVersions.length" class="lang-selector-dropdown">
                    <button
                        v-for="(item, i) in getLanguageVersions"
                        :key="i"
                        type="button"
                        class="btn btn-naked lang-selector-item p-2 form-label w-100"
                        :class="{ selected: item.hreflang === selectedOption.hreflang }"
                        @click="
                            selectLanguage(item);
                            $refs.languageSelector.click();
                        "
                        @keyup.enter="
                            selectLanguage(item);
                            $refs.languageSelector.click();
                        "
                    >
                        {{ item.label }}
                    </button>
                </div>
            </GlobalNavigationDropdown>
            <ScLink
                v-if="hasSharePrice"
                :field="sharePriceCta"
                :replaceWithSlot="true"
                :showExternalIcon="false"
                class="text-white body-small d-none d-md-flex align-items-center me-3"
            >
                <span class="text-decoration-underline">{{ $t('sharePrice') }}:</span>
                <span class="share-price-value ms-1">{{ sharePrice }} {{ $t('sharePriceCurrency') }}</span>
            </ScLink>
            <ScLink
                v-if="secondaryCtaExist"
                :field="globalNavFields.fields.ctaSecondary"
                :replaceWithSlot="false"
                :showExternalIcon="false"
                class="body-default navigation-link secondary-link py-0 px-2 mx-2 d-md-flex align-items-center"
            >
                <em
                    v-if="getSecondaryIcon"
                    :class="'bi-' + getSecondaryIcon"
                    class="text-black login-section-icon ms-1"
                />
            </ScLink>
            <ScLink
                v-if="ctaExist"
                ref="login-section-link"
                :field="globalNavFields.fields.cta"
                :replaceWithSlot="false"
                :showExternalIcon="false"
                class="body-default navigation-link py-0 px-2 d-none d-md-flex align-items-center"
            >
                <em v-if="getPrimaryIcon" :class="'bi-' + getPrimaryIcon" class="text-black login-section-icon ms-1" />
            </ScLink>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import GlobalNavigationDropdown from '@/components/Generic/Header/GlobalNavigation/GlobalNavigationDropdown.vue';
import SiteSelector from '@/components/Generic/Header/GlobalNavigation/SiteSelector.vue';
import ScLink from '@/components/Generic/SitecoreWrappers/ScLink.vue';
import StaticImage from '@/components/Generic/SitecoreWrappers/StaticImage';
import IconChevron from '@/components/Icons/icon-chevron.vue';
import SharePrice from '@/mixins/SharePrice.mixin';

export default {
    name: 'GlobalNavigation',
    components: {
        GlobalNavigationDropdown,
        IconChevron,
        SiteSelector,
        ScLink,
        StaticImage,
    },
    mixins: [SharePrice],
    props: {
        globalNavFields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    data() {
        return {
            icon: {
                url: require('@/assets/img/custom-svg/artwork.svg'),
                alt: this.$t('changeLanguage'),
            },
            options: [
                {
                    name: 'monthly',
                    displayName: 'English',
                    selected: true,
                },
                {
                    name: 'chez republic',
                    displayName: 'Français',
                    selected: false,
                },
                {
                    name: 'chez republic',
                    displayName: 'Deutsch',
                    selected: false,
                },
            ],
            selectedLanguage: 'English',
        };
    },
    computed: {
        ...mapGetters('language', ['getLanguageVersions']),
        secondaryCtaExist() {
            return (
                (this.globalNavFields.fields?.ctaSecondary?.value?.href &&
                    this.globalNavFields.fields?.ctaSecondary?.value?.text) ||
                this.isExperienceEditor
            );
        },
        getSecondaryIcon() {
            return this.globalNavFields?.fields?.iconSecondary?.value;
        },
        getPrimaryIcon() {
            return this.globalNavFields?.fields?.icon?.value;
        },
        ctaExist() {
            return (
                (this.globalNavFields.fields?.cta?.value?.href && this.globalNavFields.fields?.cta?.value?.text) ||
                this.isExperienceEditor
            );
        },
        languages() {
            return {
                label: 'LabelName',
                errorMessage: 'An error occurred',
                placeholder: 'Placeholder',
            };
        },
        languageLinks() {
            return this.$jss.sitecoreContext().languageVersions.filter((item) => item.applicableForSelector);
        },
        selectedOption() {
            const lang = this.$jss.sitecoreContext()?.displayLanguage;

            return this.getLanguageVersions.filter((item) => {
                return item?.hreflang === lang;
            })[0];
        },
        hasLanguageSelector() {
            return this.getLanguageVersions && this.selectedOption && this.getLanguageVersions.length > 1;
        },
    },
    methods: {
        selectLanguage(item) {
            this.$router.push(item.href);
        },
    },
};
</script>

<style scoped lang="scss">
.global-navigation-root {
    background: var(--abrdn-black);
}
.navigation-link {
    height: 24px;
    background: var(--digital-steel-grey);
    border: none;
    ::v-deep a {
        color: var(--abrdn-black);
    }
}
.login-section-icon {
    font-size: 11px;
}
.secondary-link {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.lang-selector-icon {
    width: 16px;
    height: 16px;
}
.lang-selector-dropdown {
    background: var(--abrdn-black);
    min-width: 160px;

    .selected {
        background: var(--digital-ash-grey);
    }
    .lang-selector-item {
        color: var(--abrdn-white);
        white-space: nowrap;
    }
    .lang-selector-item {
        outline-color: white;
    }
    .lang-selector-item:not(.selected) {
        &:hover {
            background: var(--button-mist);
            color: var(--abrdn-black);
            text-decoration: underline;
        }
    }
}
.share-price-value {
    font-weight: 600;
}

.chevron-icon,
::v-deep .chevron-icon {
    width: 0.72em;
    height: 0.72em;
    transition: transform 0.2s ease;
    flex-shrink: 0;
}
[aria-expanded='true'] .chevron-icon,
::v-deep [aria-expanded='true'] .chevron-icon {
    transform: rotate(180deg);
}
</style>
