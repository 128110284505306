<template>
    <ScRichText :id="anchorId" :class="{ [`spacing-${spacingType}`]: spacingType }" :field="fields.description" />
</template>

<script>
import { RichText } from '@sitecore-jss/sitecore-jss-vue';

export default {
    name: 'RichText',
    components: {
        ScRichText: RichText,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        spacingType: {
            type: String,
            default: () => 'default',
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep > *:not(:first-child) {
    margin-top: calc(var(--spacer) * 5);
}

.spacing-tight ::v-deep > *:not(:first-child) {
    margin-top: calc(var(--spacer) * 3);
}

::v-deep br {
    margin: 0 !important;
}
::v-deep a {
    text-decoration: underline;
    font-weight: 600;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    ::v-deep & {
        margin-bottom: calc(var(--spacer) * 2);
        + * {
            margin: 0 !important;
        }
    }
}
</style>
