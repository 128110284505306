export const formFieldsStep1 = {
    rows: [
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                            element: {
                                type: 'FormFieldGroup',
                                id: '334',
                                name: 'Firm details (step 1 of 7)',
                                instructions: '',
                                style: {
                                    textType: 'header',
                                    fieldSize: 'large',
                                    guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                                },
                            },
                        },
                    ],
                },
            ],
            type: 'FormFieldGroup',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '00N6900000Kvzsw',
                            element: {
                                type: 'FormField',
                                id: 'Firm_name__c',
                                name: 'Name of legal entity',
                                relatesTo: {
                                    value: 'Firm details',
                                },
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kvzsw',
                                },
                                dataType: 'text',
                                displayType: 'text',
                                htmlName: '00N6900000Kvzsw',
                                validations: [
                                    {
                                        type: 'FieldValidation',
                                        id: '19599',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventUrlCondition' },
                                        isEnabled: 'true',
                                        message: "Value must not contain any URL's",
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19600',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventXSSCondition' },
                                        isEnabled: 'true',
                                        message: 'Value must not contain any HTML',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19601',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: {
                                            type: 'TextLengthCondition',
                                            maximum: '255',
                                            minimum: '0',
                                        },
                                        isEnabled: 'true',
                                        message: 'Invalid length for field value',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19602',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'IsRequiredCondition' },
                                        isEnabled: 'true',
                                        message: 'Please enter name of legal entity',
                                    },
                                ],
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '00N6900000Kvzsw',
                            element: {
                                type: 'FormField',
                                id: 'FCA_Authorisation_Number__c',
                                name: 'FCA authorisation number',
                                relatesTo: {
                                    value: 'Firm details',
                                },
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kvzsw',
                                },
                                dataType: 'text',
                                displayType: 'text',
                                htmlName: '00N6900000Kvzsw',
                                validations: [
                                    {
                                        type: 'FieldValidation',
                                        id: '19599',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventUrlCondition' },
                                        isEnabled: 'true',
                                        message: "Value must not contain any URL's",
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19600',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventXSSCondition' },
                                        isEnabled: 'true',
                                        message: 'Value must not contain any HTML',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19601',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: {
                                            type: 'TextLengthCondition',
                                            maximum: '80',
                                            minimum: '0',
                                        },
                                        isEnabled: 'true',
                                        message: 'Invalid length for field value',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19602',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'IsRequiredCondition' },
                                        isEnabled: 'true',
                                        message: 'Please enter a last name',
                                    },
                                ],
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '00N6900000Kvzsw',
                            element: {
                                type: 'FormField',
                                id: 'Email__c',
                                name: 'Contact email address',
                                relatesTo: {
                                    value: 'Firm details',
                                },
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kvzsw',
                                },
                                dataType: 'text',
                                displayType: 'text',
                                htmlName: 'emailAddress',
                                validations: [
                                    {
                                        type: 'FieldValidation',
                                        id: '19599',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventUrlCondition' },
                                        isEnabled: 'true',
                                        message: "Value must not contain any URL's",
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19600',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventXSSCondition' },
                                        isEnabled: 'true',
                                        message: 'Value must not contain any HTML',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19601',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: {
                                            type: 'TextLengthCondition',
                                            maximum: '255',
                                            minimum: '0',
                                        },
                                        isEnabled: 'true',
                                        message: 'Invalid length for field value',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19602',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'IsRequiredCondition' },
                                        isEnabled: 'true',
                                        message: 'Please enter contact email address',
                                    },
                                ],
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '00N6900000Kvzsw',
                            element: {
                                type: 'FormField',
                                id: 'Phone_number__c',
                                name: 'Phone number',
                                relatesTo: {
                                    value: 'Firm details',
                                },
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kvzsw',
                                },
                                dataType: 'text',
                                displayType: 'text',
                                htmlName: 'number',
                                validations: [
                                    {
                                        type: 'FieldValidation',
                                        id: '19599',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventUrlCondition' },
                                        isEnabled: 'true',
                                        message: "Value must not contain any URL's",
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19600',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventXSSCondition' },
                                        isEnabled: 'true',
                                        message: 'Value must not contain any HTML',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19601',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: {
                                            type: 'TextLengthCondition',
                                            maximum: '255',
                                            minimum: '0',
                                        },
                                        isEnabled: 'true',
                                        message: 'Invalid length for field value',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19602',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'IsRequiredCondition' },
                                        isEnabled: 'true',
                                        message: 'Please enter phone number',
                                    },
                                ],
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                            element: {
                                type: 'FormFieldGroup',
                                id: '334',
                                name: 'Address',
                                instructions: '',
                                style: {
                                    textType: 'subheader',
                                    fieldSize: 'large',
                                    guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                                },
                            },
                        },
                    ],
                },
            ],
            type: 'FormFieldGroup',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '00N6900000Kvzsw',
                            element: {
                                type: 'FormField',
                                id: 'Building_number__c',
                                name: 'Building number',
                                relatesTo: {
                                    value: 'Address',
                                },
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kvzsw',
                                },
                                dataType: 'text',
                                displayType: 'text',
                                htmlName: '00N6900000Kvzsw',
                                validations: [
                                    {
                                        type: 'FieldValidation',
                                        id: '19599',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventUrlCondition' },
                                        isEnabled: 'true',
                                        message: "Value must not contain any URL's",
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19600',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventXSSCondition' },
                                        isEnabled: 'true',
                                        message: 'Value must not contain any HTML',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19601',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: {
                                            type: 'TextLengthCondition',
                                            maximum: '50',
                                            minimum: '0',
                                        },
                                        isEnabled: 'true',
                                        message: 'Invalid length for field value',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19602',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'IsRequiredCondition' },
                                        isEnabled: 'true',
                                        message: 'Please enter building number',
                                    },
                                ],
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '00N6900000Kvzsw',
                            element: {
                                type: 'FormField',
                                id: 'Street__c',
                                name: 'Street name',
                                relatesTo: {
                                    value: 'Address',
                                },
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kvzsw',
                                },
                                dataType: 'text',
                                displayType: 'text',
                                htmlName: '00N6900000Kvzsw',
                                validations: [
                                    {
                                        type: 'FieldValidation',
                                        id: '19599',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventUrlCondition' },
                                        isEnabled: 'true',
                                        message: "Value must not contain any URL's",
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19600',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventXSSCondition' },
                                        isEnabled: 'true',
                                        message: 'Value must not contain any HTML',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19601',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: {
                                            type: 'TextLengthCondition',
                                            maximum: '100',
                                            minimum: '0',
                                        },
                                        isEnabled: 'true',
                                        message: 'Invalid length for field value',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19602',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'IsRequiredCondition' },
                                        isEnabled: 'true',
                                        message: 'Please enter street name',
                                    },
                                ],
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '00N6900000Kvzsw',
                            element: {
                                type: 'FormField',
                                id: 'City_Town__c',
                                name: 'City / Town',
                                relatesTo: {
                                    value: 'Address',
                                },
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kvzsw',
                                },
                                dataType: 'text',
                                displayType: 'text',
                                htmlName: '00N6900000Kvzsw',
                                validations: [
                                    {
                                        type: 'FieldValidation',
                                        id: '19599',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventUrlCondition' },
                                        isEnabled: 'true',
                                        message: "Value must not contain any URL's",
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19600',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventXSSCondition' },
                                        isEnabled: 'true',
                                        message: 'Value must not contain any HTML',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19601',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: {
                                            type: 'TextLengthCondition',
                                            maximum: '100',
                                            minimum: '0',
                                        },
                                        isEnabled: 'true',
                                        message: 'Invalid length for field value',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19602',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'IsRequiredCondition' },
                                        isEnabled: 'true',
                                        message: 'Please enter City / Town',
                                    },
                                ],
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '00N6900000Kvzsw',
                            element: {
                                type: 'FormField',
                                id: 'Postcode__c',
                                name: 'Postcode',
                                relatesTo: {
                                    value: 'Address',
                                },
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kvzsw',
                                },
                                dataType: 'text',
                                displayType: 'text',
                                htmlName: '00N6900000Kvzsw',
                                validations: [
                                    {
                                        type: 'FieldValidation',
                                        id: '19599',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventUrlCondition' },
                                        isEnabled: 'true',
                                        message: "Value must not contain any URL's",
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19600',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventXSSCondition' },
                                        isEnabled: 'true',
                                        message: 'Value must not contain any HTML',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19601',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: {
                                            type: 'TextLengthCondition',
                                            maximum: '20',
                                            minimum: '0',
                                        },
                                        isEnabled: 'true',
                                        message: 'Invalid length for field value',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19602',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'IsRequiredCondition' },
                                        isEnabled: 'true',
                                        message: 'Please enter postcode',
                                    },
                                ],
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
    ],
};
