import { render, staticRenderFns } from "./ArticleSeriesCard.vue?vue&type=template&id=ce745798&scoped=true"
import script from "./ArticleSeriesCard.vue?vue&type=script&lang=js"
export * from "./ArticleSeriesCard.vue?vue&type=script&lang=js"
import style0 from "./ArticleSeriesCard.vue?vue&type=style&index=0&id=ce745798&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce745798",
  null
  
)

export default component.exports