<template>
    <table class="d-flex flex-column" aria-label="Kids hub table">
        <tr>
            <th scope="col" class="section-header-small">
                {{ $t('fundName') }}
                <!-- Commented out sorting, until it is not in use -->
                <!-- <span class="ms-1 table-sorting-wrapper">
                    <i class="bi-caret-up-fill" :class="{ active: asc }" @click="sortTable('asc')" />
                    <i class="bi-caret-down-fill" :class="{ active: !asc }" @click="sortTable('dsc')"
                /></span> -->
            </th>
            <th scope="col" class="section-header-small">{{ $t('kidShareClass') }}</th>
            <th scope="col" class="section-header-small">{{ $t('pastPerformance') }}</th>
            <th scope="col" class="section-header-small">{{ $t('scenarioCalculation') }}</th>
            <th scope="col" class="section-header-small">{{ $t('kid') }}</th>
        </tr>
        <template v-if="rows.length">
            <tr v-for="(row, index) in rows" :key="row.id">
                <td class="body-default">{{ row.name }}</td>
                <td>
                    <AbrdnSelect :fields="showMore(row)" class="w-100" @updateValue="updateSelected(index, $event)" />
                </td>
                <td>
                    <button
                        v-if="row.shareclasses.length"
                        type="button"
                        class="btn-naked bi-box-arrow-up-right"
                        @click="$emit('togglePastPerformance', row)"
                    />
                </td>
                <td>
                    <button
                        v-if="row.shareclasses.length"
                        type="button"
                        class="btn-naked bi-box-arrow-up-right"
                        @click="$emit('togglePredictivePerformance', row)"
                    />
                </td>
                <td>
                    <AbrdnButton
                        v-if="row.showDownloadButton"
                        :buttonType="'primary'"
                        :linkField="getDownloadLink(row)"
                        :showExternalIcon="false"
                    >
                        <span>{{ $t('download') }}</span>
                        <em class="bi-download ms-1" aria-hidden="true" />
                    </AbrdnButton>
                </td>
            </tr>
        </template>
        <Loader v-if="isLoading && !rows.length" :size="100" class="mx-auto my-5" />
    </table>
</template>

<script>
import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton.vue';
import AbrdnSelect from '@/components/Generic/FormElements/FormFields/AbrdnSelect.vue';
import Loader from '@/components/Generic/Loader.vue';

export default {
    name: 'DesktopTable',
    components: {
        Loader,
        AbrdnSelect,
        AbrdnButton,
    },
    props: {
        rows: {
            type: Array,
            default: /* istanbul ignore next */ () => [],
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            asc: true,
        };
    },
    methods: {
        showMore(row) {
            const output = {
                placeholder: { value: this.$t('noResultsMessage') },
                disabled: { value: !row.shareclasses.length },
                options: {
                    value: [],
                },
            };
            row.shareclasses.forEach((value, i) => {
                output.options.value.push({
                    name: i,
                    displayName: value.shareclassName,
                    value: i,
                    selected: i == parseInt(row.selected) ? true : false,
                });
            });
            return output;
        },
        sortTable(order) {
            if (order == 'asc') {
                this.asc = true;
            } else {
                this.asc = false;
            }
        },
        getDownloadLink(row) {
            return {
                value: {
                    href: row.shareclasses[row.selected].kid.documentURI,
                    linktype: 'internal',
                    target: '_blank',
                },
            };
        },
        updateSelected(index, val) {
            const updatedRows = this.rows;
            updatedRows[index].selected = val.value;
            this.$emit('updateRows', updatedRows);
        },
    },
};
</script>

<style scoped lang="scss">
.body-default {
    font-weight: 600;
}
tr {
    display: flex;
    th,
    td {
        display: flex;
        padding-top: 22px;
        padding-bottom: 22px;
        width: 100%;
        align-items: center;
    }
    th:first-child,
    td:first-child {
        min-width: 374px;
        max-width: 374px;
    }
    th:nth-child(2),
    td:nth-child(2) {
        max-width: 202px;
    }
    th:nth-child(3),
    td:nth-child(3) {
        margin-left: 100px;
        max-width: 104px;
    }
    th:nth-child(4),
    td:nth-child(4) {
        margin-left: 35px;
        max-width: 120px;
    }
    th:nth-child(5),
    td:nth-child(5) {
        margin-left: auto;
        width: auto;
    }
}

tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.table-sorting-wrapper {
    position: relative;
    height: 100%;
    .bi-caret-up-fill {
        color: var(--digital-steel-grey);
        cursor: pointer;
        top: 15%;
        font-size: 9px;
        height: 10px;
        position: absolute;
    }
    .bi-caret-down-fill {
        color: var(--digital-steel-grey);
        cursor: pointer;
        top: 46%;
        height: 10px;
        font-size: 9px;
        position: absolute;
        &::before {
            vertical-align: 1.25em;
        }
    }
    .active {
        color: var(--abrdn-black);
    }
}

.bi-box-arrow-up-right {
    cursor: pointer;
}
</style>
