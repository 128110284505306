<template>
    <router-link class="d-block press-release-card h-100" :to="fields.url">
        <div class="article-card h-100">
            <div class="py-3 px-2 d-flex flex-column flex-grow-1" :class="cardBackground">
                <div class="d-flex">
                    <time class="section-header-small no-break">{{ localizeDate(fields.publicationDate) }}</time>
                </div>
                <div class="d-flex flex-column flex-grow-1 justify-content-between">
                    <div class="mt-2">
                        <h5 class="press-release-card__title line-clamp-4">{{ fields.title }}</h5>
                        <p class="body-small text-grey line-clamp-4 mt-1">
                            {{ fields.description }}
                        </p>
                    </div>
                    <Readtime
                        :articleType="pressReleaseType"
                        :duration="fields.duration"
                        class="section-header-small mt-2"
                    />
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
import Readtime from '@/components/Includes/Readtime';
import PressReleaseTypes from '@/constants/pressRelease';
import { localizeDate } from '@/mixins/Helpers.mixin';

export default {
    name: 'PressReleaseCard',
    components: { Readtime },
    mixins: [localizeDate],
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        parentBackground: {
            type: String,
            default: 'background-white',
        },
    },
    data() {
        return {
            pressReleaseTypes: PressReleaseTypes,
        };
    },
    computed: {
        articleTypeConst() {
            return this.fields.allTemplates ? this.fields.allTemplates[0] : false;
        },
        pressReleaseType() {
            return this.pressReleaseTypes[this.articleTypeConst];
        },
        cardBackground() {
            return this.parentBackground === 'background-white' ? this.vectorBasedBackground : 'background-white';
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';

.article-card {
    transition: box-shadow 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
    &:hover,
    &:focus {
        box-shadow: 0 4px 9px rgba(0, 0, 0, 0.25);
        h5 {
            text-decoration: underline;
        }
    }
    min-height: 244px;
    @include media-breakpoint-up(md) {
        min-height: 216px;
    }
}

.press-release-card__title {
    letter-spacing: 0.02em;
}

.no-break {
    white-space: nowrap;
}
</style>
