export const benchmarkTranslationKeys = {
    benchmark: 'fundDetailsKeyInfoBenchmark',
    performanceComparator: 'fundDetailsKeyInfoPerformanceComparator',
    performanceTarget: 'fundDetailsKeyInfoPerformanceTarget',
    portfolioConstrainingBenchmark: 'fundDetailsKeyInfoPortfolioConstrainingBenchmark',
};

export const colorCodes = [
    '#0057B7',
    '#00205B',
    '#00C1D4',
    '#7474C1',
    '#7FADE3',
    '#0018A8',
    '#4D6995',
    '#373A36',
    '#67D2DF',
    '#8CA1C7',
    '#63656A',
    '#0092D7',
    '#A2B2C8',
    '#A4DBE8',
    '#ABAEAB',
    '#8CB0BF',
    '#BBBCBC',
    '#300B6E',
    '#CBD3EA',
];

export const monthTranslationKeys = [
    'dateMonthJan',
    'dateMonthFeb',
    'dateMonthMar',
    'dateMonthApr',
    'dateMonthMay',
    'dateMonthJun',
    'dateMonthJul',
    'dateMonthAug',
    'dateMonthSep',
    'dateMonthOct',
    'dateMonthNov',
    'dateMonthDec',
];
