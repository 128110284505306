<script>
import ResizeObserver from 'resize-observer-polyfill';

export default {
    name: 'SidebarMixin',
    data() {
        return {};
    },
    watch: {
        isDropdownShown: {
            handler: function (val) {
                if (val) {
                    document.addEventListener('click', this.clickOutsideListener);
                } else {
                    document.removeEventListener('click', this.clickOutsideListener);
                }
            },
        },
    },
    beforeDestroy() {
        document.removeEventListener('click', this.clickOutsideListener);
        window.removeEventListener('scroll', this.trackScreenView);
        this.resizeObserver.unobserve(this.$refs[this.dropdownRef]);
    },
    methods: {
        scrollTo(id, activeSubtitle) {
            this.showScroll = true;
            const element = document.getElementById(id);
            this.scrolling = activeSubtitle === this.activeSubtitle ? false : true;
            setTimeout(() => {
                this.scrolling = false;
            }, 700);
            setTimeout(() => {
                this.activeSubtitle = activeSubtitle;
            }, 300);
            element && window.scrollTo({ top: element.offsetTop - 151, behavior: 'smooth' });
        },
        getTopCoordinateOfTitle(id) {
            return document.getElementById(id).getBoundingClientRect().top;
        },
        setSidebarWidth(ref) {
            const dropDownTopPosition = this.$refs[ref]?.getBoundingClientRect().bottom;
            if (window.innerWidth <= 1199 && this.showDropdown !== true && dropDownTopPosition <= 97) {
                this.showDropdown = true;
            } else if (this.showDropdown !== false && window.innerWidth > 1199) {
                this.showDropdown = false;
            }
            if (this.$refs[ref] && this.sidebarWidth !== this.$refs[ref].offsetWidth - 30) {
                this.sidebarWidth = this.$refs[ref].offsetWidth - 30;
            }
        },
        setDropdownHeight() {
            if (this.dropdownHeight !== this.$refs[this.dropdownRef]?.getBoundingClientRect().height) {
                this.dropdownHeight = this.$refs[this.dropdownRef]?.getBoundingClientRect().height;
            }
        },
        observeHeight(ref) {
            this.resizeObserver = new ResizeObserver(this.setDropdownHeight);
            this.resizeObserver.observe(this.$refs[ref]);
        },
        clickOutsideListener(e) {
            //To close dropdown if click outside of the div
            if (!e.target.closest(`.${this.dropdownRef}`)) {
                this.$refs[this.dropdownRef].click();
            }
        },
        trackScreenView() {
            let activeSubtitle = null;
            this.fields.items.reduce((prevTitle, nextTitle) => {
                let id = nextTitle.id;
                if (this.wrapperRef === 'chapters-wrapper') {
                    id = nextTitle.fields.anchor.value;
                }

                if (prevTitle < 200 && this.getTopCoordinateOfTitle(id) < 200) {
                    activeSubtitle = id;
                    return this.getTopCoordinateOfTitle(id);
                } else {
                    return this.getTopCoordinateOfTitle(id);
                }
            }, this.getTopCoordinateOfTitle(this.firstId));
            this.activeSubtitle = activeSubtitle ? activeSubtitle : this.firstId;
            const dropDownTopPosition = this.$refs[this.wrapperRef].getBoundingClientRect().bottom;

            if (dropDownTopPosition <= 97 && this.showDropdown !== true && window.innerWidth <= 1199) {
                this.showDropdown = true;
            } else if (this.showDropdown !== false && dropDownTopPosition > 97) {
                this.showDropdown = false;
            }
        },
    },
};
</script>
