<template>
    <iframe title="Iframe" :scrolling="shouldScroll" :style="{ height: iframeHeight + 'px' }" />
</template>

<script>
export default {
    name: 'ChildIframe',
    props: {
        shouldScroll: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            iframeHeight: 0,
        };
    },
    mounted() {
        if (typeof window !== 'undefined') {
            const scriptTag = document.createElement('script');
            scriptTag.src = 'https://cdn.jsdelivr.net/npm/@iframe-resizer/child';
            scriptTag.id = 'child-iframe-resize';
            document.getElementsByTagName('head')[0].appendChild(scriptTag);

            window.addEventListener('message', (event) => {
                if (typeof event.data === 'string' || typeof event.data === 'number') {
                    this.iframeHeight = Number(event.data);
                }
            });
        }
    },
};
</script>

<style scoped lang="scss">
iframe {
    min-height: 650px;
    width: 1px;
    min-width: 100%;
}
.experience-editor-holder iframe {
    pointer-events: none;
}
</style>
