import { render, staticRenderFns } from "./Error404.vue?vue&type=template&id=301b06d7&scoped=true"
import script from "./Error404.vue?vue&type=script&lang=js"
export * from "./Error404.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "301b06d7",
  null
  
)

export default component.exports