<template>
    <component :is="as" v-bind="passThroughProps" class="badge"><slot /></component>
</template>

<script>
export default {
    name: 'Badge',
    props: {
        as: {
            type: [String, Object],
            default: 'span',
        },
        passThroughProps: {
            type: Object,
            default: null,
        },
    },
};
</script>

<style lang="scss" scoped>
.badge {
    display: inline-block;
    padding: var(--spacer);
    background: rgba(0, 0, 0, 0.08);
    border-radius: calc(var(--spacer) * 1 / 2);
    font-size: 16px;
    line-height: 1;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    white-space: nowrap;
}
</style>
