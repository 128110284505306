<template>
    <section :id="anchorId" class="default-section-padding background-white">
        <div class="container">
            <Placeholder class="mb-4" :rendering="rendering" name="abrdn-tiles-heading" />
            <div v-if="articlesExist" class="row d-flex flex-wrap mb-2">
                <div v-for="article in articles" :key="article.id" class="col-12 col-md-6 col-lg-3 mb-6">
                    <ArticleCard :fields="article" :onRecentPage="true" />
                </div>
            </div>
            <ScLink
                v-if="showViewAll"
                class="revert-underline text-link background-to-change"
                :field="fields.viewAll"
            />
        </div>
    </section>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';

import ScLink from '@/components/Generic/SitecoreWrappers/ScLink.vue';
import ArticleCard from '@/components/Tiles/ArticleCard.vue';

export default {
    name: 'RecentArticles',
    components: { ArticleCard, ScLink, Placeholder },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        showViewAll() {
            return (
                (!!this.fields?.viewAll?.value?.text && !!this.fields?.viewAll?.value?.href) || this.isExperienceEditor
            );
        },
        articlesExist() {
            return !!this.fields?.articles?.value?.length;
        },
        articles() {
            if (!this.articlesExist) return [];
            return this.fields.articles.value.map((article) => {
                return {
                    ...article,
                    strapline: article.description,
                    media: article.image.url,
                    articleType: article.type,
                    publicationDate: article.date,
                };
            });
        },
    },
};
</script>
