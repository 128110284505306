<template>
    <div
        class="tile"
        :class="{
            [`${size.split('-').join('-md-')}`]: !singleTile,
            [`col-md-12`]: singleTile,
            [backgroundClass + '-repeat']: true,
        }"
    >
        <div
            :class="{
                [backgroundClass]: true,
                [outerVerticalPaddings]: outerVerticalPaddings,
                'flex-xl-column': size === 'col-4' || isSvgIcon,
            }"
            class="pb-3 d-flex flex-column flex-xl-row h-100 link-change"
        >
            <WrappedImage
                :media="fields.image"
                class="background-to-change"
                :class="{
                    'tile-image-big': size === 'col-8' && !isSvgIcon,
                    'tile-image-small': size === 'col-4' && !isSvgIcon,
                    'tile-image-medium': size === 'col-6' && !isSvgIcon,
                    'tile-image-svg': isSvgIcon,
                    'tile-image-svg-small': isSvgIcon && size === 'col-4',
                }"
                :srcSet="[{ mw: 400 }, { mw: 600 }, { mw: 800 }, { mw: 1000 }]"
                sizes="
                    (max-width: 575px) 100vw,
                    (max-width: 767px) 510px,
                    (max-width: 991px) 450px,
                    (max-width: 1199px) 66.6vw,
                    442px
                "
            />
            <div class="d-flex flex-column align-items-start px-2 px-xl-3 h-100 w-100" :class="innerVerticalPaddings">
                <div>
                    <ScText
                        :field="fields.title"
                        tag="h4"
                        class="background-to-change mb-2"
                        :class="{ 'mt-3': isSvgIcon }"
                    />
                    <ScText
                        :field="fields.description"
                        tag="p"
                        class="background-to-change body-default text-grey mb-1"
                    />
                    <AbrdnPlaceholder :rendering="rendering" name="abrdn-list" class="placeholder mt-2 mb-3" />
                </div>
                <div v-if="ctaLinks.length" class="mt-auto d-flex flex-column align-items-start">
                    <ScLink
                        v-for="field in fields.items"
                        :key="field.id"
                        class="mt-2 background-to-change inline-link tile-link d-inline-block"
                        :field="field.fields.cta"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import AbrdnPlaceholder from '@/components/Generic/SitecoreWrappers/AbrdnPlaceholder.vue';
import ScLink from '@/components/Generic/SitecoreWrappers/ScLink.vue';
import WrappedImage from '@/components/Generic/SitecoreWrappers/WrappedImage';

export default {
    name: 'Tile',
    components: { WrappedImage, ScText: Text, ScLink, AbrdnPlaceholder },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        params: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        singleTile: {
            type: Boolean,
            default: false,
        },
        hardcodedBg: {
            type: String,
            default: () => '',
        },
    },
    computed: {
        backgroundClass() {
            return this.hardcodedBg ? this.hardcodedBg : this.params.background || 'background-white';
        },
        isSvgIcon() {
            return this.fields.image.value?.['data-type'] === 'image/svg+xml';
        },
        size() {
            if (this.containerSize && this.params.size === 'default') {
                return this.containerSize;
            } else {
                return this.params?.size ? this.params.size : 'col-4';
            }
        },
        containerSize() {
            return this.$parent.$props.rendering?.params?.size;
        },
        hasImage() {
            return this.fields.image?.value?.src;
        },
        outerVerticalPaddings() {
            if (this.isSvgIcon) {
                if (this.size !== 'col-4') {
                    return 'py-3 pt-md-4 py-xl-4';
                }
                if (this.size === 'col-4') {
                    return 'pt-3 pt-xl-7';
                }
            }
            if (!this.hasImage) {
                if (this.size !== 'col-4') {
                    return 'py-3 pt-md-4 pb-xl-4';
                }
                if (this.size === 'col-4') {
                    return 'py-3';
                }
            }

            if (this.hasImage && !this.isSvgIcon) {
                if (this.size !== 'col-4') {
                    return 'pb-xl-0';
                }
            }
            return '';
        },
        innerVerticalPaddings() {
            if (this.hasImage && !this.isSvgIcon) {
                if (this.size !== 'col-4') {
                    return 'pt-2 pt-md-3 pb-xl-3 pt-xl-7';
                } else {
                    return 'pt-2';
                }
            }
            return '';
        },
        ctaLinks() {
            return this.fields.items || [];
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';

.tile-image-big,
.tile-image-big ::v-deep img {
    width: 100%;
    max-width: 442px;
    object-fit: cover;
    overflow: hidden;
    height: 100%;
    min-width: 442px;
    @include media-breakpoint-down(xl) {
        min-width: unset;
        max-width: unset;
        width: 100%;
        max-height: 210px;
        min-height: 210px;
        object-fit: cover;
    }
}

.tile-image-small,
.tile-image-small ::v-deep img {
    width: 100%;
    overflow: hidden;
    max-height: 210px;
    height: 210px;
    min-height: 210px;
    object-fit: cover;
}

.tile-image-medium,
.tile-image-medium ::v-deep img {
    max-width: 286px;
    min-height: 442px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    @include media-breakpoint-down(xl) {
        min-width: unset;
        height: 210px;
        min-height: 210px;
        width: 100%;
        max-width: unset;
        max-height: 210px;
    }
}

.tile-image-svg {
    width: 100%;
    max-width: 111px;
    height: auto;
    margin-left: 24px;
    @include media-breakpoint-down(xl) {
        margin-left: 16px;
        // &.tile-image-svg-small {
        //     max-width: 65px;
        // }
    }
    // @include media-breakpoint-down(md) {
    //     max-width: 65px;
    // }
}

.tile-link {
    text-decoration: underline;
    font-weight: 600;
}
.background-white-repeat + .background-white-repeat {
    @include media-breakpoint-down(md) {
        margin-top: 0;
    }
}
</style>
