<template>
    <div class="container default-section-padding">
        <div class="row">
            <div class="col">
                <h2 class="mb-2">An error occurred during asset request submittion</h2>
                <p class="mb-4">
                    Thank you for submitting your request form, however an internal error occurred during in the system.
                    Please try again later.
                </p>
                <h3>Start another asset request</h3>
                <p class="mb-2">
                    If you'd like to start another asset request form please click the 'Start another request' button
                    below.
                </p>
                <a href="/en-gb/automationsample/form/salesforce-multiple-submissions" class="d-block mb-4"
                    >Start another request</a
                >
                <h2 class="mb-2">Finish</h2>
                <p class="mb-3">Click below to close this window.</p>
                <AbrdnButton
                    :buttonType="'primary'"
                    class="text-link text-decoration-underline"
                    @click.native="backToPrevPage"
                    >Close this window</AbrdnButton
                >
            </div>
        </div>
    </div>
</template>

<script>
import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton';

export default {
    name: 'ErrorMessage',
    components: { AbrdnButton },
    methods: {
        backToPrevPage() {
            if (this.$router) {
                this.$router.back();
            }
        },
    },
};
</script>

<style scoped></style>
