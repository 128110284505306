<template>
    <EEPlaceholder v-if="isExperienceEditor">
        <div>Fund Details Banner</div>
        <AbrdnPlaceholder :rendering="rendering" name="abrdn-preliminary-info" />
    </EEPlaceholder>

    <section v-else-if="shouldRender" class="default-section-padding background-white">
        <div class="container">
            <router-link :to="allFundsLink" class="d-inline-flex align-items-center gap-1">
                <IconChevron class="icon" />
                <span class="text-link">{{ $t('allFunds') }}</span>
            </router-link>

            <FundDetailsBannerSkeleton v-if="isLoading" class="mt-3" />

            <AlertMessageBase v-else-if="error" type="error" :title="$t(error)" class="mt-3" />

            <template v-else>
                <div class="row mt-3">
                    <div class="col-12 col-md-8">
                        <h2 class="h1">{{ name }}</h2>
                    </div>
                </div>

                <div v-if="shareClasses.length && selectedShareClass.name" class="row mt-4 mt-md-5">
                    <div class="col-12 col-md-6 col-lg-4">
                        <div :class="[shareClasses.length > 1 ? 'form-label' : 'section-header-small', 'mb-1.5']">
                            {{ $t('fundDetailsShareClass') }}
                        </div>
                        <AbrdnSelect
                            v-if="shareClasses.length > 1"
                            :fields="shareClassDropdownFields"
                            @updateValue="onShareClassDropdownChange($event)"
                        />
                        <div v-else-if="shareClasses.length === 1" class="field-text">
                            {{ selectedShareClass.name }}
                        </div>
                        <div v-else class="field-text">{{ $t('noResultsMessage') }}</div>
                    </div>
                </div>

                <div v-if="hasDocuments || shareClassDataColumns" class="row mt-5">
                    <div v-if="hasDocuments" class="col-12 col-lg-4">
                        <AlertMessageBase
                            v-if="fundDetailsDocuments.error"
                            type="error"
                            :title="$t(fundDetailsDocuments.error)"
                        />
                        <div v-else class="d-flex flex-wrap gap-3">
                            <a
                                v-for="item in fundDetailsDocuments.data"
                                :key="item.id"
                                :href="sanitizeURL(item.documentUri)"
                                target="_blank"
                                class="d-inline-flex align-items-center gap-1"
                                @click="updateDataLayer(item)"
                            >
                                <span class="text-link underline">{{ item.title }}</span>
                                <i
                                    aria-hidden="true"
                                    class="ms-1"
                                    :class="`bi bi-filetype-${getDocumentFormat(item.contentType)}`"
                                />
                                <span class="sr-only">{{ $t('opensInNewWindow') }}</span>
                            </a>
                        </div>
                    </div>

                    <div v-if="shareClassDataColumns" class="col-12 col-lg-8" :class="{ 'ps-lg-4': hasDocuments }">
                        <div class="d-lg-flex">
                            <div v-if="hasDocuments" class="divider my-4 my-lg-0 me-lg-4" />

                            <div class="w-100 d-flex flex-wrap mt-n4 ms-n4">
                                <template v-for="{ label, value, date } in shareClassDataColumns">
                                    <div v-if="value" :key="label" class="w-50 w-md-25 pt-4 ps-4">
                                        <div class="section-header-small text-break">{{ label }}</div>
                                        <div class="h4 mt-1">{{ value }}</div>
                                        <div v-if="date" class="body-small mt-1">{{ date }}</div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="formJson" class="mt-5">
                    <AbrdnButton buttonType="primary" @click.native="onContactButtonClick">{{
                        $t('contactUsFundForm')
                    }}</AbrdnButton>
                </div>

                <div
                    v-if="isFundDetailsInvTrustDisclaimerAvailable"
                    data-testid="FundDetailsHeroBannerDisclaimer"
                    class="py-4 px-2 px-md-3 px-lg-5 mt-5"
                    :class="vectorBasedBackground"
                >
                    <p class="body-small">{{ $t('fundDetailsCefDisclaimer') }}</p>
                </div>

                <AbrdnPlaceholder :rendering="rendering" name="abrdn-preliminary-info" class="mt-5 mt-lg-6" />
            </template>
        </div>
    </section>
</template>

<script>
import { dataApi } from '@sitecore-jss/sitecore-jss-vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import AlertMessageBase from '@/components/Generic/AlertMessageBase';
import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton';
import AbrdnSelect from '@/components/Generic/FormElements/FormFields/AbrdnSelect';
import AbrdnPlaceholder from '@/components/Generic/SitecoreWrappers/AbrdnPlaceholder.vue';
import IconChevronFundDetails from '@/components/Icons/icon-chevron-fund-details';
import EEPlaceholder from '@/components/Sections/EEPlaceholder';
import { UTCFormat, UTCFormatWithTime } from '@/constants/dateFormats';
import documentsFormatMap from '@/constants/documentsFormatMap';
import { dataFetcher } from '@/dataFetcher';
import pushDataLayerEvent from '@/mixins/DataLayer.mixin';
import { formatCurrency, localizeDate, sanitizeURL } from '@/mixins/Helpers.mixin';
import { getApiKey } from '@/Service.api';
import config from '@/temp/config';

import FundDetailsBannerSkeleton from './FundDetailsBannerSkeleton';

export default {
    name: 'FundDetailsBanner',

    components: {
        AbrdnSelect,
        IconChevron: IconChevronFundDetails,
        EEPlaceholder,
        FundDetailsBannerSkeleton,
        AlertMessageBase,
        AbrdnPlaceholder,
        AbrdnButton,
    },

    mixins: [formatCurrency, localizeDate, sanitizeURL, pushDataLayerEvent],

    props: {
        rendering: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            allFundsLink: '',
            shouldRender: true,
        };
    },

    computed: {
        ...mapState('fundDetails', [
            'error',
            'isLoading',
            'name',
            'fundSize',
            'shareClasses',
            'selectedShareClassIsin',
            'isPageAvailable',
            'fundDetailsDocuments',
            'correlationFundRangeID',
            'fundRangeName',
            'fundId',
            'isFundDetailsInvTrustDisclaimerAvailable',
            'fundHeroType',
            'formJson',
        ]),
        ...mapGetters('fundDetails', ['selectedShareClass', 'shareClassByIsin']),

        shareClassDropdownFields() {
            return {
                options: {
                    value: this.shareClasses.map(({ isin, name }) => {
                        return {
                            name: isin,
                            value: isin,
                            displayName: name,
                            selected: isin === this.selectedShareClassIsin.toUpperCase(),
                        };
                    }),
                },
                ariaLabel: {
                    value: this.$t('fundDetailsShareClass'),
                },
            };
        },

        shareClassDataColumns() {
            if (!this.selectedShareClass) return;

            const [price] = this.selectedShareClass?.prices || [{}];
            const [investmentTrustPrices] = this.selectedShareClass?.investmentTrustPrices || [{}];

            const priceExist = price && !!Object.keys(price).length;
            const invTrustPriceExist = investmentTrustPrices && !!Object.keys(investmentTrustPrices).length;

            if (!priceExist && !invTrustPriceExist) return [];
            const { tickerSymbol } = this.selectedShareClass;

            switch (this.fundHeroType) {
                case 0:
                    return this.shareClassDataColumnsByHeroType.defaultType(price);
                case 1:
                    return this.shareClassDataColumnsByHeroType.investmentTrust(investmentTrustPrices);
                case 2:
                    return this.shareClassDataColumnsByHeroType.investmentTrustWithUnadjustedNAV(investmentTrustPrices);
                case 3:
                    return this.shareClassDataColumnsByHeroType.USMutualFund(price, tickerSymbol);
                case 4:
                    return this.shareClassDataColumnsByHeroType.USPreciousMetalsETF(price, investmentTrustPrices);
                case 5:
                    const [medianBidAskSpreads] = this.selectedShareClass.medianBidAskSpreads || [{}];
                    return this.shareClassDataColumnsByHeroType.USETF(
                        price,
                        medianBidAskSpreads,
                        investmentTrustPrices,
                    );
                case 6:
                    const [iopVs] = this.selectedShareClass.iopVs;
                    return this.shareClassDataColumnsByHeroType.AUActiveETF(price, iopVs);
                default:
                    return [];
            }
        },

        shareClassDataColumnsByHeroType() {
            return {
                defaultType: (data) => {
                    const { priceCurrencyCode } = data;
                    return this.sharedPriceFields(data, priceCurrencyCode);
                },
                investmentTrust: (data) => {
                    return this.sharedInvTrustFields(data);
                },
                investmentTrustWithUnadjustedNAV: (data) => {
                    const { unadjustedNav } = data;
                    return [
                        ...this.sharedInvTrustFields(data),
                        {
                            label: this.$t('fundDetailsUnadjustedNav'),
                            value: this.truncateNumber(unadjustedNav),
                        },
                    ];
                },
                USMutualFund: (data, tickerSymbol) => {
                    const { changePercentage, currentAsAt, priceCurrencyCode, pricePerUnit } = data;
                    return [
                        {
                            label: this.$t('fundDetailsTicker'),
                            value: tickerSymbol,
                        },
                        {
                            label: this.$t('fundDetailsMarketPrice'),
                            value: this.formatCurrency(pricePerUnit, priceCurrencyCode),
                            date: this.localizeDate(currentAsAt, UTCFormat),
                        },
                        {
                            label: this.$t('fundDetailsDailyPriceChangePercentage'),
                            value: `${this.formatCurrency(changePercentage, priceCurrencyCode)}%`,
                            date: this.localizeDate(currentAsAt, UTCFormat),
                        },
                    ];
                },
                USETF: (data, medianBidAskSpreads, investmentTrustPrices) => {
                    const { pricePerUnit, priceCurrencyCode, currentAsAt, currencyCode, changePercentage } = data;
                    const { timeStamp, value } = medianBidAskSpreads;
                    const { exchangePrice, exchangeDate } = investmentTrustPrices;
                    return [
                        {
                            label: this.$t('fundDetailsMarketPrice'),
                            value: this.formatCurrency(exchangePrice, priceCurrencyCode),
                            date: this.localizeDate(exchangeDate, UTCFormat),
                        },
                        {
                            label: this.$t('fundDetailsNav'),
                            value: this.formatCurrency(pricePerUnit, priceCurrencyCode, 'compact'),
                            date: this.localizeDate(currentAsAt, UTCFormat),
                        },
                        {
                            label: this.$t('fundDetailsPriceChangePercentage'),
                            value: this.formatCurrency(changePercentage, currencyCode),
                            date: this.localizeDate(currentAsAt, UTCFormat),
                        },
                        {
                            label: this.$t('fundDetailsMedianBidAskSpread'),
                            value: value,
                            date: this.localizeDate(timeStamp, UTCFormat),
                        },
                    ];
                },
                AUActiveETF: (data, iopVs) => {
                    const { priceCurrencyCode, pricePerUnit, currentAsAt } = data;
                    const { timeStampInAET, value } = iopVs;
                    return [
                        {
                            label: this.$t('fundDetailsNavPriceAud'),
                            value: this.formatCurrency(pricePerUnit, priceCurrencyCode),
                            date: this.localizeDate(currentAsAt, UTCFormat),
                        },
                        {
                            label: this.$t('fundDetailsInavPriceAud'),
                            value: this.formatCurrency(value, priceCurrencyCode),
                            date: `${this.localizeDate(timeStampInAET, UTCFormatWithTime)} ${this.$t('timezoneAet')}`,
                        },
                        {
                            label: this.$t('fundDetailsCurrency'),
                            value: priceCurrencyCode,
                        },
                    ];
                },
                USPreciousMetalsETF: (data, investmentTrustPrices) => {
                    const { currentAsAt, priceCurrencyCode, pricePerUnit, currencyCode, changePercentage } = data;
                    const { exchangePrice, exchangeDate } = investmentTrustPrices;
                    const AUMField = `${this.formatCurrency(
                        this.fundSize,
                        priceCurrencyCode,
                        'compact',
                    )} ${priceCurrencyCode}`;
                    return [
                        {
                            label: this.$t('fundDetailsMarketPrice'),
                            value: this.formatCurrency(exchangePrice, priceCurrencyCode),
                            date: this.localizeDate(exchangeDate, UTCFormat),
                        },
                        {
                            label: this.$t('fundDetailsNav'),
                            value: this.formatCurrency(pricePerUnit, priceCurrencyCode, 'compact'),
                            date: this.localizeDate(currentAsAt, UTCFormat),
                        },
                        {
                            label: this.$t('fundDetailsPriceChangePercentage'),
                            value: this.formatCurrency(changePercentage, currencyCode),
                            date: this.localizeDate(currentAsAt, UTCFormat),
                        },
                        {
                            label: this.$t('fundDetailsAum'),
                            value: AUMField,
                        },
                    ];
                },
            };
        },

        metaTitle() {
            return this.$jss.sitecoreContext()?.metaTitle;
        },

        getMetaTitle() {
            return this.metaTitle?.startsWith('Abrdn') && this.name !== '' ? this.name : this.metaTitle;
        },

        hasDocuments() {
            return !!this.fundDetailsDocuments.error || !!this.fundDetailsDocuments.data?.length;
        },
    },

    metaInfo() {
        return {
            title: this.isLoading ? 'abrdn' : this.getMetaTitle,
        };
    },

    mounted() {
        if (this.isExperienceEditor) return;

        const {
            fundsConfiguration: { allFundsLink, shareClassIsin },
        } = this.$jss.sitecoreContext();

        if (!shareClassIsin) {
            this.shouldRender = false;
            return;
        }

        this.allFundsLink = allFundsLink;
        this.setSelectedShareClassIsin(shareClassIsin);
        this.fetchData();
    },

    destroyed() {
        this.resetState();
    },

    methods: {
        ...mapActions('fundDetails', ['fetchFundDetails', 'resetState', 'fetchFundDetailsDocuments']),
        ...mapMutations('fundDetails', ['setLoading', 'setSelectedShareClassIsin']),

        getPathByIsin(newIsin) {
            const urlParts = this.$route.path.split('/');
            urlParts.pop(); // ISIN removed from URL parts
            urlParts.pop(); // Share class removed from URL parts
            const newShareClass = this.shareClassByIsin(newIsin).name.replaceAll(' ', '-').toLowerCase();
            urlParts.push(newShareClass); // Share class re-added to URL parts
            urlParts.push(newIsin); // ISIN re-added to URL parts
            return urlParts.join('/');
        },

        onShareClassDropdownChange(isin) {
            this.$router.push(this.getPathByIsin(isin.value));
            this.setSelectedShareClassIsin(isin.value);
        },
        getDocumentFormat(rawFormat) {
            return Object.entries(documentsFormatMap).find(([, value]) => value === rawFormat)[0];
        },
        async fetchData() {
            const {
                fundsConfiguration: {
                    countryInvestors: { countryCode, investorType, jurisdiction, literatureAuthorization },
                    shareClassIsin,
                    notFoundPageId,
                },
                displayLanguage,
                language,
                site: { name: siteName },
            } = this.$jss.sitecoreContext();

            await this.fetchFundDetails({
                CountryInvestors: {
                    CountryCode: countryCode,
                    InvestorType: investorType,
                    Jurisdiction: jurisdiction,
                },
                language: displayLanguage,
                isin: shareClassIsin,
                site: siteName,
            });

            await this.fetchFundDetailsDocuments({
                countryCode,
                correlationFundRangeId: this.correlationFundRangeID,
                fundName: this.name,
                fundRangeName: this.fundRangeName,
                shareClass: this.selectedShareClass?.id,
                literatureAuthorizations: [literatureAuthorization],
                language: displayLanguage,
                site: siteName,
                fund: this.fundId,
            });

            if (!this.isPageAvailable) {
                const fetchOptions = {
                    layoutServiceConfig: { host: window?.app?.sitecoreApiHost || config.sitecoreApiHost },
                    querystringParams: {
                        sc_lang: language,
                        sc_site: siteName,
                        sc_apikey: getApiKey(),
                    },
                    fetcher: dataFetcher,
                };
                dataApi.fetchRouteData(notFoundPageId, fetchOptions).then((routeData) => {
                    this.$jss.store.setSitecoreData(routeData);
                    this.setLoading(false);
                });
            } else {
                this.setLoading(false);
            }
        },

        truncateNumber(number) {
            return parseFloat(number).toFixed(2);
        },

        sharedInvTrustFields({ cefnav, exchangePrice, cefPremiumDiscount, exchangeDate }) {
            return [
                {
                    label: this.$t('fundDetailsNav'),
                    value: this.truncateNumber(cefnav),
                    date: this.localizeDate(exchangeDate, UTCFormat),
                },
                {
                    label: this.$t('fundDetailsMarketPrice'),
                    value: this.truncateNumber(exchangePrice),
                    date: this.localizeDate(exchangeDate, UTCFormat),
                },
                {
                    label: this.$t('fundDetailsPremiumOrDiscount'),
                    value: this.truncateNumber(cefPremiumDiscount),
                },
            ];
        },

        sharedPriceFields({ changeValue, currentAsAt, priceCurrencyCode, pricePerUnit }, currencyCode) {
            return [
                {
                    label: this.$t('fundDetailsDailyPrice'),
                    value: this.formatCurrency(pricePerUnit, currencyCode),
                    date: this.localizeDate(currentAsAt, UTCFormat),
                },
                {
                    label: this.$t('fundDetailsPriceChange'),
                    value: this.formatCurrency(changeValue, currencyCode),
                    date: this.localizeDate(currentAsAt, UTCFormat),
                },
                {
                    label: this.$t('fundSize'),
                    value: this.fundSize && this.formatCurrency(this.fundSize, currencyCode, 'compact'),
                },
                {
                    label: this.$t('fundDetailsCurrency'),
                    value: priceCurrencyCode,
                },
            ];
        },

        onContactButtonClick() {
            const contactFormElement = document.getElementById('contact-form');
            if (!contactFormElement) return;
            contactFormElement.focus();
            window.scrollTo({
                top: contactFormElement.offsetTop - this.$root.headerHeight,
                behavior: 'smooth',
            });
        },

        updateDataLayer(item) {
            if (typeof window !== 'undefined') {
                const origin = window.location.origin;
                this.pushDataLayerEvent({
                    event: 'file_download',
                    file_url: item.documentUri.includes('https://') ? item.documentUri : `${origin}${item.documentUri}`,
                    file_name: item.title,
                });
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';

.icon {
    width: calc(var(--spacer) * 3);
}

.underline {
    border-bottom: 1px solid;
}

.divider {
    opacity: 0.3;

    @include media-breakpoint-down(lg) {
        border-top: 1px solid;
    }

    @include media-breakpoint-up(lg) {
        border-left: 1px solid;
    }
}

.w-md-25 {
    @include media-breakpoint-up(md) {
        width: 25% !important;
    }
}
</style>
