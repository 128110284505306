<template>
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M25.2413 1.75885C25.4869 2.00442 25.7176 2.23501 25.9175 2.43487C26.3083 2.82545 26.3082 3.4587 25.9175 3.84933L3.84953 25.9132C3.459 26.3037 2.82609 26.3039 2.43557 25.9134C2.23545 25.7133 2.00454 25.4824 1.75865 25.2366C1.51294 24.9909 1.28219 24.7602 1.08219 24.5603C0.691554 24.1697 0.691716 23.5366 1.08234 23.146L23.1503 1.08213C23.5409 0.691607 24.1739 0.691516 24.5645 1.08209C24.7646 1.28219 24.9954 1.51305 25.2413 1.75885Z"
            fill="currentColor"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.75872 1.75847C2.00459 1.51264 2.23548 1.28179 2.43559 1.08173C2.8261 0.691292 3.45898 0.691474 3.84949 1.08191L25.9176 23.146C26.3083 23.5366 26.3084 24.1697 25.9177 24.5603C25.7177 24.7602 25.487 24.9909 25.2413 25.2366C24.9954 25.4824 24.7645 25.7132 24.5644 25.9133C24.1739 26.3038 23.541 26.3037 23.1504 25.9132L1.0823 3.84911C0.69169 3.45857 0.691509 2.82549 1.08212 2.43495C1.28216 2.23494 1.51296 2.00419 1.75872 1.75847Z"
            fill="currentColor"
        />
    </svg>
</template>

<script>
export default {
    name: 'IconX',
};
</script>

<style scoped></style>
