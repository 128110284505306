<template>
    <section @keydown.esc="toggleDropdown">
        <FormElemWrapper class="form-element--dropdown" :isError="isError" wrapperTag="div">
            <template v-if="getLabel" #fieldLabelSlot>{{ getLabel }} </template>
            <template v-if="getTooltip" #tooltipSlot> {{ getTooltip }} </template>
            <template #fieldSlot>
                <StateDrivenSlotDropdown
                    class="dropdown"
                    :class="{ 'dropdown--expanded': isDropdownOpen }"
                    :isOpen="isDropdownOpen"
                    :fullWidth="true"
                    buttonActionType="button"
                    :showActionButtons="true"
                    @outsideClick="toggleDropdown"
                    @onFocus="$emit('onFocus')"
                    @lostFocus="isDropdownOpen = false"
                    @cancelEvent="deleteOptions"
                    @confirmEvent="applyOptions"
                >
                    <template #trigger-area>
                        <button
                            type="button"
                            :aria-expanded="isDropdownOpen ? 'true' : 'false'"
                            aria-controls="trigger-aria"
                            class="d-flex align-items-center pick-list-selector background-to-change btn btn-naked w-100"
                            @click="isDropdownOpen = true"
                        >
                            <span class="field-text">{{ name }}</span>
                            <span aria-hidden="true" tabindex="-1" class="ms-auto background-to-change"
                                ><em class="d-flex bi-chevron-down"
                            /></span>
                        </button>
                    </template>

                    <Checkbox
                        v-for="(option, i) in mappedOptions"
                        :key="i"
                        :tabindex="isDropdownOpen ? 0 : -1"
                        :aria-hidden="isDropdownOpen ? 'false' : 'true'"
                        :selected="option.selected"
                        :label="option.displayName"
                        @onChange="selectOption($event, option)"
                    />
                </StateDrivenSlotDropdown>
            </template>
            <template #errorSlot>{{ fields.errorMessages.valueMissing }}</template>
            <template v-if="getHintMessage" #hintSlot>{{ getHintMessage }}</template>
        </FormElemWrapper>
        <SelectedFilters
            v-if="selectedOptions.length"
            class="mt-4"
            :approvedFilters="selectedOptions"
            @clearFilters="
                onResetUnAppliedOptions();
                deleteOption(null, appliedOptions);
            "
            @deleteOption="deleteOption"
        />
    </section>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

import SelectedFilters from '@/components/Functional/ArticleFilterBar/SelectedFilters';
import Checkbox from '@/components/Generic/Checkbox.vue';
import FormElemWrapper from '@/components/Generic/FormElements/FormFields/FormElemWrapper';
import StateDrivenSlotDropdown from '@/components/Generic/StateDrivenSlotDropdown';
import FormFieldsGetters from '@/mixins/FormFieldsGetters.mixin';

export default {
    name: 'MultiplePickList',
    components: { StateDrivenSlotDropdown, Checkbox, FormElemWrapper, SelectedFilters },
    mixins: [FormFieldsGetters],
    props: {
        optionList: {
            type: Object,
            default: () => {},
        },
        name: {
            type: String,
            default: '',
        },
        fields: {
            type: Object,
            default: () => {},
        },
        required: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isDropdownOpen: false,
            errorMessage: '',
            mappedOptions: [],
        };
    },
    computed: {
        appliedOptions() {
            return this.mappedOptions.filter((option) => option.applied);
        },
        selectedOptions() {
            return this.mappedOptions.filter((option) => option.selected);
        },
    },
    watch: {
        isDropdownOpen(val) {
            if (!val) {
                this.onResetUnAppliedOptions();
            }
        },
    },
    mounted() {
        this.mappedOptions = this.optionList.elements.map((option) => {
            return {
                ...option,
                selected: false,
                applied: false,
                id: uuidv4(),
            };
        });
    },
    methods: {
        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
        },
        selectOption(event, option) {
            option.selected = !option.selected;
            this.$emit('updateDataLayer');
        },
        deleteOptions() {
            this.mappedOptions.forEach((option) => {
                option.selected = false;
                option.applied = false;
            });
            this.$emit('deleteMultipleOptions');
            this.setMultipleValue();
        },
        onResetUnAppliedOptions() {
            this.mappedOptions.forEach((option) => {
                option.selected = option.applied;
            });
        },
        applyOptions() {
            if (this.appliedOptions.length === this.selectedOptions.length) {
                if (!this.appliedOptions.find((appliedOption) => !this.selectedOptions.includes(appliedOption))) return;
            }
            this.isDropdownOpen = !this.isDropdownOpen;
            this.mappedOptions.forEach((option) => (option.applied = false));
            this.selectedOptions.forEach((option) => (option.applied = true));
            this.$emit('applyOptions', this.appliedOptions);
            this.setMultipleValue();
        },
        deleteOption(singleOption = {}, arrayOfOptions = []) {
            if (singleOption) {
                this.$emit('deleteSingleOption', singleOption);
            } else {
                this.$emit('deleteMultipleOptions');
            }
            [singleOption || {}, ...arrayOfOptions].forEach((option) => {
                option.selected = false;
                option.applied = false;
            });
            this.setMultipleValue();
        },
        setMultipleValue() {
            const selectedOptions = this.mappedOptions
                .filter((option) => option.selected)
                .map((option) => {
                    return option.value;
                });

            this.$emit('setSelectedValues', selectedOptions);
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixins';
.checkbox-wrapper {
    padding: 12px 16px;
    cursor: pointer;
    &:hover:not(.disabled),
    &:focus:not(.disabled) {
        background: var(--button-mist);
    }
}
.form-element--error {
    ::v-deep {
        .dropdown .dropdown__content {
            color: var(--error-400);
            background-color: var(--error-100);
            .checkbox {
                border: 1px solid var(--error-400);
            }
        }
    }
}
.form-element--error .dropdown button {
    border-color: var(--error-400);
    background-color: var(--error-100);
    border-width: 2px;
    color: var(--error-400);
    outline: 0;
    & .placeholder {
        color: var(--error-400);
        & .ss-disabled {
            color: var(--error-400);
        }
    }
    @include dark-backgrounds() {
        background-color: var(--error-200);
        border-color: var(--error-200);
        color: var(--error-400);
        & .ss-arrow span {
            border-color: var(--abrdn-black);
        }
        + .chevron {
            color: var(--abrdn-black);
        }
    }
}
.pick-list-selector {
    @include dark-backgrounds() {
        background: var(--abrdn-black);
        border-color: var(--abrdn-white);
    }
    &:hover {
        background: var(--abrdn-white);
        border-color: var(--abrdn-black);
    }
}
</style>
