var render, staticRenderFns
import script from "./FiltersSection.mixin.vue?vue&type=script&lang=js"
export * from "./FiltersSection.mixin.vue?vue&type=script&lang=js"
import style0 from "./FiltersSection.mixin.vue?vue&type=style&index=0&id=ae67cff6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae67cff6",
  null
  
)

export default component.exports