<template>
    <div class="banner-quick-links background-to-change p-3 d-none d-xl-block">
        <sc-text v-if="fields" tag="p" :field="fields.title" class="section-header-small background-to-change mb-2" />
        <ul class="links-list">
            <li v-for="(field, idx) in linkFields" :key="field.id">
                <ScLink
                    :field="field.link"
                    class="banner-quick-links__link quick-link-typography"
                    :class="{ 'mt-2': idx !== 0 }"
                    :replaceWithSlot="false"
                    :showExternalIcon="false"
                >
                    <span class="text-nowrap">&#65279;<IconChevron class="ms-2" /></span>
                </ScLink>
            </li>
        </ul>
    </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import ScLink from '@/components/Generic/SitecoreWrappers/ScLink.vue';
import IconChevron from '@/components/Icons/icon-chevron';

export default {
    name: 'BannerQuickLinks',
    components: { ScLink, IconChevron, scText: Text },
    props: {
        fields: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        linkFields() {
            if (!this.fields?.items.length) return [];
            return this.fields.items.map((item) => {
                item.fields.id = item.id;
                return item.fields;
            });
        },
    },
};
</script>

<style scoped lang="scss">
.banner-quick-links {
    border: 1px solid currentColor;
}
.links-list {
    margin: 0;
    padding: 0;
    list-style: none;
}
.links-list .banner-quick-links__link {
    display: inline-block;
    svg {
        width: 0.65em;
        height: 0.65em;
        transform: translateY(-10%) rotate(-90deg);
        transition: transform 0.2s ease-in-out;
    }
    &:hover {
        svg {
            transform: translateX(5px) translateY(-10%) rotate(-90deg);
        }
    }
}
</style>
