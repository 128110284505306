<template>
    <div class="x-axis-item">
        <div class="columns-group">
            <div
                v-for="(column, idx) in xValue.columns"
                :key="column.name"
                class="column"
                :title="column.value !== null ? column.value + '%' : ''"
            >
                <span
                    class="bar"
                    :style="{
                        height: column.relativeValue + '%',
                        bottom: `calc(${columnBottomOffset} + .5px)`,
                        transform: column.isReversed ? 'translateY(100%)' : '',
                    }"
                >
                    <template v-if="idx === 0">
                        <span
                            v-for="(tooltip, tooltipIdx) in xValue.tooltips"
                            :key="tooltip.index"
                            class="column__tooltip caption"
                            :style="
                                column.isReversed
                                    ? { top: '100%', transform: `translateX(-50%) translateY(${tooltipIdx * 200}%)` }
                                    : {
                                          bottom: '100%',
                                          transform: `translateX(-50%) translateY(-${tooltipIdx * 200}%)`,
                                      }
                            "
                        >
                            {{ tooltip.index }}
                        </span>
                    </template>
                </span>
            </div>
        </div>
        <div class="x-axis-item__legend body-small">
            {{ xValue.year }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'XAxisItem',
    props: {
        xValue: {
            type: Object,
            required: true,
        },
        columnBottomOffset: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped lang="scss">
.x-axis-item {
    display: flex;
    flex-direction: column;
}
.x-axis-item__legend {
    padding: 0 5px;
    height: var(--xLegendHeight);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    text-align: center;
    flex-shrink: 0;
}
.columns-group {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    margin: calc(var(--yValueFontSize) / 2) var(--columnsGroupMargin) 0 var(--columnsGroupMargin);
    width: var(--columnsGroupWidth);
    position: relative;
}
.x-axis-item.negative-column-present .columns-group {
    margin: calc(var(--yValueFontSize) / 2) var(--columnsGroupMargin);
}
.x-axis-item:first-child .columns-group {
    margin-left: 0;
}
.x-axis-item:last-child .columns-group {
    margin-right: 0;
}
.column__tooltip {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 14px;
    height: 14px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    margin: 8px 0;
    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: black;
        transform: rotate(45deg);
        z-index: -1;
    }
}
.column {
    max-width: 26px;
    margin-top: auto;
    flex-grow: 1;
    height: 100%;
    position: relative;
    .bar {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
    }
}
.column + .column {
    margin-left: 4px;
}
</style>
