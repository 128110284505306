<template>
    <svg
        width="110"
        height="110"
        viewBox="0 0 110 110"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
    >
        <path
            d="M30.8322 95.0911C30.6947 95.0911 30.5572 95.0715 30.4393 95.0126C14.1357 87.8429 4 70.9304 4 50.8554C4 22.8054 26.8054 0 54.8554 0C82.9054 0 105.711 22.8054 105.711 50.8554C105.711 68.9858 95.9286 85.8983 80.1947 94.9536C79.7233 95.2286 79.1143 95.0519 78.859 94.6001C78.584 94.1286 78.7411 93.5394 79.2126 93.2644C94.3376 84.5429 103.747 68.2983 103.747 50.875C103.747 23.9054 81.8054 1.98393 54.8554 1.98393C27.8857 1.96429 5.96429 23.9054 5.96429 50.8554C5.96429 70.1447 15.6482 86.3697 31.2447 93.2054C31.7357 93.4215 31.9714 94.0108 31.7554 94.5019C31.5786 94.8751 31.225 95.0911 30.8322 95.0911Z"
            fill="currentColor"
        />
        <path
            d="M54.8745 46.2588C44.7781 46.2588 36.5674 38.0481 36.5674 27.9517C36.5674 17.8553 44.7781 9.64453 54.8745 9.64453C64.971 9.64453 73.1817 17.8553 73.1817 27.9517C73.1817 38.0481 64.971 46.2588 54.8745 46.2588ZM54.8745 11.5892C45.8585 11.5892 38.5317 18.916 38.5317 27.932C38.5317 36.9481 45.8585 44.2749 54.8745 44.2749C63.8906 44.2749 71.2174 36.9481 71.2174 27.932C71.2174 18.9356 63.8906 11.5892 54.8745 11.5892Z"
            fill="currentColor"
        />
        <path d="M55.8569 45.2764H53.8926V54.7835H55.8569V45.2764Z" fill="currentColor" />
        <path
            d="M78.9175 110H30.8318C30.2818 110 29.8496 109.568 29.8496 109.018V75.8411C29.8496 63.6821 39.73 53.8018 51.8889 53.8018H57.8211C69.98 53.8018 79.8604 63.7018 79.8604 75.8411V108.998C79.8996 109.548 79.4479 110 78.9175 110ZM31.8139 108.036H77.9157V75.8411C77.9157 64.7625 68.8996 55.766 57.8407 55.766H51.9086C40.83 55.766 31.8335 64.7821 31.8335 75.8411V108.036H31.8139Z"
            fill="currentColor"
        />
    </svg>
</template>

<script>
export default {
    name: 'TeamProfilePlaceholder',
};
</script>

<style scoped></style>
