<template>
    <section :id="anchorId" :data-e2e-component-uid="rendering.uid" :class="backgroundClass" class="tet">
        <div class="container">
            <Placeholder
                class="row"
                :class="{ 'margin-top-row': hasPlaceholders }"
                :name="placeholderName"
                :rendering="rendering"
                :wrapperCols="cols"
            />
        </div>
    </section>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';

import { backgroundClass, hasPlaceholders } from '@/mixins/Helpers.mixin';

export default {
    name: 'ContainerMain',
    components: {
        Placeholder,
    },
    mixins: [backgroundClass, hasPlaceholders],
    props: {
        rendering: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        cols() {
            let cols = this.rendering?.params?.col || 'dynamic';
            const listOfSpecificComponents = {
                VectorTile: {
                    breakPoints: {
                        sm: 12,
                        md: 12,
                        xl: cols,
                    },
                },
            };
            if (this.hasPlaceholders) {
                const childComponents =
                    (this.placeholderName && this.rendering.placeholders[this.placeholderName]) || [];
                childComponents.length &&
                    childComponents.forEach((component) => {
                        const specificComponent = listOfSpecificComponents[component.componentName];
                        if (specificComponent) {
                            cols = specificComponent;
                        }
                    });
            }
            return cols;
        },
        placeholderName() {
            return Object.keys(this.rendering?.placeholders || {})[0] || 'abrdn-column';
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';

.margin-top-row {
    margin-top: -32px;
    ::v-deep > div {
        margin-top: 32px;
        display: flex;
    }
}

section {
    position: relative;
    padding: var(--default-vertical-padding) 0;
    @include media-breakpoint-down(lg) {
        padding: var(--tablet-vertical-padding) 0;
    }
    @include media-breakpoint-down(md) {
        padding: var(--mobile-vertical-padding) 0;
    }
}
</style>
