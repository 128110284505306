<template>
    <FundDetailsTabRow fullWidth class="mt-4">
        <FundDetailsTabSkeleton v-if="loading" />

        <AlertMessageBase v-else-if="error" type="error" :title="$t(error)" />

        <template v-else-if="tabData">
            <table aria-label="Fund details codes tab table" class="w-100">
                <thead hidden>
                    <tr>
                        <th scope="col">Fund key information name</th>
                        <th scope="col">Fund key information value</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="data in tabData">
                        <tr v-for="(item, key) in data" :key="key">
                            <template v-if="key !== 'fundStatisticsFootnote'">
                                <td v-if="dictionaryItems[key]" class="h6 w-50 py-4">
                                    {{ $t(dictionaryItems[key].name) + haveFootnote(key) }}
                                </td>
                                <td v-else class="h6 w-50 py-4">No dictionary key found [{{ key }}]</td>
                                <td class="body-default w-50 py-4">{{ formatItemValue(item, key) }}</td>
                            </template>
                        </tr>
                    </template>
                </tbody>
            </table>
            <div v-if="footnote" class="container mt-4 py-2 px-2" :class="vectorBasedBackground">
                <p class="footnote">{{ '[1] ' + footnote }}</p>
            </div>
        </template>

        <div v-else class="body-default">{{ $t('noResultsMessage') }}</div>

        <ManualDisclaimer
            v-if="disclaimerData && disclaimerData.length"
            :disclaimerData="disclaimerData"
            class="mt-4"
        />
    </FundDetailsTabRow>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import AlertMessageBase from '@/components/Generic/AlertMessageBase';
import ManualDisclaimer from '@/components/Generic/ManualDisclaimer';
import { UTCFormat } from '@/constants/dateFormats';
import { formatCurrency, formatNumber, localizeDate } from '@/mixins/Helpers.mixin';

import FundDetailsTabRow from '../FundDetailsTabRow';
import FundDetailsTabSharedLogic from '../FundDetailsTabSharedLogic.mixin';
import FundDetailsTabSkeleton from '../FundDetailsTabSkeleton';

export default {
    name: 'FundDetailsKeyInformationTab',

    components: {
        AlertMessageBase,
        FundDetailsTabRow,
        FundDetailsTabSkeleton,
        ManualDisclaimer,
    },

    mixins: [FundDetailsTabSharedLogic, localizeDate, formatCurrency, formatNumber],

    data() {
        return {
            dictionaryItems: {
                shareClassLaunchDate: {
                    name: 'fundDetailsKeyInfoShareClassLaunchDate',
                    type: 'date',
                },
                benchmark: {
                    name: 'fundDetailsKeyInfoBenchmark',
                    type: 'string',
                },
                underlyingYield: {
                    name: 'fundDetailsKeyInfoUnderlyingYield',
                    type: 'number',
                },
                distributionYield: {
                    name: 'fundDetailsKeyInfoDistributionYield',
                    type: 'number',
                },
                historicYield: {
                    name: 'fundDetailsKeyInfoHistoricYield',
                    type: 'number',
                },
                managementCosts: {
                    name: 'fundDetailsKeyInfoManagementCost',
                    type: 'string',
                },
                baseCurrency: {
                    name: 'fundDetailsKeyInfoBaseCurrency',
                    type: 'string',
                },
                managementFee: {
                    name: 'fundDetailsKeyInfoManagementFee',
                    type: 'string',
                },
                frontEndLoad: {
                    name: 'fundDetailsKeyInfoFrontEndLoad',
                    type: 'string',
                },
                fundLaunchDate: {
                    name: 'fundDetailsKeyInfoFundLaunchDate',
                    type: 'date',
                },
                fundSize: {
                    name: 'fundDetailsKeyInfoFundSize',
                    type: 'number',
                },
                domicile: {
                    name: 'fundDetailsKeyInfoDomicile',
                    type: 'string',
                },
                trustee: {
                    name: 'fundDetailsKeyInfoTrustee',
                    type: 'string',
                },
                registrar: {
                    name: 'fundDetailsKeyInfoRegistrar',
                    type: 'string',
                },
                auditor: {
                    name: 'fundDetailsKeyInfoAuditor',
                    type: 'string',
                },
                fundType: {
                    name: 'fundDetailsKeyInfoFundType',
                    type: 'string',
                },
                buySellSpread: {
                    name: 'fundDetailsKeyInfoBuySellSpread',
                    type: 'string',
                },
                highestNav: {
                    name: 'fundDetailsKeyInfoHighestNav',
                    type: 'object',
                },
                lowestNav: {
                    name: 'fundDetailsKeyInfoLowestNav',
                    type: 'object',
                },
                minInitialInvestment: {
                    name: 'fundDetailsKeyInfoMinInitialInvestment',
                    type: 'string',
                },
                performanceTarget: {
                    name: 'fundDetailsKeyInfoPerformanceTarget',
                    type: 'string',
                },
                performanceComparator: {
                    name: 'fundDetailsKeyInfoPerformanceComparator',
                    type: 'string',
                },
                portfolioConstrainingBenchmark: {
                    name: 'fundDetailsKeyInfoPortfolioConstrainingBenchmark',
                    type: 'string',
                },
                entryCharge: {
                    name: 'fundDetailsKeyInfoEntryCharge',
                    type: 'string',
                },
                valuationPoint: {
                    name: 'fundDetailsKeyInfoValuationPoint',
                    type: 'string',
                },
                switchingFee: {
                    name: 'fundDetailsKeyInfoSwitchingFee',
                    type: 'string',
                },
                fundIncludedUnder: {
                    name: 'fundDetailsKeyInfoFundIncludedUnder',
                    type: 'string',
                },
                numberOfHoldings: {
                    name: 'fundDetailsKeyInfoNumberOfHoldings',
                    type: 'string',
                },
                wal: {
                    name: 'fundDetailsKeyInfoWal',
                    type: 'string',
                },
                wam: {
                    name: 'fundDetailsKeyInfoWam',
                    type: 'string',
                },
                dailyAmcPerCent: {
                    name: 'fundDetailsKeyInfoDailyAmcPerCent',
                    type: 'percentage',
                },
                accountType: {
                    name: 'fundDetailsKeyInfoAccountType',
                    type: 'string',
                },
                custodian: {
                    name: 'fundDetailsKeyInfoCustodian',
                    type: 'string',
                },
                investmentManager: {
                    name: 'fundDetailsKeyInfoInvestmentManager',
                    type: 'string',
                },
                isaEligibility: {
                    name: 'fundDetailsKeyInfoIsaEligibility',
                    type: 'string',
                },
                issuingCompany: {
                    name: 'fundDetailsKeyInfoIssuingCompany',
                    type: 'string',
                },
                managementApproach: {
                    name: 'fundDetailsKeyInfoManagementApproach',
                    type: 'string',
                },
                managementCompany: {
                    name: 'fundDetailsKeyInfoManagementCompany',
                    type: 'string',
                },
                rebalanceFrequency: {
                    name: 'fundDetailsKeyInfoRebalanceFrequency',
                    type: 'string',
                },
                sfdrClassification: {
                    name: 'fundDetailsKeyInfoSfdrClassification',
                    type: 'string',
                },
                sippAvailable: {
                    name: 'fundDetailsKeyInfoSippAvailable',
                    type: 'string',
                },
                isin: {
                    name: 'fundDetailsKeyInfoIsin',
                    type: 'string',
                },
                netAssets: {
                    name: 'fundDetailsKeyInfoNetAssets',
                    type: 'number with standard notation',
                },
                shareClassCurrency: {
                    name: 'fundDetailsKeyInfoShareClassCurrency',
                    type: 'string',
                },
                sharesOutstanding: {
                    name: 'fundDetailsKeyInfoSharesOutstanding',
                    type: 'number with standard notation',
                },
                taxStatus: {
                    name: 'fundDetailsKeyInfoTaxStatus',
                    type: 'string',
                },
                totalExpenseRatio: {
                    name: 'fundDetailsKeyInfoTotalExpenseRatio',
                    type: 'percentage',
                },
                ucits: {
                    name: 'fundDetailsKeyInfoUcits',
                    type: 'string',
                },
                useOfIncome: {
                    name: 'fundDetailsKeyInfoUseOfIncome',
                    type: 'string',
                },
                amcPerCent: {
                    name: 'fundDetailsKeyInfoAmcPerCent',
                    type: 'percentage',
                },
                netExpenseRatio: {
                    name: 'fundDetailsKeyInfoNetExpenseRatio',
                    type: 'percentage',
                },
                currencyHedged: {
                    name: 'fundDetailsKeyInfoCurrencyHedged',
                    type: 'string',
                },
                dividendFrequency: {
                    name: 'fundDetailsKeyInfoDividendFrequency',
                    type: 'string',
                },
                exposure: {
                    name: 'fundDetailsKeyInfoExposure',
                    type: 'string',
                },
                assetClass: {
                    name: 'fundDetailsKeyInfoAssetClass',
                    type: 'string',
                },
                legalStructure: {
                    name: 'fundDetailsKeyInfoLegalStructure',
                    type: 'string',
                },
                grossExpenseRatio: {
                    name: 'fundDetailsKeyInfoGrossExpenseRatio',
                    type: 'number with standard notation',
                },
                ticker: {
                    name: 'fundDetailsKeyInfoTicker',
                    type: 'string',
                },
                cusip: {
                    name: 'fundDetailsKeyInfoCusip',
                    type: 'string',
                },
                fundManager: {
                    name: 'fundDetailsKeyInfoFundManager',
                    type: 'string',
                },
                inceptionDate: {
                    name: 'fundDetailsKeyInfoInceptionDate',
                    type: 'date',
                },
                netAssetValue: {
                    name: 'fundDetailsKeyInfoNetAssetValue',
                    type: 'number',
                },
                primaryBenchmark: {
                    name: 'fundDetailsKeyInfoPrimaryBenchmark',
                    type: 'string',
                },
                sevenDayNetYield: {
                    name: 'fundDetailsKeyInfoSevenDayNetYield',
                    type: 'number',
                },
                m2mVsAmortised: {
                    name: 'fundDetailsKeyInfoM2mVsAmortised',
                    type: 'number',
                },
                medianBidAskSpread: {
                    name: 'fundDetailsKeyInfoMedianBidAskSpread',
                    type: 'percentage',
                },
                basketSizeGold: {
                    name: 'fundDetailsKeyInfoBasketSizeGold',
                    type: 'number',
                },
                basketSizePalladium: {
                    name: 'fundDetailsKeyInfoBasketSizePalladium',
                    type: 'number',
                },
                basketSizePlatinum: {
                    name: 'fundDetailsKeyInfoBasketSizePlatinum',
                    type: 'number',
                },
                basketSizeSilver: {
                    name: 'fundDetailsKeyInfoBasketSizeSilver',
                    type: 'number',
                },
                cefTickerSymbol: {
                    name: 'fundDetailsKeyInfoCefTickerSymbol',
                    type: 'string',
                },
                cefSharesOutstanding: {
                    name: 'fundDetailsKeyInfoCefSharesOutstanding',
                    type: 'string',
                },
                cefManagedAssets: {
                    name: 'fundDetailsKeyInfoCefManagedAssets',
                    type: 'string',
                },
                cefNetAssets: {
                    name: 'fundDetailsKeyInfoCefNetAssets',
                    type: 'string',
                },
                cefFiscalYearEnd: {
                    name: 'fundDetailsKeyInfoCefFiscalYearEnd',
                    type: 'string',
                },
                cefMarketDistributionRate: {
                    name: 'fundDetailsKeyInfoCefMarketDistributionRate',
                    type: 'string',
                },
                cefNavDistributionRate: {
                    name: 'fundDetailsKeyInfoCefNavDistributionRate',
                    type: 'string',
                },
                cefGrossExpenseRatio: {
                    name: 'fundDetailsKeyInfoCefGrossExpenseRatio',
                    type: 'string',
                },
                cefNetExpenseRatio: {
                    name: 'fundDetailsKeyInfoCefNetExpenseRatio',
                    type: 'string',
                },
            },
        };
    },

    computed: {
        ...mapState('fundDetails', {
            loading: (state) => state.keyInformationTabData.loading,
            error: (state) => state.keyInformationTabData.error,
            tabData: (state) => state.keyInformationTabData.data,
            disclaimerData: (state) => state.keyInfoTabManualDisclaimerData.data,
            correlationFundRangeID: 'correlationFundRangeID',
            fundRangeName: 'fundRangeName',
            name: 'name',
            fundId: 'fundId',
        }),
        ...mapGetters('fundDetails', ['selectedShareClass']),
        footnote() {
            return this.tabData?.staticText?.fundStatisticsFootnote;
        },
    },

    async created() {
        if (this.disclaimerData || this.isExperienceEditor) return;

        const {
            fundsConfiguration: {
                countryInvestors: { countryCode },
            },
            displayLanguage,
            site: { name },
        } = this.$jss.sitecoreContext();

        await this.fetchDisclaimerData({
            countryCode,
            language: displayLanguage,
            shareClass: this.selectedShareClass.id,
            correlationFundRangeId: this.correlationFundRangeID,
            fundRangeName: this.fundRangeName,
            fundName: this.name,
            fund: this.fundId,
            site: name,
        });
    },

    methods: {
        ...mapActions('fundDetails', {
            fetchTabData: 'fetchKeyInformationTabData',
            fetchDisclaimerData: 'fetchKeyInfoTabManualDisclaimerData',
        }),
        formatItemValue(item, key) {
            if (!item || (this.dictionaryItems[key].type === 'object' && !item.value))
                return this.$t('notAvailableShortMessage');

            const [price] = this.selectedShareClass?.prices || [{}];

            const priceCurrencyCode = price?.priceCurrencyCode || 'GBP';

            if (key === 'm2mVsAmortised') {
                return this.formatCurrency(item, priceCurrencyCode, 'standard', 6);
            }

            if (item.date) {
                return `${this.formatCurrency(item.value, priceCurrencyCode, 'compact')} ${this.$t(
                    'asOf',
                )} ${this.localizeDate(item.date)}`;
            }

            switch (this.dictionaryItems[key].type) {
                case 'date':
                    return this.localizeDate(item, UTCFormat);
                case 'number':
                    return this.formatCurrency(item, priceCurrencyCode, 'compact');
                case 'percentage':
                    return this.formatNumber(item, this.dictionaryItems[key].type);
                case 'number with standard notation':
                    return this.formatCurrency(item, priceCurrencyCode, 'standard');
                case 'object':
                    return parseFloat(item.value).toFixed(2) + ' (' + this.localizeDate(item.date) + ')';
                default:
                    return item;
            }
        },
        haveFootnote(key) {
            return key === 'wal' || key === 'wam' ? '[1]' : '';
        },
    },
};
</script>

<style scoped lang="scss">
tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
</style>
