<template>
    <section
        :id="anchorId"
        :data-e2e-component-uid="rendering.uid"
        class="accordion default-section-padding link-change"
        :class="backgroundClass"
    >
        <div class="container">
            <Placeholder
                class="mb-4"
                :class="insideArticleBody ? 'col-lg-12' : 'col-lg-8'"
                :rendering="rendering"
                name="abrdn-tiles-heading"
            />
            <div :class="insideArticleBody ? 'col-lg-12' : 'col-lg-8'">
                <AccordionItem
                    v-for="(item, index) in fields.items"
                    :key="item.id"
                    :title="item.fields.title"
                    :description="item.fields.description"
                    :isFirst="index === 0"
                    :presentation="rendering.params.presentation"
                >
                    <AccordionItems :items="item.fields.items" class="mt-3" />
                </AccordionItem>
            </div>
        </div>
    </section>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';

import AccordionItem from '@/components/Sections/Accordion/AccordionItem';
import AccordionItems from '@/components/Sections/Accordion/AccordionItems';
import { backgroundClass } from '@/mixins/Helpers.mixin';

export default {
    name: 'Accordion',
    components: {
        AccordionItem,
        AccordionItems,
        Placeholder,
    },
    mixins: [backgroundClass],
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    data() {
        return {
            insideArticleBody: false,
        };
    },
    mounted() {
        if (typeof window !== 'undefined') {
            const articleBody = document.querySelector('.abrdn-article-body');
            const accordion = document.querySelector('.accordion');
            if (articleBody && articleBody.contains(accordion)) {
                this.insideArticleBody = true;
            }
        }
    },
};
</script>

<style scoped lang="scss">
.abrdn-article-body {
    .accordion {
        padding: 0;
        & > div {
            padding: 0;
        }
    }
}
</style>
