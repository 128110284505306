<template>
    <div :class="{ 'd-none': !hasSlot }">
        <slot v-if="hasSlot" />
    </div>
</template>

<script>
export default {
    name: 'ScLinkPlaceholder',
    computed: {
        hasSlot() {
            return this.$slots.default;
        },
    },
};
</script>

<style scoped></style>
