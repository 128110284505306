<template>
    <FundDetailsTabRow fullWidth class="mt-4">
        <FundDetailsTabSkeleton v-if="loading" />
        <AlertMessageBase v-else-if="error" type="error" :title="$t(error)" />
        <FundDetailsDiscretePerformanceTable v-else :tabData="tabData" />
    </FundDetailsTabRow>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import AlertMessageBase from '@/components/Generic/AlertMessageBase';

import FundDetailsTabRow from '../../FundDetailsTabRow';
import FundDetailsTabSharedLogic from '../../FundDetailsTabSharedLogic.mixin';
import FundDetailsTabSkeleton from '../../FundDetailsTabSkeleton.vue';
import FundDetailsDiscretePerformanceTable from './FundDetailsDiscretePerformanceTable';

export default {
    name: 'FundDetailsDiscreteTab',

    components: {
        AlertMessageBase,
        FundDetailsDiscretePerformanceTable,
        FundDetailsTabRow,
        FundDetailsTabSkeleton,
    },

    mixins: [FundDetailsTabSharedLogic],

    computed: {
        ...mapState('fundDetails', {
            error: (state) => state.discreteTabData.error,
            loading: (state) => state.discreteTabData.loading,
            tabData: (state) => state.discreteTabData.data,
        }),
    },

    methods: {
        ...mapActions('fundDetails', {
            fetchTabData: 'fetchDiscreteTabData',
        }),
    },
};
</script>
