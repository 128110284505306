<template>
    <div class="chart-root d-flex flex-column flex-md-row">
        <transition-group class="w-100" name="list" tag="div">
            <div
                v-for="(year, i) in years"
                :key="year.id"
                class="chart-year-line d-flex w-100 position-relative"
                :class="vectorBasedBackground"
            >
                <Tooltip>
                    <template #tooltipTrigger>
                        <div class="year d-flex justify-content-center align-items-center form-label">
                            Year {{ i + minYears }}
                        </div>
                    </template>
                    <template #tooltipContent>
                        <div class="d-flex justify-content-between align-items-center mb-1 pb-1 border-bottom">
                            <div class="row__title d-flex justify-content-start align-items-start">
                                <div class="p-1 background-black" />
                                <p class="form-label ms-1">Your payments</p>
                            </div>
                            <div class="row__value ms-3 form-label text-grey text-nowrap">
                                £ {{ addThousandSeparator(year.payment) }}
                            </div>
                        </div>
                        <div class="d-flex justify-content-between align-items-center mb-1 pb-1 border-bottom">
                            <div class="row__title d-flex justify-content-start align-items-start">
                                <div class="p-1 background-secondary" />
                                <p class="form-label ms-1">Growth of your investment</p>
                            </div>
                            <div class="row__value ms-3 form-label text-grey text-nowrap">
                                £ {{ addThousandSeparator(year.paymentGrow) }}
                            </div>
                        </div>
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="row__title d-flex justify-content-start align-items-start">
                                <div class="p-1 background-grey" />
                                <p class="form-label ms-1">Total value of ISA</p>
                            </div>
                            <div class="row__value ms-3 form-label text-grey text-nowrap">
                                £ {{ addThousandSeparator(year.payment + year.paymentGrow) }}
                            </div>
                        </div>
                    </template>
                </Tooltip>
                <div class="payments-wrapper d-flex">
                    <div
                        :style="{
                            ...(isValuesSumZero || !year.payment
                                ? { width: `1px` }
                                : { width: `${relativeYearsValues[i].payment}%` }),
                        }"
                        class="payment d-flex flex-wrap align-items-center"
                    >
                        <p v-if="!isValuesSumZero" class="pe-1 form-label">
                            £ {{ addThousandSeparator(year.payment) }}
                        </p>
                    </div>
                    <div
                        :style="{
                            ...(isValuesSumZero || !year.paymentGrow
                                ? { width: `0px` }
                                : { width: `${relativeYearsValues[i].paymentGrow}%` }),
                        }"
                        class="payment-grow"
                    >
                        <p v-if="!isValuesSumZero" class="h-100 d-flex align-items-center form-label text-grey">
                            £ {{ addThousandSeparator(year.payment + year.paymentGrow) }}
                        </p>
                    </div>
                </div>
            </div>
        </transition-group>
    </div>
</template>

<script>
import Tooltip from '@/components/Generic/FormElements/FormFields/Tooltip';

export default {
    name: 'Chart',
    components: { Tooltip },
    props: {
        minYears: {
            type: Number,
            default: 5,
        },
        years: {
            type: Array,
            default: () => [
                { payment: 0, paymentGrow: 0, id: 0 },
                { payment: 0, paymentGrow: 0, id: 1 },
                { payment: 0, paymentGrow: 0, id: 2 },
                { payment: 0, paymentGrow: 0, id: 3 },
                { payment: 0, paymentGrow: 0, id: 4 },
            ],
        },
    },
    data() {
        return {
            relativeYearsValues: [],
            biggestValue: 0,
        };
    },
    computed: {
        isValuesSumZero() {
            return !this.years.reduce((acc, number) => {
                const sum = number.payment + acc;
                return sum;
            }, 0);
        },
    },
    watch: {
        years() {
            this.calculateRelativeValues();
        },
    },
    created() {
        this.calculateRelativeValues();
    },
    methods: {
        calculateRelativeValues() {
            let biggestValue = 0;
            this.years.forEach((year) => {
                if (year.payment + year.paymentGrow > biggestValue) {
                    biggestValue = year.payment + year.paymentGrow;
                }
            });

            const years = this.years.map((year) => {
                const calcRelativeValue = (value) => ((value / biggestValue) * 100).toFixed(2);
                return {
                    payment: calcRelativeValue(year.payment),
                    paymentGrow: calcRelativeValue(year.paymentGrow),
                };
            });
            this.relativeYearsValues = years;
        },
        addThousandSeparator(value) {
            return Math.floor(value)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
.chart-root {
    .chart-year-line {
        height: 48px;

        .year {
            width: 78px;
            min-width: 78px;
            height: 100%;
        }

        margin-bottom: 4px;

        .payments-wrapper {
            width: calc(100% - 178px);
            max-width: calc(100% - 178px);
            height: 100%;
        }

        .payment {
            height: 100%;
            background: #000;
            color: #fff;
            transition: width ease-in-out 1s;
            overflow: hidden;

            &::before {
                content: '';
                display: inline-block;
                height: 100%;
                width: 16px;
            }
        }

        .payment-grow {
            height: 100%;
            background: var(--secondary);
            transition: width ease-in-out 1s;
            position: relative;

            p {
                position: absolute;
                left: calc(100% + 8px);
                white-space: nowrap;
            }
        }
    }

    .chart-percentage {
        position: relative;
    }

    .outer-wrapper {
        overflow: hidden;
        height: 1.2em;
        line-height: 1.2em;
        border: 1px dotted black;
        margin: 1em;
    }

    .years-saved-label {
        margin-top: 28px;
    }

    .background-grey {
        background: var(--text-grey);
    }

    .list-enter-active,
    .list-leave-active {
        transition: all 0.3s;
    }

    .list-enter,
    .list-leave-to {
        opacity: 0;
    }

    .tooltip {
        display: flex;
        ::v-deep {
            .tooltip__trigger {
                transition: background-color 0.2s;
                &:hover,
                &:focus {
                    background-color: var(--button-mist);
                }
            }
            .tooltip__content {
                min-width: 350px;
                @include media-breakpoint-down(sm) {
                    min-width: auto;
                }
            }
        }
    }
}
</style>
