<template>
    <section
        :id="anchorId"
        :data-e2e-component-uid="rendering.uid"
        class="container background-white default-section-padding"
    >
        <div class="row d-flex justify-content-start">
            <div class="col-12 col-md-8 col-lg-8">
                <Placeholder
                    name="abrdn-article-body"
                    class="press-release-body d-flex flex-column"
                    :rendering="rendering"
                    :articleBody="true"
                />
            </div>
        </div>
    </section>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';

export default {
    name: 'PressReleaseBody',
    components: {
        Placeholder,
    },
    props: {
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
};
</script>

<style scoped lang="scss">
.press-release-body {
    ::v-deep > * {
        width: 100% !important;
    }
    ::v-deep > *:not(img):not(:first-child) {
        margin: 0 !important;
        margin-top: calc(var(--spacer) * 5) !important;
    }

    ::v-deep > img {
        margin-top: calc(var(--spacer) * 5) !important;
    }

    ::v-deep img + img {
        margin-top: calc(var(--spacer) * 2) !important;
    }

    ::v-deep > * + .abrdn-list-root {
        margin-top: calc(var(--spacer) * 4) !important;
    }

    ::v-deep .podcast-box {
        margin-top: calc(var(--spacer) * 4) !important;
    }

    ::v-deep ul:last-child {
        margin: 0 !important;
    }
}
</style>
