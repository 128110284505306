<template>
    <section v-if="filterMappings" :id="anchorId">
        <FilterBarFunctional
            v-show="false"
            :formattedMapping="filterMappings"
            :vuexData="vuexData"
            :data-e2e-component-uid="rendering.uid"
        />
        <ArticleCards :data-e2e-component-uid="rendering.uid + 'listing'" :cardCategoryField="cardCategoryField" />
    </section>
    <EEPlaceholder v-else :id="anchorId">Filtered Article Cards</EEPlaceholder>
</template>

<script>
import FilterBarFunctional from '@/components/Functional/ArticleFilterBar/FilterBar';
import ArticleCards from '@/components/Pages/ArticleListings/ArticleCards.vue';
import EEPlaceholder from '@/components/Sections/EEPlaceholder';

export default {
    name: 'FilteredArticleCards',
    components: {
        ArticleCards,
        FilterBarFunctional,
        EEPlaceholder,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        filterMappings() {
            return this.fields.filterMappings.value;
        },
        cardCategoryField() {
            return this.fields?.cardCategoryField.value || false;
        },
        vuexData() {
            return {
                filterBar: {
                    state: {
                        namespace: 'articles',
                        items: [
                            'filters',
                            'filtersForApprove',
                            'approvedFilters',
                            'filtersFacets',
                            'emptyFacets',
                            'page',
                            'take',
                            'loading',
                            'totalResults',
                            'articles',
                            'filtersForApprove',
                        ],
                    },
                    actions: {
                        namespace: 'articles',
                        items: ['getArticles', 'getFacets', 'getEmptyFacets'],
                    },
                    mutations: {
                        namespace: 'articles',
                        items: [
                            'setFilters',
                            'setFiltersForApprove',
                            'setApprovedFilters',
                            'setBaseUrl',
                            'setFilterMappings',
                            'setPaginationData',
                        ],
                    },
                },
            };
        },
    },
};
</script>

<style scoped lang="scss"></style>
