<template>
    <FormElemWrapper
        class="form-element--value-field"
        :class="{ ['form-element--filled']: inputData.raw }"
        :isError="isError"
    >
        <template v-if="getLabel" #fieldLabelSlot>{{ getLabel }}</template>
        <template v-if="getTooltip" #tooltipSlot>{{ getTooltip }}</template>
        <template #fieldSlot>
            <span class="input-box input-box--value-field">
                <span class="input-box__currency">£</span>
                <input
                    ref="input"
                    type="text"
                    :maxlength="maxLength"
                    class="field-text"
                    :name="fields.name.value"
                    :placeholder="fields.placeholder.value"
                    @focus="formatOnFocus()"
                    @blur="formatInput()"
                    @change="formatInput()"
                    @keypress="isNumber($event)"
                    @keyup="checkCorrectKeys($event)"
                    @keydown.enter="getValueOnKeyDownEnter()"
                />
                <button
                    v-if="inputData.raw.length"
                    class="input-action-btn input-icon input-action-btn--reset"
                    @click="resetField"
                >
                    <IconX />
                </button>
                <span v-if="isError" class="input-icon input-icon--right input-icon--error">
                    <IconWarning />
                </span>
            </span>
        </template>
        <template #errorSlot>{{ errorMessage }}</template>
        <template v-if="getHintMessage" #hintSlot>{{ getHintMessage }}</template>
    </FormElemWrapper>
</template>

<script>
import wNumb from 'wnumb';

import FormElemWrapper from '@/components/Generic/FormElements/FormFields/FormElemWrapper.vue';
import IconWarning from '@/components/Icons/icon-warning.vue';
import IconX from '@/components/Icons/icon-x.vue';
import FormFieldsGetters from '@/mixins/FormFieldsGetters.mixin';

export default {
    name: 'ValueField',
    components: {
        IconWarning,
        IconX,
        FormElemWrapper,
    },
    mixins: [FormFieldsGetters],
    props: {
        fields: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            isError: false,
            inputData: {
                raw: '',
                formatted: '',
            },
            formatter: null,
        };
    },
    computed: {
        formatPattern() {
            const defPattern = {
                mark: '',
                thousand: ',',
                prefix: '',
                suffix: '',
                decimals: 2,
            };
            return this.fields.formatPattern.value || defPattern;
        },
        maxLength() {
            let maxLength = this.fields?.maxlength?.value;
            if (!maxLength) {
                return '';
            }
            if (this.formatPattern.decimals) {
                maxLength = maxLength + this.formatPattern.decimals + 1;
            }
            return maxLength;
        },
    },
    watch: {
        'inputData.raw': {
            handler: function (val) {
                this.$emit('updateValue', val);
                if (this.$refs.input.value) {
                    this.validate(val);
                } else {
                    this.validate(0);
                }
            },
        },
    },
    created() {
        this.formatter = wNumb(this.formatPattern);
    },
    methods: {
        formatOnFocus() {
            this.$refs.input.value = this.inputData.raw || '';
        },
        checkCorrectKeys(evt) {
            if (this.isNumber(evt)) {
                this.inputData.raw = +this.$refs.input.value;
            } else {
                return false;
            }
        },
        getValueOnKeyDownEnter() {
            this.formatInput();
            this.$refs.input.blur();
            this.$emit('onKeyDownEnter');
        },
        resetField() {
            this.inputData.raw = '';
            this.inputData.formatted = '';
            this.$refs.input.value = '';
        },
        formatInput() {
            const value = Number(this.$refs.input.value.replace(/[^\d.,]/g, ''));
            if (!value && value !== 0) {
                this.$refs.input.value = this.inputData.formatted;
                return;
            }
            this.inputData.raw = value;
            this.inputData.formatted = this.formatter.to(this.inputData.raw);
            this.$refs.input.value = this.inputData.formatted;
        },
        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            const charCode = evt.which ? evt.which : evt.keyCode;
            let defCheck = charCode > 31 && (charCode < 48 || charCode > 57);
            if (this.formatPattern.decimals) {
                //Allow period symbol (.)
                defCheck = charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46;
            }
            if (defCheck) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/components/input-field';
</style>
