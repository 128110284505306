<template>
    <svg
        width="108"
        height="108"
        viewBox="0 0 108 108"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
    >
        <path
            d="M55.0275 66.731H54.1558C46.7466 66.731 40.7266 60.6345 40.7266 53.131V29.6C40.7266 22.0966 46.7466 16 54.1558 16H55.0275C62.4368 16 68.4568 22.0966 68.4568 29.6V53.131C68.4568 60.6345 62.4368 66.731 55.0275 66.731ZM54.1558 21.5172C49.743 21.5172 46.1745 25.131 46.1745 29.6V53.131C46.1745 57.6 49.743 61.2138 54.1558 61.2138H55.0275C59.4404 61.2138 63.0088 57.6 63.0088 53.131V29.6C63.0088 25.131 59.4404 21.5172 55.0275 21.5172H54.1558Z"
            fill="currentColor"
        />
        <path
            d="M54.7279 90.8689C53.2297 90.8689 52.0039 89.6275 52.0039 88.1103V73.6551C52.0039 72.1379 53.2297 70.8965 54.7279 70.8965C56.2261 70.8965 57.4519 72.1379 57.4519 73.6551V88.1103C57.4519 89.6275 56.2261 90.8689 54.7279 90.8689Z"
            fill="currentColor"
        />
        <path
            d="M65.7328 91.9999H43.4506C41.9524 91.9999 40.7266 90.7585 40.7266 89.2413C40.7266 87.724 41.9524 86.4827 43.4506 86.4827H65.7328C67.231 86.4827 68.4568 87.724 68.4568 89.2413C68.4568 90.7585 67.231 91.9999 65.7328 91.9999Z"
            fill="currentColor"
        />
        <path
            d="M54.1015 75.2551C42.579 75.2551 32.1461 67.8896 28.1691 56.9655C27.6516 55.531 28.3598 53.9586 29.7763 53.4345C31.1927 52.9103 32.7454 53.6276 33.263 55.062C36.4773 63.862 44.8399 69.7655 54.0743 69.7655C63.3086 69.7655 71.4261 64.1379 74.7494 55.4207C75.2942 53.9862 76.8741 53.2965 78.2633 53.8482C79.6525 54.4 80.3608 56 79.816 57.4069C75.7028 68.2482 65.5968 75.2551 54.1015 75.2551Z"
            fill="currentColor"
        />
    </svg>
</template>

<script>
export default {
    name: 'IconPodcast',
};
</script>

<style scoped></style>
