<template>
    <div class="d-flex align-items-center justify-content-between">
        <AbrdnButton buttonType="secondary" :class="{ disabled: isLoading }" @click.native.stop="$emit('clearFilters')">
            <span>{{ $t('clear') }}</span>
        </AbrdnButton>
        <AbrdnButton buttonType="primary" :class="{ disabled: isLoading }" @click.native="$emit('filterApply')">
            <span>{{ $t('apply') }}</span>
        </AbrdnButton>
    </div>
</template>

<script>
import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton.vue';

export default {
    name: 'MobileBaseFiltersActions',
    components: { AbrdnButton },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped></style>
