<template>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16 11C16 13.7614 13.7614 16 11 16C8.23858 16 6 13.7614 6 11C6 8.23858 8.23858 6 11 6C13.7614 6 16 8.23858 16 11ZM15.5184 16.3466C14.2991 17.3781 12.7222 18 11 18C7.13401 18 4 14.866 4 11C4 7.13401 7.13401 4 11 4C14.866 4 18 7.13401 18 11C18 12.4214 17.5763 13.7439 16.8484 14.8481L20.293 18.2927C20.6836 18.6833 20.6836 19.3164 20.293 19.707C19.9025 20.0975 19.2694 20.0975 18.8788 19.707L15.5184 16.3466Z"
            fill="currentColor"
        />
    </svg>
</template>

<script>
export default {
    name: 'IconSearch',
};
</script>

<style scoped></style>
