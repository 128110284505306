<template>
    <AbrdnPlaceholder v-if="!havePremiumArticleEloquaForm" name="abrdn-article-bottom-content" :rendering="rendering" />
</template>

<script>
import { mapState } from 'vuex';

import AbrdnPlaceholder from '@/components/Generic/SitecoreWrappers/AbrdnPlaceholder.vue';

export default {
    name: 'ArticleBottomContainer',
    components: {
        AbrdnPlaceholder,
    },
    props: {
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        ...mapState('global', ['havePremiumArticleEloquaForm']),
    },
};
</script>

<style lang="scss" scoped></style>
