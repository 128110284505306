<template>
    <div>
        <article v-for="article in articles.items" :key="article.id" class="article-description pb-7 mb-7">
            <ScText :id="article.id" tag="h2" class="mb-3" :field="article.fields.title" />
            <div
                v-for="(section, sectionIndex) in article.fields.items"
                :key="section.id"
                :class="{ 'mt-8': sectionIndex !== 0 }"
            >
                <ScText
                    :class="{ 'hidden-subsection-title': !isExperienceEditor }"
                    tag="h3"
                    class="mb-2"
                    :field="section.fields.subsectionTitle"
                />
                <ScRichText :id="section.id" :field="section.fields.description" />
                <div v-for="accordionGroup in section.fields.items" :key="accordionGroup.id" class="mt-6">
                    <AccordionNew
                        v-for="(accordion, accordionIndex) in accordionGroup.fields.items"
                        :key="accordion.id"
                        :title="accordion.fields.title"
                        :description="accordion.fields.description"
                        :isFirst="accordionIndex === 0"
                    />
                </div>
            </div>
        </article>
    </div>
</template>

<script>
import { RichText, Text } from '@sitecore-jss/sitecore-jss-vue';

import AccordionNew from '@/components/Generic/AccordionNew';

export default {
    name: 'Articles',
    components: {
        ScRichText: RichText,
        ScText: Text,
        AccordionNew,
    },
    props: {
        articles: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>

<style lang="scss" scoped>
.article-description {
    border-bottom: 1px solid var(--digital-steel-grey);
    &:last-child {
        border-bottom: none;
    }
}

::v-deep p {
    white-space: pre-line;
    margin-bottom: 16px;
}

::v-deep a {
    font-weight: 600;
    text-decoration: underline !important;
    cursor: pointer;
}

::v-deep .hidden-subsection-title {
    display: none;
}
</style>
