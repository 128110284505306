<template>
    <EEPlaceholder v-if="isExperienceEditor">Premium Article Eloqua Form</EEPlaceholder>
    <transition v-else name="slide-out">
        <div v-if="showForm" id="premium-eloqua-form" class="position-relative">
            <div class="faded-top position-absolute" />
            <EloquaForm
                :fields="fields"
                :rendering="rendering"
                :background="'background-black'"
                :icon="true"
                class="px-2 px-md-4 py-4"
                @submitSuccess="onSubmit"
            />
        </div>
    </transition>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

import EEPlaceholder from '@/components/Sections/EEPlaceholder';
import EloquaForm from '@/components/Sections/EloquaForm';
import Cookie from '@/mixins/Cookie.mixin';

export default {
    name: 'PremiumArticleEloquaForm',
    components: { EloquaForm, EEPlaceholder },
    mixins: [Cookie],
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            showForm: false,
        };
    },
    computed: {
        ...mapState('global', ['havePremiumArticleEloquaForm']),
    },
    mounted() {
        if (!!this.fields?.formJson?.value?.layout?.rows?.length && !this.getCookie('abrdn-premium-article')) {
            this.setHavePremiumArticleEloquaForm(true);
            this.showForm = true;
        }
    },
    methods: {
        ...mapMutations('global', ['setHavePremiumArticleEloquaForm']),
        onSubmit() {
            const value = {
                sitename: this.$jss.sitecoreContext().site.name,
                language: this.$jss.sitecoreContext().language,
            };
            this.setCookie('abrdn-premium-article', JSON.stringify(value), 30);
            this.showForm = false;
            setTimeout(() => {
                this.setHavePremiumArticleEloquaForm(false);
                this.$nextTick(() => {
                    document.querySelector('.abrdn-article-body').focus({ preventScroll: true });
                });
            }, 3000);
        },
    },
};
</script>

<style scoped lang="scss">
.faded-top {
    top: -100px;
    height: 100px;
    width: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
}

.slide-out-leave-active {
    animation: slide-out 0.3s ease-in-out 2.7s;
}

@keyframes slide-out {
    from {
        transform: translateY(0%);
    }
    to {
        transform: translateY(100%);
    }
}
</style>
