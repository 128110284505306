<template>
    <section class="default-section-padding background-white">
        <FundDetailsTabRow>
            <h2>{{ title }}</h2>
            <div class="mt-3">
                <FundDetailsTabSkeleton v-if="loading" />
                <AlertMessageBase v-else-if="error" type="error" :title="$t(error)" />
                <ul v-else-if="tabData && tabData.length" class="abrdn-list">
                    <li v-for="item in tabData" :key="item" class="body-default">{{ item }}</li>
                </ul>
                <div v-else class="body-default">{{ $t('noResultsMessage') }}</div>
            </div>
        </FundDetailsTabRow>
    </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import AlertMessageBase from '@/components/Generic/AlertMessageBase';

import FundDetailsTabRow from '../FundDetailsTabRow';
import FundDetailsTabSharedLogic from '../FundDetailsTabSharedLogic.mixin';
import FundDetailsTabSkeleton from '../FundDetailsTabSkeleton';

export default {
    name: 'FundDetailsManagementTab',

    components: {
        AlertMessageBase,
        FundDetailsTabRow,
        FundDetailsTabSkeleton,
    },

    mixins: [FundDetailsTabSharedLogic],

    props: {
        title: {
            type: String,
            default: '',
        },
    },

    computed: {
        ...mapState('fundDetails', {
            loading: (state) => state.managementTabData.loading,
            error: (state) => state.managementTabData.error,
            tabData: (state) => state.managementTabData.data,
        }),
    },

    methods: {
        ...mapActions('fundDetails', {
            fetchTabData: 'fetchManagementTabData',
        }),
    },
};
</script>
