<template>
    <ScLink :field="scLinkField" :showExternalIcon="false" :data-e2e-component-uid="renderingId" class="article-card">
        <div class="d-flex flex-column h-100">
            <div class="flex-shrink-0 position-relative">
                <StaticImage
                    :src="cardData.media"
                    :alt="cardData.title"
                    class="article-card__thumbnail"
                    :srcSet="[{ mw: 400 }, { mw: 600 }, { mw: 800 }, { mw: 1000 }]"
                    sizes="
                        (max-width: 575px) 100vw,
                        (max-width: 767px) 510px,
                        (max-width: 991px) 330px,
                        (max-width: 1199px) 25vw,
                        270px
                    "
                />
                <div v-if="isTechzoneArticle" class="article-card__badge text-link" :class="vectorBasedBackground">
                    {{ $t('techzone') }}
                </div>
            </div>
            <div class="article-card__body py-3 px-2 flex-grow-1" :class="vectorBasedBackground">
                <div class="d-flex align-items-start section-header-small">
                    <span class="article-category section-header-small">{{ getCategoryText }}</span>
                    <time class="ms-auto section-header-small text-nowrap">{{
                        localizeDate(cardData.publicationDate)
                    }}</time>
                </div>
                <div :class="['mt-2', useLineClamps ? 'mb-8' : 'mb-2']">
                    <h5 :class="['article-card__title', useLineClamps && 'line-clamp-4']">{{ cardData.title }}</h5>
                    <p :class="['body-small text-grey mt-1', useLineClamps && 'line-clamp-3']">
                        {{ cardData.strapline }}
                    </p>
                </div>
                <div class="mt-auto">
                    <span v-if="isTechzoneArticle" class="text-link">
                        <span>{{ scLinkField.value.text }}</span>
                        <IconExternal class="external-icon" />
                    </span>
                    <Readtime
                        v-else
                        :articleType="cardData.articleType"
                        :articleTypeConst="articleTypeConst"
                        :duration="cardData.duration"
                        class="section-header-small"
                    />
                </div>
            </div>
        </div>
    </ScLink>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { mapState } from 'vuex';

import ScLink from '@/components/Generic/SitecoreWrappers/ScLink.vue';
import StaticImage from '@/components/Generic/SitecoreWrappers/StaticImage.vue';
import IconExternal from '@/components/Icons/icon-external.vue';
import Readtime from '@/components/Includes/Readtime.vue';
import { localizeDate } from '@/mixins/Helpers.mixin';

export default {
    name: 'ArticleCard',
    components: {
        IconExternal,
        Readtime,
        ScLink,
        StaticImage,
    },
    mixins: [localizeDate],
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        onRecentPage: {
            type: Boolean,
            default: /* istanbul ignore next */ () => false,
        },
        cardCategoryField: {
            type: [String, Boolean],
            default: /* istanbul ignore next */ () => false,
        },
        useLineClamps: {
            type: Boolean,
            default: /* istanbul ignore next */ () => true,
        },
    },
    data() {
        return {
            renderingId: undefined,
        };
    },
    computed: {
        ...mapState('articles', ['filters', 'baseUrl']),
        cardData() {
            return this.fields;
        },
        articleBaseUrl() {
            return this.$jss.routeData();
        },
        articleTypeConst() {
            return this.fields.allTemplates ? this.fields.allTemplates[0] : false;
        },
        getCategoryText() {
            if (this.cardCategoryField) return this.fields[this.cardCategoryField];
            return this.onRecentPage ? this.cardData.category : this.getCategory(this.cardData);
        },
        url() {
            if (!this.cardData?.url) {
                return this.baseUrl ? `${this.baseUrl}/${this.cardData?.name}` : '/';
            }
            return this.cardData.url;
        },
        isTechzoneArticle() {
            return !!this.cardData.articleUrl;
        },
        scLinkField() {
            let value;

            if (this.isTechzoneArticle) {
                value = {
                    text: this.$t('readOnTechzone'),
                    target: '_blank',
                    href: this.cardData.articleUrl,
                };
            } else {
                value = {
                    href: this.url,
                };
            }

            return { value };
        },
    },
    mounted() {
        this.renderingId = uuidv4();
    },
    methods: {
        getCategory(card) {
            let categoryName = '';
            this.filters.forEach((item) => {
                const categoryOption = item.options.filter((item) => item.id === card.category)[0];
                if (categoryOption) {
                    categoryName = categoryOption.name;
                }
            });
            return categoryName;
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';

.article-card {
    display: block;
    height: 100%;
    transition: box-shadow 0.2s ease-in-out;

    &:hover,
    &:focus {
        box-shadow: 0 4px 9px rgba(0, 0, 0, 0.25);

        .article-card__title {
            text-decoration: underline;
        }
    }
}

.article-card__thumbnail {
    width: 100%;
    height: 140px;
    object-fit: cover;

    @include media-breakpoint-down(md) {
        height: 178px;
    }
}

.article-card__body {
    display: flex;
    flex-direction: column;
}

.article-card__title {
    letter-spacing: 0.03em;
}

.article-card__badge {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: calc(var(--spacer) * 1.5) calc(var(--spacer) * 2);
}
</style>
