<template>
    <div v-if="isPlaceholderNotEmpty || isExperienceEditor">
        <Placeholder
            :name="name"
            :rendering="rendering"
            :componentFactory="componentFactory"
            :fields="fields"
            :params="params"
            :errorComponent="errorComponent"
            v-bind="$attrs"
        />
    </div>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';

export default {
    name: 'AbrdnPlaceholder',
    components: {
        Placeholder,
    },
    props: {
        name: {
            type: String,
            required: true,
        },
        rendering: {
            type: Object,
            required: true,
        },
        componentFactory: {
            type: Function,
            default: undefined,
        },
        fields: {
            type: Object,
            default: undefined,
        },
        params: {
            type: Object,
            default: undefined,
        },
        errorComponent: {
            type: Object,
            default: undefined,
        },
    },
    computed: {
        isPlaceholderNotEmpty() {
            return this.rendering?.placeholders?.[this.name] && this.rendering.placeholders[this.name].length;
        },
    },
};
</script>

<style scoped></style>
