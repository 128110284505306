<template>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.5713 17.7614L8.24513 12.5761C7.91829 12.2579 7.91829 11.7421 8.24513 11.4239L13.5713 6.23864C13.8981 5.92045 14.428 5.92045 14.7549 6.23864C15.0817 6.55684 15.0817 7.07272 14.7549 7.39091L10.0193 12L14.7549 16.6091C15.0817 16.9273 15.0817 17.4432 14.7549 17.7614C14.428 18.0795 13.8981 18.0795 13.5713 17.7614Z"
            fill="currentColor"
        />
    </svg>
</template>

<script>
export default {
    name: 'IconChevronFundDetails',
};
</script>
