<template>
    <component :is="tag" class="skeleton-loader animation--wave" :style="{ height: height, width: width }">
        <span class="skeleton">‌</span>
    </component>
</template>

<script>
export default {
    name: 'BaseSkeleton',
    props: {
        tag: {
            type: String,
            default: 'span',
        },
        height: {
            type: [String, undefined],
            default: undefined,
        },
        width: {
            type: [String, undefined],
            default: undefined,
        },
    },
};
</script>

<style scoped lang="scss">
.skeleton {
    display: inline-block;
    line-height: inherit;
    width: 100%;
    height: inherit;
    background: var(--button-mist);
    &:hover {
        cursor: wait;
    }
}
.animation--wave .skeleton {
    background-size: 200px 100%;
    background-repeat: no-repeat;
    animation: SkeletonOpacityLoading 1.5s ease-in-out infinite alternate;
    background-image: linear-gradient(90deg, rgb(217, 223, 227), rgb(227, 233, 237), rgb(217, 223, 227));
}
@keyframes SkeletonOpacityLoading {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0.3;
    }
}

//@keyframes SkeletonLoadingShimmer {
//    0% {
//        background-position: -200px 0;
//    }
//    100% {
//        background-position: calc(200px + 100%) 0;
//    }
//}
</style>
