<template>
    <GlobalNavigationDropdown
        v-if="siteSelectorProperty"
        :id="anchorId"
        ref="navigationDropdown"
        class="d-none d-xl-flex"
    >
        <template #trigger-area>
            <div ref="dropdownTrigger" class="background-white h-100 px-2 d-flex align-items-center body-small">
                <p>{{ $t('siteSelectorTitle') }}</p>
                <IconChevron class="chevron-icon ms-1" />
            </div>
        </template>
        <div class="site-selector__dropdown background-black pt-3">
            <div class="site-selector__dropdown__scrollable pt-5 pb-4">
                <button
                    type="button"
                    :aria-label="$t('close')"
                    class="btn btn-naked site-selector__dropdown__closer d-flex"
                    @click="$refs.dropdownTrigger.click()"
                    @keyup.enter="closeDropdown"
                >
                    <IconX />
                </button>
                <div
                    v-for="(item, idx) in siteSelectorItems"
                    :key="idx"
                    class="site-selector__item"
                    :class="{ 'mt-3': idx !== 0 }"
                >
                    <div class="site-selector__item__heading d-flex align-items-center px-3">
                        <WrappedImage
                            :media="item.icon"
                            class="background-to-change d-inline-flex me-2"
                            :srcSet="[{ mw: 50 }, { mw: 100 }]"
                            sizes="32px"
                        />
                        <ScText tag="h4" :field="item.heading" />
                    </div>
                    <ScText
                        class="site-selector__item__description px-3 mt-3 body-small"
                        tag="p"
                        :field="item.description"
                    />
                    <ul v-if="item.links.length" class="site-selector__item__links mt-2">
                        <li v-for="cta in item.links" :key="cta.link.id">
                            <ScLink
                                :field="cta.link"
                                :class="{ active: cta.isSelected.value }"
                                :replaceWithSlot="false"
                                class="site-selector__item__link meganav-link meganav-link--small d-flex align-items-center px-3"
                                :showExternalIcon="false"
                                @click.native="$refs.dropdownTrigger.click()"
                            >
                                <IconChevron class="link-chevron ms-auto" />
                            </ScLink>
                        </li>
                    </ul>
                </div>
                <ScLink
                    :field="siteSelectorProperty.changeCountryCta"
                    class="site-selector__change-country site-selector__item__link meganav-link meganav-link--small d-flex align-items-center px-3 mt-3"
                    :showExternalIcon="false"
                    @click.native="changeSiteHandler()"
                >
                    <WrappedImage
                        :media="siteSelectorProperty.changeCountryIcon"
                        class="background-to-change link-icon d-inline-flex me-2"
                        :srcSet="[{ mw: 50 }, { mw: 100 }]"
                        sizes="28px"
                    />
                    <span>{{ $t('changeCountrySite') }}</span>
                    <IconChevron class="link-chevron ms-auto" />
                </ScLink>
            </div>
        </div>
    </GlobalNavigationDropdown>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { mapMutations } from 'vuex';

import GlobalNavigationDropdown from '@/components/Generic/Header/GlobalNavigation/GlobalNavigationDropdown.vue';
import ScLink from '@/components/Generic/SitecoreWrappers/ScLink.vue';
import WrappedImage from '@/components/Generic/SitecoreWrappers/WrappedImage.vue';
import IconChevron from '@/components/Icons/icon-chevron.vue';
import IconX from '@/components/Icons/icon-x.vue';

export default {
    name: 'SiteSelector',
    components: {
        GlobalNavigationDropdown,
        IconX,
        WrappedImage,
        ScText: Text,
        IconChevron,
        ScLink,
    },
    computed: {
        siteSelectorProperty() {
            return this.$jss.sitecoreContext().siteSelector;
        },
        siteSelectorItems() {
            return this.siteSelectorProperty.vectors;
        },
    },
    methods: {
        ...mapMutations('global', ['setPreOpenGlobalLangSelector']),
        changeSiteHandler() {
            this.$refs.dropdownTrigger.click();
            this.setPreOpenGlobalLangSelector(true);
        },
        closeDropdown() {
            const dropdownButton = this.$refs['navigationDropdown'].$el.childNodes[1];
            dropdownButton.focus();
            this.$refs['navigationDropdown'].overlay = false;
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixins';
.site-selector__dropdown {
    color: var(--abrdn-white);
    width: 375px;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
}
.site-selector__dropdown__scrollable {
    overflow: auto;
    @include formScrollbar;
    flex-grow: 1;
}
.site-selector__dropdown__closer {
    position: absolute;
    right: calc(var(--spacer) * 2);
    top: calc(var(--spacer) * 2);
    svg {
        width: 24px;
        height: 24px;
        padding: 5px;
    }
}
.site-selector__item__heading img,
.site-selector__item__heading ::v-deep img {
    width: 32px;
    max-height: 32px;
}
.site-selector__item__links {
    padding: 0;
    margin: 0;
    list-style: none;
}
.site-selector__item__link {
    padding-top: 12px;
    padding-bottom: 12px;
    transition: background-color 0.2s ease-in-out;
    &.active,
    &:hover,
    &:focus {
        background-color: var(--digital-ash-grey);
    }
}
.link-chevron {
    height: 0.75em;
    width: 0.75em;
    transform: rotate(-90deg);
}
.link-icon {
    width: 28px;
    height: 28px;
}
</style>
