<template>
    <div :class="cols">
        <div class="customer-reviews p-5 w-100" :class="backgroundClass">
            <div v-if="rating" class="customer-reviews__rating background-to-change d-flex align-items-center mb-5">
                <Rating :rating="rating" class="flex-shrink-0" />
                <span class="ms-2">{{ ratingDescription }}</span>
            </div>
            <ScText tag="h4" :field="fields.title" class="customer-reviews__title background-to-change" />
            <ScText tag="blockquote" :field="fields.description" class="mt-5 text-grey background-to-change" />
            <ScText tag="p" :field="fields.authorName" class="section-header-large background-to-change mt-3" />
        </div>
    </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import Rating from '@/components/Includes/Rating';
import { backgroundClass } from '@/mixins/Helpers.mixin';

export default {
    name: 'CustomerReviewTile',
    components: { Rating, ScText: Text },
    mixins: [backgroundClass],
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        gridData: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        rating() {
            return Number(this.fields.stars?.value);
        },
        ratingDescription() {
            return this.fields.starsDescription.value?.replace('{token.stars}', this.rating);
        },
        cols() {
            const numOfCols = Number(this.rendering.params.size) || 12;
            let mdColumns = 12;
            if (this.gridData.columns.includes(4) || this.gridData.columns.includes(8)) {
                mdColumns = 6;
            }
            const isLast = this.componentIndex === this.gridData.count;
            const isOdd = this.gridData.count % 2;
            //isOdd and last
            if (isOdd && isLast) {
                mdColumns = 12;
            }

            return `col-12 col-md-${mdColumns} col-xl-${numOfCols}`;
        },
        componentIndex() {
            return this.$parent.$children.findIndex((child) => child === this) + 1;
        },
    },
};
</script>

<style scoped lang="scss">
.customer-reviews__rating span {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.03em;
}

blockquote {
    font-style: italic;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.02em;
}
</style>
