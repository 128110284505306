<template>
    <td class="field-text">
        <span class="d-xl-none section-header-small">
            {{ $t('kidShareClass') }}
            <Tooltip
                v-if="
                    $t('fundsFundCentreTooltipShareclass') &&
                    $t('fundsFundCentreTooltipShareclass') !== 'fundsFundCentreTooltipShareclass'
                "
                :arialLabelText="$t('kidShareClass')"
            >
                <template #tooltipTitle>
                    <p class="tooltip-title">
                        {{ $t('kidShareClass') }}
                    </p>
                </template>
                <template #tooltipContent>
                    <p class="tooltip-description">{{ $t('fundsFundCentreTooltipShareclass') }}</p>
                </template>
            </Tooltip>
        </span>
        <AbrdnSelect
            v-if="!singleName"
            class="w-100 shareclass-selector"
            :fields="buildShareclassSelectorField(fund)"
            @updateValue="shareclassSelected($event)"
        />
        <p v-else>{{ singleName }}</p>
    </td>
</template>

<script>
import { mapMutations } from 'vuex';

import AbrdnSelect from '@/components/Generic/FormElements/FormFields/AbrdnSelect.vue';
import Tooltip from '@/components/Generic/FormElements/FormFields/Tooltip';

export default {
    name: 'FundsShareclassCell',
    components: {
        AbrdnSelect,
        Tooltip,
    },
    props: {
        fund: {
            type: Object,
            required: true,
        },
        fundIdx: {
            type: Number,
            required: true,
        },
    },
    computed: {
        isMoreThanOneOptions() {
            return this.fund.shareclasses.length && this.fund.shareclasses.length >= 2;
        },
        singleName() {
            if (!this.isMoreThanOneOptions) {
                return this.fund.shareclasses[0]?.shareclassName || this.$t('notAvailableShortMessage');
            }
            return false;
        },
    },
    methods: {
        ...mapMutations('allFunds', ['updateSelectedShareclass']),
        buildShareclassSelectorField(fund) {
            return {
                placeholder: { value: this.$t('noResultsMessage') },
                disabled: { value: !fund.shareclasses.length },
                options: {
                    value: fund.shareclasses.map((shareclass) => {
                        return {
                            name: shareclass.shareclassID,
                            displayName: shareclass.shareclassName,
                            value: shareclass.shareclassID,
                            selected: shareclass.shareclassID === fund.selectedShareclass?.shareclassID,
                        };
                    }),
                },
            };
        },
        shareclassSelected({ value }) {
            this.updateSelectedShareclass({ fundIdx: this.fundIdx, shareclassID: value });
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
.shareclass-selector {
    max-width: 374px;
    @include media-breakpoint-down(md) {
        max-width: 100%;
    }
}
</style>
