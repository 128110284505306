var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"main-footer",class:{ 'default-margin-top': _vm.shoulAddMargin },attrs:{"id":_vm.anchorId,"data-e2e-component-uid":_vm.rendering.uid}},[(_vm.riskWarningsFields)?_c('RiskWarning',{attrs:{"fields":_vm.riskWarningsFields}}):_vm._e(),_c('footer',{ref:"footer",staticClass:"flex background-black footer-root py-5 py-md-6 py-xl-8",attrs:{"role":"contentinfo"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"logo-wrapper mb-5 mb-md-6 mb-xl-8"},[_c('ScLink',{attrs:{"backgroundToChange":false,"field":_vm.fields.logoLink,"showExternalIcon":false}},[(_vm.fields.logo.value)?_c('WrappedImage',{attrs:{"media":_vm.fields.logo,"srcSet":[{ mw: 200 }, { mw: 300 }, { mw: 400 }],"sizes":"170px"}}):_vm._e()],1)],1),(
                    (_vm.fields.items && _vm.fields.items) ||
                    (_vm.rendering.placeholders['abrdn-social-network'] &&
                        _vm.rendering.placeholders['abrdn-social-network'].length) ||
                    (_vm.fields.expandableBlockTitle && _vm.fields.expandableBlockTitle.value) ||
                    (_vm.fields.copywriteText && _vm.fields.copywriteText.value) ||
                    _vm.isExperienceEditor
                )?_c('div',{staticClass:"policy-section pt-4"},[_c('div',{staticClass:"d-flex flex-column flex-md-row mb-3 mb-lg-2 mb-md-3 row"},[(_vm.fields.items || _vm.isExperienceEditor)?_c('div',{staticClass:"col policies d-flex col-12 col-lg-9 flex-wrap flex-lg-wrap"},_vm._l((_vm.fields.items),function(policy){return _c('div',{key:policy.id,staticClass:"mb-sm-2 mb-2 mb-md-1 me-lg-4 me-sm-3 align-items-center"},[_c('ScLink',{class:'footer-link-small',attrs:{"field":policy.fields.link}})],1)}),0):_vm._e(),(
                            (_vm.rendering.placeholders['abrdn-social-network'] &&
                                _vm.rendering.placeholders['abrdn-social-network'].length) ||
                            _vm.isExperienceEditor
                        )?_c('div',{staticClass:"col col-12 col-lg-3 d-flex justify-content-start justify-content-lg-end mt-1 mt-sm-1 mt-md-2 mt-lg-0"},[_c('Placeholder',{attrs:{"rendering":_vm.rendering,"name":"abrdn-social-network"}})],1):_vm._e()]),(
                        (_vm.fields.expandableBlockTitle && _vm.fields.expandableBlockTitle.value) ||
                        (_vm.fields.copywriteText && _vm.fields.copywriteText.value) ||
                        (_vm.fields.badge && _vm.fields.badge.value) ||
                        _vm.isExperienceEditor
                    )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-12 d-flex flex-column flex-md-row align-items-start align-items-md-end mb-3"},[_c('span',{staticClass:"d-flex flex-column"},[_c('ScText',{staticClass:"footnote",attrs:{"field":_vm.copyrightString}}),_c('ScText',{staticClass:"footnote",attrs:{"field":_vm.fields.registeredText}})],1),_c('WrappedImage',{staticClass:"badge-wrapper mt-3 mt-md-0 ms-0 ms-md-3",attrs:{"media":_vm.fields.badge,"srcSet":[{ mw: 50 }, { mw: 100 }],"sizes":"48px"}})],1),(
                            (_vm.fields.expandableBlockTitle && _vm.fields.expandableBlockTitle.value) || _vm.isExperienceEditor
                        )?_c('div',{staticClass:"col col-12 col-md-8 d-flex"},[_c('button',{directives:[{name:"collapse-target",rawName:"v-collapse-target",value:('#copywrite-dropdown'),expression:"'#copywrite-dropdown'"}],staticClass:"btn text-link copywrite-button d-flex align-items-end p-0",class:{ collapsed: !_vm.isDropdownExpandedByDefault },attrs:{"aria-expanded":_vm.isDropdownExpandedByDefault ? 'true' : 'false',"type":"button","variant":"button"},on:{"click":function($event){((_vm.fields.expandableBlockText && _vm.fields.expandableBlockText.value) ||
                                    _vm.isExperienceEditor) &&
                                    _vm.showCopywriteText()}}},[_c('div',{staticClass:"d-flex align-items-center expandable"},[_c('ScText',{staticClass:"expandable-title mb-0 font-size-6",attrs:{"field":_vm.fields.expandableBlockTitle}}),(
                                        (_vm.fields.expandableBlockText && _vm.fields.expandableBlockText.value) ||
                                        _vm.isExperienceEditor
                                    )?_c('em',{staticClass:"d-flex bi-chevron-down ms-1"}):_vm._e()],1)])]):_vm._e()]):_vm._e()]):_vm._e(),_c('div',{staticClass:"collapse",class:_vm.isDropdownExpandedByDefault ? 'show' : 'collapsed',attrs:{"id":"copywrite-dropdown"}},[_c('div',{staticClass:"copywrite-dropdown"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('ScRichText',{staticClass:"body-small",attrs:{"field":_vm.fields.expandableBlockText}})],1)])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }