<template>
    <section
        :id="anchorId"
        :data-e2e-component-uid="rendering.uid"
        class="container-fluid default-section-padding"
        :class="[background]"
    >
        <form
            v-if="showForm"
            class="container relative background-to-change"
            :name="getFormName"
            @submit="postFormData($event)"
        >
            <div class="row">
                <template v-for="(row, i) in layout.rows">
                    <div
                        v-if="isHeader(row)"
                        :key="i"
                        class="form-field-header d-flex flex-column flex-md-row align-items-md-center"
                    >
                        <i v-if="icon" class="bi bi-star me-2" aria-hidden="true" />
                        <span class="h3">
                            {{ row.cols[0].cells[0].element.name }}
                        </span>
                    </div>
                    <p v-else-if="isDescription(row)" :key="i" class="form-field-list body-default text-grey">
                        {{ row.cols[0].cells[0].element.name }}
                    </p>
                    <template v-for="col in row.cols" v-else>
                        <div
                            v-if="col.cells[0]"
                            :key="col.cells[0].element.createdFromContactFieldId"
                            class="mb-3"
                            :class="[getColClass(col.width), { hidden: subscriptionSuccess }]"
                        >
                            <FormsSharedFields
                                :element="col.cells[0].element"
                                :formName="getFormName"
                                formType="eloqua"
                                @change="setValue($event)"
                                @updateDataLayer="pushDataLayerEvent($event)"
                                @executeFormStartEvent="executeFormStartEvent($event)"
                                @applyOptions="applyOptions($event)"
                                @appendValue="appendValue($event)"
                                @deleteMultipleOptions="deleteMultipleOptions"
                                @deleteSingleOption="deleteSingleOption"
                            />
                        </div>
                    </template>
                </template>
                <template v-for="(col, i) in submitRow">
                    <div :key="'A' + i" :class="[getColClass(col.width), { hidden: subscriptionSuccess }]">
                        <AbrdnButton :id="col.cells[0].element.id" buttonType="primary">
                            <span>{{ getButtonText(col) }}</span>
                        </AbrdnButton>
                    </div>
                </template>
            </div>
            <div
                v-if="subscriptionSuccess"
                class="container message-content d-flex flex-column justify-content-center align-items-start"
                :class="[background]"
            >
                <div class="d-flex">
                    <em class="bi bi-check-lg h3 me-2" aria-hidden="true" />
                    <p class="h3">{{ $t('formSuccessTitle') }}</p>
                </div>
                <span class="body-large success-description mt-6">{{ $t('formSuccessDescription') }}</span>
            </div>
        </form>
    </section>
</template>

<script>
import axios from 'axios';

import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton';
import Cookie from '@/mixins/Cookie.mixin';
import DataLayerHandler from '@/mixins/DataLayer.mixin';
import FormsSharedLogic from '@/mixins/FormsSharedLogic.mixin';

import FormsSharedFields from './Forms/FormsSharedFields';

export default {
    name: 'EloquaForm',

    components: {
        AbrdnButton,
        FormsSharedFields,
    },

    mixins: [FormsSharedLogic, Cookie, DataLayerHandler],

    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: () => ({}),
        },
        background: {
            type: String,
            default: 'background-mist',
        },
        icon: {
            type: Boolean,
            default: false,
        },
    },

    mounted() {
        if (!this.showForm) {
            console.log('missing data');
        } else {
            this.setupFormData();
        }
    },

    methods: {
        setValue({ htmlName, value }) {
            this.formData.set(htmlName, value);
        },
        appendValue({ fieldName, value }) {
            this.formData.append(fieldName, value);
        },
        executeFormStartEvent(payload) {
            if (!this.formStartEventExistInDataLayer) {
                this.formStartEventExistInDataLayer = true;
                this.pushDataLayerEvent(payload);
            }
        },
        postFormData(event) {
            event.preventDefault();
            const eloquaGuid = this.getCookie('ELOQUA')?.split('=')[1]?.split('&')[0] || '';
            this.appendValue({ fieldName: 'elqCustomerGUID', value: eloquaGuid });
            this.appendValue({ fieldName: 'elqCookieWrite', value: 0 });

            axios.post(this.fields.formJson.value.postUrl, this.formData).then((res) => {
                if (res.status === 200) {
                    this.subscriptionSuccess = true;
                    this.$nextTick(() => {
                        this.$emit('submitSuccess');
                        this.pushDataLayerEvent({
                            event: 'form_submit',
                            formName: this.getFormName,
                            form_field: 'n/a',
                        });
                    });
                    this.setupFormData();
                }
            });
        },
        setupFormData() {
            this.formData = new FormData();
            this.appendValue({ fieldName: 'elqFormName', value: this.fields.formJson.value.htmlName });
            this.appendValue({ fieldName: 'elqSiteId', value: this.fields.formJson.value.siteId });
            this.includeHiddenFields();
        },
        includeHiddenFields() {
            this.formField.map((field) => {
                field.cols.map((col) => {
                    if (!!col.cells[0]) {
                        if (col.cells[0]?.element?.displayType === 'hidden') {
                            switch (col.cells[0]?.element?.htmlName) {
                                case 'Date_submitted':
                                    this.formData.set(
                                        col.cells[0]?.element?.htmlName,
                                        new Date(Date.now()).toDateString(),
                                    );
                                    break;
                                case 'Module_name':
                                    this.formData.set(col.cells[0]?.element?.htmlName, this.$route.fullPath);
                                    break;
                                default:
                                    this.formData.set(
                                        col.cells[0]?.element?.htmlName,
                                        col.cells[0]?.element?.defaultValue,
                                    );
                                    break;
                            }
                        }
                    }
                });
            });
        },
        applyOptions(optionsList) {
            optionsList.forEach(({ name, selected }) => {
                this.formData.set(name, selected);
            });
        },
        deleteMultipleOptions() {
            const multiSelect = this.formField.filter((field) => {
                return field.cols[0].cells[0].element.displayType === 'multiSelect';
            });
            const multiSelectOptions = [...multiSelect[0].cols[0].cells[0].element.optionList.elements];
            multiSelectOptions.forEach((option) => {
                if (this.formData.get(option.value)) {
                    this.formData.delete(option.value);
                }
            });
        },
        deleteSingleOption(option) {
            this.formData.delete(option.name);
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';

.form-field-header {
    i {
        font-size: 36px;
        @include media-breakpoint-down(md) {
            font-size: 40px;
        }
    }
}
.hidden {
    z-index: -1;
}
</style>
