<template>
    <section :id="anchorId" class="w-100 pt-5 pt-md-8 background-white">
        <div class="container section-selector pb-3">
            <ScText v-if="fields.title" tag="h2" class="col-12 col-lg-8" :field="fields.title" />
            <ScText
                v-if="fields.description"
                tag="p"
                class="body-default mt-5 col-12 col-lg-8"
                :field="fields.description"
            />
            <div class="d-flex align-items-center mt-3 mb-4">
                <ScText v-if="fields.subtitle" tag="h4" class="col col-lg-8" :field="fields.subtitle" />
                <SliderNavCircle
                    ref="navPrev"
                    aria-label="Previous slide"
                    class="ms-auto nav-prev d-xl-none"
                    :direction="'left'"
                />
                <SliderNavCircle
                    ref="navNext"
                    aria-label="Next slide"
                    class="ms-3 nav-next d-xl-none"
                    :direction="'right'"
                />
            </div>
            <Slider
                v-if="fields.items.length"
                :sliderOptions="sliderOptions"
                class="tabs-wrapper"
                @swiperInited="swiperInited"
            >
                <template #slides>
                    <SliderSlide v-for="(item, index) in fields.items" :key="index">
                        <div class="tabs" :class="{ active: activeTab === index }" @click="toggleTab(index)">
                            <WrappedImage
                                v-if="item.fields.pictogram"
                                :media="item.fields.pictogram"
                                class="image mb-2"
                                :srcSet="[{ mw: 100 }, { mw: 200 }]"
                                sizes="64px"
                            />
                            <ScText :field="item.fields.title" tag="p" class="subheader-2" />
                        </div>
                    </SliderSlide>
                </template>
            </Slider>
        </div>
        <div class="placeholders">
            <div v-for="(item, index) in fields.items" v-show="fields.items.length" :key="item.id" class="placeholder">
                <Placeholder
                    v-if="activeTab === index && !isExperienceEditor"
                    :rendering="rendering"
                    :name="`abrdn-section-selector-tab-${index}`"
                />
                <template v-if="isExperienceEditor">
                    <Placeholder
                        v-show="activeTab === index && isExperienceEditor"
                        :rendering="rendering"
                        :name="`abrdn-section-selector-tab-${index}`"
                    />
                </template>
            </div>
        </div>
    </section>
</template>

<script>
import { Placeholder, Text } from '@sitecore-jss/sitecore-jss-vue';
import { Keyboard, Navigation } from 'swiper';

import Slider from '@/components/Functional/Slider/Slider.vue';
import SliderNavCircle from '@/components/Functional/Slider/SliderNavCircle.vue';
import SliderSlide from '@/components/Functional/Slider/SliderSlide.vue';
import WrappedImage from '@/components/Generic/SitecoreWrappers/WrappedImage.vue';

export default {
    name: 'SectionSelector',
    components: { Placeholder, ScText: Text, WrappedImage, SliderNavCircle, SliderSlide, Slider },
    props: {
        placeholders: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    data() {
        return {
            activeTab: 0,
            sliderOptions: null,
            swiperInstance: null,
        };
    },
    mounted() {
        if (this.fields.items && this.fields.items.length) {
            this.sliderOptions = {
                modules: [Navigation, Keyboard],
                slidesPerView: 'auto',
                spaceBetween: 15,
                navigation: {
                    nextEl: this.$refs.navNext.$el,
                    prevEl: this.$refs.navPrev.$el,
                },
                keyboard: {
                    enabled: true,
                },
                breakpoints: {
                    768: {
                        spaceBetween: 30,
                    },
                },
            };
        }
    },
    methods: {
        toggleTab(tabIndex) {
            if (tabIndex !== this.activeTab) {
                this.activeTab = tabIndex;
                this.swiperInstance.slideTo(tabIndex);
            } else {
                this.activeTab = -1;
            }
        },
        swiperInited(instance) {
            this.swiperInstance = instance;
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables';
@import '@/assets/styles/mixins';

.section-selector {
    /deep/ .placeholder {
        margin-top: 22px;
    }
    .swiper-slide {
        width: auto !important;
        height: auto;
    }
    .tabs-wrapper {
        .tabs {
            position: relative;
            cursor: pointer;
            width: 270px;
            border-radius: 15px;
            margin-bottom: 20px;
            border: 2px solid var(--digital-light-grey);
            padding: 22px 20px;
            height: calc(100% - 20px);
            &:not(:first-of-type) {
                margin-left: 30px;
            }
            .image {
                width: 64px;
                height: 64px;
                /deep/ svg,
                /deep/ img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            &.active {
                background: var(--primary);
                border: 2px solid var(--primary);
                .subheader-2 {
                    color: var(--abrdn-white);
                }
                &:before {
                    content: '';
                    width: 29px;
                    height: 29px;
                    background: var(--primary);
                    position: absolute;
                    bottom: -13px;
                    left: 50%;
                    transform: translateX(-50%) rotate(45deg) skew(6deg, 6deg);
                    border-radius: 7px 0;
                }
                .image {
                    @include applyColorFilter('white');
                }
            }
        }
    }
}
.placeholders {
    ::v-deep .text-blocks {
        padding-top: 8px;
        padding-bottom: 0;
    }
}
</style>
