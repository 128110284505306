const state = () => ({
    amountOfRiskWarningElements: 0,
});

const mutations = {
    setAmount: (state, amount) => {
        state.amountOfRiskWarningElements = amount;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
};
