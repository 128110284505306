<template>
    <section :id="anchorId" :data-e2e-component-uid="rendering.uid" class="secondary-hero-banner">
        <Animation
            v-if="isAnimationVisible"
            :loop="false"
            class="background-video-wrapper"
            :animationData="desktopAnimation"
            :scaleSettings="scaleSettings"
        />
        <div v-else class="background-image-wrapper">
            <WrappedImage
                :media="fields.backgroundImage"
                :srcSet="[
                    { mw: 400 },
                    { mw: 600 },
                    { mw: 800 },
                    { mw: 1000 },
                    { mw: 1200 },
                    { mw: 1400 },
                    { mw: 1600 },
                    { mw: 1800 },
                    { mw: 2000 },
                ]"
                sizes="100vw"
                :lazy="false"
            />
        </div>
        <div class="container px-4 text-block pb-1 pb-md-2 pb-lg-3 pb-xl-4">
            <div class="row">
                <div class="inner-wrapper background-white px-2 px-sm-4 px-xl-5 pt-4 pt-sm-4 pt-xl-5 pb-4 pb-lg-5">
                    <ScText :field="fields.title" class="col-lg-10 col-sm-11 col-12" tag="h1" />
                    <ScText :field="fields.subtitle" class="mt-2 body-large col-md-11 col-lg-12" tag="p" />
                    <AbrdnButton
                        v-if="isValueExists(fields.primaryCtaLink)"
                        :linkField="fields.primaryCtaLink"
                        :buttonType="buttonType"
                        class="mt-2 mt-md-3"
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import Animation from '@/components/Generic/Animation';
import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton.vue';
import WrappedImage from '@/components/Generic/SitecoreWrappers/WrappedImage.vue';
import deviceType from '@/mixins/DeviceType.mixin';

export default {
    name: 'SecondaryHeroBanner',
    components: {
        AbrdnButton,
        WrappedImage,
        ScText: Text,
        Animation,
    },
    mixins: [deviceType],
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        params: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        desktopAnimation() {
            return this.fields.backgroundAnimation?.value;
        },
        scaleSettings() {
            return {
                preserveAspectRatio: 'xMaxYMax slice',
            };
        },
        isAnimationVisible() {
            return this.desktopAnimation && this.deviceType === 'desktop';
        },
        buttonType() {
            const { linkType } = this.rendering.params;
            const buttonTypesMap = {
                Primary: 'primary',
                Secondary: 'secondary',
                Tertiary: 'textLink',
            };
            return buttonTypesMap[linkType] || 'textLink';
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';

section {
    position: relative;
}

.background-image-wrapper,
.background-video-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 384px;
    ::v-deep img {
        object-fit: cover;
        object-position: right;
        width: 100%;
        height: 100%;
        @include media-breakpoint-down(xl) {
            height: 304px;
        }
        @include media-breakpoint-down(sm) {
            height: 288px;
        }
    }
}

.text-block {
    position: relative;

    .inner-wrapper {
        position: relative;
        z-index: 1;
        margin-top: 272px;
        @include media-breakpoint-down(xl) {
            margin-top: 205px;
        }

        @include media-breakpoint-down(lg) {
            margin-top: 232px;
        }

        @include media-breakpoint-down(sm) {
            margin-top: 224px;
        }
    }
}
</style>
