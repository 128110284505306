<template>
    <section class="date-picker">
        <div class="d-flex flex-column mb-3 form-element" :class="{ ['form-element--error']: dayFieldError.error }">
            <label class="form-element__label form-label mb-2" :for="dayInputFields.id">{{
                getLabelValue('dayInputFields')
            }}</label>
            <span class="input-box">
                <input
                    :id="dayInputFields.id"
                    min="1"
                    max="31"
                    type="number"
                    :required="dayInputFields.required"
                    class="field-text"
                    @invalid="checkError($event, 'dayInputFields', 'dayFieldError')"
                    @blur="checkError($event, 'dayInputFields', 'dayFieldError')"
                    @input="setValue($event, dayInputFields.id, dayInputFields.label)"
                />
                <span v-if="dayFieldError.error" class="input-icon input-icon--right input-icon--error">
                    <IconWarning />
                </span>
            </span>
            <span v-if="dayFieldError.error" class="form-element__error field-error-text text-error-400">
                {{ dayFieldError.message }}
            </span>
        </div>
        <div class="d-flex flex-column mb-3 form-element" :class="{ ['form-element--error']: monthFieldError.error }">
            <label class="form-element__label form-label mb-2" :for="monthInputFields.id">{{
                getLabelValue('monthInputFields')
            }}</label>
            <span class="input-box">
                <input
                    :id="monthInputFields.id"
                    min="1"
                    max="12"
                    type="number"
                    :required="monthInputFields.required"
                    class="field-text"
                    @invalid="checkError($event, 'monthInputFields', 'monthFieldError')"
                    @blur="checkError($event, 'monthInputFields', 'monthFieldError')"
                    @input="setValue($event, monthInputFields.id, monthInputFields.label)"
                />
                <span v-if="monthFieldError.error" class="input-icon input-icon--right input-icon--error">
                    <IconWarning />
                </span>
            </span>
            <span v-if="monthFieldError.error" class="form-element__error field-error-text text-error-400">
                {{ monthFieldError.message }}
            </span>
        </div>
        <div class="d-flex flex-column mb-3 form-element" :class="{ ['form-element--error']: yearFieldError.error }">
            <label class="form-element__label form-label mb-2" :for="yearInputFields.id">{{
                getLabelValue('yearInputFields')
            }}</label>
            <span class="input-box">
                <input
                    :id="yearInputFields.id"
                    min="1900"
                    :max="currentYear"
                    type="number"
                    :required="yearInputFields.required"
                    class="field-text"
                    @invalid="checkError($event, 'yearInputFields', 'yearFieldError')"
                    @blur="checkError($event, 'yearInputFields', 'yearFieldError')"
                    @input="setValue($event, yearInputFields.id, yearInputFields.label)"
                />
                <span v-if="yearFieldError.error" class="input-icon input-icon--right input-icon--error">
                    <IconWarning />
                </span>
            </span>
            <span v-if="yearFieldError.error" class="form-element__error field-error-text text-error-400">
                {{ yearFieldError.message }}
            </span>
        </div>
    </section>
</template>

<script>
import IconWarning from '@/components/Icons/icon-warning.vue';
import FormFieldsGetters from '@/mixins/FormFieldsGetters.mixin';

export default {
    name: 'DatePicker',
    components: { IconWarning },
    mixins: [FormFieldsGetters],
    props: {
        fields: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            dayFieldError: {
                error: false,
                message: '',
            },
            monthFieldError: {
                error: false,
                message: '',
            },
            yearFieldError: {
                error: false,
                message: '',
            },
            date: {
                day: '',
                month: '',
                year: '',
            },
        };
    },
    computed: {
        dayInputFields() {
            return {
                name: this.fields.day.name,
                label: this.fields.day.label,
                required: this.fields.day.required,
                type: this.fields.day.type,
                id: this.fields.day.id,
            };
        },
        monthInputFields() {
            return {
                name: this.fields.month.name,
                label: this.fields.month.label,
                required: this.fields.month.required,
                type: this.fields.month.type,
                id: this.fields.month.id,
            };
        },
        yearInputFields() {
            return {
                name: this.fields.year.name,
                label: this.fields.year.label,
                required: this.fields.year.required,
                type: this.fields.year.type,
                id: this.fields.year.id,
            };
        },
        requiredErrorMessage() {
            return this.fields?.validations?.find(({ condition }) => condition.type === 'IsRequiredCondition')?.message;
        },
        currentYear() {
            return new Date().getFullYear();
        },
    },
    methods: {
        setValue(event, id, label) {
            switch (label) {
                case 'Day':
                    this.date.day = event.target.value;
                    break;
                case 'Month':
                    this.date.month = event.target.value;
                    break;
                case 'Year':
                    this.date.year = event.target.value;
                    break;
                default:
                    this.date = {};
            }
            this.$emit('updateValue', {
                value: `${this.date.day} ${this.date.month} ${this.date.year}`,
                id,
                name: this.dayInputFields.name,
                relatesTo: this.fields.relatesTo?.value,
            });
        },
        getLabelValue(dateRange) {
            return this[dateRange].required ? this[dateRange].label + '*' : this[dateRange].label;
        },
        checkError(event, dateRange, errorField) {
            switch (true) {
                case event.target.validity.typeMismatch:
                    this[errorField].message = this.fields.errorMessages?.typeMismatch;
                    this[errorField].error = true;
                    break;
                case event.target.validity.valueMissing:
                    this[errorField].message = this.requiredErrorMessage;
                    this[errorField].error = true;
                    break;
                case event.target.validity.rangeUnderflow:
                    this[errorField].message = event.target.validationMessage;
                    this[errorField].error = true;
                    break;
                case event.target.validity.rangeOverflow:
                    this[errorField].message = event.target.validationMessage;
                    this[errorField].error = true;
                    break;
                default:
                    this.validateField(event.target.value, errorField);
                    break;
            }
        },
        validateField(value, errorField) {
            const message = this.getValidationPatterns?.find((validation) => {
                const regex = new RegExp(validation.validation);
                return !regex.test(value);
            })?.title;
            this[errorField].error = !!message;
            this.errorMessage = this.requiredErrorMessage;
        },
    },
};
</script>
<style scoped lang="scss">
@import '@/assets/styles/mixins';
@import '@/assets/styles/components/input-field';

.date-picker {
    display: grid;
    grid-template-columns: 20% 20% 3fr;
    gap: 20px;
    .form-element {
        margin-top: 0;
        &__label {
            margin-bottom: var(--default-form-label-margin);
        }
        &--error {
            margin-bottom: 11px;
        }
        &__error {
            display: block;
            width: 100%;
            line-height: 1;
            margin-top: 12px;
            @include dark-backgrounds() {
                padding: 4px;
                background-color: $abrdn-white;
            }
        }
        &__hint {
            width: 100%;
            color: var(--button-night);
            @include dark-backgrounds() {
                color: var(--abrdn-white);
            }
        }
        &__hints {
            line-height: 1;
            margin-top: 12px;
        }
    }
}
</style>
