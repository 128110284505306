<template>
    <EEPlaceholder v-if="isExperienceEditor">Strategy in focus</EEPlaceholder>
    <section v-else :id="anchorId" :data-e2e-component-uid="rendering.uid">
        <FundsListing
            :itemsPerPage="20"
            :jurisdiction="jurisdiction"
            :filters="[]"
            :investorTypes="investorTypes"
            :countryCode="countryCode"
            :withFilters="false"
            :withPagination="false"
            :site="site"
        />
    </section>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

import FundsListing from '@/components/Pages/FundsCenter/AllFunds/FundsListing/FundsListing';
import EEPlaceholder from '@/components/Sections/EEPlaceholder';

export default {
    name: 'StrategyInFocus',
    components: { FundsListing, EEPlaceholder },
    inject: ['countryCode', 'investorTypes', 'jurisdiction', 'site'],
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        params: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    data() {
        return {
            tableData: [],
        };
    },
    computed: {
        ...mapState('allFunds', ['allFundsCount', 'paginationData', 'selectedTab', 'fundsInFocusActiveTab']),
        footnote() {
            return this.$t('paginationDisplayingResults')
                .replace('{token.range}', this.allFundsCount)
                .replace(' of {token.total}', '');
        },
    },
    mounted() {
        if (this.isExperienceEditor) return;
        this.setFilters([
            {
                name: this.fields.strategyInFocus.value.filterName,
                values: this.fields.strategyInFocus.value.filterValues,
            },
        ]);
    },
    methods: {
        ...mapMutations('allFunds', ['setFilters']),
    },
};
</script>
