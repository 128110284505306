<template>
    <section class="default-section-padding background-white">
        <FundDetailsTabRow fullWidth>
            <h2>{{ $t('fundDetailsTabsBreakdown') }}</h2>
            <FundDetailsBreakdownCreditProfileTable v-if="isTableEnabled('creditBreakdown')" class="mt-5 mt-lg-8" />
            <FundDetailsBreakdownMaturityProfileTable v-if="isTableEnabled('maturityBreakdown')" class="mt-5 mt-lg-8" />
            <FundDetailsBreakdownSectorTable v-if="isTableEnabled('sectorBreakdown')" class="mt-5 mt-lg-8" />
            <FundDetailsBreakdownCountryTable v-if="isTableEnabled('countryBreakdown')" class="mt-5 mt-lg-8" />
        </FundDetailsTabRow>
    </section>
</template>

<script>
import { mapState } from 'vuex';

import FundDetailsTabRow from '../../FundDetailsTabRow';
import FundDetailsBreakdownCountryTable from './FundDetailsBreakdownCountryTable';
import FundDetailsBreakdownCreditProfileTable from './FundDetailsBreakdownCreditProfileTable';
import FundDetailsBreakdownMaturityProfileTable from './FundDetailsBreakdownMaturityProfileTable';
import FundDetailsBreakdownSectorTable from './FundDetailsBreakdownSectorTable';

export default {
    name: 'FundDetailsBreakdownTab',
    components: {
        FundDetailsBreakdownCountryTable,
        FundDetailsBreakdownCreditProfileTable,
        FundDetailsBreakdownMaturityProfileTable,
        FundDetailsBreakdownSectorTable,
        FundDetailsTabRow,
    },
    computed: {
        ...mapState('fundDetails', ['availableTabs']),
    },
    methods: {
        isTableEnabled(tableKey) {
            return !!this.availableTabs?.breakdownTab?.[tableKey];
        },
    },
};
</script>
