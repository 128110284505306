<template>
    <div :data-e2e-component-uid="renderingId" class="d-flex align-items-center" :class="{ 'page-loading': isLoading }">
        <template v-if="!showSkeleton && currentPage !== null && maxPages !== null">
            <em
                class="bi-chevron-left me-2 tile"
                tabindex="0"
                :class="{ disabled: !(currentPage > 1) }"
                @click="currentPage > 1 && $emit('onSelect', currentPage - 1)"
            />
            <div v-if="maxPages > 5 && currentPage >= maxPages - 2 && currentPage <= maxPages" class="me-2 dots">
                ...
            </div>
            <template v-for="i in maxPages > 5 ? 3 : maxPages">
                <div
                    :key="i + 'pages'"
                    class="me-2 tile"
                    tabindex="0"
                    :class="{ selected: getPage(i) === currentPage }"
                    @click="$emit('onSelect', getPage(i))"
                >
                    {{ getPage(i) }}
                </div>
            </template>
            <div v-if="maxPages > 5 && currentPage < maxPages - 2" class="me-2 dots">...</div>
            <div
                v-if="maxPages > 5"
                tabindex="0"
                class="me-2 tile"
                :class="{ selected: currentPage == maxPages }"
                @click="$emit('onSelect', maxPages)"
            >
                {{ maxPages }}
            </div>
            <em
                class="bi-chevron-right tile"
                tabindex="0"
                :class="{ disabled: !(currentPage < maxPages) }"
                @click="currentPage < maxPages && $emit('onSelect', currentPage + 1)"
            />
        </template>
        <BaseSkeleton v-if="showSkeleton" width="320px" height="32px" />
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

import BaseSkeleton from '@/components/Generic/BaseSkeleton.vue';

export default {
    name: 'Pagination',
    components: { BaseSkeleton },
    props: {
        currentPage: {
            type: Number,
            default: null,
        },
        maxPages: {
            type: Number,
            default: null,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        showSkeleton: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            renderingId: undefined,
        };
    },
    mounted() {
        this.renderingId = uuidv4();
    },
    methods: {
        getPage(page) {
            const { maxPages, currentPage } = this;
            if (maxPages <= 5 && page <= maxPages) {
                return page;
            }
            if (currentPage == 1) {
                return page;
            }
            if (page == 1) {
                return currentPage > maxPages - 2 ? maxPages - 3 : currentPage - 1;
            } else if (page == 2) {
                return currentPage > maxPages - 2 ? maxPages - 2 : currentPage;
            } else {
                return currentPage > maxPages - 2 ? maxPages - 1 : currentPage + 1;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.selected {
    background: var(--abrdn-black);
    color: white;
    min-width: 32px;
    min-height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.dots {
    min-width: 32px;
    min-height: 32px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.03em;
    display: flex;
    justify-content: center;
    padding-top: 4px;
}
.tile {
    min-width: 32px;
    min-height: 32px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.03em;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    &:hover {
        background: var(--button-night);
        color: var(--abrdn-white);
    }
    &.disabled {
        background: none;
        pointer-events: none;
        color: var(--digital-steel-grey);
    }
}
.page-loading .tile:not(.selected) {
    background: none;
    pointer-events: none;
    color: var(--digital-steel-grey);
}
</style>
