<template>
    <section
        :id="anchorId"
        :data-e2e-component-uid="rendering.uid"
        class="container background-white default-section-padding link-change"
    >
        <div class="col-12 col-md-8">
            <ScText
                v-if="isExperienceEditor || titleExist"
                tag="h2"
                :field="fields.title"
                class="background-to-change"
            />
        </div>
        <div v-if="haveLinks" class="mt-2">
            <template v-for="item in fields.items">
                <div
                    v-if="linkHaveValue(item) || isExperienceEditor"
                    :key="item.id"
                    class="faded-underline col-12 py-3"
                >
                    <ScLink class="black-underline text-link me-1" :field="setLinkTarget(item)" />
                    <em
                        v-if="!isExternalLink(item.fields.link.value.linktype)"
                        class="bi-download"
                        aria-hidden="true"
                    />
                    <ScText
                        tag="p"
                        :field="item.fields.description"
                        class="background-to-change footnote text-grey mt-1"
                    />
                </div>
            </template>
        </div>
        <div v-if="ctaLinkExist" class="link col-12 pt-5 d-flex align-items-center">
            <ScLink class="me-2" :field="fields.link" />
            <em class="bi-chevron-right d-flex align-items-center" aria-hidden="true" />
        </div>
    </section>
</template>
<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import ScLink from '@/components/Generic/SitecoreWrappers/ScLink.vue';

export default {
    name: 'DocumentListings',
    components: { ScLink, ScText: Text },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        titleExist() {
            return !!this.fields?.title?.value;
        },
        ctaLinkExist() {
            return !!this.fields?.link?.value?.text && !!this.fields?.link?.value?.href;
        },
        haveLinks() {
            return this.fields?.items?.length;
        },
    },
    methods: {
        isExternalLink(linktype) {
            return linktype === 'external';
        },
        setLinkTarget(item) {
            const link = {
                ...item.fields.link,
                value: {
                    ...item.fields.link.value,
                    target: '_blank',
                    text: item.fields.link.value.text ? item.fields.link.value.text : item.fields.link.value.href,
                },
            };

            if (this.isExperienceEditor) {
                link.editable = link.editable.replace('No text in field', item.displayName);
                link.editableFirstPart = link.editableFirstPart.replace('No text in field', item.displayName);
                link.editableLastPart = link.editableLastPart.replace('No text in field', item.displayName);
            }

            return link;
        },
        linkHaveValue(item) {
            return !!item.fields.link?.value?.href;
        },
    },
};
</script>

<style scoped lang="scss">
.black-underline {
    border-bottom: 1px solid;
}

.faded-underline {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.link {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
}
.link i:before {
    font-weight: 600 !important;
    font-size: 14px;
}
</style>
