<template>
    <section class="default-section-padding background-white">
        <FundDetailsTabRow>
            <FundDetailsTabSkeleton v-if="loading" />
            <AlertMessageBase v-else-if="error" type="error" :title="$t(error)" />
            <template v-else-if="tabData">
                <h2>{{ tabData.podcastTitle }}</h2>
                <ScRichText
                    class="mt-5 body-default text-grey d-grid gap-3"
                    :field="{ value: tabData.podcastDescription }"
                />
                <div class="mt-6">
                    <iframe
                        class="iframe"
                        :src="tabData.podcastUrl"
                        width="100%"
                        height="242"
                        allowfullscreen=""
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                        loading="lazy"
                        :title="tabData.podcastTitle"
                    />
                </div>
                <AccordionDefault
                    v-if="tabData.podcastTranscript"
                    :title="{ value: $t('transcript') }"
                    :description="{ value: tabData.podcastTranscript }"
                    class="mt-3 accordion-item"
                />
            </template>
            <div v-else class="body-default">{{ $t('noResultsMessage') }}</div>
        </FundDetailsTabRow>
    </section>
</template>

<script>
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import { mapActions, mapState } from 'vuex';

import AccordionDefault from '@/components/Generic/AccordionDefault';

import FundDetailsTabRow from '../FundDetailsTabRow';
import FundDetailsTabSharedLogic from '../FundDetailsTabSharedLogic.mixin';

export default {
    name: 'FundDetailsPodcastTab',

    components: {
        AccordionDefault,
        FundDetailsTabRow,
        ScRichText: RichText,
    },

    mixins: [FundDetailsTabSharedLogic],

    computed: {
        ...mapState('fundDetails', {
            loading: (state) => state.podcastTabData.loading,
            error: (state) => state.podcastTabData.error,
            tabData: (state) => state.podcastTabData.data,
        }),
    },

    methods: {
        ...mapActions('fundDetails', {
            fetchTabData: 'fetchPodcastTabData',
        }),
    },
};
</script>

<style scoped lang="scss">
.iframe {
    display: block;
    border-radius: 12px;
}

.accordion-item ::v-deep .btn {
    padding-top: 20px;
    padding-bottom: 20px;
}
</style>
