var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[(!_vm.showSkeleton)?_c('tr',[_c('th',{staticClass:"section-header-small sticky-column",attrs:{"scope":"col"}},[_c('div',{staticClass:"d-flex"},[_vm._v("\n                "+_vm._s(_vm.$t('fundName'))+"\n            ")])]),(!_vm.hideShareclass)?_c('th',{staticClass:"section-header-small",attrs:{"scope":"col"}},[_c('div',{staticClass:"d-flex"},[_vm._v("\n                "+_vm._s(_vm.$t('kidShareClass'))+"\n                "),(
                        _vm.$t('fundsFundCentreTooltipShareclass') &&
                        _vm.$t('fundsFundCentreTooltipShareclass') !== 'fundsFundCentreTooltipShareclass'
                    )?_c('Tooltip',{attrs:{"arialLabelText":_vm.$t('kidShareClass')},scopedSlots:_vm._u([{key:"tooltipTitle",fn:function(){return [_c('p',{staticClass:"tooltip-title"},[_vm._v("\n                            "+_vm._s(_vm.$t('kidShareClass'))+"\n                        ")])]},proxy:true},{key:"tooltipContent",fn:function(){return [_c('p',{staticClass:"tooltip-description"},[_vm._v(_vm._s(_vm.$t('fundsFundCentreTooltipShareclass')))])]},proxy:true}],null,false,4114374637)}):_vm._e()],1)]):_vm._e(),_vm._l((_vm.columnsToShow.general),function(optionalColumn){return _c('th',{key:optionalColumn.visibleName,staticClass:"section-header-small",attrs:{"scope":"col"}},[_c('div',{staticClass:"d-flex"},[_vm._v("\n                "+_vm._s(optionalColumn.visibleName)+"\n                "),(
                        optionalColumn.propertyName === 'sfdrClassification' &&
                        _vm.$t('fundsFundCentreTooltipSfdr') &&
                        _vm.$t('fundsFundCentreTooltipSfdr') !== 'fundsFundCentreTooltipSfdr'
                    )?_c('Tooltip',{attrs:{"arialLabelText":optionalColumn.visibleName},scopedSlots:_vm._u([{key:"tooltipTitle",fn:function(){return [_c('p',{staticClass:"tooltip-title"},[_vm._v("\n                            "+_vm._s(optionalColumn.visibleName)+"\n                        ")])]},proxy:true},{key:"tooltipContent",fn:function(){return [_c('p',{staticClass:"tooltip-description"},[_vm._v(_vm._s(_vm.$t('fundsFundCentreTooltipSfdr')))])]},proxy:true}],null,true)}):_vm._e()],1)])}),_vm._l((_vm.columnsToShow.shareclassSpecific),function(optionalColumn){return _c('th',{key:optionalColumn.visibleName,staticClass:"section-header-small",attrs:{"scope":"col"}},[_vm._v("\n            "+_vm._s(optionalColumn.visibleName)+"\n        ")])})],2):_c('tr',{staticClass:"skeleton-row"},_vm._l((6),function(item,idx){return _c('th',{key:idx,staticClass:"section-header-small",attrs:{"scope":"col"}},[_c('BaseSkeleton')],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }