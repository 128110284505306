<template>
    <div class="relative" @focusout="checkFocusWithin($event)" @keydown.esc="$emit('closeDropdown')">
        <div v-if="isOpen" class="overlay" @click="$emit('outsideClick')" />
        <div class="d-flex">
            <slot name="trigger-area" />
        </div>
        <div id="trigger-aria" ref="content" class="dropdown__content absolute" :class="{ 'w-100': fullWidth }">
            <slot />
            <div v-if="showActionButtons" class="filter-dropdown-nav d-flex align-items-center" :class="buttonsLayout">
                <AbrdnButton
                    buttonType="secondary"
                    :type="buttonActionType"
                    :tabindex="isOpen ? '0' : '-1'"
                    aria-hidden="true"
                    :class="{ disabled: isLoading, 'me-2': !buttonsLayout }"
                    class="override--light-background"
                    @click.native="!isLoading && $emit('cancelEvent')"
                >
                    <span>{{ $t('clear') }}</span>
                </AbrdnButton>
                <AbrdnButton
                    buttonType="primary"
                    :type="buttonActionType"
                    :tabindex="isOpen ? '0' : '-1'"
                    aria-hidden="true"
                    :class="{ disabled: isLoading }"
                    class="override--light-background"
                    @click.native="!isLoading && $emit('confirmEvent')"
                >
                    <span>{{ $t('apply') }}</span>
                </AbrdnButton>
            </div>
        </div>
    </div>
</template>

<script>
import gsap from 'gsap';

import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton.vue';
import { isFocusWithin } from '@/mixins/Helpers.mixin';

export default {
    name: 'StateDrivenSlotDropdown',
    components: { AbrdnButton },
    mixins: [isFocusWithin],
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        isOpen: {
            type: Boolean,
            default: false,
        },
        speed: {
            type: Number,
            default: 200,
        },
        showActionButtons: {
            type: Boolean,
            default: false,
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
        buttonActionType: {
            type: String,
            default: 'submit',
        },
        buttonsLayout: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            collapseAnimation: null,
        };
    },
    watch: {
        isOpen: {
            handler: function (val, oldVal) {
                this.$emit('onFocus');
                if (val === oldVal) return;
                if (val) {
                    this.collapseAnimation.play();
                } else {
                    this.collapseAnimation.reverse();
                }
            },
        },
    },
    mounted() {
        this.collapseAnimation = gsap.to(this.$refs.content, {
            height: 'auto',
            duration: this.speed / 1000,
            paused: !this.isOpen,
        });
    },
    methods: {
        checkFocusWithin(event) {
            if (!this.isFocusWithin(event)) this.$emit('lostFocus');
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixins';
.relative {
    position: relative;
}
.overlay {
    background: #eeeeee10;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 99;
}

.dropdown__content {
    @include dark-backgrounds() {
        color: var(--abrdn-black);
    }
    top: calc(100% + var(--spacer));
    left: 0;
    background: var(--abrdn-white);
    box-shadow: 0 16px 100px rgba(9, 9, 14, 0.12);
    position: absolute;
    z-index: 999;
    height: 0;
    overflow: hidden;
}

.dropdown--content-right .dropdown__content {
    left: auto;
    right: 0;
}

.filter-dropdown-nav {
    padding: calc(var(--spacer) * 2);
    width: 100%;
}
</style>
