<template>
    <section class="default-section-padding background-white">
        <FundDetailsTabRow fullWidth>
            <h2>{{ title }}</h2>
        </FundDetailsTabRow>

        <div class="mt-5">
            <FundDetailsTabRow v-if="loading || error" fullWidth>
                <FundDetailsTabSkeleton v-if="loading" />
                <AlertMessageBase v-else-if="error" type="error" :title="$t(error)" />
            </FundDetailsTabRow>
            <div v-else-if="tabData && tabData.length">
                <FundDetailsSecondLevelTabGroup
                    v-if="tabData.length > 1"
                    :availableTabs="availableTabs"
                    :tabComponentsByTabKeys="tabComponentsByTabName"
                    :tabTitlesByTabKeys="tabTitlesByTabName"
                    @tabChanged="setTabDataData(['literatureTabSelectedSubTab', $event])"
                />
                <LiteratureSharedTab v-else />
            </div>

            <FundDetailsTabRow v-else>
                <div class="body-default">{{ $t('noResultsMessage') }}</div>
            </FundDetailsTabRow>
        </div>
    </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

import AlertMessageBase from '@/components/Generic/AlertMessageBase';
import FundDetailsSecondLevelTabGroup from '@/components/Pages/FundsCenter/FundDetails/FundDetailsSecondLevelTabGroup';
import LiteratureSharedTab from '@/components/Pages/FundsCenter/FundDetails/SecondLevelTabs/LiteratureTabs/LiteratureSharedTab';

import FundDetailsTabRow from '../FundDetailsTabRow';
import FundDetailsTabSharedLogic from '../FundDetailsTabSharedLogic.mixin';
import FundDetailsTabSkeleton from '../FundDetailsTabSkeleton';

export default {
    name: 'FundDetailsLiteratureTab',

    components: {
        AlertMessageBase,
        FundDetailsTabRow,
        FundDetailsTabSkeleton,
        FundDetailsSecondLevelTabGroup,
        LiteratureSharedTab,
    },

    mixins: [FundDetailsTabSharedLogic],

    props: {
        title: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            tabComponentsByTabName: {
                [this.$t('fundDetailsLiteratureFundLiterature')]: LiteratureSharedTab,
                [this.$t('fundDetailsLiteratureSustainability')]: LiteratureSharedTab,
                [this.$t('fundDetailsLiteratureAnnouncements')]: LiteratureSharedTab,
                [this.$t('fundDetailsLiteratureSectionNineteen')]: LiteratureSharedTab,
                [this.$t('fundDetailsLiteratureTaxInformation')]: LiteratureSharedTab,
            },
            tabTitlesByTabName: {
                [this.$t('fundDetailsLiteratureFundLiterature')]: this.$t('fundDetailsLiteratureFundLiterature'),
                [this.$t('fundDetailsLiteratureSustainability')]: this.$t('fundDetailsLiteratureSustainability'),
                [this.$t('fundDetailsLiteratureAnnouncements')]: this.$t('fundDetailsLiteratureAnnouncements'),
                [this.$t('fundDetailsLiteratureSectionNineteen')]: this.$t('fundDetailsLiteratureSectionNineteen'),
                [this.$t('fundDetailsLiteratureTaxInformation')]: this.$t('fundDetailsLiteratureTaxInformation'),
            },
        };
    },

    computed: {
        ...mapState('fundDetails', {
            loading: (state) => state.literatureTabData.loading,
            error: (state) => state.literatureTabData.error,
            tabData: (state) => state.literatureTabData.data,
        }),

        availableTabs() {
            return this.tabData.reduce((tabs, tab) => {
                tabs[this.$t(tab.title)] = true;
                return tabs;
            }, {});
        },
    },

    methods: {
        ...mapActions('fundDetails', {
            fetchTabData: 'fetchLiteratureTabData',
        }),
        ...mapMutations('fundDetails', ['setTabDataData']),
    },
};
</script>
