<template>
    <div class="meganav__body__mobile">
        <div role="button" class="overlay d-none d-sm-block" @click="closeMobileMenu()" />
        <div class="meganav__body__mobile__inner py-5 background-white">
            <div
                class="meganav__body__mobile__header d-flex align-items-center justify-content-between pb-5 ps-3 pe-2 ps-md-6 pe-md-5"
            >
                <button
                    v-if="levels.length > 1"
                    class="btn btn-naked meganav__body__mobile__backlink text-link"
                    @click="levelBack()"
                >
                    <span class="chevron me-2"><IconChevron /></span>
                    <ScText tag="span" :field="backLabelString" />
                </button>
                <button class="btn btn-naked meganav__closer ms-auto" @click="closeMobileMenu()">
                    <IconX />
                </button>
            </div>

            <div
                v-if="(submenuTitle || submenuDescription) && !isLanguageSelector"
                class="meganav__body__desc py-2 px-2 ps-sm-4 pe-sm-5"
            >
                <p v-if="submenuTitle" class="section-header-small">{{ submenuTitle }}</p>
                <p v-if="submenuDescription" class="body-small text-grey mt-2">{{ submenuDescription }}</p>
            </div>
            <transition-group name="fade" tag="ul" class="meganav__body__mobile__level">
                <li v-for="category in currentLevel" :key="category.id">
                    <ScLink
                        v-if="category.fields.link"
                        :field="category.fields.link"
                        class="meganav__menu-item meganav-link meganav-link--small py-2 px-2 ps-sm-4 pe-sm-5 d-inline-flex w-100 revert-underline"
                        @click.native="closeMobileMenu()"
                    />
                    <ScLink
                        v-else-if="!category.hasLinks && category.directLink"
                        :field="category.directLink"
                        class="meganav__menu-item btn btn-naked meganav-link meganav-link--large py-2 px-2 ps-sm-4 pe-sm-5"
                        @click.native="closeMobileMenu()"
                    />
                    <button
                        v-else-if="category.fields.title"
                        class="meganav__menu-item btn btn-naked meganav-link meganav-link--large py-2 px-2 ps-sm-4 pe-sm-6"
                        @click="selectLevel(category)"
                    >
                        <StaticImage
                            v-if="category.fields.title.value === 'Change Language'"
                            :src="icon"
                            class="menu-item-icon me-2"
                            alt="Change language"
                            :srcSet="[{ mw: 50 }]"
                            sizes="24px"
                        />

                        {{ category.fields.title.value }}
                        <span class="chevron">
                            <IconChevron />
                        </span>
                    </button>
                    <div v-if="category.fields.languageSelector">
                        <LanguageSelector @goBack="levelBack()" />
                    </div>
                    <div v-if="category.fields.changeSite">
                        <MobileMenuSiteSelector
                            :siteSelectorProperty="siteSelectorProperty"
                            @changeSiteHandler="closeMobileMenu"
                        />
                    </div>
                </li>
            </transition-group>
            <div class="overflow-hidden">
                <transition name="fade">
                    <div v-if="!currentDepth" class="meganav__body__mobile__pullout">
                        <hr class="mx-2 ms-sm-4 me-sm-5 my-3" />
                        <ul class="meganav__body__mobile__level w-100">
                            <li v-if="siteSelectorProperty" class="d-flex w-100">
                                <button
                                    class="meganav__menu-item btn btn-naked meganav-link meganav-link--small py-2 px-2 ps-sm-4 pe-sm-5"
                                    @click="selectLevel(changeSiteStructure)"
                                >
                                    <WrappedImage
                                        :media="siteSelectorProperty.changeCountryIcon"
                                        class="menu-item-icon me-2"
                                        :srcSet="[{ mw: 50 }]"
                                        sizes="24px"
                                    />
                                    {{ $t('siteSelectorTitle') }}
                                    <span class="chevron">
                                        <IconChevron />
                                    </span>
                                </button>
                            </li>
                            <li v-if="getLanguageVersions.length > 1" class="d-flex w-100">
                                <button
                                    v-if="!currentDepth"
                                    class="meganav__menu-item btn btn-naked meganav-link meganav-link--small py-2 px-2 ps-sm-4 pe-sm-5"
                                    @click="selectLevel(changeLanguageStructure)"
                                >
                                    <StaticImage
                                        v-if="changeLanguageStructure.fields.title.value === 'Change Language'"
                                        :src="icon"
                                        :alt="$t('changeLanguage')"
                                        class="menu-item-icon me-2"
                                        :srcSet="[{ mw: 50 }]"
                                        sizes="24px"
                                    />

                                    {{ $t('changeLanguage') }}
                                    <span class="chevron">
                                        <IconChevron />
                                    </span>
                                </button>
                            </li>
                            <li class="d-flex w-100">
                                <ScLink
                                    v-if="!currentDepth && hasSharePrice"
                                    :field="sharePriceCta"
                                    :replaceWithSlot="true"
                                    :showExternalIcon="false"
                                    class="meganav__menu-item btn btn-naked meganav-link meganav-link--small py-2 px-2 ps-sm-4 pe-sm-5"
                                    @click.native="closeMobileMenu()"
                                >
                                    <div class="menu-item-icon me-2" />
                                    {{ $t('sharePrice') }}
                                    <span class="chevron">
                                        <IconChevron />
                                    </span>
                                </ScLink>
                            </li>
                        </ul>
                    </div>
                </transition>
                <transition name="fade">
                    <div v-if="pullOutLink" class="meganav__body__mobile__pullout">
                        <hr class="mx-2 my-3" />
                        <ScLink
                            :field="pullOutLink"
                            class="meganav-link meganav-link--small py-2 px-2 ps-sm-4 pe-sm-5 d-inline-flex w-100 revert-underline"
                            @click.native="closeMobileMenu()"
                        />
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { mapGetters } from 'vuex';

import MobileMenuSiteSelector from '@/components/Generic/Header/MegaNavigation/MobileMenuSiteSelector.vue';
import ScLink from '@/components/Generic/SitecoreWrappers/ScLink.vue';
import StaticImage from '@/components/Generic/SitecoreWrappers/StaticImage';
import WrappedImage from '@/components/Generic/SitecoreWrappers/WrappedImage.vue';
import IconChevron from '@/components/Icons/icon-chevron.vue';
import IconX from '@/components/Icons/icon-x.vue';
import SharePrice from '@/mixins/SharePrice.mixin';

import changeLanguageStructure from './changeLanguage.js';
import changeSiteStructure from './changeSite.js';
import LanguageSelector from './LanguageSelector.vue';

export default {
    name: 'MegaNavigationBodyMobile',
    components: {
        WrappedImage,
        MobileMenuSiteSelector,
        ScLink,
        IconX,
        IconChevron,
        ScText: Text,
        LanguageSelector,
        StaticImage,
    },
    mixins: [SharePrice],
    props: {
        categories: {
            type: Array,
            default: /* istanbul ignore next */ () => [],
        },
        closeLabel: {
            type: Object,
            default: /* istanbul ignore next */ () => {},
        },
        backLabel: {
            type: Object,
            default: /* istanbul ignore next */ () => {},
        },
    },
    data() {
        return {
            selectedCategory: null,
            levels: [this.categories],
            currentDepth: 0,
            selectedLanguage: 0,
            languages: [
                { value: 'en', label: 'English', selected: true },
                { value: 'fr', label: 'Français' },
                { value: 'de', label: 'Deutsch' },
            ],
            icon: require('@/assets/img/custom-svg/artwork.svg'),
            changeLanguageStructure: changeLanguageStructure,
            changeSiteStructure,
            backLabelString: {
                value: this.$t('back'),
            },
        };
    },
    computed: {
        ...mapGetters('language', ['getLanguageVersions']),
        pullOutLink() {
            return this.selectedCategory?.fields?.pulloutLink?.value?.href
                ? this.selectedCategory?.fields?.pulloutLink
                : false;
        },
        currentLevel() {
            return this.selectedCategory
                ? this.selectedCategory?.fields?.items || []
                : !this.currentDepth
                ? [...this.categories]
                : this.categories;
        },
        submenuTitle() {
            return this.selectedCategory?.fields?.title.value;
        },
        isLanguageSelector() {
            return this.selectedCategory?.hideTitle;
        },
        submenuDescription() {
            return this.selectedCategory?.fields?.description?.value;
        },
        siteSelectorProperty() {
            return this.$jss.sitecoreContext().siteSelector;
        },
    },
    methods: {
        updateBackLabelString() {
            this.backLabelString.value =
                this.levels.length > 2 ? this.levels[this.levels.length - 2].fields.title.value : this.$t('back');
        },
        selectLevel(level) {
            this.currentDepth += 1;
            setTimeout(() => {
                if (!this.levels.includes(level)) {
                    this.levels.push(level);
                }
                this.selectedCategory = level;
                this.updateBackLabelString();
            }, 200);
        },
        levelBack() {
            const targetLevelIdx = this.levels.length - 2;
            this.selectedCategory = targetLevelIdx === 0 ? null : this.levels[targetLevelIdx];
            this.levels.pop();
            this.currentDepth -= 1;
            this.updateBackLabelString();
        },
        closeMobileMenu() {
            this.currentDepth = 0;
            this.levels = [this.categories];
            this.selectedCategory = null;
            this.updateBackLabelString();
            this.$emit('closeMobileMenu');
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
.overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    z-index: -1;
}
.meganav__body__mobile {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 75%;
    z-index: 100;
    pointer-events: none;
    @include media-breakpoint-down(sm) {
        width: 100%;
    }
    .meganav__body__mobile__inner {
        transform: translateX(100%);
        transition: transform 0.3s ease-in-out;
        height: 100%;
        overflow: auto;
    }
    &.active {
        pointer-events: all;
        .meganav__body__mobile__inner {
            transform: none;
        }
        .overlay {
            opacity: 1;
        }
    }
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
    overflow: hidden;
}
ul li .meganav__menu-item {
    display: inline-flex;
    align-items: center;
    width: 100%;
    .chevron {
        width: 13px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        transform: rotate(-90deg);
        svg {
            width: 12px;
            height: 12px;
        }
    }
}
.meganav__closer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    svg {
        width: 14px;
        height: 14px;
    }
}
.meganav__body__mobile__backlink {
    display: flex;
    align-items: center;
    line-height: 1;
    .chevron {
        width: 12px;
        height: 12px;
        display: flex;
        transform: rotate(90deg);
        svg {
            width: 100%;
            height: 100%;
        }
    }
}
.meganav__body__mobile__pullout hr {
    background-color: rgba(0, 0, 0, 0.3);
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.25s, transform 0.25s;
}
.fade-leave-active {
    transition-timing-function: ease-out;
}
.fade-enter-active {
    transition-timing-function: ease-in;
}

.fade-enter-active {
    transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
.fade-leave-to {
    transform: translateX(30px);
}
.fade-enter {
    transform: translateX(-30px);
}

.menu-item-icon {
    width: 24px;
    height: 24px;
}
</style>
