<template>
    <section
        :id="anchorId"
        :data-e2e-component-uid="rendering.uid"
        class="container default-section-margin short-section"
    >
        <h2>How much could your savings be worth in the future?</h2>
        <p class="body-default col-xl-8 col-md-10 mt-5 pe-xl-1 text-grey">
            Our ISA calculator gives you an idea of how much your money could be worth over the next five years. Keep in
            mind that growth is an assumption and doesn't reflect how your money will perform in reality.
        </p>
        <div class="mt-5 pt-4 mt-sm-8 pt-sm-8 border-top d-flex flex-xl-row flex-column justify-content-between">
            <div class="input-wrapper input-wrapper-1">
                <div class="inputs d-flex justify-content-between">
                    <ValueField
                        ref="regularPaymentFields"
                        :fields="regularPaymentFields"
                        @onKeyDownEnter="calculate"
                        @updateValue="setValue('regularPayment', ...arguments)"
                    />
                    <AbrdnSelect :fields="fieldsDropdown" class="dropdown" @updateValue="validateOnDropDown" />
                </div>
            </div>
            <div class="input-wrapper input-wrapper-2 pt-sm-4 pt-3 pt-xl-0 px-xl-4 mt-xl-0 mt-sm-4 mt-3">
                <div class="inputs one-payment-input">
                    <ValueField
                        ref="oneOffPaymentFields"
                        :fields="oneOffPaymentFields"
                        @onKeyDownEnter="calculate"
                        @updateValue="setValue('oneOffPayment', ...arguments)"
                    />
                </div>
            </div>
            <div class="growth-dragger-wrapper pt-sm-4 pt-2 pt-xl-0 mt-xl-0 mt-sm-4 mt-2">
                <Dragger
                    :fields="fieldsGrowthDragger"
                    class="growth-dragger inputs"
                    @setDraggerValue="getGrowthValue"
                />
            </div>
            <div class="mt-xl-3 mt-sm-7 mt-6 button-wrapper">
                <AbrdnButton :buttonType="'primary'" @click.native="calculate">
                    <span>Calculate</span>
                </AbrdnButton>
            </div>
        </div>
        <transition name="fade">
            <div
                v-show="!!calculatedRegularPayment || !!calculatedOneOffPayment"
                class="graph-wrapper flex-column flex-md-row mt-sm-5 mt-4 w-100"
            >
                <Chart :minYears="minYears" :years="calculatedYears" class="chart-wrapper" />
                <div class="chart-settings d-flex flex-column ms-md-2 mt-2 mt-md-0 px-2 py-3">
                    <div class="d-flex justify-content-start align-items-start mb-3">
                        <div class="p-1 background-black" />
                        <p class="form-label ms-1">Your payments</p>
                    </div>
                    <div class="d-flex justify-content-start align-items-start mb-3">
                        <div class="p-1 background-secondary" />
                        <p class="form-label ms-1">Growth of your investment</p>
                    </div>
                    <div class="d-flex justify-content-start align-items-start">
                        <div class="p-1 background-grey" />
                        <p class="form-label ms-1">Total value of ISA</p>
                    </div>
                    <div class="years-saved-label form-label">
                        <Dragger
                            :label="'How many years do you want to save for?'"
                            :max="maxYears"
                            :min="minYears"
                            :startValue="yearsCount"
                            :usePercent="false"
                            @updateDraggerValue="getYearsCount"
                        />
                    </div>
                </div>
            </div>
        </transition>
        <div class="collapse-wrapper mt-sm-2 mt-3 mb-5">
            <button
                class="collapse-trigger d-flex align-items-center justify-content-between field-text"
                @click="toggleDropdown"
            >
                View important information, assumptions and limitations
                <svg
                    :class="{ active: showDropdown }"
                    fill="none"
                    height="7"
                    viewBox="0 0 12 7"
                    width="12"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                >
                    <path
                        clip-rule="evenodd"
                        d="M11.7614 1.42872L6.57614 6.75487C6.25794 7.08171 5.74206 7.08171 5.42386 6.75487L0.238644 1.42872C-0.0795479 1.10188 -0.0795479 0.571969 0.238644 0.245129C0.556835 -0.0817098 1.07272 -0.0817098 1.39091 0.245129L6 4.98074L10.6091 0.245129C10.9273 -0.0817098 11.4432 -0.0817098 11.7614 0.245129C12.0795 0.571969 12.0795 1.10188 11.7614 1.42872Z"
                        fill="black"
                        fill-rule="evenodd"
                    />
                </svg>
            </button>
            <transition name="height">
                <div v-show="showDropdown" class="w-100 overflow-hidden">
                    <h4 class="mt-2">Important information</h4>
                    <p class="body-default mt-2 text-grey">
                        The figures produced by this calculator are based on a number of assumptions and are only
                        illustrative. abrdn does not accept any responsibility for any loss caused by reliance on the
                        figures generated by this calculator. Neither the information provided nor the figures generated
                        by this calculator constitute financial or other professional advice from abrdn. If you require
                        financial or other professional advice you should consult a suitably-qualified professional.
                    </p>
                    <p class="body-default mt-2 text-grey">
                        Investment returns aren't guaranteed. The value of your investment can go up or down and may be
                        worth less than what was paid in. Laws and tax rules may change in the future. The information
                        here is based on our understanding in the current tax year. Your personal circumstances also
                        have an impact on tax treatment.
                    </p>

                    <h4 class="mt-2">Assumptions</h4>
                    <ul class="list-wrapper dropdown-list mt-2">
                        <li class="body-default mt-1 text-grey">Inflation is not taken into account</li>
                        <li class="body-default mt-1 text-grey">
                            The annual growth rate will remain the same each year
                        </li>
                        <li class="body-default mt-1 text-grey">
                            The one-off lump sum payment is made in this tax year only
                        </li>
                        <li class="body-default mt-1 text-grey">
                            The ISA limit is £20,000 per year through the term of the investment
                        </li>
                    </ul>
                    <h4>Limitations</h4>

                    <ul class="list-wrapper dropdown-list mt-2 text-grey">
                        <li class="body-default mt-1 text-grey">
                            Any product, fund or platform charges are not taken into account.
                        </li>
                        <li class="body-default mt-1 text-grey">
                            The calculator assumes you make the same regular payments each year through the term of the
                            investment
                        </li>
                    </ul>
                </div>
            </transition>
            <div class="w-100">
                <h3 class="mt-8">Ready to open an ISA?</h3>
                <p class="body-default col-xl-8 col-sm-8 col-12 mt-5 text-grey">
                    Apply online in minutes - make sure you have your National Insurance number and debit card / bank
                    details handy.
                </p>
                <ul class="list-wrapper mt-sm-3 mt-2">
                    <li class="body-default mt-1 text-grey">Select your investment choice</li>
                    <li class="body-default mt-1 text-grey">Set up your payments</li>
                    <li class="body-default mt-1 text-grey">Register for our online portal to manage your ISA</li>
                </ul>
                <AbrdnButton
                    :buttonType="'primary'"
                    :linkField="ctaFieldLink"
                    class="external-button mt-sm-5 mt-4 d-inline-flex align-items-center"
                />
            </div>
        </div>
    </section>
</template>

<script>
import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton';
import Dragger from '@/components/Generic/FormElements/Dragger';
import AbrdnSelect from '@/components/Generic/FormElements/FormFields/AbrdnSelect';
import ValueField from '@/components/Generic/FormElements/FormFields/ValueField';

import Chart from './Chart';

export default {
    name: 'IsaCalculator',
    components: { Chart, Dragger, AbrdnButton, ValueField, AbrdnSelect },
    props: {
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    data() {
        return {
            maxValueMonthly: 1666,
            maxValueYearly: 20000,
            maxYears: 15,
            minYears: 5,
            isError: false,
            showDropdown: false,
            fieldsGrowthDragger: {
                tooltip: {
                    value: 'This is the annual rate of growth you think you may get on your investments. The growth rate range in the calculator is set by the Financial Conduct Authority. These are not minimum or maximum rates.',
                },
            },
            regularPaymentFields: {
                label: {
                    value: 'Your regular payments',
                },
                maxlength: {
                    value: 5,
                },
                formatPattern: {
                    value: {
                        mark: '',
                        thousand: ',',
                        prefix: '',
                        suffix: '',
                        decimals: 0,
                    },
                },
                validations: {
                    value: [
                        {
                            validation: '^([1-9]|[1-9]\\d{1,2}|1[0-5]\\d{2}|16[0-5]\\d|166[0-6])$',
                            title: 'Value must be greater then 0 and max £1,666',
                        },
                    ],
                },
                placeholder: {
                    value: '0.00',
                },
                name: {
                    value: 'regular-payment-input',
                },
                hint: {
                    value: 'Max £1,666 per month, £20,000 per year',
                },
            },
            oneOffPaymentFields: {
                label: {
                    value: 'One-off payment',
                },
                validations: {
                    value: [
                        {
                            validation: '^([0-9]|[1-9][0-9]{1,3}|1[0-9]{4}|20000)$',
                            title: 'Value must be max 20000 of every payment',
                        },
                    ],
                },
                maxlength: {
                    value: 5,
                },
                formatPattern: {
                    value: {
                        mark: '',
                        thousand: ',',
                        prefix: '',
                        suffix: '',
                        decimals: 0,
                    },
                },
                placeholder: {
                    value: '0.00',
                },
                name: 'one-off-payment-input',
                tooltip: {
                    value: 'This is how much you are going to pay into the ISA as a lump-sum payment for this tax year only.',
                },
            },
            fieldsDropdown: {
                label: 'LabelName',
                errorMessage: 'An error occurred',
                placeholder: 'Placeholder',
                options: {
                    value: [
                        {
                            name: 'monthly',
                            displayName: 'Monthly',
                            selected: true,
                        },
                        {
                            name: 'yearly',
                            displayName: 'Yearly',
                            selected: false,
                        },
                    ],
                },
            },
            regularPayment: 0,
            calculatedRegularPayment: 0,
            oneOffPayment: 0,
            calculatedOneOffPayment: 0,
            growthValue: 0.05,
            period: 'monthly',
            inflation: 0.025,
            yearsCount: 10,
            label: 'How many years do you want to save for?',
            years: [
                { payment: 0, paymentGrow: 0, id: 0 },
                { payment: 0, paymentGrow: 0, id: 1 },
                { payment: 0, paymentGrow: 0, id: 2 },
                { payment: 0, paymentGrow: 0, id: 3 },
                { payment: 0, paymentGrow: 0, id: 4 },
                { payment: 0, paymentGrow: 0, id: 5 },
                { payment: 0, paymentGrow: 0, id: 6 },
                { payment: 0, paymentGrow: 0, id: 7 },
                { payment: 0, paymentGrow: 0, id: 8 },
                { payment: 0, paymentGrow: 0, id: 9 },
                { payment: 0, paymentGrow: 0, id: 10 },
                { payment: 0, paymentGrow: 0, id: 11 },
                { payment: 0, paymentGrow: 0, id: 12 },
                { payment: 0, paymentGrow: 0, id: 13 },
                { payment: 0, paymentGrow: 0, id: 14 },
            ],
            ctaFieldLink: {
                value: {
                    href: 'https://secure-uk.abrdn.com/wpclient/sign-up/',
                    text: 'Open an ISA today',
                    anchor: '',
                    linktype: 'external',
                    class: '',
                    title: '',
                    target: '_blank',
                    querystring: '',
                },
            },
        };
    },
    computed: {
        calculatedYears() {
            return this.years.filter((year, index) => index >= this.minYears - 1 && index <= this.yearsCount - 1);
        },
    },
    methods: {
        toggleDropdown() {
            this.showDropdown = !this.showDropdown;
        },
        getGrowthValue(data) {
            this.growthValue = data / 100;
        },
        getYearsCount(data) {
            this.yearsCount = data;
        },
        validateOnDropDown(val) {
            this.period = val;
            this.validateInputsSum(['regularPayment', 'oneOffPayment']);
            this.validate();
        },
        validate() {
            this.$refs.regularPaymentFields.validate(this.regularPayment);
            this.$refs.oneOffPaymentFields.validate(this.oneOffPayment);
        },
        validateInputsSum(inputNames) {
            inputNames.forEach((inputName) => {
                const fields = inputName + 'Fields';
                const validations = this[fields].validations.value[0];
                if (this.period === 'monthly' && this.regularPayment * 12 + this.oneOffPayment > 20000) {
                    validations.validation = '^[a-z]$';
                    validations.title = 'Sum of two values must not exceed 20000';
                } else if (this.period === 'yearly' && this.regularPayment + this.oneOffPayment > 20000) {
                    validations.validation = '^[a-z]$';
                    validations.title = 'Sum of two values must not exceed 20000';
                } else {
                    if (inputName === 'oneOffPayment') {
                        validations.validation = '^([0-9]|[1-9][0-9]{1,3}|1[0-9]{4}|20000)$';
                        validations.title = 'Value must be max £20,000';
                    } else {
                        if (this.oneOffPayment === 0) {
                            if (this.period === 'monthly') {
                                validations.validation = '^([1-9]|[1-9]\\d{1,2}|1[0-5]\\d{2}|16[0-5]\\d|166[0-6])$';
                                validations.title = 'Value must be greater then 0 and max £1,666';
                            } else {
                                validations.validation = '^([1-9]|[1-9]\\d{1,3}|1\\d{4}|20000)$';
                                validations.title = 'Value must be greater then 0 and max £20,000';
                            }
                        } else {
                            if (this.period === 'monthly') {
                                validations.validation =
                                    '^([0-9]|[1-9][0-9]{1,2}|1[0-5][0-9]{2}|16[0-5][0-9]|166[0-6])$';
                                validations.title = 'Value must be greater then 0 and max £1,666';
                            } else {
                                validations.validation = '^([0-9]|[1-9][0-9]{1,3}|1[0-9]{4}|20000)$';
                                validations.title = 'Value must be greater then 0 and max £20,000';
                            }
                        }
                    }
                }
            });
        },
        setValue(inputName, value) {
            this[inputName] = value || 0;
            this.validateInputsSum(['regularPayment', 'oneOffPayment']);
            this.validate();
        },
        checkError() {
            this.isError = Object.keys(this.$refs).some((fields) => this.$refs[fields].isError);
        },
        calculate() {
            this.validateInputsSum(['regularPayment', 'oneOffPayment']);
            this.validate();
            this.checkError();
            this.calculatedRegularPayment = this.regularPayment || 0;
            this.calculatedOneOffPayment = this.oneOffPayment || 0;
            if (this.isError) {
                this.calculatedRegularPayment = 0;
                this.calculatedOneOffPayment = 0;
            }
            const paymentCollection = [];
            const discountRate = 12 * (1 - Math.pow(1 + this.growthValue, -1 / 12)); // 0.048691111787194874
            if (this.period === 'monthly' && this.calculatedRegularPayment) {
                const firstMonthTotalPayment = {
                    payment: this.calculatedOneOffPayment,
                    paymentGrow: 0,
                };

                let prevYear = firstMonthTotalPayment;

                for (let i = 0; i < this.years.length; i++) {
                    const { growthValue, regularPayment } = this;
                    const regularYearlyPayment = 12 * regularPayment;
                    const paymentWithGrow =
                        prevYear.payment * growthValue +
                        (regularYearlyPayment * growthValue) / discountRate +
                        prevYear.payment;

                    const paymentWithoutGrow = (i + 1) * regularPayment * 12 + this.calculatedOneOffPayment;

                    paymentCollection.push({
                        payment: paymentWithoutGrow,
                        paymentGrow: this.growthValue ? paymentWithGrow - paymentWithoutGrow : 0,
                        id: i,
                    });

                    prevYear = {
                        payment: paymentWithGrow,
                        paymentGrow: this.growthValue ? prevYear.paymentGrow + regularYearlyPayment : 0,
                    };
                }
            } else {
                for (let i = 0; i < this.years.length; i++) {
                    if (i === 0) {
                        paymentCollection.push({
                            payment: this.calculatedRegularPayment + this.calculatedOneOffPayment, // 100 + 0 = 100
                            paymentGrow:
                                (this.calculatedRegularPayment + this.calculatedOneOffPayment) * this.growthValue, // (100 + 0) * 0.05 = 5
                            id: 0,
                        });
                    } else {
                        const payment = paymentCollection[i - 1].payment + this.calculatedRegularPayment; // 100 + 100 = 200
                        const previousPaymentGrow = paymentCollection[i - 1].paymentGrow; // 5
                        const currentPaymentGrow = (payment + previousPaymentGrow) * this.growthValue; // (200 + 5) * 0.05 = ~10
                        const totalPaymentGrow = previousPaymentGrow + currentPaymentGrow; // 0 || 10 + 5 = 15
                        paymentCollection.push({
                            payment: payment,
                            paymentGrow: totalPaymentGrow,
                            id: i,
                        });
                    }
                }
            }
            this.years = paymentCollection;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
@import '@/assets/styles/variables';
@import '@/assets/styles/mixins';

.chart-settings {
    width: 240px;
    min-width: 240px;
    align-self: start;
    border: 1px solid #000;
    @include media-breakpoint-down(md) {
        width: 100%;
    }

    .form-label {
        min-width: 185px;

        &.years-saved-label {
            margin-top: 27px;
            margin-bottom: 11px;
            @include media-breakpoint-down(sm) {
                margin-top: 31px;
            }

            /deep/ .form-label-wrapper {
                @include media-breakpoint-down(sm) {
                    width: 70%;
                }
            }

            /deep/ .dragger-wrapper {
                margin-top: 16px;

                .noUi-target {
                    width: 182px;
                    @include media-breakpoint-down(md) {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.growth-dragger {
    width: 240px;
    @include media-breakpoint-down(sm) {
        width: 100%;
        /deep/ .noUi-target {
            width: 86%;
        }
    }
}

.input-wrapper {
    &-1 {
        width: 389px;
        @include media-breakpoint-down(sm) {
            width: 100%;
        }

        .inputs {
            gap: calc(var(--spacer) * 2);
            .form-element {
                @include media-breakpoint-down(sm) {
                    width: 56%;
                }
            }

            .dropdown {
                margin-top: 28px;
                width: 133px;
                flex-shrink: 0;
                @include media-breakpoint-down(sm) {
                    width: 40%;
                }
            }
        }
    }

    &-2 {
        border-left: 1px solid var(--bs-gray-300);
        border-right: 1px solid var(--bs-gray-300);
        width: 306px;
        @include media-breakpoint-down(xl) {
            border-top: 1px solid var(--bs-gray-300);
            border-left: none;
            border-right: none;
            width: 100%;
        }

        .one-payment-input {
            @include media-breakpoint-down(xl) {
                width: 306px;
            }
            @include media-breakpoint-down(sm) {
                width: 69.3%;
            }
        }
    }

    .hint-text {
        margin-top: 12px;
        color: #3f3f3f;
    }
}

.growth-dragger-wrapper {
    @include media-breakpoint-down(xl) {
        border-top: 1px solid var(--bs-gray-300);
        width: 100%;
    }
}

.button-wrapper {
    padding-top: 4px;
}

.graph-wrapper {
    display: flex;
}

.chart-wrapper {
    width: calc(100% - 256px);
    @include media-breakpoint-down(md) {
        width: 100%;
    }
}

.dropdown /deep/ .form-element__label {
    margin-bottom: 0;
}

.inputs /deep/ {
    .form-element__label,
    .form-label-wrapper {
        position: relative;

        .tooltip {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            &__content {
                width: 210px;
            }
        }
    }

    .form-element__hints {
        position: relative;
        top: auto;
        left: auto;
        margin-top: 11px;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: all 0.3s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.collapse-trigger {
    background: none;
    cursor: pointer;
    width: 493px;
    border: 1px solid var(--abrdn-black);
    padding: 12px 16px 12px 13px;
    color: var(--abrdn-black);
    @include media-breakpoint-down(sm) {
        width: 100%;
    }

    svg {
        transition: transform 0.3s linear;

        &.active {
            transform: rotate(180deg);
        }
    }
}

.collapse-wrapper {
    .list-wrapper {
        li {
            list-style: none;
            position: relative;

            &:before {
                content: '';
                position: absolute;
                left: -32px;
                top: 50%;
                transform: translateY(-50%);
                width: 8px;
                height: 8px;
                border-radius: 4px;
                background-color: var(--abrdn-black);
                @include dark-backgrounds() {
                    background-color: var(--abrdn-white);
                }
            }
        }
        &.dropdown-list {
            padding-left: 45px;
            li {
                &:before {
                    left: -19px;
                    width: 6px;
                    height: 6px;
                    border-radius: 4px;
                    background-color: var(--text-grey);
                    @include dark-backgrounds() {
                        background-color: var(--abrdn-white);
                    }
                }
            }
        }
    }

    .external-button {
        a {
            color: inherit;
        }

        svg {
            margin-left: 8px;
        }
    }
}

.height-enter-active,
.height-leave-active {
    transition: all 0.2s;
    max-height: 527px;
}

.height-enter,
.height-leave-to {
    opacity: 0;
    max-height: 0px;
}

.background-grey {
    background-color: #424242;
}
</style>
