<template>
    <div :id="anchorId" class="mt-2 mb-3">
        <ScRichText
            v-if="isExperienceEditor || haveDescriptionValue"
            class="body-default text-grey background-to-change"
            :field="fields.description"
        />
    </div>
</template>

<script>
import { RichText } from '@sitecore-jss/sitecore-jss-vue';

export default {
    name: 'Paragraph',
    components: {
        ScRichText: RichText,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        haveDescriptionValue() {
            return !!this.fields?.description?.value;
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep a {
    text-decoration: underline;
    font-weight: 600;
}
</style>
