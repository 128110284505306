<template>
    <div v-if="items.length || isExperienceEditor" :id="anchorId" :data-e2e-component-uid="rendering.uid">
        <h5 v-if="sidebar" class="mb-3">{{ title }}</h5>

        <div :class="sidebar && 'd-flex flex-column gap-3'">
            <router-link
                v-for="item in items"
                :key="item.id"
                :to="item.link"
                class="card"
                :class="{ 'card--sidebar': sidebar }"
            >
                <StaticImage
                    v-if="sidebar"
                    class="card__image"
                    :src="item.image.url"
                    :alt="item.image.alt"
                    :srcSet="[{ mw: 300 }, { mw: 400 }, { mw: 500 }, { mw: 600 }]"
                    sizes="
                        (max-width: 991px) 210px,
                        (max-width: 1199px) 25vw,
                        270px
                    "
                />
                <div class="p-3 background-digital-deep-grey">
                    <div v-if="item.name" :class="sidebar ? 'footnote' : 'section-header-small'">
                        {{ getSubtitle(item.name) }}
                    </div>
                    <div v-if="item.title" class="mt-1 d-flex justify-content-between align-items-center gap-2">
                        <div class="card__link">{{ item.title }}</div>
                        <svg
                            v-if="sidebar"
                            viewBox="0 0 36 36"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="card__icon"
                            aria-hidden="true"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M20.5843 12.2386L25.7617 17.4239C26.0794 17.7421 26.0794 18.2579 25.7617 18.5761L20.5843 23.7614C20.2665 24.0795 19.7514 24.0795 19.4337 23.7614C19.116 23.4432 19.116 22.9273 19.4337 22.6091L23.2223 18.8148H10.8148C10.3648 18.8148 10 18.45 10 18C10 17.55 10.3648 17.1852 10.8148 17.1852H23.2223L19.4337 13.3909C19.116 13.0727 19.116 12.5568 19.4337 12.2386C19.7514 11.9205 20.2665 11.9205 20.5843 12.2386Z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
import StaticImage from '@/components/Generic/SitecoreWrappers/StaticImage';

export default {
    name: 'RelatedBlock',

    components: {
        StaticImage,
    },

    props: {
        rendering: {
            type: Object,
            default: () => ({}),
        },
        title: {
            type: String,
            default: '',
        },
        items: {
            type: Array,
            default: () => [],
        },
        sidebar: {
            type: Boolean,
            default: true,
        },
        helpArticle: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        getSubtitle(name) {
            return this.helpArticle ? this.$t('relatedHelpPlatformTitle').replace('{token.Platform}', name) : name;
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';

.background-digital-deep-grey {
    color: var(--abrdn-white);
}

.card {
    display: block;
}

.card__link {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.02em;

    .card:hover &,
    .card:focus & {
        text-decoration: underline;
    }

    .card--sidebar & {
        @include media-breakpoint-down(xl) {
            font-size: 18px;
        }

        @include media-breakpoint-down(md) {
            line-height: 24px;
        }
    }
}

.card__image {
    width: 100%;
    height: 140px;
    object-fit: cover;

    @include media-breakpoint-up(md) {
        height: 109px;
    }
}

.card__icon {
    width: 1em;
    height: 1em;
    font-size: calc(var(--spacer) * 4.5);
}
</style>
