<template>
    <div
        class="flex-column d-flex align-items-start dropdown-section-root"
        @keydown.esc="$emit('toggleDropdown', undefined)"
    >
        <button
            class="d-flex align-items-center justify-content-between expandable w-100 dropdown-button btn btn-naked"
            :aria-expanded="isOpen ? 'true' : 'false'"
            @click="toggleDropdown"
        >
            <span class="h5">{{ filter.displayName }}</span>
            <span class="ms-auto"><em class="d-flex bi-chevron-down" aria-hidden="true" /></span>
        </button>
        <div ref="dropdownContent" class="dropdown__content background-white w-100">
            <div class="filter-dropdown-options-wrapper d-flex flex-column w-100">
                <MobileBaseFiltersDropdownOption
                    v-for="filterOption in filterOptions"
                    :key="filterOption.id"
                    :filterOption="filterOption"
                    :isVisible="isOpen"
                    :isLoading="isLoading"
                    @optionSelect="(option) => $emit('optionSelect', option)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { gsap } from 'gsap';

import MobileBaseFiltersDropdownOption from '@/components/Functional/Filters/MobileFilter/MobileBaseFiltersDropdownOption.vue';
import { filterOptions } from '@/mixins/ArticleFilter/FiltersDropdownHelper.mixin';

export default {
    name: 'MobileBaseFiltersDropdown',
    components: {
        MobileBaseFiltersDropdownOption,
    },
    mixins: [filterOptions],
    props: {
        filter: {
            type: Object,
            default: () => ({}),
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        isOpen: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            animation: null,
        };
    },
    watch: {
        isOpen: {
            handler: function (val, oldVal) {
                if (val === oldVal) return;
                if (val) {
                    this.animation.play();
                } else {
                    this.animation.reverse();
                }
            },
        },
    },
    mounted() {
        this.animation = gsap.fromTo(
            this.$refs.dropdownContent,
            { height: 0 },
            { height: 'auto', paused: true, duration: 0.3 },
        );
    },
    methods: {
        toggleDropdown() {
            this.$emit('toggleDropdown', this.filter.id);
        },
        onOptionSelect(option) {
            this.$emit('optionSelect', option);
        },
    },
};
</script>

<style scoped lang="scss">
.dropdown-section-root {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.dropdown-button {
    padding: 26px 24px 26px 16px;
    cursor: pointer;
    &.disabled,
    &.disabled {
        ::v-deep .checkbox-wrapper {
            cursor: default;
        }
    }
}
.dropdown__content {
    height: 0;
    overflow: hidden;
}
</style>
