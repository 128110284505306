<template>
    <div class="flex-column d-flex align-items-start accordion-item mb-1">
        <button
            class="btn btn-naked text-link d-flex align-items-center justify-content-between w-100"
            :aria-expanded="show ? 'true' : 'false'"
            @click="show = !show"
        >
            <ScText tag="h3" :field="title" class="form-label accordion-title" />
            <span class="ms-auto"><em class="d-flex bi-chevron-down" aria-hidden="true" /></span>
        </button>
        <ExpandableContent :show="show">
            <div class="background-white pb-1 px-2">
                <ScRichText :field="description" class="body-small d-flex flex-column accordion-description" />
                <slot />
            </div>
        </ExpandableContent>
    </div>
</template>

<script>
import { RichText, Text } from '@sitecore-jss/sitecore-jss-vue';

import ExpandableContent from '@/components/Generic/ExpandableContent';

export default {
    name: 'AccordionDefault',
    components: {
        ExpandableContent,
        ScRichText: RichText,
        ScText: Text,
    },
    props: {
        title: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        description: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    data() {
        return {
            show: false,
        };
    },
};
</script>

<style scoped lang="scss">
.accordion-item {
    border: 2.3px solid var(--abrdn-black);
    border-radius: 0;
}

.btn {
    z-index: 1;
    padding: calc(var(--spacer) * 1) calc(var(--spacer) * 2);
}

.btn:focus-visible {
    outline: 3px solid var(--abrdn-black);
}

.accordion-description,
.accordion-title {
    text-align: left;
}

.accordion-description ::v-deep p {
    white-space: pre-line;
    margin-bottom: 16px;
}

.accordion-description ::v-deep a {
    font-weight: 600;
    text-decoration: underline !important;
    cursor: pointer;
}

.accordion-description ::v-deep p:last-child {
    margin-bottom: 0;
}

.bi-chevron-down {
    transform: rotate(180deg);
    transition: 0.2s linear;
}

[aria-expanded='true'] .bi-chevron-down {
    transform: rotate(0deg);
}

.bi-chevron-down {
    &:before {
        font-weight: 600 !important;
    }
}
</style>
