<template>
    <div :id="anchorId" class="w-100">
        <div v-show="!loading" id="component-container" ref="container" />
        <div v-show="loading" class="loader-wrapper justify-content-center w-100">
            <Loader :size="64" class="loader" />
        </div>
    </div>
</template>

<script>
import axios from 'axios';

import Loader from '@/components/Generic/Loader';

require('iframe-resizer/js/iframeResizer.contentWindow.min');
export default {
    name: 'MorningstarDataFeed',
    components: {
        Loader,
    },
    props: {
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    data() {
        return {
            loading: true,
            resizeObserver: undefined,
            mutationObserver: undefined,
            heightUpdateIntervalId: undefined,
        };
    },
    watch: {
        $route(to, from) {
            if (from.hash.length > to.hash.length) {
                this.$router.go(-2);
            }
        },
    },
    async mounted() {
        // const styleElement = document.createElement('style');
        // styleElement.innerHTML = '#onetrust-consent-sdk { display: none; }';
        // document.head.appendChild(styleElement);

        if ('parentIFrame' in window) {
            parentIFrame.autoResize(false);
        }
        this.observeHeight();
        this.observeDOM();
        this.heightUpdateIntervalId = setInterval(() => {
            this.resizeHandler();
        }, 3000);
        const morningstarScript = document.createElement('script');
        const jQueryScript = document.createElement('script');
        let APP_CONFIG = {};
        const ENV = this.fields.dataFeedConfiguration.value.env;
        const sharesFromSecurityDeatails = [];
        let shares = [];
        const fundId = this.fields.fundId.value;
        let isOnlyShare = false;
        const setLoading = (value) => {
            this.loading = value;
        };
        window.location.hash = `#?id=${fundId}`;
        async function getShareSecurityIds() {
            let apiUrl =
                'https://eultrcqa.morningstar.com/api/rest.svc/aytmsh33fr/security_details/{{secId}}?viewId=ITsnapshot&idtype=msid&languageId=en-GB&currencyId=GBP&responseViewFormat=json';
            const securityId = fundId;
            apiUrl = apiUrl.replace('{{secId}}', fundId);
            const holdings = [];
            await axios.get(apiUrl).then((response) => {
                const res = response.data;
                if (typeof res[0].Cef.Shares !== 'undefined') {
                    if (res[0].Cef.Shares.length == 1 && res[0].Cef.Shares[0].ClassSecId == securityId) {
                        isOnlyShare = true;
                    } else {
                        isOnlyShare = false;
                        res[0].Cef.Shares.forEach(function (share) {
                            if (share.InstrType != 'W') {
                                const id = share.ClassSecId;
                                sharesFromSecurityDeatails.push(share);
                                holdings.push(id);
                            }
                        });
                    }
                } else {
                    isOnlyShare = true;
                }
            });
            return holdings.join('|');
        }
        async function getShareDetails(ids) {
            let apiUrl =
                'https://eultrcqa.morningstar.com/api/rest.svc/aytmsh33fr/securities_details?ids={{secIds}}&idType=msid&viewIds=ITShareDetails&currencyId=GBP&language=en-GB&countryId=GBR&responseViewFormat=json';
            let shares = [];
            apiUrl = apiUrl.replace('{{secIds}}', ids);
            await axios.get(apiUrl).then((res) => {
                shares = res.data;
            });
            return shares;
        }
        async function updateShares() {
            const ids = await getShareSecurityIds();
            let shares = [];
            if (isOnlyShare == true) {
                APP_CONFIG = {
                    components: {
                        ecSecurityReportLoader: {
                            type: 'ecSecurityReportLoader',
                            components: {
                                closedEndFund: {
                                    type: 'ecSecurityReport',
                                    components: {
                                        containerSecurityReport: {
                                            type: 'ecContainer',
                                            settings: {
                                                breakpoints: {
                                                    defaults: {
                                                        groups: {
                                                            subShares: {
                                                                cols: 0,
                                                            },
                                                        },
                                                    },
                                                },
                                                sections: {
                                                    subSharesSection: {
                                                        breakpoints: {
                                                            defaults: {
                                                                cols: 0,
                                                            },
                                                        },
                                                    },
                                                },
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                };
            } else {
                APP_CONFIG = {};
                shares = await getShareDetails(ids);
                if (shares.length) {
                    for (let i = 0; i < shares.length; i++) {
                        shares[i] = { ...shares[i], ...sharesFromSecurityDeatails[i] };
                    }
                }
            }
            return shares;
        }
        shares = await updateShares();
        const loaderConfig = {
            dynamic: true,
            lazy: {
                lazyLoadComponent: true,
                bundlesToDownload: {
                    ecSecurityReportLoader: true,
                },
            },
            api: {
                apiTokenExpiredCallback: function (options) {
                    console.log(options);
                },
            },
            angular: {
                bootstrap: false,
            },
            apiTokens: {
                apiGatewayToken: this.fields.dataFeedConfiguration.value.access_token,
            },
            configuration: {
                source: {
                    type: 'config-api',
                    environment: ENV,
                },
                environment: ENV,
                namespace: this.fields.dataFeedConfiguration.value.namespace,
                overrides: window.appConfig || APP_CONFIG,
            },
            css: {
                includeComponentCss: true,
                mds: {
                    style: true,
                    icons: true,
                    version: '2.4.0',
                },
                // customStyles: ['/-/media/aam-web/files/Styling/MorningstarReport/client'],
            },
            componentSource: {
                environment: ENV,
                type: 'mwc',
                instid: 'AAML',
            },
            instrumentation: {
                providers: [
                    {
                        provider: 'console',
                        level: 'fatal|error|warn',
                    },
                ],
            },
            runtime: {
                environment: ENV,
            },
        };

        morningstarScript.onload = async () => {
            const { morningstar } = window;

            morningstar.loader
                .load(loaderConfig)
                .then(function (manifest) {
                    const { morningstar } = window;
                    morningstar.initComponent('ecSecurityReportLoader', {
                        container: document.getElementById('component-container'),
                    });
                    angular.module('MorningstarApp', morningstar.asterix.ng.getAngularModuleDependencies());
                    angular.bootstrap(
                        window.bootstrapElementId ? document.getElementById(window.bootstrapElementId) : document,
                        ['MorningstarApp'],
                    );
                    morningstar.asterix.instanceRegistry
                        .get('ecSecurityReportLoader')
                        .setModelProperty('shares', shares);
                    window.loaderCallback = window.loaderCallback || window.onComponentLoad;
                    if (typeof window.loaderCallback === 'function') {
                        // Implement this using a custom JS file
                        window.loaderCallback(manifest);
                    }
                    morningstar.asterix.ng.transforms.register('ecSecurityReport.LegalNameInChart', function (data) {
                        if (!data || (morningstar.asterix.util.isArray(data) && !data.length)) {
                            return {};
                        }
                        data.securityToken.name = data.legalName;
                        return data;
                    });
                    let securityCurrency = '';
                    morningstar.asterix.ng.transforms.register(
                        'ecSecurityReport.customFromSecurityDetailsToDividendsKeyStats',
                        function (data) {
                            if (!data || (morningstar.asterix.util.isArray(data) && !data.length)) {
                                return {};
                            }
                            securityCurrency = data[0].currencyId;
                            if (securityCurrency === 'GBP') {
                                data[0].amount *= 100; // convert to pence
                            }
                            return data;
                        },
                    );
                    morningstar.asterix.ng.transforms.register(
                        'ecSecurityReport.customfromSecurityDetailsToSharedDividends',
                        function (data) {
                            if (!data || (morningstar.asterix.util.isArray(data) && !data.length)) {
                                return {};
                            }
                            if (securityCurrency === 'GBP') {
                                if (data.tableData && data.tableData.length > 0) {
                                    data.tableData.forEach(function (val, index, array) {
                                        array[index].dividendPerShare *= 100;
                                    });
                                }
                                if (
                                    data.chartData &&
                                    data.chartData.series &&
                                    data.chartData.series[0] &&
                                    data.chartData.series[0].data &&
                                    data.chartData.series[0].data.length > 0
                                ) {
                                    data.chartData.series[0].data.forEach(function (val, index, array) {
                                        array[index] *= 100;
                                    });
                                }
                            }
                            return data;
                        },
                    );
                    morningstar.asterix.ng.transforms.register(
                        'ecSecurityReport.fromSecurityDetailsToKeyStats',
                        function (data) {
                            const labels = morningstar.asterix.instanceRegistry
                                .get('ecSecurityReportLoader')
                                .getLabels();
                            if (!data || data.length === 0) {
                                return {};
                            }
                            switch (data.cef.dividendFrequency) {
                                case 'Q':
                                    data.cef.dividendFrequency = labels.dividendFrequencyQuarterly;
                                    break;
                                case 'S':
                                    data.cef.dividendFrequency = labels.dividendFrequencySemiAnnually;
                                    break;
                                case 'A':
                                    data.cef.dividendFrequency = labels.dividendFrequencyAnnually;
                                    break;
                                case undefined:
                                case 'N':
                                    data.cef.dividendFrequency = labels.dividendFrequencyNone;
                                    break;
                                case 'M':
                                    data.cef.dividendFrequency = labels.dividendFrequencyMonthly;
                                    break;
                                default:
                                    break;
                            }
                            const formatter = this.context.instance.getFormatter();
                            data.averageDailyValueTraded1YrCurrencyLabel =
                                this.context.labels.get('averageDailyValueTraded1Yr') +
                                ' (' +
                                formatter.currencySymbol(data.currency.Id, 'symbol') +
                                'm)';
                            data.lastActualNAVWithTraigingCurrencyLabel =
                                this.context.labels.get('lastActualNAV') +
                                ' (' +
                                formatter.currencySymbol(data.tradingCurrency.Id, 'symbol') +
                                ')';
                            data.marketCapWithCurrencyLabel =
                                this.context.labels.get('marketCap') +
                                ' (' +
                                formatter.currencySymbol(data.currency.Id, 'symbol') +
                                ')';
                            data.netAssetsWithCurrencyLabel =
                                this.context.labels.get('netAssets') +
                                ' (' +
                                formatter.currencySymbol(data.currency.Id, 'symbol') +
                                ')';
                            data.grossAssetsCumWithCurrencyLabel =
                                this.context.labels.get('grossAssetsCum') +
                                ' (' +
                                formatter.currencySymbol(data.currency.Id, 'symbol') +
                                ')';
                            data.nAVHighM12WithTraigingCurrencyLabel =
                                this.context.labels.get('nAVHighM12') +
                                ' (' +
                                formatter.currencySymbol(data.tradingCurrency.Id, 'symbol') +
                                ')';
                            data.nAVLowM12WithTraigingCurrencyLabel =
                                this.context.labels.get('nAVLowM12') +
                                ' (' +
                                formatter.currencySymbol(data.tradingCurrency.Id, 'symbol') +
                                ')';
                            return data;
                        },
                    );
                    const morningstarRating = document.createElement('span');

                    morningstarRating.innerHTML = 'Morningstar Rating';
                    const morningstarSVG = document.createElement('span');

                    morningstarSVG.innerHTML = `<svg width="24" height="24" viewBox="0 0 108 109" fill="none" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" class="ms-1">
                        <path
                            d="M28.3501 61.8575L25.6838 61.7213V44.7581L30.2738 44.5537C30.9826 44.5197 31.6576 44.6219 32.2651 44.8262C32.9063 45.0306 33.4463 45.3712 33.8851 45.8141C34.3238 46.2569 34.6951 46.8359 34.9313 47.4831C35.1676 48.1303 35.3026 48.8797 35.3026 49.6972C35.3026 50.5147 35.1676 51.2641 34.9313 51.9453C34.6951 52.5925 34.3238 53.1716 33.8851 53.6484C33.4463 54.0912 32.9063 54.4659 32.2651 54.6703C31.6576 54.9087 30.9488 54.9769 30.3076 55.0109H28.3501V61.8575ZM28.3501 47.5853V52.0816H30.3076C30.9488 52.0816 31.4551 51.8772 31.8938 51.4684C32.2988 51.0597 32.5013 50.5147 32.5013 49.7313C32.5013 48.9478 32.2988 48.4028 31.8938 48.0281C31.4888 47.6534 30.9826 47.4831 30.3413 47.5172L28.3501 47.5853Z"
                            fill="black"
                        />
                        <path
                            d="M42.3225 62.5388C42.1875 62.5388 42.0863 62.5388 41.9513 62.5388L36.6863 62.3003V44.2812L41.9175 44.0428C43.0988 43.9747 44.1788 44.1791 45.225 44.6219C46.2713 45.0647 47.1825 45.6778 47.9588 46.5294C48.735 47.3469 49.3763 48.3687 49.815 49.5269C50.2538 50.685 50.49 51.9794 50.49 53.3419C50.49 54.7044 50.2538 55.9647 49.815 57.1228C49.3763 58.2809 48.735 59.2687 47.9588 60.1203C47.1825 60.9378 46.2713 61.585 45.225 62.0278C44.3138 62.3344 43.335 62.5388 42.3225 62.5388ZM39.5888 59.3369L41.9175 59.4391C42.6938 59.4391 43.3688 59.3369 44.01 59.0303C44.6513 58.7238 45.225 58.315 45.6975 57.77C46.17 57.225 46.575 56.5437 46.845 55.7944C47.115 55.0109 47.2838 54.1594 47.2838 53.2738C47.2838 52.3881 47.1488 51.5366 46.845 50.7531C46.575 49.9697 46.2713 49.1862 45.6975 48.7775C43.875 47.4491 43.4363 47.1084 44.01 47.5172C44.55 47.9259 42.66 47.1084 41.9175 47.1425L39.5888 47.2106V59.3369Z"
                            fill="black"
                        />
                        <path
                            d="M56.2613 63.22L52.9875 63.0497V43.4978L64.0238 42.9869V46.2909L56.2613 46.5634V51.2981L63.585 51.23V54.5H56.2613V63.22Z"
                            fill="black"
                        />
                        <path
                            d="M90.5175 32.3253L82.8563 23.4691C82.2825 22.8219 81.4388 22.4813 80.5613 22.5494C80.46 22.5494 80.3925 22.4813 80.2913 22.4813H73.305C73.2713 19.3134 71.1788 16.8269 68.2425 16.8269L21.3975 20.4716C18.6975 20.4716 16.5713 22.9922 16.5713 26.1941V82.8741C16.5713 86.0759 18.6638 88.5966 21.1275 88.5966L68.4788 92.1731C70.8075 92.1731 72.7313 90.2656 73.17 87.6769H87.75C89.6063 87.6769 91.125 86.1441 91.125 84.2706V34.0284C91.125 33.4153 90.9563 32.8022 90.5175 32.3253ZM66.5213 85.1903L23.3213 81.9203V27.1478L66.5213 23.8097V85.1903ZM73.2713 80.8644V29.2938H78.0638V33.9944C78.0638 34.6756 78.3338 35.3228 78.8738 35.7997C79.38 36.2766 80.055 36.515 80.7638 36.515H80.7975L84.375 36.4809V80.8644H73.2713Z"
                            fill="black"
                        />
                    </svg>`;
                    setTimeout(() => {
                        const rating = document.getElementsByClassName('ec-security-header-ratings__stars');
                        const exportButton = document.getElementsByClassName('mstar-action-export-to-pdf');
                        const iconText = document.getElementsByClassName('ec-security-header-analyst-rating__text');
                        iconText.length && (iconText[0].innerText = iconText[0].innerText.split(':')[0] + ': ');
                        exportButton.length && exportButton[0].childNodes[1].appendChild(morningstarSVG.firstChild);

                        rating.length && rating[0].prepend(morningstarRating);
                    }, 1000);
                })
                .catch(function (error) {
                    window.console.log(error);
                });
        };
        jQueryScript.src = '//ajax.googleapis.com/ajax/libs/jquery/2.1.4/jquery.min.js';
        morningstarScript.src =
            '//mwc-cdn.morningstar.com/lib/ec-component-loader/0.8.1/dist/ec-component-loader.min.js';
        document.getElementsByTagName('head')[0].appendChild(jQueryScript);
        document.getElementsByTagName('head')[0].appendChild(morningstarScript);
        setLoading(false);
    },
    beforeDestroy() {
        this.resizeObserver.unobserve(this.$refs.container);
        this.mutationObserver.disconnect();
        clearInterval(this.heightUpdateIntervalId);
    },
    methods: {
        resizeHandler() {
            this.$nextTick(() => {
                if ('parentIFrame' in window) {
                    parentIFrame.size(this.$refs.container.getBoundingClientRect().height);
                }
            });
        },
        observeHeight() {
            this.resizeObserver = new ResizeObserver(this.resizeHandler);
            this.resizeObserver.observe(this.$refs.container);
        },
        observeDOM() {
            const config = {
                attributes: true,
                childList: true,
                subtree: true,
            };
            this.mutationObserver = new MutationObserver(this.resizeHandler);
            this.mutationObserver.observe(this.$refs.container, config);
        },
    },
};
</script>

<style lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
.loader-wrapper {
    display: flex;
}
.loader {
    margin-top: 64px;
}
.ec-security-header-analyst-rating__icon {
    padding: 0 !important;
    span {
        position: static;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.375;
    }
    &:after {
        $fallback-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
        font-family: 'Morningstar Symbols', 'SharpSans', $fallback-font;
        @at-root html[lang^='th-'] & {
            font-family: 'Morningstar Symbols', 'Prompt', $fallback-font;
        }
    }
}
#component-container {
    *:not(.mkts-cmpt-svgcht-menubtn):not(.mkts-cmpt-svgcht-dropdown):not(.mkts-cmpt-svgcht-menuicn)
    // :not(.ec-security-header-analyst-rating__icon)
    {
        font-family: var(--main-font) !important;
        @at-root html[lang^='th-'] & {
            font-family: var(--main-font-thai) !important;
        }
        &::before {
            $fallback-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
            font-family: 'Morningstar Symbols', 'SharpSans', $fallback-font;
            @at-root html[lang^='th-'] & {
                font-family: 'Morningstar Symbols', 'Prompt', $fallback-font;
            }
        }
    }
    .mkts-cmpt-svgcht-menubtn {
        display: flex;
        align-items: center;
    }
    table {
        ::before {
            font-family: var(--main-font) !important;
            @at-root html[lang^='th-'] & {
                font-family: var(--main-font-thai) !important;
            }
        }
    }
    .ng-biding.ng-scope {
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        letter-spacing: 0.04em !important;
        color: #555555 !important;
    }
    .ec-section__header.ec-section__header--collapsible,
    .ec-action-container__list-item.ng-scope {
        .mds-button {
            height: 48px;
            display: inline-flex;
            align-items: center;
            border-radius: 24px;
            transition: background-color 0.2s, color 0.2s, opacity 0.2s;
            padding: 0 calc(var(--spacer) * 4);
            outline: 0;
            border: 1px solid var(--button-black);
            color: var(--button-black);
            box-shadow: none;
            span {
                font-weight: 600;
            }
            &:after {
                display: none;
            }
            &:hover {
                color: var(--button-white);
                background: var(--button-night);
                border: 1px solid var(--button-night);
            }
        }
        // .mds-button {
        //     background-color: var(--button-black);
        //     color: var(--abrdn-white);
        //     border: none;
        // }
        :focus,
        .mds-button:focus {
            outline: var(--abrdn-black) solid 2px;
            box-shadow: none;
        }

        :focus:not(:focus-visible) {
            outline: 0;
        }
    }
    .ec-security-quote-quote__main.ng-scope {
        display: flex;
        flex-wrap: wrap;
    }
    .ec-security-quote-quote__main-items {
        display: flex;
        flex-direction: column;
        // width: auto;
        min-width: calc(32% - 20px);
        margin-right: 20px;
        margin-bottom: 32px;
        // margin-right: 20px;
        .ec-security-quote-quote__price-change-value:before,
        .ec-security-quote-quote__price-change-value:after {
            font-size: 31px;
        }
        span:first-child {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #555555;
            margin-bottom: 8px;
        }
        span:last-child {
            font-weight: 600;
            font-size: 28px;
            line-height: 32px;
            letter-spacing: 0.01em;
            // color: var(--abrdn-black);
        }

        @include media-breakpoint-down(md) {
            min-width: 50%;
            margin-right: 0px;
        }
        @include media-breakpoint-down(sm) {
            min-width: 100%;
            margin-right: 0px;
        }
    }
    .ec-section__header.ec-section__header--collapsible {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .ec-section__title.ec-section__title--key-stats {
        display: flex;
        align-items: center;
    }
    .ms-neat-row > div > section {
        margin: 0 !important;
    }
    .ec-section__content.ec-section__content--expanded {
        padding-bottom: 16px;
    }
    .ng-isolate-scope.mstar-security-header.ec-security-header.ec-component {
        background: var(--primary);
        color: var(--abrdn-white);
        span {
            color: var(--abrdn-white);
        }
        .mds-button {
            background: var(--abrdn-white);
            padding: 0 calc(var(--spacer) * 3);
            &:hover {
                color: var(--button-black) !important;
                background: var(--button-mist);
            }
        }
    }
    .ec-security-header-title.ng-scope.ng-isolate-scope.ec-section.ec-section--main-title {
        @include media-breakpoint-down(md) {
            padding: 0 !important;
        }
    }
    .ec-section__content.ec-section__content.ec-section__content--title.ec-section__content--expanded {
        display: flex;
        padding-top: 16px;
        @include media-breakpoint-down(md) {
            flex-direction: column;
        }
        > .ms-neat-row:first-child {
            display: flex;
            justify-content: space-between;
            width: 100%;
            > * {
                padding: 0;
            }
            @include media-breakpoint-down(md) {
                padding-right: 15px;
                padding-left: 15px;
                flex-direction: column;
                > div {
                    width: 100% !important;
                }
            }
            > .ms-neat-row:nth-child(2) {
                margin-left: 16px;
                @include media-breakpoint-down(md) {
                    margin-left: 0px;
                    padding: 0;
                    > * {
                        padding: 0 !important;
                    }
                }
            }
            > .ms-neat-row:nth-child(3) {
                margin-left: auto;
                @include media-breakpoint-down(md) {
                    margin-left: 0px;
                    > * {
                        padding: 0 !important;
                        > * {
                            padding: 0 !important;
                        }
                    }
                }
            }
        }
        > .ms-neat-row:last-child {
            display: none;
        }
    }
    .ec-section--fees-and-expenses,
    .ec-section--sub-shares {
        display: none;
    }
    .ec-security-header-ratings__stars {
        display: flex;
        flex-direction: column-reverse;
    }
}
</style>
