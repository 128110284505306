export const formFieldsStep3 = {
    rows: [
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                            element: {
                                type: 'FormFieldGroup',
                                id: '334',
                                depth: 'complete',
                                name: 'Individually managed accounts (step 3 of 7)',
                                instructions: '',
                                style: {
                                    textType: 'header',
                                    fieldSize: 'large',
                                    guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                                },
                                unique: true,
                            },
                        },
                    ],
                },
            ],
            type: 'FormFieldGroup',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '00N6900000Kw00Y',
                            element: {
                                type: 'FormField',
                                id: 'Access_to_Individually_Managed_Accts_fun__c',
                                name: 'Will you require access to individually Managed Accounts functionality (Tailored Portfolio Service)?',
                                instructions: '',
                                fieldDescription: '',
                                relatesTo: {
                                    value: 'Individually managed accounts',
                                },
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kw00Y',
                                },
                                dataType: 'text',
                                displayType: 'radio',
                                htmlName: '00N6900000Kw00Y',
                                optionListId: '202',
                                validations: [
                                    {
                                        type: 'FieldValidation',
                                        id: '19608',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventUrlCondition' },
                                        isEnabled: 'true',
                                        message: "Value must not contain any URL's",
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19609',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventXSSCondition' },
                                        isEnabled: 'true',
                                        message: 'Value must not contain any HTML',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19610',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'IsRequiredCondition' },
                                        isEnabled: 'true',
                                        message: 'This field is required',
                                    },
                                ],
                                optionList: {
                                    type: 'OptionList',
                                    id: '202',
                                    name: 'Arranging deals in investments',
                                    elements: [
                                        {
                                            type: 'Option',
                                            displayName: 'Yes',
                                            value: 'Yes',
                                        },
                                        {
                                            type: 'Option',
                                            displayName: 'No',
                                            value: 'No',
                                        },
                                    ],
                                },
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
        {
            cols: [
                {
                    width: 12,
                    cells: [
                        {
                            guid: '00N6900000Kw00Y',
                            element: {
                                type: 'FormField',
                                id: 'Tailored_portfolio_option__c',
                                name: 'When all target funds have been excluded from an asset class, which tailored portfolio substitution strategy should be applied?',
                                instructions: '',
                                fieldDescription: '',
                                conditionalField: true,
                                conditionTargetField: 'Access_to_Individually_Managed_Accts_fun__c',
                                conditionTargetValue: 'Yes',
                                relatesTo: {
                                    value: 'Individually managed accounts',
                                },
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kw00Y',
                                },
                                dataType: 'text',
                                displayType: 'radio',
                                htmlName: '00N6900000Kw00Y',
                                optionListId: '202',
                                validations: [
                                    {
                                        type: 'FieldValidation',
                                        id: '19608',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventUrlCondition' },
                                        isEnabled: 'true',
                                        message: "Value must not contain any URL's",
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19609',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventXSSCondition' },
                                        isEnabled: 'true',
                                        message: 'Value must not contain any HTML',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19610',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'IsRequiredCondition' },
                                        isEnabled: 'true',
                                        message: 'This field is required',
                                    },
                                ],
                                optionList: {
                                    type: 'OptionList',
                                    id: '202',
                                    name: 'Arranging deals in investments',
                                    elements: [
                                        {
                                            type: 'Option',
                                            displayName: 'Nominate a replace asset class',
                                            value: 'Nominate a replace asset class',
                                        },
                                        {
                                            type: 'Option',
                                            displayName: 'Proportionately across all other target funds',
                                            value: 'Proportionately across all other target funds',
                                        },
                                    ],
                                },
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
    ],
};
