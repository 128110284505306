<template>
    <section>
        <div v-if="isFundDetailsImportantInfoAvailable" class="py-4 background-island text-white">
            <div class="container">
                <h5>{{ $t('fundDetailsImportantInformation') }}</h5>
                <div class="mt-3">
                    <FundDetailsTabSkeleton v-if="loading" />
                    <AlertMessageBase v-else-if="error" type="error" :title="$t(error)" />
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <p v-else-if="data" class="body-small" v-html="data" />
                    <div v-else class="body-small">{{ $t('noResultsMessage') }}</div>
                </div>
            </div>
        </div>
        <EEPlaceholder v-if="isExperienceEditor">Fund Details Important Information</EEPlaceholder>
    </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import AlertMessageBase from '@/components/Generic/AlertMessageBase';
import EEPlaceholder from '@/components/Sections/EEPlaceholder';

import FundDetailsTabSkeleton from './FundDetailsTabSkeleton';

export default {
    name: 'FundDetailsImportantInfo',
    components: {
        AlertMessageBase,
        EEPlaceholder,
        FundDetailsTabSkeleton,
    },
    computed: {
        ...mapState('fundDetails', {
            loading: (state) => state.fundDetailsImportantInformationData.loading,
            error: (state) => state.fundDetailsImportantInformationData.error,
            data: (state) => state.fundDetailsImportantInformationData.data,
            isFundDetailsImportantInfoAvailable: 'isFundDetailsImportantInfoAvailable',
            correlationFundRangeID: 'correlationFundRangeID',
        }),
        ...mapGetters('fundDetails', ['selectedShareClass']),
    },
    watch: {
        selectedShareClass: {
            handler: function () {
                if (this.isFundDetailsImportantInfoAvailable) {
                    this.fetchData();
                }
            },
        },
    },
    methods: {
        ...mapActions('fundDetails', ['fetchFundDetailsImportantInformation']),
        async fetchData() {
            if (this.isExperienceEditor) return;

            const {
                fundsConfiguration: {
                    countryInvestors: { countryCode },
                },
                displayLanguage,
                site: { name: siteName },
            } = this.$jss.sitecoreContext();

            await this.fetchFundDetailsImportantInformation({
                shareClass: this.selectedShareClass?.id,
                language: displayLanguage,
                countryCode: countryCode,
                correlationFundRangeID: this.correlationFundRangeID,
                site: siteName,
            });
        },
    },
};
</script>
