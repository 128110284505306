<template>
    <section>
        <div class="container">
            <div ref="wrapper" class="d-flex justify-content-between gap-4.5">
                <div ref="visibleTabsWrapper" class="d-flex gap-4.5 flex-grow-1 overflow-hidden">
                    <button
                        v-for="tab in visibleTabs"
                        :key="tab.key"
                        class="tab-button text-nowrap"
                        :class="{
                            'tab-button--active': tab.key === activeTabKey,
                            'tab-button--only': visibleTabs.length === 1,
                        }"
                        @click="$emit('tabClick', tab.key)"
                    >
                        {{ tab.title }}
                    </button>
                </div>

                <StateDrivenSlotDropdown
                    v-if="renderDropdown"
                    class="dropdown dropdown--content-right"
                    buttonsLayout="justify-content-between"
                    :class="{ 'dropdown--expanded': isDropdownOpen }"
                    :isOpen="isDropdownOpen"
                    @outsideClick="closeDropdown"
                >
                    <template #trigger-area>
                        <button class="dropdown__button d-flex align-items-center gap-1" @click="toggleDropdown">
                            <span class="text-link text-nowrap">{{ $t('viewMore') }}</span>
                            <IconChevronSecondLevelTabs class="icon icon--chevron" />
                        </button>
                    </template>

                    <div class="dropdown__panel py-1">
                        <button
                            v-for="tab in hiddenTabs"
                            :key="tab.key"
                            class="dropdown__tab-button p-2 button-label text-nowrap"
                            @click="onDropdownTabButtonClick(tab.key)"
                            @focus="openDropdown"
                            @blur="closeDropdown"
                        >
                            {{ tab.title }}
                        </button>
                    </div>
                </StateDrivenSlotDropdown>
            </div>

            <div class="divider" />
        </div>
    </section>
</template>

<script>
import debounce from 'lodash.debounce';

import StateDrivenSlotDropdown from '@/components/Generic/StateDrivenSlotDropdown';
import IconChevronSecondLevelTabs from '@/components/Icons/icon-chevron-second-level-tabs';

export default {
    name: 'SecondLevelTabs',

    components: {
        StateDrivenSlotDropdown,
        IconChevronSecondLevelTabs,
    },

    props: {
        tabs: {
            type: Array,
            default: () => [],
        },
        activeTabKey: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            renderDropdown: false,
            sortedTabs: [...this.tabs],
            visibleTabs: [...this.tabs],
            hiddenTabs: [],
            isDropdownOpen: false,
        };
    },

    computed: {
        onResize() {
            return debounce(this.updateDropdown, 500);
        },
    },

    watch: {
        activeTabKey() {
            this.updateDropdown();
        },
    },

    mounted() {
        this.updateDropdown();
        window.addEventListener('resize', this.onResize);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },

    methods: {
        updateDropdown() {
            this.renderDropdown = false;
            this.visibleTabs = [...this.sortedTabs];
            this.hiddenTabs = [];

            this.$nextTick(() => {
                const { wrapper, visibleTabsWrapper } = this.$refs;

                if (visibleTabsWrapper.scrollWidth > wrapper.offsetWidth) {
                    this.renderDropdown = true;

                    this.$nextTick(() => {
                        let index = Array.from(visibleTabsWrapper.children).findIndex((tab) => {
                            const tabOffsetRight = tab.offsetLeft + tab.offsetWidth;
                            const visibleTabsWrapperOffsetRight =
                                visibleTabsWrapper.offsetLeft + visibleTabsWrapper.offsetWidth;
                            return tabOffsetRight > visibleTabsWrapperOffsetRight;
                        });
                        index = Math.max(index, 1);
                        this.visibleTabs = this.sortedTabs.slice(0, index);
                        this.hiddenTabs = this.sortedTabs.slice(index);

                        if (this.hiddenTabs.find((tab) => tab.key === this.activeTabKey)) {
                            const activeTabIndex = this.tabs.findIndex((tab) => tab.key === this.activeTabKey);
                            this.sortedTabs = [...this.tabs];
                            const [activeTab] = this.sortedTabs.splice(activeTabIndex, 1);
                            this.sortedTabs.unshift(activeTab);
                            this.updateDropdown();
                        } else {
                            this.sortedTabs = [...this.tabs];
                        }
                    });
                }
            });
        },
        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
        },
        openDropdown() {
            this.isDropdownOpen = true;
        },
        closeDropdown() {
            this.isDropdownOpen = false;
        },
        onDropdownTabButtonClick(tabKey) {
            this.$emit('tabClick', tabKey);
            this.closeDropdown();
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';

button {
    background: none;
    border: none;
    padding: 0;
    text-align: left;
    color: inherit;
}

.icon {
    width: calc(var(--spacer) * 3);
}

.tab-button {
    border-bottom: calc(var(--spacer) * 0.5) solid transparent;
    padding-bottom: calc(var(--spacer) * 2);
    font-weight: 600;
    font-size: 20px;
    line-height: calc(var(--spacer) * 3);
    letter-spacing: 0.02em;
    color: var(--button-night);
    transition-property: color, border-bottom-color;
    transition-duration: 0.2s;

    &--only {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &:hover,
    &:focus,
    &--active {
        color: var(--abrdn-black);
        border-bottom-color: currentColor;
    }

    @include media-breakpoint-up(lg) {
        font-size: 24px;
        letter-spacing: 0.01em;
    }
}

.dropdown {
    &__button {
        padding-bottom: calc(var(--spacer) * 2.5);
    }

    &__panel {
        max-width: calc(var(--spacer) * 50);
    }

    &__tab-button {
        width: 100%;
        transition-property: background-color;
        transition-duration: 0.2s;

        &:hover,
        &:focus {
            background-color: var(--digital-light-grey);
        }
    }

    .icon--chevron {
        transform: translateY(2px);
        transition-property: transform;
        transition-duration: 0.2s;
    }

    &--expanded .icon--chevron {
        transform: translateY(0px) rotate(180deg);
    }
}

.divider {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 1px;
        background-color: var(--abrdn-black);
        opacity: 0.3;
    }
}
</style>
