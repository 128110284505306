<template>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
        <path
            d="M19.5451 6.70499L15.3301 2.46749C15.1051 2.24249 14.7976 2.11499 14.4826 2.11499H8.35508C7.70258 2.11499 7.17008 2.64749 7.17008 3.29999V5.24999H5.26508C4.61258 5.24999 4.08008 5.78249 4.08008 6.43499V20.565C4.08008 21.2175 4.61258 21.75 5.26508 21.75H15.6451C16.2976 21.75 16.8301 21.2175 16.8301 20.565V18.615H18.7351C19.3876 18.615 19.9201 18.0825 19.9201 17.43V7.55249C19.9126 7.22999 19.7776 6.92999 19.5451 6.70499ZM15.2326 4.49249L17.3626 6.61499H15.2326V4.49249ZM15.3301 20.2575H5.58008V6.75749H7.17008V17.4375C7.17008 18.09 7.70258 18.6225 8.35508 18.6225H15.3301V20.2575ZM18.4201 17.115H8.67008V6.75749V5.25749V3.61499H13.7326V7.36499C13.7326 7.77749 14.0701 8.11499 14.4826 8.11499H18.4201V17.115Z"
            fill="white"
        />
    </svg>
</template>

<script>
export default {
    name: 'IconCopy',
};
</script>

<style scoped></style>
