<template>
    <div class="trust-card h-100">
        <div class="trust-card__thumbnail flex-shrink-0">
            <StaticImage
                :src="fields.media"
                :alt="fields.title"
                :srcSet="[{ mw: 400 }, { mw: 600 }, { mw: 800 }, { mw: 1000 }]"
                sizes="
                    (max-width: 575px) 100vw,
                    (max-width: 767px) 510px,
                    (max-width: 991px) 210px,
                    (max-width: 1199px) 25vw,
                    270px
                "
            />
        </div>
        <div class="trust-card__body p-2 flex-grow-1" :class="vectorBasedBackground">
            <div class="trust-card__heading d-flex align-items-start section-header-small">
                <span class="article-category section-header-small">{{ regionName }}</span>
            </div>
            <div class="trust-card__content my-2">
                <h5 class="line-clamp-4">{{ fields.title }}</h5>
                <p class="body-small text-grey line-clamp-3 mt-2">{{ fields.description }}</p>
            </div>
            <div v-if="link" class="trust-card__footer mt-auto">
                <AbrdnButton :linkField="link" buttonType="primary" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton';
import StaticImage from '@/components/Generic/SitecoreWrappers/StaticImage';

export default {
    name: 'TrustCard',
    components: { StaticImage, AbrdnButton },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        ...mapState('trusts', ['filters']),
        link() {
            if (!this.fields.link) return false;
            return {
                value: {
                    href: this.fields.link,
                    text: this.$t('visitWebsite'),
                    linktype: 'external',
                    anchor: '',
                    target: '_blank',
                },
            };
        },
        regionName() {
            let name = '';
            this.filters.forEach((filter) => {
                const selectedFilter = filter.options.find((option) => option.id === this.fields.region);
                if (selectedFilter) {
                    name = selectedFilter.name;
                }
            });
            return name;
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
.trust-card__thumbnail {
    width: 100%;
    height: 140px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @include media-breakpoint-down(xl) {
        height: 109px;
    }
    @include media-breakpoint-down(md) {
        height: 180px;
    }
}
.trust-card {
    transition: box-shadow 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
    &:hover,
    &:focus-within {
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }
}
.trust-card__body {
    display: flex;
    flex-direction: column;
}
</style>
