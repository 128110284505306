<template>
    <div>
        <h3>{{ $t('fundCentreSearchTitle') }}</h3>
        <BaseSearch
            :vuexStore="storeName"
            :cookieName="cookieName"
            :placeholder="placeholder"
            :fieldName="fieldName"
            @getSuggestions="debouncedRequestCall($event)"
        />
    </div>
</template>

<script>
import BaseSearch from '@/components/Generic/SearchBar/BaseSearch.vue';

const debounce = require('lodash.debounce');

export default {
    name: 'FundsSearch',
    components: { BaseSearch },
    data() {
        return {
            placeholder: this.$t('fundCentreSearchDescription'),
            fieldName: 'fundSearch',
            cookieName: `fundsSearchHistory#${this.$jss.sitecoreContext().site?.name || ''}`,
            storeName: 'allFunds',
        };
    },
    methods: {
        debouncedRequestCall: debounce(async (context) => {
            context.searchMessageText = '';
            try {
                context.isLoading = true;
                const { products } = await context.$store.dispatch(
                    'allFunds/fetchSearchResults',
                    context.searchInputData,
                );
                context.searchResults = products;
                if (!products.length) {
                    context.searchMessageText = context.$t('noResultsMessage');
                }
            } catch (e) {
                console.error('Search fetching error: ', e);
            } finally {
                context.isLoading = false;
            }
        }, 300),
    },
};
</script>
