<template>
    <div class="table-selector">
        <button
            v-for="tab in allFundsTabTabs"
            :key="tab.key"
            :aria-label="$t(tab.key)"
            type="button"
            class="h4 btn btn-naked pb-3"
            :class="{ active: selectedTab === tab.key }"
            @click="setSelectedTab(tab.key)"
        >
            {{ $t(tab.title) }}
        </button>
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

import { updateUrlParams } from '@/mixins/Helpers.mixin';

export default {
    name: 'TableSelector',
    mixins: [updateUrlParams],
    inject: ['allFundsTabTabs'],
    computed: {
        ...mapState('allFunds', ['selectedTab']),
    },
    watch: {
        selectedTab: {
            handler: function (val, oldVal) {
                if (val === oldVal) return;
                this.updateUrlParams([
                    {
                        name: 'table',
                        value: val,
                    },
                ]);
            },
        },
    },
    methods: {
        ...mapMutations('allFunds', ['setSelectedTab']),
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
.table-selector {
    display: flex;
    gap: calc(var(--spacer) * 6);

    @include media-breakpoint-down(lg) {
        gap: calc(var(--spacer) * 4);
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);

        button {
            &:not(.active) {
                color: var(--text-grey);
            }
        }
    }
}

button {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        height: 4px;
        background-color: var(--abrdn-black);
        transition: opacity 0.2s ease-in-out;
        opacity: 0;
    }

    &.active,
    &:hover,
    &:focus {
        &:before {
            opacity: 1;
        }
    }
}
</style>
