<template>
    <div :data-e2e-component-uid="rendering.uid" class="page-error page-error--404">
        <ScText :field="fields.title" tag="h2" />
        <ScText :field="fields.subtitle" tag="h5" class="mt-5" />
        <ScText
            class="body-default mt-2 multiline text-grey background-to-change"
            tag="p"
            :field="fields.description"
        />
        <div class="page-error__cta mt-3">
            <ScLink class="revert-underline text-link" :field="fields.link" />
        </div>
    </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import ScLink from '@/components/Generic/SitecoreWrappers/ScLink.vue';

export default {
    name: 'Error404',
    components: {
        ScText: Text,
        ScLink,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
};
</script>

<style scoped></style>
