<template>
    <svg width="15" height="28" viewBox="0 0 15 28" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.50146 27.1115V14.0542H13.1058L13.5835 9.5546H9.50146L9.50758 7.30251C9.50758 6.12894 9.61909 5.50012 11.3047 5.50012H13.558V1H9.95309C5.62304 1 4.09897 3.18279 4.09897 6.85357V9.55511H1.3999V14.0547H4.09897V27.1115H9.50146Z"
            stroke="currentColor"
            fill="currentColor"
        />
    </svg>
</template>

<script>
export default {
    name: 'IconFacebook',
};
</script>

<style scoped></style>
