<template>
    <section class="default-section-padding link-change">
        <div class="container">
            <ScText tag="h2" class="background-to-change mb-5" :field="fields.title" />
            <div v-if="fields.items && fields.items.length" class="row">
                <div class="col-12 col-md-6">
                    <ScText tag="h3" class="mb-2 background-to-change" :field="textBlock.fields.title" />
                    <ScRichText
                        class="contentDescription body-default quote-description text-grey background-to-change"
                        :fields="textBlock.fields"
                    />
                    <AbrdnPlaceholder :rendering="rendering" name="abrdn-list" class="placeholder mt-2 mb-3" />
                    <AbrdnButton
                        v-if="renderButton"
                        :buttonType="buttonType"
                        class="mt-2 mt-md-3 background-to-change"
                        :linkField="textBlock.fields.cta"
                    />
                </div>
                <div class="col-12 col-md-6 mt-5 mt-md-0">
                    <AbrdnPlaceholder :rendering="rendering" name="abrdn-media-item" class="placeholder" />
                    <ScText :tag="'p'" class="background-to-change caption mt-3" :field="fields.description" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton';
import AbrdnPlaceholder from '@/components/Generic/SitecoreWrappers/AbrdnPlaceholder.vue';
import RichText from '@/components/Generic/SitecoreWrappers/RichText.vue';
import { hasPlaceholders } from '@/mixins/Helpers.mixin';

export default {
    name: 'MediaBlock',
    components: {
        AbrdnPlaceholder,
        ScText: Text,
        ScRichText: RichText,
        AbrdnButton,
    },
    mixins: [hasPlaceholders],
    props: {
        fields: {
            type: Object,
            default: () => ({}),
        },
        rendering: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        textBlock() {
            return this.fields.items[0];
        },
        renderButton() {
            return this.isExperienceEditor || this.textBlock?.fields?.cta?.value?.text;
        },
        buttonType() {
            switch (this.rendering?.params?.presentationType) {
                case 'textLink':
                    return 'textLink';
                case 'buttonPrimary':
                    return 'primary';
                case 'buttonSecondary':
                    return 'secondary';
                default:
                    return 'primary';
            }
        },
    },
};
</script>

<style scoped lang="scss">
.placeholder {
    max-width: 100%;
    padding: 0px;
    ::v-deep .default-section-padding {
        padding-top: 0px;
        padding-bottom: 0px;
        .container {
            padding: 0px;
            .video-wrapper {
                margin: 0px;
                width: 100%;
                .kv-kulu {
                    padding-top: 0px !important;
                    padding-bottom: 0px !important;
                    .kv-packshot-holder {
                        height: 100% !important;
                    }
                }
            }
        }
    }
}
::v-deep img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}
</style>
