<template>
    <div>
        <SkipToMainContent />
        <PageLevelNotification />
        <Placeholder v-if="appState.routeData" name="abrdn-header" :rendering="appState.routeData" />
        <RouteLoading v-show="loading || languageIsChanging" />
        <Layout v-if="!loading" :route="appState.routeData" />
        <NotFound v-if="notFound" :context="appState.sitecoreContext" />
    </div>
</template>

<script>
import { dataApi, isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-vue';
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';
import { mapMutations } from 'vuex';

import PageLevelNotification from '@/components/Generic/ExperienceEditor/PageLevelNotification';
import SkipToMainContent from '@/components/Generic/SkipToMainContent';
import DisableScroll from '@/mixins/DisableScroll.mixin';
import { vectorResolver } from '@/mixins/Helpers.mixin';

import { virtualVectors } from './constants/vectors';
import { dataFetcher } from './dataFetcher';
import Layout from './Layout';
import NotFound from './NotFound';
import RouteLoading from './RouteLoading';
import { getApiHost, getApiKey } from './Service.api';
import config from './temp/config';

// Dynamic route handler for Sitecore items.
// Because JSS app routes are defined in Sitecore, traditional static routing isn't enough -
// we need to load dynamic route data from Sitecore when the client side route changes.
// So vue-router delegates all route rendering to this handler, which attempts to get the right
// route data from Sitecore - and if none exists, renders the not found component.

export default {
    name: 'RouteHandler',
    components: {
        Layout,
        NotFound,
        RouteLoading,
        Placeholder,
        PageLevelNotification,
        SkipToMainContent,
    },
    mixins: [DisableScroll],
    inject: {
        changeAppLanguage: {
            type: Function,
        },
    },
    props: {
        route: {
            type: Object,
            default: () => ({}),
        },
        languageIsChanging: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        const state = {
            notFound: true,
            defaultLanguage: config.defaultLanguage,
            loading: true,
            t0: null,
            t1: null,
            t2: null,
            apiHost: process.env.NODE_ENV === 'development' ? 'https://dev-jss.abrdn.com' : getApiHost(),
            silentRouteChange: false,
            dataLayerData: {
                event: 'page_view',
                page_title: null,
                breadcrumbs: null,
                page_location: null,
                proposition: {
                    investment_fund: {
                        fund_code: null,
                    },
                },
            },
        };

        // To take advantage of Vue's reactive data for tracking app state changes, we need
        // to reference the same `state` object that the $jss store references in order for mutations to be observed.
        // $jss is attached to the Vue instance via `SitecoreJssPlugin`.
        const appState = this.$jss.store.state;

        // if the app state has routeData, we don't need to load it and don't need a loading screen
        if (appState.routeData) {
            state.loading = false;
        }

        // route path from vue router - if route was resolved, it's not a 404
        if (this.route !== null) {
            state.notFound = false;
        }

        // if we have an initial SSR state, and that state doesn't have a valid route data,
        // then this is a 404 route.
        if (!appState.routeData) {
            state.notFound = true;
        }

        // if we have initial context data, and that context data has a language defined, set the default language
        // (this makes the language of content follow the Sitecore context language cookie)
        // note that a route-based language (i.e. /de-DE) will override this default; this is for home.
        if (appState.sitecoreContext && appState.sitecoreContext.displayLanguage) {
            state.defaultLanguage = appState.sitecoreContext.displayLanguage;
        }

        return { ...state, appState };
    },
    watch: {
        // watch for a change in the 'route' prop
        route(newRoute, oldRoute) {
            //Prevent fetching new route data if we manually set up silentRouteChange to true
            if (this.silentRouteChange) {
                this.silentRouteChange = false;
                return;
            }
            // if the route contains a hash value, assume the URL is a named anchor/bookmark link, e.g. /page#anchorId.
            // in that scenario, we don't want to fetch new route data but instead allow default browser behavior.
            if (newRoute.hash !== '' && newRoute.path === oldRoute.path) {
                return;
            }
            // if in experience editor - force reload instead of route data update
            // avoids confusing Sitecore's editing JS
            if (isExperienceEditorActive()) {
                window.location.assign(newRoute.path);
                return;
            }

            this.updateLanguage();
            this.updateRouteData();
            if (typeof document !== 'undefined' && this.$root.windowFreezer.isFrozen) {
                this.toggleWindowScroll(false);
            }
        },
    },
    updated() {
        this.scrollToAnchorId();
    },
    created() {
        // if no existing routeData is present (from SSR), get Layout Service fetching the route data
        if (!this.appState.routeData) {
            this.updateRouteData();
        } else {
            this.setLangCodeToRoute();
            const dataLayer = this.$jss.sitecoreContext().dataLayer || {};
            this.fillDataLayerValues(this.$jss.sitecoreContext(), dataLayer, this.appState?.routeData);
        }
        // tell app to sync its current language with the route language
        this.updateLanguage();

        // Executing scrollToAnchorId when loading with SSR
        this.scrollToAnchorId();
    },
    mounted() {
        this.setLanguageVersions(this.$jss.sitecoreContext().languageVersions);
    },
    methods: {
        ...mapMutations('language', ['setLanguageVersions']),
        /**
         * Loads route data from Sitecore Layout Service into appState.routeData
         */
        updateRouteData() {
            let sitecoreRoutePath = this.route.params.sitecoreRoute || '/';
            if (!sitecoreRoutePath.startsWith('/')) {
                sitecoreRoutePath = `/${sitecoreRoutePath}`;
            }

            const language =
                this.route.params.lang || this.appState.sitecoreContext.displayLanguage || this.defaultLanguage;
            this.loading = true;
            const relatedSiteName = vectorResolver(virtualVectors, this.route.params.vectorPath);
            getRouteData(sitecoreRoutePath, language, relatedSiteName).then((routeData) => {
                this.setSitecoreData(routeData);
                const dataLayer = routeData?.sitecore?.context.dataLayer || {};
                this.fillDataLayerValues(routeData?.sitecore?.context, dataLayer, routeData?.sitecore?.route);
            });
        },
        updateGtmDataLayer(dataLayer) {
            if (typeof window === 'undefined' || !dataLayer || !window.dataLayer) return;
            window.dataLayer?.push(dataLayer);
        },
        fillDataLayerValues(context, dataLayer, routeData) {
            if (typeof window === 'undefined' || this.isExperienceEditor) return;
            this.$nextTick(() => {
                const language = context?.displayLanguage.split('-')[0];
                const country = context?.displayLanguage.split('-')[1];

                this.dataLayerData.page_title = context?.metaTitle;
                this.dataLayerData.page_location = window.location.href;
                this.dataLayerData.breadcrumbs =
                    routeData.placeholders['abrdn-header'][0]?.placeholders[
                        'abrdn-breadcrumbs'
                    ][0]?.fields.breadcrumbs.value.map((value) => value.name) || 'n/a';
                this.dataLayerData.global = {
                    ...dataLayer.global,
                    country,
                    language,
                };
                this.dataLayerData.proposition.investment_fund.fund_code =
                    this.$store.state.fundDetails.selectedShareClassIsin || 'n/a';

                const updatedData = this.replaceNullValues(Object.assign(dataLayer, this.dataLayerData));
                this.updateGtmDataLayer(updatedData);
            });
        },
        replaceNullValues(data) {
            const replaceNull = (innerObj) => {
                for (const key in innerObj) {
                    if (innerObj[key] === null) {
                        innerObj[key] = 'n/a';
                    } else if (typeof innerObj[key] === 'object') {
                        replaceNull(innerObj[key]);
                    }
                }
            };

            replaceNull(data);
            return data;
        },
        setSitecoreData(routeData) {
            if (routeData !== null && routeData.sitecore.route) {
                // Update the JSS store instance with the fetched data.
                // This will signal the RouteHandler to update/re-render, as well as any components
                // that are referencing the JSS store instance in their `data` object.
                this.$jss.store.setSitecoreData(routeData);
                this.notFound = false;
            } else {
                this.$jss.store.setSitecoreData(routeData);
                this.notFound = true;
            }
            this.setLanguageVersions(this.$jss.sitecoreContext().languageVersions);
            this.loading = false;
        },
        /**
         * Updates the current app language to match the route data.
         */
        updateLanguage() {
            const newLanguage =
                this.route.params.lang || this.appState.sitecoreContext.displayLanguage || this.defaultLanguage;
            // `changeAppLanguage` is "inject"-ed from AppRoot
            this.changeAppLanguage(newLanguage);
        },
        setLangCodeToRoute() {
            const contextLanguage = this.appState.sitecoreContext.displayLanguage;
            if (!this.$route.params.lang && contextLanguage) {
                this.silentRouteChange = true;
                this.$router.replace(`/${contextLanguage.toLowerCase()}${this.$route.fullPath}`);
            }
        },
        scrollToAnchorId() {
            if (typeof window !== 'undefined' && !this.isExperienceEditor) {
                if (this.$router.props?.scrollToAnchor) {
                    setTimeout(() => {
                        document.getElementById(this.$router.props?.targetScroll).scrollIntoView();
                        this.$router.props = {};
                    }, 1000);
                } else if (window?.location?.hash) {
                    /*
                     * NOTE: `setTimeout` is necessary because `document.getElementById`
                     * might return null since searched component may not be rendered yet
                     */
                    setTimeout(() => {
                        const id = window.location.hash;
                        document.querySelector(id).scrollIntoView();
                    }, 1000);
                }
            }
        },
    },
};

/**
 * Gets route data from Sitecore. This data is used to construct the component layout for a JSS route.
 * @param {string} route Route path to get data for (e.g. /about)
 * @param {string} language Language to get route data in (content language, e.g. 'en')
 * @param {dataApi.LayoutServiceRequestOptions} options Layout service fetch options
 */
function getRouteData(route, language, siteName) {
    const fetchOptions = {
        layoutServiceConfig: { host: window?.app?.sitecoreApiHost || config.sitecoreApiHost },
        querystringParams: {
            sc_lang: language,
            sc_site: siteName,
            sc_apikey: getApiKey(),
        },
        fetcher: dataFetcher,
    };

    return dataApi.fetchRouteData(route, fetchOptions).catch((error) => {
        if (error.response && error.response.status === 404 && error.response.data) {
            return error.response.data;
        }

        console.error('Route data fetch error', error, error.response);

        return null;
    });
}
</script>
