var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"default-section-padding text-blocks link-change",class:_vm.backgroundClass,attrs:{"id":_vm.anchorId,"data-e2e-component-uid":_vm.rendering.uid}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{class:{ 'col-12 col-xl-8 mb-4 mb-xl-0': _vm.haveAside, 'col-12': !_vm.haveAside }},[(_vm.isExperienceEditor || _vm.haveTitleValue)?_c('div',{staticClass:"row",class:{ 'mb-5': _vm.hasPlaceholders || (_vm.fields.items && _vm.fields.items.length) }},[_c('div',{staticClass:"col col-xl-8 col-md-10",class:{ 'col-12': _vm.haveAside }},[_c('ScText',{staticClass:"background-to-change",attrs:{"tag":_vm.headingType,"field":_vm.fields.title}})],1)]):_vm._e(),(_vm.hasPlaceholders || (_vm.fields.items && _vm.fields.items.length))?_c('div',{staticClass:"row d-flex"},[_vm._l((_vm.fields.items),function(textBlock,i){return _c('div',{key:textBlock.id,staticClass:"col-12 col-md mb-md-0 d-flex flex-column align-items-start",class:{
                            'col-xl-8 col-md-10': _vm.fields.items.length === 1 && !_vm.haveAside,
                            'col-md-6 col-xl mb-xl-0': _vm.fields.items.length > 3 && !_vm.haveAside,
                            'mb-md-5': i <= 1 && _vm.fields.items.length > 3 && !_vm.haveAside,
                            'mb-5': i !== _vm.fields.items.length - 1,
                            'col-12': _vm.haveAside,
                        }},[(_vm.isExperienceEditor || _vm.getIcon(textBlock))?_c('div',{staticClass:"mb-2 mb-md-4 image-wrapper"},[_c('WrappedImage',{staticClass:"image background-to-change",attrs:{"media":textBlock.fields.icon,"srcSet":[{ mw: 100 }, { mw: 200 }],"sizes":"64px"}})],1):_vm._e(),(
                                _vm.isExperienceEditor ||
                                (textBlock.fields && textBlock.fields.title && textBlock.fields.title.value)
                            )?_c('ScText',{staticClass:"mb-2 background-to-change",attrs:{"tag":"h5","field":textBlock.fields.title}}):_vm._e(),(
                                _vm.isExperienceEditor ||
                                (textBlock.fields &&
                                    textBlock.fields.description &&
                                    textBlock.fields.description.value)
                            )?_c('ScRichText',{staticClass:"contentDescription body-default quote-description text-grey background-to-change mb-0 w-100 link-change",attrs:{"fields":textBlock.fields}}):_vm._e(),_c('AbrdnPlaceholder',{staticClass:"mt-3",attrs:{"rendering":_vm.rendering,"name":("abrdn-list-" + i)}}),(
                                _vm.isExperienceEditor ||
                                (textBlock.fields &&
                                    textBlock.fields.cta &&
                                    textBlock.fields.cta.value &&
                                    textBlock.fields.cta.value.text)
                            )?[_c('AbrdnButton',{class:{ 'mt-2 mt-md-3': !_vm.haveInsidePlaceholders },attrs:{"buttonType":_vm.buttonType,"linkField":textBlock.fields.cta}})]:_vm._e()],2)}),_c('AbrdnPlaceholder',{attrs:{"rendering":_vm.rendering,"name":"abrdn-text-blocks"}})],2):_vm._e()]),_c('AbrdnPlaceholder',{staticClass:"col-12 col-xl-4",attrs:{"rendering":_vm.rendering,"name":"abrdn-text-blocks-aside","singleTile":true,"hardcodedBg":"background-blue-grey"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }