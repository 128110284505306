<script>
export default {
    name: 'FiltersSection',
    data() {
        return {
            isFiltersLoading: false,
            formattedFilters: [],
        };
    },
    computed: {
        showAggregateCount() {
            return !this.notShowAggregateCount;
        },
    },
    created() {
        this.formattedFilters = this.formatFilters(this.filters);
    },
    methods: {
        formatFilters(filters) {
            return JSON.parse(JSON.stringify(filters)).map((filter) => {
                if (!!filter.options) {
                    filter.options.forEach((option) => {
                        option.selected = false;
                        option.numberOfSelected = 0;
                        option.applied = false;
                        option.aggregateCount = 0;
                        option.showAggregateCount = this.showAggregateCount;
                    });
                } else {
                    filter.options = [];
                }
                return filter;
            });
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
::v-deep .mobile-filters-button {
    @include media-breakpoint-down(lg) {
        max-width: 270px;
    }
    @include media-breakpoint-down(md) {
        max-width: 100%;
    }
}
</style>
