<template>
    <section>
        <p class="h3">{{ sectionTitle }}</p>

        <ReviewStep
            v-for="(step, accordionIndex) in reviewSteps"
            :key="step.id"
            :isFirst="accordionIndex !== 0"
            :title="step.displayName"
            :description="step.description"
            :stepBack="step.stepBack"
        />
    </section>
</template>
<script>
import { mapState } from 'vuex';

import ReviewStep from '@/components/Sections/Forms/ReviewStep';

export default {
    name: 'Step7',
    components: { ReviewStep },
    data() {
        return {
            sectionTitle: 'Review and submit (step 7 of 7)',
        };
    },
    computed: {
        ...mapState('onboardingForm', ['reviewSteps']),
    },
};
</script>
