export default {
    computed: {
        sharePrice() {
            return this.$jss.sitecoreContext().sharePriceSettings?.sharePrice?.value;
        },
        sharePriceCta() {
            return this.$jss.sitecoreContext().sharePriceSettings?.sharePriceCta;
        },
        hasSharePrice() {
            return !!this.sharePrice && !!this.sharePriceCta;
        },
    },
};
