<template>
    <svg viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
        <path
            d="M0.315445 2.36143C0.599929 1.6752 1.08773 1.09255 1.71325 0.69183C2.33876 0.291114 3.07199 0.0915576 3.8143 0.120001H20.1352C20.8695 0.0885594 21.5965 0.277422 22.2228 0.662321C22.849 1.04722 23.3458 1.61058 23.6494 2.28C23.7106 2.36446 23.7506 2.46236 23.7662 2.56548C23.7817 2.6686 23.7723 2.77394 23.7387 2.87267C23.7051 2.9714 23.6484 3.06064 23.5732 3.13291C23.498 3.20517 23.4066 3.25836 23.3066 3.288L12.4783 8.928C12.3417 9.00635 12.187 9.04757 12.0296 9.04757C11.8721 9.04757 11.7174 9.00635 11.5809 8.928L0.61716 3.24C0.53014 3.21399 0.450568 3.16762 0.385036 3.10474C0.319504 3.04186 0.269897 2.96427 0.240323 2.87839C0.210749 2.79252 0.202061 2.70084 0.214975 2.61094C0.22789 2.52104 0.262894 2.43551 0.315445 2.36143ZM23.9314 14.79C23.7947 15.6604 23.3486 16.4523 22.675 17.0202C22.0014 17.5881 21.1456 17.894 20.2646 17.8817H3.80744C2.97722 17.8996 2.16502 17.6382 1.50106 17.1395C0.837094 16.6407 0.359816 15.9335 0.145731 15.1311C0.0595281 14.8183 0.0142925 14.4956 0.0111589 14.1711V6.33086C0.0111589 5.65372 0.43373 5.40429 1.03973 5.71629C4.44773 7.48771 7.85258 9.26372 11.2543 11.0443C11.4831 11.1801 11.7442 11.2518 12.0103 11.2518C12.2764 11.2518 12.5375 11.1801 12.7663 11.0443C16.152 9.26829 19.5423 7.50829 22.9372 5.76429C23.0332 5.7087 23.1365 5.66659 23.244 5.63914C23.3357 5.60746 23.4339 5.59962 23.5294 5.61635C23.6249 5.63308 23.7146 5.67384 23.7901 5.73479C23.8655 5.79574 23.9242 5.87488 23.9606 5.96475C23.997 6.05463 24.01 6.1523 23.9983 6.24857V13.0286C24.0242 13.6152 24.0016 14.2029 23.9306 14.7857L23.9314 14.79Z"
            fill="currentColor"
        />
    </svg>
</template>

<script>
export default {
    name: 'IconEmail',
};
</script>

<style scoped></style>
