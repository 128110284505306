<template>
    <EEPlaceholder v-if="isExperienceEditor">Share screen</EEPlaceholder>
    <section v-else class="default-section-padding" :class="vectorBasedBackground">
        <div class="container">
            <div class="d-flex flex-column align-items-center mb-2">
                <h2 class="my-2">{{ $t('screenShareTitle') }}</h2>
                <p class="mb-2">{{ $t('screenShareDescription') }}</p>
                <AbrdnButton buttonType="primary" class="mb-2" @click.native="generateCode">
                    <span>{{ $t('startScreenShare') }}</span>
                </AbrdnButton>
                <h4 v-if="cobrowseCode !== ''">{{ cobrowseCode }}</h4>
            </div>
        </div>
    </section>
</template>

<script>
import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton';
import EEPlaceholder from '@/components/Sections/EEPlaceholder';

export default {
    name: 'ShareScreen',
    components: { EEPlaceholder, AbrdnButton },
    data() {
        return {
            cobrowseCode: '',
        };
    },
    computed: {
        isClient() {
            return !this.isExperienceEditor || typeof window !== 'undefined';
        },
    },
    mounted() {
        if (this.isClient) {
            this.initiateCobrowseIO();
        }
    },
    methods: {
        initiateCobrowseIO() {
            if (this.isClient) {
                const promise = new Promise((resolve) => {
                    window['CobrowseIO'] = {
                        client: () => {
                            const script = document.createElement('script');
                            script.src = 'https://js.cobrowse.io/CobrowseIO.js';
                            script.async = 1;
                            document.head.appendChild(script);
                            script.onload = () => {
                                resolve(window['CobrowseIO']);
                            };
                            return promise;
                        },
                    };
                });

                window['CobrowseIO'].license = 'eu_PseHgVOKlgXSdw';
                window['CobrowseIO'].api = 'https://eu.cobrowse.io';
                window['CobrowseIO'].client().then(() => {
                    window['CobrowseIO'].start();
                });
            }
        },
        generateCode() {
            if (this.isClient) {
                window['CobrowseIO'].client().then(() => {
                    window['CobrowseIO'].createSessionCode().then((code) => {
                        this.cobrowseCode = `${this.$t('screenShareCodeMessage')} ${code}`;
                    });
                });
            }
        },
    },
};
</script>
