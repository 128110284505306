<template>
    <section v-if="!showMessage" class="default-section-padding">
        <div class="container">
            <div class="row">
                <p v-if="currentStep !== 0 && currentStep !== steps.length" class="mb-5 text-grey body-small px-0">
                    Fields marked with * are required.
                </p>
                <StartPage v-if="currentStep === 0" :startPageData="startPageData" />
                <KeepAlive v-else exclude="Step7">
                    <component :is="'Step' + currentStep" ref="step" :formFields="formFields['step' + currentStep]" />
                </KeepAlive>
            </div>
        </div>
        <StepSelector @submitForm="submitForm" />
    </section>
    <section v-else ref="messageContent">
        <SuccessMessage v-if="showSuccessMessage" />
        <ErrorMessage v-if="showErrorMessage" />
    </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

import ErrorMessage from '@/components/Sections/Forms/ErrorMessage';
import { formFieldsStep1 } from '@/components/Sections/Forms/Form-LPP48/steps/formFieldsStep1';
import { formFieldsStep2 } from '@/components/Sections/Forms/Form-LPP48/steps/formFieldsStep2';
import { formFieldsStep3 } from '@/components/Sections/Forms/Form-LPP48/steps/formFieldsStep3';
import { formFieldsStep4 } from '@/components/Sections/Forms/Form-LPP48/steps/formFieldsStep4';
import { formFieldsStep5 } from '@/components/Sections/Forms/Form-LPP48/steps/formFieldsStep5';
import { formFieldsStep6 } from '@/components/Sections/Forms/Form-LPP48/steps/formFieldsStep6';
import StartPage from '@/components/Sections/Forms/StartPage';
import Step1 from '@/components/Sections/Forms/Step1';
import Step2 from '@/components/Sections/Forms/Step2';
import Step3 from '@/components/Sections/Forms/Step3';
import Step4 from '@/components/Sections/Forms/Step4';
import Step5 from '@/components/Sections/Forms/Step5';
import Step6 from '@/components/Sections/Forms/Step6';
import Step7 from '@/components/Sections/Forms/Step7';
import StepSelector from '@/components/Sections/Forms/StepSelector';
import SuccessMessage from '@/components/Sections/Forms/SuccessMessage';
import DataLayerHandler from '@/mixins/DataLayer.mixin';
import { updateUrlParams } from '@/mixins/Helpers.mixin';

export default {
    name: 'FormLPP48',
    components: {
        StartPage,
        Step1,
        Step2,
        Step3,
        Step4,
        Step5,
        Step6,
        Step7,
        StepSelector,
        SuccessMessage,
        ErrorMessage,
    },
    mixins: [updateUrlParams, DataLayerHandler],

    data() {
        return {
            startPageData: {
                title: 'Get started',
                subtitle: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit',
                list: [
                    {
                        title: 'Firm details',
                        description: 'Provide the contact details for your firm.',
                    },
                    {
                        title: 'Portfolio options',
                        description:
                            'Outline the features for your new portfolio including your approach to automatic rebalancing, minimum cash allocation and treatment of non-tradeable assets for new deposits.',
                    },
                    {
                        title: 'Individually managed accounts',
                        description: 'The us where you need to access individually managed accounts.',
                    },
                    {
                        title: 'Remuneration for portfolio manager fee',
                        description: "Choose how often you'd like your fee paid and confirm your bank account details.",
                    },
                    {
                        title: 'Admin used details',
                        description: "You'll need to provide the details of wto admin users.",
                    },
                    {
                        title: 'Hub branding',
                        description:
                            "If you want the hub to show your form's branding, you can provide a logo and choose a navigation color.",
                    },
                    {
                        title: 'Review and submit',
                        description: '',
                    },
                ],
                afterwords: 'This form should take around xx minutes to complete.',
            },
            reviewSteps: [
                {
                    id: 'e57aa843-88aa-4fd9-a6aa-522c1ec15ceb',
                    name: 'Firm details',
                    displayName: '1. Firm details',
                    description: {},
                    stepBack: {
                        value: 'Edit step 1: Firm details',
                        step: 1,
                    },
                },
                {
                    id: '05dd5e71-760e-4e0e-b96a-ed2b2a6e3f91',
                    name: '2. Portfolio options',
                    displayName: '2. Portfolio options',
                    description: {},
                    stepBack: {
                        value: 'Edit step 2: Portfolio options',
                        step: 2,
                    },
                },
                {
                    id: 'efd432d2-279c-44e4-a624-6ceec23d1dfd',
                    name: '3. Individually managed accounts',
                    displayName: '3. Individually managed accounts',
                    description: {},
                    stepBack: {
                        value: 'Edit step 3: Individually managed accounts',
                        step: 3,
                    },
                },
                {
                    id: 'ad8e05c5-b610-4619-80b7-53f3d27d2ab9',
                    name: '4. Remuneration for portfolio manager fee',
                    displayName: '4. Remuneration for portfolio manager fee',
                    description: {},
                    stepBack: {
                        value: 'Edit step 4: Remuneration for portfolio manager fee',
                        step: 4,
                    },
                },
                {
                    id: 'ad8e05c5-b610-4619-80b7-53f3d27d2ab9',
                    name: '5. Admin user details',
                    displayName: '5. Admin user details',
                    description: {},
                    stepBack: {
                        value: 'Edit step 5: Admin user details',
                        step: 5,
                    },
                },

                {
                    id: 'ad8e05c5-b610-4619-80b7-53f3d27d2ab9',
                    name: '6. Hub branding',
                    displayName: '6. Hub branding',
                    description: {},
                    stepBack: {
                        value: 'Edit step 6: Hub branding',
                        step: 6,
                    },
                },
            ],
            formFields: {
                step1: formFieldsStep1,
                step2: formFieldsStep2,
                step3: formFieldsStep3,
                step4: formFieldsStep4,
                step5: formFieldsStep5,
                step6: formFieldsStep6,
            },
            showMessage: false,
            showSuccessMessage: false,
            showErrorMessage: false,
        };
    },
    computed: {
        ...mapState('onboardingForm', ['steps', 'currentStep', 'onboardingFormData']),
    },
    created() {
        this.setFormSteps([
            {
                nextStep: 'Start form',
                prevStep: null,
            },
            {
                nextStep: 'Portfolio options',
                prevStep: 'Get started',
            },
            {
                nextStep: 'Individually managed accounts',
                prevStep: 'Firm details',
            },
            {
                nextStep: 'Remuneration for portfolio manager fee',
                prevStep: 'Portfolio options',
            },
            {
                nextStep: 'Admin user details',
                prevStep: 'Individually managed accounts',
            },
            {
                nextStep: 'Hub branding',
                prevStep: 'Remuneration for portfolio manager fee',
            },
            {
                nextStep: 'Review and submit',
                prevStep: 'Admin user details',
            },
        ]);
        this.setActiveStep();
        this.setReviewStepsData(this.reviewSteps);
        this.setFormID('012KD000000bpt7YAA');
    },
    methods: {
        ...mapMutations('onboardingForm', ['setFormSteps', 'changeStep', 'setReviewStepsData', 'setFormID']),
        ...mapActions('onboardingForm', ['postFormData']),
        setActiveStep() {
            const stepFromUrl = this.$route.query.step;
            if (stepFromUrl && this.formFields['step' + stepFromUrl]) {
                this.changeStep(+stepFromUrl);
            } else {
                this.changeStep(0);
                this.updateUrlParams([{ name: 'step', value: 0 }]);
            }
        },
        submitForm() {
            this.postFormData()
                .then((response) => {
                    this.showMessage = true;
                    if (response.status === 200 && response.data === true) {
                        this.showSuccessMessage = true;
                    } else {
                        this.showErrorMessage = true;
                    }
                })
                .finally(() => {
                    this.$nextTick(() => {
                        const messageContainer = this.$refs.messageContent;
                        if (messageContainer) {
                            messageContainer.scrollIntoView();
                        }
                    });
                });
            this.pushDataLayerEvent({
                event: 'form_submit',
                formName: 'LPP48',
            });
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';

::v-deep {
    .divider {
        height: 1px;
        margin-top: calc(var(--spacer) * 5);
        margin-bottom: calc(var(--spacer) * 5);
        background-color: var(--abrdn-black);
        opacity: 0.3;

        @include media-breakpoint-up(md) {
            margin-top: calc(var(--spacer) * 6);
            margin-bottom: calc(var(--spacer) * 6);
        }
    }

    .radioFieldSet {
        display: flex;
        align-items: center;

        .radio-button-wrapper {
            margin-top: 0;
            margin-right: 30px;
        }
    }

    .conditional-field {
        padding: 30px;
        background: var(--digital-light-grey);
    }
}
</style>
