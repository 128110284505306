<template>
    <EEPlaceholder v-if="isExperienceEditor">Dynamic Related Articles Section</EEPlaceholder>
    <RelatedArticlesSections
        v-else-if="showRelatedArticles"
        :id="anchorId"
        :data-e2e-component-uid="rendering.uid"
        :fields="{ articles }"
        :rendering="rendering"
    />
</template>

<script>
import { mapState } from 'vuex';

import { getArticles } from '@/api/related-articles';
import EEPlaceholder from '@/components/Sections/EEPlaceholder';
import RelatedArticlesSections from '@/components/Sections/RelatedArticlesVariations/RelatedArticlesSection.vue';

export default {
    name: 'DynamicRelatedArticlesSection',
    components: { RelatedArticlesSections, EEPlaceholder },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    data() {
        return {
            articles: [],
        };
    },
    computed: {
        ...mapState('fundDetails', ['assetClassName']),
        showRelatedArticles() {
            return !!this.articles?.value?.length;
        },
        taxonomy() {
            return this.fields?.taxonomy?.value;
        },
        language() {
            return this.$jss.sitecoreContext()?.displayLanguage || 'en-gb';
        },
        siteName() {
            return this.$jss.sitecoreContext()?.site?.name;
        },
    },
    watch: {
        assetClassName: {
            handler: function () {
                if (!!this.assetClassName) {
                    this.fetchArticles();
                }
            },
        },
    },
    methods: {
        async fetchArticles() {
            try {
                const res = await getArticles({
                    language: this.language,
                    taxonomy: this.taxonomy,
                    value: this.assetClassName,
                    site: this.siteName,
                });
                this.articles = res.data.articles;
            } catch (e) {
                console.warn(e);
            }
        },
    },
};
</script>

<style scoped lang="scss"></style>
