<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="ms-1 svg-image"
        aria-hidden="true"
    >
        <circle cx="12" cy="12" r="12" />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.23864 13.5713L11.4239 8.24513C11.7421 7.91829 12.2579 7.91829 12.5761 8.24513L17.7614 13.5713C18.0795 13.8981 18.0795 14.428 17.7614 14.7549C17.4432 15.0817 16.9273 15.0817 16.6091 14.7549L12 10.0193L7.39091 14.7549C7.07272 15.0817 6.55683 15.0817 6.23864 14.7549C5.92045 14.428 5.92045 13.8981 6.23864 13.5713Z"
        />
    </svg>
</template>

<script>
export default {
    name: 'IconToTop',
};
</script>

<style scoped></style>
