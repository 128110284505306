export default {
    name: 'DisableScroll',
    methods: {
        toggleWindowScroll(state) {
            const body = document.querySelector('body');
            if (state && this.$root.windowFreezer.isFrozen) return;
            if (state) {
                this.$root.windowFreezer.scrollPosition = window.pageYOffset;
                body.style.overflow = 'hidden';
                body.style.position = 'fixed';
                body.style.top = `-${this.$root.windowFreezer.scrollPosition}px`;
                body.style.width = '100%';
            } else {
                body.style.removeProperty('overflow');
                body.style.removeProperty('position');
                body.style.removeProperty('top');
                body.style.removeProperty('width');
                window.scrollTo({ top: this.$root.windowFreezer.scrollPosition, behavior: 'instant' });
            }
            this.$root.windowFreezer.isFrozen = state;
        },
    },
};
