<template>
    <section :id="anchorId" :data-e2e-component-uid="rendering.uid" class="background-white">
        <FilterBarFunctional
            v-if="postsPerPage"
            v-show="hasFilters"
            :vuexData="vuexData"
            :data-e2e-component-uid="rendering.uid"
            :formattedMapping="filterMappings"
            :initialTake="postsPerPage"
        />
    </section>
</template>

<script>
import FilterBarFunctional from '@/components/Functional/ArticleFilterBar/FilterBar';
import DeviceType from '@/mixins/DeviceType.mixin';

export default {
    name: 'PressReleaseFilterBar',
    components: { FilterBarFunctional },
    mixins: [DeviceType],
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    data() {
        return {
            vuexData: {
                filterBar: {
                    state: {
                        namespace: 'pressReleases',
                        items: [
                            'filters',
                            'filtersForApprove',
                            'approvedFilters',
                            'filtersFacets',
                            'emptyFacets',
                            'page',
                            'take',
                            'loading',
                            'totalResults',
                            'articles',
                            'filtersForApprove',
                            'baseUrl',
                        ],
                    },
                    actions: {
                        namespace: 'pressReleases',
                        items: ['getArticles', 'clearArticles', 'getFacets', 'getEmptyFacets'],
                    },
                    mutations: {
                        namespace: 'pressReleases',
                        items: [
                            'setFilters',
                            'setFiltersForApprove',
                            'setApprovedFilters',
                            'setFilterMappings',
                            'setPaginationData',
                            'setBaseUrl',
                        ],
                    },
                },
            },
        };
    },
    computed: {
        filterMappings() {
            return this.fields.filterMappings.value;
        },
        hasFilters() {
            return this.filterMappings.filters?.length;
        },
        postsPerPageOptions() {
            return {
                mobile: this.fields?.filterMappings?.value.pagination.mobile || 6,
                desktop: this.fields.filterMappings?.value.pagination.desktop || 12,
            };
        },
        postsPerPage() {
            if (!this.deviceType) return undefined;
            return this.deviceType === 'mobile' ? this.postsPerPageOptions.mobile : this.postsPerPageOptions.desktop;
        },
    },
};
</script>

<style scoped lang="scss"></style>
