<template>
    <div class="my-3">
        <ScLink
            v-if="isExperienceEditor || haveLinkValue"
            class="revert-underline text-link background-to-change"
            :field="fields.link"
        />
    </div>
</template>

<script>
import ScLink from '@/components/Generic/SitecoreWrappers/ScLink.vue';

export default {
    name: 'Link',
    components: {
        ScLink,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        haveLinkValue() {
            return !!this.fields?.link?.value;
        },
    },
};
</script>

<style scoped lang="scss"></style>
