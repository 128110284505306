<template>
    <div
        class="lds-ring"
        :class="{ [`lds-ring--${color}`]: color }"
        :style="{ width: `${size}px`, height: `${size}px` }"
    >
        <div :style="{ width: `${size}px`, height: `${size}px` }" />
        <div :style="{ width: `${size}px`, height: `${size}px` }" />
        <div :style="{ width: `${size}px`, height: `${size}px` }" />
        <div :style="{ width: `${size}px`, height: `${size}px` }" />
    </div>
</template>

<script>
export default {
    name: 'Loader',
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        size: {
            type: Number,
            default: 20,
        },
        color: {
            type: String,
            default: 'black',
        },
    },
};
</script>

<style scoped>
.lds-ring {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    border: 2px solid #000000;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #000000 transparent transparent transparent;
}
.lds-ring--white div {
    border-color: #ffffff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
