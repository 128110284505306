<template>
    <div>
        <div class="position-relative">
            <div
                id="wrapperId"
                :class="[{ scrollable: isScrollable }, { [gradientPosition]: gradientPosition && isScrollable }]"
            >
                <table id="tableId" ref="table" aria-label="All funds table" class="w-100">
                    <FundsThead
                        :showSkeleton="showSkeleton"
                        :columnsToShow="columnsToShow"
                        :class="{ scrollable: isScrollable }"
                        :hideShareclass="hideShareclassCell"
                    />
                    <tbody>
                        <FundsTableRow
                            v-for="(fund, idx) in fundsOrSkeleton"
                            :key="fund.id"
                            :fund="fund"
                            :columnsToShow="columnsToShow"
                            :fundIdx="idx"
                            :class="{ scrollable: isScrollable }"
                            :hideShareclass="hideShareclassCell"
                        />
                    </tbody>
                </table>
            </div>
        </div>

        <CustomScroll
            v-if="isScrollable"
            tableId="tableId"
            wrapperId="wrapperId"
            @changeGradientPosition="changeGradientPosition"
        />
    </div>
</template>

<script>
import ResizeObserver from 'resize-observer-polyfill';
import { mapState } from 'vuex';

import CustomScroll from '@/components/Functional/CustomScroll';
import FundsTableRow from '@/components/Pages/FundsCenter/AllFunds/FundsListing/FundsTableParts/FundsTableRow.vue';
import FundsThead from '@/components/Pages/FundsCenter/AllFunds/FundsListing/FundsTableParts/FundsThead.vue';
import deviceType from '@/mixins/DeviceType.mixin';

export default {
    name: 'FundsTable',
    components: { FundsTableRow, FundsThead, CustomScroll },
    mixins: [deviceType],
    data() {
        return {
            gradientPosition: 'gradient-right',
            showScroll: false,
        };
    },
    computed: {
        ...mapState('allFunds', ['funds', 'isLoading', 'isPaginationLoading', 'selectedTab']),
        optionalColumns() {
            if (this.selectedTab === 'prices') {
                return {
                    general: [
                        {
                            propertyName: 'fundType',
                            visibleName: this.$t('fundsFundCentreFiltersFundType'),
                        },
                    ],
                    shareclassSpecific: [
                        {
                            propertyName: 'newspaperName',
                            visibleName: this.$t('newspaperName'),
                        },
                        {
                            propertyName: 'dailyPrice',
                            visibleName: this.$t('fundCentrePricesDailyPrice'),
                        },
                        {
                            propertyName: 'priceChange',
                            visibleName: this.$t('fundCentrePricesPriceChange'),
                        },
                        {
                            propertyName: 'currency',
                            visibleName: this.$t('fundCentrePricesCurrency'),
                        },
                        {
                            propertyName: 'nav',
                            visibleName: this.$t('fundCentrePricesNav'),
                        },
                        {
                            propertyName: 'offerPrice',
                            visibleName: this.$t('fundCentrePricesOffer'),
                        },
                        {
                            propertyName: 'bidPrice',
                            visibleName: this.$t('fundCentrePricesBid'),
                        },
                        {
                            propertyName: 'assetSize',
                            visibleName: this.$t('fundCentrePricesAssetSize'),
                        },
                        {
                            propertyName: 'navPrice',
                            visibleName: this.$t('fundCentrePricesNavPrice'),
                        },
                        {
                            propertyName: 'applicationPrice',
                            visibleName: this.$t('fundCentrePricesApplicationPrice'),
                        },
                        {
                            propertyName: 'redemptionPrice',
                            visibleName: this.$t('fundCentrePricesRedemptionPrice'),
                        },
                    ],
                };
            }

            return {
                general: [
                    {
                        propertyName: 'fundType',
                        visibleName: this.$t('fundsFundCentreFiltersFundType'),
                    },
                    {
                        propertyName: 'sfdrClassification',
                        visibleName: this.$t('sfdrClassification'),
                    },
                    {
                        propertyName: 'assetClass',
                        visibleName: this.$t('assetClass'),
                    },
                    {
                        propertyName: 'morningstar',
                        visibleName: this.$t('morningstar'),
                    },
                    {
                        propertyName: 'categoryX',
                        visibleName: this.$t('categoryX'),
                    },
                    {
                        propertyName: 'newspaperName',
                        visibleName: this.$t('newspaperName'),
                    },
                ],
                shareclassSpecific: [
                    {
                        propertyName: 'factsheet',
                        visibleName: this.$t('factsheet'),
                    },
                    {
                        propertyName: 'kid',
                        visibleName: this.$t('kid'),
                    },
                    {
                        propertyName: 'kiid',
                        visibleName: this.$t('kiid'),
                    },
                ],
            };
        },
        columnsToShow() {
            const output = {
                general: [],
                shareclassSpecific: [],
            };
            if (!this.funds.length) return output;
            this.funds.forEach((fund) => {
                this.optionalColumns.general.forEach((column, index) => {
                    if (fund.hasOwnProperty(column.propertyName) && !output.general.includes(column)) {
                        output.general.splice(index, 0, column);
                    }
                });
                this.optionalColumns.shareclassSpecific.forEach((column, index) => {
                    if (
                        fund.shareclasses[0]?.hasOwnProperty(column.propertyName) &&
                        !output.shareclassSpecific.includes(column)
                    ) {
                        output.shareclassSpecific.splice(index, 0, column);
                    }
                });
            });
            return output;
        },
        fundsOrSkeleton() {
            if (this.showSkeleton) {
                return new Array(20).fill({ skeleton: true });
            }
            return this.funds;
        },
        showSkeleton() {
            return this.isLoading || this.isPaginationLoading;
        },
        isScrollable() {
            return this.selectedTab === 'prices' && this.showScroll && this.deviceType === 'desktop';
        },
        position() {
            return this.gradientPosition;
        },
        hideShareclassCell() {
            return this.funds.some((fund) => fund.shareclasses[0]?.shareclassName === '');
        },
    },
    mounted() {
        this.initResizeObserver();
    },
    beforeDestroy() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    },
    methods: {
        changeGradientPosition(val) {
            this.gradientPosition = val;
        },
        initResizeObserver() {
            const containerWidth = 1170;
            this.resizeObserver = new ResizeObserver((entries) => {
                for (const entry of entries) {
                    if (entry.contentRect.width > containerWidth) {
                        this.showScroll = true;
                    }
                }
            });
            this.resizeObserver.observe(this.$refs.table);
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
table {
    --default-table-border: 1px solid rgba(0, 0, 0, 0.3);
}
::v-deep .scrollable {
    table {
        th,
        td {
            min-width: calc(var(--spacer) * 23);
            &:last-child {
                padding-right: 20px;
            }
        }
    }
}
@include media-breakpoint-up(xl) {
    ::v-deep tr {
        border-bottom: var(--default-table-border);
        &.skeleton-row {
            border-bottom-color: transparent;
        }

        th,
        td {
            padding: calc(var(--spacer) * 3);
            min-width: calc(64px + calc(var(--spacer) * 3));
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }
        }

        th + th,
        td + td {
            margin-left: calc(var(--spacer) * 3);
        }

        th:nth-child(1),
        td:nth-child(1) {
            width: calc(270px + calc(var(--spacer) * 3));
            min-width: calc(270px + calc(var(--spacer) * 3));
        }

        th:nth-child(2),
        td:nth-child(2) {
            width: calc(208px + calc(var(--spacer) * 6));
            min-width: calc(208px + calc(var(--spacer) * 6));
        }
    }
}
@include media-breakpoint-down(xl) {
    table {
        thead {
            display: none;
        }
        tbody {
            display: block;
        }
    }
}
</style>
