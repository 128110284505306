export const formFieldsStep4 = {
    rows: [
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                            element: {
                                type: 'FormFieldGroup',
                                id: '334',
                                depth: 'complete',
                                name: 'Remuneration for portfolio manager fee (step 4 of 7)',
                                instructions: '',
                                style: {
                                    textType: 'header',
                                    fieldSize: 'large',
                                    guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                                },
                                unique: true,
                            },
                        },
                    ],
                },
            ],
            type: 'FormFieldGroup',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                            element: {
                                type: 'FormFieldGroup',
                                id: '334',
                                depth: 'complete',
                                name: 'Frequency',
                                instructions: '',
                                style: {
                                    textType: 'subheader',
                                    fieldSize: 'large',
                                    guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                                },
                                unique: true,
                            },
                        },
                    ],
                },
            ],
            type: 'FormFieldGroup',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                            element: {
                                type: 'FormFieldGroup',
                                id: '334',
                                depth: 'complete',
                                name: "Fees are deducted monthly from client portfolios on or around the 22nd. They will be paid to your bank account on the 25th of teh month. You can choose the frequency with which you'd like to be paid.",
                                instructions: '',
                                style: {
                                    textType: 'body',
                                    fieldSize: 'large',
                                    guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                                },
                                unique: true,
                            },
                        },
                    ],
                },
            ],
            type: 'FormFieldGroup',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '00N6900000Kw00Y',
                            element: {
                                type: 'FormField',
                                id: 'Free_deduction_option__c',
                                name: 'How often should your model portfolio manager fee be paid into your account?',
                                instructions: '',
                                fieldDescription: '',
                                relatesTo: {
                                    value: 'Frequency',
                                },
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kw00Y',
                                },
                                dataType: 'text',
                                defaultValue: 'Monthly',
                                displayType: 'radio',
                                htmlName: '00N6900000Kw00Y',
                                optionListId: '202',
                                useGlobalSubscriptionStatus: 'False',
                                validations: [
                                    {
                                        type: 'FieldValidation',
                                        id: '19608',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventUrlCondition' },
                                        isEnabled: 'true',
                                        message: "Value must not contain any URL's",
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19609',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventXSSCondition' },
                                        isEnabled: 'true',
                                        message: 'Value must not contain any HTML',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19610',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'IsRequiredCondition' },
                                        isEnabled: 'true',
                                        message: 'This field is required',
                                    },
                                ],
                                optionList: {
                                    type: 'OptionList',
                                    id: '202',
                                    createdAt: '0',
                                    createdBy: '0',
                                    depth: 'complete',
                                    name: 'Arranging deals in investments',
                                    updatedAt: '0',
                                    updatedBy: '0',
                                    elements: [
                                        {
                                            type: 'Option',
                                            displayName: 'Monthly',
                                            value: 'Monthly',
                                        },
                                        {
                                            type: 'Option',
                                            displayName: 'Quarterly (Mar, Jun, Sept & Dec)',
                                            value: 'Quarterly (Mar, Jun, Sept & Dec)',
                                        },
                                        {
                                            type: 'Option',
                                            displayName: 'Half-year (Jan and Jul)',
                                            value: 'Half-year (Jan and Jul)',
                                        },
                                        {
                                            type: 'Option',
                                            displayName: 'Yearly (Jul)',
                                            value: 'Yearly (Jul)',
                                        },
                                    ],
                                },
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                            element: {
                                type: 'FormFieldGroup',
                                id: '334',
                                depth: 'complete',
                                name: 'Bank details',
                                instructions: '',
                                style: {
                                    textType: 'subheader',
                                    fieldSize: 'large',
                                    guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                                },
                                unique: true,
                            },
                        },
                    ],
                },
            ],
            type: 'FormFieldGroup',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '00N6900000Kvzsg',
                            element: {
                                type: 'FormField',
                                id: 'Bank_account_name__c',
                                name: 'Account name',
                                relatesTo: {
                                    value: 'Bank details',
                                },
                                description: '',
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kvzsg',
                                },
                                createdFromContactFieldId: '100003',
                                dataType: 'text',
                                displayType: 'text',
                                htmlName: '00N6900000Kvzsg',
                                useGlobalSubscriptionStatus: 'False',
                                validations: [
                                    {
                                        type: 'FieldValidation',
                                        id: '19599',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventUrlCondition' },
                                        isEnabled: 'true',
                                        message: "Value must not contain any URL's",
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19600',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventXSSCondition' },
                                        isEnabled: 'true',
                                        message: 'Value must not contain any HTML',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19601',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: {
                                            type: 'TextLengthCondition',
                                            maximum: '100',
                                            minimum: '0',
                                        },
                                        isEnabled: 'true',
                                        message: 'Invalid length for field value',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19610',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'IsRequiredCondition' },
                                        isEnabled: 'true',
                                        message: 'This field is required',
                                    },
                                ],
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '00N6900000Kvzsg',
                            element: {
                                type: 'FormField',
                                id: 'Bank_account_number__c',
                                name: 'Account number',
                                relatesTo: {
                                    value: 'Bank details',
                                },
                                description: '',
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kvzsg',
                                },
                                createdFromContactFieldId: '100003',
                                dataType: 'text',
                                displayType: 'text',
                                htmlName: '00N6900000Kvzsg',
                                useGlobalSubscriptionStatus: 'False',
                                validations: [
                                    {
                                        type: 'FieldValidation',
                                        id: '19599',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventUrlCondition' },
                                        isEnabled: 'true',
                                        message: "Value must not contain any URL's",
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19600',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventXSSCondition' },
                                        isEnabled: 'true',
                                        message: 'Value must not contain any HTML',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19601',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: {
                                            type: 'TextLengthCondition',
                                            maximum: '20',
                                            minimum: '0',
                                        },
                                        isEnabled: 'true',
                                        message: 'Invalid length for field value',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19610',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'IsRequiredCondition' },
                                        isEnabled: 'true',
                                        message: 'This field is required',
                                    },
                                ],
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '00N6900000Kvzsg',
                            element: {
                                type: 'FormField',
                                id: 'Bank_sort_code__c',
                                name: 'Sort code',
                                relatesTo: {
                                    value: 'Bank details',
                                },
                                description: '',
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kvzsg',
                                },
                                createdFromContactFieldId: '100003',
                                dataType: 'text',
                                displayType: 'text',
                                htmlName: '00N6900000Kvzsg',
                                useGlobalSubscriptionStatus: 'False',
                                validations: [
                                    {
                                        type: 'FieldValidation',
                                        id: '19599',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventUrlCondition' },
                                        isEnabled: 'true',
                                        message: "Value must not contain any URL's",
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19600',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventXSSCondition' },
                                        isEnabled: 'true',
                                        message: 'Value must not contain any HTML',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19601',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: {
                                            type: 'TextLengthCondition',
                                            maximum: '20',
                                            minimum: '0',
                                        },
                                        isEnabled: 'true',
                                        message: 'Invalid length for field value',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19610',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'IsRequiredCondition' },
                                        isEnabled: 'true',
                                        message: 'This field is required',
                                    },
                                ],
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '00N6900000Kvzsg',
                            element: {
                                type: 'FormField',
                                id: 'Bank_Name__c',
                                name: 'Bank name',
                                relatesTo: {
                                    value: 'Bank details',
                                },
                                description: '',
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kvzsg',
                                },
                                createdFromContactFieldId: '100003',
                                dataType: 'text',
                                displayType: 'text',
                                htmlName: '00N6900000Kvzsg',
                                useGlobalSubscriptionStatus: 'False',
                                validations: [
                                    {
                                        type: 'FieldValidation',
                                        id: '19599',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventUrlCondition' },
                                        isEnabled: 'true',
                                        message: "Value must not contain any URL's",
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19600',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventXSSCondition' },
                                        isEnabled: 'true',
                                        message: 'Value must not contain any HTML',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19601',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: {
                                            type: 'TextLengthCondition',
                                            maximum: '100',
                                            minimum: '0',
                                        },
                                        isEnabled: 'true',
                                        message: 'Invalid length for field value',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19610',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'IsRequiredCondition' },
                                        isEnabled: 'true',
                                        message: 'This field is required',
                                    },
                                ],
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                            element: {
                                type: 'FormFieldGroup',
                                id: '334',
                                depth: 'complete',
                                name: 'Bank address',
                                instructions: '',
                                style: {
                                    textType: 'subheader',
                                    fieldSize: 'large',
                                    guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                                },
                                unique: true,
                            },
                        },
                    ],
                },
            ],
            type: 'FormFieldGroup',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '00N6900000Kvzsg',
                            element: {
                                type: 'FormField',
                                id: 'Bank_Building_number__c',
                                name: 'Building number',
                                relatesTo: {
                                    value: 'Bank address',
                                },
                                description: '',
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kvzsg',
                                },
                                createdFromContactFieldId: '100003',
                                dataType: 'text',
                                displayType: 'text',
                                htmlName: '00N6900000Kvzsg',
                                useGlobalSubscriptionStatus: 'False',
                                validations: [
                                    {
                                        type: 'FieldValidation',
                                        id: '19599',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventUrlCondition' },
                                        isEnabled: 'true',
                                        message: "Value must not contain any URL's",
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19600',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventXSSCondition' },
                                        isEnabled: 'true',
                                        message: 'Value must not contain any HTML',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19601',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: {
                                            type: 'TextLengthCondition',
                                            maximum: '100',
                                            minimum: '0',
                                        },
                                        isEnabled: 'true',
                                        message: 'Invalid length for field value',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19610',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'IsRequiredCondition' },
                                        isEnabled: 'true',
                                        message: 'This field is required',
                                    },
                                ],
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '00N6900000Kvzsg',
                            element: {
                                type: 'FormField',
                                id: 'Bank_street__c',
                                name: 'Street name',
                                relatesTo: {
                                    value: 'Bank address',
                                },
                                description: '',
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kvzsg',
                                },
                                createdFromContactFieldId: '100003',
                                dataType: 'text',
                                displayType: 'text',
                                htmlName: '00N6900000Kvzsg',
                                useGlobalSubscriptionStatus: 'False',
                                validations: [
                                    {
                                        type: 'FieldValidation',
                                        id: '19599',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventUrlCondition' },
                                        isEnabled: 'true',
                                        message: "Value must not contain any URL's",
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19600',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventXSSCondition' },
                                        isEnabled: 'true',
                                        message: 'Value must not contain any HTML',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19601',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: {
                                            type: 'TextLengthCondition',
                                            maximum: '100',
                                            minimum: '0',
                                        },
                                        isEnabled: 'true',
                                        message: 'Invalid length for field value',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19610',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'IsRequiredCondition' },
                                        isEnabled: 'true',
                                        message: 'This field is required',
                                    },
                                ],
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '00N6900000Kvzsg',
                            element: {
                                type: 'FormField',
                                id: 'Bank_City_Town__c',
                                name: 'City / Town',
                                description: '',
                                relatesTo: {
                                    value: 'Bank address',
                                },
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kvzsg',
                                },
                                createdFromContactFieldId: '100003',
                                dataType: 'text',
                                displayType: 'text',
                                htmlName: '00N6900000Kvzsg',
                                useGlobalSubscriptionStatus: 'False',
                                validations: [
                                    {
                                        type: 'FieldValidation',
                                        id: '19599',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventUrlCondition' },
                                        isEnabled: 'true',
                                        message: "Value must not contain any URL's",
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19600',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventXSSCondition' },
                                        isEnabled: 'true',
                                        message: 'Value must not contain any HTML',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19601',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: {
                                            type: 'TextLengthCondition',
                                            maximum: '100',
                                            minimum: '0',
                                        },
                                        isEnabled: 'true',
                                        message: 'Invalid length for field value',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19610',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'IsRequiredCondition' },
                                        isEnabled: 'true',
                                        message: 'This field is required',
                                    },
                                ],
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '00N6900000Kvzsg',
                            element: {
                                type: 'FormField',
                                id: 'Bank_Postcode__c',
                                name: 'Postcode',
                                description: '',
                                relatesTo: {
                                    value: 'Bank address',
                                },
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kvzsg',
                                },
                                createdFromContactFieldId: '100003',
                                dataType: 'text',
                                displayType: 'text',
                                htmlName: '00N6900000Kvzsg',
                                useGlobalSubscriptionStatus: 'False',
                                validations: [
                                    {
                                        type: 'FieldValidation',
                                        id: '19599',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventUrlCondition' },
                                        isEnabled: 'true',
                                        message: "Value must not contain any URL's",
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19600',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventXSSCondition' },
                                        isEnabled: 'true',
                                        message: 'Value must not contain any HTML',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19601',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: {
                                            type: 'TextLengthCondition',
                                            maximum: '20',
                                            minimum: '0',
                                        },
                                        isEnabled: 'true',
                                        message: 'Invalid length for field value',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19610',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'IsRequiredCondition' },
                                        isEnabled: 'true',
                                        message: 'This field is required',
                                    },
                                ],
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
    ],
};
