<template>
    <div
        class="meganav__body__mobile"
        :class="{ active, left: positionX === 'left', right: positionX === 'right' }"
        :style="{ width: `${width}%`, ...(height ? { height: `${height}px` } : {}) }"
    >
        <div role="button" class="overlay d-none d-sm-block" @click="close()" />
        <div
            class="meganav__body__mobile__inner py-5 background-white"
            :class="{ left: positionX === 'left', right: positionX === 'right' }"
        >
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'AsideModal',
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        positionX: {
            type: String,
            default: 'left',
        },
        width: {
            type: Number,
            default: 80,
        },
        height: {
            type: Number,
            default: 0,
        },
    },
    methods: {
        close() {
            this.$emit('onClose');
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
.overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    z-index: -1;
}
.meganav__body__mobile {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 1001;
    pointer-events: none;
    height: 100%;
    &.left {
        left: 0;
    }
    &.right {
        right: 0;
    }
    @include media-breakpoint-down(md) {
        width: 100% !important;
    }
    .meganav__body__mobile__inner {
        transition: transform 0.3s ease-in-out;
        height: 100%;
        padding: 68px 0 80px 0 !important;
        overflow: auto;
        &.left {
            transform: translateX(-100%);
        }
        &.right {
            transform: translateX(100%);
        }
    }

    &.active {
        pointer-events: all;
        .meganav__body__mobile__inner {
            transform: none;
        }
        .overlay {
            opacity: 1;
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.25s, transform 0.25s;
}
.fade-leave-active {
    transition-timing-function: ease-out;
}
.fade-enter-active {
    transition-timing-function: ease-in;
}

.fade-enter-active {
    transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
.fade-leave-to {
    transform: translateX(30px);
}
.fade-enter {
    transform: translateX(-30px);
}
</style>
