<template>
    <svg viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.53984 0.885435C9.83273 1.19053 10.0703 1.43786 10.0703 1.43786L5.04004 6.66663C5.04004 6.66663 4.80255 6.43046 4.50965 6.12536L0.0096531 1.43786C0.0096531 1.43786 0.247146 1.1905 0.540039 0.885398C0.832932 0.580301 1.07031 0.333008 1.07031 0.333008L5.03998 4.46808L9.00965 0.333008C9.00965 0.333008 9.24694 0.580338 9.53984 0.885435Z"
            fill="currentColor"
        />
    </svg>
</template>

<script>
export default {
    name: 'IconChevron',
};
</script>

<style scoped></style>
