<template>
    <div class="search__suggests" :class="{ ['search__suggests--w-message']: messageText }">
        <Loader v-if="isLoading" :size="16" class="mx-auto d-flex my-2" />
        <div v-else class="search__suggests__content" role="listbox">
            <p v-if="messageText" class="search__suggests__message section-header-small p-2" aria-hidden="true">
                {{ messageText }}
            </p>
            <p class="sr-only" />
            <ul v-if="suggestions.length">
                <li v-for="(suggestion, idx) in suggestions" :key="suggestion.name" role="option">
                    <button
                        :id="'suggestion-' + idx"
                        type="button"
                        aria-hidden="true"
                        class="field-dropdown-expanded-label btn btn-naked p-2 suggest-option"
                        @click="selectHandler(suggestion)"
                        @keydown.down.prevent="$emit('selectFilterOptionWithArrow', { direction: 'down', idx })"
                        @keydown.up.prevent="$emit('selectFilterOptionWithArrow', { direction: 'up', idx })"
                        @focus="$emit('suggestOptionFocusHandler', idx)"
                    >
                        <strong>{{ suggestion.name }}</strong>
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Loader from '@/components/Generic/Loader.vue';

export default {
    name: 'BaseSearchSuggests',
    components: { Loader },
    props: {
        suggestions: {
            type: Array,
            default: /* istanbul ignore next */ () => [],
        },
        messageText: {
            type: String,
            default: '',
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        searchQuery: {
            type: String,
            default: '',
        },
    },
    methods: {
        selectHandler(suggestion) {
            this.$emit('onSelect', suggestion);
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
@import '@/assets/styles/_mixins.scss';
ul {
    padding: 0;
    margin: 0;
    list-style: none;
    width: 100%;
}
button {
    transition: background-color 0.2s ease-in-out;
    width: 100%;
    &:hover,
    &:focus {
        background-color: var(--button-mist);
        outline: none;
    }
}
.search__suggests {
    background-color: var(--abrdn-white);
    max-height: 230px;
    overflow: auto;
    box-shadow: 0 16px 100px rgba(9, 9, 14, 0.12);
    @include formScrollbar();
    @include media-breakpoint-down(lg) {
        max-height: 200px;
    }
}
.search__suggests--w-message {
    max-height: 288px;
    @include media-breakpoint-down(lg) {
        max-height: 240px;
    }
    @include media-breakpoint-down(md) {
        max-height: 304px;
    }
}
.search__suggests__message:not(:last-child) {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        left: calc(var(--spacer) * 2);
        right: calc(var(--spacer) * 2);
        bottom: 0;
        height: 1px;
        background-color: var(--digital-steel-grey);
        pointer-events: none;
    }
}
button strong {
    font-weight: 800;
}
</style>
