import { apiGetArticleFacets, apiSearchArticles } from '@/api/filters';
import { getArticles, getEmptyFacets, getFacets } from '@/store/reusableActions/filterActions';
import {
    setApprovedFilters,
    setApprovedFiltersBuffer,
    setBaseUrl,
    setData,
    setEmptyFacetsData,
    setFacetsData,
    setFilterMappings,
    setFilters,
    setFiltersForApprove,
    setLoading,
    setPaginationData,
} from '@/store/reusableMutations/filterMutations';
import { baseFilterState } from '@/store/reusableStates/baseFilterState';

const state = () =>
    baseFilterState({
        searchRequest: apiSearchArticles,
        facetsRequest: apiGetArticleFacets,
    });

// actions
const actions = {
    getArticles,
    getFacets,
    getEmptyFacets,
};

const mutations = {
    setFilters,
    setData,
    setLoading,
    setFiltersForApprove,
    setApprovedFilters,
    setApprovedFiltersBuffer,
    setFacetsData,
    setEmptyFacetsData,
    setBaseUrl,
    setPaginationData,
    setFilterMappings,
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
