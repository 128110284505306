import { render, staticRenderFns } from "./FundDetailsBreakdownCountryTable.vue?vue&type=template&id=d4a2e0ee"
import script from "./FundDetailsBreakdownCountryTable.vue?vue&type=script&lang=js"
export * from "./FundDetailsBreakdownCountryTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports