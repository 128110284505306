<template>
    <div>
        <h3>{{ $t('fundDetailsPremiumDiscountGraphDataTitle') }}</h3>

        <AlertMessageBase v-if="error" type="error" :title="$t(error)" class="mt-4" />

        <template v-else>
            <div class="mt-4">
                <h4>{{ $t('fundDetailsPremiumDiscountLineGraphTitle') }}</h4>
                <FundDetailsPremiumDiscountLineChart class="mt-4" />
            </div>

            <div class="mt-6">
                <h4>{{ $t('fundDetailsPremiumDiscountDataTableTitle') }}</h4>
                <FundDetailsPremiumDiscountLineChartTable class="mt-4" />
            </div>
        </template>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import AlertMessageBase from '@/components/Generic/AlertMessageBase';

import FundDetailsPremiumDiscountLineChart from './FundDetailsPremiumDiscountLineChart';
import FundDetailsPremiumDiscountLineChartTable from './FundDetailsPremiumDiscountLineChartTable';

export default {
    name: 'FundDetailsPremiumDiscountLineChartSection',

    components: {
        FundDetailsPremiumDiscountLineChart,
        FundDetailsPremiumDiscountLineChartTable,
        AlertMessageBase,
    },

    computed: {
        ...mapState('fundDetails', {
            data: (state) => state.premiumLineChartData.data,
            error: (state) => state.premiumLineChartData.error,
        }),

        ...mapGetters('fundDetails', ['selectedShareClass']),
    },

    async created() {
        if (this.isExperienceEditor) return;

        if (!this.data) {
            const {
                displayLanguage,
                site: { name },
            } = this.$jss.sitecoreContext();

            await this.fetchData({
                language: displayLanguage,
                site: name,
                shareClass: this.selectedShareClass.id,
            });
        }
    },

    methods: {
        ...mapActions('fundDetails', {
            fetchData: 'fetchPremiumLineChartData',
        }),
    },
};
</script>
