<template>
    <div>
        <div v-for="(item, index) in items" :key="item.id" :class="{ 'mt-3': index !== 0 }">
            <template v-if="item.template === 'Automatic Document Listings'">
                <ScText v-if="item.fields.title" tag="h5" :field="item.fields.title" class="mb-3" />
                <DocumentListingBase :editionId="item.fields.editionId && item.fields.editionId.value" />
            </template>
        </div>
    </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import DocumentListingBase from '@/components/Generic/DocumentListingBase';

export default {
    name: 'AccordionItems',
    components: {
        DocumentListingBase,
        ScText: Text,
    },
    props: {
        items: {
            type: Array,
            default: () => [],
        },
    },
};
</script>
