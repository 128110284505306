var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.article)?_c('section',{staticClass:"background-white",attrs:{"id":_vm.anchorId}},[_c('div',{staticClass:"section-header-wrapper"},[_c('WrappedImage',{staticClass:"section-background",attrs:{"media":_vm.article.image,"srcSet":[
                { mw: 400 },
                { mw: 600 },
                { mw: 800 },
                { mw: 1000 },
                { mw: 1200 },
                { mw: 1400 },
                { mw: 1600 },
                { mw: 1800 },
                { mw: 2000 } ],"sizes":"100vw","lazy":false}}),_c('div',{staticClass:"mt-auto pt-10"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10 col-md-9 col-lg-7"},[_c('div',{staticClass:"section-header"},[(_vm.article.contentGroup || _vm.article.category)?_c('div',{staticClass:"mb-2 d-flex flex-column flex-md-row align-items-md-center gap-1 gap-md-4"},[(_vm.article.contentGroup)?_c('div',[_c('div',{staticClass:"h5 text-nowrap"},[_vm._v(_vm._s(_vm.article.contentGroup))])]):_vm._e(),(_vm.article.category)?_c('div',[_c('Badge',[_vm._v(_vm._s(_vm.article.category))])],1):_vm._e()]):_vm._e(),_c('ScText',{staticClass:"h2",attrs:{"tag":"h2","field":_vm.article.title}})],1)])])])])],1),_c('div',{staticClass:"container"},[_c('div',{staticClass:"py-4 py-lg-8"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-9"},[_c('ScText',{staticClass:"subheader-1 text-grey",attrs:{"tag":"p","field":_vm.article.strapline}})],1)]),(_vm.authorsLength || _vm.article.contributors)?_c('div',{staticClass:"row mt-4 mt-md-5 mt-lg-6"},[_c('div',{staticClass:"col-12 col-md-9 col-lg-12"},[_c('div',{staticClass:"row mt-n3"},[(_vm.authorsLength)?_c('ArticleAuthors',{staticClass:"col-12 col-lg-6 mt-3",attrs:{"authors":_vm.article.authors}}):_vm._e(),(_vm.article.contributors)?_c('ArticleContributors',{staticClass:"col-12 col-lg-6 mt-3",attrs:{"contributors":_vm.article.contributors}}):_vm._e()],1)])]):_vm._e()])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }