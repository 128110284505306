<template>
    <svg
        width="108"
        height="108"
        viewBox="0 0 108 108"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
    >
        <rect x="16" y="24" width="76" height="6" rx="3" fill="currentColor" />
        <rect x="16" y="42" width="76" height="6" rx="3" fill="currentColor" />
        <rect x="16" y="60" width="76" height="6" rx="3" fill="currentColor" />
        <rect x="16" y="78" width="48" height="6" rx="3" fill="currentColor" />
    </svg>
</template>

<script>
export default {
    name: 'IconArticle',
};
</script>

<style scoped></style>
