import { getFundDetails, getFundDetailsTabData, getFundDetailsTabDataEndpoints } from '@/api/funds';

const getInitialTabDataState = () => ({
    loading: false,
    error: null,
    data: null,
});

export const getInitialState = () => ({
    error: '',
    isLoading: false,
    name: '',
    fundId: null,
    fundSize: null,
    shareClasses: [],
    selectedShareClassIsin: '',
    assetClassName: '',
    availableTabs: null,
    fundRangeName: null,
    fundHeroType: 0,
    formJson: '',
    literatureAuthorizations: null,
    isFundDetailsImportantInfoAvailable: false,
    correlationFundRangeID: null,
    isPageAvailable: true,
    isFundDetailsInvTrustDisclaimerAvailable: false,
    literatureTabSelectedSubTab: getInitialTabDataState(),
    cumulativeQuarterlyData: getInitialTabDataState(),
    annualizedQuarterlyData: getInitialTabDataState(),
    managementTabData: getInitialTabDataState(),
    literatureTabData: getInitialTabDataState(),
    derivativeUsageTabData: getInitialTabDataState(),
    keyRiskTabData: getInitialTabDataState(),
    portfolioSecuritiesTabData: getInitialTabDataState(),
    codesTabData: getInitialTabDataState(),
    riskAnalysisTabData: getInitialTabDataState(),
    fundDetailsImportantInformationData: getInitialTabDataState(),
    fundFactsTabData: getInitialTabDataState(),
    fundRiskStatisticsTabData: getInitialTabDataState(),
    cumulativeTabData: getInitialTabDataState(),
    cumulativeDailyTabData: getInitialTabDataState(),
    annualizedTabData: getInitialTabDataState(),
    annualizedDailyTabData: getInitialTabDataState(),
    keyInformationTabData: getInitialTabDataState(),
    holdingsTabData: getInitialTabDataState(),
    discreteTabData: getInitialTabDataState(),
    calendarTabData: getInitialTabDataState(),
    fundsPerformanceDisclaimerData: getInitialTabDataState(),
    fundDetailsDocuments: getInitialTabDataState(),
    breakdownSectorData: getInitialTabDataState(),
    breakdownCountryData: getInitialTabDataState(),
    breakdownCreditProfileData: getInitialTabDataState(),
    breakdownMaturityProfileData: getInitialTabDataState(),
    authorisedCountriesTabData: getInitialTabDataState(),
    tradingInformationTabData: getInitialTabDataState(),
    cumulativeChartData: getInitialTabDataState(),
    filteredCumulativeChartData: getInitialTabDataState(),
    correlationMatrixTabData: getInitialTabDataState(),
    premiumBarChartData: getInitialTabDataState(),
    premiumLineChartData: getInitialTabDataState(),
    podcastTabData: getInitialTabDataState(),
    keyInfoTabManualDisclaimerData: getInitialTabDataState(),
    holdingsTabManualDisclaimerData: getInitialTabDataState(),
    preliminaryImportantInfo: getInitialTabDataState(),
});

const state = getInitialState;

const getters = {
    selectedShareClass(state) {
        return (
            state.shareClasses.find(({ isin }) => isin === state.selectedShareClassIsin.toUpperCase()) ||
            state.shareClasses[0]
        );
    },
    shareClassByIsin: (state) => (isin) => {
        return state.shareClasses.find((shareClass) => shareClass.isin === isin);
    },
};

const actions = {
    resetState({ commit }) {
        commit('resetState');
    },
    async fetchFundDetails({ commit }, payload) {
        try {
            commit('setError', null);
            commit('setLoading', true);
            const { data } = await getFundDetails(payload);
            commit('setFundDetailsData', data);
        } catch (error) {
            commit('setError', error);
        }
    },
    async fetchManagementTabData({ commit }, payload) {
        return fetchData({
            commit,
            payload,
            tabDataKey: 'managementTabData',
            endpoint: getFundDetailsTabDataEndpoints.MANAGEMENT,
            getData: (data) => data.management,
        });
    },
    async fetchLiteratureTabData({ commit }, payload) {
        return fetchData({
            commit,
            payload,
            tabDataKey: 'literatureTabData',
            endpoint: getFundDetailsTabDataEndpoints.LITERATURE,
            getData: (data) => {
                const { title } = data[0] || {};
                title && commit('setTabDataData', ['literatureTabSelectedSubTab', title]);
                return data;
            },
        });
    },
    async fetchFundFactsTabData({ commit }, payload) {
        return fetchData({
            commit,
            payload,
            tabDataKey: 'fundFactsTabData',
            endpoint: getFundDetailsTabDataEndpoints.FUND_OBJECTIVES,
            getData: (data) => data.fundObjectives,
        });
    },
    async fetchDerivativeTabData({ commit }, payload) {
        return fetchData({
            commit,
            payload,
            tabDataKey: 'derivativeUsageTabData',
            endpoint: getFundDetailsTabDataEndpoints.DERIVATIVE_USAGE,
            getData: (data) => data.derivativeUsage,
        });
    },
    async fetchKeyRiskTabData({ commit }, payload) {
        return fetchData({
            commit,
            payload,
            tabDataKey: 'keyRiskTabData',
            endpoint: getFundDetailsTabDataEndpoints.KEY_RISK,
            getData: (data) => data.keyRisk,
        });
    },
    async fetchPortfolioSecuritiesTabData({ commit }, payload) {
        return fetchData({
            commit,
            payload,
            tabDataKey: 'portfolioSecuritiesTabData',
            endpoint: getFundDetailsTabDataEndpoints.PORTFOLIO_SECURITIES,
            getData: (data) => data.portfolioSecurities,
        });
    },
    async fetchCodesTabData({ commit }, payload) {
        return fetchData({
            commit,
            payload,
            tabDataKey: 'codesTabData',
            endpoint: getFundDetailsTabDataEndpoints.CODES,
            getData: (data) => data.codes,
        });
    },
    async fetchRiskAnalysisTabData({ commit }, payload) {
        return fetchData({
            commit,
            payload,
            tabDataKey: 'riskAnalysisTabData',
            endpoint: getFundDetailsTabDataEndpoints.RISK_ANALYSIS,
            getData: (data) => {
                data.results.forEach((item) => {
                    item.columns.sort((a, b) => a.columnDisplayOrder - b.columnDisplayOrder);
                });
                return data;
            },
        });
    },
    async fetchCorrelationMatrixTabData({ commit }, payload) {
        return fetchData({
            commit,
            payload,
            tabDataKey: 'correlationMatrixTabData',
            endpoint: getFundDetailsTabDataEndpoints.CORRELATION_MATRIX,
        });
    },
    async fetchFundDetailsImportantInformation({ commit }, payload) {
        return fetchData({
            commit,
            payload,
            tabDataKey: 'fundDetailsImportantInformationData',
            endpoint: getFundDetailsTabDataEndpoints.IMPORTANT_INFORMATION,
            getData: (data) => data.importantInformation,
        });
    },
    async fetchFundRiskStatisticsTabData({ commit }, payload) {
        return fetchData({
            commit,
            payload,
            tabDataKey: 'fundRiskStatisticsTabData',
            endpoint: getFundDetailsTabDataEndpoints.FUND_RISK_STATISTICS,
        });
    },
    async fetchCumulativeTabData({ commit }, payload) {
        return fetchData({
            commit,
            payload,
            tabDataKey: 'cumulativeTabData',
            endpoint: getFundDetailsTabDataEndpoints.PERFORMANCE.CUMULATIVE,
        });
    },
    async fetchCumulativeQuarterlyTabData({ commit }, payload) {
        return fetchData({
            commit,
            payload: { ...payload, quarterly: true },
            tabDataKey: 'cumulativeQuarterlyData',
            endpoint: getFundDetailsTabDataEndpoints.PERFORMANCE.CUMULATIVE,
        });
    },
    async fetchCumulativeDailyTabData({ commit }, payload) {
        return fetchData({
            commit,
            payload,
            tabDataKey: 'cumulativeDailyTabData',
            endpoint: getFundDetailsTabDataEndpoints.PERFORMANCE.CUMULATIVE_DAILY,
        });
    },
    async fetchAnnualizedTabData({ commit }, payload) {
        return fetchData({
            commit,
            payload,
            tabDataKey: 'annualizedTabData',
            endpoint: getFundDetailsTabDataEndpoints.PERFORMANCE.ANNUALIZED,
        });
    },
    async fetchAnnualizedQuarterlyTabData({ commit }, payload) {
        return fetchData({
            commit,
            payload: { ...payload, quarterly: true },
            tabDataKey: 'annualizedQuarterlyData',
            endpoint: getFundDetailsTabDataEndpoints.PERFORMANCE.ANNUALIZED,
        });
    },
    async fetchAnnualizedDailyTabData({ commit }, payload) {
        return fetchData({
            commit,
            payload,
            tabDataKey: 'annualizedDailyTabData',
            endpoint: getFundDetailsTabDataEndpoints.PERFORMANCE.ANNUALIZED_DAILY,
        });
    },
    async fetchKeyInformationTabData({ commit }, payload) {
        return fetchData({
            commit,
            payload,
            tabDataKey: 'keyInformationTabData',
            endpoint: getFundDetailsTabDataEndpoints.KEY_INFORMATION,
        });
    },
    async fetchKeyInfoTabManualDisclaimerData({ commit }, payload) {
        return fetchData({
            commit,
            payload,
            tabDataKey: 'keyInfoTabManualDisclaimerData',
            endpoint: getFundDetailsTabDataEndpoints.KEY_INFORMATION_DISCLAIMER,
        });
    },
    async fetchDiscreteTabData({ commit }, payload) {
        return fetchData({
            commit,
            payload,
            tabDataKey: 'discreteTabData',
            endpoint: getFundDetailsTabDataEndpoints.PERFORMANCE.DISCRETE,
        });
    },
    async fetchCalendarTabData({ commit }, payload) {
        return fetchData({
            commit,
            payload,
            tabDataKey: 'calendarTabData',
            endpoint: getFundDetailsTabDataEndpoints.PERFORMANCE.CALENDAR,
        });
    },
    async fetchHoldingsTabData({ commit }, payload) {
        return fetchData({
            commit,
            payload,
            tabDataKey: 'holdingsTabData',
            endpoint:
                getFundDetailsTabDataEndpoints.HOLDINGS[payload.topHoldings ? 'TOP_TEN_HOLDINGS' : 'DAILY_AND_MONTHLY'],
        });
    },
    async fetchHoldingsTabManualDisclaimerData({ commit }, payload) {
        return fetchData({
            commit,
            payload,
            tabDataKey: 'holdingsTabManualDisclaimerData',
            endpoint: getFundDetailsTabDataEndpoints.HOLDINGS.DISCLAIMER,
        });
    },
    async fetchFundsPerformanceDisclaimer({ commit }, payload) {
        return fetchData({
            commit,
            payload,
            tabDataKey: 'fundsPerformanceDisclaimerData',
            endpoint: getFundDetailsTabDataEndpoints.PERFORMANCE.DISCLAIMER,
        });
    },
    async fetchFundDetailsDocuments({ commit }, payload) {
        return fetchData({
            commit,
            payload,
            tabDataKey: 'fundDetailsDocuments',
            endpoint: getFundDetailsTabDataEndpoints.FUND_DOCUMENTS,
        });
    },
    async fetchFundDetailsBreakdownSector({ commit }, payload) {
        return fetchData({
            commit,
            payload,
            tabDataKey: 'breakdownSectorData',
            endpoint: getFundDetailsTabDataEndpoints.BREAKDOWN.SECTOR,
        });
    },
    async fetchFundDetailsBreakdownCountry({ commit }, payload) {
        return fetchData({
            commit,
            payload,
            tabDataKey: 'breakdownCountryData',
            endpoint: getFundDetailsTabDataEndpoints.BREAKDOWN.COUNTRY,
        });
    },
    async fetchFundDetailsBreakdownCreditProfile({ commit }, payload) {
        return fetchData({
            commit,
            payload,
            tabDataKey: 'breakdownCreditProfileData',
            endpoint: getFundDetailsTabDataEndpoints.BREAKDOWN.CREDIT,
        });
    },
    async fetchFundDetailsBreakdownMaturityProfile({ commit }, payload) {
        return fetchData({
            commit,
            payload,
            tabDataKey: 'breakdownMaturityProfileData',
            endpoint: getFundDetailsTabDataEndpoints.BREAKDOWN.MATURITY,
        });
    },
    async fetchAuthorisedCountriesTabData({ commit }, payload) {
        return fetchData({
            commit,
            payload,
            tabDataKey: 'authorisedCountriesTabData',
            endpoint: getFundDetailsTabDataEndpoints.AUTHORISED_COUNTRIES,
        });
    },
    async fetchTradingInformationTabData({ commit }, payload) {
        return fetchData({
            commit,
            payload,
            tabDataKey: 'tradingInformationTabData',
            endpoint: getFundDetailsTabDataEndpoints.TRADING_INFORMATION,
        });
    },
    async fetchCumulativeChartData({ commit }, payload) {
        return fetchData({
            commit,
            payload,
            tabDataKey: 'cumulativeChartData',
            endpoint: getFundDetailsTabDataEndpoints.PERFORMANCE.CUMULATIVE_CHART,
        });
    },
    async fetchFilteredCumulativeChartData({ commit }, payload) {
        return fetchData({
            commit,
            payload,
            tabDataKey: 'filteredCumulativeChartData',
            endpoint: getFundDetailsTabDataEndpoints.PERFORMANCE.CUMULATIVE_CHART,
        });
    },
    async fetchPremiumBarChartData({ commit }, payload) {
        return fetchData({
            commit,
            payload,
            tabDataKey: 'premiumBarChartData',
            endpoint: getFundDetailsTabDataEndpoints.PREMIUM_DISCOUNT.BAR_CHART,
        });
    },
    async fetchPremiumLineChartData({ commit }, payload) {
        return fetchData({
            commit,
            payload,
            tabDataKey: 'premiumLineChartData',
            endpoint: getFundDetailsTabDataEndpoints.PREMIUM_DISCOUNT.LINE_CHART,
        });
    },
    async fetchPodcastTabData({ commit }, payload) {
        return fetchData({
            commit,
            payload,
            tabDataKey: 'podcastTabData',
            endpoint: getFundDetailsTabDataEndpoints.PODCAST,
        });
    },
    async fetchPreliminaryImportantInfo({ commit }, payload) {
        return fetchData({
            commit,
            payload,
            tabDataKey: 'preliminaryImportantInfo',
            endpoint: getFundDetailsTabDataEndpoints.PRELIMINARY_IMPORTANT_INFO,
            getData: (data) => data.preliminaryInfo,
        });
    },
};

const mutations = {
    resetState: (state) => {
        const initialState = getInitialState();
        Object.keys(state).forEach((key) => {
            state[key] = initialState[key];
        });
    },
    setLoading: (state, isLoading) => {
        state.isLoading = isLoading;
    },
    setError: (state, error) => {
        state.error = getError(error);
    },
    setFundDetailsData: (state, data) => {
        state.name = data.name;
        state.fundId = data.id;
        state.fundSize = data.fundSizeValue;
        state.shareClasses = data.shareclasses;
        state.assetClassName = data.assetClassName;
        state.availableTabs = data.availableFundDetailsTabs;
        state.isFundDetailsImportantInfoAvailable = data.isImportantInfoAvailable;
        state.correlationFundRangeID = data.correlationFundRangeID;
        state.fundRangeName = data.fundRangeName;
        state.literatureAuthorizations = data.literatureAuthorizations;
        state.isPageAvailable = data.isPageAvailable;
        state.isFundDetailsInvTrustDisclaimerAvailable = data.isInvestmentTrustDisclaimerAvailable;
        state.fundHeroType = data.fundHeroType;
        state.formJson = data.formJson;
    },
    setSelectedShareClassIsin: (state, payload) => {
        state.selectedShareClassIsin = payload;
    },
    setTabDataLoading: (state, [tabDataKey, loading]) => {
        state[tabDataKey].loading = loading;
    },
    setTabDataError: (state, [tabDataKey, error]) => {
        state[tabDataKey].error = getError(error);
    },
    setTabDataData: (state, [tabDataKey, data]) => {
        state[tabDataKey].data = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

const fetchData = async ({ commit, payload, tabDataKey, endpoint, getData }) => {
    try {
        commit('setTabDataError', [tabDataKey, null]);
        commit('setTabDataLoading', [tabDataKey, true]);
        const { data } = await getFundDetailsTabData(payload, endpoint);
        commit('setTabDataData', [tabDataKey, getData ? getData(data) : data]);
    } catch (error) {
        commit('setTabDataError', [tabDataKey, error]);
    } finally {
        commit('setTabDataLoading', [tabDataKey, false]);
    }
};

const getError = (error) => {
    if (!error) return null;
    const status = error?.response?.status;
    if (status !== undefined) return `error${status}`;
    return 'errorUnknown';
};
