<template>
    <section
        v-if="articlesExist"
        :id="anchorId"
        :data-e2e-component-uid="rendering.uid"
        class="default-section-margin short-section container"
    >
        <ScText tag="h2" class="h2 mb-3" :field="fields.title" />
        <ScText tag="p" class="body-large text-grey mb-3 mb-md-4" :field="fields.description" />
        <div class="row d-flex flex-wrap">
            <ArticleSeriesCard
                v-for="article in getSeries"
                :key="article.id"
                :fields="article"
                class="col-12 col-md-6 col-lg-4 mb-3 mb-md-4"
            />
        </div>
    </section>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import ArticleSeriesCard from '@/components/Tiles/ArticleSeriesCard';

export default {
    name: 'ArticleSeriesCollection',
    components: { ArticleSeriesCard, ScText: Text },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        articlesExist() {
            return !!this.getSeries.length || this.isExperienceEditor;
        },
        getSeries() {
            return this.fields.series?.value?.filter((series) => series.availableArticlesCount !== 0) || [];
        },
    },
};
</script>

<style scoped lang="scss">
.body-large {
    font-weight: 500;
}
</style>
