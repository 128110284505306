var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12 col-md-6 col-xl-3 realtive"},[_c('div',{ref:"sidebar-wrapper",staticClass:"sidebar-wrapper mb-6 mb-xl-0"},[_c('nav',{style:({ width: (_vm.sidebarWidth + "px") })},[_c('ScText',{staticClass:"mb-5",attrs:{"tag":"h3","field":_vm.fields.title}}),_c('div',{staticClass:"flex flex-column w-100"},_vm._l((_vm.fields.items),function(title,i){return _c('div',{key:((title.id) + "-" + i)},[(title.fields.title && title.fields.title.value)?_c('p',{staticClass:"title text-uppercase py-1 ps-3",class:{ active: _vm.activeSubtitle === title.id },attrs:{"tabindex":0},on:{"click":function($event){return _vm.scrollTo(title.id, title.id)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.scrollTo(title.id, title.id)}}},[_vm._v("\n                        "+_vm._s(title.fields.title.value)+"\n                    ")]):_vm._e(),_vm._l((title.fields.items),function(subtitle,index){return [(
                                subtitle.fields.subsectionTitle &&
                                subtitle.fields.subsectionTitle.value &&
                                _vm.activeSubtitle === title.id &&
                                !_vm.scrolling
                            )?_c('p',{key:((subtitle.id) + "-" + i + "-" + index),staticClass:"subtitle ps-5 pb-1",class:{ active: _vm.activeSubtitle === title.id },attrs:{"tabindex":0},on:{"click":function($event){$event.preventDefault();return _vm.scrollTo(subtitle.id, title.id)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.scrollTo(subtitle.id, title.id)}}},[_vm._v("\n                            "+_vm._s(subtitle.fields.subsectionTitle.value)+"\n                        ")]):_vm._e()]})],2)}),0)],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDropdown),expression:"showDropdown"}],staticClass:"sidebar-dropdown flex-column",style:({ top: ((_vm.$root.headerHeight) + "px") })},[_c('button',{directives:[{name:"collapse-target",rawName:"v-collapse-target",value:('#sidebar-dropdown-btn'),expression:"'#sidebar-dropdown-btn'"}],ref:"sidebar-dropdown",staticClass:"sidebar-dropdown-btn collapsed pe-2",attrs:{"aria-expanded":"false","type":"button","variant":"button"},on:{"click":function($event){_vm.showScroll = !_vm.showScroll;
                _vm.isDropdownShown = !_vm.isDropdownShown;}}},[_c('div',{staticClass:"d-flex align-items-center container expandable"},[_c('p',{staticClass:"text-left text-uppercase dropdown-spacing text-black"},[_vm._v("\n                    "+_vm._s(_vm.getActiveSubtitleName)+"\n                ")]),_c('em',{staticClass:"d-flex bi-chevron-down ms-auto ms-sm-3",attrs:{"aria-hidden":"true"}})])]),_c('div',{staticClass:"collapse background-white",attrs:{"id":"sidebar-dropdown-btn"}},[_c('div',{staticClass:"copywrite-dropdown pb-1",style:({ 'max-height': ("calc(100vh - " + (_vm.$root.headerHeight + _vm.dropdownHeight) + "px)") })},_vm._l((_vm.fields.items),function(title,i){return _c('div',{key:((title.id) + "-" + i)},[(title.fields.title && title.fields.title.value)?_c('div',{staticClass:"title dropdown-title dropdown-spacing text-uppercase py-2",class:{ active: _vm.activeSubtitle === title.id },attrs:{"tabindex":0},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.scrollTo(title.id, title.id)},"click":function($event){_vm.scrollTo(title.id, title.id);
                            _vm.$refs['sidebar-dropdown'].click();}}},[_c('div',{staticClass:"container"},[_vm._v("\n                            "+_vm._s(title.fields.title.value)+"\n                        ")])]):_vm._e(),_vm._l((title.fields.items),function(subtitle,index){return [(subtitle.fields.subsectionTitle && subtitle.fields.subsectionTitle.value)?_c('div',{key:((subtitle.id) + "-" + i + "-" + index),staticClass:"dropdown-subtitle ps-2 pb-2",class:{ active: _vm.activeSubtitle === title.id },attrs:{"tabindex":0},on:{"click":function($event){_vm.scrollTo(subtitle.id, title.id);
                                _vm.$refs['sidebar-dropdown'].click();},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.scrollTo(subtitle.id, title.id)}}},[_c('div',{staticClass:"container"},[_vm._v("\n                                "+_vm._s(subtitle.fields.subsectionTitle.value)+"\n                            ")])]):_vm._e()]})],2)}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }