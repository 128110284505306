<template>
    <div>
        <h3>{{ $t('fundDetailsBreakdownSectorTitle') }}</h3>
        <div class="mt-5">
            <FundDetailsTabSkeleton v-if="loading" />
            <AlertMessageBase v-else-if="error" type="error" :title="$t(error)" />
            <FundDetailsStripedTable v-else-if="tabData" :reportDate="reportDate" :tableData="tableData" />
            <div v-else class="body-small">{{ $t('noResultsMessage') }}</div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import AlertMessageBase from '@/components/Generic/AlertMessageBase';
import FundDetailsStripedTable from '@/components/Pages/FundsCenter/FundDetails/FirstLevelTabs/FundDetailsStripedTable';
import { localizeDate } from '@/mixins/Helpers.mixin';
import StripedTableHelper from '@/mixins/StripedTableHelper.mixin';

import FundDetailsTabSharedLogic from '../../FundDetailsTabSharedLogic.mixin';
import FundDetailsTabSkeleton from '../../FundDetailsTabSkeleton';

export default {
    name: 'FundDetailsBreakdownSectorTable',
    components: {
        AlertMessageBase,
        FundDetailsStripedTable,
        FundDetailsTabSkeleton,
    },
    mixins: [localizeDate, FundDetailsTabSharedLogic, StripedTableHelper],
    computed: {
        ...mapState('fundDetails', {
            loading: (state) => state.breakdownSectorData.loading,
            error: (state) => state.breakdownSectorData.error,
            tabData: (state) => state.breakdownSectorData.data,
        }),
    },
    methods: {
        ...mapActions('fundDetails', {
            fetchTabData: 'fetchFundDetailsBreakdownSector',
        }),

        formatTableValues(columns) {
            return Array.from(this.tableColumnHeadings).map((heading) => {
                const col = columns.find((col) => col.columnLabel === heading);

                if (!col) return this.$t('notAvailableShortMessage');

                return this.applyGeneralFormattingRules(col);
            });
        },
    },
};
</script>
