<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';

import BackToTopButton from '@/components/Generic/Buttons/BackToTopButton.vue';
import AbrdnPlaceholder from '@/components/Generic/SitecoreWrappers/AbrdnPlaceholder.vue';

export default {
    name: 'ArticleBodySharedLogic',

    components: {
        AbrdnPlaceholder,
        BackToTopButton,
        Placeholder,
    },
};
</script>

<style scoped lang="scss"></style>
