export const formFieldsStep6 = {
    rows: [
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                            element: {
                                type: 'FormFieldGroup',
                                id: '334',
                                depth: 'complete',
                                name: 'Hub branding (step 6 of 7)',
                                instructions: '',
                                style: {
                                    textType: 'header',
                                    fieldSize: 'large',
                                    guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                                },
                                unique: true,
                            },
                        },
                    ],
                },
            ],
            type: 'FormFieldGroup',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                            element: {
                                type: 'FormFieldGroup',
                                id: '334',
                                depth: 'complete',
                                name: 'Logo',
                                instructions: '',
                                style: {
                                    textType: 'subheader',
                                    fieldSize: 'large',
                                    guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                                },
                                unique: true,
                            },
                        },
                    ],
                },
            ],
            type: 'FormFieldGroup',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                            element: {
                                type: 'FormFieldGroup',
                                id: '334',
                                depth: 'complete',
                                name: "Your firm's logo can be added to the internal (logged in) pages of the hub. We'll let you know what format and where to send this after",
                                instructions: '',
                                style: {
                                    textType: 'body',
                                    fieldSize: 'large',
                                    guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                                },
                                unique: true,
                            },
                        },
                    ],
                },
            ],
            type: 'FormFieldGroup',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '00N6900000Kw00Y',
                            element: {
                                type: 'FormField',
                                id: 'logo',
                                name: "Do you want to apply your firm's logo to the hub?",
                                instructions: '',
                                fieldDescription: '',
                                relatesTo: {
                                    value: 'Logo',
                                },
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kw00Y',
                                },
                                dataType: 'text',
                                displayType: 'radio',
                                htmlName: '00N6900000Kw00Y',
                                optionListId: '202',
                                useGlobalSubscriptionStatus: 'False',
                                validations: [
                                    {
                                        type: 'FieldValidation',
                                        id: '19608',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventUrlCondition' },
                                        isEnabled: 'true',
                                        message: "Value must not contain any URL's",
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19609',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventXSSCondition' },
                                        isEnabled: 'true',
                                        message: 'Value must not contain any HTML',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19610',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'IsRequiredCondition' },
                                        isEnabled: 'true',
                                        message: 'This field is required',
                                    },
                                ],
                                optionList: {
                                    type: 'OptionList',
                                    id: '202',
                                    createdAt: '0',
                                    createdBy: '0',
                                    depth: 'complete',
                                    name: 'Arranging deals in investments',
                                    updatedAt: '0',
                                    updatedBy: '0',
                                    elements: [
                                        {
                                            type: 'Option',
                                            displayName: 'Yes',
                                            value: 'Yes',
                                        },
                                        {
                                            type: 'Option',
                                            displayName: 'No',
                                            value: 'No',
                                        },
                                    ],
                                },
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                            element: {
                                type: 'FormFieldGroup',
                                id: '334',
                                depth: 'complete',
                                name: 'Navigation colour',
                                instructions: '',
                                style: {
                                    textType: 'subheader',
                                    fieldSize: 'large',
                                    guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                                },
                                unique: true,
                            },
                        },
                    ],
                },
            ],
            type: 'FormFieldGroup',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                            element: {
                                type: 'FormFieldGroup',
                                id: '334',
                                depth: 'complete',
                                name: 'Your can choose a colour to be applied to the background of the second-level navigation and the text labels of the third-level navigation of teh Hub. Please check the guidance below:',
                                instructions:
                                    'The color must be dark to allow navigation text labels to be read clearly',
                                style: {
                                    textType: 'body',
                                    fieldSize: 'large',
                                    guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                                },
                                unique: true,
                            },
                        },
                    ],
                },
            ],
            type: 'FormFieldGroup',
        },
        {
            cols: [
                {
                    width: 3,
                    cells: [
                        {
                            guid: '00N6900000Kvzsg',
                            element: {
                                type: 'FormField',
                                id: 'Navigation_colour_RGBHEX_Colour__c',
                                name: 'Navigation color (RGB)',
                                description: '',
                                relatesTo: {
                                    value: 'Navigation color',
                                },
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kvzsg',
                                },
                                createdFromContactFieldId: '100003',
                                dataType: 'text',
                                displayType: 'text',
                                htmlName: '00N6900000Kvzsg',
                                useGlobalSubscriptionStatus: 'False',
                                validations: [
                                    {
                                        type: 'FieldValidation',
                                        id: '19599',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventUrlCondition' },
                                        isEnabled: 'true',
                                        message: "Value must not contain any URL's",
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19600',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventXSSCondition' },
                                        isEnabled: 'true',
                                        message: 'Value must not contain any HTML',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19601',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: {
                                            type: 'TextLengthCondition',
                                            maximum: '50',
                                            minimum: '0',
                                        },
                                        isEnabled: 'true',
                                        message: 'Invalid length for field value',
                                    },
                                ],
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
    ],
};
