<template>
    <div :data-e2e-component-uid="renderingId" class="overflow-wrapper">
        <div class="past-performance-modal background-white w-100">
            <div class="past-performance-modal__heading d-flex align-items-center justify-content-between p-2 p-md-4">
                <h3 class="subheader-1">{{ $t('pastPerformance') }}</h3>
                <button class="btn btn-naked past-performance-modal__closer text-link" @click="closeModal">
                    {{ $t('close') }} <IconX class="ms-1" />
                </button>
            </div>
            <div class="past-performance-modal__content px-2 px-md-4 pb-md-4 py-4 py-xl-6">
                <PastPerformanceBlock v-if="shareClassId" :shareClassId="shareClassId" :countryCode="countryCode" />
            </div>
        </div>
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

import IconX from '@/components/Icons/icon-x.vue';
import PastPerformanceBlock from '@/components/Pages/KidsHub/KidsTable/PastPerformanceChart/PastPerformanceBlock.vue';

export default {
    name: 'PastPerformanceModalContent',
    components: { IconX, PastPerformanceBlock },
    props: {
        shareClass: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        countryCode: {
            type: String,
            required: true,
            default: '',
        },
    },
    data() {
        return {
            renderingId: undefined,
        };
    },
    computed: {
        shareClassId() {
            return this.shareClass.shareclassID;
        },
    },
    mounted() {
        this.renderingId = uuidv4();
    },
    methods: {
        closeModal() {
            this.$emit('closeModal');
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
.overflow-wrapper {
    padding: 100px 0;
    width: 100%;
    pointer-events: none;
    @include media-breakpoint-down(md) {
        padding: 44px 0;
    }
}
.past-performance-modal {
    pointer-events: all;
}
.past-performance-modal__heading {
    border-bottom: 1px solid var(--digital-light-grey);
}
.past-performance-modal__closer svg {
    width: 0.875em;
    height: 0.875em;
}
</style>
