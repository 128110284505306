<template>
    <tr class="skeleton-row">
        <td colspan="10" class="skeleton px-0">
            <BaseSkeleton class="w-100 d-none d-xl-block" height="56px" />
            <BaseSkeleton class="w-100 d-block d-xl-none" height="48px" />
        </td>
        <td colspan="10" class="skeleton d-xl-none">
            <BaseSkeleton class="w-100 d-none d-md-block" height="48px" />
            <BaseSkeleton class="w-100 d-block d-md-none" height="56px" />
        </td>
        <td colspan="10" class="skeleton d-xl-none">
            <BaseSkeleton class="w-100 d-block" height="72px" />
        </td>
        <td colspan="10" class="skeleton d-xl-none">
            <BaseSkeleton class="w-100 d-block" height="72px" />
        </td>
        <td colspan="10" class="skeleton d-xl-none">
            <BaseSkeleton class="w-100 d-block" height="72px" />
        </td>
        <td colspan="10" class="skeleton d-xl-none">
            <BaseSkeleton class="w-100 d-block" height="72px" />
        </td>
    </tr>
</template>

<script>
import BaseSkeleton from '@/components/Generic/BaseSkeleton.vue';

export default {
    name: 'FundsTableRowSkeleton',
    components: { BaseSkeleton },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
.skeleton {
    &:nth-child(1) {
        max-width: 434px;
        @include media-breakpoint-down(md) {
            max-width: 100%;
        }
    }
    &:nth-child(2) {
        max-width: 374px;
        @include media-breakpoint-down(md) {
            max-width: 100%;
        }
    }
}
</style>
