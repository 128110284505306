<template>
    <div class="funds-filter px-2 py-5 px-md-5" :class="vectorBasedBackground">
        <LiteratureSearch />
        <BaseFilter
            v-if="formattedFilters.length"
            class="mt-4 mt-lg-5"
            :filters="formattedFilters"
            :isLoading="isFiltersLoading"
            @filterApply="onFilterApply"
        />
    </div>
</template>

<script>
import { mapMutations } from 'vuex';

import BaseFilter from '@/components/Functional/Filters/BaseFilter.vue';
import LiteratureSearch from '@/components/Pages/LiteratureHub/LiteratureFilters/LiteratureSearch/LiteratureSearch.vue';
import { formatFiltersForReq } from '@/mixins/ArticleFilter/FilterHelper.mixin';
import FiltersSection from '@/mixins/FiltersSection.mixin';

export default {
    name: 'LiteratureFiltersSection',
    components: { BaseFilter, LiteratureSearch },
    mixins: [formatFiltersForReq, FiltersSection],
    props: {
        filters: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            notShowAggregateCount: true,
        };
    },
    methods: {
        ...mapMutations('literatureHub', ['setFilters']),
        onFilterApply() {
            const { reqFilters } = this.formatFiltersForReq(this.formattedFilters);
            this.setFilters(reqFilters);
        },
    },
};
</script>
