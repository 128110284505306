<template>
    <button
        class="d-flex filter-dropdown-option w-100 btn-naked"
        :class="{ disabled }"
        :disabled="disabled"
        @click="$emit('onSelect')"
    >
        <Checkbox
            :selected="option.selected"
            :disabled="disabled"
            :label="option.name"
            tabindex="-1"
            aria-hidden="true"
            class="pe-none"
        />
        <div class="d-flex">
            <Loader v-if="loading" />
            <p v-else class="ms-auto">{{ getOption(option.id).aggregateCount }}</p>
        </div>
    </button>
</template>

<script>
import Checkbox from '@/components/Generic/Checkbox';
import Loader from '@/components/Generic/Loader';
import { disabledOption, getOption } from '@/mixins/ArticleFilter/FiltersDropdownHelper.mixin';

export default {
    name: 'ArticleFilterOption',

    components: {
        Checkbox,
        Loader,
    },

    mixins: [disabledOption, getOption],

    props: {
        option: {
            type: Object,
            default: () => ({}),
        },
        loading: {
            type: Boolean,
            default: false,
        },
        filtersFacets: {
            type: Array,
            default: () => [],
        },
        approvedFilters: {
            type: Array,
            default: () => [],
        },
    },

    computed: {
        disabled() {
            return this.disabledOption(this.option.id) || this.loading;
        },
    },
};
</script>

<style scoped lang="scss">
.filter-dropdown-option {
    padding: 16px;
    padding-right: 24px;

    &:hover:not(.disabled) {
        background: var(--button-mist);
    }
}
</style>
