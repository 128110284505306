<template>
    <FundDetailsTabRow fullWidth class="mt-4">
        <AnnualizedTable />
        <AnnualizedQuarterlyTable v-if="quarterlyTableAvailable" class="mt-4" />
    </FundDetailsTabRow>
</template>

<script>
import { mapState } from 'vuex';

import FundDetailsTabRow from '@/components/Pages/FundsCenter/FundDetails/FundDetailsTabRow';

import AnnualizedQuarterlyTable from './Tables/AnnualizedQuarterlyTable';
import AnnualizedTable from './Tables/AnnualizedTable';

export default {
    name: 'FundDetailsAnnualizedTab',

    components: {
        AnnualizedQuarterlyTable,
        AnnualizedTable,
        FundDetailsTabRow,
    },

    computed: {
        ...mapState('fundDetails', {
            quarterlyTableAvailable: (state) => state.availableTabs.performanceTab.annualizedQuarterlyTab,
        }),
    },
};
</script>
