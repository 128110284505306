export const formFieldsStep2 = {
    rows: [
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                            element: {
                                type: 'FormFieldGroup',
                                id: '334',
                                name: 'Portfolio options (step 2 of 7)',
                                instructions: 'Please outline the features for your new model portfolio,',
                                style: {
                                    textType: 'header',
                                    fieldSize: 'large',
                                    guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                                },
                            },
                        },
                    ],
                },
            ],
            type: 'FormFieldGroup',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                            element: {
                                type: 'FormFieldGroup',
                                id: '334',
                                name: 'Please outline the features for your new model portfolio.',
                                instructions: '',
                                style: {
                                    textType: 'body',
                                    fieldSize: 'large',
                                    guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                                },
                            },
                        },
                    ],
                },
            ],
            type: 'FormFieldGroup',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '00N6900000Kvzsw',
                            element: {
                                type: 'FormField',
                                id: 'note',
                                title: 'NOTE',
                                body: 'It can take between 7-10 days from when you create a new model portfolio to when adviser can select it for investing',
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kvzsw',
                                },
                                dataType: 'text',
                                displayType: 'note',
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                            element: {
                                type: 'FormFieldGroup',
                                id: '334',
                                name: 'Automatic rebalancing',
                                instructions: '',
                                style: {
                                    textType: 'subheader',
                                    fieldSize: 'large',
                                    guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                                },
                            },
                        },
                    ],
                },
            ],
            type: 'FormFieldGroup',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                            element: {
                                type: 'FormFieldGroup',
                                id: '334',
                                name: 'If you select automatic rebalancing options for you model portfolios, you can put a restriction on the minimum number of days between any system generated rebalanced on an individual client account',
                                instructions: '',
                                style: {
                                    textType: 'body',
                                    fieldSize: 'large',
                                    guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                                },
                            },
                        },
                    ],
                },
            ],
            type: 'FormFieldGroup',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '00N6900000Kw00Y',
                            element: {
                                type: 'FormField',
                                id: 'automatic_rebalancing',
                                name: 'Do you want to apply automatic rebalancing?',
                                relatesTo: {
                                    value: 'Automatic rebalancig',
                                },
                                instructions: '',
                                fieldDescription: '',
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kw00Y',
                                },
                                dataType: 'text',
                                displayType: 'radio',
                                htmlName: '00N6900000Kw00Y',
                                optionListId: '202',
                                validations: [
                                    {
                                        type: 'FieldValidation',
                                        id: '19608',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventUrlCondition' },
                                        isEnabled: 'true',
                                        message: "Value must not contain any URL's",
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19609',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventXSSCondition' },
                                        isEnabled: 'true',
                                        message: 'Value must not contain any HTML',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19610',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'IsRequiredCondition' },
                                        isEnabled: 'true',
                                        message: 'This field is required',
                                    },
                                ],
                                optionList: {
                                    type: 'OptionList',
                                    id: '202',
                                    createdAt: '0',
                                    createdBy: '0',
                                    name: 'Arranging deals in investments',
                                    elements: [
                                        {
                                            type: 'Option',
                                            displayName: 'Yes',
                                            value: 'Yes',
                                        },
                                        {
                                            type: 'Option',
                                            displayName: 'No',
                                            value: 'No',
                                        },
                                    ],
                                },
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
        {
            cols: [
                {
                    width: 12,
                    cells: [
                        {
                            guid: '00N6900000Kvzsg',
                            element: {
                                type: 'FormField',
                                id: 'Minimum_days_between_rebalances__c',
                                name: 'Minimum days between automated rebalances',
                                description: 'Enter a number between 1 and 30',
                                relatesTo: {
                                    value: 'Automatic rebalancig',
                                },
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kvzsg',
                                },
                                createdFromContactFieldId: '100003',
                                dataType: 'text',
                                displayType: 'text',
                                conditionalField: true,
                                conditionTargetField: 'automatic_rebalancing',
                                conditionTargetValue: 'Yes',
                                htmlName: 'number',
                                validations: [
                                    {
                                        type: 'FieldValidation',
                                        id: '19599',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventUrlCondition' },
                                        isEnabled: 'true',
                                        message: "Value must not contain any URL's",
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19600',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventXSSCondition' },
                                        isEnabled: 'true',
                                        message: 'Value must not contain any HTML',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19601',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: {
                                            type: 'TextLengthCondition',
                                            maximum: '10',
                                            minimum: '0',
                                        },
                                        isEnabled: 'true',
                                        message: 'Invalid length for field value',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19610',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'IsRequiredCondition' },
                                        isEnabled: 'true',
                                        message: 'This field is required',
                                    },
                                ],
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                            element: {
                                type: 'FormFieldGroup',
                                id: '334',
                                name: 'Minimum cash allocation',
                                instructions: '',
                                style: {
                                    textType: 'subheader',
                                    fieldSize: 'large',
                                    guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                                },
                            },
                        },
                    ],
                },
            ],
            type: 'FormFieldGroup',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                            element: {
                                type: 'FormFieldGroup',
                                id: '334',
                                name: 'Minimum model portfolio cash allocation allow you to set a stated minimum target allocation to cash in all your model portfolios. This means when you create any new model porfolios, the cash allocation  must be at least at the minimum level(%) set for your firm.',
                                instructions: '',
                                style: {
                                    textType: 'body',
                                    fieldSize: 'large',
                                    guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                                },
                            },
                        },
                    ],
                },
            ],
            type: 'FormFieldGroup',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '00N6900000Kw00Y',
                            element: {
                                type: 'FormField',
                                id: 'cash_allocation',
                                name: 'Do you want to to set a minimum target allocation to cash?',
                                instructions: '',
                                relatesTo: {
                                    value: 'Minimum cash allocation',
                                },
                                fieldDescription: '',
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kw00Y',
                                },
                                dataType: 'text',
                                displayType: 'radio',
                                htmlName: '00N6900000Kw00Y',
                                optionListId: '202',
                                validations: [
                                    {
                                        type: 'FieldValidation',
                                        id: '19608',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventUrlCondition' },
                                        isEnabled: 'true',
                                        message: "Value must not contain any URL's",
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19609',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventXSSCondition' },
                                        isEnabled: 'true',
                                        message: 'Value must not contain any HTML',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19610',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'IsRequiredCondition' },
                                        isEnabled: 'true',
                                        message: 'This field is required',
                                    },
                                ],
                                optionList: {
                                    type: 'OptionList',
                                    id: '202',
                                    createdAt: '0',
                                    createdBy: '0',
                                    name: 'Arranging deals in investments',
                                    elements: [
                                        {
                                            type: 'Option',
                                            displayName: 'Yes',
                                            value: 'Yes',
                                        },
                                        {
                                            type: 'Option',
                                            displayName: 'No',
                                            value: 'No',
                                        },
                                    ],
                                },
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
        {
            cols: [
                {
                    width: 12,
                    cells: [
                        {
                            guid: '00N6900000Kvzsg',
                            element: {
                                type: 'FormField',
                                id: 'Minimum_model_portfolio_cash_allocation__c',
                                name: 'Minimum model portfolio cash allocation (%)',
                                description: 'Enter a number between 0 and 100',
                                relatesTo: {
                                    value: 'Minimum cash allocation',
                                },
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kvzsg',
                                },
                                createdFromContactFieldId: '100003',
                                dataType: 'text',
                                displayType: 'text',
                                conditionalField: true,
                                conditionTargetField: 'cash_allocation',
                                conditionTargetValue: 'Yes',
                                htmlName: 'number',
                                validations: [
                                    {
                                        type: 'FieldValidation',
                                        id: '19599',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventUrlCondition' },
                                        isEnabled: 'true',
                                        message: "Value must not contain any URL's",
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19600',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventXSSCondition' },
                                        isEnabled: 'true',
                                        message: 'Value must not contain any HTML',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19601',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: {
                                            type: 'TextLengthCondition',
                                            maximum: '255',
                                            minimum: '0',
                                        },
                                        isEnabled: 'true',
                                        message: 'Invalid length for field value',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19610',
                                        depth: 'complete',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'IsRequiredCondition' },
                                        isEnabled: 'true',
                                        message: 'This field is required',
                                    },
                                ],
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                            element: {
                                type: 'FormFieldGroup',
                                id: '334',
                                name: 'Treatment of non-tradeable assets for new deposits',
                                instructions: '',
                                style: {
                                    textType: 'header',
                                    fieldSize: 'large',
                                    guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                                },
                            },
                        },
                    ],
                },
            ],
            type: 'FormFieldGroup',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                            element: {
                                type: 'FormFieldGroup',
                                id: '334',
                                name: 'When the model portfolio target allocation contains assets that cannot be traded, new deposits will be reallocated.',
                                instructions: '',
                                style: {
                                    textType: 'body',
                                    fieldSize: 'large',
                                    guid: '32AFBF4AA11F44D8A58EE3EF36D354A7',
                                },
                            },
                        },
                    ],
                },
            ],
            type: 'FormFieldGroup',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '00N6900000Kvzsw',
                            element: {
                                type: 'FormField',
                                id: 'note',
                                title: 'NOTE',
                                body: 'For rebalance and withdrawals it will always be allocated proportionately across all tradable assets.',
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kvzsw',
                                },
                                dataType: 'text',
                                displayType: 'note',
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
        {
            cols: [
                {
                    width: 6,
                    cells: [
                        {
                            guid: '00N6900000Kw00Y',
                            element: {
                                type: 'FormField',
                                id: 'Allocate_option__c',
                                name: 'How would you like non-tradable assets for new deposits to be allocated?',
                                instructions: '',
                                fieldDescription: '',
                                relatesTo: {
                                    value: 'Treatment of non-tradeable assets for new deposits',
                                },
                                style: {
                                    fieldSize: 'large',
                                    guid: '00N6900000Kw00Y',
                                },
                                dataType: 'text',
                                displayType: 'radio',
                                htmlName: '00N6900000Kw00Y',
                                optionListId: '202',
                                validations: [
                                    {
                                        type: 'FieldValidation',
                                        id: '19608',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventUrlCondition' },
                                        isEnabled: 'true',
                                        message: "Value must not contain any URL's",
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19609',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'PreventXSSCondition' },
                                        isEnabled: 'true',
                                        message: 'Value must not contain any HTML',
                                    },
                                    {
                                        type: 'FieldValidation',
                                        id: '19610',
                                        description: 'Form Field Validation Rule',
                                        name: 'Form Field Validation Rule',
                                        condition: { type: 'IsRequiredCondition' },
                                        isEnabled: 'true',
                                        message: 'This field is required',
                                    },
                                ],
                                optionList: {
                                    type: 'OptionList',
                                    id: '202',
                                    createdAt: '0',
                                    createdBy: '0',
                                    name: 'Arranging deals in investments',
                                    elements: [
                                        {
                                            type: 'Option',
                                            displayName: 'Allocate proportionately across all treadeable assets',
                                            value: 'Allocate proportionately across all treadeable assets',
                                        },
                                        {
                                            type: 'Option',
                                            displayName: 'Allocate proportion to cash',
                                            value: 'Allocate proportion to cash',
                                        },
                                    ],
                                },
                            },
                        },
                    ],
                },
            ],
            type: 'FormField',
        },
    ],
};
