<template>
    <section class="default-section-padding background-white">
        <FundDetailsTabRow>
            <h2>{{ title }}</h2>
            <FundDetailsTabSkeleton v-if="loading" class="mt-5" />
            <AlertMessageBase v-else-if="error" type="error" :title="$t(error)" class="mt-5" />
            <div v-else-if="tabData && tabData.length" class="mt-5">
                <h5>{{ $t('fundDetailsTabsFundObjectives') }}</h5>
                <div class="mt-2">
                    <p
                        v-for="(item, index) in tabData"
                        :key="item"
                        class="body-default text-grey"
                        :class="{ 'mt-3': index > 0 }"
                    >
                        {{ item }}
                    </p>
                </div>
            </div>
        </FundDetailsTabRow>

        <FundDetailsSecondLevelTabGroup
            class="mt-8"
            :availableTabs="availableTabsTop"
            tabLvl="subTab"
            :tabComponentsByTabKeys="tabComponentsByTabKeys.top"
            :tabTitlesByTabKeys="tabTitlesByTabKeys.top"
        />

        <FundDetailsSecondLevelTabGroup
            class="mt-6"
            :availableTabs="availableTabsBottom"
            tabLvl="subTab2"
            :tabComponentsByTabKeys="tabComponentsByTabKeys.bottom"
            :tabTitlesByTabKeys="tabTitlesByTabKeys.bottom"
        />
    </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import AlertMessageBase from '@/components/Generic/AlertMessageBase';

import FundDetailsSecondLevelTabGroup from '../FundDetailsSecondLevelTabGroup';
import FundDetailsTabRow from '../FundDetailsTabRow';
import FundDetailsTabSharedLogic from '../FundDetailsTabSharedLogic.mixin';
import FundDetailsTabSkeleton from '../FundDetailsTabSkeleton';
import FundDetailsAuthorisedCountriesTab from '../SecondLevelTabs/FundDetailsAuthorisedCountriesTab.vue';
import FundDetailsCodesTab from '../SecondLevelTabs/FundDetailsCodesTab.vue';
import FundDetailsCorrelationMatrixTab from '../SecondLevelTabs/FundDetailsCorrelationMatrixTab';
import FundDetailsDerivativeTab from '../SecondLevelTabs/FundDetailsDerivativeTab';
import FundDetailsKeyInformationTab from '../SecondLevelTabs/FundDetailsKeyInformationTab.vue';
import FundDetailsKeyRiskTab from '../SecondLevelTabs/FundDetailsKeyRiskTab';
import FundDetailsPortfolioSecuritiesTab from '../SecondLevelTabs/FundDetailsPortfolioSecuritiesTab';
import FundDetailsRiskAnalysisTab from '../SecondLevelTabs/FundDetailsRiskAnalysisTab';
import FundDetailsTradingInformationTab from '../SecondLevelTabs/FundDetailsTradingInformationTab.vue';
import FundRiskStatisticsTab from '../SecondLevelTabs/FundRiskStatisticsTab';

export default {
    name: 'FundDetailsFundsFactsTab',

    components: {
        FundDetailsSecondLevelTabGroup,
        FundDetailsTabRow,
        AlertMessageBase,
        FundDetailsTabSkeleton,
    },

    mixins: [FundDetailsTabSharedLogic],

    props: {
        title: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            tabComponentsByTabKeys: {
                top: {
                    portfolioSecuritiesTab: FundDetailsPortfolioSecuritiesTab,
                    derivativeUsageTab: FundDetailsDerivativeTab,
                    breakdownRiskTab: FundDetailsRiskAnalysisTab,
                    codesTab: FundDetailsCodesTab,
                    keyInformationTab: FundDetailsKeyInformationTab,
                    authorizedCountriesTab: FundDetailsAuthorisedCountriesTab,
                    tradingInformationTab: FundDetailsTradingInformationTab,
                    correlationMatrixTab: FundDetailsCorrelationMatrixTab,
                },
                bottom: {
                    keyRiskTab: FundDetailsKeyRiskTab,
                    fundRiskStatisticsTab: FundRiskStatisticsTab,
                },
            },
            tabTitlesByTabKeys: {
                top: {
                    portfolioSecuritiesTab: this.$t('fundDetailsTabsPortfolioSecurities'),
                    derivativeUsageTab: this.$t('fundDetailsTabsDerivativeUsage'),
                    breakdownRiskTab: this.$t('fundDetailsTabsRiskAnalysis'),
                    codesTab: this.$t('fundDetailsTabsCodes'),
                    keyInformationTab: this.$t('fundDetailsTabsKeyInformation'),
                    authorizedCountriesTab: this.$t('fundDetailsTabsAuthorisedCountries'),
                    tradingInformationTab: this.$t('fundDetailsTabsTradingInformation'),
                    correlationMatrixTab: this.$t('fundDetailsTabsCorrelationMatrix'),
                },
                bottom: {
                    keyRiskTab: this.$t('fundDetailsTabsKeyRisk'),
                    fundRiskStatisticsTab: this.$t('fundDetailsTabsRiskStatistics'),
                },
            },
        };
    },

    computed: {
        ...mapState('fundDetails', {
            availableTabs: 'availableTabs',
            loading: (state) => state.fundFactsTabData.loading,
            error: (state) => state.fundFactsTabData.error,
            tabData: (state) => state.fundFactsTabData.data,
        }),

        availableTabsTop() {
            return this.availableTabs?.fundFactsTab?.topTabs;
        },

        availableTabsBottom() {
            return this.availableTabs?.fundFactsTab?.bottomTabs;
        },
    },

    methods: {
        ...mapActions('fundDetails', {
            fetchTabData: 'fetchFundFactsTabData',
        }),
    },
};
</script>
