<template>
    <div :id="anchorId" class="mt-5 mb-2">
        <ScText
            v-if="isExperienceEditor || haveHeaderValue"
            :tag="params.type"
            class="background-to-change"
            :field="fields.header"
        />
    </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

export default {
    name: 'Heading',
    components: {
        ScText: Text,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        params: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        haveHeaderValue() {
            return !!this.fields?.header?.value;
        },
    },
};
</script>

<style scoped lang="scss"></style>
