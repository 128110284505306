<template>
    <FormElemWrapper
        class="form-element--input"
        :class="{ ['form-element--filled']: fieldAttrs.value, 'conditional-field': fieldAttrs.conditional }"
        :isError="isError"
    >
        <template v-if="getLabel" #fieldLabelSlot>{{ getLabel }} </template>
        <template v-if="description" #description>
            <p class="mt-2 text-grey">{{ description }}</p>
        </template>
        <template v-if="getTooltip" #tooltipSlot> {{ getTooltip }} </template>
        <template #fieldSlot>
            <span class="input-box">
                <span v-if="$slots['leftIcon']" class="input-icon input-icon--left">
                    <slot name="leftIcon" />
                </span>
                <component
                    :is="multiline ? 'textarea' : 'input'"
                    ref="input"
                    :aria-autocomplete="!multiline && 'list'"
                    v-bind="$attrs"
                    class="field-text"
                    :name="fieldAttrs.name"
                    :placeholder="fieldAttrs.placeholder"
                    :value="fieldAttrs.value"
                    :required="fieldAttrs.required"
                    :type="!multiline && fieldAttrs.type"
                    :minlength="getMin"
                    :maxlength="getMax"
                    :rows="multiline && 3"
                    @input="setValue"
                    @change="
                        setValue;
                        onChange($event);
                    "
                    @blur="
                        checkError($event);
                        onBlur($event);
                    "
                    @focus="onFocus($event)"
                    @invalid="checkError"
                    @keydown.down.prevent="$emit('selectFirstFilterOption')"
                />
                <button
                    v-if="fieldAttrs.value.length"
                    type="button"
                    class="input-action-btn input-icon input-action-btn--reset"
                    :aria-label="$t('clear')"
                    @click="resetField"
                >
                    <IconX />
                </button>
                <span v-if="isError" class="input-icon input-icon--right input-icon--error">
                    <IconWarning />
                </span>
            </span>
        </template>
        <template v-if="errorMessage" #errorSlot>
            {{ errorMessage }}
        </template>

        <template v-if="getHintMessage" #hintSlot>{{ getHintMessage }}</template>
    </FormElemWrapper>
</template>

<script>
import FormElemWrapper from '@/components/Generic/FormElements/FormFields/FormElemWrapper.vue';
import IconWarning from '@/components/Icons/icon-warning.vue';
import IconX from '@/components/Icons/icon-x.vue';
import FormFieldsGetters from '@/mixins/FormFieldsGetters.mixin';

export default {
    name: 'InputField',
    components: {
        IconWarning,
        IconX,
        FormElemWrapper,
    },
    mixins: [FormFieldsGetters],
    props: {
        fields: {
            type: Object,
            default: () => {},
        },
        multiline: {
            type: Boolean,
            default: false,
        },
        description: {
            type: String,
            default: () => '',
        },
    },
    data() {
        return {
            //When the input field doesn't have proper value binding from the parent component
            isolatedValue: '',
        };
    },
    computed: {
        fieldAttrs() {
            return {
                name: this.fields.name?.value || '',
                placeholder: this.fields.placeholder?.value || '',
                value: this.fieldValue,
                required: !!this.fields.required?.value || false,
                type: this.fields.type?.value || 'text',
                conditional: this.fields.conditionalField?.value,
                relatesTo: this.fields.relatesTo?.value,
            };
        },
        fieldValue() {
            return this.fields?.value?.value || this.isolatedValue;
        },
    },
    watch: {
        fieldValue(value) {
            this.$refs.input.value = value;
        },
    },
    methods: {
        onFocus(evt) {
            this.$emit('onFocus', evt);
        },
        onBlur(evt) {
            this.$emit('onBlur', evt);
        },
        onChange() {
            this.$emit('onChange');
        },
        resetField() {
            this.isolatedValue = '';
            this.$emit('updateValue', { value: '' });
            this.$refs.input.value = '';
        },
        setValue(event) {
            this.isolatedValue = event.target.value;
            this.$emit('updateValue', { value: event.target.value, relatesTo: this.fieldAttrs.relatesTo?.value });
        },
        checkError(event) {
            switch (true) {
                case event.target.validity.typeMismatch:
                    this.errorMessage = this.fields.errorMessages?.typeMismatch;
                    this.isError = true;
                    break;
                case this.fieldAttrs.required && event.target.value.trim() === '':
                    this.errorMessage = this.fields.errorMessages?.valueMissing;
                    this.isError = true;
                    break;
                case event.target.validity.valueMissing:
                    this.errorMessage = this.fields.errorMessages?.valueMissing;
                    this.isError = true;
                    break;
                case event.target.validity.tooLong:
                    this.errorMessage = this.fields.errorMessages?.tooLong;
                    this.isError = true;
                    break;
                case event.target.validity.tooShort:
                    this.errorMessage = this.fields.errorMessages?.tooShort;
                    this.isError = true;
                    break;
                default:
                    this.validate(event.target.value);
                    break;
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/components/input-field';
</style>
