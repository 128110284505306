<template>
    <FundDetailsTabRow fullWidth class="mt-4">
        <FundDetailsTabSkeleton v-if="loading" />
        <AlertMessageBase v-else-if="error" type="error" :title="$t(error)" />
        <table v-else-if="tabData" aria-label="Fund details authorised countries tab table" class="w-100">
            <thead hidden>
                <tr>
                    <th scope="col">Fund authorised country name</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, key) in tabData" :key="key">
                    <td class="body-default py-4">{{ item.countryName }}</td>
                </tr>
            </tbody>
        </table>
        <div v-else class="body-default">{{ $t('noResultsMessage') }}</div>
    </FundDetailsTabRow>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import AlertMessageBase from '@/components/Generic/AlertMessageBase';

import FundDetailsTabRow from '../FundDetailsTabRow';
import FundDetailsTabSharedLogic from '../FundDetailsTabSharedLogic.mixin';
import FundDetailsTabSkeleton from '../FundDetailsTabSkeleton';

export default {
    name: 'FundDetailsAuthorisedCountriesTab',

    components: {
        FundDetailsTabRow,
        AlertMessageBase,
        FundDetailsTabSkeleton,
    },

    mixins: [FundDetailsTabSharedLogic],

    computed: {
        ...mapState('fundDetails', {
            loading: (state) => state.authorisedCountriesTabData.loading,
            error: (state) => state.authorisedCountriesTabData.error,
            tabData: (state) => state.authorisedCountriesTabData.data?.jurisdictions,
        }),
    },

    methods: {
        ...mapActions('fundDetails', {
            fetchTabData: 'fetchAuthorisedCountriesTabData',
        }),
    },
};
</script>

<style scoped lang="scss">
tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
</style>
