<template>
    <div class="p-3 background-digital-light-grey w-100">
        <h5 class="mb-3">{{ fields.title }}</h5>
        <p class="text-grey">{{ fields.body }}</p>
    </div>
</template>

<script>
export default {
    name: 'Note',
    props: {
        fields: {
            type: Object,
            default: () => {},
        },
    },
};
</script>
