<template>
    <div class="button-wrapper">
        <AbrdnButton :buttonType="'textLink'" @click.native="toTop">
            <span>{{ $t('backToTop') }}</span>
            <IconToTop aria-hidden="true" />
        </AbrdnButton>
    </div>
</template>

<script>
import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton.vue';
import IconToTop from '@/components/Icons/icon-to-top.vue';

export default {
    name: 'BackToTopButton',
    components: {
        AbrdnButton,
        IconToTop,
    },
    methods: {
        toTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/mixins';
.btn-text-link {
    text-decoration: none;
    display: flex;
    @include dark-backgrounds() {
        color: var(--button-white);
    }
    &:hover {
        text-decoration: underline;
        /deep/ .svg-image {
            circle {
                fill: var(--button-night);
                @include dark-backgrounds() {
                    fill: var(--button-mist);
                }
            }
        }
    }
    /deep/ .svg-image {
        circle {
            fill: var(--abrdn-black);
            @include dark-backgrounds() {
                fill: var(--abrdn-white);
            }
        }
        path {
            fill: var(--abrdn-white);
            @include dark-backgrounds() {
                fill: var(--abrdn-black);
            }
        }
    }
}
.button-wrapper {
    margin-left: auto;
    position: relative;
    z-index: 1;
}
</style>
