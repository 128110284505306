<template>
    <transition @enter="setWrapperHeight" @beforeLeave="setWrapperHeight">
        <div v-if="show" ref="wrapper">
            <slot />
        </div>
    </transition>
</template>

<script>
export default {
    name: 'ExpandableContent',

    props: {
        show: {
            type: Boolean,
            required: true,
        },
    },

    methods: {
        setWrapperHeight() {
            this.$refs.wrapper.style.setProperty('--height', `${this.$refs.wrapper.scrollHeight}px`);
        },
    },
};
</script>

<style scoped lang="scss">
.v-enter-active,
.v-leave-active {
    transition: height 0.3s;
    overflow: hidden;
}

.v-enter,
.v-leave-to {
    height: 0;
}

.v-leave,
.v-enter-to {
    height: var(--height);
}
</style>
