<template>
    <div :id="anchorId" :data-e2e-component-uid="rendering.uid" class="abrdn-article-footnote">
        <ol v-if="fields.items && fields.items.length">
            <li v-for="item in fields.items" :key="item.id">
                <ScText v-if="item.fields.text" tag="span" :field="item.fields.text" class="footnote" />
                <ScLink
                    v-if="item.fields.link"
                    :field="item.fields.link"
                    class="inline-link inline-link--small revert-underline"
                />
            </li>
        </ol>
        <p v-else-if="isExperienceEditor">[{{ rendering.params.name }}]</p>
    </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import ScLink from '@/components/Generic/SitecoreWrappers/ScLink.vue';

export default {
    name: 'ArticleFootnote',
    components: {
        ScLink,
        ScText: Text,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
};
</script>

<style scoped lang="scss">
ol {
    padding: 0;
    margin: 0;
    list-style: none;
    counter-reset: footnote-counter;
    margin-top: calc(var(--spacer) * 5);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
li {
    counter-increment: footnote-counter;
    display: inline-flex;
    align-items: flex-start;
    line-height: 20px;
}
li:not(:last-child) {
    margin-bottom: var(--spacer);
}
li:before {
    content: counter(footnote-counter);
    font-size: 8px;
    margin-right: var(--spacer);
}
</style>
