import { render, staticRenderFns } from "./MediaBlock.vue?vue&type=template&id=77557f86&scoped=true"
import script from "./MediaBlock.vue?vue&type=script&lang=js"
export * from "./MediaBlock.vue?vue&type=script&lang=js"
import style0 from "./MediaBlock.vue?vue&type=style&index=0&id=77557f86&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77557f86",
  null
  
)

export default component.exports