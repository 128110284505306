<template>
    <div
        class="disclaimer-modal"
        :class="{ 'disclaimer-modal--overflow': isContentOverflow, 'disclaimer-modal--reach-end': isScrolledToBottom }"
    >
        <div class="disclaimer-modal__wrapper background-white">
            <div class="disclaimer-modal__heading px-2 px-md-3 py-3">
                <ScText tag="h5" :field="fields.title" class="subheader-1" />
            </div>
            <div
                ref="content"
                class="disclaimer-modal__content mx-2 mx-md-3 py-3"
                :class="{ 'pe-3': isContentOverflow }"
                :tabindex="isContentOverflow ? '0' : '-1'"
            >
                <ScRichText class="body-default" spacingType="tight" :fields="fields" />
            </div>
            <div class="disclaimer-modal__bottom p-3">
                <AbrdnButton :linkField="fields.declineLink" buttonType="secondary">
                    <span>{{ $t('decline') }}</span>
                </AbrdnButton>
                <AbrdnButton buttonType="primary" @click.native="acceptHandler">
                    <span>{{ $t('accept') }}</span>
                </AbrdnButton>
            </div>
        </div>
    </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton';
import RichText from '@/components/Generic/SitecoreWrappers/RichText.vue';
import Cookie from '@/mixins/Cookie.mixin';

export default {
    name: 'DisclaimerModalContent',
    components: {
        AbrdnButton,
        ScText: Text,
        ScRichText: RichText,
    },
    mixins: [Cookie],
    props: {
        fields: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            isContentOverflow: false,
            isScrolledToBottom: false,
        };
    },
    mounted() {
        if (this.$refs.content) {
            this.isContentOverflow = this.$refs.content.scrollHeight > this.$refs.content.clientHeight;
            if (this.isContentOverflow) {
                this.$refs.content.addEventListener('scroll', this.scrollListener, { passive: true });
            }
        }
    },
    beforeDestroy() {
        this.$refs.content.removeEventListener('scroll', this.scrollListener);
    },
    methods: {
        scrollListener(e) {
            const elem = e.currentTarget;
            this.isScrolledToBottom = elem.scrollHeight - (elem.scrollTop + elem.clientHeight) < 5;
        },
        acceptHandler() {
            const oldCookie = JSON.parse(this.getCookie('abrdn-disclaimer') || null);
            /*
             * n - sitename
             * e - endDate
             * s - sessionId
             * l - language code
             * */
            const value = {
                n: this.$jss.sitecoreContext().site.name,
                e: undefined,
                s: undefined,
                l: this.$jss.sitecoreContext().language,
            };
            //const isSessionBased = this.fields.session.value;
            const isSessionBased = false;
            if (isSessionBased) {
                value.s = this.getCookie('ASP.NET_SessionId');
            } else {
                const date = new Date();
                value.e = date.setTime(date.getTime() + 90 * 24 * 60 * 60 * 1000);
            }
            const cookieValue = oldCookie || [];
            const expiredCookieIndex = cookieValue.findIndex(
                (cookie) =>
                    cookie.n === this.$jss.sitecoreContext().site.name &&
                    cookie.l === this.$jss.sitecoreContext().language,
            );
            if (expiredCookieIndex !== -1) {
                cookieValue.splice(expiredCookieIndex, 1);
            }
            cookieValue.push(value);
            this.setCookie('abrdn-disclaimer', JSON.stringify(cookieValue), 9999);
            this.$emit('closeModal');
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
@import '@/assets/styles/variables';
.disclaimer-modal {
    margin: 112px 0;
    display: flex;
    max-height: 100%;
    overflow: hidden;
    width: 100%;
    @include media-breakpoint-down(md) {
        margin: 40px 0;
    }
}

.disclaimer-modal__wrapper {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    width: 100%;
}

.disclaimer-modal__heading,
.disclaimer-modal__bottom {
    flex-shrink: 0;
}

.disclaimer-modal__bottom {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.disclaimer-modal__content {
    flex-grow: 1;
    border-top: 1px solid $dark-grey;
    border-bottom: 1px solid $dark-grey;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background: $digital-deep-grey;
        border-radius: 8px;
    }
}
.disclaimer-modal--overflow {
    .disclaimer-modal__content {
        border-bottom-color: transparent;
    }
    .disclaimer-modal__bottom {
        box-shadow: 0px -4px 28px 0px rgba(0, 0, 0, 0.08);
    }
    &.disclaimer-modal--reach-end {
        .disclaimer-modal__bottom {
            box-shadow: none;
        }
        .disclaimer-modal__content {
            border-bottom-color: $dark-grey;
        }
    }
}
</style>
