<template>
    <tr v-if="!fund.skeleton">
        <td class="text-link sticky-column">
            <ScLink
                v-if="hasValidUrlData && !isExperienceEditor"
                class="text-decoration-underline"
                :field="linkValue"
            />
            <span v-else>{{ fund.name }}</span>
        </td>
        <FundsShareclassCell v-if="!hideShareclass" :fund="fund" :fundIdx="fundIdx" />
        <td v-for="generalColumn in columnsToShow.general" :key="generalColumn.visibleName">
            <div class="d-flex">
                <span class="d-xl-none section-header-small">
                    {{ generalColumn.visibleName }}
                </span>
                <Tooltip
                    v-if="
                        generalColumn.propertyName === 'sfdrClassification' &&
                        $t('fundsFundCentreTooltipSfdr') &&
                        $t('fundsFundCentreTooltipSfdr') !== 'fundsFundCentreTooltipSfdr'
                    "
                    :arialLabelText="generalColumn.visibleName"
                    class="d-xl-none"
                >
                    <template #tooltipTitle>
                        <p class="tooltip-title">
                            {{ generalColumn.visibleName }}
                        </p>
                    </template>
                    <template #tooltipContent>
                        <p class="tooltip-description">{{ $t('fundsFundCentreTooltipSfdr') }}</p>
                    </template>
                </Tooltip>
            </div>

            <span class="h6">{{ fund[generalColumn.propertyName] }}</span>
        </td>
        <td v-for="specificColumn in columnsToShow.shareclassSpecific" :key="specificColumn.propertyName">
            <span class="d-xl-none section-header-small">
                {{ specificColumn.visibleName }}
            </span>
            <template v-if="fund.selectedShareclass">
                <FundsPrice
                    v-if="isPrice(specificColumn.propertyName)"
                    :selectedShareclass="fund.selectedShareclass"
                    :propertyName="specificColumn.propertyName"
                />

                <div v-else-if="notNumericValues.includes(specificColumn.propertyName)" class="h6">
                    <template v-if="fund.selectedShareclass[specificColumn.propertyName]">
                        {{ fund.selectedShareclass[specificColumn.propertyName] }}
                    </template>
                    <template v-else>--</template>
                </div>

                <FundsExternalLink
                    v-else-if="hasDocumentURI(specificColumn.propertyName)"
                    :document="fund.selectedShareclass[specificColumn.propertyName]"
                />
            </template>
        </td>
    </tr>
    <FundsTableRowSkeleton v-else />
</template>

<script>
import path from 'path';
import { mapState } from 'vuex';

import Tooltip from '@/components/Generic/FormElements/FormFields/Tooltip';
import ScLink from '@/components/Generic/SitecoreWrappers/ScLink.vue';
import FundsExternalLink from '@/components/Pages/FundsCenter/AllFunds/FundsListing/FundsTableParts/CellParts/FundsExternalLink.vue';
import FundsPrice from '@/components/Pages/FundsCenter/AllFunds/FundsListing/FundsTableParts/CellParts/FundsPrice.vue';
import FundsShareclassCell from '@/components/Pages/FundsCenter/AllFunds/FundsListing/FundsTableParts/Cells/FundsShareclassCell.vue';
import FundsTableRowSkeleton from '@/components/Pages/FundsCenter/AllFunds/FundsListing/FundsTableParts/FundsTableRowSkeleton.vue';
import { formatNumber } from '@/mixins/Helpers.mixin';

export default {
    name: 'FundsTableRow',
    components: {
        FundsTableRowSkeleton,
        FundsShareclassCell,
        FundsExternalLink,
        FundsPrice,
        ScLink,
        Tooltip,
    },
    mixins: [formatNumber],
    props: {
        fund: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        fundIdx: {
            type: Number,
            required: true,
        },
        columnsToShow: {
            type: Object,
            required: true,
        },
        hideShareclass: {
            type: Boolean,
            default: () => false,
        },
    },
    data() {
        return {
            notNumericValues: ['currency', 'newspaperName'],
        };
    },
    computed: {
        ...mapState('allFunds', ['fundDetailsPageUrl']),
        selectedShareclassUrl() {
            return this.fund.selectedShareclass?.url || '';
        },
        hasValidUrlData() {
            return !!this.fundDetailsPageUrl && !!this.selectedShareclassUrl;
        },
        fundUrl() {
            return this.isAbsoluteLink
                ? this.selectedShareclassUrl
                : path.join(this.fundDetailsPageUrl, this.selectedShareclassUrl);
        },
        isAbsoluteLink() {
            return this.selectedShareclassUrl.startsWith('http');
        },
        linkValue() {
            return {
                value: { href: this.fundUrl, text: this.fund?.name, target: this.isAbsoluteLink ? '_blank' : '' },
            };
        },
    },
    methods: {
        isPrice(propertyName) {
            return [
                'dailyPrice',
                'priceChange',
                'navPrice',
                'applicationPrice',
                'redemptionPrice',
                'nav',
                'offerPrice',
                'bidPrice',
                'assetSize',
            ].includes(propertyName);
        },
        hasDocumentURI(propertyName) {
            return this.fund.selectedShareclass[propertyName]?.documentURI;
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
@include media-breakpoint-down(xl) {
    tr {
        padding: calc(var(--spacer) * 3) calc(var(--spacer) * 2);
        border: var(--default-table-border);
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: calc(var(--spacer) * 3);
        position: relative;
        &.skeleton-row {
            border-color: transparent;
        }
        & + tr {
            margin-top: calc(var(--spacer) * 3);
        }
        ::v-deep td:nth-child(1),
        ::v-deep td:nth-child(2) {
            grid-column: 1 / -1;
        }
        ::v-deep td {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
        }
        .my-fund-cell {
            position: absolute;
            bottom: calc(var(--spacer) * 3);
            right: calc(var(--spacer) * 2);
        }
        @include media-breakpoint-down(md) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .mt-1\/2 {
        margin-top: calc(var(--spacer) * 0.5);
    }
}
.scrollable {
    .sticky-column {
        background-color: var(--abrdn-white);
        z-index: 1;
    }
}
</style>
