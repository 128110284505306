<template>
    <RelatedArticlesSections
        :id="anchorId"
        :sectionTitle="$t('relatedTaggedHelpTitle')"
        :fields="fields"
        :rendering="rendering"
        :sliderCardComponent="sliderCardComponent"
        :backgroundColor="vectorBasedBackground"
    />
</template>

<script>
import RelatedArticlesSections from '@/components/Sections/RelatedArticlesVariations/RelatedArticlesSection';
import HelpArticleTile from '@/components/Tiles/HelpArticleTile';

export default {
    name: 'RelatedHelpArticlesSection',
    components: { RelatedArticlesSections },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    data() {
        return {
            sliderCardComponent: HelpArticleTile,
        };
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
::v-deep .swiper-slide .help-article {
    width: 370px;
    @include media-breakpoint-down(xl) {
        width: 290px;
    }
}
::v-deep .swiper-slide {
    width: auto;
}
</style>
