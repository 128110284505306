<template>
    <p class="footnote text-grey">
        <template v-if="!showSkeleton">
            {{ $t(dictionaryKey).replace('{token.range}', `${from} - ${to}`).replace('{token.total}', `${total}`) }}
        </template>
        <BaseSkeleton v-else width="220px" class="d-block" />
    </p>
</template>

<script>
import BaseSkeleton from '@/components/Generic/BaseSkeleton.vue';

export default {
    name: 'DisplayingRange',
    components: { BaseSkeleton },
    props: {
        dictionaryKey: {
            type: String,
            default: 'paginationDisplayingResults',
        },
        page: {
            type: Number,
            required: true,
        },
        take: {
            type: Number,
            required: true,
        },
        total: {
            type: Number,
            required: true,
        },
        showSkeleton: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        from() {
            if (this.total === 0) {
                return 0;
            }
            return (this.page - 1) * this.take + 1;
        },
        to() {
            return Math.min(this.page * this.take, this.total);
        },
    },
};
</script>

<style scoped></style>
