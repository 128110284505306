<template>
    <section :id="anchorId" :data-e2e-component-uid="rendering.uid" class="default-section-padding background-white">
        <form v-if="showForm" class="container-fluid relative" :name="getFormName" @submit="postFormData($event)">
            <template v-if="!responseMessage">
                <div class="row">
                    <div class="col-8 content-wrapper">
                        <template v-for="(row, i) in layout.rows">
                            <p v-if="isHeader(row)" :key="'h' + i" class="h3 form-field-header">
                                {{ row.cols[0].cells[0].element.name }}
                            </p>
                            <p
                                v-else-if="isDescription(row)"
                                :key="'d' + i"
                                class="form-field-list body-default text-grey mb-5"
                            >
                                {{ row.cols[0].cells[0].element.name }}
                            </p>
                            <template v-else>
                                <div
                                    v-if="!!row.cols[0].cells[0]"
                                    :key="row.cols[0].cells[0].element.createdFromContactFieldId + '' + i"
                                    class="mb-3"
                                    :class="[getColClass(row.cols[0].width)]"
                                >
                                    <FormsSharedFields
                                        v-if="
                                            showByCondition(row.cols[0].cells[0].element, 0) &&
                                            row.cols[0].cells[0].element.displayType !== 'removeSubmission'
                                        "
                                        :element="row.cols[0].cells[0].element"
                                        :formName="getFormName"
                                        formType="salesforce"
                                        @change="setValue($event, 0)"
                                        @removeAsset="removeForm(0)"
                                        @updateDataLayer="pushDataLayerEvent($event)"
                                        @executeFormStartEvent="executeFormStartEvent($event)"
                                    />
                                </div>
                            </template>
                        </template>
                    </div>
                </div>
                <div v-if="requestedFormsNumber > 1" class="row">
                    <div
                        v-for="(col, key, idx) in fieldsToRepeat"
                        :key="key"
                        :class="{ 'background-mist': idx % 2 === 0 }"
                    >
                        <div class="col-8 content-wrapper">
                            <p class="h3 form-field-header mt-5">
                                {{ `Asset ${idx + 2} details` }}
                            </p>
                            <div v-for="(element, i) in col" :key="i">
                                <div :key="key" class="mb-3" :class="getColClass(element.colWidth)">
                                    <KeepAlive>
                                        <FormsSharedFields
                                            v-if="showByCondition(element.element, idx + 1)"
                                            :element="element.element"
                                            :formName="getFormName"
                                            formType="salesforce"
                                            @change="setValue($event, idx + 1)"
                                            @removeAsset="removeForm(key, idx)"
                                            @updateDataLayer="pushDataLayerEvent($event)"
                                            @executeFormStartEvent="executeFormStartEvent($event)"
                                        />
                                    </KeepAlive>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-8 content-wrapper">
                        <template v-for="(row, index) in bottomRows">
                            <template v-for="(col, i) in row.cols">
                                <div :key="'col' + index + i" :class="getColClass(col.width)">
                                    <template v-if="col.cells[0].element.displayType !== 'addSubmission'">
                                        <p v-if="showHeaderByLimit(row)" :key="i" class="h3 form-field-header">
                                            {{ col.cells[0].element.name }}
                                        </p>
                                        <p
                                            v-else-if="showByLimit(row)"
                                            :key="i"
                                            class="form-field-list body-default text-grey w-75"
                                        >
                                            {{ col.cells[0].element.name }}
                                        </p>
                                    </template>
                                    <AbrdnButton
                                        v-if="col.cells[0].element.displayType === 'addSubmission' && showAddFormButton"
                                        :key="'btn' + i"
                                        class="add-asset-button"
                                        type="button"
                                        @click.native="addForm($event)"
                                    >
                                        <span>{{ col.cells[0].element.name }}</span>
                                        <em class="bi bi-plus ms-1 body-large" aria-hidden="true" />
                                    </AbrdnButton>
                                </div>
                            </template>
                        </template>
                    </div>
                </div>
                <div class="row">
                    <div class="col-8 content-wrapper">
                        <template v-for="(col, i) in submitRow">
                            <div :key="'A' + i" :class="getColClass(col.width)">
                                <AbrdnButton :id="col.cells[0].element.id" buttonType="primary">
                                    <span>{{ getButtonText(col) }}</span>
                                </AbrdnButton>
                            </div>
                        </template>
                    </div>
                </div>
            </template>
            <div
                v-if="responseMessage"
                class="container-fluid background-white d-flex flex-column justify-content-center py-5"
            >
                <!-- eslint-disable vue/no-v-html -->
                <div id="messageContent" class="mx-auto" v-html="responseMessage" />
            </div>
        </form>
    </section>
</template>

<script>
import { postSalesforceCaseFormData } from '@/api/SalesforceCaseForm';
import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton';
import FormsSharedFields from '@/components/Sections/Forms/FormsSharedFields';
import DataLayerHandler from '@/mixins/DataLayer.mixin';
import FormsSharedLogic from '@/mixins/FormsSharedLogic.mixin';

export default {
    name: 'SalesforceCaseForm',

    components: {
        AbrdnButton,
        FormsSharedFields,
    },

    mixins: [FormsSharedLogic, DataLayerHandler],

    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            requestedFormsNumber: 1,
            fieldsToRepeat: {},
            selectedOptionsByIndex: {},
            responseMessage: '',
            removedFormKey: null,
            requesterDetailsData: {},
        };
    },
    computed: {
        showAddFormButton() {
            return (
                this.fields.submissionLimit.value > 1 &&
                !this.subscriptionSuccess &&
                this.requestedFormsNumber < +this.fields.submissionLimit.value
            );
        },
        formKey() {
            return this.removedFormKey || `form-${this.requestedFormsNumber}`;
        },
    },
    mounted() {
        if (!this.showForm) {
            console.log('missing data');
        }
    },
    methods: {
        setValue({ id, value }, index) {
            this.salesforceFormData.records[index][id] = value;
            this.includeHiddenFields(index, value);
            this.salesforceFormData.records[index].attributes = { type: 'Case' };

            // use forceUpdate to render hidden fields if they pass validation
            this.$forceUpdate();
        },
        executeFormStartEvent(payload) {
            if (!this.formStartEventExistInDataLayer) {
                this.formStartEventExistInDataLayer = true;
                this.pushDataLayerEvent(payload);
            }
        },
        showByCondition(element, index) {
            if (element.conditionalField && this.salesforceFormData.records.length) {
                return (
                    this.salesforceFormData.records[index][element.conditionTargetField] ===
                    element.conditionTargetValue
                );
            }

            return true;
        },
        addRequesterDetailsToEachOfRequestedForms() {
            this.uniqueFields.forEach((field) => {
                const id = field.cols[0].cells[0].element.id;
                if (this.salesforceFormData.records[0][id] !== undefined) {
                    this.requesterDetailsData[id] = this.salesforceFormData.records[0][id];
                }
            });
            this.salesforceFormData.records.forEach((form) => Object.assign(form, this.requesterDetailsData));
        },
        postFormData(event) {
            event.preventDefault();
            this.addRequesterDetailsToEachOfRequestedForms();

            postSalesforceCaseFormData(this.salesforceFormData, this.fields.formJson.value.postUrl)
                .then((res) => {
                    if (res.status === 200 && res.data === true) {
                        this.responseMessage = this.fields.successMessage.value;
                        this.requestedFormsNumber = 0;
                        this.pushDataLayerEvent({
                            event: 'form_submit',
                            formName: this.getFormName,
                            form_field: 'n/a',
                        });
                    } else {
                        this.responseMessage = this.fields.errorMessage.value;
                    }
                })
                .finally(() => {
                    this.$nextTick(() => {
                        const messageContainer = document.getElementById('messageContent');
                        if (messageContainer) {
                            messageContainer.scrollIntoView();
                        }
                    });
                });
        },
        includeHiddenFields(index) {
            this.formField.map((field) => {
                field.cols.map((col) => {
                    col.cells.map((cell) => {
                        if (cell.element?.displayType === 'hidden') {
                            this.salesforceFormData.records[index][cell?.element.id] =
                                cell?.element?.defaultValue &&
                                cell?.element?.defaultValue.replace(
                                    '[{fieldValue.Asset_Name__c}]',
                                    this.salesforceFormData.records[index].Asset_Name__c,
                                );
                        }
                    });
                });
            });
            if (!this.salesforceFormData.records[index].Asset_Name__c) {
                delete this.salesforceFormData.records[index].Subject;
            }
        },
        addForm() {
            this.requestedFormsNumber += 1;
            this.fieldsToRepeat[this.formKey] = this.sharedFields;
            this.removedFormKey = null;
            this.salesforceFormData.records.push({});
        },
        removeForm(key, idx) {
            this.removedFormKey = key;
            this.requestedFormsNumber -= 1;
            this.$delete(this.fieldsToRepeat, key);
            this.salesforceFormData.records.splice(idx + 1, 1);
        },
        showByLimit(row) {
            return (
                (row.showUnderLimit && this.requestedFormsNumber < +this.fields.submissionLimit.value) ||
                (row.showOverLimit && this.requestedFormsNumber === +this.fields.submissionLimit.value)
            );
        },
        showHeaderByLimit(row) {
            return row.showUnderLimit ? this.isHeader(row) && this.showByLimit(row) : this.isHeader(row);
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixins';
@import '@/assets/styles/variables';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
.add-asset-button,
::v-deep .start-another-request {
    background-color: var(--abrdn-white);
    border-radius: 24px;
    border: 2px solid var(--abrdn-black);
    align-items: center;
    height: 3rem;
    padding: 0 calc(var(--spacer) * 4);
    margin-bottom: 4.37rem;
    width: fit-content;
    &:hover {
        background-color: var(--abrdn-black);
        color: var(--abrdn-white);
    }
}
::v-deep {
    .btn-primary {
        height: 48px;
        display: inline-flex;
        align-items: center;
        border-radius: 24px;
        transition: background-color 0.2s, color 0.2s, opacity 0.2s;
        padding: 0 calc(var(--spacer) * 4);
        white-space: initial;
        background-color: var(--button-black);
        color: var(--abrdn-white);
        border: none;
        @include dark-backgrounds() {
            background: var(--button-white);
            color: var(--button-black);
        }
        &:hover {
            background-color: var(--button-night);
        }
    }
}
.message-content {
    height: fit-content;
}
.content-wrapper {
    margin-left: 7rem;
    @include media-breakpoint-down(lg) {
        margin-left: 4rem;
    }
    @include media-breakpoint-down(md) {
        margin-left: 1rem;
    }
}
</style>
