var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tile",class:( _obj = {}, _obj[("" + (_vm.size.split('-').join('-md-')))] = !_vm.singleTile, _obj["col-md-12"] = _vm.singleTile, _obj[_vm.backgroundClass + '-repeat'] = true, _obj )},[_c('div',{staticClass:"pb-3 d-flex flex-column flex-xl-row h-100 link-change",class:( _obj$1 = {}, _obj$1[_vm.backgroundClass] = true, _obj$1[_vm.outerVerticalPaddings] = _vm.outerVerticalPaddings, _obj$1['flex-xl-column'] =  _vm.size === 'col-4' || _vm.isSvgIcon, _obj$1 )},[_c('WrappedImage',{staticClass:"background-to-change",class:{
                'tile-image-big': _vm.size === 'col-8' && !_vm.isSvgIcon,
                'tile-image-small': _vm.size === 'col-4' && !_vm.isSvgIcon,
                'tile-image-medium': _vm.size === 'col-6' && !_vm.isSvgIcon,
                'tile-image-svg': _vm.isSvgIcon,
                'tile-image-svg-small': _vm.isSvgIcon && _vm.size === 'col-4',
            },attrs:{"media":_vm.fields.image,"srcSet":[{ mw: 400 }, { mw: 600 }, { mw: 800 }, { mw: 1000 }],"sizes":"\n                (max-width: 575px) 100vw,\n                (max-width: 767px) 510px,\n                (max-width: 991px) 450px,\n                (max-width: 1199px) 66.6vw,\n                442px\n            "}}),_c('div',{staticClass:"d-flex flex-column align-items-start px-2 px-xl-3 h-100 w-100",class:_vm.innerVerticalPaddings},[_c('div',[_c('ScText',{staticClass:"background-to-change mb-2",class:{ 'mt-3': _vm.isSvgIcon },attrs:{"field":_vm.fields.title,"tag":"h4"}}),_c('ScText',{staticClass:"background-to-change body-default text-grey mb-1",attrs:{"field":_vm.fields.description,"tag":"p"}}),_c('AbrdnPlaceholder',{staticClass:"placeholder mt-2 mb-3",attrs:{"rendering":_vm.rendering,"name":"abrdn-list"}})],1),(_vm.ctaLinks.length)?_c('div',{staticClass:"mt-auto d-flex flex-column align-items-start"},_vm._l((_vm.fields.items),function(field){return _c('ScLink',{key:field.id,staticClass:"mt-2 background-to-change inline-link tile-link d-inline-block",attrs:{"field":field.fields.cta}})}),1):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }