export default {
    computed: {
        deviceType() {
            if (this.$root.breakPoints?.current === 'xs' || this.$root.breakPoints?.current === 'sm') {
                return 'mobile';
            }
            if (this.$root.breakPoints?.current === 'md') {
                return 'tablet';
            }
            if (this.$root.breakPoints?.current === 'lg') {
                return 'small-desktop';
            }
            if (this.$root.breakPoints?.current === 'xl') {
                return 'desktop';
            }
            return false;
        },
    },
};
