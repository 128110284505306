<template>
    <div :id="anchorId" :data-e2e-component-uid="rendering.uid" class="container pt-5 pt-md-6">
        <div class="row d-flex justify-content-end flex-column flex-md-row article-body-wrapper">
            <div class="col-12 col-md-12 col-lg-3 mt-6 mt-md-0 abrdn-article-chapters-wrapper">
                <AbrdnPlaceholder name="abrdn-article-chapters" :rendering="rendering" />
            </div>
            <div class="col-12 col-md-8 col-lg-6">
                <Placeholder name="abrdn-article-key-highlights" class="mb-5 mb-md-6 mb-xl-8" :rendering="rendering" />
                <Placeholder
                    name="abrdn-article-intro"
                    class="abrdn-article-intro d-flex flex-column mb-5"
                    :rendering="rendering"
                />
                <div :class="[{ 'hide-article-body': !isExperienceEditor && havePremiumArticleEloquaForm }]">
                    <Placeholder
                        ref="abrdn-article-body"
                        name="abrdn-article-body"
                        class="abrdn-article-body d-flex flex-column"
                        :rendering="rendering"
                        :articleBody="true"
                    />
                    <AbrdnPlaceholder name="abrdn-article-footnote" :rendering="rendering" />
                    <AbrdnPlaceholder name="abrdn-article-next-steps" class="mt-5" :rendering="rendering" />
                </div>
                <Placeholder ref="eloquaForm" name="abrdn-premium-article-eloqua-form" :rendering="rendering" />
            </div>
            <div class="col-12 col-md-4 col-lg-3 mt-6 mt-md-0">
                <div class="article-body__sidebar">
                    <AbrdnPlaceholder
                        name="abrdn-article-related-capabilities"
                        :rendering="rendering"
                        :class="{ 'hide-on-mobile': nextSteps }"
                    />
                    <!-- just in case you need to make something sticky
                    <StickySidebar></StickySidebar>
                    -->
                    <AbrdnPlaceholder
                        class="related-articles-placeholder"
                        name="abrdn-article-related-stories"
                        :rendering="rendering"
                    />
                </div>
            </div>
        </div>
        <BackToTopButton class="my-3 my-lg-4 d-flex justify-content-end" />
    </div>
</template>

<script>
import { mapState } from 'vuex';

import ArticleBodySharedLogic from '@/mixins/ArticleBodySharedLogic.mixin';
import ElementFinder from '@/mixins/ElementFinder.vue';
import { isPlaceholderNotEmpty } from '@/mixins/Helpers.mixin';

export default {
    name: 'ArticleBody',
    mixins: [isPlaceholderNotEmpty, ElementFinder, ArticleBodySharedLogic],
    props: {
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    data() {
        return {
            nextSteps: null,
        };
    },
    computed: {
        ...mapState('global', ['havePremiumArticleEloquaForm']),
    },
    mounted() {
        this.findComponentInTree(this.$jss.routeData().placeholders['abrdn-main'], 'NextSteps', 'nextSteps');
        const premiumArticleHeight = this.$refs['abrdn-article-body'].$el.clientHeight * 0.1;
        if (this.havePremiumArticleEloquaForm) {
            this.$refs['abrdn-article-body'].$el.setAttribute('tabindex', -1);
        }
        document.documentElement.style.setProperty('--premiumArticleHeight', premiumArticleHeight + 'px');

        if (typeof window === 'undefined' || this.isExperienceEditor) return;
        window.dataLayer?.push({
            assetClass: this.$jss.sitecoreContext().dataLayer.content.article_capability || 'n/a',
        });
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/article-body';
</style>
