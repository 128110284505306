<template>
    <div class="tooltip" :class="{ ['ms-1']: isDefaultTooltip }" @blur="isOpen = false">
        <button
            ref="tooltipTrigger"
            :aria-label="$t('moreInformation') + arialLabelText"
            :aria-expanded="isOpen ? 'true' : 'false'"
            class="tooltip__trigger"
            aria-controls="popupContent"
            @click="isOpen = !isOpen"
        >
            <slot v-if="!isDefaultTooltip" name="tooltipTrigger" />
            <p v-else class="tooltip-trigger">i</p>
        </button>
        <div
            v-if="isOpen"
            id="popupContent"
            ref="popup"
            class="tooltip__content d-flex p-2"
            role="tooltip"
            tabindex="-1"
            @keydown.esc="isOpen = !isOpen"
        >
            <div>
                <slot name="tooltipTitle" />
                <slot name="tooltipContent" tabindex="0" />
            </div>
            <button
                tabindex="0"
                class="tooltip__closer"
                aria-label="Hide tooltip"
                @click="isOpen = !isOpen"
                @blur="isOpen = false"
            >
                <IconX />
            </button>
        </div>
        <div class="overlay d-lg-none" :class="{ active: isOpen }" @click="isOpen = false" />
    </div>
</template>

<script>
import IconX from '@/components/Icons/icon-x.vue';

export default {
    name: 'Tooltip',
    components: { IconX },
    props: {
        arialLabelText: {
            type: String,
            default: () => '',
        },
    },
    data() {
        return {
            isOpen: false,
        };
    },
    computed: {
        isDefaultTooltip() {
            return !this.$slots['tooltipTrigger'];
        },
    },
    watch: {
        isOpen: {
            handler(val) {
                if (val) {
                    this.$nextTick(() => {
                        this.$refs.popup.focus();
                        document.addEventListener('click', this.handleClickOutside);
                    });
                } else {
                    this.$refs.tooltipTrigger.focus();
                    document.removeEventListener('click', this.handleClickOutside);
                }
            },
        },
    },

    methods: {
        handleClickOutside(evt) {
            if (
                this.$refs.popup &&
                !this.$refs.popup.contains(evt.target) &&
                !this.$refs.tooltipTrigger.contains(evt.target)
            ) {
                this.isOpen = false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
@import '@/assets/styles/mixins';
.tooltip {
    display: inline-block;
    position: initial;
    @include media-breakpoint-up(lg) {
        position: relative;
    }
    & button {
        width: 16px;
        height: 16px;
        padding: 0;
        appearance: none;
        border: none;
        svg {
            width: 100%;
            height: 100%;
        }
    }
    & .tooltip__content {
        position: absolute;
        z-index: 9999;
        bottom: calc(100% + var(--spacer) * 2);
        left: 50%;
        width: 256px;
        height: auto;
        transform: translateX(-50%);
        border: 1px solid var(--button-night);
        padding: var(--spacer);
        border-radius: 8px;
        background-color: $white;
        color: $black;
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        letter-spacing: 0.02em;
        text-transform: none;
        text-wrap: initial;
        box-shadow: 0px 16px 100px rgba(9, 9, 14, 0.12);
        @include media-breakpoint-up(lg) {
            background-color: var(--digital-deep-grey);
            color: white;
            .tooltip__closer {
                color: $white;
            }
            &::before,
            &::after {
                --border-x: calc(var(--spacer) * 0.5);
                --border-y: var(--spacer);
                content: '';
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translateX(-50%) rotate(180deg);
            }
            &::before {
                border: calc(var(--border-y) + 2px) solid transparent;
                border-right-width: calc(var(--border-x) + 1px);
                border-left-width: calc(var(--border-x) + 1px);
                border-bottom-color: var(--digital-deep-grey);
            }
        }
        @include media-breakpoint-down(lg) {
            left: 50%;
            transform: translateX(-50%);
            top: 50%;
            width: 256px;
            height: fit-content;
        }
        @include dark-backgrounds() {
            color: var(--abrdn-black);
        }
        & .tooltip__closer {
            width: calc(var(--spacer) * 2);
            height: calc(var(--spacer) * 2);
            margin-left: auto;
            background-color: transparent;
            & svg {
                width: 14px;
                height: 14px;
            }
            &:focus {
                outline: 1px solid $white;
            }
            @include media-breakpoint-down(lg) {
                &:focus {
                    outline: 1px solid $black;
                }
            }
        }

        & .tooltip-title {
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.02em;
            font-weight: 600;
            margin-bottom: 1.5rem;
        }

        & .tooltip-description {
            white-space: pre-line;
        }
    }

    &.active .tooltip__content,
    &.active .tooltip__content {
        opacity: 1;
        pointer-events: all;
    }
}

.tooltip-trigger {
    background: var(--button-black);
    border-radius: 50%;
    min-width: 16px;
    min-height: 16px;
    max-width: 16px;
    max-height: 16px;
    color: var(--abrdn-white);
    font-size: 10px;
    text-transform: none;
}
.overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    opacity: 0;
    -webkit-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;
    pointer-events: all;
    z-index: -1;
    outline: none;

    &.active {
        opacity: 1;
        z-index: 1;
    }
}
</style>
