<template>
    <NextStepsCommon v-if="relatedCapabilitiesLength || isExperienceEditor" v-bind="passThroughProps" />
</template>

<script>
import ElementFinder from '@/mixins/ElementFinder';

import NextStepsCommon from './NextStepsCommon';

export default {
    name: 'NextSteps',

    components: {
        NextStepsCommon,
    },

    mixins: [ElementFinder],

    props: {
        rendering: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            relatedCapabilitiesComponent: null,
        };
    },

    computed: {
        relatedCapabilitiesLength() {
            return this.relatedCapabilitiesComponent?.fields?.relatedCapabilities?.value?.length;
        },
        passThroughProps() {
            return {
                rendering: this.rendering,
                title: this.$t('nextSteps'),
                subtitle: this.$t('nextStepsTitle'),
                description: this.$t('nextStepsDescription'),
                placeholderName: 'abrdn-article-next-steps-content',
            };
        },
    },

    mounted() {
        this.findComponentInTree(
            this.$jss.routeData().placeholders['abrdn-main'],
            'RelatedCapabilities',
            'relatedCapabilitiesComponent',
        );
    },
};
</script>
