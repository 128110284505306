<template>
    <FundDetailsTabRow v-if="isThai && tabData && tabData.length">
        <ManualDisclaimer
            v-if="isThai"
            variant="secondary"
            :title="$t('fundDetailsPerformanceFootnoteTitle')"
            :disclaimerData="tabData"
        />
    </FundDetailsTabRow>

    <FundDetailsTabRow v-else-if="tabData && tabData.length" fullWidth>
        <ManualDisclaimer :disclaimerData="tabData" />
    </FundDetailsTabRow>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import ManualDisclaimer from '@/components/Generic/ManualDisclaimer';
import FundDetailsTabSharedLogic from '@/components/Pages/FundsCenter/FundDetails/FundDetailsTabSharedLogic.mixin';

import FundDetailsTabRow from '../FundDetailsTabRow';

export default {
    name: 'FundDetailsPerformanceDisclaimer',

    components: {
        ManualDisclaimer,
        FundDetailsTabRow,
    },

    mixins: [FundDetailsTabSharedLogic],

    props: {
        isThai: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        ...mapState('fundDetails', {
            tabData: (state) => state.fundsPerformanceDisclaimerData.data,
        }),
    },

    methods: {
        ...mapActions('fundDetails', {
            fetchTabData: 'fetchFundsPerformanceDisclaimer',
        }),
    },
};
</script>
