import Vue from 'vue';

import ComponentLevelNotification from '@/components/Generic/ExperienceEditor/ComponentLevelNotification';
import FailSafePlaceholder from '@/components/Generic/ExperienceEditor/FailSafePlaceholder';

export default function wrapComponent(Component) {
    return Vue.component('ComponentFactory', {
        functional: true,
        // to compensate for the lack of an instance
        // we pass the context as the second argument
        render: function (createElement, context) {
            const { isDatasourcePresented, isCorrectDatasourceTemplate, dependentLanguages } =
                context.data.props.params;
            //Fail safe rendering
            const shouldRenderFailSafePlaceholder =
                isDatasourcePresented === 'False' || isCorrectDatasourceTemplate === 'False';
            if (shouldRenderFailSafePlaceholder) {
                if (context.parent.$jss.sitecoreContext().pageEditing) {
                    return createElement(FailSafePlaceholder, context.data, context.children);
                }
                return null;
            }
            // Component level notification rendering
            let componentLevelNotification = createElement();
            if (
                !!dependentLanguages &&
                Component.name !== 'LanguageSelector' &&
                context.parent.$jss.sitecoreContext().pageEditing
            ) {
                componentLevelNotification = createElement(ComponentLevelNotification, context.data, context.children);
            }
            //Component factory
            const componentToRender = createElement(Component, context.data, context.children);
            if (context.data.props.wrapperCols) {
                function getColsClasses() {
                    if (typeof context.data.props.wrapperCols === 'object') {
                        let returnedValue = '';
                        Object.entries(context.data.props.wrapperCols.breakPoints).forEach((objectProperty) => {
                            const key = objectProperty[0];
                            const value = objectProperty[1] === 'dynamic' ? '' : objectProperty[1];
                            returnedValue += `col-${key + (value ? '-' + value : '')} `;
                        });
                        return (returnedValue += 'd-flex flex-column');
                    } else if (context.data.props.wrapperCols === 'dynamic') {
                        return 'col-sm-12 col-md-6 col-xl d-flex flex-column';
                    } else {
                        const initialColsNumber = context.data.props.wrapperCols;
                        const mdColsNumber = initialColsNumber < 6 ? 6 : initialColsNumber;
                        return `col-md-${mdColsNumber} col-xl-${initialColsNumber} d-flex flex-column`;
                    }
                }
                return createElement(
                    'div',
                    {
                        class: getColsClasses(),
                        // ...id:
                    },
                    componentLevelNotification ? [componentLevelNotification, componentToRender] : componentToRender,
                );
            } else {
                const { params, rendering } = context.data.props;
                if (params?.hasPersonalization === 'True' && !rendering?.personalized) {
                    return null;
                }
                return componentLevelNotification ? [componentLevelNotification, componentToRender] : componentToRender;
            }
        },
    });
}
