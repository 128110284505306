<template>
    <FundDetailsTabRow class="mt-4">
        <FundDetailsTabSkeleton v-if="loading" />
        <AlertMessageBase v-else-if="error" type="error" :title="$t(error)" />
        <template v-else-if="tabData && tabData.length">
            <p
                v-for="(item, index) in tabData"
                :key="item"
                class="body-default text-grey"
                :class="{ 'mt-3': index > 0 }"
            >
                {{ item }}
            </p>
        </template>
        <div v-else class="body-default">{{ $t('noResultsMessage') }}</div>
    </FundDetailsTabRow>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import AlertMessageBase from '@/components/Generic/AlertMessageBase';

import FundDetailsTabRow from '../FundDetailsTabRow';
import FundDetailsTabSharedLogic from '../FundDetailsTabSharedLogic.mixin';
import FundDetailsTabSkeleton from '../FundDetailsTabSkeleton';

export default {
    name: 'FundDetailsKeyRiskTab',

    components: {
        FundDetailsTabRow,
        AlertMessageBase,
        FundDetailsTabSkeleton,
    },

    mixins: [FundDetailsTabSharedLogic],

    computed: {
        ...mapState('fundDetails', {
            loading: (state) => state.keyRiskTabData.loading,
            error: (state) => state.keyRiskTabData.error,
            tabData: (state) => state.keyRiskTabData.data,
        }),
    },

    methods: {
        ...mapActions('fundDetails', {
            fetchTabData: 'fetchKeyRiskTabData',
        }),
    },
};
</script>
