<template>
    <section
        v-if="showBanner"
        :data-e2e-component-uid="rendering.uid"
        class="py-4 py-lg-7 text-white"
        :class="changeBackgroundColor"
    >
        <div class="container pt-1 pt-md-5 pt-lg-6">
            <div class="row">
                <div class="col-12 col-md-8 col-lg-9">
                    <ScText tag="h1" class="mb-2" :field="title" />
                    <span class="body-large"> {{ localizeDate(publicationDate, dateFormatOptions) }} </span>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import { localizeDate } from '@/mixins/Helpers.mixin';

export default {
    name: 'PressReleaseHero',
    components: { ScText: Text },
    mixins: [localizeDate],
    props: {
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    data() {
        return {
            dateFormatOptions: { timeZone: 'UTC', year: 'numeric', month: 'long', day: '2-digit' },
        };
    },
    computed: {
        showBanner() {
            return (!!this.title.value && !isNaN(this.publicationDate)) || this.isExperienceEditor;
        },
        title() {
            return this.$jss?.routeData()?.fields?.pressReleaseTitle;
        },
        publicationDate() {
            return new Date(this.$jss?.routeData()?.fields?.pressReleasePublicationDate?.value);
        },
        changeBackgroundColor() {
            return this.$jss.sitecoreContext().pageTheme?.toLowerCase() === 'financialfairness'
                ? 'background-secondary'
                : 'background-primary';
        },
    },
};
</script>

<style scoped lang="scss">
h1 {
    padding-bottom: 2px;
}
</style>
