<template>
    <FormStep :stepData="formFields" />
</template>

<script>
import FormStep from '@/components/Sections/Forms/Form-LPP48/FormStepTemplate';

export default {
    name: 'Step2',
    components: { FormStep },
    props: {
        formFields: {
            type: Object,
            default: () => {},
        },
    },
};
</script>
