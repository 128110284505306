<template>
    <div
        class="funds-listing__heading d-flex flex-column flex-lg-row justify-content-between align-items-lg-center mb-3 mb-xl-0"
    >
        <TableSelector />
        <DisplayingRange
            class="footnote text-grey mt-4 mt-lg-0"
            dictionaryKey="paginationDisplayingResults"
            :total="totalFunds"
            :take="take"
            :page="currentPage"
            :showSkeleton="isLoading"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';

import DisplayingRange from '@/components/Includes/DisplayingRange.vue';
import TableSelector from '@/components/Pages/FundsCenter/AllFunds/FundsListing/TableSelector.vue';

export default {
    name: 'FundsListingHeading',
    components: { DisplayingRange, TableSelector },
    props: {
        totalFunds: {
            type: Number,
            required: true,
        },
        currentPage: {
            type: Number,
            required: true,
        },
        take: {
            type: Number,
            required: true,
        },
    },
    computed: {
        ...mapState('allFunds', ['isLoading']),
    },
};
</script>
