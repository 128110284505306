<template>
    <FundDetailsTabRow class="mt-4">
        <FundDetailsTabSkeleton v-if="loading" />
        <AlertMessageBase v-else-if="error" type="error" :title="$t(error)" />
        <ul v-else-if="tabData && tabData.length" class="abrdn-list">
            <li v-for="item in tabData" :key="item" class="body-default">{{ item }}</li>
        </ul>
        <div v-else class="body-default">{{ $t('noResultsMessage') }}</div>
    </FundDetailsTabRow>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import AlertMessageBase from '@/components/Generic/AlertMessageBase';

import FundDetailsTabRow from '../FundDetailsTabRow';
import FundDetailsTabSharedLogic from '../FundDetailsTabSharedLogic.mixin';
import FundDetailsTabSkeleton from '../FundDetailsTabSkeleton';

export default {
    name: 'FundDetailsPortfolioSecuritiesTab',

    components: {
        FundDetailsTabRow,
        AlertMessageBase,
        FundDetailsTabSkeleton,
    },

    mixins: [FundDetailsTabSharedLogic],

    computed: {
        ...mapState('fundDetails', {
            loading: (state) => state.portfolioSecuritiesTabData.loading,
            error: (state) => state.portfolioSecuritiesTabData.error,
            tabData: (state) => state.portfolioSecuritiesTabData.data,
        }),
    },

    methods: {
        ...mapActions('fundDetails', {
            fetchTabData: 'fetchPortfolioSecuritiesTabData',
        }),
    },
};
</script>
