import { getTableData } from '@/api/literatureHub';

const state = () => ({
    documents: [],
    isLoading: true,
    isPaginationLoading: false,
    allDocumentsCount: 0,
    paginationData: {
        take: 20,
        skip: 0,
    },
    searchQuery: {
        id: '',
        name: '',
    },
    appliedFilters: [],
});

// getters
const getters = {};

// actions
const actions = {
    async fetchDocuments({ commit, state }, payload = {}) {
        payload.skip = state.paginationData.skip;
        payload.take = state.paginationData.take;
        payload.searchQuery = state.searchQuery.name;
        payload.filters = state.appliedFilters;
        commit('setLoading', true);
        const { data } = await getTableData(payload);
        commit('setLoading', false);
        return data;
    },
    async searchDocuments({ state }, payload = {}) {
        payload.filters = state.appliedFilters;
        const { data } = await getTableData(payload);
        return data;
    },
};

const mutations = {
    setFilters: (state, filters) => {
        state.appliedFilters = filters;
    },
    setSearchQuery: (state, data) => {
        state.searchQuery.name = data.name;
    },
    setLoading: (state, data) => {
        state.isLoading = data;
    },
    setPaginationLoading: (state, data) => {
        state.isPaginationLoading = data;
    },
    setDocuments: (state, apiRes) => {
        state.allDocumentsCount = apiRes.resultCount;
        state.documents = apiRes.result;
    },
    setPaginationData: (state, data) => {
        state.paginationData.take = data.take;
        state.paginationData.skip = data.skip;
    },
    resetDocumentsData: (state) => {
        state.paginationData.take = 0;
        state.allDocumentsCount = 0;
        state.documents = [];
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
