<template>
    <ArticleHeroImageBackground
        v-if="params.imagePosition === 'background'"
        :data-e2e-component-uid="rendering.uid"
        :article="article"
    />
    <ArticleHeroImageRight v-else :data-e2e-component-uid="rendering.uid" :article="article" />
</template>

<script>
import ArticleHeroImageBackground from '@/components/Sections/Banners/ArticleHero/ArticleHeroImageBackground';
import ArticleHeroImageRight from '@/components/Sections/Banners/ArticleHero/ArticleHeroImageRight';

import { getArticleDataForInvestmentsHero } from './utils';

export default {
    name: 'InvestmentsHero',
    components: {
        ArticleHeroImageBackground,
        ArticleHeroImageRight,
    },
    props: {
        params: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    data() {
        return {
            article: null,
        };
    },
    mounted() {
        const { fields } = this.$jss.routeData();
        this.article = getArticleDataForInvestmentsHero(fields);
    },
};
</script>

<style scoped lang="scss"></style>
