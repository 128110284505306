<template>
    <div class="accordion border-bottom" :class="{ 'border-top': isFirst }">
        <button
            class="btn-naked d-flex justify-content-between align-items-center w-100 py-3 gap-1"
            :aria-expanded="show ? 'true' : 'false'"
            @click="show = !show"
        >
            <ScText tag="h3" :field="title" />
            <svg
                class="accordion__icon"
                aria-hidden="true"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M24 7.40068V5L12.8565 16.0448L12.0086 16.9101L11.1435 16.0448L0.0171306 5.01697L0 7.38371L12 19.2853L24 7.40068Z"
                    fill="currentColor"
                />
            </svg>
        </button>

        <ExpandableContent :show="show">
            <div class="accordion__panel">
                <div class="accordion__panel__inner">
                    <ScRichText :field="description" class="body-default" />
                    <slot />
                </div>
            </div>
        </ExpandableContent>
    </div>
</template>

<script>
import { RichText, Text } from '@sitecore-jss/sitecore-jss-vue';

import ExpandableContent from '@/components/Generic/ExpandableContent';

export default {
    name: 'AccordionNew',
    components: {
        ExpandableContent,
        ScRichText: RichText,
        ScText: Text,
    },
    props: {
        title: {
            type: Object,
            default: () => ({}),
        },
        description: {
            type: Object,
            default: () => ({}),
        },
        isFirst: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            show: false,
        };
    },
};
</script>

<style lang="scss" scoped>
.accordion {
    border-color: var(--dark-grey) !important;
}

.accordion__icon {
    width: calc(var(--spacer) * 3);
    height: calc(var(--spacer) * 3);
    flex-shrink: 0;
    transition: transform 0.3s;
}

[aria-expanded='true'] .accordion__icon {
    transform: rotate(180deg);
}

.accordion__panel {
    padding-top: calc(var(--spacer) * 1);
    padding-bottom: calc(var(--spacer) * 3);
}

.accordion__panel__inner {
    border-left: calc(var(--spacer) * 0.5) solid var(--digital-light-grey);
    padding-left: calc(var(--spacer) * 3);
}
</style>
