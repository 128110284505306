var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"secondary-hero-banner",attrs:{"id":_vm.anchorId,"data-e2e-component-uid":_vm.rendering.uid}},[(_vm.isAnimationVisible)?_c('Animation',{staticClass:"background-video-wrapper",attrs:{"loop":false,"animationData":_vm.desktopAnimation,"scaleSettings":_vm.scaleSettings}}):_c('div',{staticClass:"background-image-wrapper"},[_c('WrappedImage',{attrs:{"media":_vm.fields.backgroundImage,"srcSet":[
                { mw: 400 },
                { mw: 600 },
                { mw: 800 },
                { mw: 1000 },
                { mw: 1200 },
                { mw: 1400 },
                { mw: 1600 },
                { mw: 1800 },
                { mw: 2000 } ],"sizes":"100vw","lazy":false}})],1),_c('div',{staticClass:"container px-4 text-block pb-1 pb-md-2 pb-lg-3 pb-xl-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"inner-wrapper background-white px-2 px-sm-4 px-xl-5 pt-4 pt-sm-4 pt-xl-5 pb-4 pb-lg-5"},[_c('ScText',{staticClass:"col-lg-10 col-sm-11 col-12",attrs:{"field":_vm.fields.title,"tag":"h1"}}),_c('ScText',{staticClass:"mt-2 body-large col-md-11 col-lg-12",attrs:{"field":_vm.fields.subtitle,"tag":"p"}}),(_vm.isValueExists(_vm.fields.primaryCtaLink))?_c('AbrdnButton',{staticClass:"mt-2 mt-md-3",attrs:{"linkField":_vm.fields.primaryCtaLink,"buttonType":_vm.buttonType}}):_vm._e()],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }