<template>
    <section :id="anchorId" :data-e2e-component-uid="rendering.uid" class="container pb-5">
        <div class="d-flex flex-column-reverse flex-sm-row align-items-sm-center mb-5 w-100 justify-content-between">
            <DisplayingRange
                class="footnote text-grey"
                :page="page"
                dictionaryKey="paginationDisplayingInsightsResults"
                :take="take"
                :total="totalResults"
            />
            <div class="d-flex align-items-center ms-auto ms-sm-0 mb-2 mb-sm-0">
                <p class="form-label me-2">{{ $t('paginationShow') }}</p>
                <AbrdnSelect :key="take" :fields="showMore" @updateValue="setPages" />
            </div>
        </div>
        <div class="row d-flex flex-wrap" :class="{ 'w-100': articles.length < 4 }">
            <template v-if="articles.length">
                <div v-for="article in articles" :key="article.document.id" class="col-12 col-md-6 col-lg-3 mb-3">
                    <ArticleCard :fields="article.document" :cardCategoryField="cardCategoryField" />
                </div>
            </template>
        </div>
        <div class="d-flex align-items-lg-center mt-2 mt-md-3 mt-xl-5 mb-0 mb-md-1 mb-xl-3 w-100 relative">
            <DisplayingRange
                class="footnote text-grey"
                dictionaryKey="paginationDisplayingInsightsResults"
                :page="page"
                :take="take"
                :total="totalResults"
            />
            <Pagination
                v-if="totalResults"
                :currentPage="page"
                :maxPages="Math.ceil(totalResults / take)"
                class="pagination justify-content-center"
                @onSelect="onSelect"
            />
        </div>
    </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

import AbrdnSelect from '@/components/Generic/FormElements/FormFields/AbrdnSelect.vue';
import DisplayingRange from '@/components/Includes/DisplayingRange.vue';
import Pagination from '@/components/Includes/Pagination.vue';
import ArticleCard from '@/components/Tiles/ArticleCard.vue';
import FilterHelper from '@/mixins/ArticleFilter/FilterHelper.mixin';
import { sitecoreLanguage } from '@/mixins/Helpers.mixin';
import PaginationMixin from '@/mixins/Pagination.mixin';

export default {
    name: 'ArticleCards',
    components: {
        DisplayingRange,
        ArticleCard,
        Pagination,
        AbrdnSelect,
    },
    mixins: [FilterHelper, PaginationMixin],
    props: {
        cardCategoryField: {
            type: [String, Boolean],
            default: /* istanbul ignore next */ () => false,
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    data() {
        return {
            currentPage: 1,
        };
    },
    computed: {
        ...mapState('articles', ['articles', 'filterMappings', 'approvedFilters', 'page', 'totalResults', 'take']),
        pages() {
            const options = [];
            for (let i = 0; i < 5; i++) {
                options.push({
                    name: i ? i * 20 : 20,
                    displayName: i ? i * 20 : 20,
                    selected: !i,
                });
            }
            return options;
        },
        sitecoreLanguage,
        showMore() {
            const output = {
                placeholder: 'pages',
                options: {
                    value: [],
                },
            };
            const step = 20;
            for (let i = 0; i < 5; i++) {
                const stepNumber = (i + 1) * step;
                output.options.value.push({
                    name: stepNumber,
                    displayName: stepNumber,
                    selected: this.take === stepNumber,
                });
            }
            return output;
        },
    },
    methods: {
        ...mapActions('articles', ['getArticles']),
        ...mapMutations('articles', ['setPaginationData']),

        onSelect(currentPage) {
            this.currentPage = currentPage;
            this.setPaginationData({ page: currentPage, take: this.take });
            this.generateUrl(this.approvedFilters, this.page, this.take);
            this.getArticles(
                this.getParams(
                    this.prepareFilterCategories(this.approvedFilters),
                    this.filterMappings,
                    this.page,
                    this.take,
                    this.sitecoreLanguage,
                ),
            );
        },
        setPages(perPage) {
            if (this.totalResults <= this.page * perPage.value) {
                // this.currentPage = Math.round(this.totalResults / perPage);
                this.currentPage = 1;
            }
            this.setPaginationData({
                // page: this.totalResults <= this.page * perPage ? Math.ceil(this.totalResults / perPage) : this.page,
                page: 1,
                take: +perPage.value,
            });
            this.generateUrl(this.approvedFilters, this.page, this.take);
            this.getArticles(
                this.getParams(
                    this.prepareFilterCategories(this.approvedFilters),
                    this.filterMappings,
                    this.page,
                    this.take,
                    this.sitecoreLanguage,
                ),
            );
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';

.relative {
    position: relative;
    height: 32px;
    @include media-breakpoint-down(lg) {
        height: 76px;
    }
}
.pagination {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    @include media-breakpoint-down(lg) {
        bottom: 0;
    }
    // width: 320px;
}
</style>
