<template>
    <section :id="anchorId" :data-e2e-component-uid="rendering.uid" class="container background-white pt-4 pb-5">
        <div class="col-12 col-md-8">
            <ScText tag="h2" :field="title" class="background-to-change" />
        </div>
        <div v-if="results" class="row d-flex">
            <template v-for="item in items">
                <div v-if="results.hasOwnProperty(item.name)" :key="item.name" class="label col-12 col-md-4">
                    <div class="body-default text-grey pt-2 pricings-title">
                        <span>{{ $t(`funds${item.name}`) }}</span>
                    </div>
                    <p v-show="results[item.name]" class="h3 pt-1 pb-2 d-flex align-items-center">
                        <span>
                            {{ getValue(results[item.name], item.type) }}
                        </span>
                    </p>
                </div>
            </template>
        </div>
        <div v-if="bottomLineExist" class="bottom-line text-grey pt-1">
            <span
                >{{ results.currency }} | {{ $t('fundslondonStockExchange') }} | {{ $t('asOf') }} {{ formattedDate }} |
                D</span
            >
        </div>
    </section>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import { getPricingData } from '@/api/funds';

export default {
    name: 'PricingSummary',
    components: { ScText: Text },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    data() {
        return {
            apiData: {
                language: this.$jss.sitecoreContext()?.displayLanguage || 'en-GB',
                currency: 'GBP',
                site: this.$jss.sitecoreContext()?.name,
            },
            title: {
                value: this.$t('fundsdailyPricing'),
            },
            dateFormatOptions: { year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit' },
            items: [
                { name: 'sharePrice', type: 'currency' },
                { name: 'estimatedNavCumIncome', type: 'currency' },
                { name: 'estimatedNavExIncome', type: 'currency' },
                { name: 'lastActualCumIncomeNav', type: 'currency' },
                { name: 'dividendYield', type: 'percent' },
                { name: 'discountPremiumToNavCumIncome', type: 'percent' },
                { name: 'discountPremiumToNavExIncome', type: 'percent' },
            ],
            results: null,
        };
    },
    computed: {
        bottomLineExist() {
            return !!this.results?.currency && !!this.results?.dateTime;
        },
        formattedDate() {
            return new Date(this.results.dateTime).toLocaleDateString('en-GB', this.dateFormatOptions);
        },
        securityCode() {
            return this.$jss.routeData().fields?.trustId?.value;
        },
        securityType() {
            return this.$jss.routeData().fields?.trustType?.value;
        },
    },
    mounted() {
        this.apiData.securityCode = this.securityCode;
        this.apiData.securityType = this.securityType;
        getPricingData(this.apiData).then((res) => {
            this.results = res.data;
            this.filterTrustName();
        });
    },
    methods: {
        formatNumber(num) {
            if (num) num = num.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
            return num;
        },
        getTypeSymbol(type) {
            switch (type) {
                case 'currency':
                    return 'p';
                case 'percent':
                    return '%';
                default:
                    return '';
            }
        },
        getValue(item, type) {
            return `${this.formatNumber(item)} ${this.getTypeSymbol(type)}`;
        },
        filterTrustName() {
            const isDiversifiedTrust = this.$jss.sitecoreContext()?.site.name === 'abrdnDiversifiedJss';
            if (isDiversifiedTrust) {
                delete this.results.dividendYield;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.bottom-line {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;
    font-weight: 600;
}
.label {
    min-height: 96px;
}
.text-green {
    color: var(--success-400);
}
.text-red {
    color: var(--error-400);
}
.pricings-title {
    display: inline-block;
}
i {
    font-size: 20px;
    padding-right: 14px;
}
</style>
