<template>
    <svg viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.5843 0.238643L15.7617 5.42386C16.0794 5.74206 16.0794 6.25794 15.7617 6.57614L10.5843 11.7614C10.2665 12.0795 9.75142 12.0795 9.43371 11.7614C9.11599 11.4432 9.11599 10.9273 9.43371 10.6091L13.2223 6.81478L0.814779 6.81478C0.364789 6.81478 0 6.44999 0 6C0 5.55001 0.36479 5.18522 0.81478 5.18522L13.2223 5.18522L9.43371 1.39091C9.11599 1.07272 9.11599 0.556834 9.43371 0.238643C9.75142 -0.0795478 10.2665 -0.0795478 10.5843 0.238643Z"
            fill="currentColor"
        />
    </svg>
</template>

<script>
export default {
    name: 'IconArrowRight',
};
</script>

<style scoped></style>
