<template>
    <div class="col-12 col-md-6 col-xl-3 realtive">
        <div ref="sidebar-wrapper" class="sidebar-wrapper mb-6 mb-xl-0">
            <nav :style="{ width: `${sidebarWidth}px` }">
                <ScText tag="h3" class="mb-5" :field="fields.title" />
                <div class="flex flex-column w-100">
                    <div v-for="(title, i) in fields.items" :key="`${title.id}-${i}`">
                        <p
                            v-if="title.fields.title && title.fields.title.value"
                            class="title text-uppercase py-1 ps-3"
                            :tabindex="0"
                            :class="{ active: activeSubtitle === title.id }"
                            @click="scrollTo(title.id, title.id)"
                            @keyup.enter="scrollTo(title.id, title.id)"
                        >
                            {{ title.fields.title.value }}
                        </p>
                        <template v-for="(subtitle, index) in title.fields.items">
                            <p
                                v-if="
                                    subtitle.fields.subsectionTitle &&
                                    subtitle.fields.subsectionTitle.value &&
                                    activeSubtitle === title.id &&
                                    !scrolling
                                "
                                :key="`${subtitle.id}-${i}-${index}`"
                                :tabindex="0"
                                class="subtitle ps-5 pb-1"
                                :class="{ active: activeSubtitle === title.id }"
                                @click.prevent="scrollTo(subtitle.id, title.id)"
                                @keyup.enter="scrollTo(subtitle.id, title.id)"
                            >
                                {{ subtitle.fields.subsectionTitle.value }}
                            </p>
                        </template>
                    </div>
                </div>
            </nav>
        </div>
        <div v-show="showDropdown" class="sidebar-dropdown flex-column" :style="{ top: `${$root.headerHeight}px` }">
            <button
                ref="sidebar-dropdown"
                v-collapse-target="'#sidebar-dropdown-btn'"
                aria-expanded="false"
                class="sidebar-dropdown-btn collapsed pe-2"
                type="button"
                variant="button"
                @click="
                    showScroll = !showScroll;
                    isDropdownShown = !isDropdownShown;
                "
            >
                <div class="d-flex align-items-center container expandable">
                    <p class="text-left text-uppercase dropdown-spacing text-black">
                        {{ getActiveSubtitleName }}
                    </p>
                    <em class="d-flex bi-chevron-down ms-auto ms-sm-3" aria-hidden="true" />
                </div>
            </button>
            <div id="sidebar-dropdown-btn" class="collapse background-white">
                <div
                    class="copywrite-dropdown pb-1"
                    :style="{ 'max-height': `calc(100vh - ${$root.headerHeight + dropdownHeight}px)` }"
                >
                    <div v-for="(title, i) in fields.items" :key="`${title.id}-${i}`">
                        <div
                            v-if="title.fields.title && title.fields.title.value"
                            class="title dropdown-title dropdown-spacing text-uppercase py-2"
                            :tabindex="0"
                            :class="{ active: activeSubtitle === title.id }"
                            @keyup.enter="scrollTo(title.id, title.id)"
                            @click="
                                scrollTo(title.id, title.id);
                                $refs['sidebar-dropdown'].click();
                            "
                        >
                            <div class="container">
                                {{ title.fields.title.value }}
                            </div>
                        </div>
                        <template v-for="(subtitle, index) in title.fields.items">
                            <div
                                v-if="subtitle.fields.subsectionTitle && subtitle.fields.subsectionTitle.value"
                                :key="`${subtitle.id}-${i}-${index}`"
                                :tabindex="0"
                                class="dropdown-subtitle ps-2 pb-2"
                                :class="{ active: activeSubtitle === title.id }"
                                @click="
                                    scrollTo(subtitle.id, title.id);
                                    $refs['sidebar-dropdown'].click();
                                "
                                @keyup.enter="scrollTo(subtitle.id, title.id)"
                            >
                                <div class="container">
                                    {{ subtitle.fields.subsectionTitle.value }}
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import Collapse from '@/mixins/Collapse.mixin';
import DisableScroll from '@/mixins/DisableScroll.mixin';
import SidebarMixin from '@/mixins/Sidebar.mixin';

export default {
    name: 'Sidebar',
    components: { ScText: Text },
    mixins: [Collapse, DisableScroll, SidebarMixin],

    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    data() {
        return {
            activeSubtitle: this.fields.items[0].id,
            firstId: this.fields.items[0].id,
            scrolling: false,
            sidebarWidth: null,
            resizeObserver: null,
            showDropdown: false,
            dropdownHeight: null,
            showScroll: true,
            isDropdownShown: false,
            dropdownRef: 'sidebar-dropdown',
            wrapperRef: 'sidebar-wrapper',
        };
    },
    computed: {
        getActiveSubtitleName() {
            const currentTitle = this.fields.items.filter((item) => item.id === this.activeSubtitle)[0]?.fields.title
                .value;
            return currentTitle;
        },
    },
    mounted() {
        window.addEventListener('scroll', this.trackScreenView);
        this.setSidebarWidth('sidebar-wrappers');
        this.dropdownHeight = this.$refs['sidebar-dropdown'].getBoundingClientRect().height;
        this.$refs['sidebar-dropdown'] && this.observeHeight('sidebar-dropdown');
        window.addEventListener('resize', this.setSidebarWidth);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.trackScreenView);
        window.removeEventListener('resize', this.setSidebarWidth);
    },
};
</script>

<style lang="scss" scoped>
.sidebar-wrapper {
    position: sticky;
    top: 157px;
}

.title {
    letter-spacing: 0.04em;
}
.title,
.subtitle {
    border-left: 2px solid rgba(177, 185, 190, 0.4);
    cursor: pointer;
    transition: border-left ease-out 0.5s;
}

.title.active {
    border-left: 2.3px solid var(--abrdn-black);
    font-weight: 600;
    transition: border-left ease-in 0.2s;
}
.dropdown-spacing {
    letter-spacing: 0.04em;
}
.dropdown-title,
.dropdown-subtitle {
    cursor: pointer;
    border-left: none;
    &.active {
        border-left: none;
        font-weight: 600;
    }
}
.subtitle.active {
    font-weight: 600;
}

.sidebar-dropdown {
    position: fixed;
    width: 100%;
    left: 0;
    display: flex;
    padding: 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
}

.sidebar-dropdown-btn {
    width: 100%;
    border: none;
    border-bottom: 1px solid black;
    border-top: 1px solid black;
    background: white;
    padding: 20px 0;
    display: flex;
    font-weight: 600;
    justify-content: center;
    padding-right: 0 !important;

    > div {
        padding: 0 15px;
    }
    * {
        color: var(--abrdn-black) !important;
    }
}
.container {
    padding: 0 15px;
}
.collapse {
    border-bottom: 1px solid black;
}
.bi-chevron-down {
    &:before {
        font-weight: 600 !important;
    }
}
#sidebar-dropdown-btn {
    > div {
        overflow-y: auto;
        overflow-x: hidden;
        // margin: 0 15px;
        // margin-right: 15px;
        padding-left: 15px;
        > div {
            margin-left: -15px;
            width: 100vw;
            // padding-left: 15px;
        }
    }
    // &.active {
    //     .bi-chevron-down {
    //         transform: rotate(180deg);
    //     }
    // }
}

.bi-chevron-down {
    transform: rotate(180deg);
    transition: 0.2s linear;
}

.collapsed .bi-chevron-down {
    transform: rotate(0deg);
}

.text-left {
    text-align: left;
}
</style>
