<template>
    <section
        :id="anchorId"
        :data-e2e-component-uid="rendering.uid"
        :class="backgroundClass"
        class="default-section-padding"
    >
        <div class="container">
            <div class="row">
                <div
                    class="col-12 col-lg-4 d-flex flex-column flex-md-row flex-lg-column align-items-start justify-content-between"
                    :class="{ 'justify-content-lg-center': !gotStarsValue }"
                >
                    <div>
                        <ScText class="subheader-1 background-to-change" tag="p" :field="fields.title" />
                        <div v-if="showStars" class="d-flex flex-nowrap align-items-center mt-2">
                            <Rating :rating="Number(fields.stars.value)" />
                            <ScText
                                class="stars-description background-to-change body-default"
                                tag="p"
                                :field="starsDescription"
                            />
                        </div>
                    </div>
                    <AbrdnButton :linkField="fields.ctaLink" buttonType="primary" class="w-auto mt-6 mt-md-0 mt-lg-6" />
                </div>
                <div class="col-12 col-lg-8 mt-6 mt-lg-0">
                    <div
                        class="images-wrapper d-flex flex-row flex-lg-row-reverse flex-wrap flex-lg-nowrap justify-content-start"
                    >
                        <div
                            v-for="(image, i) in images"
                            :key="i"
                            class="award-image background-white flex-shrink-0 d-flex align-items-center justify-content-center mt-md-0"
                        >
                            <WrappedImage
                                :media="image.fields.image"
                                :srcSet="[{ mw: 200 }, { mw: 300 }]"
                                sizes="110px"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton';
import WrappedImage from '@/components/Generic/SitecoreWrappers/WrappedImage';
import Rating from '@/components/Includes/Rating';
import { backgroundClass } from '@/mixins/Helpers.mixin';

export default {
    name: 'AwardsBanner',
    components: { Rating, ScText: Text, AbrdnButton, WrappedImage },
    mixins: [backgroundClass],
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        images() {
            return this.fields?.items;
        },
        starsDescription() {
            return {
                ...this.fields?.starsDescription,
                value: this.fields?.starsDescription?.value.replace('{token.stars}', this.fields?.stars?.value),
            };
        },
        gotStarsValue() {
            return !!this.fields?.stars?.value;
        },
        showStars() {
            return this.gotStarsValue || this.isExperienceEditor;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';

.stars-description {
    margin-left: 13px;
}

.images-wrapper {
    margin: -15px;
    @include media-breakpoint-up(lg) {
        margin-top: 0px;
    }
}

.award-image {
    width: 150px;
    height: 150px;
    border-radius: 90px;
    margin: 15px;
    @include media-breakpoint-up(lg) {
        width: 170px;
        height: 170px;
    }
}

::v-deep img {
    height: auto;
    max-width: 100px;
    object-fit: cover;
    @include media-breakpoint-up(lg) {
        max-width: 110px;
    }
}
</style>
