<template>
    <div class="d-flex social-networks justify-content-end" :data-e2e-component-uid="rendering.uid">
        <template v-if="fields.items && fields.items.length">
            <a
                v-for="(icon, idx) in fields.items"
                :key="icon.id"
                :href="icon.fields.link.value.href"
                class="icon d-flex align-items-center justify-content-center"
                :class="idx !== fields.items.length - 1 ? 'me-3' : ''"
                target="_blank"
                :aria-label="icon.fields.icon.value"
            >
                <em
                    v-if="icon.fields.icon.value"
                    :class="{ ['bi-' + icon.fields.icon.value]: true }"
                    aria-hidden="true"
                />
                <p v-else-if="!icon.fields.icon.value && isExperienceEditor">[no icon selected]</p>
                <span class="sr-only">{{ $t('opensInNewWindow') }}</span>
            </a>
        </template>
        <p v-if="!fields.items && fields.items.length && isExperienceEditor" class="social-networks-placeholder">
            [Social Networks]
        </p>
    </div>
</template>

<script>
export default {
    name: 'SocialNetwork',
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        rendering: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
};
</script>

<style scoped lang="scss">
.social-networks {
    svg {
        color: var(--abrdn-white);
    }
    .icon {
        width: 24px;
        height: 24px;
        > * {
            color: var(--abrdn-white);
            font-size: 18px;
            line-height: 18px;
        }
    }
    a {
        &:after {
            display: none;
        }
    }
    .social-networks-placeholder {
        color: var(--abrdn-white);
    }
}
</style>
