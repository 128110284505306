export default {
    name: 'Cookie',
    methods: {
        setCookie(name, value, days, sameSite, secure) {
            if (!document) return false;
            let expires = '';
            if (days) {
                const date = new Date();
                date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
                expires = `; expires=${date.toUTCString()}`;
            }
            const Secure = secure ? ' Secure;' : '';
            const SameSite = sameSite ? ` SameSite=${sameSite};` : '';
            const encodedValue = encodeURIComponent(value || '');
            document.cookie = `${name}=${encodedValue}${expires}; path=/;${SameSite}${Secure}`;
        },
        getCookie(name) {
            if (!document) return false;
            const nameEqual = `${name}=`;
            const cookieArray = document.cookie.split('; ');
            const foundCookie = cookieArray.find((cookie) => cookie.includes(nameEqual));
            return foundCookie ? decodeURIComponent(foundCookie.replace(nameEqual, '')) : '';
        },
        eraseCookie(name) {
            if (!document) return false;
            document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
        },
    },
};
