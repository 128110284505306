import { render, staticRenderFns } from "./SecondLevelTabs.vue?vue&type=template&id=0c56f4ba&scoped=true"
import script from "./SecondLevelTabs.vue?vue&type=script&lang=js"
export * from "./SecondLevelTabs.vue?vue&type=script&lang=js"
import style0 from "./SecondLevelTabs.vue?vue&type=style&index=0&id=0c56f4ba&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c56f4ba",
  null
  
)

export default component.exports