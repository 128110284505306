<template>
    <div>
        <span v-if="reportDate" class="report-date">{{ reportDate }}</span>
        <div class="position-relative">
            <div
                :id="wrapperId"
                class="abrdn-table mt-5 mt-md-2"
                :class="[{ scrollable: isScrollable }, { [gradientPosition]: gradientPosition }]"
            >
                <table :id="tableId" :class="{ minimal }" tabindex="0" aria-label="Fund details holdings alt tab table">
                    <thead>
                        <tr>
                            <th id="first_col" class="sticky-column" />
                            <th v-for="colName in tableData.tableHeaderLabels" :id="colName" :key="colName">
                                {{ colName }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in tableData.tableBodyRowLabels" :key="item.rowLabel">
                            <th scope="row" class="sticky-column">{{ item.rowLabel }}</th>
                            <td v-for="(colValue, i) in item.values" :key="i">
                                {{ colValue }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div v-if="footnote" class="mt-1 footnote text-grey">{{ footnote }}</div>
        <CustomScroll
            v-if="isScrollable"
            :tableId="tableId"
            :wrapperId="wrapperId"
            @changeGradientPosition="changeGradientPosition"
        />
    </div>
</template>

<script>
import CustomScroll from '@/components/Functional/CustomScroll';
import { formatNumber } from '@/mixins/Helpers.mixin';

export default {
    name: 'FundDetailsStripedTable',
    components: {
        CustomScroll,
    },
    mixins: [formatNumber],
    props: {
        reportDate: {
            type: String,
            default: '',
        },
        footnote: {
            type: String,
            default: '',
        },
        isScrollable: {
            type: Boolean,
            default: false,
        },
        tableData: {
            type: Object,
            default: () => ({}),
        },
        minimal: {
            type: Boolean,
            default: false,
        },
        wrapperId: {
            type: String,
            default: '',
        },
        tableId: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            gradientPosition: 'gradient-right',
        };
    },

    methods: {
        changeGradientPosition(val) {
            this.gradientPosition = val;
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';

.report-date {
    @include media-breakpoint-down(md) {
        align-self: start;
        margin-bottom: 32px;
    }

    align-self: end;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
}

.abrdn-table {
    th {
        min-width: calc(var(--spacer) * 20);
    }

    table.minimal {
        thead {
            tr {
                #first_col {
                    background-color: var(--abrdn-white);
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        thead {
            th {
                text-align: center;
                border-bottom: 0;
            }
        }

        tbody {
            tr {
                td {
                    text-align: center;
                }
            }
        }
    }
}
</style>
