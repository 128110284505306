<template>
    <span v-if="durationType" class="article__readtime d-flex align-items-center">
        <span class="article__readtime__icon me-1">
            <component :is="durationType.icon" v-if="durationType" />
        </span>
        <span>
            <span>{{ duration }}</span> min {{ durationType.word }}
        </span>
    </span>
</template>

<script>
import IconArticle from '@/components/Icons/icon-article';
import IconPodcast from '@/components/Icons/icon-podcast';
import IconWebinar from '@/components/Icons/icon-webinar';
import ArticleTypes from '@/constants/article';

export default {
    name: 'Readtime',
    components: { IconArticle, IconPodcast, IconWebinar },
    props: {
        articleType: {
            type: [String, Boolean],
            default: () => false,
        },
        articleTypeConst: {
            type: [String, Boolean],
            default: () => false,
        },
        duration: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            articleTypes: ArticleTypes,
        };
    },
    computed: {
        durationType() {
            const type = this.articleType || this.articleTypes[this.articleTypeConst];
            switch (type) {
                case 'Article Page':
                case 'article':
                    return {
                        icon: 'IconArticle',
                        word: this.$t('read'),
                    };
                case 'Video Page':
                case 'video':
                    return {
                        icon: 'IconWebinar',
                        word: this.$t('watch'),
                    };
                case 'Podcast Page':
                case 'podcast':
                    return {
                        icon: 'IconPodcast',
                        word: this.$t('listen'),
                    };
                case 'Webinar Page':
                case 'webinar':
                    return {
                        icon: 'IconWebinar',
                        word: this.$t('watch'),
                    };
                default:
                    return false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.article__readtime__icon {
    color: var(--abrdn-black);
    width: 24px;
    height: 24px;
    svg {
        width: 100%;
        height: 100%;
    }
}
</style>
