// Supposed to be used for date NOT in UCT format like 2023-06-30T00:00:00, 6/30/2023 12:00:00 AM.
// localizeDate(2023-06-30T00:00:00) -> 06/30/2023
export const standardFormat = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
};
// Supposed to be used for date in UCT format like 2023-07-28T00:00:00Z.
// localizeDate(2023-07-28T00:00:00Z) -> 28/07/2023
export const UTCFormat = {
    timeZone: 'UTC',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
};
//Supposed to be used to display date and time
export const UTCFormatWithTime = {
    timeZone: 'UTC',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hourCycle: 'h24',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
};
// Supposed to be used for date in UCT format like 2023-07-28T00:00:00Z.
// localizeDate(2023-07-28T00:00:00Z) -> 28 Jul 2023
export const UTCFormatShort = {
    timeZone: 'UTC',
    month: 'short',
    year: 'numeric',
    day: '2-digit',
};
