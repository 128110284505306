import { getAllFundsOverview, getAllFundsPrices, getFundsSearchResults } from '@/api/funds';

const state = () => ({
    funds: [],
    isLoading: true,
    isPaginationLoading: false,
    allFundsCount: 0,
    fundsInFocusCount: 0,
    paginationData: {
        take: 0,
        skip: 0,
    },
    searchQuery: {
        id: '',
        name: '',
    },
    requestPayloadAssets: {
        countryCode: '',
        investorTypes: '',
        jurisdiction: '',
        displayLanguage: '',
        site: '',
    },
    requestsControllers: {
        fetchFundsOverview: undefined,
        fetchFundsPrices: undefined,
    },
    appliedFilters: [],
    selectedTab: 'overview',
    fundDetailsPageUrl: '',
    activeTabKey: 0,
    error: '',
});

// getters
const getters = {
    getMergedPayload: (state) => (payload) => {
        return { ...state.requestPayloadAssets, ...{ filters: state.appliedFilters }, ...payload };
    },
};

// actions
const actions = {
    async fetchFundsOverview({ commit, state, getters }, payload = {}) {
        try {
            payload.skip = state.paginationData.skip;
            payload.take = state.paginationData.take;
            payload.searchQuery = {
                id: state.searchQuery.id,
                name: state.searchQuery.name,
            };
            payload = getters.getMergedPayload(payload);
            commit('setError', null);
            commit('setLoading', true);
            //Abort previous unfulfilled funds request and start a new one
            state.requestsControllers.fetchFundsOverview?.abort();
            const requestController = new AbortController();
            const axiosConfig = {
                signal: requestController.signal,
            };
            commit('setRequestControllers', { fetchFundsOverview: requestController });
            const { data } = await getAllFundsOverview(payload, { axiosConfig });
            commit('setRequestControllers', { fetchFundsOverview: undefined });
            commit('setLoading', false);
            return data;
        } catch (error) {
            if (error?.name !== 'CanceledError') {
                commit('setError', error);
                commit('setLoading', false);
            }
        }
    },
    async fetchFundsPrices({ commit, state, getters }, payload = {}) {
        try {
            payload.skip = state.paginationData.skip;
            payload.take = state.paginationData.take;
            payload.searchQuery = {
                id: state.searchQuery.id,
                name: state.searchQuery.name,
            };
            payload = getters.getMergedPayload(payload);
            commit('setError', null);
            commit('setLoading', true);
            //Abort previous unfulfilled funds request and start a new one
            state.requestsControllers.fetchFundsPrices?.abort();
            const requestController = new AbortController();
            const axiosConfig = {
                signal: requestController.signal,
            };
            commit('setRequestControllers', { fetchFundsPrices: requestController });
            const { data } = await getAllFundsPrices(payload, { axiosConfig });
            commit('setRequestControllers', { fetchFundsPrices: undefined });
            commit('setLoading', false);
            return data;
        } catch (error) {
            if (error?.name !== 'CanceledError') {
                commit('setError', error);
                commit('setLoading', false);
            }
        }
    },
    async fetchSearchResults({ commit, getters }, searchFundName = {}) {
        try {
            commit('setError', null);
            let payload = {
                searchFundName,
            };
            payload = getters.getMergedPayload(payload);
            const { data } = await getFundsSearchResults(payload);
            return data;
        } catch (error) {
            commit('setError', error);
        }
    },
};

const mutations = {
    setFilters: (state, filters) => {
        state.appliedFilters = filters;
    },
    setRequestControllers: (state, controller) => {
        state.requestsControllers = { ...state.requestsControllers, ...controller };
    },
    setRequestPayloadAssets: (state, data) => {
        state.requestPayloadAssets = { ...state.requestPayloadAssets, ...data };
    },
    setSearchQuery: (state, data) => {
        state.searchQuery.id = data.id;
        state.searchQuery.name = data.name;
    },
    setLoading: (state, data) => {
        state.isLoading = data;
    },
    setPaginationLoading: (state, data) => {
        state.isPaginationLoading = data;
    },
    setFundsData: (state, apiRes) => {
        if (state.activeTabKey === 0) {
            state.allFundsCount = apiRes.resultCount;
        }
        if (state.activeTabKey === 1) {
            state.fundsInFocusCount = apiRes.resultCount;
        }
        const funds = apiRes[state.selectedTab] || apiRes.overview;
        state.funds = funds.map((fund) => {
            fund.selectedShareclass = fund.shareclasses[0];
            return fund;
        });
    },
    setActiveTab: (state, payload) => {
        state.activeTabKey = payload;
    },
    setPaginationData: (state, data) => {
        state.paginationData.take = data.take;
        state.paginationData.skip = data.skip;
    },
    updateSelectedShareclass: (state, data) => {
        const fund = state.funds[data.fundIdx];
        fund.selectedShareclass = fund.shareclasses.find((shareclass) => shareclass.shareclassID === data.shareclassID);
    },
    setSelectedTab: (state, payload) => {
        state.selectedTab = payload;
    },
    setFundDetailsPageUrl: (state, payload) => {
        state.fundDetailsPageUrl = payload;
    },
    setError: (state, error) => {
        const status = error?.response?.status;
        if (!error) {
            state.error = '';
        } else if (status !== undefined) {
            state.error = `error${status}`;
        } else {
            state.error = 'errorUnknown';
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
