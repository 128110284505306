<template>
    <section class="default-section-padding background-white">
        <FundDetailsTabRow fullWidth>
            <h2>{{ $t('fundDetailsTabsHoldings') }}</h2>

            <div class="mt-5 mt-lg-8">
                <h3>{{ $t(dictionary.title) }}</h3>

                <div class="mt-5">
                    <FundDetailsTabSkeleton v-if="loading" />

                    <AlertMessageBase v-else-if="error" type="error" :title="$t(error)" />

                    <template v-else-if="tabData && tabData.results.length">
                        <FundDetailsStripedTable
                            :reportDate="reportDate"
                            :isScrollable="isScrollable"
                            :tableData="tableData"
                            tableId="holdingsTable"
                            wrapperId="holdingsWrapper"
                        />

                        <div
                            v-if="holdingType !== 'topHoldings'"
                            class="d-flex flex-column flex-md-row mt-4 align-items-start align-items-md-center justify-content-between"
                        >
                            <DisplayingRange
                                class="footnote text-grey mb-5 mb-md-0"
                                dictionaryKey="paginationDisplayingResults"
                                :total="tabData.totalResults"
                                :take="20"
                                :page="currentPage"
                            />
                            <Pagination
                                v-if="tabData.totalResults"
                                :currentPage="currentPage"
                                :maxPages="Math.ceil(tabData.totalResults / 20)"
                                class="pagination justify-content-center text-grey mb-5 mb-md-0"
                                @onSelect="onPageChange"
                            />
                            <AbrdnButton id="download" buttonType="primary" @click.native="fetchDownloadData">
                                <span>{{ $t(dictionary.downloadBtn) }}</span>
                                <i class="bi bi-download ps-1" aria-hidden="true" />
                            </AbrdnButton>
                        </div>

                        <ManualDisclaimer
                            v-if="disclaimerData && disclaimerData.length"
                            :disclaimerData="disclaimerData"
                            class="mt-8"
                        />
                    </template>

                    <div v-else class="body-small">{{ $t('noResultsMessage') }}</div>
                </div>
            </div>
        </FundDetailsTabRow>
    </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import { getFundDetailsTabData, getFundDetailsTabDataEndpoints } from '@/api/funds';
import AlertMessageBase from '@/components/Generic/AlertMessageBase';
import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton';
import ManualDisclaimer from '@/components/Generic/ManualDisclaimer';
import DisplayingRange from '@/components/Includes/DisplayingRange.vue';
import Pagination from '@/components/Includes/Pagination.vue';
import FundDetailsStripedTable from '@/components/Pages/FundsCenter/FundDetails/FirstLevelTabs/FundDetailsStripedTable';
import { standardFormat } from '@/constants/dateFormats';
import DeviceType from '@/mixins/DeviceType.mixin';
import { formatCurrency, localizeDate } from '@/mixins/Helpers.mixin';
import PaginationMixin from '@/mixins/Pagination.mixin';
import StripedTableHelper from '@/mixins/StripedTableHelper.mixin';

import FundDetailsTabRow from '../FundDetailsTabRow';
import FundDetailsTabSkeleton from '../FundDetailsTabSkeleton';

export default {
    name: 'FundDetailsHoldingsTab',
    components: {
        ManualDisclaimer,
        FundDetailsStripedTable,
        FundDetailsTabRow,
        DisplayingRange,
        Pagination,
        AbrdnButton,
        AlertMessageBase,
        FundDetailsTabSkeleton,
    },
    mixins: [PaginationMixin, localizeDate, formatCurrency, DeviceType, StripedTableHelper],
    data() {
        return {
            currentPage: 1,
            skip: 0,
        };
    },
    computed: {
        ...mapState('fundDetails', {
            loading: (state) => state.holdingsTabData.loading,
            error: (state) => state.holdingsTabData.error,
            tabData: (state) => state.holdingsTabData.data,
            fundId: 'fundId',
            disclaimerData: (state) => state.holdingsTabManualDisclaimerData.data,
            availableTabs: 'availableTabs',
            correlationFundRangeID: 'correlationFundRangeID',
            fundRangeName: 'fundRangeName',
            name: 'name',
            fundHeroType: 'fundHeroType',
        }),
        ...mapGetters('fundDetails', ['selectedShareClass']),

        holdingType() {
            return Object.keys(this.availableTabs.holdingsTab).find(
                (key) => key !== 'tab' && this.availableTabs.holdingsTab[key] === true,
            );
        },

        dictionary() {
            const dictionaryItems = {
                title: this.$t('fundDetailsTabsDailyHoldings'),
                downloadBtn: this.$t('downloadDailyHoldingsBtn'),
            };

            if (this.holdingType === 'monthlyHoldings') {
                dictionaryItems.title = this.$t('fundDetailsTabsMonthlyHoldings');
                dictionaryItems.downloadBtn = this.$t('downloadMonthlyHoldingsBtn');
            }

            if (this.holdingType === 'topHoldings') {
                dictionaryItems.title = this.$t('fundDetailsTabsTopTenHoldings');
            }

            return dictionaryItems;
        },

        reportDate() {
            return (
                this.$t('fundDetailsHoldingsReportDate') +
                ' ' +
                this.localizeDate(this.tabData?.reportDate, standardFormat)
            );
        },

        isScrollable() {
            if (this.tabData === null) return false;
            if (this.deviceType === 'mobile' || this.deviceType === 'tablet') {
                return this.tableColumnHeadings.size >= 3;
            } else {
                return this.tableColumnHeadings.size > 4;
            }
        },

        isClient() {
            return typeof window !== 'undefined';
        },
    },
    async mounted() {
        if (this.isExperienceEditor) return;

        if (!this.tabData) {
            await this.fetchData();
        }

        if (!this.disclaimerData) {
            const {
                fundsConfiguration: {
                    countryInvestors: { countryCode },
                },
                displayLanguage,
                site: { name },
            } = this.$jss.sitecoreContext();

            this.fetchHoldingsTabManualDisclaimerData({
                countryCode,
                language: displayLanguage,
                shareClass: this.selectedShareClass.id,
                correlationFundRangeId: this.correlationFundRangeID,
                fundRangeName: this.fundRangeName,
                fundName: this.name,
                fund: this.fundId,
                site: name,
            });
        }
    },
    methods: {
        ...mapActions('fundDetails', ['fetchHoldingsTabData', 'fetchHoldingsTabManualDisclaimerData']),
        onPageChange(currentPage) {
            const element = document.getElementById('holdings-tab__table');
            this.currentPage = +currentPage;
            this.skip = 20 * +currentPage - 20;
            this.fetchData();
            if (element) {
                window.scrollTo(null, +element.offsetTop - 80);
            }
        },
        fetchData() {
            const {
                fundsConfiguration: {
                    countryInvestors: { countryCode },
                },
                displayLanguage,
                site: { name },
            } = this.$jss.sitecoreContext();

            this.fetchHoldingsTabData({
                language: displayLanguage,
                site: name,
                fund: this.fundId,
                take: 20,
                skip: this.skip,
                topHoldings: this.holdingType === 'topHoldings',
                shareClass: this.selectedShareClass.id,
                countryCode,
                correlationFundRangeId: this.correlationFundRangeID,
            });
        },
        async fetchDownloadData() {
            const { displayLanguage, site } = this.$jss.sitecoreContext();
            try {
                const data = await getFundDetailsTabData(
                    {
                        language: displayLanguage,
                        fund: this.fundId,
                        site: site.name,
                        holdingType: this.holdingType === 'dailyHoldings' ? 0 : 1,
                    },
                    getFundDetailsTabDataEndpoints.HOLDINGS.DOWNLOAD_DATA,
                    { responseType: 'blob' },
                );

                this.createAndDownloadFile(data);
            } catch (error) {
                console.log(error);
            }
        },
        createAndDownloadFile(data) {
            if (this.isClient) {
                const a = document.createElement('a');
                a.href = window.URL.createObjectURL(data.data);
                a.download = `${this.dictionary.title}`;
                document.body.appendChild(a);

                a.click();
                document.body.removeChild(a);
            }
        },

        formatTableValues(columns) {
            return Array.from(this.tableColumnHeadings).map((heading) => {
                const col = columns.find((col) => col.columnLabel === heading);
                if (!col) return this.$t('notAvailableShortMessage');

                return this.applyGeneralFormattingRules(col);
            });
        },
    },
};
</script>
