const state = () => ({
    languageVersions: [],
});

const mutations = {
    setLanguageVersions: (state, data) => {
        state.languageVersions = data;
    },
};
const getters = {
    getLanguageVersions: (state) => {
        return state.languageVersions
            .filter((item) => item.applicableForSelector)
            .map((item) => {
                return {
                    ...item,
                    label: item.languageName,
                    value: item.hreflang,
                    href: item.href.replace(window.location.origin, ''),
                    selected: false,
                };
            });
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    getters,
};
