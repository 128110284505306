<template>
    <div class="abrdn-list-root">
        <!-- Don't delete abrdn-list-root, it's made for targeting in ArticleBody -->
        <ul
            :class="{
                [params.style]: params.style,
                'icon-style': iconStyle,
                'image-style': imageStyle || svgExist,
                'circle-style': !iconStyle && !imageStyle && !svgExist,
                'ordered-style': orderedStyle,
            }"
            class="abrdn-list mb-0"
        >
            <li v-for="item in filteredArray" :key="item.id">
                <span class="circle background-to-change" />
                <div v-if="!orderedStyle && (iconStyle || imageStyle || svgExist)" class="icon me-2">
                    <em v-if="iconStyle && (!imageStyle || !svgExist)" :class="{ ['bi-' + fields.icon.value]: true }" />
                    <WrappedImage
                        v-if="(imageStyle || svgExist) && !iconStyle"
                        :media="getImage(item)"
                        class="image background-to-change"
                    />
                </div>
                <div class="list-item-text">
                    <ScRichText
                        v-if="isExperienceEditor || (item.fields && item.fields.title && item.fields.title.value)"
                        :field="item.fields.title"
                        class="body-default background-to-change"
                    />
                    <ScRichText
                        v-if="
                            isExperienceEditor ||
                            (item.fields && item.fields.description && item.fields.description.value)
                        "
                        :field="item.fields.description"
                        class="description body-small"
                    />
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import { RichText } from '@sitecore-jss/sitecore-jss-vue';

import WrappedImage from '@/components/Generic/SitecoreWrappers/WrappedImage.vue';

export default {
    name: 'List',
    components: {
        ScRichText: RichText,
        WrappedImage,
    },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        params: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
    },
    computed: {
        imageStyle() {
            if (this.itemLvlImageNotEmpty) {
                return true;
            }
            return (
                this.fields.image?.value?.src &&
                this.fields.image?.value?.class !== 'scEmptyImage' &&
                this.params.style === 'unordered' &&
                !this.fields.icon?.value
            );
        },
        iconStyle() {
            return this.fields.icon?.value && this.params.style === 'unordered' && !this.imageStyle;
        },
        orderedStyle() {
            return this.params.style === 'ordered';
        },
        svgExist() {
            return this.fields.image.value?.['data-type'] === 'image/svg+xml';
        },
        filteredArray() {
            return this.fields.items?.length || this.isExperienceEditor
                ? this.fields.items
                      .filter((item) => item.fields.title.value || this.isExperienceEditor)
                      .sort((firstItem, secondItem) => {
                          if (!this.itemLvlImageNotEmpty) return;
                          const firstItemImage = firstItem.fields.image?.value;
                          const secondItemImage = secondItem.fields.image?.value;
                          if (this.isObjectEmpty(firstItemImage) && !this.isObjectEmpty(secondItemImage)) {
                              return -1;
                          } else if (!this.isObjectEmpty(firstItemImage) && this.isObjectEmpty(secondItemImage)) {
                              return 1;
                          }
                          return 0;
                      })
                : [];
        },
        itemLvlImageNotEmpty() {
            return this.fields.items.some((item) => this.isObjectEmpty(item.fields.image?.value));
        },
    },
    methods: {
        getImage(listItem) {
            const itemLvlImage = listItem.fields.image;
            return this.isObjectEmpty(itemLvlImage.value) ? itemLvlImage : this.fields.image;
        },
        isObjectEmpty(object) {
            return !!Object.keys(object).length;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables';
@import '@/assets/styles/mixins';

.abrdn-list {
    padding: 0;
    list-style-type: none;

    li {
        display: flex;
        color: var(--digital-deep-grey);

        .description {
            margin-top: 8px;
            color: var(--digital-ash-grey);
            @include dark-backgrounds() {
                color: var(--abrdn-white);
            }
            @include light-backgrounds('.highlight-block') {
                color: var(--digital-ash-grey);
            }
        }
        @include dark-backgrounds() {
            color: var(--abrdn-white);
        }
        @include light-backgrounds('.highlight-block') {
            color: var(--digital-deep-grey);
        }
        &:not(:first-of-type) {
            margin-top: 16px;
        }
    }
    &.unordered {
        margin-bottom: 1rem !important;
    }
    .icon {
        i {
            &:before {
                font-size: 25px;
                @include dark-backgrounds() {
                    color: var(--abrdn-white);
                }
                @include light-backgrounds('.highlight-block') {
                    color: currentColor;
                }
            }
        }
    }

    &.image-style {
        .list-item-text {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    &.circle-style {
        display: block;
        .list-item-text {
            margin-left: 24px;
        }
        li {
            position: relative;
            .circle {
                position: absolute;
                top: 8px;
                background: currentColor;
                border-radius: 8px;
                width: 8px;
                height: 8px;
                @include dark-backgrounds('.background-white') {
                    background: var(--abrdn-white);
                }
                @include light-backgrounds('.highlight-block') {
                    background: var(--digital-deep-grey);
                }
            }
        }
    }

    &.icon-style {
        .icon {
            align-self: flex-start;
        }

        .list-item-text {
            display: block;
        }
    }

    &.ordered-style {
        counter-reset: ordered;
        display: block;
        .list-item-text {
            margin-left: 42px;
        }
        li {
            position: relative;
            &:not(:first-of-type) {
                margin-top: 32px;
            }
            &:before {
                counter-increment: ordered;
                content: counter(ordered);
                padding-left: 1px;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0;
                background: var(--digital-deep-grey);
                color: var(--abrdn-white);
                border-radius: 14px;
                width: 24px;
                height: 24px;
                margin-right: 16px;
                @include dark-backgrounds() {
                    background: var(--abrdn-white);
                    color: var(--digital-deep-grey);
                }
                @include light-backgrounds('.highlight-block') {
                    background: var(--digital-deep-grey);
                    color: var(--abrdn-white);
                }
            }
        }
    }
}

.image {
    min-width: 48px;
    min-height: 48px;
    width: 48px;
    height: 48px;
    display: block;

    ::v-deep img {
        max-width: 48px;
        max-height: 48px;
    }

    ::v-deep svg {
        width: 48px;
        height: 48px;
    }
}

::v-deep a {
    font-weight: 600;
    text-decoration: underline !important;
    cursor: pointer;
    color: inherit;
}
</style>
