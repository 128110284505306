<template>
    <form
        action="#"
        class="funds-search__form mt-2 d-flex align-items-center gap-3"
        @focusout="checkFocusWithin($event)"
        @submit.prevent="onSearchAction"
    >
        <div v-click-outside="hideAutoSuggestions" class="search-box">
            <div id="autocomplete-status" role="status" aria-live="polite" class="sr-only">
                {{ screenReaderContent }}
            </div>
            <InputField
                :fields="searchField"
                aria-haspopup="listbox"
                aria-owns="autocomplete-suggestions"
                :aria-activedescendant="focusedOption"
                autocomplete="off"
                class="search-field"
                @updateValue="searchInputHandler(...arguments)"
                @onFocus="onFocusHandler"
                @selectFirstFilterOption="selectFirstFilterOption"
            >
                <template #leftIcon>
                    <IconSearch class="search-icon" />
                </template>
            </InputField>
            <template v-if="useAutosuggestions">
                <transition name="slide">
                    <BaseSearchSuggests
                        v-show="isShowAutosuggestions"
                        id="autocomplete-suggestions"
                        :messageText="searchMessageText"
                        :suggestions="formattedSearchResults"
                        :searchQuery="searchInputData"
                        :isLoading="isLoading"
                        class="autosuggestions"
                        @onSelect="onSuggestionSelect"
                        @selectFilterOptionWithArrow="selectFilterOptionWithArrow"
                        @suggestOptionFocusHandler="suggestOptionFocusHandler"
                    />
                </transition>
            </template>
        </div>
        <AbrdnButton type="submit" buttonType="primary" class="submit-button">
            <span>{{ $t('search') }}</span>
        </AbrdnButton>
    </form>
</template>

<script>
import vClickOutside from 'v-click-outside';

import AbrdnButton from '@/components/Generic/Buttons/AbrdnButton.vue';
import InputField from '@/components/Generic/FormElements/FormFields/InputField.vue';
import BaseSearchSuggests from '@/components/Generic/SearchBar/BaseSearchSuggests.vue';
import IconSearch from '@/components/Icons/icon-search.vue';
import Cookie from '@/mixins/Cookie.mixin';
import { isFocusWithin } from '@/mixins/Helpers.mixin';
import { updateUrlParams } from '@/mixins/Helpers.mixin';

export default {
    name: 'BaseSearch',
    components: { BaseSearchSuggests, AbrdnButton, IconSearch, InputField },
    directives: {
        clickOutside: vClickOutside.directive,
    },
    mixins: [isFocusWithin, updateUrlParams, Cookie],
    props: {
        useAutosuggestions: {
            type: Boolean,
            default: true,
        },
        vuexStore: {
            type: String,
            default: '',
        },
        cookieName: {
            type: String,
            required: true,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        fieldName: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            searchInputData: '',
            isShowAutosuggestions: false,
            isLoading: false,
            searchMessageText: '',
            searchResults: [],
            latestAvailableData: null,
            screenReaderContent: '',
            focusedOption: undefined,
        };
    },
    computed: {
        searchQuery() {
            return this.$store.state[this.vuexStore].searchQuery;
        },
        searchField() {
            return {
                label: {
                    value: '',
                },
                placeholder: {
                    value: this.placeholder,
                },
                errorMessages: 'error',
                name: {
                    value: this.fieldName,
                },
                hint: {
                    value: '',
                },
                required: {
                    value: false,
                },
                type: {
                    value: 'search',
                },
                value: {
                    value: this.searchInputData,
                },
            };
        },
        formattedSearchResults() {
            return !!this.searchResults
                ? this.searchResults?.map((result) => {
                      if (result.title) {
                          result.name = result.title;
                      }
                      return result;
                  })
                : [];
        },
    },
    watch: {
        formattedSearchResults: {
            handler: function () {
                this.screenReaderContent = `There are ${this.formattedSearchResults.length} options available`;
            },
        },
    },
    mounted() {
        const urlParams = this.updateUrlParams();
        if (urlParams.search) {
            this.onSearchValueUpdate(urlParams.search, false);
        }
    },
    methods: {
        checkFocusWithin(event) {
            if (!this.isFocusWithin(event) && this.isShowAutosuggestions) {
                this.isShowAutosuggestions = false;
            }
        },
        searchInputHandler({ value }, needSuggestions) {
            if (value === this.searchInputData) return;
            this.onSearchValueUpdate(value, needSuggestions);
        },
        hideAutoSuggestions() {
            this.isShowAutosuggestions = false;
            this.screenReaderContent = '';
        },
        onFocusHandler() {
            this.isShowAutosuggestions = true;
            this.onSearchFocus();
            this.screenReaderContent = '';
        },
        onSuggestionSelect(suggestion) {
            this.searchInputHandler({ value: suggestion.name }, false);
            this.updateSearchQueryState(suggestion.id);
            this.isShowAutosuggestions = false;
        },
        onSearchAction() {
            this.updateSearchQueryState();
            document.activeElement.blur();
            this.hideAutoSuggestions();
        },

        onSearchValueUpdate(inputValue, needSuggestions = true) {
            this.searchInputData = inputValue;
            this.updateUrlParams([
                {
                    name: 'search',
                    value: inputValue,
                },
            ]);
            if (inputValue.length >= 3 && needSuggestions) {
                this.$emit('getSuggestions', this);
            } else {
                this.searchResults = [];
                this.searchMessageText = '';
            }
        },
        onSearchFocus() {
            if (this.searchInputData) return;
            const searchHistory = this.getSearchHistory();
            if (!searchHistory.length) return;
            this.searchResults = searchHistory;
            this.searchMessageText = this.$t('recentSearchResults');
        },
        updateSearchQueryState(fundId) {
            if (this.searchQuery === this.searchInputData) return;
            this.$store.commit(this.vuexStore + '/setSearchQuery', {
                name: this.searchInputData,
                id: fundId || '',
            });
            if (this.searchQuery.name) {
                this.updateSearchHistory({
                    name: this.searchQuery.name,
                });
            }
        },
        updateSearchHistory(fundObject) {
            if (!fundObject.name) return;
            const records = this.getSearchHistory();
            const alreadyExistingIdx = records.findIndex((record) => record.name === fundObject.name);
            if (alreadyExistingIdx !== -1) {
                records.splice(alreadyExistingIdx, 1);
            } else {
                records.splice(4, 1);
            }
            records.unshift(fundObject);
            this.setCookie(this.cookieName, JSON.stringify(records));
            return records;
        },
        getSearchHistory() {
            return JSON.parse(this.getCookie(this.cookieName) || '[]');
        },
        selectFirstFilterOption() {
            if (!this.formattedSearchResults.length) return;
            const firstOption = document.getElementById('suggestion-0');

            firstOption.focus();
        },

        selectLastFilterOption() {
            if (!this.formattedSearchResults.length) return;
            const lastOption = document.getElementById('suggestion-' + (this.formattedSearchResults.length - 1));

            lastOption.focus();
        },

        selectFilterOptionWithArrow(payload) {
            const { direction, idx } = payload;

            if (direction === 'down') {
                const nextElement = document.getElementById('suggestion-' + (idx + 1));
                if (idx === this.formattedSearchResults.length - 1) {
                    this.selectFirstFilterOption();
                }
                nextElement && nextElement.focus();
                this.focusedOption = 'suggestion-' + idx;
            } else if (direction === 'up') {
                const previousElement = document.getElementById('suggestion-' + (idx - 1));
                if (idx === 0) {
                    this.selectLastFilterOption();
                }
                previousElement && previousElement.focus();
            }
        },
        suggestOptionFocusHandler(idx) {
            this.screenReaderContent = `${idx + 1} of ${this.formattedSearchResults.length} is selected`;
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
.search-icon {
    padding: 0;
}
.search-box {
    position: relative;
    flex-grow: 1;
    max-width: 730px;
}
.autosuggestions {
    position: absolute;
    left: 0;
    top: calc(100% + 1px);
    right: 0;
    z-index: 5;
}

.slide-enter-active,
.slide-leave-active {
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
    transform-origin: top;
}
.slide-enter,
.slide-leave-to {
    transform: scaleY(0);
    opacity: 0;
}
.submit-button {
    @include media-breakpoint-down(lg) {
        display: none;
    }
}
</style>
