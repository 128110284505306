<template>
    <div class="d-none d-xl-block meganav__body background-white">
        <div class="pt-4 pb-8 meganav__body__inner">
            <div class="container">
                <div class="meganav__heading d-flex mb-4">
                    <ScLink
                        v-if="pullOutLink"
                        :tabindex="active ? 0 : -1"
                        :aria-hidden="active ? 'false' : 'true'"
                        :field="pullOutLink"
                        class="meganav-link meganav-link--small revert-underline"
                        @click.native="$emit('closeMegaNavBody')"
                    />
                    <button
                        :tabindex="active ? 0 : -1"
                        :aria-hidden="active ? 'false' : 'true'"
                        class="btn btn-naked meganav__closer ms-auto text-link"
                        @click="
                            $emit('closeMegaNavBody');
                            $emit('restoreFocus');
                        "
                    >
                        <span>{{ $t('close') }}</span>
                        <IconX />
                    </button>
                </div>
                <transition-group
                    name="fade"
                    tag="div"
                    class="row flex-nowrap"
                    :css="false"
                    @beforeEnter="beforeEnter"
                    @enter="enter"
                >
                    <div
                        v-for="(column, index) in columns"
                        v-show="active"
                        :key="column.id"
                        :class="columnsClass"
                        :data-index="index"
                    >
                        <div class="meganav__column">
                            <ScText class="meganav-link meganav-link--large" tag="p" :field="column.fields.title" />
                            <ScText class="body-small text-grey mt-2" tag="p" :field="column.fields.description" />

                            <ul>
                                <li v-for="linkItem in column.fields.items" :key="linkItem.id" class="mt-3">
                                    <ScLink
                                        class="meganav-link meganav-link--small revert-underline"
                                        :field="linkItem.fields.link"
                                        :showExternalIcon="true"
                                        @click.native="$emit('closeMegaNavBody')"
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <PromotedContent
                        v-if="promotedBlock"
                        v-show="active"
                        :key="promotedBlock.id"
                        :e2eId="promotedBlock.id"
                        :class="columnsClass"
                        :fields="promotedBlock.fields"
                        class="ms-auto"
                        :isWide="columnsClass === 'col-lg-3'"
                        @clickHandler="$emit('closeMegaNavBody')"
                    />
                </transition-group>
            </div>
        </div>
    </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import PromotedContent from '@/components/Generic/Header/MegaNavigation/PromotedContent.vue';
import ScLink from '@/components/Generic/SitecoreWrappers/ScLink.vue';
import IconX from '@/components/Icons/icon-x.vue';

export default {
    name: 'MegaNavigationBody',
    components: { PromotedContent, IconX, ScLink, ScText: Text },
    props: {
        category: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        active: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            animationData: {
                duration: 250,
                delay: 100,
            },
        };
    },
    computed: {
        pullOutLink() {
            return this.category?.fields?.pulloutLink?.value?.href || this.isExperienceEditor
                ? this.category?.fields?.pulloutLink
                : null;
        },
        promotedBlock() {
            return this.category?.fields?.promotedContent && this.columns.length < 5
                ? this.category?.fields?.promotedContent || {}
                : null;
        },
        columns() {
            return this.category?.fields?.items || [];
        },
        columnsClass() {
            let columnsLength = this.columns.length;
            if (this.promotedBlock) {
                columnsLength += 1;
            }
            return columnsLength >= 5 ? 'col-lg' : 'col-lg-3';
        },
    },
    watch: {
        active: {
            handler: function (active) {
                if (active) {
                    this.$gsap.fromTo(
                        this.$el,
                        { height: this.$el.getBoundingClientRect().height },
                        { height: 'auto', duration: 0.3 },
                    );
                } else {
                    this.$gsap.to(this.$el, { height: 0, duration: 0.3 });
                }
            },
        },
    },
    methods: {
        beforeEnter: /* istanbul ignore next */ function (el) {
            if (this.isExperienceEditor) return;
            el.style.opacity = 0;
        },
        enter: /* istanbul ignore next */ function (el, done) {
            if (this.isExperienceEditor) {
                done();
                return;
            }
            const stagger = el.dataset.index * this.animationData.delay;
            setTimeout(() => {
                this.$gsap.fromTo(
                    el,
                    { autoAlpha: 0, y: -30 },
                    { autoAlpha: 1, y: 0, duration: this.animationData.duration / 1000, onComplete: () => done() },
                );
            }, stagger);
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
.meganav__body {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    height: 0;
    overflow: hidden;
    z-index: 100;
    outline: none;
}

.meganav__body__inner {
    max-height: calc(100vh - var(--headerHeight));
    overflow: auto;
}
.meganav__closer {
    display: inline-flex;
    align-items: center;
    svg {
        width: 14px;
        height: 14px;
        margin-left: 12px;
    }
}
ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
</style>
