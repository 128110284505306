import { postSalesforceOnboardingFormData } from '@/api/SalesforceOnboardingForm';

const state = () => ({
    currentStep: 0,
    steps: [],
    onboardingFormData: {},
    reviewSteps: [],
    completedSteps: {},
});

const mutations = {
    changeStep: (state, step) => {
        state.currentStep = step;
    },
    setFormSteps: (state, steps) => {
        state.steps = steps;
    },
    setData: (state, data) => {
        // Ensure onboardingFormData is updated
        state.onboardingFormData[data.id] = data.value;

        // Define the path for nested property
        const path = `description.${data.relatesTo}.${data.name}`;

        // Ensure reviewSteps array index exists
        if (!state.reviewSteps[state.currentStep - 1]) {
            state.reviewSteps[state.currentStep - 1] = { description: {} };
        }

        // Set the nested property safely
        setNestedProperty(state.reviewSteps[state.currentStep - 1], path, data.value);
    },
    setFormID: (state, id) => {
        state.onboardingFormData.RecordTypeId = id;
    },
    setReviewStepsData: (state, data) => {
        state.reviewSteps = data;
    },
    setCompletedStep: (state) => {
        state.completedSteps[state.currentStep] = true;
    },
};

const actions = {
    async postFormData({ state }) {
        return await postSalesforceOnboardingFormData(state.onboardingFormData);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};

function setNestedProperty(obj, path, value) {
    const keys = path.split('.');
    let current = obj;

    for (let i = 0; i < keys.length - 1; i++) {
        if (!current[keys[i]]) {
            current[keys[i]] = {}; // create an object if the key doesn't exist
        }
        current = current[keys[i]];
    }

    current[keys[keys.length - 1]] = value;
}
