<template>
    <div class="highlight-item p-2" :class="[numberClass, addLayoutLess]">
        <div class="highlight-item__title">
            <WrappedImage
                :media="fields.icon"
                class="me-2 flex-shrink-0 highlight-item__icon background-to-change"
                :srcSet="[{ mw: 50 }, { mw: 100 }]"
                sizes="(max-width: 767px) 32px, 48px"
            />
            <ScText tag="h3" :field="fields.title" class="text-grey background-to-change text-nowrap" />
        </div>
        <div class="highlight-item__content">
            <ScText tag="p" :field="fields.description" class="text-grey background-to-change" />
        </div>
    </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';

import WrappedImage from '@/components/Generic/SitecoreWrappers/WrappedImage';

export default {
    name: 'HighlightItem',
    components: { WrappedImage, ScText: Text },
    props: {
        fields: {
            type: Object,
            default: /* istanbul ignore next */ () => ({}),
        },
        numberOfItems: {
            type: Number,
            default: /* istanbul ignore next */ () => 0,
        },
    },
    computed: {
        numberClass() {
            return `layout-${this.numberOfItems}`;
        },
        addLayoutLess() {
            return this.numberOfItems === 3 || this.numberOfItems === 1 ? 'layout-less' : '';
        },
    },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/variables';
.highlight-item__title {
    display: flex;
    align-items: center;
    @include media-breakpoint-down(md) {
        flex-direction: column;
        align-items: flex-start;
        .highlight-item__icon {
            margin-bottom: var(--spacer);
            width: 32px;
            height: 32px;
        }
    }
}
.highlight-item__icon {
    width: 48px;
    height: 48px;
}
.highlight-item__content {
    margin-top: var(--spacer);
}
.layout-2.highlight-item {
    @include media-breakpoint-up(xl) {
        display: flex;
        align-items: center;
    }
}
.layout-2.highlight-item .highlight-item__content {
    @include media-breakpoint-up(xl) {
        margin-left: calc(var(--spacer) * 2);
        margin-top: 0;
    }
}
.layout-4.highlight-item .highlight-item__title {
    @include media-breakpoint-between(md, xl) {
        flex-direction: column;
        align-items: flex-start;
        .highlight-item__icon {
            margin-bottom: var(--spacer);
        }
    }
}
.layout-3.highlight-item .highlight-item__icon,
.layout-4.highlight-item .highlight-item__icon {
    @include media-breakpoint-between(md, xl) {
        width: 32px;
        height: 32px;
    }
}
.layout-less.highlight-item {
    @include media-breakpoint-down(md) {
        .highlight-item__title {
            flex-direction: row;
            align-items: center;
        }
        .highlight-item__icon {
            width: 48px;
            height: 48px;
            margin-bottom: 0;
        }
    }
}
</style>
